import { Box, makeStyles, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import Text from '../../elements/text/Text';
import Title from '../../elements/title/Title';
import DeleteButton from './DeleteButton';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
}));

const DeleteSection = ({
  title,
  description,
  button,
  validation,
  onDelete,
  disableMessage,
}) => {
  const classes = useStyles();

  const handleSubmit = async (data) => {
    await onDelete(data);
  };

  return (
    <>
      <Title text={title} subTitle />
      <Container className={classes.margin}>
        <Text text={description} />
        {disableMessage !== '' ? (
          <Box my={1}>
            <Alert severity="info">{disableMessage}</Alert>
          </Box>
        ) : (
          <DeleteButton
            buttonText={button}
            onFormSubmit={handleSubmit}
            title={title}
            validation={validation}
          />
        )}
      </Container>
    </>
  );
};

DeleteSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.string,
  validation: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  disableMessage: PropTypes.string,
};

export default DeleteSection;
