import { useState } from 'react';

import {
  Box,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatDateTimeUTC } from '../../../utils/timeUtils';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';

const ScanResult = ({ result, timezone }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formatCell = (cell) => {
    switch (cell[0]) {
      case 'raw':
        return (
          <>
            <Button variant="text" onClick={handleOpen} size="small">
              {t('clickForDetails', { ns: 'buttons' })}
            </Button>
            <Modal
              title={t('rawVirusScan', { ns: 'sectionTitles' })}
              openModal={open}
              handleClose={handleClose}
            >
              <Box
                style={{
                  fontSize: '0.8em',
                  whiteSpace: 'pre-line',
                  background: '#333',
                  color: 'white',
                  padding: '1em',
                  borderRadius: '5px',
                }}
              >
                {cell[1]}
              </Box>
              <DialogActions>
                <Button
                  variant="text"
                  color="default"
                  style={{ marginRight: '-8px' }}
                  onClick={handleClose}
                >
                  {t('close', { ns: 'button' })}
                </Button>
              </DialogActions>
            </Modal>
          </>
        );
      case 'hits':
        return (
          cell[1].length > 0 &&
          cell[1].map((hit, i) => <Text key={i}>{hit}</Text>)
        );
      case 'startDate':
      case 'endDate':
        return formatDateTimeUTC(cell[1], timezone);
      default:
        return cell[1];
    }
  };

  const scanDetailsRow = (row) => {
    switch (row[0]) {
      case 'hits':
        return (
          row[1].length > 0 && (
            <>
              <TableCell>
                {t(`antivirus.${row[0]}`, { ns: 'instance' })}
              </TableCell>
              <TableCell>
                {row[1].map((hit, i) => (
                  <Text key={i}>{hit}</Text>
                ))}
              </TableCell>
            </>
          )
        );
      default:
        return (
          <>
            <TableCell>
              {t(`antivirus.${row[0]}`, { ns: 'instance' })}
            </TableCell>
            <TableCell>{formatCell(row)}</TableCell>
          </>
        );
    }
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {Object.entries(result.clamscanResult).map((row, j) => (
            <TableRow key={j}>{scanDetailsRow(row)}</TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ScanResult.propTypes = {
  result: PropTypes.object,
  timezone: PropTypes.string,
};

export default ScanResult;
