import { instance, getToken } from './config';

const getUserProfile = (userId) =>
  instance.get(`/users/${userId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteUser = (userId) =>
  instance.delete(`/users/${userId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const getUserProviders = (userId, redirectUrl) =>
  instance.get(`/users/${userId}/ips`, {
    params: {
      redirectUrl: redirectUrl,
    },
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteUserProvider = (userId, provider) =>
  instance.post(`/users/${userId}/ips/${provider}/unlink`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const enableUser2FA = (userId) =>
  instance.post(`/users/${userId}/otp/enable`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const disableUser2FA = (userId) =>
  instance.post(`/users/${userId}/otp/disable`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const getUserProjects = (userId, page = 0) =>
  instance.get(`/users/${userId}/projects`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      size: 20,
      page,
    },
  });

const updateUserInfo = (userId, body) =>
  instance.put(`/users/${userId}`, body, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateUserIcon = (userId, body) =>
  instance.put(`/users/${userId}/icon`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

const deleteUserIcon = (userId) =>
  instance.delete(`/users/${userId}/icon`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const resetPassword = (userId) =>
  instance.post(`/users/${userId}/password/reset`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const sendSupportInquiry = (data) =>
  instance.post(`/support-inquiries`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const sendAnonymousInquiry = (data) =>
  instance.post(`/anonymous-inquiries`, data);

export const userApis = {
  getUserProfile,
  deleteUser,
  getUserProviders,
  deleteUserProvider,
  enableUser2FA,
  disableUser2FA,
  getUserProjects,
  updateUserInfo,
  updateUserIcon,
  deleteUserIcon,
  resetPassword,
  sendSupportInquiry,
  sendAnonymousInquiry,
};
