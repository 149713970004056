import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Text from '../../elements/text/Text';
import Title from '../../elements/title/Title';
import { NOTIFICATION_TYPES } from './constants';
import DragAndDropItems from './DragAndDropItems';
import FormButtons from './FormButtons';
import { webhookSchema } from './formSchemas';
import { formatWebhookPayload } from './utils';

const WebhookForm = ({ notif, onWebhookSubmit, onSendTest, onCancel }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!notif) return;

    setValue('url', notif.settings.url);
    setValue('text', notif.settings.text);

    // eslint-disable-next-line
  }, []);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      url: '',
      text: t('notifMessage', { ns: 'instance' }),
    },
    resolver: yupResolver(webhookSchema()),
  });

  const handleWebhookSubmit = (data) => {
    const formattedPayload = formatWebhookPayload(data);
    onWebhookSubmit(formattedPayload);
    onCancel();
  };

  const handleSendTest = async () => {
    await trigger(['url', 'text']);
    if (!!errors.url || !!errors.text) return;

    const formValues = getValues();
    const formattedPayload = formatWebhookPayload(formValues);
    onSendTest(formattedPayload);
  };

  return (
    <Box mt={2}>
      <form
        onSubmit={handleSubmit(handleWebhookSubmit)}
        aria-label="webhook form"
      >
        <Box display="flex">
          <Text
            bodyBold
            style={{ width: 160, paddingTop: '0.5em', marginRight: '1em' }}
          >
            {t('monitor.webhookUrl', { ns: 'fields' })}
          </Text>
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('monitor.webhookUrl', { ns: 'fields' })}
                variant="outlined"
                size="small"
                fullWidth
                error={!!errors.url}
                helperText={errors?.url?.message}
                inputProps={{ 'aria-label': 'webhook address' }}
              />
            )}
          />
        </Box>
        <Title
          subTitle
          text={t('monitoring.notificationMessage', { ns: 'sectionTitles' })}
        />
        <DragAndDropItems />
        <Controller
          name="text"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('monitor.webhookMessage', { ns: 'fields' })}
              variant="outlined"
              multiline
              rows={5}
              fullWidth
              style={{ marginTop: '0.5em' }}
              error={!!errors?.text}
              helperText={errors?.text?.message}
              inputProps={{ 'aria-label': 'webhook notification' }}
            />
          )}
        />
        <FormButtons
          type={NOTIFICATION_TYPES.WEBHOOK}
          onCancel={onCancel}
          onSendTest={handleSendTest}
        />
      </form>
    </Box>
  );
};

WebhookForm.propTypes = {
  notif: PropTypes.object,
  onWebhookSubmit: PropTypes.func.isRequired,
  onSendTest: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default WebhookForm;
