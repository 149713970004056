import { uniqueId } from 'lodash';

const getFileExtName = (name) => {
  if (!(name.split('.').length > 1)) return '';
  return name.split('.').pop();
};

export const formatFileList = (files) =>
  files.map((file) => ({
    ...file,
    id: uniqueId(),
    modDate: new Date(file.date),
    isDir: file.dir,
    ext: getFileExtName(file.name),
  }));
