import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';
import ModalButtons from '../../sections/modal/ModalButtons';

const useTheme = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

const DeleteModal = ({
  collectionName,
  isSolrCloud,
  openModal,
  handleDelete,
  handleClose,
}) => {
  const { t } = useTranslation();
  const classes = useTheme();

  const handleCollectionDelete = (options) => {
    handleDelete(options);
  };

  const handleDeleteCancel = () => {
    reset();
    handleClose();
  };

  const { handleSubmit, control, reset } = useForm();

  return (
    <Modal
      openModal={openModal}
      title={t('deletion', {
        ns: 'project',
        text: t('collection', { ns: 'instance' }),
      })}
      handleClose={handleDeleteCancel}
    >
      <Text>
        {t('deleteCollection', { ns: 'descriptions', text: collectionName })}
      </Text>
      <form
        onSubmit={handleSubmit(handleCollectionDelete)}
        className={classes.indent}
      >
        <section>
          <Controller
            name="sc"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox defaultChecked {...field} />}
                label={
                  isSolrCloud
                    ? t('deleteSolrData', { ns: 'instance' })
                    : t('deleteInstanceDir', { ns: 'instance' })
                }
              />
            )}
          />
          {isSolrCloud && (
            <Controller
              name="zc"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox defaultChecked {...field} />}
                  label={t('deleteZooKeeperData', { ns: 'instance' })}
                />
              )}
            />
          )}
        </section>
        <ModalButtons
          onCancel={handleDeleteCancel}
          cancelProps={{ type: 'reset' }}
          actionProps={{ type: 'submit' }}
          actionText={t('delete', { ns: 'buttons' })}
          danger
        />
      </form>
    </Modal>
  );
};

DeleteModal.propTypes = {
  collectionName: PropTypes.string,
  isSolrCloud: PropTypes.bool,
  openModal: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleClose: PropTypes.func,
};

export default DeleteModal;
