import { useEffect, useState } from 'react';

import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { modelApis } from '../../../apis/modelApis';
import { projectApis } from '../../../apis/projectApis';
import Loading from '../../../components/elements/loading/Loading';
import Title from '../../../components/elements/title/Title';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import List from '../../../components/sections/list/List';

const ProjectModelListPage = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();

  const [project, setProject] = useState(null);
  const [modelList, setModelList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [{ data: projectRes }, { data: modelRes }] = await requestAll([
        projectApis.getProject(projectId),
        modelApis.getModels(projectId),
      ]);
      setProject(projectRes);
      setModelList(modelRes.content);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {loading && <Loading style={{ marginTop: '1em' }} />}
      {error && (
        <Alert style={{ marginTop: '1em' }} severity="error">
          {t('fetchModelError', { ns: 'notifications' })}
        </Alert>
      )}
      {!loading && modelList && (
        <section>
          <Breadcrumbs projectName={project.name} />
          <Title text={t('models', { ns: 'model' })} />
          <List isModelList data={modelList} />
        </section>
      )}
    </Container>
  );
};

export default ProjectModelListPage;
