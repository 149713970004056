import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import { range, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FILE_SIZE, MAX_FILE_NUMBER } from '../../../constants';
import {
  fileTooBig,
  fileNotZip,
  isSolrCloud,
  formatBytes,
} from '../../../utils/utils';
import ModalButtons from '../../sections/modal/ModalButtons';
import FileUpload from '../file-upload/FileUpload';

const useStyles = makeStyles((theme) => ({
  noPadding: {
    padding: 0,
  },
  noBottomPadding: {
    paddingBottom: 0,
  },
}));

const AddModal = ({
  openModal,
  instanceType,
  instanceNodes,
  schema,
  handleAdd,
  handleClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [tab, setTab] = useState('0');
  const [files, setFiles] = useState([]);
  const [sizeError, setSizeError] = useState(false);
  const [formatError, setFormatError] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const handleTabChange = (e, newValue) => {
    setTab(newValue);
  };

  const handleAddCollection = (data) => {
    handleAdd({ ...data, file: files[0] });
  };

  const handleAddModalClose = () => {
    setFiles([]);
    setFormatError(false);
    setSizeError(false);
    handleClose();
  };

  const onDrop = (acceptedFiles) => {
    if (isEmpty(acceptedFiles) || files.length >= MAX_FILE_NUMBER) return;

    setSizeError(fileTooBig(acceptedFiles[0].size, FILE_SIZE.BYTES));
    setFormatError(fileNotZip(acceptedFiles[0].type));

    if (
      !fileTooBig(acceptedFiles[0].size, FILE_SIZE.BYTES) &&
      !fileNotZip(acceptedFiles[0].type)
    ) {
      setSizeError(false);
      setFormatError(false);
      setFiles([...files, ...acceptedFiles]);
    }
  };

  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const removeFile = (fileIndex) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(fileIndex), 1);
    setFiles(newFiles);
  };

  const invalidForm = () => {
    if (tab === '0') {
      return !isValid;
    } else if (tab === '1') {
      return !isValid || sizeError || formatError || isEmpty(files);
    }
  };

  return (
    <Dialog open={openModal} fullWidth>
      <TabContext value={tab}>
        <TabList
          onChange={handleTabChange}
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label={t('createNew', { ns: 'instance' })} value="0" />
          <Tab label={t('createByImport', { ns: 'instance' })} value="1" />
        </TabList>
        <DialogTitle className={classes.noBottomPadding}>
          {t('addition', {
            ns: 'project',
            text: t('collection', { ns: 'instance' }),
          })}
        </DialogTitle>
        <form onSubmit={handleSubmit(handleAddCollection)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ padding: 0 }}>
                <TabPanel value="0" className={classes.noPadding} />
                <TabPanel value="1" className={classes.noPadding}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <FileUpload
                      files={files}
                      open={open}
                      handleRemove={removeFile}
                      formatError={formatError}
                      sizeError={sizeError}
                      sizeLimit={formatBytes(FILE_SIZE.BYTES, 0)}
                      helperText={t('dropOneZipFile', { ns: 'instance' })}
                      fileErrorMsg={t('onlyZipFile', { ns: 'descriptions' })}
                    />
                    <input {...getInputProps()} aria-label="upload file" />
                  </div>
                </TabPanel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('name')}
                  id="name"
                  fullWidth
                  autoComplete="off"
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  label={t('collectionName', { ns: 'fields' })}
                  defaultValue={''}
                />
              </Grid>

              {isSolrCloud(instanceType) && (
                <>
                  <Grid item xs={6}>
                    <Controller
                      name="shards"
                      control={control}
                      defaultValue={1}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel>
                            {t('shards', { ns: 'fields' })}
                          </InputLabel>
                          <Select
                            {...field}
                            inputProps={{
                              'aria-label': t('shards', { ns: 'fields' }),
                            }}
                          >
                            {range(1, instanceNodes + 1).map((num) => (
                              <MenuItem key={num} value={num}>
                                {num}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="replicationFactor"
                      control={control}
                      defaultValue={2}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel>
                            {t('replicationFactor', { ns: 'fields' })}
                          </InputLabel>
                          <Select
                            {...field}
                            inputProps={{
                              'aria-label': t('replicationFactor', {
                                ns: 'fields',
                              }),
                            }}
                          >
                            {range(1, instanceNodes + 1).map((num) => (
                              <MenuItem key={num} value={num}>
                                {num}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <ModalButtons
            onCancel={handleAddModalClose}
            cancelProps={{ type: 'reset' }}
            actionProps={{ disabled: invalidForm(), type: 'submit' }}
            actionText={t('save', { ns: 'buttons' })}
          />
        </form>
      </TabContext>
    </Dialog>
  );
};

AddModal.propTypes = {
  openModal: PropTypes.bool,
  instanceType: PropTypes.string,
  instanceNodes: PropTypes.number,
  schema: PropTypes.object,
  handleAdd: PropTypes.func,
  handleClose: PropTypes.func,
};

export default AddModal;
