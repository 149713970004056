import { useState } from 'react';

import { makeStyles, Box, Container, Paper } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import amex from '../../../../assets/card-brands/amex.svg';
import dinersclub from '../../../../assets/card-brands/dinersclub.svg';
import discover from '../../../../assets/card-brands/discover.svg';
import jcb from '../../../../assets/card-brands/jcb.svg';
import mastercard from '../../../../assets/card-brands/mastercard.svg';
import visa from '../../../../assets/card-brands/visa.svg';
import { PAYMENT_TYPES } from '../../../../constants';
import i18n from '../../../../i18n';
import Button from '../../../elements/button/Button';
import Svg from '../../../elements/svg/Svg';
import Text from '../../../elements/text/Text';
import Title from '../../../elements/title/Title';
import AddCardModal from './AddCardModal';
import DeleteCardModal from './DeleteCardModal';
import PaymentCardSection from './PaymentCardSection';
import UpdateCardModal from './UpdateCardModal';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  brandsList: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    '& > *': {
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      alignItems: 'flex-start',
      marginLeft: 0,
    },
  },
  noBilling: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  invoiceContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '1em',
    marginBottom: '1em',
    [theme.breakpoints.up('sm')]: {
      width: '500px',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  deleteText: {
    paddingBottom: theme.spacing(2),
  },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentMethod = ({
  billingInfo,
  card,
  handleUpdate,
  handleDelete,
  handleAdd,
  canDelete,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const locale = i18n.language || 'ja';

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const confirmDelete = (data) => {
    handleDelete(data);
    toggleDeleteModal();
  };

  const toggleUpdateModal = () => setUpdateModal(!updateModal);
  const confirmUpdate = (data) => {
    handleUpdate(data);
    toggleUpdateModal();
  };

  const toggleAddModal = () => setAddModal(!addModal);
  const confirmAdd = async (data) => {
    await handleAdd(data);
    toggleAddModal();
  };

  const cardsImgList = [amex, dinersclub, discover, jcb, mastercard, visa];

  return (
    <>
      <Box className={classes.title}>
        <Title subTitle text={t('methodInfo', { ns: 'sectionTitles' })} />
        <Box className={classes.brandsList}>
          {cardsImgList.map((cardImg, i) => (
            <Svg src={cardImg} key={i} />
          ))}
        </Box>
      </Box>
      {billingInfo ? (
        <Box>
          {billingInfo.paymentType === PAYMENT_TYPES.INVOICE && (
            <Paper className={classes.invoiceContainer} variant="outlined">
              <Box display="flex">
                <ReceiptOutlinedIcon
                  color="disabled"
                  style={{ fontSize: 45 }}
                />
                <Box ml={1}>
                  <Text bodyBold>
                    {t('billing.paymentByInvoice', { ns: 'project' })}
                  </Text>
                  <Text component="p" caption style={{ fontStyle: 'italic' }}>
                    {t('billing.noCardRequired', { ns: 'project' })}
                  </Text>
                </Box>
              </Box>
              <Button
                startIcon={<CreditCardIcon />}
                onClick={toggleAddModal}
                disabled={!!card}
              >
                {!card
                  ? t('add', {
                      ns: 'buttons',
                      text: t('billing.creditCard', { ns: 'project' }),
                    })
                  : t('cardOnFile', { ns: 'buttons' })}
              </Button>
            </Paper>
          )}
          {billingInfo.paymentType === PAYMENT_TYPES.CARD && (
            <PaymentCardSection
              card={card}
              canDelete={canDelete}
              toggleAddModal={toggleAddModal}
              toggleUpdateModal={toggleUpdateModal}
              toggleDeleteModal={toggleDeleteModal}
            />
          )}
        </Box>
      ) : (
        <Container className={classes.noBilling}>
          <InfoOutlinedIcon />
          <Text>{t('addBillingFirst', { ns: 'descriptions' })}</Text>
        </Container>
      )}

      <DeleteCardModal
        visible={deleteModal}
        onClose={toggleDeleteModal}
        onConfirm={confirmDelete}
      />
      <UpdateCardModal
        card={card}
        visible={updateModal}
        onClose={toggleUpdateModal}
        onConfirm={confirmUpdate}
      />
      <Elements
        stripe={stripePromise}
        options={{ locale: locale }}
        key={locale}
      >
        <AddCardModal
          visible={addModal}
          onClose={toggleAddModal}
          onConfirm={confirmAdd}
        >
          {!canDelete && (
            <Text className={classes.deleteText}>
              {t('cardDeleteWarning', { ns: 'descriptions' })}
            </Text>
          )}
        </AddCardModal>
      </Elements>
    </>
  );
};

PaymentMethod.propTypes = {
  billingInfo: PropTypes.object,
  card: PropTypes.object,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
};

export default PaymentMethod;
