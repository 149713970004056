import { useEffect, useState } from 'react';

import { Box, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import ProjectForm from '../../../components/page-sections/project-form/ProjectForm';
import ProjectManager from '../../../components/page-sections/project-manager/ProjectManager';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import DeleteSection from '../../../components/sections/delete/DeleteSection';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import { mapBlobFormData, sentenceCase } from '../../../utils/utils';

// Page
const ProjectSettingsPage = () => {
  const [project, setProject] = useState(null);
  const [projectManagers, setProjectManagers] = useState([]);
  const [areInstances, setAreInstances] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const { projectId } = useParams();
  const history = useHistory();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const fetchData = async (projectId) => {
    try {
      const [projectRequest, projectManagersRequest, instances] =
        await requestAll([
          projectApis.getProject(projectId),
          projectApis.getProjectManagers(projectId),
          solrApis.getInstances(projectId),
        ]);

      setProject(projectRequest.data);
      setProjectManagers(projectManagersRequest.data.content);
      setAreInstances(instances.data.content.length > 0);
      setIsLoading(false);
    } catch (error) {
      handleErrors(history, error, showToast);
      setIsLoading(false);
    }
  };

  // component events
  const onUpdate = async (data) => {
    const formData = mapBlobFormData(data, 'project');
    try {
      await projectApis.updateProject(projectId, formData);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('projectInfo', { ns: 'project' }),
          })
        )
      );
      fetchData(projectId);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('projectInfo', { ns: 'project' }),
          })
        )
      );
    }
  };

  const onRemoveImage = async () => {
    try {
      await projectApis.deleteProjectIcon(projectId);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('avatar', { ns: 'fields' }),
          })
        )
      );
      fetchData(projectId);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('avatar', { ns: 'fields' }),
          })
        )
      );
    }
  };

  const onUpdateManager = (data) => {
    if (projectManagers.length > 0) {
      projectApis
        .updateProjectManager(projectId, projectManagers[0].id, data)
        .then(() => {
          showToast(
            TOAST_TYPE.SUCCESS,
            sentenceCase(
              t('updateSuccess', {
                ns: 'notifications',
                text: t('pm.info', { ns: 'project' }),
              })
            )
          );
          fetchData(projectId);
        })
        .catch(() => {
          showToast(
            TOAST_TYPE.ERROR,
            sentenceCase(
              t('updateError', {
                ns: 'notifications',
                text: t('pm.info', { ns: 'project' }),
              })
            )
          );
        });
    } else {
      projectApis
        .addProjectManager(projectId, data)
        .then(() => {
          showToast(
            TOAST_TYPE.SUCCESS,
            sentenceCase(
              t('addSuccess', {
                ns: 'notifications',
                text: t('projectManager', { ns: 'sectionTitles' }),
              })
            )
          );
          fetchData(projectId);
        })
        .catch(() => {
          showToast(
            TOAST_TYPE.ERROR,
            sentenceCase(
              t('addError', {
                ns: 'notifications',
                text: t('projectManager', { ns: 'sectionTitles' }),
              })
            )
          );
        });
    }
  };

  const onDelete = () => {
    projectApis
      .deleteProject(projectId)
      .then(() => {
        showToast(
          TOAST_TYPE.SUCCESS,
          sentenceCase(
            t('deleteSuccess', {
              ns: 'notifications',
              text: t('project', { ns: 'project' }),
            })
          )
        );
        const projectsPage = buildPath({
          path: PRIVATE_ROUTE.PROJECTS,
        });
        setTimeout(() => history.push(projectsPage), 1000);
      })
      .catch(() => {
        showToast(
          TOAST_TYPE.ERROR,
          sentenceCase(
            t('deleteError', {
              ns: 'notifications',
              text: t('project', { ns: 'project' }),
            })
          )
        );
      });
  };

  useEffect(() => {
    fetchData(projectId);

    // eslint-disable-next-line
  }, [projectId]);

  return (
    <Container>
      {isLoading && <Loading />}
      {!isLoading &&
        (project ? (
          <>
            <Breadcrumbs projectName={project.name} />
            <TitlePanel title={t('settings', { ns: 'titles' })} />
            <ProjectForm
              data={project}
              onSubmit={onUpdate}
              handleRemoveImage={onRemoveImage}
            />
            <ProjectManager
              handleSubmit={onUpdateManager}
              projectManager={projectManagers}
            />
            <DeleteSection
              title={t('projectDeletion', { ns: 'sectionTitles' })}
              description={sentenceCase(
                t('deleteWarning', {
                  ns: 'descriptions',
                  text: t('project', { ns: 'project' }),
                })
              )}
              validation={project.name}
              onDelete={onDelete}
              disableMessage={
                areInstances
                  ? t('emptyInstanceBeforeDeleteProject', {
                      ns: 'descriptions',
                    })
                  : ''
              }
            />
          </>
        ) : (
          <Box mt={2}>
            <Alert severity="error">
              {sentenceCase(
                t('loading', {
                  ns: 'errors',
                  content: t('settingsPage', {
                    ns: 'project',
                    text: t('project', { ns: 'project' }),
                  }),
                })
              )}
            </Alert>
          </Box>
        ))}
    </Container>
  );
};

export default ProjectSettingsPage;
