import { DEFAULT_SUBSCRIPT_NUMBER } from '../constants';
import { instance, getToken } from './config';

// Actions shared by all instances. Other actions are in either solrApis or serverApis files.
const getStorage = (
  projectId,
  instanceId,
  instSubscript = DEFAULT_SUBSCRIPT_NUMBER
) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/monitoring/disk`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: { instSubscript },
    }
  );

const checkSubdomain = async (subdomain) => {
  try {
    await instance.get(`/subdomains/${subdomain}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    return false;
  } catch (e) {
    if (e.response.status === 404) {
      return true;
    }

    throw Error(e);
  }
};

const updateInstanceImage = async (projectId, instanceId, payload) => {
  await instance.put(
    `projects/${projectId}/instance-sets/${instanceId}/icon`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const deleteInstanceImage = async (projectId, instanceId) =>
  instance.delete(`/projects/${projectId}/instance-sets/${instanceId}/icon`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

export const instanceApis = {
  getStorage,
  checkSubdomain,
  updateInstanceImage,
  deleteInstanceImage,
};
