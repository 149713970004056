import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { projectApis } from '../../../apis/projectApis';
import Button from '../../../components/elements/button/Button';
import Text from '../../../components/elements/text/Text';
import Title from '../../../components/elements/title/Title';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
  },
  spaceRight: {
    marginRight: theme.spacing(2),
  },
}));

// Page
const ProjectMemberInvitationPage = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { projectId, inviteId } = useParams();

  const history = useHistory();

  const { showToast } = useToast();

  const acceptInvitation = () => {
    projectApis
      .acceptInvitationProjectMember(projectId, inviteId)
      .then(() => {
        history.push(
          buildPath({
            path: PRIVATE_ROUTE.PROJECT_OVERVIEW,
            params: { projectId: projectId },
          })
        );
      })
      .catch(() => {
        showToast(
          TOAST_TYPE.ERROR,
          t('acceptInvitationFailed', { ns: 'notifications' })
        );
      });
  };

  const goBack = () => {
    history.replace(PRIVATE_ROUTE.PROJECTS);
  };

  return (
    <Container className={classes.root}>
      <Box mt={2}>
        <Title text={t('memberInvitation', { ns: 'project' })} />
        <Text>{t('continueToBeInvited', { ns: 'descriptions' })}</Text>
      </Box>
      <Box mt={2}>
        <Button
          onClick={goBack}
          variant="outlined"
          className={classes.spaceRight}
        >
          {t('backToProjectList', { ns: 'buttons' })}
        </Button>
        <Button onClick={acceptInvitation}>
          {t('acceptInvitation', { ns: 'buttons' })}
        </Button>
      </Box>
    </Container>
  );
};

export default ProjectMemberInvitationPage;
