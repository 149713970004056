import { useEffect, useState } from 'react';

import { Box, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { projectApis } from '../../../apis/projectApis';
import { serverApis } from '../../../apis/serverApis';
import Loading from '../../../components/elements/loading/Loading';
import Text from '../../../components/elements/text/Text';
import Editor from '../../../components/page-sections/editor/Editor';
import {
  statusColor,
  statusIcon,
  useStyles,
} from '../../../components/page-sections/server-jobs/styles';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { TASK_STATUS } from '../../../constants/serverConstants';
// import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { PRIVATE_ROUTE } from '../../../routes/routes';
import { sentenceCase } from '../../../utils/utils';

const ServerJobsDetailsPage = () => {
  const history = useHistory();
  const { projectId, gpsId, jobId } = useParams();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [project, setProject] = useState(null);
  const [gps, setGps] = useState(null);
  const [task, setTask] = useState(null);
  const [log, setLog] = useState(null);
  const [logLoading, setLogLoading] = useState(true);

  const state = history.location.state;
  const modelId = state?.modelId;
  const mode = state?.file?.ext || 'txt';

  const fetchData = async () => {
    setLoading(true);
    try {
      if (isEmpty(state)) {
        history.length > 1
          ? history.goBack()
          : history.push(PRIVATE_ROUTE.PROJECTS);
      }

      const [{ data: projectRes }, { data: gpsRes }, { data: taskRes }] =
        await requestAll([
          projectApis.getProject(projectId),
          serverApis.getServer(projectId, gpsId),
          serverApis.getIndexingTask(projectId, modelId, jobId),
        ]);
      setProject(projectRes);
      setGps(gpsRes);
      setTask(taskRes.result);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const refreshTasks = async () => {
    try {
      const { data: taskRes } = await serverApis.getIndexingTask(
        projectId,
        modelId,
        jobId
      );
      setTask(taskRes.result);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('fetch', { ns: 'errors', content: t('job', { ns: 'gps' }) })
      );
      setTask(null);
      setError(true);
    }
  };

  const fetchLogs = async () => {
    try {
      const logRes = await serverApis.getTaskLog(
        projectId,
        task.instanceSetId,
        jobId
      );
      setLog(logRes.data);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('fetch', {
          ns: 'errors',
          content: t('log', { ns: 'gps' }),
        })
      );
      setError(true);
    } finally {
      logLoading && setLogLoading(false);
    }
  };

  useEffect(() => {
    if (!task) return;
    const taskInterval = setInterval(refreshTasks, 10000);
    if (
      task.status !== TASK_STATUS.RUNNING &&
      task.status !== TASK_STATUS.PENDING
    ) {
      clearInterval(taskInterval);
    }

    return () => clearInterval(taskInterval);
    // eslint-disable-next-line
  }, [task]);

  useEffect(() => {
    if (!task) return;
    fetchLogs();
    // eslint-disable-next-line
  }, [task]);

  return (
    <Container>
      {loading && <Loading />}
      {!loading && error && (
        <Alert severity="error" style={{ marginTop: '1em' }}>
          {t('fetch', {
            ns: 'errors',
            content: t('log', { ns: 'gps' }),
          })}
        </Alert>
      )}
      {!loading && !error && project && gps && (
        <>
          <Breadcrumbs projectName={project?.name} serverName={gps?.name} />
          <TitlePanel title={t('jobDetails', { ns: 'titles' })} />
          {logLoading ? (
            <Loading />
          ) : (
            log &&
            task && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  m={1}
                >
                  <Text text={t('status', { ns: 'gps' })} />:
                  {statusIcon(task.status, classes)}
                  <Text className={statusColor(task.status, classes)}>
                    {sentenceCase(t(task.status.toLowerCase(), { ns: 'gps' }))}
                  </Text>
                </Box>
                <Editor
                  mode={mode}
                  code={log}
                  prevPage={state.redirect}
                  readOnly="nocursor"
                />
              </>
            )
          )}
        </>
      )}
    </Container>
  );
};

export default ServerJobsDetailsPage;
