import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const SemanticSearchFormSection = ({ control, errors, style, dense }) => {
  const { t } = useTranslation();

  return (
    <Box className={style.inputContainer}>
      <Box className={style.input}>
        <FormControlLabel
          label={t('search.semantic', { ns: 'fields' })}
          control={
            <Controller
              name="settings.query.jsonQuery.params.dense"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  inputProps={{ 'aria-label': 'semantic search' }}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                />
              )}
            />
          }
        />
      </Box>
      {dense && (
        <>
          <Box className={style.input}>
            <Controller
              name="settings.query.jsonQuery.params.denseField"
              control={control}
              defaultValue=""
              shouldUnregister
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('search.denseField', { ns: 'fields' })}
                  variant="outlined"
                  size="small"
                  fullWidth
                  inputProps={{ 'aria-label': 'dense field' }}
                  error={
                    !!errors?.settings?.query?.jsonQuery?.params?.denseField
                  }
                  helperText={
                    errors?.settings?.query?.jsonQuery?.params?.denseField
                      ?.message
                  }
                />
              )}
            />
          </Box>

          <Box className={style.input}>
            <Controller
              name="settings.query.jsonQuery.params.denseTopK"
              control={control}
              defaultValue=""
              shouldUnregister
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('search.denseK', { ns: 'fields' })}
                  variant="outlined"
                  size="small"
                  fullWidth
                  inputProps={{ 'aria-label': 'denseK' }}
                  error={
                    !!errors?.settings?.query?.jsonQuery?.params?.denseTopK
                  }
                  helperText={
                    errors?.settings?.query?.jsonQuery?.params?.denseTopK
                      ?.message
                  }
                />
              )}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

SemanticSearchFormSection.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  dense: PropTypes.bool,
};

export default SemanticSearchFormSection;
