import Error from '../../components/sections/error/Error';
import i18next from '../../i18n';

const notFound = {
  type: 404,
  title: 'Not Found',
  content: [
    i18next.t('notFoundPage', { ns: 'errors' }),
    i18next.t('notFoundPageSubtext', { ns: 'errors' }),
  ],
};

const NotFoundError = () => <Error error={notFound} />;

export default NotFoundError;
