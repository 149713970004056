import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';

const RenameForm = ({ name, onSubmit }) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('fileName', { ns: 'fields' }),
          })
        )
      )
      .matches(
        /^([a-zA-Z0-9][^:?]*)$/,
        sentenceCase(
          t('invalid', {
            ns: 'validations',
            field: t('fileName', { ns: 'fields' }),
          })
        )
      )
      .max(255, t('maxLength', { ns: 'validations', max: '255' })),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        defaultValue={name}
        render={({ field }) => (
          <TextField
            {...field}
            label={t('fileName', { ns: 'fields' })}
            error={!!errors?.name}
            helperText={errors?.name?.message}
            autoFocus
            fullWidth
          />
        )}
      />
      <Box textAlign="right" my={2}>
        <Button type="submit" disabled={!isDirty || !isValid}>
          {t('save', { ns: 'buttons' })}
        </Button>
      </Box>
    </form>
  );
};

RenameForm.propTypes = {
  name: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default RenameForm;
