import { useState } from 'react';

import { Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import {
  setChonkyDefaults,
  FileBrowser as ChonkyFileBrowser,
  FileNavbar,
  FileToolbar,
  FileList,
  FileContextMenu,
  ChonkyActions,
} from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import { transform } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DEFAULT_TIMEZONE, FILE_MANAGER_UPLOAD_TYPE } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';
import CreateForm from './CreateForm';
import { fileActions } from './customActions';
import { japaneseI18n, englishI18n } from './i18nConfig';
import RenameForm from './RenameForm';
import UploadForm from './UploadForm';
import './FileBrowser.styles.scss';
import ZipFileUploadForm from './ZipFileUploadForm';

setChonkyDefaults({ iconComponent: ChonkyIconFA });

const FileBrowser = ({ files, folderChain, actionFunctions }) => {
  const { create, upload, edit, download, rename, remove, getStructure } =
    actionFunctions;
  const { showToast } = useToast();
  const { t, i18n } = useTranslation();
  const { user } = useUser();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [storeFile, setStoreFile] = useState({});

  const [tab, setTab] = useState('0');

  const handleTabChange = (e, newValue) => setTab(newValue);

  const toggleCreateModal = () =>
    setOpenCreateModal((prevStatus) => !prevStatus);
  const toggleRenameModal = () =>
    setOpenRenameModal((prevStatus) => !prevStatus);
  const toggleUploadModal = () =>
    setOpenUploadModal((prevStatus) => !prevStatus);
  const toggleDeleteModal = () =>
    setOpenDeleteModal((prevStatus) => !prevStatus);

  const onCreateSubmit = async (data) => {
    toggleCreateModal();
    await create(data.name);
  };
  const onRenameSubmit = async (data) => {
    toggleRenameModal();
    // storeFile.name = old name, data.name = new name
    await rename(storeFile.name, data.name);
  };
  const onUploadSubmit = async (files) => {
    toggleUploadModal();
    await upload(files, FILE_MANAGER_UPLOAD_TYPE.REG);
  };
  const onZipUploadSubmit = async (file) => {
    toggleUploadModal();
    await upload(file, FILE_MANAGER_UPLOAD_TYPE.ZIP);
  };
  const onDeleteConfirm = async () => {
    toggleDeleteModal();
    await remove(storeFile);
  };

  const handleAction = (data) => {
    const actions = transform(fileActions, (obj, x) => (obj[x.id] = x), {});
    const selectedFiles = data.state.selectedFiles;
    const showWarning = () =>
      showToast(
        TOAST_TYPE.WARNING,
        t('selectSingleFile', { ns: 'validations' })
      );

    if (data.id === ChonkyActions.CreateFolder.id) toggleCreateModal();
    if (data.id === ChonkyActions.UploadFiles.id) toggleUploadModal();
    if (data.id === ChonkyActions.OpenFiles.id && data.payload.files[0].isDir)
      getStructure(data.payload?.files?.[0]);
    if (data.id === actions.edit.id)
      selectedFiles.length === 1 ? edit(selectedFiles[0]) : showWarning();
    if (data.id === actions.download.id) download(selectedFiles);
    if (data.id === actions.rename.id) {
      if (selectedFiles.length === 1) {
        setStoreFile(selectedFiles[0]);
        toggleRenameModal();
      } else {
        showWarning();
      }
    }
    if (data.id === actions.remove.id) {
      setStoreFile(selectedFiles);
      toggleDeleteModal();
    }
  };

  return (
    <Container style={{ height: 600 }}>
      <ChonkyFileBrowser
        files={files}
        folderChain={folderChain}
        fileActions={fileActions}
        onFileAction={handleAction}
        defaultFileViewActionId={ChonkyActions.EnableListView.id}
        clearSelectionOnOutsideClick={true}
        disableDragAndDropProvider={true}
        i18n={
          i18n.language === 'en'
            ? englishI18n(user?.timeZone || DEFAULT_TIMEZONE)
            : japaneseI18n(user?.timeZone || DEFAULT_TIMEZONE)
        }
      >
        <FileNavbar />
        <FileToolbar />
        <FileList />
        <FileContextMenu />
      </ChonkyFileBrowser>

      <Modal
        openModal={openCreateModal}
        title={t('folderCreation', { ns: 'instance' })}
        handleClose={toggleCreateModal}
      >
        <CreateForm onSubmit={onCreateSubmit} />
      </Modal>

      <Modal
        openModal={openRenameModal}
        title={t('folderEdit', { ns: 'instance' })}
        handleClose={toggleRenameModal}
      >
        <RenameForm name={storeFile.name} onSubmit={onRenameSubmit} />
      </Modal>

      <Modal
        openModal={openUploadModal}
        title={t('fileUpload', { ns: 'instance' })}
        handleClose={toggleUploadModal}
      >
        <TabContext value={tab}>
          <TabList
            onChange={handleTabChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            style={{ marginBottom: '1em' }}
          >
            <Tab label="File Upload" value="0" />
            <Tab label="File Upload and Unzip" value="1" />
          </TabList>
          <Box>
            <TabPanel value="0" style={{ padding: 0 }}>
              <UploadForm onSubmit={onUploadSubmit} />
            </TabPanel>
            <TabPanel value="1" style={{ padding: 0 }}>
              <ZipFileUploadForm onSubmit={onZipUploadSubmit} />
            </TabPanel>
          </Box>
        </TabContext>
      </Modal>

      <Modal
        openModal={openDeleteModal}
        title={t('deletion', {
          ns: 'project',
          text: t('file', { ns: 'fields' }),
        })}
        handleClose={toggleDeleteModal}
        showActions
        handleConfirm={onDeleteConfirm}
      >
        <Text>
          {t('areYouSureFiles', { ns: 'descriptions' })}{' '}
          {t('cantUndo', { ns: 'descriptions' })}
        </Text>
      </Modal>
    </Container>
  );
};

FileBrowser.propTypes = {
  files: PropTypes.array.isRequired,
  folderChain: PropTypes.array.isRequired,
  actionFunctions: PropTypes.object.isRequired,
};

export default FileBrowser;
