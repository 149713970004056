import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import FileSection from './FileSection';
import UploadBox from './UploadBox';

const FileUpload = ({
  files,
  handleRemove,
  open,
  sizeLimit,
  formatError,
  sizeError,
  helperText,
  fileErrorMsg,
}) => {
  return (
    <section style={{ padding: 8 }}>
      {!isEmpty(files) ? (
        <FileSection files={files} removeFile={handleRemove} />
      ) : (
        <UploadBox
          sizeLimit={sizeLimit}
          sizeError={sizeError}
          formatError={formatError}
          open={open}
          helperText={helperText}
          fileErrorMsg={fileErrorMsg}
        />
      )}
    </section>
  );
};

FileUpload.propTypes = {
  files: PropTypes.array,
  handleRemove: PropTypes.func,
  open: PropTypes.func,
  sizeLimit: PropTypes.string,
  formatError: PropTypes.bool,
  sizeError: PropTypes.bool,
  helperText: PropTypes.string,
  fileErrorMsg: PropTypes.string,
};

export default FileUpload;
