import { getToken, instance } from './config';

const getAntivirusSettings = (projectId, instanceId) =>
  instance.get(`/projects/${projectId}/instance-sets/${instanceId}/virusScan`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const enableAntivirus = (projectId, instanceId) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/virusScan`,
    null,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const disableAntivirus = (projectId, instanceId, virusScanId) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/virusScan/${virusScanId}/disable`,
    null,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const getVirusScanList = (projectId, instanceId, virusScanId) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/virusScan/${virusScanId}/results`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

export const antivirusApis = {
  getAntivirusSettings,
  enableAntivirus,
  disableAntivirus,
  getVirusScanList,
};
