import { useState } from 'react';

import {
  Box,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  DATA_TYPES,
  TABLE_HEADERS,
} from '../../../constants/monitoringConstants';
import { useUser } from '../../../contexts/UserContext';
import Button from '../../elements/button/Button';
import MonitoringGraphs from './MonitoringGraphs';
import { formatDiskLabels } from './utils';

const MonitoringData = ({ onDownload, monitoringData, error }) => {
  const { t } = useTranslation('monitoring');
  const { user } = useUser();
  const [type, setType] = useState(DATA_TYPES.GRAPH);

  if (error) {
    return (
      <Alert severity="error">
        {t('fetchMetricsError', { ns: 'notifications' })}
      </Alert>
    );
  }

  const resultsArray = Object.entries(monitoringData.result);
  const diskIoKeys = resultsArray?.[0]?.[1]?.ioUtilization
    ? Object.keys(resultsArray[0][1].ioUtilization)
    : [];

  const monitoringValues = Object.entries(monitoringData?.result) || [];
  const solrDisk = monitoringData.dataDiskName;

  return (
    <section>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <ButtonGroup disableElevation disableRipple>
          <Button
            color="primary"
            variant={type === DATA_TYPES.GRAPH ? 'contained' : 'outlined'}
            onClick={() => setType(DATA_TYPES.GRAPH)}
          >
            {t('graph')}
          </Button>
          <Button
            color="primary"
            variant={type === DATA_TYPES.DATA ? 'contained' : 'outlined'}
            onClick={() => setType(DATA_TYPES.DATA)}
          >
            {t('data')}
          </Button>
        </ButtonGroup>

        <Button onClick={onDownload}>{t('downloadData')}</Button>
      </Box>

      {type === DATA_TYPES.DATA && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {TABLE_HEADERS.map((header, i) =>
                  i === 0 ? (
                    <TableCell key={header}>{t(header)}</TableCell>
                  ) : (
                    <TableCell key={header} align="center">
                      {t(header)}
                    </TableCell>
                  )
                )}
                {diskIoKeys.map((disk) => (
                  <TableCell key={disk} align="center">
                    {t(formatDiskLabels(disk, solrDisk))}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {monitoringValues.map((dataPoint) => (
                <TableRow key={dataPoint[0]}>
                  <TableCell>
                    {moment(dataPoint[0] * 1000)
                      .tz(user?.timeZone)
                      .format(t('format.monitorDate', { ns: 'general' }))}
                  </TableCell>
                  <TableCell align="center">
                    {dataPoint[1].cpuUsage ? `${dataPoint[1].cpuUsage}%` : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {dataPoint[1].ramUsagePercent
                      ? `${dataPoint[1].ramUsagePercent}%`
                      : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {dataPoint[1].dataDiskUsagePercent
                      ? `${dataPoint[1].dataDiskUsagePercent}%`
                      : '-'}
                  </TableCell>
                  {diskIoKeys.map((disk) => (
                    <TableCell align="center" key={disk}>
                      {dataPoint[1].ioUtilization?.[disk]
                        ? `${dataPoint[1].ioUtilization[disk] * 100}%`
                        : '-'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {type === DATA_TYPES.GRAPH && (
        <MonitoringGraphs monitoringData={monitoringData} solrDisk={solrDisk} />
      )}
    </section>
  );
};

MonitoringData.propTypes = {
  onDownload: PropTypes.func.isRequired,
  monitoringData: PropTypes.object,
  error: PropTypes.bool,
};

export default MonitoringData;
