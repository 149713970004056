import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import Modal from '../../sections/modal/Modal';

const RemoveModal = ({ member, modal, toggleModal, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal
      openModal={modal}
      title={t('confirmation', {
        ns: 'sectionTitles',
        text: t('deletion', {
          ns: 'project',
          text: t('member', { ns: 'project' }),
        }),
      })}
      showActions
      handleClose={toggleModal}
      handleConfirm={onConfirm}
    >
      <Trans
        i18nKey="areYouSureMember"
        ns="descriptions"
        components={[<strong key="0" />]}
        values={{ member: member.publicName }}
      />
    </Modal>
  );
};

RemoveModal.propTypes = {
  member: PropTypes.object.isRequired,
  modal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default RemoveModal;
