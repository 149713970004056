import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { useUser } from '../../../contexts/UserContext';
import { PRIVATE_ROUTE, buildPath } from '../../../routes/routes';
import { formatDateTimeUTC } from '../../../utils/timeUtils';
import { getInitials } from '../../../utils/utils';
import Image from '../../elements/image/Image';
import Status from '../../elements/status/Status';
import Text from '../../elements/text/Text';
import ModelChip from '../instance-chip/ModelChip';

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: theme.spacing(6),
  },
  badge: {
    color: theme.palette.grey[600],
    borderColor: theme.palette.grey[400],
    fontSize: theme.typography.caption.fontSize,
  },
  spaceLeft: {
    marginLeft: theme.spacing(1),
  },
}));

const ModelListItem = ({ model }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useUser();
  const { projectId } = useParams();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListItem>
      <ListItemAvatar>
        <Image size="small" shape="rounded" alt={model.name} src={model?.icon}>
          {getInitials(model.name)}
        </Image>
      </ListItemAvatar>

      <ListItemText
        primary={
          <Box display="flex" alignItems="center">
            <Link
              component={RouterLink}
              to={buildPath({
                path: PRIVATE_ROUTE.MODEL_OVERVIEW,
                params: { projectId, modelId: model.id },
              })}
              color="inherit"
            >
              {model.name}
            </Link>
            <Status instanceState={model.status} solrRunning={true} />
            <ModelChip category={model.category} ml={2} />
          </Box>
        }
        secondary={
          isSmallScreen && (
            <Box display="flex" alignItems="center">
              {model.createDate && (
                <Box ml={1}>
                  <Text caption className={classes.createdDate}>
                    {t('createdAt', {
                      ns: 'instance',
                      date: formatDateTimeUTC(model.createDate, user?.timeZone),
                    })}
                  </Text>
                </Box>
              )}
            </Box>
          )
        }
      />

      {!isSmallScreen && (
        <ListItemSecondaryAction>
          <Box display="flex" alignItems="center">
            {model.createDate && (
              <Box ml={1}>
                <Text caption className={classes.createdDate}>
                  {t('createdAt', {
                    ns: 'instance',
                    date: formatDateTimeUTC(model.createDate, user?.timeZone),
                  })}
                </Text>
              </Box>
            )}
          </Box>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

ModelListItem.propTypes = {
  model: PropTypes.object.isRequired,
};

export default ModelListItem;
