import { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { extensionApis } from '../../../apis/extensionApis';
import { projectApis } from '../../../apis/projectApis';
import { userApis } from '../../../apis/userApis';
import Loading from '../../../components/elements/loading/Loading';
import ExtensionDetails from '../../../components/page-sections/extension-details/ExtensionDetails';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import { useUser } from '../../../contexts/UserContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';

const ExtensionDetailsPage = () => {
  const { extensionId } = useParams();
  const { showToast } = useToast();
  const history = useHistory();
  const { user } = useUser();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [extension, setExtension] = useState(null);

  const fetchProjects = async () => {
    try {
      const { data: projectsRes } = await userApis.getUserProjects(user.id);
      return projectsRes.content;
    } catch (error) {
      return null;
    }
  };

  const fetchExtension = async () => {
    try {
      const { data: extensionRes } = await extensionApis.getExtension(
        extensionId
      );
      setExtension(extensionRes);
    } catch (error) {
      handleErrors(history, error, showToast);
    }
  };

  const loadPage = async () => {
    setLoading(true);
    await fetchExtension();
    setLoading(false);
  };

  useEffect(() => {
    loadPage();
    // eslint-disable-next-line
  }, [user]);

  const handleSubscribe = async (projectId) => {
    try {
      const { data: subExtensionRes } = await projectApis.getSubsExtensions(
        projectId
      );
      const alreadySubbed = subExtensionRes.some(
        (sub) => sub.extensionId === extension.id && sub.status === 'ACTIVE'
      );

      if (alreadySubbed) {
        showToast(
          TOAST_TYPE.INFO,
          t('extensionInUse', { ns: 'notifications' })
        );
      } else {
        await projectApis.subscribeExtension(projectId, {
          extensionId: extensionId,
          status: 'ACTIVE',
        });
        showToast(
          TOAST_TYPE.SUCCESS,
          t('addToProjectSuccess', {
            ns: 'notifications',
            text: extension.name,
          })
        );
      }
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('addToProjectError', { ns: 'notifications', text: extension.name })
      );
    }
  };

  return loading ? (
    <Loading />
  ) : (
    extension && (
      <Container>
        <Breadcrumbs extensionName={extension?.name} />
        <ExtensionDetails
          extension={extension}
          fetchProjects={fetchProjects}
          onSubscribe={handleSubscribe}
        />
      </Container>
    )
  );
};

export default ExtensionDetailsPage;
