import { useEffect, useState } from 'react';

import { Box, Container, useMediaQuery } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useParams, Link as RouterLink, useHistory } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { modelApis } from '../../../apis/modelApis';
import { projectApis } from '../../../apis/projectApis';
import { serverApis } from '../../../apis/serverApis';
import { solrApis } from '../../../apis/solrApis';
import Button from '../../../components/elements/button/Button';
import Loading from '../../../components/elements/loading/Loading';
import Text from '../../../components/elements/text/Text';
import Title from '../../../components/elements/title/Title';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import List from '../../../components/sections/list/List';
import Overview from '../../../components/sections/overview/Overview';
import { useUser } from '../../../contexts/UserContext';
import { handleErrors } from '../../../helpers';
import useToast from '../../../hooks/useToast';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import { mapSolrStatusToInstance, sentenceCase } from '../../../utils/utils';

// Page
const ProjectOverviewPage = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { projectId } = useParams();
  const { user } = useUser();
  const { showToast } = useToast();

  const [project, setProject] = useState(null);
  const [instances, setInstances] = useState(null);
  const [gps, setGps] = useState(null);
  const [models, setModels] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requestError, setRequestError] = useState(false);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [projectRes, instancesRes, gpsRes, modelRes] = await requestAll([
          projectApis.getProject(projectId),
          solrApis.getInstances(projectId),
          serverApis.getServers(projectId),
          modelApis.getModels(projectId),
        ]);

        const instancesWithSolrRes = await Promise.all(
          instancesRes.data.content.map(async (instance) => {
            try {
              const { data: solrStatus } = await solrApis.getSolrStatus(
                projectId,
                instance.id
              );
              const solrWithStatus = mapSolrStatusToInstance(
                instance,
                solrStatus
              );
              return solrWithStatus;
            } catch (error) {
              return { ...instance, solrRunning: [] };
            }
          })
        );

        setProject(projectRes.data);
        setInstances(instancesWithSolrRes);
        setGps(gpsRes.data.content);
        setModels(modelRes.data.content);
        setLoading(false);
      } catch (error) {
        handleErrors(history, error, showToast);
        setLoading(false);
        setRequestError(true);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [projectId]);

  const newInstanceButton = (
    <Button
      id="new-instance"
      fullWidth={isSmallScreen}
      component={RouterLink}
      to={buildPath({
        path: PRIVATE_ROUTE.INSTANCE_NEW,
        params: { projectId: projectId },
      })}
      variant="contained"
      color="primary"
    >
      {t('createInstance', { ns: 'buttons' })}
    </Button>
  );

  return (
    <Container>
      {loading && <Loading />}
      {!loading && requestError && (
        <Box mt={2}>
          <Alert severity="error">
            {sentenceCase(
              t('loading', {
                ns: 'errors',
                content: t('projectInfo', { ns: 'project' }),
              })
            )}
          </Alert>
        </Box>
      )}
      {!loading && project && instances && (
        <>
          <Breadcrumbs projectName={project.name} />
          <Overview
            isProject
            data={{
              ...project,
              label: t('projectId', { ns: 'project' }),
            }}
          >
            {user?.isAdmin?.(projectId) && newInstanceButton}
          </Overview>
          <Text>{project.description}</Text>
          <Title subTitle text={t('instances', { ns: 'instance' })} />
          {instances && <List isInstanceList data={instances} />}
          {gps.length > 0 && (
            <section>
              <Title subTitle text={t('generalServer', { ns: 'project' })} />
              <List isGpsList data={gps} />
            </section>
          )}
          {models.length > 0 && (
            <section>
              <Title subTitle text={t('models', { ns: 'model' })} />
              <List isModelList data={models} />
            </section>
          )}
        </>
      )}
    </Container>
  );
};
export default ProjectOverviewPage;
