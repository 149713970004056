import { makeStyles } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { TASK_STATUS } from '../../../constants/serverConstants';

export const useStyles = makeStyles((theme) => ({
  success: {
    fontWeight: 'bold',
    marginLeft: '0.25em',
    color: theme.palette.success.main,
  },
  failed: {
    fontWeight: 'bold',
    marginLeft: '0.25em',
    color: theme.palette.error.main,
  },
  running: {
    fontWeight: 'bold',
    marginLeft: '0.25em',
    color: theme.palette.info.main,
  },
  spin: {
    animation: `$spinAnim linear 3s infinite`,
  },
  '@keyframes spinAnim': {
    [`0%`]: { transform: 'rotate(0deg)' },
    [`100%`]: { transform: 'rotate(359deg)' },
  },
}));

export const statusColor = (status, classes) =>
  status === TASK_STATUS.COMPLETED
    ? classes.success
    : status === TASK_STATUS.FAILED
    ? classes.failed
    : status === TASK_STATUS.RUNNING
    ? classes.running
    : '';

export const statusIcon = (status, classes) =>
  status === TASK_STATUS.COMPLETED ? (
    <CheckCircleOutlineIcon
      fontSize="small"
      className={statusColor(status, classes)}
    />
  ) : status === TASK_STATUS.FAILED ? (
    <ErrorOutlineIcon
      fontSize="small"
      className={statusColor(status, classes)}
    />
  ) : status === TASK_STATUS.RUNNING ? (
    <AutorenewIcon
      fontSize="small"
      className={`${statusColor(status, classes)} ${classes.spin}`}
    />
  ) : (
    ''
  );
