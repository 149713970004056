import { useEffect, useState } from 'react';

import { Box, Container, Switch } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { healthCheckApis } from '../../../apis/healthCheckApis';
import { projectApis } from '../../../apis/projectApis';
import Loading from '../../../components/elements/loading/Loading';
import Title from '../../../components/elements/title/Title';
import HealthCheckForm from '../../../components/page-sections/health-check/HealthCheckForm';
import InstanceStatus from '../../../components/page-sections/health-check/InstanceStatus';
import { NOTIFICATION_TYPES } from '../../../components/page-sections/instance-notification/constants';
import NotificationButton from '../../../components/page-sections/instance-notification/NotificationButton';
import NotificationTable from '../../../components/page-sections/instance-notification/NotificationTable';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import NoContent from '../../../components/sections/no-content/NoContent';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { INSTANCE_CATEGORY } from '../../../constants';
import { useSolrInstance } from '../../../contexts/SolrContext';
import { useUser } from '../../../contexts/UserContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { PRIVATE_ROUTE } from '../../../routes/routes';
import { processHealthCheckResponse, sentenceCase } from '../../../utils/utils';

const InstanceHealthCheckPage = () => {
  const { projectId, instanceId } = useParams();
  const { instance } = useSolrInstance();
  const history = useHistory();
  const { showToast } = useToast();
  const { user } = useUser();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [monitor, setMonitor] = useState(false);
  const [settings, setSettings] = useState(null);
  const [healthId, setHealthId] = useState(null);
  const [healthCheck, setHealthCheck] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [projectMembers, setProjectMembers] = useState([]);

  const fetchProject = async () => {
    try {
      const { data: project } = await projectApis.getProject(projectId);
      setProject(project);
    } catch (error) {
      handleErrors(history, error, showToast);
    }
  };

  const fetchHealthCheck = async () => {
    try {
      const { data: healthCheckRes } = await healthCheckApis.getHealthCheck(
        projectId,
        instanceId
      );

      const formattedHealthCheck = processHealthCheckResponse(
        healthCheckRes.content[0]
      );

      setHealthId(formattedHealthCheck.id);
      setSettings(formattedHealthCheck.settings);
      setHealthCheck(formattedHealthCheck.status);
      setMonitor(formattedHealthCheck.monitor);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('fetch', {
            ns: 'errors',
            content: t('healthCheck', { ns: 'instance' }),
          })
        )
      );
    }
  };

  const fetchNotifications = async (page) => {
    if (!healthId) return;
    try {
      const { data: notifRes } = await healthCheckApis.getNotifications(
        projectId,
        instanceId,
        healthId,
        page
      );
      const content = notifRes.content;
      setNotifications(content);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('fetch', {
            ns: 'errors',
            content: t('notification', { ns: 'instance' }),
          })
        )
      );
    }
  };

  const fetchMembers = async () => {
    try {
      const { data: projectMemRes } = await projectApis.getProjectMembers(
        projectId
      );
      setProjectMembers(projectMemRes.content);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('fetch', {
            ns: 'errors',
            content: t('monitor.projectMember', { ns: 'fields' }),
          })
        )
      );
    }
  };

  const handlePageLoad = async () => {
    setLoading(true);
    await fetchProject();
    await fetchHealthCheck();
    setLoading(false);
    fetchMembers();
  };

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line
  }, [healthId]);

  useEffect(() => {
    if (!user) return;
    handlePageLoad();
    // Cancel effect is community instance.
    return () => {
      setProject(null);
      setHealthId(null);
      setSettings(null);
      setHealthCheck(null);
      setMonitor(null);
      setNotifications([]);
    };
    // eslint-disable-next-line
  }, [user]);

  const handleEmailSubmit = async (payload) => {
    try {
      await healthCheckApis.createEmailNotif(
        projectId,
        instanceId,
        healthId,
        payload
      );
      await fetchNotifications();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('createSuccess', {
          ns: 'notifications',
          text: t('notification', { ns: 'instance' }),
        })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('createError', {
          ns: 'notifications',
          text: t('notification', { ns: 'instance' }),
        })
      );
    }
  };

  const handleWebhookSubmit = async (payload) => {
    try {
      await healthCheckApis.createWebhookNotif(
        projectId,
        instanceId,
        healthId,
        payload
      );
      await fetchNotifications();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('createSuccess', {
          ns: 'notifications',
          text: t('notification', { ns: 'instance' }),
        })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('createError', {
          ns: 'notifications',
          text: t('notification', { ns: 'instance' }),
        })
      );
    }
  };

  const handleTestNotificationSubmit = async (data) => {
    if (data.type === NOTIFICATION_TYPES.EMAIL) {
      try {
        await healthCheckApis.sendTestEmail(
          projectId,
          instanceId,
          healthId,
          data
        );
        showToast(
          TOAST_TYPE.SUCCESS,
          t('testEmailSendSuccess', { ns: 'notifications' })
        );
      } catch (error) {
        showToast(
          TOAST_TYPE.ERROR,
          t('testEmailSendError', { ns: 'notifications' })
        );
      }
    } else if (data.type === NOTIFICATION_TYPES.WEBHOOK) {
      try {
        await healthCheckApis.sendTestWebhook(
          projectId,
          instanceId,
          healthId,
          data
        );
        showToast(
          TOAST_TYPE.SUCCESS,
          t('testWebhookSendSuccess', { ns: 'notifications' })
        );
      } catch (error) {
        showToast(
          TOAST_TYPE.ERROR,
          t('testWebhookSendError', { ns: 'notifications' })
        );
      }
    }
  };

  const handleStatusCheck = async () => {
    try {
      const { data: healthCheckRes } = await healthCheckApis.executeHealthCheck(
        projectId,
        instanceId,
        healthId
      );
      const formattedHealthCheck = processHealthCheckResponse(healthCheckRes);

      setHealthId(formattedHealthCheck.id);
      setSettings(formattedHealthCheck.settings);
      setHealthCheck(formattedHealthCheck.status);
      setMonitor(formattedHealthCheck.monitor);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('fetchHealthCheckStatusError', { ns: 'notifications' })
      );
    }
  };

  const handleDeleteNotification = async (id) => {
    try {
      await healthCheckApis.deleteNotif(projectId, instanceId, healthId, id);
      await fetchNotifications();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('deleteSuccess', {
          ns: 'notifications',
          text: t('notification', { ns: 'instance' }),
        })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('deleteError', {
          ns: 'notifications',
          text: t('notification', { ns: 'instance' }),
        })
      );
    }
  };

  const handleEditNotification = async (id, payload) => {
    try {
      if (payload.type === NOTIFICATION_TYPES.EMAIL)
        await healthCheckApis.editEmailNotif(
          projectId,
          instanceId,
          healthId,
          id,
          payload
        );
      if (payload.type === NOTIFICATION_TYPES.WEBHOOK)
        await healthCheckApis.editWebhookNotif(
          projectId,
          instanceId,
          healthId,
          id,
          payload
        );
      await fetchNotifications();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('updateSuccess', {
          ns: 'notifications',
          text: t('notification', { ns: 'instance' }),
        })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('updateError', {
          ns: 'notifications',
          text: t('notification', { ns: 'instance' }),
        })
      );
    }
  };

  useEffect(() => {
    if (!monitor || !settings) return;
    // checkInterval is in minutes. Convert to milliseconds here
    const intervalRefresh = settings.checkInterval * 60000;
    const interval = setInterval(() => {
      handleStatusCheck();
    }, intervalRefresh);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [monitor]);

  const enableHealthCheck = async () => {
    try {
      await healthCheckApis.enableHealthCheck(projectId, instanceId, healthId);
      setMonitor(true);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('enableHealthCheckError', { ns: 'notifications' })
      );
    }
  };

  const disableHealthCheck = async () => {
    try {
      await healthCheckApis.disableHealthCheck(projectId, instanceId, healthId);
      setMonitor(false);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('disableHealthCheckError', { ns: 'notifications' })
      );
    }
  };

  const handleHealthCheckToggle = () => {
    if (monitor) {
      disableHealthCheck();
    } else {
      // The user needs to save some settings first to enable/disable/execute/etc the monitoring functionality
      if (!settings) {
        showToast(
          TOAST_TYPE.WARNING,
          t('enableHealthCheckWarning', { ns: 'notifications' })
        );
        return;
      }

      enableHealthCheck();
    }
  };

  const handleSaveSettings = async (data) => {
    const body = {
      period: data.checkInterval,
      notificationPeriod: data.renoticeInterval,
      type: data.checkMethod,
    };

    if (healthId) {
      try {
        const { data: updateRes } = await healthCheckApis.updateHealthCheck(
          projectId,
          instanceId,
          healthId,
          body
        );

        const formattedHealthCheck = processHealthCheckResponse(updateRes);

        setHealthId(formattedHealthCheck.id);
        setSettings(formattedHealthCheck.settings);
        setHealthCheck(formattedHealthCheck.status);
        showToast(
          TOAST_TYPE.SUCCESS,
          t('updateSuccess', {
            ns: 'notifications',
            text: t('monitorSettings', { ns: 'instance' }),
          })
        );
      } catch (error) {
        showToast(
          TOAST_TYPE.ERROR,
          t('updateHealthCheckSettingsError', { ns: 'notifications' })
        );
      }
    } else {
      try {
        await healthCheckApis.createHealthCheck(projectId, instanceId, body);
        await fetchHealthCheck();
        showToast(
          TOAST_TYPE.SUCCESS,
          t('createSuccess', {
            ns: 'notifications',
            text: t('monitorSettings', { ns: 'instance' }),
          })
        );
      } catch (error) {
        showToast(
          TOAST_TYPE.ERROR,
          t('createHealthCheckSettingsError', { ns: 'notifications' })
        );
      }
    }
  };

  useEffect(() => {
    if (instance?.category === INSTANCE_CATEGORY.COMMUNITY) {
      history.replace(PRIVATE_ROUTE.ERROR403);
    }
    // eslint-disable-next-line
  }, [instance]);

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <Breadcrumbs projectName={project?.name} instanceName={instance?.name} />
      <TitlePanel title={t('healthCheck', { ns: 'titles' })} />
      {user.isAdmin?.(projectId) && (
        <Box>
          {t('healthSwitch', { ns: 'instance' })}
          <Switch
            color="primary"
            checked={monitor}
            onChange={handleHealthCheckToggle}
          />
        </Box>
      )}
      <InstanceStatus
        monitor={monitor}
        status={healthCheck}
        onStatusCheck={handleStatusCheck}
      />
      {user.isAdmin?.(projectId) && (
        <HealthCheckForm
          healthSettings={settings}
          onSaveSettings={handleSaveSettings}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Title
          subTitle
          text={t('monitoring.notificationSettings', { ns: 'sectionTitles' })}
        />
        {user.isAdmin?.(projectId) && (
          <NotificationButton
            disabled={!monitor}
            projectMembers={projectMembers}
            onEmailSubmit={handleEmailSubmit}
            onWebhookSubmit={handleWebhookSubmit}
            onTestSubmit={handleTestNotificationSubmit}
          />
        )}
      </Box>
      {isEmpty(notifications) ? (
        <NoContent title={t('noNotifications', { ns: 'descriptions' })} />
      ) : (
        <NotificationTable
          data={notifications}
          projectMembers={projectMembers}
          onTestSubmit={handleTestNotificationSubmit}
          onEdit={handleEditNotification}
          onDelete={handleDeleteNotification}
          isAdmin={user.isAdmin?.(projectId)}
        />
      )}
    </Container>
  );
};

export default InstanceHealthCheckPage;
