import { makeStyles } from '@material-ui/core';

import communityBanner from '../../../assets/community-banner.png';
import planBanner from '../../../assets/plan-banner.png';

export const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    backgroundImage: `url(${planBanner})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#0c63a6', // matches planBanner color
    },
  },
  communityCard: {
    height: '100%',
    backgroundImage: `url(${communityBanner})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#505050', // matches communityBanner color
    },
  },
  contentFeatureIcon: {
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      color: 'white',
    },
  },
  contentFeature: {
    fontSize: '0.85em',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      color: 'white',
    },
  },
  planPrice: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.25em',
  },
  radio: {
    '& .MuiFormControlLabel-root': {
      display: 'block',
      position: 'relative',
      width: '100%',
      margin: 0,
      alignItems: 'stretch',
    },
    '& .MuiRadio-root': {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  subdomain: {
    position: 'absolute',
    right: 0,
    height: theme.spacing(4),
    top: theme.spacing(2.5),
    transform: 'translateY(-50%)',
  },
  taxText: {
    color: 'white',
    fontSize: '0.75em',
  },
}));
