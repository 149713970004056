import Container from '@material-ui/core/Container';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

import { userApis } from '../../../apis/userApis';
import Loading from '../../../components/elements/loading/Loading';
import UserProfile from '../../../components/page-sections/user-profile/UserProfile';
import { useUser } from '../../../contexts/UserContext';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { sentenceCase, updateObjectValues } from '../../../utils/utils';

const userSettingsChanged = (currentData, newData) =>
  currentData.firstName !== newData.firstName ||
  currentData.lastName !== newData.lastName ||
  currentData.email !== newData.email;

const UserProfilePage = () => {
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const { user, refreshUser } = useUser();
  const { showToast } = useToast();

  const updateUserPreferences = async (type, value) => {
    const {
      firstName,
      lastName,
      email,
      locale,
      authorDisplay,
      newsSubscription,
      timeZone,
    } = user;

    const userPreferences = { [type]: value };
    const payload = {
      firstName,
      lastName,
      email,
      locale,
      authorDisplay,
      newsSubscription,
      timeZone,
      ...userPreferences,
    };

    try {
      await userApis.updateUserInfo(user.id, payload);
      await keycloak.updateToken(1800);
      await refreshUser();

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t(type, { ns: 'user' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t(type, { ns: 'user' }),
          })
        )
      );
    }
  };

  const handleUpdateUserImage = async (imageFile) => {
    try {
      const formData = new FormData();
      formData.append('image', imageFile);

      await userApis.updateUserIcon(user.id, formData);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('userImage', { ns: 'user' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('userImage', { ns: 'user' }),
          })
        )
      );
    }
  };

  const handleUpdateUser = async (data) => {
    try {
      await userApis.updateUserInfo(user.id, data);
      await keycloak.updateToken(1800);

      if (user.email !== data.email) {
        keycloak.login({ locale: i18n.language });
      }

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('userInfo', { ns: 'user' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('userInfo', { ns: 'user' }),
          })
        )
      );
    }
  };

  const userInfoSubmit = async (data) => {
    const {
      firstName,
      lastName,
      email,
      locale,
      authorDisplay,
      newsSubscription,
      timeZone,
    } = user;

    const { icon, ...restProps } = data;
    const initialUserData = {
      firstName,
      lastName,
      email,
      locale,
      authorDisplay,
      newsSubscription,
      timeZone,
    };

    const userPayload = updateObjectValues(initialUserData, restProps);

    if (data.icon) {
      await handleUpdateUserImage(data.icon);
    }

    if (userSettingsChanged(user, data)) {
      await handleUpdateUser(userPayload);
    }

    refreshUser();
  };

  const passwordReset = async () => {
    try {
      await userApis.resetPassword(user.id);

      showToast(
        TOAST_TYPE.SUCCESS,
        t('passwordResetSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('passwordResetError', { ns: 'notifications' })
      );
    }
  };

  const handleRemoveImage = async () => {
    try {
      await userApis.deleteUserIcon(user.id);
      await refreshUser();

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('userImage', { ns: 'user' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('avatar', { ns: 'fields' }),
          })
        )
      );
    }
  };

  return (
    <Container>
      {user ? (
        <UserProfile
          user={user}
          userInfoSubmit={userInfoSubmit}
          passwordReset={passwordReset}
          handleRemoveImage={handleRemoveImage}
          updateUserPreferences={updateUserPreferences}
        />
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default UserProfilePage;
