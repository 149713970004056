import { useMemo, useState } from 'react';

import {
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  ListSubheader,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TIMEZONE_LIST } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import Title from '../../elements/title/Title';

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 'calc(100% - 350px)',
    padding: 0,
    '& .MuiList-padding': {
      paddingTop: 0,
    },
  },
  header: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    background: theme.palette.common.white,
    paddingBottom: theme.spacing(2),
  },
  search: {
    backgroundColor: 'inherit',
    width: '96%',
  },
  select: {
    padding: 0,
  },
}));

const TimezoneForm = ({ onSubmit }) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);

  const { user } = useUser();
  const { t } = useTranslation();

  const handleTimezoneChange = async (e) => {
    const { name, value } = e.target;
    if (!value) {
      e.stopPropagation();
      return;
    }
    setOpen(false);
    await onSubmit(name, value);
  };

  const matchedOptions = useMemo(
    () =>
      TIMEZONE_LIST.filter((option) =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      ),
    [searchText]
  );

  const select = (
    <Select
      value={user?.timeZone}
      name="timeZone"
      inputProps={{ 'aria-label': 'timezone select' }}
      fullWidth
      onClick={() => setOpen(false)}
      onChange={handleTimezoneChange}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={(e) => {
        const isHeader = e.currentTarget
          .getAttribute('class')
          ?.includes('MuiListSubheader');
        if (!isHeader) {
          setOpen(false);
          setTimeout(() => setSearchText(''), 1);
        }
      }}
      className={classes.select}
      // Disable auto focus on MenuItems to allow TextField to be in focus
      // Set anchorOrigin to display menu in fix position
      MenuProps={{
        classes: { paper: classes.menuPaper },
        autoFocus: false,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      <ListSubheader className={classes.header}>
        <TextField
          size="small"
          variant="outlined"
          placeholder={t('searchTimezone', { ns: 'fields' })}
          autoFocus
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={classes.search}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </ListSubheader>
      {matchedOptions.map(({ label, zoneId }) => (
        <MenuItem key={zoneId} value={zoneId}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <>
      <Title subTitle text={t('timezoneSettings', { ns: 'sectionTitles' })} />

      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
          {select}
        </Grid>
      </Grid>
    </>
  );
};

TimezoneForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default TimezoneForm;
