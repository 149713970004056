import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getInitials, runningNodeExists } from '../../../utils/utils';
import CopyableText from '../../elements/copyable-text/CopyableText';
import Image from '../../elements/image/Image';
import Status from '../../elements/status/Status';
import Title from '../../elements/title/Title';
import InstanceChip from '../instance-chip/InstanceChip';
import ModelChip from '../instance-chip/ModelChip';

const useStyles = makeStyles((theme) => ({
  running: {
    margin: 2,
    animation: `$spinAnim linear 3s infinite`,
  },
  '@keyframes spinAnim': {
    [`0%`]: { transform: 'rotate(0deg)' },
    [`100%`]: { transform: 'rotate(359deg)' },
  },
  icon: {
    verticalAlign: 'bottom',
  },
  overviewContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  flowChange: {
    display: 'flex',
  },
  children: {
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
  },

  chip: {
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
    height: theme.spacing(2.5),
    '& .MuiChip-labelSmall': {
      paddingLeft: theme.spacing(0.6),
      paddingRight: theme.spacing(0.6),
    },
  },
}));

const Overview = ({
  data,
  isProject,
  isInstance,
  isGps,
  isModel,
  children,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { status = null } = data;

  const id = isProject
    ? 'project-id'
    : isInstance
    ? 'instance-id'
    : isGps
    ? 'gps-id'
    : isModel
    ? 'model-id'
    : '';

  const name = isProject
    ? 'project-name'
    : isInstance
    ? 'instance-name'
    : isGps
    ? 'gps-name'
    : isModel
    ? 'model-name'
    : '';

  const label = isProject
    ? t('projectId', { ns: 'project' })
    : isInstance
    ? t('instanceId', { ns: 'instance' })
    : isGps
    ? t('serverId', { ns: 'gps' })
    : isModel
    ? t('modelId', { ns: 'model' })
    : '';

  return (
    <Box className={classes.overviewContainer}>
      <Box display="flex" flexDirection="row">
        <Image
          src={data?.icon}
          alt="project avatar"
          shape="rounded"
          size="large"
        >
          {getInitials(data.name)}
        </Image>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          ml={1}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Title id={name} subTitle text={data.name} style={{ margin: 0 }} />
            {(isInstance || isModel) && (
              <>
                <Status
                  instanceState={status}
                  solrRunning={
                    isInstance ? runningNodeExists(data.instances) : true
                  }
                />

                {isInstance && (
                  <InstanceChip
                    plan={data.instances[0].type}
                    category={data.category}
                    ml={2}
                  />
                )}

                {isModel && <ModelChip category={data.category} ml={2} />}
              </>
            )}
          </Box>
          <CopyableText
            id={id}
            text={data.id}
            label={label}
            variant="outlined"
            upperCase
          />
        </Box>
      </Box>
      <Box className={classes.children}>{children}</Box>
    </Box>
  );
};

Overview.propTypes = {
  data: PropTypes.shape({
    category: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    instances: PropTypes.array,
    status: PropTypes.string,
    createDate: PropTypes.string,
  }),
  isProject: PropTypes.bool,
  isInstance: PropTypes.bool,
  isGps: PropTypes.bool,
  isModel: PropTypes.bool,
  children: PropTypes.node,
};

export default Overview;
