import { useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReplayIcon from '@material-ui/icons/Replay';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { PRIVATE_ROUTE, buildPath } from '../../../routes/routes';
import { isSolrCloud } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';
import SelectDropdown from '../../sections/select-dropdown/SelectDropdown';
import TitlePanel from '../../sections/title-panel/TitlePanel';

const ConfigHeader = ({
  instanceType,
  title,
  collections,
  onReload,
  onUpconfig,
  onCollectionChange,
}) => {
  const { t } = useTranslation();
  const { projectId, instanceId } = useParams();

  const [isUpconfigProcessing, setIsUpconfigProcessing] = useState(false);
  const [isReloadProcessing, setIsReloadProcessing] = useState(false);
  const [upconfigModal, setUpconfigModal] = useState(false);
  // delete ZooKeeper before upconfig flag
  const [deleteZk, setDeleteZk] = useState(true);

  const collectionsPage = buildPath({
    path: PRIVATE_ROUTE.INSTANCE_COLLECTIONS,
    params: { projectId, instanceId },
  });

  const toggleUpconfigModal = () => setUpconfigModal(!upconfigModal);

  const handleReload = async () => {
    setIsReloadProcessing(true);
    await onReload();
    setIsReloadProcessing(false);
  };

  const handleUpconfig = async () => {
    setIsUpconfigProcessing(true);
    toggleUpconfigModal();
    await onUpconfig(deleteZk);
    setIsUpconfigProcessing(false);
  };

  useEffect(() => {
    upconfigModal && setDeleteZk(true);
  }, [upconfigModal]);

  const handleCheck = (event) => setDeleteZk(event.target.checked);

  return (
    <section>
      <TitlePanel title={title} visible>
        {!isEmpty(collections) && (
          <Box display="grid" gridGap={5} gridTemplateColumns="auto auto">
            <Button
              startIcon={
                isReloadProcessing ? (
                  <CircularProgress size={20} />
                ) : (
                  <ReplayIcon fontSize="small" />
                )
              }
              aria-label="reload"
              onClick={handleReload}
              disabled={isReloadProcessing}
            >
              {t('reload', { ns: 'buttons' })}
            </Button>
            {isSolrCloud(instanceType) && (
              <Button
                startIcon={
                  isUpconfigProcessing ? (
                    <CircularProgress size={20} />
                  ) : (
                    <CloudUploadIcon fontSize="small" />
                  )
                }
                aria-label="upconfig"
                onClick={toggleUpconfigModal}
                disabled={isUpconfigProcessing}
              >
                {t('upconfig', { ns: 'buttons' })}
              </Button>
            )}
          </Box>
        )}
      </TitlePanel>
      {!isEmpty(collections) ? (
        <Box mb={2}>
          <SelectDropdown
            title={t('selected', {
              ns: 'instance',
              text: t('collection', { ns: 'instance' }),
            })}
            selectList={collections || []}
            onChange={onCollectionChange}
          />
        </Box>
      ) : (
        <Box mt={4} textAlign="center">
          <Text align="center">
            {t('configNoCollection', { ns: 'descriptions' })}
          </Text>
          <Text align="center">
            {t('configNoCollection2', { ns: 'descriptions' })}
          </Text>
          <Box mt={2}>
            <Button
              component={RouterLink}
              to={collectionsPage}
              variant="outlined"
            >
              {t('moveToCollection', { ns: 'buttons' })}
            </Button>
          </Box>
        </Box>
      )}
      <Modal
        openModal={upconfigModal}
        title={t('upconfigOptions', { ns: 'instance' })}
        handleClose={toggleUpconfigModal}
        handleConfirm={handleUpconfig}
        showActions
      >
        {deleteZk && (
          <Alert severity="info" style={{ marginBottom: '1em' }}>
            <Text bodyBold style={{ marginBottom: '0.5em' }}>
              {t('upconfigLogInfo', { ns: 'descriptions' })}
            </Text>
            <Text bodyBold>{t('clearSolrErrors', { ns: 'descriptions' })}</Text>
          </Alert>
        )}
        <FormControlLabel
          control={<Checkbox checked={deleteZk} onChange={handleCheck} />}
          label={t('deleteUpconfig', { ns: 'descriptions' })}
        />
      </Modal>
    </section>
  );
};

ConfigHeader.propTypes = {
  collections: PropTypes.array,
  instanceType: PropTypes.string,
  title: PropTypes.string.isRequired,
  onCollectionChange: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  onUpconfig: PropTypes.func.isRequired,
};

export default ConfigHeader;
