import { useRef, useState, useEffect } from 'react';

import Link from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import placeholder from '../../../assets/image-placeholder.svg';
import { sentenceCase } from '../../../utils/utils';
import Text from '../../elements/text/Text';
import Modal from '../modal/Modal';
import styles from './ImageUpload.module.scss';

const ImageUpload = ({
  name,
  control,
  limit,
  imageSrc,
  onRemoveImage,
  disabled,
  ...restProps
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const defaultImage = imageSrc || placeholder;

  const {
    field,
    fieldState: { error },
  } = useController({ control, name });

  const [preview, setPreview] = useState(null);
  const [open, setOpen] = useState(false);

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const fileInputRef = useRef();

  useEffect(() => {
    const reader = new FileReader();
    if (field.value) {
      reader.onloadend = () => {
        if (field.value.size > limit) return;
        setPreview(reader.result);
      };
      reader.readAsDataURL(field.value);
    }

    return () => reader.abort();
  }, [field.value, limit]);

  const handleInputClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    field.onChange(file);
    setPreview(file);
  };

  const handleConfirm = () => {
    handleModalClose();
    setPreview(null);
    onRemoveImage();
  };

  return (
    <div {...restProps}>
      {error && (
        <div className={styles.flexCenter}>
          <ErrorOutlineIcon color="error" fontSize="small" />
          <Text
            caption
            color="error"
            text={error.message}
            align="center"
            style={{ marginLeft: theme.spacing(1) }}
          />
        </div>
      )}
      <div className={styles.flexCenter}>
        <div className={styles.previewWrapper}>
          <img
            className={styles.preview}
            alt="avatar"
            src={preview || defaultImage}
          />
          {!disabled && (
            <div className={styles.overlay} onClick={handleInputClick}>
              {t('uploadImage', { ns: 'buttons' })}
            </div>
          )}
        </div>

        <input
          type="file"
          ref={fileInputRef}
          name={name}
          style={{ display: 'none' }}
          accept=".png, .jpg, .jpeg"
          aria-label="upload file"
          onChange={handleImageChange}
        />
      </div>

      {!disabled && imageSrc && onRemoveImage && (
        <div className={styles.flexCenter}>
          <Link
            href="#"
            color="inherit"
            variant="button"
            underline="hover"
            style={{ color: theme.palette.grey[700] }}
            onClick={handleModalOpen}
            aria-label="remove avatar"
          >
            {t('deleteImage', { ns: 'buttons' })}
          </Link>
          <Modal
            openModal={open}
            title={t('deletion', {
              ns: 'project',
              text: t('avatar', { ns: 'fields' }),
            })}
            handleConfirm={handleConfirm}
            handleClose={handleModalClose}
            showActions
          >
            <Text>
              {sentenceCase(
                t('deleteAction', {
                  ns: 'descriptions',
                  text: t('avatar', { ns: 'fields' }),
                })
              )}
            </Text>
            <Text>{t('delete', { ns: 'descriptions' })}</Text>
          </Modal>
        </div>
      )}
    </div>
  );
};

ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  limit: PropTypes.number.isRequired,
  imageSrc: PropTypes.string,
  onRemoveImage: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ImageUpload;
