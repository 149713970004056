import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getCardIcon } from '../../../utils/utils';
import Text from '../../elements/text/Text';

const useStyles = makeStyles({
  card: {
    textAlign: 'left',
    '& .MuiCardHeader-action': {
      alignSelf: 'center',
      marginTop: 0,
    },
  },
});

const CreditCard = ({ card }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const formatMonth = (month) => `0${month}`.slice(-2);

  return (
    <Card variant="outlined" style={{ marginBottom: 15 }}>
      <CardHeader
        className={classes.card}
        avatar={getCardIcon(card?.brand, 50)}
        title={
          <Text>{`${card?.brand} ${t('billing.creditCardEnding', {
            ns: 'project',
            number: card?.number,
          })}`}</Text>
        }
        subheader={
          <Text caption>
            {t('billing.creditCardExpiration', {
              ns: 'project',
              number: `${formatMonth(card?.expireMonth)}/${card?.expireYear}`,
            })}
          </Text>
        }
      />
    </Card>
  );
};

CreditCard.propTypes = {
  card: PropTypes.object,
};

export default CreditCard;
