import { useEffect, useState } from 'react';

import { Container, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { isInstanceRunning } from '../../../apis/constants';
import { instanceApis } from '../../../apis/instanceApis';
import { projectApis } from '../../../apis/projectApis';
import { serverApis } from '../../../apis/serverApis';
import Loading from '../../../components/elements/loading/Loading';
import Storage from '../../../components/elements/storage/Storage';
import InstanceDetails from '../../../components/page-sections/instance-details/InstanceDetails';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import Overview from '../../../components/sections/overview/Overview';
import { useUser } from '../../../contexts/UserContext';
import { getGpsSubdomain } from '../../../utils/utils';

const ServerOverviewPage = () => {
  const { projectId, gpsId } = useParams();
  const { user } = useUser();
  const { t } = useTranslation();

  const [project, setProject] = useState(null);
  const [gps, setGps] = useState(null);
  const [storage, setStorage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [{ data: projectRes }, { data: gpsRes }] = await requestAll([
        projectApis.getProject(projectId),
        serverApis.getServer(projectId, gpsId),
      ]);

      const projectData = {
        project: projectRes,
      };

      setGps(gpsRes);
      setProject(projectData);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchStorage = async () => {
    // Check to see if this is working on backend. If not, remove.
    const storageRes =
      isInstanceRunning(gps) &&
      (await instanceApis.getStorage(projectId, gpsId));

    setStorage(storageRes.data);
  };

  useEffect(() => {
    if (!gps) return;
    fetchStorage();
    // eslint-disable-next-line
  }, [gps]);

  return (
    <Container>
      {loading && <Loading style={{ marginTop: '1em' }} />}
      {error && (
        <Alert style={{ marginTop: '1em' }} severity="error">
          {t('fetchServerError', { ns: 'notifications' })}
        </Alert>
      )}
      {!loading && gps && (
        <section>
          <Breadcrumbs
            projectName={project.project.name}
            serverName={gps.name}
          />
          <Overview data={gps} isGps />
          <Grid container>
            <Grid item xs={12} md={9}>
              <InstanceDetails
                instance={gps}
                subdomainUrl={getGpsSubdomain(gps.subdomain)}
                isAdmin={user.isAdmin?.(projectId)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {storage && (
                <Storage
                  available={Number(storage.available)}
                  total={Number(storage.size)}
                />
              )}
            </Grid>
          </Grid>
        </section>
      )}
    </Container>
  );
};

export default ServerOverviewPage;
