import BackupIcon from '@material-ui/icons/Backup';
import BugReportIcon from '@material-ui/icons/BugReport';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PageviewIcon from '@material-ui/icons/Pageview';

export const TAX_RATE = 0.1;
// export const EBS_PRICE_PER_GB = 23.4;

export const INSTANCE_PLANS = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
};

export const INSTANCE_CATEGORY = {
  LEGACY: 'LEGACY',
  COMMUNITY: 'COMMUNITY',
  STANDARD: 'STANDARD',
  ENTERPRISE: 'ENTERPRISE',
};

export const PLAN_LIST = [
  {
    name: 'community',
    description: 'features.desc.community',
    type: INSTANCE_PLANS.SMALL,
    category: INSTANCE_CATEGORY.COMMUNITY,
    price: 0,
    storage: 314572800, // 300MB
    features: [
      {
        icon: <DescriptionOutlinedIcon />,
        content: 'features.docs.community',
      },
      {
        icon: <BackupIcon />,
        content: 'features.backup.community',
      },
    ],
  },
  {
    name: 'entry',
    description: 'features.desc.standard',
    type: INSTANCE_PLANS.SMALL,
    category: INSTANCE_CATEGORY.STANDARD,
    price: 10000,
    storage: 1073741824, // 1GB
    features: [
      {
        icon: <DescriptionOutlinedIcon />,
        content: 'features.docs.entry',
      },
      {
        icon: <BackupIcon />,
        content: 'features.backup.entry',
      },
      {
        icon: <PageviewIcon />,
        content: 'features.crawler.oneSeed',
      },
    ],
  },
  {
    name: 'pro',
    description: 'features.desc.standard',
    type: INSTANCE_PLANS.MEDIUM,
    category: INSTANCE_CATEGORY.STANDARD,
    price: 50000,
    storage: 10737418240, // 10 GB
    features: [
      {
        icon: <DescriptionOutlinedIcon />,
        content: 'features.docs.pro',
      },
      {
        icon: <BackupIcon />,
        content: 'features.backup.pro',
      },
      {
        icon: <PageviewIcon />,
        content: 'features.crawler.oneSeed',
      },
      {
        icon: <BugReportIcon />,
        content: 'features.virusScan',
      },
    ],
  },
  {
    name: 'elite',
    description: 'features.desc.standard',
    type: INSTANCE_PLANS.LARGE,
    category: INSTANCE_CATEGORY.STANDARD,
    price: 100000,
    storage: 42949672960, // 40GB
    features: [
      {
        icon: <DescriptionOutlinedIcon />,
        content: 'features.docs.elite',
      },
      {
        icon: <BackupIcon />,
        content: 'features.backup.elite',
      },
      {
        icon: <PageviewIcon />,
        content: 'features.crawler.oneSeed',
      },
      {
        icon: <BugReportIcon />,
        content: 'features.virusScan',
      },
    ],
  },
];
