import { Box, Link, makeStyles, Tooltip } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { INSTANCE_STATE } from '../../../apis/constants';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import CopyableText from '../../elements/copyable-text/CopyableText';
import Text from '../../elements/text/Text';

const useStyles = makeStyles((theme) => ({
  warningIcon: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.warning.main,
  },
}));

const CidrDetails = ({ isAdmin, instance, solrUrl }) => {
  const { t } = useTranslation();
  const { projectId, instanceId } = useParams();
  const classes = useStyles();

  const isPrivate = instance.cidrs.length < 1;
  const isBusy = instance.status === INSTANCE_STATE.PENDING;

  return (
    <Box mb={2}>
      {isPrivate ? (
        isAdmin ? (
          <Text caption>
            <Trans
              i18nKey="setCidrBeforeUrlAccess"
              ns="descriptions"
              components={[
                <Link
                  key="0"
                  component={RouterLink}
                  to={buildPath({
                    path: PRIVATE_ROUTE.INSTANCE_SETTINGS,
                    params: { projectId: projectId, instanceId: instanceId },
                  })}
                />,
              ]}
            />
          </Text>
        ) : (
          <Text caption>{t('cidrConfigRequired', { ns: 'descriptions' })}</Text>
        )
      ) : (
        <Box display="inline-block">
          <CopyableText
            id="solr-url"
            label={t('subdomainUrl', { ns: 'instance' })}
            text={solrUrl}
          />
          <Box display="flex" alignItems="center">
            <Text id="cidr-list" caption>
              {t('accessibleFrom', {
                ns: 'instance',
                text: instance.cidrs.map((cidr) => cidr.cidr).join(', '),
              })}
            </Text>
            {isBusy && (
              <Tooltip
                arrow
                title={t('instanceUpdate', { ns: 'descriptions' })}
              >
                <WarningRoundedIcon
                  className={classes.warningIcon}
                  fontSize="small"
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

CidrDetails.propTypes = {
  instance: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  solrUrl: PropTypes.string,
};

export default CidrDetails;
