import { ChonkyActions, FileHelper } from 'chonky';

import { formatDateTimeUTC } from '../../../utils/timeUtils';

export const japaneseI18n = (timeZone) => ({
  locale: 'ja',
  formatters: {
    formatFileModDate: (intl, file) => {
      const safeModDate = FileHelper.getModDate(file);
      if (safeModDate) {
        return `${formatDateTimeUTC(safeModDate, timeZone)}`;
      } else {
        return null;
      }
    },
  },
  messages: {
    'chonky.toolbar.searchPlaceholder': '検索',
    'chonky.toolbar.visibleFileCount': `{fileCount, plural,
      =0 {#個の項目}
      one {#個の項目}
      other {#個の項目}
    }`,
    'chonky.toolbar.selectedFileCount': `{fileCount, plural,
      =0 {}
      one {#個選択}
      other {#個選択}
    }`,
    'chonky.toolbar.hiddenFileCount': `{fileCount, plural,
      =0 {}
      one {#個}
      other {#個}
    }`,
    'chonky.fileList.nothingToShow': 'ファイルまたはフォルダーが存在しません',
    'chonky.contextMenu.browserMenuShortcut': 'ブラウザメニュー: {shortcut}',

    // File action translation strings. These depend on which actions you have enabled in Chonky.
    [`chonky.actionGroups.Actions`]: 'アクション',
    [`chonky.actionGroups.Options`]: 'オプション',
    [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]:
      '親フォルダを開く',
    [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]:
      '新規フォルダ',
    [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]:
      '新規フォルダ',
    [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.name`]:
      'アップロード',
    [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.tooltip`]:
      'アップロード',
    [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]:
      '選択されたファイルを開く',
    [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]:
      'すべてのファイルを選択',
    [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]:
      'すべての選択を解除',
    [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]:
      'リストビュー',
    [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]:
      'フォルダビュー',
    [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]:
      '名前を昇順に表示',
    [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]:
      'サイズを昇順に表示',
    [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]:
      '時間を昇順に表示',
    [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]:
      '隠しファイルの表示',
    [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]:
      'フォルダが先頭に並び替え',

    // Custom actions
    'chonky.actions.edit.button.name': '編集',
    'chonky.actions.rename.button.name': '名前の変更',
    'chonky.actions.download.button.name': 'ダウンロード',
    'chonky.actions.remove.button.name': '削除',
    'chonky.actions.open_folder.button.name': 'フォルダを開く',
  },
  timeZone: timeZone,
});

export const englishI18n = (timeZone) => ({
  locale: 'en',
  formatters: {
    formatFileModDate: (intl, file) => {
      const safeModDate = FileHelper.getModDate(file);
      if (safeModDate) {
        return `${formatDateTimeUTC(safeModDate, timeZone)}`;
      } else {
        return null;
      }
    },
  },
  messages: {
    'chonky.toolbar.searchPlaceholder': 'Search',
    'chonky.toolbar.visibleFileCount': `{fileCount, plural,
      =0 {# Item}
      one {# Item}
      other {# Items}
    }`,
    'chonky.toolbar.selectedFileCount': `{fileCount, plural,
      =0 {}
      one {# Selected Item}
      other {# Selected Items}
    }`,
    'chonky.toolbar.hiddenFileCount': `{fileCount, plural,
      =0 {}
      one {# Item}
      other {# Items}
    }`,
    'chonky.fileList.nothingToShow': 'File or folder does not exist',
    'chonky.contextMenu.browserMenuShortcut': 'Browser menu: {shortcut}',

    // File action translation strings. These depend on which actions you have enabled in Chonky.
    [`chonky.actionGroups.Actions`]: 'Actions',
    [`chonky.actionGroups.Options`]: 'Options',
    [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]:
      'Open parent folder',
    [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]:
      'New folder',
    [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]:
      'New folder',
    [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.name`]: 'Upload',
    [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.tooltip`]: 'Upload',
    [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]:
      'Open selected file',
    [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]:
      'Select all files',
    [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]:
      'Deselect all',
    [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]:
      'List view',
    [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]:
      'フォルダビュー',
    [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]:
      'Sort by name',
    [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]:
      'Sort by file size',
    [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]:
      'Sort by date',
    [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]:
      'Toggle hidden files',
    [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]:
      'Show folders at the top',

    // Custom actions
    'chonky.actions.edit.button.name': 'Edit',
    'chonky.actions.rename.button.name': 'Rename',
    'chonky.actions.download.button.name': 'Download',
    'chonky.actions.remove.button.name': 'Delete',
    'chonky.actions.open_folder.button.name': 'Open folder',
  },
  timeZone: timeZone,
});
