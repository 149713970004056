import { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { find } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { backupApis } from '../../../apis/backupApis';
import { collectionApis } from '../../../apis/collectionApis';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import CreateBackupButtons from '../../../components/page-sections/backup-form/CreateBackupButtons';
import BackupListTable from '../../../components/page-sections/backup-restore/backup-table/BackupListTable';
import ScheduledBackupTable from '../../../components/page-sections/backup-restore/scheduled-backups/ScheduledBackupTable';
import S3BarChart from '../../../components/page-sections/s3-barchart/S3BarChart';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import {
  PERIOD_TYPES,
  BACKUP_PERIOD_TYPE_LABELS,
  BACKUP_TYPES,
  DAYS,
  DEFAULT_SUBSCRIPT_NUMBER,
} from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { getTime, nth } from '../../../utils/timeUtils';
import {
  createAndDownloadFile,
  isSolrCloud,
  sentenceCase,
} from '../../../utils/utils';

const ProjectBackupPage = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams();
  const { showToast } = useToast();
  const { user } = useUser();

  const [loading, setLoading] = useState(true);
  const [backupList, setBackupList] = useState(null);
  const [scheduledBackups, setScheduleBackups] = useState(null);
  const [project, setProject] = useState(null);
  const [tab, setTab] = useState('0');

  const handleTabChange = (e, tabValue) => setTab(tabValue);

  const getPeriodDay = (period, form) => {
    if (period === PERIOD_TYPES.WEEK) return form.day;
    if (period === PERIOD_TYPES.MONTH) return form.date;
    return '';
  };

  const getScheduledDay = (periodType, periodDay) => {
    if (periodType === PERIOD_TYPES.WEEK) {
      const label = find(DAYS, { value: periodDay }).label;
      return i18n.language === 'ja'
        ? t(label, { ns: 'menus' })
        : `on ${t(label, { ns: 'menus' })}`;
    }

    if (periodType === PERIOD_TYPES.MONTH) {
      return i18n.language === 'ja'
        ? `${periodDay}${t('day', { ns: 'general' })}`
        : `on the ${nth(periodDay)}`;
    }

    return '';
  };

  const formatPeriodTime = (backup) => {
    const backupPeriod = find(BACKUP_PERIOD_TYPE_LABELS, {
      value: backup.periodType,
    });
    const time = getTime(backup.periodTime);
    const day = getScheduledDay(backup.periodType, backup.periodDay);

    return i18n.language === 'ja'
      ? `${t(backupPeriod.label, { ns: 'menus' })}${day} ${time}`
      : `${t(backupPeriod.label, { ns: 'menus' })} ${day} ${time}`;
  };

  const getProject = async () => {
    const { data: project } = await projectApis.getProject(projectId);
    setProject(project);
  };

  useEffect(() => {
    getProject();
    // eslint-disable-next-line
  }, []);

  const getBackups = async () => {
    const { data } = await backupApis.getBackups(projectId);
    setBackupList([...data]);
  };

  const getScheduledBackups = async () => {
    const { data: backups } = await projectApis.getScheduledBackups(projectId);
    const formattedBackups = backups.map((backup) => {
      const displayTime = formatPeriodTime(backup);
      return { ...backup, displayTime };
    });
    setScheduleBackups(formattedBackups);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!project) await getProject();
        if (tab === '0') await getBackups();
        if (tab === '1') await getScheduledBackups();
        if (loading) setLoading(false);
      } catch (error) {
        handleErrors(history, error, showToast);
      }
    };

    fetchData();

    const interval = setInterval(() => fetchData(), 20000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [tab]);

  const getInstances = async () => {
    const { data } = await solrApis.getInstances(projectId);
    return data.content;
  };

  const getInstanceNodes = async (instanceId) => {
    const { data: instance } = await solrApis.getInstance(
      projectId,
      instanceId
    );
    const instanceNodeList = instance.instances.map((item) => ({
      ...item,
      name: `${instance.name}-${item.subscriptNumber}`,
    }));
    return instanceNodeList;
  };

  const getCollections = async (instance) => {
    if (!instance) return;
    if (isSolrCloud(instance.type)) {
      const { data } = await collectionApis.getSolrCloudCollections(
        projectId,
        instance.id
      );
      return data.collections;
    } else {
      const { data } = await collectionApis.getCollections(
        projectId,
        instance.id
      );
      return Object.keys(data.status);
    }
  };

  const getStorage = async () => {
    const date = moment().format('YYYY-MM-DD');

    const { data: storageRes } = await projectApis.getS3Usage(projectId, date);

    const remaining =
      storageRes.quota - storageRes.current > 0
        ? storageRes.quota - storageRes.current
        : 0;

    const convertToGBAndRound = (value) =>
      Math.ceil((value / 1024 ** 3) * 100) / 100;

    const storageUsage = {
      quota: convertToGBAndRound(storageRes.quota),
      usage: [
        {
          name: t('dataFree', { ns: 'tooltips' }),
          value: convertToGBAndRound(remaining),
        },
        {
          name: t('dataInUse', { ns: 'tooltips' }),
          value: convertToGBAndRound(storageRes.current),
        },
        {
          name: t('dataAverage', { ns: 'tooltips' }),
          value: convertToGBAndRound(storageRes.average),
        },
      ],
    };

    return storageUsage;
  };

  const getPreview = async (form) => {
    const data = {
      instanceSetId: form.instance.id,
      instanceSubscript: form.subscript || DEFAULT_SUBSCRIPT_NUMBER,
      includeFiles: form.includeFiles,
      excludeFiles: form.excludeFiles || '',
    };

    try {
      const previewRes = await projectApis.previewFileList(projectId, data);

      return previewRes.data.trim();
    } catch (error) {
      return t('retrieveFilePreview', { ns: 'errors' });
    }
  };

  const handleBackup = async ({ backupType, ...form }) => {
    const { subject, instance } = form;

    const data = {
      subject,
      instanceSetId: instance.id,
      ...(backupType === BACKUP_TYPES.INDEX && {
        collection: form.collectionName,
      }),
      ...(backupType === BACKUP_TYPES.FILE && {
        instanceSubscript: form.subscript || DEFAULT_SUBSCRIPT_NUMBER,
      }),
      ...(backupType === BACKUP_TYPES.FILE && {
        includeFiles: form.includeFiles,
      }),
      ...(backupType === BACKUP_TYPES.FILE && {
        excludeFiles: form.excludeFiles,
      }),
    };

    try {
      await projectApis.createManualBackup(projectId, backupType, data);

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('preparing', {
            ns: 'notifications',
            text: t('backup', { ns: 'titles' }),
          })
        )
      );

      await getBackups();
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('createError', {
            ns: 'notifications',
            text: t('backup', { ns: 'titles' }),
          })
        )
      );
    }
  };

  const handleScheduleCreate = async ({ backupType, ...form }) => {
    const formatData = () => {
      const { subject, instance, periodType, historySize, periodTime } = form;

      return {
        subject,
        instanceSetId: instance.id,
        periodType,
        periodTime,
        periodZone: user?.timeZone,
        historySize,
        periodDay: getPeriodDay(periodType, form),
        ...(backupType === BACKUP_TYPES.INDEX && {
          collection: form.collectionName,
        }),
        ...(backupType === BACKUP_TYPES.FILE && {
          instanceSubscript: form.subscript || DEFAULT_SUBSCRIPT_NUMBER,
        }),
        ...(backupType === BACKUP_TYPES.FILE && {
          includeFiles: form.includeFiles,
        }),
        ...(backupType === BACKUP_TYPES.FILE && {
          excludeFiles: form.excludeFiles,
        }),
      };
    };

    try {
      await projectApis.createScheduledBackup(
        projectId,
        backupType,
        formatData()
      );

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('createSuccess', {
            ns: 'notifications',
            text: t('backup.scheduled', { ns: 'project' }),
          })
        )
      );

      await getScheduledBackups();
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('createError', {
            ns: 'notifications',
            text: t('backup.scheduled', { ns: 'project' }),
          })
        )
      );
    }
  };

  const handleScheduleEdit = async (form, schedule) => {
    const {
      subject,
      periodType,
      historySize,
      periodTime,
      backupType,
      updateTimeZone,
    } = form;

    const periodZone = updateTimeZone ? user?.timeZone : schedule.periodZone;

    const data = {
      subject,
      periodType,
      periodTime,
      historySize,
      category: backupType,
      periodDay: getPeriodDay(periodType, form),
      periodZone: periodZone,
      ...(form.includeFiles && { includeFiles: form.includeFiles }),
      ...(form.excludeFiles && { excludeFiles: form.excludeFiles }),
    };

    try {
      await projectApis.updateScheduledBackup(projectId, schedule.id, data);

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('backup.scheduled', { ns: 'project' }),
          })
        )
      );

      await getScheduledBackups();
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('backup.scheduled', { ns: 'project' }),
          })
        )
      );
    }
  };

  const handleScheduleDelete = async (scheduleId) => {
    try {
      await projectApis.deleteScheduledBackup(projectId, scheduleId);

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('backup.scheduled', { ns: 'project' }),
          })
        )
      );

      await getScheduledBackups();
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('backup.scheduled', { ns: 'project' }),
          })
        )
      );
    }
  };

  const handleBackupDelete = async (backup) => {
    try {
      await backupApis.deleteBackup(projectId, backup.id);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('preparingDelete', {
            ns: 'notifications',
            text: t('backup', { ns: 'titles' }),
          })
        )
      );

      await getBackups();
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('backup', { ns: 'titles' }),
          })
        )
      );
    }
  };

  // restore modal
  const handleRestore = async (backup, instance, collection, isFile) => {
    const bodyProp = isFile
      ? { overwrite: true }
      : { destinyCollection: collection };

    const bodyPayload = {
      instanceSetId: backup.instanceSetId,
      targetInstanceSetId: instance,
      targetInstanceSubscript: backup.instanceSubscript,
      ...bodyProp,
    };

    try {
      isFile
        ? await backupApis.restoreFileBackup(projectId, backup.id, bodyPayload)
        : await backupApis.restoreIndexBackup(
            projectId,
            backup.id,
            bodyPayload
          );

      showToast(
        TOAST_TYPE.SUCCESS,
        t('preparing', {
          ns: 'notifications',
          text: t('restore', { ns: 'titles' }),
        })
      );
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('restoreError', { ns: 'notifications' }));
    }
  };

  const handleDownload = async (backup) => {
    try {
      const { data: linkRes } = await backupApis.getBackupDownload(
        projectId,
        backup.id
      );
      createAndDownloadFile(backup.subject, linkRes);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('downloadSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('downloadError', { ns: 'notifications' }));
    }
  };

  const handleDownloadToAnotherDirectory = async (backup) => {
    const bodyPayload = {
      instanceSetId: backup.instanceSetId,
      targetInstanceSetId: backup.instanceSetId,
      instanceSubScript: backup.instanceSubscript,
      targetInstanceSubscript: backup.instanceSubscript,
      overwrite: false,
    };

    try {
      await backupApis.restoreFileBackup(projectId, backup.id, bodyPayload);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('preparing', {
          ns: 'notifications',
          text: t('backup.download', { ns: 'project' }),
        })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('backupDownloadError', { ns: 'notifications' })
      );
    }
  };

  return (
    <Container>
      <section>
        <Breadcrumbs projectName={project?.name} />
        <TitlePanel visible title={t('backup', { ns: 'titles' })}>
          <CreateBackupButtons
            handleCreate={handleScheduleCreate}
            getInstanceNodes={getInstanceNodes}
            handleBackup={handleBackup}
            getPreview={getPreview}
            getInstances={getInstances}
            getCollections={getCollections}
          />
        </TitlePanel>
        <TabContext value={tab}>
          <TabList
            onChange={handleTabChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label={t('backupList', { ns: 'sectionTitles' })} value="0" />
            <Tab
              label={t('scheduledBackup', { ns: 'sectionTitles' })}
              value="1"
            />
          </TabList>
          <TabPanel value="0">
            {loading ? (
              <Loading />
            ) : (
              <>
                <Box mb={2}>
                  <S3BarChart getStorage={getStorage} />
                </Box>
                <BackupListTable
                  backupList={backupList}
                  onRestore={handleRestore}
                  onDownload={handleDownload}
                  onDownloadTOD={handleDownloadToAnotherDirectory}
                  onDelete={handleBackupDelete}
                  getInstances={getInstances}
                  getCollections={getCollections}
                />
              </>
            )}
          </TabPanel>
          <TabPanel value="1">
            {loading ? (
              <Loading />
            ) : (
              <ScheduledBackupTable
                scheduledBackups={scheduledBackups}
                handleEdit={handleScheduleEdit}
                handleDelete={handleScheduleDelete}
                getPreview={getPreview}
              />
            )}
          </TabPanel>
        </TabContext>
      </section>
    </Container>
  );
};

export default ProjectBackupPage;
