export const COMMUNITY_LETTER = 'C';
export const DEFAULT_SUBNET_MASK = '/32';
export const DEFAULT_SUBSCRIPT_NUMBER = '1';
export const DEFAULT_SEARCH_ROW = 10;

export const CLUSTER_TYPES = {
  SOLR_CLUSTER: 'SOLR_CLUSTER',
  GPS_CLUSTER: 'GPS_CLUSTER',
  MODEL_CLUSTER: 'MODEL_CLUSTER',
};

export const INSTANCE_TYPES = {
  STANDALONE: 'STANDALONE',
  SOLRCLOUD: 'SOLRCLOUD',
};
export const INDEXING_FILE_SIZE = { BYTES: 2097152, MEGABYTES: 2 };

export const FILE_SIZE = { BYTES: 15728640, MEGABYTES: 15 };
export const MAX_FILE_NUMBER = 1;
export const MAX_FILE_MANAGER_NUMBER = 20;

export const FILE_MANAGER_UPLOAD_TYPE = {
  REG: 'REG',
  ZIP: 'ZIP',
};

export const MAX_CIDRS_LIMIT = 4;
