import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CRAWLER_STATUS } from '../../../../constants/crawlerConstants';
import Text from '../../../elements/text/Text';
import StatusCaption from '../../../sections/status-caption/StatusCaption';

const JobStatusDisplay = ({ status, progress, error }) => {
  const { t } = useTranslation();

  const errorMessage = error?.message || '';

  const progressTooltip = (progress) => (
    <>
      {Object.entries(progress).map((item) => (
        <Text key={item[0]} variant="caption" display="block">
          {t(`crawler.table.${item[0]}`, { ns: 'project' })}: {item[1]}
        </Text>
      ))}
      <Text style={{ marginTop: '1em', fontSize: '1em' }} display="block">
        {t('crawler.table.indexWarning', { ns: 'project' })}
      </Text>
    </>
  );

  switch (status) {
    case CRAWLER_STATUS.LOADING:
      return (
        <StatusCaption
          text={t('table.fetching', { ns: 'crawler' })}
          color="grey"
          icon={QueryBuilderIcon}
          aria-label="loading"
        />
      );
    case CRAWLER_STATUS.CREATING:
      return (
        <StatusCaption
          text={t('table.initialize', { ns: 'crawler' })}
          color="grey"
          icon={QueryBuilderIcon}
          aria-label="initializing"
        />
      );
    case CRAWLER_STATUS.FAILED_CREATING:
      return (
        <Tooltip title={errorMessage}>
          <StatusCaption
            text={t('table.initializeError', { ns: 'crawler' })}
            color="red"
            icon={ErrorIcon}
            aria-label="failed initializing"
            style={{ cursor: 'help' }}
          />
        </Tooltip>
      );
    case CRAWLER_STATUS.WAITING:
      return (
        <StatusCaption
          text={t('table.waiting', { ns: 'crawler' })}
          color="grey"
          icon={QueryBuilderIcon}
          aria-label="waiting"
        />
      );
    case CRAWLER_STATUS.RUNNING:
      return (
        <Tooltip title={progressTooltip(progress)} placement="bottom-start">
          <StatusCaption
            text={t('table.running', { ns: 'crawler' })}
            color="blue"
            icon={QueryBuilderIcon}
            aria-label="running"
          />
        </Tooltip>
      );
    case CRAWLER_STATUS.FAILED_RUNNING:
      return (
        <Tooltip title={errorMessage}>
          <StatusCaption
            text={t('table.runningError', { ns: 'crawler' })}
            color="red"
            icon={ErrorIcon}
            aria-label="failed running"
            style={{ cursor: 'help' }}
          />
        </Tooltip>
      );
    case CRAWLER_STATUS.UPDATING:
      return (
        <StatusCaption
          text={t('table.updating', { ns: 'crawler' })}
          color="gray"
          icon={QueryBuilderIcon}
          aria-label="updating"
        />
      );
    case CRAWLER_STATUS.FAILED_UPDATING:
      return (
        <Tooltip title={errorMessage}>
          <StatusCaption
            text={t('table.updateError', { ns: 'crawler' })}
            color="red"
            icon={ErrorIcon}
            aria-label="failed updating"
            style={{ cursor: 'help' }}
          />
        </Tooltip>
      );
    case CRAWLER_STATUS.DELETING:
      return (
        <StatusCaption
          text={t('table.deleting', { ns: 'crawler' })}
          color="grey"
          icon={DeleteIcon}
          aria-label="deleting"
        />
      );
    case CRAWLER_STATUS.FAILED_DELETING:
      return (
        <Tooltip title={errorMessage}>
          <StatusCaption
            text={t('table.deleteError', { ns: 'crawler' })}
            color="red"
            icon={ErrorIcon}
            aria-label="failed deleting"
            style={{ cursor: 'help' }}
          />
        </Tooltip>
      );
    case CRAWLER_STATUS.ABORTING:
      return (
        <StatusCaption
          text={t('table.abort', { ns: 'crawler' })}
          color="grey"
          icon={QueryBuilderIcon}
          aria-label="aborting"
        />
      );
    case CRAWLER_STATUS.FAILED_ABORTING:
      return (
        <Tooltip title={errorMessage}>
          <StatusCaption
            text={t('table.abortError', { ns: 'crawler' })}
            color="red"
            icon={ErrorIcon}
            aria-label="failed aborting"
            style={{ cursor: 'help' }}
          />
        </Tooltip>
      );
    default:
      return (
        <StatusCaption
          text={t('table.fetching', { ns: 'crawler' })}
          color="grey"
          icon={QueryBuilderIcon}
          aria-label="loading"
        />
      );
  }
};

JobStatusDisplay.propTypes = {
  status: PropTypes.string,
  progress: PropTypes.object,
  error: PropTypes.object,
};

export default JobStatusDisplay;
