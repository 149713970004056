import { Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {
  pink,
  green,
  cyan,
  purple,
  blueGrey,
  orange,
} from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  chip: {
    height: theme.spacing(3),
    marginBottom: 3,
  },
  solrPlugin: {
    borderColor: purple[500],
    color: purple[500],
  },
  solrDictionary: {
    borderColor: green[500],
    color: green[500],
  },
  solrCollectionConfig: {
    borderColor: cyan[700],
    color: cyan[700],
  },
  solrModel: {
    borderColor: orange[700],
    color: orange[700],
  },
  data: {
    borderColor: pink[500],
    color: pink[500],
  },
  misc: {
    borderColor: blueGrey[600],
    color: blueGrey[600],
  },
}));

const Badge = ({ label, color, ...restProps }) => {
  const classes = useStyles();

  return (
    <Chip
      variant="outlined"
      className={`${classes.chip} ${classes[color]}`}
      label={<Typography variant="caption">{label}</Typography>}
      {...restProps}
    />
  );
};

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default Badge;
