import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextDivider from '../../../elements/text-divider/TextDivider';

const AdditionalParamsFormSection = ({ parser, control, errors, style }) => {
  const { t } = useTranslation();

  const dismaxFields = ['alt', 'qf', 'mm', 'pf', 'ps', 'qs', 'tie', 'bq', 'bf'];
  const edismaxFields = ['uf', 'pf2', 'pf3', 'ps2', 'ps3', 'boost'];

  return (
    <>
      <TextDivider>
        {t('search.additionalParams', { ns: 'sectionTitles' })}
      </TextDivider>

      <Box className={style.inputContainer}>
        {dismaxFields.map((fieldName) => (
          <Box className={style.input} key={fieldName}>
            <Controller
              name={`settings.query.jsonQuery.params.${fieldName}`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t(`search.${fieldName}`, { ns: 'fields' })}
                  variant="outlined"
                  size="small"
                  fullWidth
                  inputProps={{ 'aria-label': fieldName }}
                  error={
                    !!errors?.settings?.query?.jsonQuery?.params?.[fieldName]
                  }
                  helperText={
                    errors?.settings?.query?.jsonQuery?.params?.[fieldName]
                      ?.message
                  }
                />
              )}
            />
          </Box>
        ))}
        {parser === 'edismax' &&
          edismaxFields.map((fieldName) => (
            <Box className={style.input} key={fieldName}>
              <Controller
                name={`settings.query.jsonQuery.params.${fieldName}`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t(`search.${fieldName}`, { ns: 'fields' })}
                    variant="outlined"
                    size="small"
                    fullWidth
                    inputProps={{ 'aria-label': fieldName }}
                    error={
                      !!errors?.settings?.query?.jsonQuery?.params?.[fieldName]
                    }
                    helperText={
                      errors?.settings?.query?.jsonQuery?.params?.[fieldName]
                        ?.message
                    }
                  />
                )}
              />
            </Box>
          ))}
      </Box>
    </>
  );
};

AdditionalParamsFormSection.propTypes = {
  parser: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default AdditionalParamsFormSection;
