import { useState } from 'react';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/shell/shell';

import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/edit/closebrackets';

import { UnControlled as CodeMirror } from '@leifandersen/react-codemirror2';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import './Editor.styles.scss';
import Button from '../../elements/button/Button';

const Editor = ({
  mode = 'txt',
  code,
  onSubmit,
  prevPage,
  readOnly = false,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [data, setData] = useState(code);
  const [isValid, setIsValid] = useState(true);

  const formatModeName = (mode) => {
    if (mode === 'sh') return 'shell';
    if (mode === 'js') return 'javascript';
    if (mode === 'json') return 'application/json';
    return mode;
  };

  const formatCode = (code) => {
    if (mode === 'json') return JSON.stringify(code, null, 4);
    return code;
  };

  const parseXML = (code) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(code, 'application/xml');
    const hasError = xmlDoc.getElementsByTagName('parsererror').length > 0;
    return !hasError;
  };

  const isValidJson = (code) => {
    try {
      JSON.parse(code);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleChange = (value) => {
    if (mode === 'xml') !parseXML(value) ? setIsValid(false) : setIsValid(true);
    if (mode === 'json')
      !isValidJson(value) ? setIsValid(false) : setIsValid(true);

    setData(value);
  };

  const goBack = () => history.push(prevPage);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(mode, data);
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit} aria-label={`${mode} editor`}>
        <CodeMirror
          value={formatCode(code)}
          options={{
            mode: formatModeName(mode),
            theme: 'material',
            lineNumbers: true,
            lineWrapping: true,
            smartIndent: true,
            matchBrackets: true,
            autoCloseTags: true,
            autoCloseBrackets: true,
            viewportMargin: Infinity,
            singleCursorHeightPerLine: false,
            readOnly: readOnly,
          }}
          onChange={(editor, data, value) => {
            handleChange(value);
          }}
        />
        <Box textAlign="center" my={2}>
          <Button
            type="button"
            variant="outlined"
            onClick={goBack}
            style={{ marginRight: 10 }}
            aria-label="go back"
          >
            {t('back', { ns: 'buttons' })}
          </Button>
          {!readOnly && (
            <Button
              type="submit"
              style={{ marginLeft: 10 }}
              aria-label="confirm"
              disabled={!isValid}
            >
              {t('save', { ns: 'buttons' })}
            </Button>
          )}
        </Box>
      </form>
    </Container>
  );
};

Editor.propTypes = {
  mode: PropTypes.string,
  code: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  prevPage: PropTypes.string.isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Editor;
