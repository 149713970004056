import { useEffect, useState } from 'react';

import { Box, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { antivirusApis } from '../../../apis/antivirusApis';
import { requestAll } from '../../../apis/config';
import { INSTANCE_STATE, isInstanceRunning } from '../../../apis/constants';
import { instanceApis } from '../../../apis/instanceApis';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import AntivirusSection from '../../../components/page-sections/antivirus/AntivirusSection';
import CidrAddressSection from '../../../components/page-sections/cidr-address/CidrAddressSection';
import InstanceForm from '../../../components/page-sections/instance-settings/InstanceForm';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import DeleteSection from '../../../components/sections/delete/DeleteSection';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { useSolrInstance } from '../../../contexts/SolrContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import {
  mapCidrs,
  sentenceCase,
  updateObjectValues,
} from '../../../utils/utils';

const instanceSettingsChanged = (currentData, newData) =>
  currentData.name !== newData.name ||
  currentData.description !== newData.description ||
  currentData.subdomain !== newData.subdomain;

// Page
const InstanceSettingsPage = () => {
  const history = useHistory();
  const { instance, refreshInstance } = useSolrInstance();
  const { projectId, instanceId } = useParams();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [antivirus, setAntivirus] = useState(null);
  const [requestError, setRequestError] = useState(false);

  const fetchData = async () => {
    try {
      const [{ data: projectRes }, { data: antivirusRes }] = await requestAll([
        projectApis.getProject(projectId),
        antivirusApis.getAntivirusSettings(projectId, instanceId),
      ]);

      setProject(projectRes);
      await refreshInstance();
      setAntivirus(antivirusRes.content[0]);
    } catch (error) {
      handleErrors(history, error, showToast);
      setRequestError(true);
    }
  };

  // component events
  const onCheckDuplicateSubdomainInstance = async (subdomain) => {
    if (subdomain === instance.subdomain) {
      return { isValid: true };
    }

    try {
      return { isValid: await instanceApis.checkSubdomain(subdomain) };
    } catch (e) {
      showToast(
        TOAST_TYPE.ERROR,
        t('unableToCheckSubdomain', { ns: 'notifications' })
      );

      return { isValid: false };
    }
  };

  const handleUpdateInstanceImage = async (imageFile) => {
    try {
      const formData = new FormData();
      formData.append('image', imageFile);

      await instanceApis.updateInstanceImage(projectId, instanceId, formData);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('instanceImage', { ns: 'instance' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('instanceImage', { ns: 'instance' }),
          })
        )
      );
    }
  };

  const handleCidrsUpdate = (cidrList) => {
    const formattedCidrList = { cidrs: cidrList };
    handleUpdateInstance(formattedCidrList);
  };

  const handleInstanceSettingsUpdate = async (settingsData) => {
    const instancePayload = formatInstanceData(settingsData);

    try {
      await solrApis.updateInstance(projectId, instanceId, instancePayload);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('instanceInfo', { ns: 'instance' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('instanceInfo', { ns: 'instance' }),
          })
        )
      );
    }
  };

  const formatInstanceData = (data) => {
    const { name, description, subdomain, cidrs, clusterType } = instance;

    const initialInstanceData = {
      name,
      description,
      subdomain,
      clusterType,
      cidrs: mapCidrs(cidrs),
    };

    const instancePayload = updateObjectValues(initialInstanceData, data);
    return instancePayload;
  };

  const handleUpdateInstance = async (data) => {
    const { name, description, subdomain, cidrs, clusterType } = instance;
    const { icon, ...restProps } = data;

    const initialInstanceData = {
      name,
      description,
      subdomain,
      clusterType,
      cidrs: mapCidrs(cidrs),
    };

    const settingsPayload = updateObjectValues(initialInstanceData, restProps);

    if (data.icon) {
      await handleUpdateInstanceImage(data.icon);
    }

    if (instanceSettingsChanged(instance, data)) {
      await handleInstanceSettingsUpdate(settingsPayload);
    }

    fetchData(projectId, instanceId);
  };

  const handleRemoveImage = async () => {
    try {
      await instanceApis.deleteInstanceImage(projectId, instanceId);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('deleteSuccess', {
          ns: 'notifications',
          text: t('instanceImage', { ns: 'instance' }),
        })
      );
      fetchData(projectId, instanceId);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('instanceImage', { ns: 'instance' }),
          })
        )
      );
    }
  };

  const onDeleteInstance = async () => {
    try {
      await solrApis.deleteSolrInstance(projectId, instanceId);

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('instance', { ns: 'instance' }),
          })
        )
      );
      const projectOverview = buildPath({
        path: PRIVATE_ROUTE.PROJECT_OVERVIEW,
        params: { projectId: projectId },
      });
      setTimeout(() => history.push(projectOverview), 800);
    } catch (errors) {
      const { data: errorRes } = errors.response;
      if (errorRes.status === 400) {
        showToast(
          TOAST_TYPE.ERROR,
          t('crawlJobRunningError', { ns: 'notifications' })
        );
      } else {
        showToast(
          TOAST_TYPE.ERROR,
          sentenceCase(
            t('deleteError', {
              ns: 'notifications',
              text: t('instance', { ns: 'instance' }),
            })
          )
        );
      }
    }
  };

  const handleAntivirusChange = async (setting) => {
    if (setting) {
      try {
        const updatedSettings = await antivirusApis.enableAntivirus(
          projectId,
          instanceId
        );
        setAntivirus(updatedSettings.data);
        showToast(
          TOAST_TYPE.SUCCESS,
          t('antivirusEnableSuccess', { ns: 'notifications' })
        );
      } catch (error) {
        showToast(
          TOAST_TYPE.ERROR,
          t('antivirusEnableError', { ns: 'notifications' })
        );
      }
    } else {
      try {
        const updatedSettings = await antivirusApis.disableAntivirus(
          projectId,
          instanceId,
          antivirus.id
        );
        setAntivirus(updatedSettings.data);
        showToast(
          TOAST_TYPE.SUCCESS,
          t('antivirusDisableSuccess', { ns: 'notifications' })
        );
      } catch (error) {
        showToast(
          TOAST_TYPE.ERROR,
          t('antivirusDisableError', { ns: 'notifications' })
        );
      }
    }
  };

  useEffect(() => {
    if (!projectId || !instanceId) return;
    setLoading(true);
    fetchData();
    setLoading(false);
    // eslint-disable-next-line
  }, [projectId, instanceId]);

  const fetchVirusScans = async (antivirusId) => {
    try {
      const { data: virusScanRes } = await antivirusApis.getVirusScanList(
        projectId,
        instanceId,
        antivirusId
      );
      return virusScanRes;
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('virusScanError', { ns: 'notifications' }));
      return [];
    }
  };

  return (
    <Container>
      {loading && <Loading />}

      {requestError && (
        <Box mt={2}>
          <Alert severity="error">
            {sentenceCase(
              t('loading', {
                ns: 'errors',
                content: t('settingsPage', {
                  ns: 'project',
                  text: t('instance', { ns: 'instance' }),
                }),
              })
            )}
          </Alert>
        </Box>
      )}

      {!loading && project && instance && (
        <>
          <Breadcrumbs
            projectName={project.name}
            instanceName={instance.name}
          />
          <TitlePanel title={t('settings', { ns: 'titles' })} />
          <InstanceForm
            data={instance}
            checkDuplicate={onCheckDuplicateSubdomainInstance}
            onSubmit={handleUpdateInstance}
            onRemoveImage={handleRemoveImage}
            disableSubdomainMessage={
              instance.status !== INSTANCE_STATE.RUNNING
                ? t('instanceIsBusy', { ns: 'descriptions' })
                : ''
            }
          />
          <CidrAddressSection
            cidrs={instance.cidrs}
            instanceState={instance.status}
            onUpdateCidrRange={handleCidrsUpdate}
          />
          <AntivirusSection
            antivirus={antivirus}
            instanceSize={instance?.instances[0]?.type}
            onAntivirusChange={handleAntivirusChange}
            fetchVirusScans={fetchVirusScans}
          />
          <DeleteSection
            title={t('instanceDeletion', { ns: 'sectionTitles' })}
            description={sentenceCase(
              t('deleteWarning', {
                ns: 'descriptions',
                text: t('instance', { ns: 'instance' }),
              })
            )}
            validation={instance.name}
            onDelete={onDeleteInstance}
            disableMessage={
              isInstanceRunning(instance)
                ? ''
                : t('instanceIsBusy', { ns: 'descriptions' })
            }
          />
        </>
      )}
    </Container>
  );
};

export default InstanceSettingsPage;
