import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import { imageApis } from '../../../apis/imageApis';
import Button from '../../../components/elements/button/Button';
import Loading from '../../../components/elements/loading/Loading';
import Gallery from '../../../components/page-sections/gallery/Gallery';
import GalleryForm from '../../../components/page-sections/gallery/GalleryForm';
import Modal from '../../../components/sections/modal/Modal';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { BACKEND_USER_ROLES } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { PRIVATE_ROUTE } from '../../../routes/routes';
import { mapBlobFormData } from '../../../utils/utils';

import 'react-medium-image-zoom/dist/styles.css';

const AdminImageAssetsPage = () => {
  const history = useHistory();
  const { user } = useUser();
  const { showToast } = useToast();

  const [images, setImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  // Open and close create modal
  const openCreateModal = () => setCreateModalOpen(true);
  const closeCreateModal = () => setCreateModalOpen(false);

  const fetchImages = async (id, shouldBust) => {
    try {
      const { data } = await imageApis.getImages();

      if (shouldBust && !isEmpty(data.content)) {
        // This is get around image caching issue
        const bust = Math.floor(Math.random() * 1000);
        const updatedData = data.content.map((img) => {
          return img.id === id
            ? { ...img, caching: `${img.url}?bust=${bust}` }
            : { ...img, caching: img.url };
        });

        setImages(updatedData);
      } else {
        const formattedData = data.content.map((img) => ({
          ...img,
          caching: img.url,
        }));
        setImages(formattedData);
      }
    } catch (error) {
      handleErrors(history, error, showToast);
      throw error;
    }
  };

  const getImageList = async () => {
    try {
      setIsLoading(true);
      await fetchImages();
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user) return;

    // Validate roles
    if (
      !user.roles.includes(BACKEND_USER_ROLES.PRODUCT_MANAGER) &&
      !user.roles.includes(BACKEND_USER_ROLES.DEVELOPER)
    ) {
      history.replace(PRIVATE_ROUTE.ERROR403);
    }

    getImageList();

    // eslint-disable-next-line
  }, [history, user]);

  const handleCreate = async (data) => {
    try {
      const formData = mapBlobFormData(data, 'info', {
        formKey: 'file',
        requestKey: 'file',
      });
      await imageApis.createImage(formData);
      await fetchImages();

      showToast(TOAST_TYPE.SUCCESS, 'イメージをアップロードしました。');
      closeCreateModal();
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, 'イメージのアップロードに失敗しました。');
    }
  };

  const handleEdit = async (id, data) => {
    try {
      const formData = mapBlobFormData(data, 'info', {
        formKey: 'file',
        requestKey: 'file',
      });
      await imageApis.updateImage(id, formData);
      await fetchImages(id, true);

      showToast(TOAST_TYPE.SUCCESS, 'イメージを更新しました。');
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, 'イメージの更新に失敗しました。');
    }
  };

  const handleDelete = async (id) => {
    try {
      await imageApis.deleteImage(id);
      await fetchImages();
      showToast(TOAST_TYPE.SUCCESS, 'イメージを削除しました。');
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, 'イメージの削除に失敗しました。');
    }
  };

  return (
    <Container>
      <Box mt={1}>
        <TitlePanel title={'イメージリスト'} visible>
          <Button onClick={openCreateModal}>イメージを追加する</Button>
        </TitlePanel>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        images && (
          <Gallery
            images={images}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        )
      )}

      {/* Create modal */}
      <Modal
        openModal={createModalOpen}
        title={'イメージの作成'}
        handleClose={closeCreateModal}
      >
        <GalleryForm
          handleCreate={handleCreate}
          handleModalClose={closeCreateModal}
        />
      </Modal>
    </Container>
  );
};

export default AdminImageAssetsPage;
