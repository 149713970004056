import { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import { isArray, truncate } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useSearch } from '../../../contexts/SearchContext';
import { sentenceCase } from '../../../utils/utils';
import Loading from '../../elements/loading/Loading';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.grey[50],
  },
}));

const FieldTable = ({ docKey }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    query,
    isLoadingStoredFields,
    storedFields,
    fetchStoredFields,
    clearStoreFields,
  } = useSearch();
  const { uniqueKey } = query;

  useEffect(() => {
    fetchStoredFields(`${uniqueKey}:"${docKey}"`);

    return () => clearStoreFields();
    // eslint-disable-next-line
  }, []);

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: 400, minWidth: 200, maxWidth: 500, padding: 0 }}
    >
      {isLoadingStoredFields ? (
        <Loading />
      ) : storedFields ? (
        <Table
          size="small"
          aria-label="field table"
          className={classes.paper}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('solrField', { ns: 'instance' })}</TableCell>
              <TableCell>{t('solrValue', { ns: 'instance' })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(storedFields).map((key) => (
              <TableRow key={key}>
                <TableCell align="left" style={{ minWidth: 80 }}>
                  {key}
                </TableCell>
                <TableCell align="left">
                  {isArray(storedFields[key])
                    ? truncate(storedFields[key].join('\n'), { length: 30 })
                    : storedFields[key].toString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Box m={2}>
          <Alert variant="outlined" severity="error">
            {sentenceCase(
              t('loading', {
                ns: 'errors',
                content: t('storedFields', { ns: 'instance' }),
              })
            )}
          </Alert>
        </Box>
      )}
    </TableContainer>
  );
};

FieldTable.propTypes = {
  docKey: PropTypes.string,
};

export default FieldTable;
