import { useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Modal from '../../sections/modal/Modal';
import ManualBackupForm from './ManualBackupForm';
import ScheduledBackupForm from './ScheduledBackupForm';

const useStyles = makeStyles((theme) => ({
  spaceRight: {
    marginRight: theme.spacing(2),
  },
}));

const CreateBackupButtons = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [openRegularBackupModal, setOpenRegularBackupModal] = useState(false);
  const [openManualBackupModal, setOpenManualBackupModal] = useState(false);

  const handleRegularBackupOpen = () => setOpenRegularBackupModal(true);
  const handleRegularBackupClose = () => setOpenRegularBackupModal(false);

  const handleManualBackupOpen = () => setOpenManualBackupModal(true);
  const handleManualBackupClose = () => setOpenManualBackupModal(false);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          startIcon={<AddIcon />}
          className={classes.spaceRight}
          onClick={handleRegularBackupOpen}
        >
          {t('add', {
            ns: 'buttons',
            text: t('backup.scheduled', { ns: 'project' }),
          })}
        </Button>
        <Button onClick={handleManualBackupOpen}>
          {t('take', {
            ns: 'buttons',
            text: t('backup.manual', { ns: 'project' }),
          })}
        </Button>
      </Box>

      <Modal
        openModal={openRegularBackupModal}
        title={t('backup.scheduled', { ns: 'project' })}
        handleClose={handleRegularBackupClose}
      >
        <ScheduledBackupForm
          {...props}
          handleModalClose={handleRegularBackupClose}
        />
      </Modal>

      <Modal
        openModal={openManualBackupModal}
        title={t('backup.manual', { ns: 'project' })}
        handleClose={handleManualBackupClose}
      >
        <ManualBackupForm
          {...props}
          handleModalClose={handleManualBackupClose}
        />
      </Modal>
    </>
  );
};

export default CreateBackupButtons;
