import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { grey } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useTranslation } from 'react-i18next';

import { keywords } from './utils';

const DragAndDropItems = () => {
  const { t } = useTranslation();

  const handleDragStart = (event, value) => {
    // removes background on drag in chrome and edge
    event.target.style.transform = 'translate(0, 0)';

    event.dataTransfer.setData('text/plain', value);
  };

  return (
    <Paper
      variant="outlined"
      style={{
        backgroundColor: grey[200],
        display: 'flex',
        padding: '0.3em',
        marginBottom: '0.5em',
      }}
    >
      <Tooltip
        title={t('buildNotification', { ns: 'tooltips' })}
        placement="top"
        arrow
      >
        <InfoOutlinedIcon
          style={{
            marginRight: '0.5em',
            fontSize: '1.25em',
            alignSelf: 'center',
          }}
        />
      </Tooltip>
      <Box style={{ lineHeight: '1.75em' }}>
        {keywords.map((keyword) => (
          <Tooltip
            title={t(`descriptions.${keyword.label}`, { ns: 'instance' })}
            placement="top"
            arrow
            key={keyword.label}
            style={{ marginRight: '0.5em' }}
          >
            <Chip
              color="primary"
              className="chip"
              size="small"
              label={t(keyword.label, { ns: 'instance' })}
              draggable={true}
              onDragStart={(e) => handleDragStart(e, keyword.value)}
              aria-label="drag chip"
            />
          </Tooltip>
        ))}
      </Box>
    </Paper>
  );
};

export default DragAndDropItems;
