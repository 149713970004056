import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import brandImage from '../../../assets/brand/kandasearch-brand-logo.svg';
import brandName from '../../../assets/brand/kandasearch-brand-name.svg';
import { PRIVATE_ROUTE } from '../../../routes/routes';
import Svg from '../../elements/svg/Svg';
import { useStyles } from './Logo.styles';

const Logo = ({ open, isPublic, ...restProps }) => {
  const classes = useStyles(open);

  const redirectTo = PRIVATE_ROUTE.PROJECTS;

  return (
    <Link to={redirectTo} className={classes.sidebarHeader} {...restProps}>
      <div className={classes.sidebarBrand}>
        <Svg brandImage src={brandImage} />
        <Svg brandName src={brandName} />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  open: PropTypes.bool,
  isPublic: PropTypes.bool,
};

export default Logo;
