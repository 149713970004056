import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import loadingImg from '../../../assets/loading.svg';
import Svg from '../../elements/svg/Svg';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.grey[50],
  },
}));

const Loading = ({ fullscreen = false }) => {
  const classes = useStyles();

  return (
    <>
      {fullscreen ? (
        <Backdrop className={classes.backdrop} open={true} aria-label="loading">
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box
          minHeight={100}
          display="flex"
          justifyContent="center"
          alignItems="center"
          aria-label="data loading"
        >
          <Svg width={60} src={loadingImg} />
        </Box>
      )}
    </>
  );
};

Loading.propTypes = {
  fullscreen: PropTypes.bool,
};

export default Loading;
