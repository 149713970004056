import i18next from 'i18next';
import * as yup from 'yup';

import { SYNONYM_FILTERS } from '../../../../constants';
import { sentenceCase } from '../../../../utils/utils';

export const deploySchema = yup.object().shape({
  instanceId: yup.string().required(
    sentenceCase(
      i18next.t('selectRequired', {
        ns: 'validations',
        field: i18next.t('instance', { ns: 'instance' }),
      })
    )
  ),
  collection: yup.string().required(
    sentenceCase(
      i18next.t('selectRequired', {
        ns: 'validations',
        field: i18next.t('collection', { ns: 'instance' }),
      })
    )
  ),
  filter: yup.string().required(
    sentenceCase(
      i18next.t('selectRequired', {
        ns: 'validations',
        field: i18next.t('synonym.filter', { ns: 'fields' }),
      })
    )
  ),
  filename: yup.string().when('filter', {
    is: SYNONYM_FILTERS.SYNONYM_GRAPH_FILTER,
    then: yup.string().required(
      sentenceCase(
        i18next.t('inputRequired', {
          ns: 'validations',
          field: i18next.t('fileName', { ns: 'fields' }),
        })
      )
    ),
    otherwise: yup.string(),
  }),
  resource: yup.string().when('filter', {
    is: SYNONYM_FILTERS.MANAGED_SYNONYM_GRAPH_FILTER,
    then: yup.string().required(
      sentenceCase(
        i18next.t('selectRequired', {
          ns: 'validations',
          field: i18next.t('synonym.managedResource', { ns: 'fields' }),
        })
      )
    ),
    otherwise: yup.string(),
  }),
  initArgs: yup.array().when('filter', {
    is: SYNONYM_FILTERS.MANAGED_SYNONYM_GRAPH_FILTER,
    then: yup.array().of(
      yup.object().shape(
        {
          key: yup.string().when('val', {
            is: (v) => v !== '',
            then: yup
              .string()
              .required(i18next.t('keyArgsRequired', { ns: 'validations' })),

            otherwise: yup.string(),
          }),
          val: yup.string().when('key', {
            is: (v) => v !== '',
            then: yup
              .string()
              .required(i18next.t('valArgsRequired', { ns: 'validations' })),
            otherwise: yup.string(),
          }),
        },
        ['key', 'val']
      )
    ),
  }),
});
