import { instance, getToken } from './config';

const createProject = (data) =>
  instance.post(`/projects`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'multipart/form-data',
    },
  });

const getProject = (projectId) =>
  instance.get(`/projects/${projectId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateProject = (projectId, data) =>
  instance.put(`/projects/${projectId}`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'multipart/form-data',
    },
  });

const deleteProjectIcon = (projectId) =>
  instance.delete(`/projects/${projectId}/icon`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteProject = (projectId) =>
  instance.delete(`/projects/${projectId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

// Project Billing Endpoints
const getProjectCards = (projectId) =>
  instance.get(`/projects/${projectId}/cards`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const createProjectCard = (projectId, data) =>
  instance.post(`/projects/${projectId}/cards`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateProjectCard = (projectId, id, data) =>
  instance.put(`/projects/${projectId}/cards/${id}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteProjectCard = (projectId, id) =>
  instance.delete(`/projects/${projectId}/cards/${id}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

// Billing Info Endpoints
const getProjectBillingInfo = (projectId) =>
  instance.get(`/projects/${projectId}/bis?size=1`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const createProjectBillingInfo = (projectId, data) =>
  instance.post(`/projects/${projectId}/bis`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateProjectBillingInfo = (projectId, id, data) =>
  instance.put(`/projects/${projectId}/bis/${id}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const getProjectBillingHistory = (projectId, pageNum = 0) =>
  instance.get(`/projects/${projectId}/bps`, {
    params: {
      page: pageNum,
      size: 5,
      sort: 'date,desc',
    },
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const downloadReceipt = (projectId, bpsId) =>
  instance.post(`/projects/${projectId}/bps/${bpsId}/receipt`, null, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    responseType: 'arraybuffer',
  });

// Project Manager Endpoints
const getProjectManagers = (projectId) =>
  instance.get(`/projects/${projectId}/pms?size=1`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const addProjectManager = (projectId, data) =>
  instance.post(`/projects/${projectId}/pms`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateProjectManager = (projectId, projectManagerId, data) =>
  instance.put(`/projects/${projectId}/pms/${projectManagerId}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

// Project Member Endpoints
const getProjectMembers = (projectId, page = 0) =>
  instance.get(`/projects/${projectId}/users`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      projectId,
      page,
      size: 20,
    },
  });

const inviteProjectMember = (projectId, data) =>
  instance.post(`/projects/${projectId}/invites`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const acceptInvitationProjectMember = (projectId, inviteId) =>
  instance.post(`/projects/${projectId}/invites/${inviteId}/accept`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateProjectMember = (projectId, projectMemberId, data) =>
  instance.put(`/projects/${projectId}/members/${projectMemberId}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteProjectMember = (projectId, projectMemberId) =>
  instance.delete(`/projects/${projectId}/members/${projectMemberId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

// Extension Endpoints
const getSubsExtensions = (projectId) =>
  instance.get(`/projects/${projectId}/extension-subscriptions`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const subscribeExtension = (projectId, data) =>
  instance.post(`/projects/${projectId}/extension-subscriptions`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const unsubscribeExtension = (projectId, subscriptionId) =>
  instance.delete(
    `/projects/${projectId}/extension-subscriptions/${subscriptionId}`,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

// Storage Endpoints
const getS3Usage = (projectId, date) =>
  instance.get(`/projects/${projectId}/storage/summary`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { date: date },
  });

// Backup Endpoints
const createManualBackup = (projectId, backupType, data) =>
  instance.post(`/projects/${projectId}/backups?${backupType}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const getScheduledBackups = (projectId) =>
  instance.get(`/projects/${projectId}/backup-schedules`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const createScheduledBackup = (projectId, backupType, data) =>
  instance.post(`/projects/${projectId}/backup-schedules?${backupType}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateScheduledBackup = (projectId, scheduleId, data) =>
  instance.put(`/projects/${projectId}/backup-schedules/${scheduleId}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteScheduledBackup = (projectId, scheduleId) =>
  instance.delete(`/projects/${projectId}/backup-schedules/${scheduleId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const previewFileList = (projectId, data) =>
  instance.post(`/projects/${projectId}/backups/preview`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const downloadWebApp = (projectId, instanceId, data) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/applications/sc`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      timeout: 150000,
      responseType: 'arraybuffer',
    }
  );

export const projectApis = {
  createProject,
  getProject,
  updateProject,
  deleteProjectIcon,
  deleteProject,
  getProjectCards,
  createProjectCard,
  updateProjectCard,
  deleteProjectCard,
  getProjectBillingInfo,
  createProjectBillingInfo,
  updateProjectBillingInfo,
  getProjectBillingHistory,
  downloadReceipt,
  getProjectManagers,
  addProjectManager,
  updateProjectManager,
  getProjectMembers,
  inviteProjectMember,
  acceptInvitationProjectMember,
  updateProjectMember,
  deleteProjectMember,
  getSubsExtensions,
  subscribeExtension,
  unsubscribeExtension,
  getS3Usage,
  createManualBackup,
  getScheduledBackups,
  createScheduledBackup,
  updateScheduledBackup,
  deleteScheduledBackup,
  previewFileList,
  downloadWebApp,
};
