import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { fileManagerApis } from '../../../apis/fileManagerApis';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import Editor from '../../../components/page-sections/editor/Editor';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { PRIVATE_ROUTE } from '../../../routes/routes';
import { getFileFormData, sentenceCase } from '../../../utils/utils';

const FileEditorPage = () => {
  const history = useHistory();
  const { projectId, instanceId } = useParams();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const [project, setProject] = useState(null);
  const [instance, setInstance] = useState(null);
  const [code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const state = history.location.state;

  const isXml =
    state?.file?.ext === 'xml' || state?.file?.name === 'managed-schema';
  const mode = isXml ? 'xml' : state?.file?.ext;

  useEffect(() => {
    if (isEmpty(state)) {
      history.length > 1
        ? history.goBack()
        : history.push(PRIVATE_ROUTE.PROJECTS);
      return;
    }

    const fetchFile = async () => {
      const {
        path,
        file: { name },
        subscriptNum,
      } = state;

      try {
        const [projectRes, instanceRes, fileRes] = await requestAll([
          projectApis.getProject(projectId),
          solrApis.getInstance(projectId, instanceId),
          fileManagerApis.downloadFiles(
            projectId,
            instanceId,
            false,
            subscriptNum,
            path,
            name
          ),
        ]);

        setProject(projectRes.data);
        setInstance(instanceRes.data);
        if (typeof fileRes.data !== 'string' && mode !== 'json') {
          setCode(JSON.stringify(fileRes.data, null, 4));
        } else {
          setCode(fileRes.data);
        }
      } catch (error) {
        handleErrors(history, error, showToast);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFile();

    // eslint-disable-next-line
  }, [projectId, instanceId]);

  const onSubmit = async (mode, code) => {
    const getFile = (mode) => {
      switch (mode) {
        case 'xml':
          return new File([code], state.file.name, {
            type: 'text/xml',
          });
        case 'shell':
          return new File([code], state.file.name, {
            type: 'application/x-sh',
          });
        default:
          return new File([code], state.file.name, {
            type: 'text/plain;charset=utf-8',
          });
      }
    };

    try {
      const { subscript } = state;
      const formData = getFileFormData('files', getFile(mode));
      await fileManagerApis.uploadFiles(
        projectId,
        instanceId,
        subscript,
        state?.path,
        formData
      );
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('file', { ns: 'fields' }),
          })
        )
      );
      setTimeout(() => history.push(state.redirect), 800);
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('updateFile', { ns: 'errors' }));
      setIsLoading(false);
    }
  };

  const editorContent =
    code !== null ? (
      <>
        <Breadcrumbs projectName={project.name} instanceName={instance.name} />
        <TitlePanel title={t('fileEditor', { ns: 'titles' })} />
        <Editor
          mode={mode}
          code={code}
          onSubmit={onSubmit}
          prevPage={state.redirect}
        />
      </>
    ) : (
      <Box mt={2}>
        <Alert severity="error">
          {sentenceCase(
            t('loading', {
              ns: 'errors',
              content: t('requestedFile', { ns: 'instance' }),
            })
          )}
        </Alert>
      </Box>
    );

  return (
    <Container>
      {isLoading && <Loading />}
      {!isLoading && editorContent}
    </Container>
  );
};

export default FileEditorPage;
