import { Grid, Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import PropTypes from 'prop-types';

import MemberCard from './MemberCard';

const MemberCardList = ({
  data,
  isAdmin,
  onChange,
  onRemove,
  onPageChange,
}) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        style={{ minHeight: '60vh' }}
      >
        {data.content.map((member) => (
          <Grid key={member.id} item xs={12} sm={6} md={3} lg={2}>
            <MemberCard
              member={member}
              isAdmin={isAdmin}
              onChangeRole={onChange}
              onRemove={onRemove}
            />
          </Grid>
        ))}
      </Grid>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={data.totalPages}
          shape="rounded"
          onChange={onPageChange}
        />
      </Box>
    </>
  );
};

MemberCardList.propTypes = {
  data: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default MemberCardList;
