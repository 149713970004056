import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles, Box, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';

const phoneRegex =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  confirmButton: {
    marginLeft: theme.spacing(2),
  },
}));

const ProjectManagerForm = ({ onSubmit, onClose, data }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('name', { ns: 'fields' }),
          })
        )
      )
      .max(256, t('maxLength', { ns: 'validations', max: '256' })),
    email: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('email', { ns: 'fields' }),
          })
        )
      )
      .email(
        sentenceCase(
          t('invalidFormat', {
            ns: 'validations',
            field: t('email', { ns: 'fields' }),
          })
        )
      )
      .max(256, t('maxLength', { ns: 'validations', max: '256' })),
    title: yup
      .string()
      .max(256, t('maxLength', { ns: 'validations', max: '256' })),
    company: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('companyName', { ns: 'fields' }),
          })
        )
      )
      .max(256, t('maxLength', { ns: 'validations', max: '256 ' })),
    address: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('address', { ns: 'fields' }),
          })
        )
      )
      .max(512, t('maxLength', { ns: 'validations', max: '512' })),
    phone: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('phoneNumber', { ns: 'fields' }),
          })
        )
      )
      .matches(phoneRegex, {
        message: sentenceCase(
          `${t('invalidFormat', {
            ns: 'validations',
            field: t('phoneNumber', { ns: 'fields' }),
          })} ${t('example', { ns: 'validations', example: '030-1234-5678' })}`
        ),
      }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue={data?.name || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('name', { ns: 'fields' })}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  inputProps={{ 'aria-label': 'name' }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue={data?.email || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('email', { ns: 'fields' })}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  inputProps={{ 'aria-label': 'email' }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="title"
              control={control}
              defaultValue={data?.title || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('jobTitle', { ns: 'fields' })}
                  error={!!errors?.title}
                  helperText={errors?.title?.message}
                  inputProps={{ 'aria-label': 'title' }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="company"
              control={control}
              defaultValue={data?.company || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('companyName', { ns: 'fields' })}
                  error={!!errors?.company}
                  helperText={errors?.company?.message}
                  inputProps={{ 'aria-label': 'company' }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="phone"
              control={control}
              defaultValue={data?.phone || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('phoneNumber', { ns: 'fields' })}
                  error={!!errors?.phone}
                  helperText={errors?.phone?.message}
                  inputProps={{ 'aria-label': 'phone' }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="address"
              control={control}
              defaultValue={data?.address || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('address', { ns: 'fields' })}
                  error={!!errors?.address}
                  helperText={errors?.address?.message}
                  inputProps={{ 'aria-label': 'address' }}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Box className={classes.buttonContainer}>
          <Button variant="text" onClick={onClose} aria-label="cancel">
            {t('cancel', { ns: 'buttons' })}
          </Button>
          <Button
            className={classes.confirmButton}
            variant="text"
            type="submit"
            disabled={!isDirty || !isValid}
            aria-label="submit"
            onClick={onClose}
          >
            {t('confirm', { ns: 'buttons' })}
          </Button>
        </Box>
      </form>
    </>
  );
};

ProjectManagerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default ProjectManagerForm;
