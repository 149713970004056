import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { reduce, capitalize, isEqual, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { userApis } from '../apis/userApis';
import { USER_ROLES } from '../constants';
import { matchError } from '../helpers';
import useToast, { TOAST_TYPE } from '../hooks/useToast';
import { cookies } from '../utils/utils';

let cacheProfile = null;

export const UserContext = createContext();

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context)
    throw new Error('useUser must be used within UserContextProvider');

  return context;
};

const UserProvider = ({ children }) => {
  const { keycloak } = useKeycloak();
  const { showToast } = useToast();
  const { i18n } = useTranslation();

  const [user, setUser] = useState(cacheProfile);
  const [error, setError] = useState(null);

  const fetchUserProfile = useCallback(async () => {
    setError(null);

    try {
      const userId = keycloak.tokenParsed.sub;
      const token = keycloak.token;

      const { data } = await userApis.getUserProfile(userId);
      const roleKeyPair = mapToObject(data.projects, 'projectId', 'role');
      const getRole = (projectId) => capitalize(roleKeyPair[projectId]);
      const isAdmin = (projectId) =>
        roleKeyPair[projectId] === USER_ROLES.ADMIN;
      const initials =
        data.firstName && data.lastName
          ? (data.firstName[0] + data.lastName[0]).toUpperCase()
          : data?.fullName?.slice(0, 2);

      const profile = { ...data, initials, token, getRole, isAdmin };

      if (isEqual(profile, cacheProfile)) return;

      cacheProfile = cloneDeep(profile);
      setUser(profile);
      setError(null);
    } catch (error) {
      const { message } = matchError(error);
      setError(message);
      setError(null);
      setUser(null);
    }
  }, [keycloak.token, keycloak.tokenParsed.sub]);

  useEffect(() => {
    if (!user?.locale) return;

    if (user.locale !== cookies.get('locale')) {
      cookies.set('locale', user.locale);
    }

    if (user.locale !== i18n.language) {
      i18n.changeLanguage(user.locale);
    }

    // eslint-disable-next-line
  }, [user?.locale]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  useEffect(() => {
    if (error) showToast(TOAST_TYPE.ERROR, error);
  }, [error, showToast]);

  return (
    <UserContext.Provider value={{ user, refreshUser: fetchUserProfile }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export default UserProvider;

function mapToObject(array, key, value) {
  return reduce(
    array,
    (obj, item) => {
      obj[item[key]] = item[value];
      return obj;
    },
    {}
  );
}
