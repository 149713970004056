import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import * as FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { collectionApis } from '../../../apis/collectionApis';
import { isInstanceRunning } from '../../../apis/constants';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import { synonymApis } from '../../../apis/synonymApis';
import Loading from '../../../components/elements/loading/Loading';
import { MIME_TYPE } from '../../../components/page-sections/file-browser/constants';
import SynonymActionGroup from '../../../components/page-sections/synonym/SynonymActionGroup';
import SynonymSetTable from '../../../components/page-sections/synonym/SynonymSetTable';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { SYNONYM_FILTERS } from '../../../constants';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import {
  getFileFormData,
  isSolrCloud,
  mapBlobFormData,
  sentenceCase,
} from '../../../utils/utils';

const ProjectSynonymDictionaryPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { projectId, dictionaryId } = useParams();
  const { showToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSynonymSet, setIsLoadingSynonymSet] = useState(false);
  const [project, setProject] = useState(null);
  const [synonymDictionary, setSynonymDictionary] = useState(null);
  const [synonymSetData, setSynonymSetData] = useState(null);
  const [filteredSynonymSetData, setFilteredSynonymSetData] = useState(null);
  const [shouldDisplaySearch, setShouldDisplaySearch] = useState(false);
  const [searchConfig, setSearchConfig] = useState({
    page: 0,
    query: '',
    isExact: true,
  });

  const refreshTable = async () => {
    shouldDisplaySearch
      ? await handleSearch(searchConfig)
      : await fetchSynonymSets(searchConfig.page);
  };

  const updateSearchConfig = (data) => {
    setSearchConfig((prevConfig) => ({ ...prevConfig, ...data, page: 0 }));
    if (data.query === '') {
      setShouldDisplaySearch(false);
      setFilteredSynonymSetData(null);
    } else {
      setShouldDisplaySearch(true);
    }
  };

  const fetchDictionary = async () => {
    const { data } = await synonymApis.getSynonymDictionary(
      projectId,
      dictionaryId
    );
    setSynonymDictionary(data);
  };

  const getInstances = async () => {
    const { data } = await solrApis.getInstances(projectId);
    return data.content.filter((instance) => isInstanceRunning(instance));
  };

  const getCollections = async (instance) => {
    if (!instance) return;
    if (isSolrCloud(instance.type)) {
      const { data } = await collectionApis.getSolrCloudCollections(
        projectId,
        instance.id
      );
      return data.collections;
    } else {
      const { data } = await collectionApis.getCollections(
        projectId,
        instance.id
      );
      return Object.keys(data.status);
    }
  };

  const getResources = async (instanceId, collection) => {
    const { data } = await synonymApis.getManagedResources(
      projectId,
      instanceId,
      collection
    );
    return data;
  };

  const getInitArgs = async (instanceId, collection, resource) => {
    const { data } = await synonymApis.getInitArgs(
      projectId,
      instanceId,
      collection,
      resource
    );
    return data;
  };

  const fetchSynonymSets = async (page = 0) => {
    try {
      const { data } = await synonymApis.getSynonymSets(
        projectId,
        dictionaryId,
        page
      );
      setSynonymSetData(data);
    } catch {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('loading', {
            ns: 'errors',
            content: t('synonym.set', { ns: 'project' }),
          })
        )
      );
    }
  };

  const handleSearch = async (config) => {
    try {
      const { data } = await synonymApis.searchSynonym(
        projectId,
        dictionaryId,
        config.query,
        config.isExact,
        config.page
      );

      setFilteredSynonymSetData(data);
    } catch (error) {
      setShouldDisplaySearch(false);
      setFilteredSynonymSetData(null);
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('cannotGetResults', {
            ns: 'errors',
            word: searchConfig.query,
          })
        )
      );
    }
  };

  const handlePageChange = async (page) => {
    setSearchConfig((prevConfig) => ({ ...prevConfig, page }));
  };

  const handleRename = async (id, data) => {
    try {
      await synonymApis.updateSynonymDictionary(projectId, id, data);
      await fetchDictionary();

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    }
  };

  const handleDeploy = async (_, data) => {
    try {
      const { initArgs, filter, ...requestParams } = data;
      const managed = filter === SYNONYM_FILTERS.MANAGED_SYNONYM_GRAPH_FILTER;

      if (filter === SYNONYM_FILTERS.MANAGED_SYNONYM_GRAPH_FILTER) {
        const requestBody = mapBlobFormData(initArgs, 'initArgs');
        await synonymApis.deploySynonymDictionary(
          projectId,
          dictionaryId,
          requestBody,
          { ...requestParams, managed }
        );
      }

      if (filter === SYNONYM_FILTERS.SYNONYM_GRAPH_FILTER) {
        await synonymApis.deploySynonymDictionary(
          projectId,
          dictionaryId,
          null,
          { ...requestParams, managed }
        );
      }

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deploySuccess', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        showToast(TOAST_TYPE.ERROR, t('timeoutError', { ns: 'notifications' }));
      } else {
        showToast(
          TOAST_TYPE.ERROR,
          t('deployError', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        );
      }
    }
  };

  const handleImport = async (id, file, options) => {
    try {
      const format = file.type === MIME_TYPE.json ? 'json' : 'csv';
      const formData = getFileFormData('dictionary', file);
      const dictionaryText = t('synonym.dictionary', { ns: 'project' });
      showToast(
        TOAST_TYPE.INFO,
        sentenceCase(
          t('importStart', { ns: 'notifications', text: dictionaryText })
        )
      );
      await synonymApis.importSynonymDictionary(projectId, id, formData, {
        format,
        ...options,
      });
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('importSuccess', { ns: 'notifications', text: dictionaryText })
        )
      );

      setSearchConfig((prevConfig) => ({ ...prevConfig, page: 0 }));
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('importError', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    }
  };

  const handleExport = async (id, data) => {
    const { comments, fileType } = data;
    const format = fileType.toLowerCase();
    try {
      showToast(
        TOAST_TYPE.INFO,
        t('prepareForDownload', { ns: 'notifications' })
      );
      const response = await synonymApis.exportSynonymDictionary(
        projectId,
        id,
        { format, comments }
      );
      const blob = new Blob([response.data], {
        type: MIME_TYPE[format],
      });
      FileSaver.saveAs(blob, data.fileName);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('exportSuccess', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        showToast(TOAST_TYPE.ERROR, t('timeoutError', { ns: 'notifications' }));
      } else {
        showToast(
          TOAST_TYPE.ERROR,
          t('exportError', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        );
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await synonymApis.deleteSynonymDictionary(projectId, id);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
      const dictionaryPage = buildPath({
        path: PRIVATE_ROUTE.PROJECT_SYNONYM_DICTIONARIES,
        params: { projectId },
      });
      setTimeout(() => history.push(dictionaryPage), 800);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    }
  };

  const handleCreateSynonymSet = async (data) => {
    try {
      await synonymApis.createSynonymSet(projectId, dictionaryId, data);

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('createSuccess', {
            ns: 'notifications',
            text: t('synonym.set', { ns: 'project' }),
          })
        )
      );

      await refreshTable();
    } catch (error) {
      if (error.response?.status === 400) {
        const word = /\[(.*)]/g.exec(error.response.data.message)?.[1];
        return t('duplicateInDictionary', { ns: 'validations', value: word });
      } else {
        showToast(
          TOAST_TYPE.ERROR,
          sentenceCase(
            t('createError', {
              ns: 'notifications',
              text: t('synonym.set', { ns: 'project' }),
            })
          )
        );
      }
    }
  };

  const handleEditSynonymSet = async (id, data) => {
    try {
      await synonymApis.updateSynonymSet(projectId, dictionaryId, id, data);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('synonym.set', { ns: 'project' }),
          })
        )
      );

      await refreshTable();
    } catch (error) {
      if (error.response?.status === 400) {
        const word = /\[(.*)]/g.exec(error.response.data.message)?.[1];
        return t('duplicateInDictionary', { ns: 'validations', value: word });
      } else {
        showToast(
          TOAST_TYPE.ERROR,
          sentenceCase(
            t('updateError', {
              ns: 'notifications',
              text: t('synonym.set', { ns: 'project' }),
            })
          )
        );
      }
    }
  };

  const handleDeleteSynonymSet = async (id) => {
    try {
      await synonymApis.deleteSynonymSet(projectId, dictionaryId, id);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('synonym.set', { ns: 'project' }),
          })
        )
      );

      await refreshTable();
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('synonym.set', { ns: 'project' }),
          })
        )
      );
    }
  };

  useEffect(() => {
    const getTableContent = async () => {
      setIsLoadingSynonymSet(true);
      searchConfig.query
        ? await handleSearch(searchConfig)
        : await fetchSynonymSets(searchConfig.page);
      setIsLoadingSynonymSet(false);
    };

    getTableContent();
    // eslint-disable-next-line
  }, [searchConfig]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data: project } = await projectApis.getProject(projectId);
        await fetchDictionary();

        setProject(project);
        setIsLoading(false);
      } catch (error) {
        handleErrors(history, error, showToast);
        setIsLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [projectId, dictionaryId]);

  const content =
    project && synonymDictionary ? (
      <>
        <Breadcrumbs
          projectName={project.name}
          dictionaryName={synonymDictionary.name}
        />
        <TitlePanel title={synonymDictionary.name} />

        <>
          <SynonymActionGroup
            numberFound={
              shouldDisplaySearch
                ? filteredSynonymSetData?.totalElements || 0
                : synonymSetData?.totalElements || 0
            }
            dictionary={synonymDictionary}
            handleCreateSynonymSet={handleCreateSynonymSet}
            isSearching={shouldDisplaySearch}
            searchConfig={searchConfig}
            updateSearchConfig={updateSearchConfig}
            handleRename={handleRename}
            handleImport={handleImport}
            handleExport={handleExport}
            handleDeploy={handleDeploy}
            handleDelete={handleDelete}
            getInstances={getInstances}
            getCollections={getCollections}
            getResources={getResources}
            getInitArgs={getInitArgs}
          />
          {isLoadingSynonymSet ? (
            <Loading />
          ) : (
            <SynonymSetTable
              isSearching={shouldDisplaySearch}
              data={
                shouldDisplaySearch ? filteredSynonymSetData : synonymSetData
              }
              handlePageChange={handlePageChange}
              handleEdit={handleEditSynonymSet}
              handleDelete={handleDeleteSynonymSet}
            />
          )}
        </>
      </>
    ) : (
      <Box mt={2}>
        <Alert severity="error">
          {sentenceCase(
            t('loading', {
              ns: 'errors',
              content: t('synonym.set', { ns: 'project' }),
            })
          )}
        </Alert>
      </Box>
    );

  return (
    <Container>
      {isLoading && <Loading />}
      {!isLoading && content}
    </Container>
  );
};

export default ProjectSynonymDictionaryPage;
