import './Svg.styles.scss';
import PropTypes from 'prop-types';

const setStyleClasses = ({ provider, brandImage, brandName }) => {
  if (provider) return 'provider';
  if (brandImage) return 'nav-brand-image';
  if (brandName) return 'nav-brand-name';
  return 'general';
};

const Svg = ({ src, width, style, ...restProps }) => {
  const styles = !width ? setStyleClasses(restProps) : '';

  return (
    <div
      role="figure"
      style={{ ...style, width: width }}
      className={`svg-wrapper ${styles}`}
    >
      <img src={src} alt={styles} />
    </div>
  );
};

Svg.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
};

export default Svg;
