import { replace } from 'lodash';
import * as yup from 'yup';

import i18n from '../../../i18n';
import { sentenceCase } from '../../../utils/utils';

// Allows any ip address with mask between 0 and 32
export const IP_RANGE_FORMAT_VALIDATION =
  /^([0-9]{1,3}\.){3}[0-9]{1,3}\/([0-9]|1[0-9]|2[0-9]|30|31|32)$/g;

export const schema = (cidrs) =>
  yup.object().shape({
    cidr: yup
      .string()
      .required(
        sentenceCase(
          i18n.t('inputRequired', {
            ns: 'validations',
            field: i18n.t('ipRange', { ns: 'fields' }),
          })
        )
      )
      .matches(
        IP_RANGE_FORMAT_VALIDATION,
        sentenceCase(
          `${i18n.t('invalidFormat', {
            ns: 'validations',
            field: i18n.t('ipRange', { ns: 'fields' }),
          })} ${i18n.t('example', { ns: 'validations', example: '0.0.0.0/0' })}`
        )
      )
      .test(
        'isUnique',
        sentenceCase(
          i18n.t('duplicate', {
            ns: 'validations',
            field: i18n.t('ipRange', { ns: 'fields' }),
          })
        ),
        (value) => {
          return cidrs.indexOf(value) < 0;
        }
      )
      .test(
        'isValidCIDR',
        sentenceCase(
          i18n.t('invalidFormat', {
            ns: 'validations',
            field: i18n.t('ipRange', { ns: 'fields' }),
          })
        ),
        function (value) {
          try {
            if (value === undefined) return false;

            const [ip, mask] = value.split('/');
            const numbers = ip.split('.');

            // ip to binary
            let binaryIpNumbers = '';

            for (let i = 0; i < numbers.length; i++) {
              const binaryIpNumber = Number(numbers[i]).toString(2);

              for (let j = binaryIpNumber.length; j < 8; j++)
                binaryIpNumbers += '0';
              binaryIpNumbers += binaryIpNumber;
            }

            // validate mask
            const endIp = binaryIpNumbers.substr(mask);
            const isValid = endIp.indexOf('1') < 0;

            // suggested ip address
            const suggestedIpAddress = `${(
              binaryIpNumbers.substr(0, mask) + replace(endIp, /1/g, '0')
            )
              .match(/.{8}/g)
              .map((binary) => parseInt(binary, 2))
              .join('.')}/${mask}`;

            return (
              isValid ||
              this.createError({
                message: sentenceCase(
                  `${i18n.t('invalidFormat', {
                    ns: 'validations',
                    field: i18n.t('ipRange', { ns: 'fields' }),
                  })} ${i18n.t('example', {
                    ns: 'validations',
                    example: suggestedIpAddress,
                  })}`
                ),
              })
            );
          } catch (e) {
            return false;
          }
        }
      ),
  });

export const tableHeaders = (compact) =>
  compact
    ? [i18n.t('ipRange', { ns: 'fields' }), '']
    : [
        i18n.t('ipRange', { ns: 'fields' }),
        i18n.t('creator', { ns: 'instance' }),
        i18n.t('createDate', { ns: 'instance' }),
        '',
      ];
export const createRows = (ip, creator, createDate, button) => [
  ip,
  creator,
  createDate,
  button,
];
export const createCompactRows = (ip, button) => [ip, button];
