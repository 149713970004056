import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatUserInfo, sentenceCase } from '../../../../utils/utils';
import Text from '../../../elements/text/Text';
import Modal from '../../../sections/modal/Modal';
import NoContent from '../../../sections/no-content/NoContent';
import UserAvatar from '../../../sections/user-avatar/UserAvatar';
import ScheduledBackupForm from '../../backup-form/ScheduledBackupForm';
import InstanceCell from '../table-columns/InstanceCell';
import StatusCell from '../table-columns/StatusCell';
import TypeCell from '../table-columns/TypeCell';

const ScheduledBackupTable = ({
  scheduledBackups,
  handleEdit,
  handleDelete,
  ...restProps
}) => {
  const { t } = useTranslation();

  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const TABLE_HEADERS = [
    t('backupSubject', { ns: 'fields' }),
    t('instance', { ns: 'instance' }),
    t('type', { ns: 'project' }),
    t('creator', { ns: 'project' }),
    t('backup.executeDate', { ns: 'project' }),
  ];

  const handleEditModalOpen = (backup) => {
    setSelectedSchedule(backup);
    setOpenEditModal(true);
  };

  const handleEditModalClose = () => {
    setSelectedSchedule(null);
    setOpenEditModal(false);
  };

  const handleDeleteModalOpen = (backup) => {
    setSelectedSchedule(backup);
    setOpenDeleteModal(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedSchedule(null);
    setOpenDeleteModal(false);
  };

  const onEdit = async (data, schedule) => {
    await handleEdit(data, schedule);
    handleEditModalClose();
  };

  const onDelete = async (id) => {
    await handleDelete(id);
    handleDeleteModalClose();
  };

  return isEmpty(scheduledBackups) ? (
    <NoContent
      title={sentenceCase(
        t('unset', {
          ns: 'descriptions',
          text: t('backup.scheduled', { ns: 'project' }),
        })
      )}
    />
  ) : (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" aria-label="table">
        <TableHead>
          <TableRow>
            {TABLE_HEADERS.map((header, i) => (
              <TableCell align={i !== 0 ? 'center' : 'left'} key={header}>
                {header}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {scheduledBackups.map((backup) => (
            <React.Fragment key={backup.id}>
              <TableRow aria-label="table row">
                <StatusCell
                  subject={backup.subject}
                  schedules={backup.historySize}
                />
                <InstanceCell
                  instance={backup.instanceSetName}
                  collection={backup.collection}
                  node={backup.instanceSubscript}
                  solrType={backup.solrType}
                  category={backup.category}
                />
                <TypeCell category={backup.category} size={backup.size} />
                <TableCell align="center">
                  <UserAvatar
                    user={formatUserInfo(backup.createdBy, backup.author)}
                  />
                </TableCell>
                <TableCell align="center">
                  <Text>{`${backup.displayTime} (${backup.periodZone})`}</Text>
                </TableCell>
                <TableCell align="right">
                  <Box display="flex">
                    <IconButton
                      aria-label="edit"
                      title={t('edit', { ns: 'buttons' })}
                      onClick={() => handleEditModalOpen(backup)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      title={t('delete', { ns: 'buttons' })}
                      onClick={() => handleDeleteModalOpen(backup)}
                    >
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>

      {/* Edit modal */}
      <Modal
        openModal={openEditModal}
        title={t('backup.editSchedule', { ns: 'project' })}
        handleClose={handleEditModalClose}
      >
        <ScheduledBackupForm
          data={selectedSchedule}
          handleEdit={onEdit}
          {...restProps}
        />
      </Modal>

      {/* Delete modal */}
      <Modal
        openModal={openDeleteModal}
        title={t('deletion', {
          ns: 'project',
          text: t('backup.schedule', { ns: 'project' }),
        })}
        handleClose={handleDeleteModalClose}
        handleConfirm={() => onDelete(selectedSchedule?.id)}
        showActions
      >
        <Text>
          {t('deleteSchedule', {
            ns: 'descriptions',
            text: selectedSchedule?.subject,
          })}
        </Text>
      </Modal>
    </TableContainer>
  );
};

ScheduledBackupTable.propTypes = {
  scheduledBackups: PropTypes.array,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ScheduledBackupTable;
