import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ModalButtons from './ModalButtons';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  padding: {
    marginRight: theme.spacing(2.5),
  },
}));

const Modal = ({
  openModal,
  title,
  children,
  form,
  deleteButton,
  showActions,
  handleClose,
  handleConfirm,
  ...restProps
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={openModal} fullWidth {...restProps}>
      <DialogTitle aria-label="modal title" className={classes.padding}>
        {title}
      </DialogTitle>
      <IconButton
        className={classes.closeButton}
        aria-label="close"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {children}
        {form}
      </DialogContent>

      {showActions && (
        <ModalButtons
          danger={deleteButton}
          onCancel={handleClose}
          onAction={handleConfirm}
          actionText={
            deleteButton
              ? t('delete', { ns: 'buttons' })
              : t('confirm', { ns: 'buttons' })
          }
        />
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  form: PropTypes.element,
  deleteButton: PropTypes.bool,
  showActions: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
};

export default Modal;
