import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextDivider from '../../../elements/text-divider/TextDivider';

const HighlightParamsFormSection = ({ control, errors, style }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextDivider>
        {t('search.highlightOptions', { ns: 'sectionTitles' })}
      </TextDivider>

      <Box className={style.inputContainer}>
        <Box className={style.input}>
          <Controller
            name="settings.query.jsonQuery.params.fl"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t('search.highlightFields', { ns: 'fields' })}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ 'aria-label': 'highlight field' }}
                error={!!errors?.settings?.query?.jsonQuery?.params?.fl}
                helperText={
                  errors?.settings?.query?.jsonQuery?.params?.fl?.message
                }
              />
            )}
          />
        </Box>
        <Grid item xs={12}>
          <FormGroup row>
            {[
              'requireFieldMatch',
              'usePhraseHighLighter',
              'highlightMultiTerm',
            ].map((fieldName) => (
              <FormControlLabel
                key={fieldName}
                label={t(`search.${fieldName}`, { ns: 'fields' })}
                control={
                  <Controller
                    name={`settings.query.jsonQuery.params.${fieldName}`}
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <Checkbox
                        {...field}
                        checked={!!value}
                        inputProps={{ 'aria-label': fieldName }}
                      />
                    )}
                  />
                }
              />
            ))}
          </FormGroup>
        </Grid>
      </Box>
    </>
  );
};

HighlightParamsFormSection.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default HighlightParamsFormSection;
