import { Box, Grid, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { INSTANCE_CATEGORY, PAYMENT_TYPES } from '../../../constants';
import { PRIVATE_ROUTE, buildPath } from '../../../routes/routes';
import { projectSetupComplete } from '../../../utils/paymentUtils';
import Text from '../../elements/text/Text';
import CreditCard from '../../sections/credit-card-display/CreditCard';
import ProjectSetupIncomplete from '../../sections/project-setup-incomplete/ProjectSetupIncomplete';
import StepTitle from './StepTitle';

const Payment = ({ project }) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext();

  const { id, cards, billingInfo, managers } = project;

  const setupComplete = projectSetupComplete(cards, billingInfo, managers);
  const isInvoicePayment =
    billingInfo?.[0]?.paymentType === PAYMENT_TYPES.INVOICE;
  const isCommunity = getValues('plan') === INSTANCE_CATEGORY.COMMUNITY;

  return isCommunity ? (
    <Box variant="outlined" style={{ padding: '2em', textAlign: 'center' }}>
      <Text gutterBottom bodyBold>
        {t('communityCreate', { ns: 'descriptions' })}
      </Text>
      <Text gutterBottom bodyBold>
        {t('noPaymentNeeded', { ns: 'descriptions' })}
      </Text>
      <Text gutterBottom bodyBold>
        {t('deleteAfterThirty', { ns: 'descriptions' })}
      </Text>
    </Box>
  ) : setupComplete ? (
    <section>
      <StepTitle title={t('confirmPaymentMethod', { ns: 'descriptions' })} />
      {isInvoicePayment ? (
        <Box my={2}>
          <Text align="center" gutterBottom bodyBold>
            {t('chargeByInvoice', { ns: 'descriptions' })}
          </Text>
          <Text align="center">
            {t('invoiceSentByMail', { ns: 'descriptions' })}
          </Text>
        </Box>
      ) : (
        <Grid container justify="center">
          <Grid item xs={12} style={{ marginBottom: '1em' }}>
            <Text align="center" gutterBottom>
              {t('cardCharged', { ns: 'descriptions' })}
            </Text>
          </Grid>

          <Grid item xs={12} sm={7} md={4} xl={3}>
            <CreditCard card={cards?.[0]} />
          </Grid>

          <Grid item xs={12} style={{ marginTop: '1em' }}>
            <Text align="center" component="p" caption>
              <Trans
                i18nKey="changePaymentMethod"
                ns="descriptions"
                components={[
                  <Link
                    key="0"
                    aria-label="to billing page"
                    component={RouterLink}
                    to={buildPath({
                      path: PRIVATE_ROUTE.PROJECT_BILLING,
                      params: { id },
                    })}
                  />,
                ]}
              />
            </Text>
          </Grid>
        </Grid>
      )}
    </section>
  ) : (
    <ProjectSetupIncomplete
      serviceName={t('paidInstance', { ns: 'instance' })}
      cards={cards}
      billingInfo={billingInfo}
      managers={managers}
    />
  );
};

Payment.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Payment;
