import { useState, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormHelperText, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Loading from '../../elements/loading/Loading';
import Text from '../../elements/text/Text';

const DictionaryForm = ({
  extensionType,
  version,
  handleAdd,
  getInstances,
  getCollections,
  getConfPath,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [instances, setInstances] = useState(null);
  const [collections, setCollections] = useState(null);

  const schema = yup.object().shape({
    instance: yup.mixed().test(
      'instanceRequired',
      sentenceCase(
        t('selectRequired', {
          ns: 'validations',
          field: t('instance', { ns: 'instance' }),
        })
      ),
      (value) => !!value
    ),
    collectionName: yup.string().when('instance', {
      is: (instance) => !!instance,
      then: yup.string().required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('collection', { ns: 'instance' }),
          })
        )
      ),
      otherwise: yup.string(),
    }),
    path: yup
      .string()
      .required(t('waitForDownloadPath', { ns: 'descriptions' })),
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { extensionType: extensionType, version: version },
  });

  const { instance, collectionName, path } = watch();

  const getInstanceData = async () => {
    try {
      const data = await getInstances();
      setInstances(data);
    } catch (error) {
      setInstances(null);
    }
  };

  const getCollectionData = async () => {
    setIsLoading(true);
    try {
      const data = await getCollections(instance);
      setCollections(data);
      setIsLoading(false);
    } catch (error) {
      setCollections(null);
      setIsLoading(false);
    }
  };

  const getPath = async () => {
    try {
      const path = await getConfPath(instance, collectionName);
      setValue('path', path, { shouldValidate: true });
    } catch (error) {
      setValue('path', undefined, { shouldValidate: true });
    }
  };

  useEffect(() => {
    getInstanceData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!instance) return;

    getCollectionData();
    setValue('collectionName', undefined);
    setValue('path', undefined);

    // eslint-disable-next-line
  }, [instance]);

  useEffect(() => {
    if (!collectionName) return;

    setValue('path', undefined, { shouldValidate: true });
    getPath();

    // eslint-disable-next-line
  }, [collectionName]);

  const onSubmit = (data) => {
    setIsProcessing(true);
    handleAdd(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      aria-label="dictionary extension form"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.instance}>
            <InputLabel>
              {t('ext.instanceToDownload', { ns: 'project' })}
            </InputLabel>
            <Select
              {...register('instance')}
              defaultValue={''}
              inputProps={{ 'aria-label': 'instance select' }}
            >
              {instances?.map((instance) => (
                <MenuItem key={instance.id} value={instance}>
                  {instance.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.instance?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {instance &&
            (isLoading ? (
              <Loading />
            ) : (
              <FormControl fullWidth error={!!errors.collectionName}>
                <InputLabel>
                  {t('ext.collectionToDownload', { ns: 'project' })}
                </InputLabel>
                <Select
                  {...register('collectionName')}
                  defaultValue={''}
                  inputProps={{ 'aria-label': 'collection select' }}
                >
                  {collections?.map((collection) => (
                    <MenuItem key={collection} value={collection}>
                      {collection}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.collectionName?.message}
                </FormHelperText>
              </FormControl>
            ))}
        </Grid>
      </Grid>
      <Box textAlign="center" my={2}>
        <Box textAlign="center" my={1}>
          <Text color="primary" component="div">
            {path
              ? t('ext.downloadPath', { ns: 'project', path })
              : collectionName && <CircularProgress />}
          </Text>
        </Box>
        <Button
          type="submit"
          disabled={!isEmpty(errors) || isProcessing}
          aria-label="confirm"
        >
          {t('download', { ns: 'buttons' })}
        </Button>
      </Box>
    </form>
  );
};

DictionaryForm.propTypes = {
  version: PropTypes.object.isRequired,
  extensionType: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  getInstances: PropTypes.func.isRequired,
  getCollections: PropTypes.func.isRequired,
  getConfPath: PropTypes.func.isRequired,
};

export default DictionaryForm;
