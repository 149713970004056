const SIDEBAR_TYPE = {
  PROJECT: 'project',
  INSTANCE: 'instance',
  USER: 'user',
  GPS: 'gps',
  MODEL: 'model',
};

const PROJECT_SIDEBAR_ITEMS = {
  OVERVIEW: 'overview',
  MEMBER: 'members',
  BACKUP: 'backup',
  RESTORE: 'restore',
  PROJECT_BILLING: 'billing',
  SUBS_EXTENSIONS: 'subExtensions',
  SYNONYM_DICTIONARIES: 'synonymDict',
  CRAWLER: 'crawler',
  SETTINGS: 'settings',
};

const INSTANCE_SIDEBAR_ITEMS = {
  OVERVIEW: 'overview',
  COLLECTION: 'collections',
  CONFIG: 'config',
  FILE_MANAGER: 'fileManager',
  SEARCH: 'search',
  HEALTH_CHECK: 'healthCheck',
  MONITOR: 'monitor',
  SETTINGS: 'settings',
};

const GPS_SIDEBAR_ITEMS = {
  OVERVIEW: 'overview',
  JOBS: 'job',
  FILE_MANAGER: 'fileManager',
  SETTINGS: 'settings',
};

const MODEL_SIDEBAR_ITEMS = {
  OVERVIEW: 'overview',
  SETTINGS: 'settings',
};

const USER_ITEMS = {
  PROFILE: 'profile',
  ACCOUNT: 'account',
};

export {
  SIDEBAR_TYPE,
  PROJECT_SIDEBAR_ITEMS,
  INSTANCE_SIDEBAR_ITEMS,
  GPS_SIDEBAR_ITEMS,
  MODEL_SIDEBAR_ITEMS,
  USER_ITEMS,
};
