import { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { collectionApis } from '../../../apis/collectionApis';
import { requestAll } from '../../../apis/config';
import { crawlerApis } from '../../../apis/crawlerApis';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import CrawlerStepper from '../../../components/page-sections/web-crawler/crawler-stepper/CrawlerStepper';
import NewCrawlerButton from '../../../components/page-sections/web-crawler/crawler-stepper/NewCrawlerButton';
import CrawlerTable from '../../../components/page-sections/web-crawler/crawler-table/CrawlerTable';
import EditCrawlerJob from '../../../components/page-sections/web-crawler/crawler-table/EditCrawlerJob';
import {
  transformToRequest,
  transformToForm,
  projectSetupComplete,
} from '../../../components/page-sections/web-crawler/crawlerUtils';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import Modal from '../../../components/sections/modal/Modal';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { INSTANCE_CATEGORY } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { isSolrCloud, sentenceCase } from '../../../utils/utils';

const ProjectCrawlerPage = () => {
  const { projectId } = useParams();
  const history = useHistory();
  const { showToast } = useToast();
  const { user } = useUser();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [projectSetup, setProjectSetup] = useState(null);
  const [instances, setInstances] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [jobModal, setJobModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editJob, setEditJob] = useState(null);

  const fetchProjectSetup = async () => {
    try {
      const [projectRes, cardsRes, billingInfoRes, managersRes] =
        await requestAll([
          projectApis.getProject(projectId),
          projectApis.getProjectCards(projectId),
          projectApis.getProjectBillingInfo(projectId),
          projectApis.getProjectManagers(projectId),
        ]);

      const project = {
        ...projectRes.data,
        cards: cardsRes.data,
        billingInfo: billingInfoRes.data.content,
        managers: managersRes.data.content,
      };

      setProject(project);
      setProjectSetup(projectSetupComplete(project));
    } catch (error) {
      handleErrors(history, error, showToast);
    }
  };

  const fetchJobs = async () => {
    try {
      const { data: jobsRes } = await crawlerApis.getCrawlJobs(projectId, page);
      const jobsContent = jobsRes.content;

      setJobs(jobsContent);
      setTotalPages(jobsRes.totalPages);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('fetch', {
          ns: 'errors',
          content: t('job', { ns: 'crawler' }),
        })
      );
      setJobs([]);
    }
  };

  const getFreeJobs = async () => {
    try {
      const { data: freeJobsRes } = await crawlerApis.getJobSlots(projectId);
      return freeJobsRes.content;
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('fetch', {
          ns: 'errors',
          content: t('jobSlot', { ns: 'crawler' }),
        })
      );
      return null;
    }
  };

  const getInstances = async () => {
    try {
      const { data: instancesRes } = await solrApis.getInstances(projectId, {
        category: `${INSTANCE_CATEGORY.LEGACY}, ${INSTANCE_CATEGORY.STANDARD}, ${INSTANCE_CATEGORY.ENTERPRISE}`,
      });
      setInstances(instancesRes.content);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('fetch', {
            ns: 'errors',
            content: t('instance', { ns: 'instance' }),
          })
        )
      );
      setInstances([]);
    }
  };

  const getCollections = async (instance) => {
    try {
      if (isSolrCloud(instance.type)) {
        const { data } = await collectionApis.getSolrCloudCollections(
          projectId,
          instance.id
        );
        return data.collections;
      } else {
        const { data } = await collectionApis.getCollections(
          projectId,
          instance.id
        );
        return Object.keys(data.status);
      }
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('fetch', {
            ns: 'errors',
            content: t('collection', { ns: 'instance' }),
          })
        )
      );
      return [];
    }
  };

  const getUniqueKey = async (instance, collection) => {
    try {
      const { data: uniqueKey } = await collectionApis.getSchemaUniqueKey(
        projectId,
        instance,
        collection
      );
      return uniqueKey;
    } catch (error) {
      return '';
    }
  };

  const getMembers = async () => {
    try {
      const { data: memberRes } = await projectApis.getProjectMembers(
        projectId,
        0
      );
      return memberRes.content;
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('fetch', { ns: 'errors', content: t('members', { ns: 'titles' }) })
      );
      return [];
    }
  };

  const onPageLoad = async () => {
    setLoading(true);
    await fetchProjectSetup();
    await getInstances();
    await fetchJobs();
    setLoading(false);
  };

  useEffect(() => {
    onPageLoad();
    // eslint-disable-next-line
  }, []);

  // fetch jobs every 60 seconds. Return to clean up on page change.
  useEffect(() => {
    const jobInterval = setInterval(async () => await fetchJobs(), 60000);
    return () => clearInterval(jobInterval);
    // eslint-disable-next-line
  }, [page]);

  const handleRunJob = async (jobId) => {
    try {
      await crawlerApis.startCrawlJob(projectId, jobId);
      await fetchJobs();

      showToast(
        TOAST_TYPE.SUCCESS,
        t('crawlStartSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      const runningRe = /is already running/gi;
      runningRe.test(error?.message)
        ? showToast(
            TOAST_TYPE.ERROR,
            t('crawlAlreadyStart', { ns: 'notifications' })
          )
        : showToast(
            TOAST_TYPE.ERROR,
            t('crawlStartError', { ns: 'notifications' })
          );
    }
  };

  const handleStopJob = async (jobId) => {
    try {
      await crawlerApis.stopCrawlJob(projectId, jobId);
      await fetchJobs();

      showToast(
        TOAST_TYPE.SUCCESS,
        t('crawlStopSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      const notActiveRe = /is not active/gi;
      notActiveRe.test(error?.message)
        ? showToast(
            TOAST_TYPE.ERROR,
            t('crawlAlreadyStop', { ns: 'notifications' })
          )
        : showToast(
            TOAST_TYPE.ERROR,
            t('crawlStopError', { ns: 'notifications' })
          );
    }
  };

  const handleResetJob = async (jobId) => {
    try {
      await crawlerApis.resetSeeds(projectId, jobId);
      await fetchJobs();

      showToast(
        TOAST_TYPE.SUCCESS,
        t('resetSeedsSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('resetSeedsError', { ns: 'notifications' })
      );
    }
  };

  const handleEditJob = async (jobId) => {
    try {
      const { data: jobRes } = await crawlerApis.getCrawlJob(projectId, jobId);
      const formData = transformToForm(jobRes);

      setEditJob(formData);
      toggleEditModal();
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('fetch', {
          ns: 'errors',
          content: t('job', { ns: 'crawler' }),
        })
      );
    }
  };

  const handleSubmitEdit = async (data) => {
    try {
      const body = transformToRequest(data);

      await crawlerApis.editCrawlJob(projectId, data.id, body);
      await fetchJobs();
      toggleEditModal();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('updateSuccess', {
          ns: 'notifications',
          text: t('job', { ns: 'crawler' }),
        })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('updateError', {
          ns: 'notifications',
          text: t('job', { ns: 'crawler' }),
        })
      );
    }
  };

  const handleDeleteJob = async (jobId) => {
    try {
      await crawlerApis.deleteCrawlJob(projectId, jobId);
      await fetchJobs();
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('job', { ns: 'crawler' }),
          })
        )
      );
    } catch (errors) {
      const { data: errorRes } = errors.response;
      // This success case is a result of the backend sending a 500
      // however the job is still deleted. This issue should be addressed
      // by the backend as it is anti-pattern.
      const errorMsgRe = /Retries exhausted/gi;

      if (errorRes.status === 400) {
        showToast(
          TOAST_TYPE.ERROR,
          t('crawlJobRunningError', { ns: 'notifications' })
        );
      } else if (errors.response) {
        errorMsgRe.test(errorRes.message)
          ? showToast(
              TOAST_TYPE.SUCCESS,
              sentenceCase(
                t('deleteSuccess', {
                  ns: 'notifications',
                  text: t('job', { ns: 'crawler' }),
                })
              )
            )
          : showToast(
              TOAST_TYPE.ERROR,
              sentenceCase(
                t('deleteError', {
                  ns: 'notifications',
                  text: t('job', { ns: 'crawler' }),
                })
              )
            );
      } else {
        showToast(
          TOAST_TYPE.ERROR,
          sentenceCase(
            t('deleteError', {
              ns: 'notifications',
              text: t('job', { ns: 'crawler' }),
            })
          )
        );
      }
      await fetchJobs();
    }
  };

  const handleCreateJob = async (data) => {
    try {
      const body = transformToRequest(data);
      await crawlerApis.createCrawlJob(projectId, body);
      fetchJobs();
      toggleJobModal();
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('createSuccess', {
            ns: 'notifications',
            text: t('job', { ns: 'crawler' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('createError', {
            ns: 'notifications',
            text: t('job', { ns: 'crawler' }),
          })
        )
      );
    }
  };

  const handlePageChange = async (newPage) => setPage(newPage);

  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line
  }, [page]);

  const toggleJobModal = () => setJobModal(!jobModal);
  const toggleEditModal = () => setEditModal(!editModal);

  return (
    <Container>
      {!loading ? (
        <section>
          <Breadcrumbs projectName={project?.name} />
          <TitlePanel visible title={t('crawlerJobs', { ns: 'titles' })}>
            <NewCrawlerButton
              isAdmin={user?.isAdmin?.(projectId)}
              toggleModal={toggleJobModal}
            />
          </TitlePanel>
          <CrawlerTable
            jobs={jobs}
            totalPages={totalPages}
            isAdmin={user.isAdmin(projectId)}
            onRunJob={handleRunJob}
            onStopJob={handleStopJob}
            onResetJob={handleResetJob}
            onEditJob={handleEditJob}
            onDeleteJob={handleDeleteJob}
            onPageChange={handlePageChange}
          />

          <Modal
            title={t('crawler.jobSetup', { ns: 'sectionTitles' })}
            openModal={jobModal}
            handleClose={toggleJobModal}
            maxWidth="md"
          >
            <CrawlerStepper
              onClose={toggleJobModal}
              onSubmit={handleCreateJob}
              instances={instances}
              getCollections={getCollections}
              getFreeJobs={getFreeJobs}
              getMembers={getMembers}
              getUniqueKey={getUniqueKey}
              projectSetup={projectSetup}
              project={project}
            />
          </Modal>

          <Modal
            title={t('crawler.editJob', { ns: 'sectionTitles' })}
            openModal={editModal}
            handleClose={toggleEditModal}
            maxWidth="md"
          >
            <EditCrawlerJob
              instances={instances}
              job={editJob}
              getCollections={getCollections}
              getMembers={getMembers}
              getUniqueKey={getUniqueKey}
              onClose={toggleEditModal}
              onSubmit={handleSubmitEdit}
            />
          </Modal>
        </section>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default ProjectCrawlerPage;
