import { useEffect, useState } from 'react';

import { Box, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { projectApis } from '../../../apis/projectApis';
import { serverApis } from '../../../apis/serverApis';
import Loading from '../../../components/elements/loading/Loading';
import ServerJobsList from '../../../components/page-sections/server-jobs/ServerJobsList';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import NoContent from '../../../components/sections/no-content/NoContent';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { useServer } from '../../../contexts/ServerContext';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { sentenceCase } from '../../../utils/utils';

const ServerJobsPage = () => {
  const { t } = useTranslation();
  const { projectId, gpsId } = useParams();
  const { server } = useServer();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [project, setProject] = useState(null);
  const [tasks, setTasks] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [{ data: projectRes }, { data: tasksRes }] = await requestAll([
        projectApis.getProject(projectId),
        serverApis.getIndexingTasks(projectId, gpsId),
      ]);
      setProject(projectRes);
      // The response has a wrapper with error inside.
      // You need to manually throw if it is not null.
      if (tasksRes.error) throw new Error();
      setTasks(tasksRes.result);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (_, page) => {
    try {
      const { data: tasksRes } = await serverApis.getIndexingTasks(
        projectId,
        gpsId,
        page - 1
      );
      setTasks(tasksRes.result);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('fetch', { ns: 'error', content: t('jobs', { ns: 'gps' }) })
      );
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {loading && <Loading />}
      {!loading && error && (
        <Box mt={2}>
          <Alert severity="error">
            {sentenceCase(
              t('loading', {
                ns: 'errors',
                content: t('jobs', { ns: 'titles' }),
              })
            )}
          </Alert>
        </Box>
      )}
      {!loading && !error && project && server && (
        <>
          <Breadcrumbs projectName={project.name} serverName={server.name} />
          <TitlePanel title={t('job', { ns: 'titles' })} />
          {tasks && tasks.content.length > 0 ? (
            <ServerJobsList jobs={tasks} onPageChange={handlePageChange} />
          ) : (
            <NoContent title={t('noJobsFound', { ns: 'descriptions' })} />
          )}
        </>
      )}
    </Container>
  );
};

export default ServerJobsPage;
