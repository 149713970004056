import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import buttonsEN from './locales/en/buttons.json';
import crawlerEN from './locales/en/crawler.json';
import descriptionsEN from './locales/en/descriptions.json';
import errorsEN from './locales/en/errors.json';
import fieldsEN from './locales/en/fields.json';
import generalEN from './locales/en/general.json';
import gpsEN from './locales/en/gps.json';
import instanceEN from './locales/en/instance.json';
import menusEN from './locales/en/menus.json';
import modelEN from './locales/en/model.json';
import monitoringEN from './locales/en/monitoring.json';
import notificationsEN from './locales/en/notifications.json';
import projectEN from './locales/en/project.json';
import sectionTitlesEN from './locales/en/sectionTitles.json';
import titlesEN from './locales/en/titles.json';
import tooltipsEN from './locales/en/tooltips.json';
import userEN from './locales/en/user.json';
import validationsEN from './locales/en/validations.json';
import buttonsJA from './locales/ja/buttons.json';
import crawlerJA from './locales/ja/crawler.json';
import descriptionsJA from './locales/ja/descriptions.json';
import errorsJA from './locales/ja/errors.json';
import fieldsJA from './locales/ja/fields.json';
import generalJA from './locales/ja/general.json';
import gpsJA from './locales/ja/gps.json';
import instanceJA from './locales/ja/instance.json';
import menusJA from './locales/ja/menus.json';
import modelJA from './locales/ja/model.json';
import monitoringJA from './locales/ja/monitoring.json';
import notificationsJA from './locales/ja/notifications.json';
import projectJA from './locales/ja/project.json';
import sectionTitlesJA from './locales/ja/sectionTitles.json';
import titlesJA from './locales/ja/titles.json';
import tooltipsJA from './locales/ja/tooltips.json';
import userJA from './locales/ja/user.json';
import validationsJA from './locales/ja/validations.json';

const DEFAULT_NAMESPACE = 'general';

const resources = {
  en: {
    buttons: buttonsEN,
    crawler: crawlerEN,
    descriptions: descriptionsEN,
    fields: fieldsEN,
    general: generalEN,
    notifications: notificationsEN,
    sectionTitles: sectionTitlesEN,
    titles: titlesEN,
    tooltips: tooltipsEN,
    validations: validationsEN,
    menus: menusEN,
    errors: errorsEN,
    project: projectEN,
    instance: instanceEN,
    user: userEN,
    gps: gpsEN,
    monitoring: monitoringEN,
    model: modelEN,
  },
  ja: {
    buttons: buttonsJA,
    crawler: crawlerJA,
    descriptions: descriptionsJA,
    fields: fieldsJA,
    general: generalJA,
    notifications: notificationsJA,
    sectionTitles: sectionTitlesJA,
    titles: titlesJA,
    tooltips: tooltipsJA,
    validations: validationsJA,
    menus: menusJA,
    errors: errorsJA,
    project: projectJA,
    instance: instanceJA,
    user: userJA,
    gps: gpsJA,
    monitoring: monitoringJA,
    model: modelJA,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: 'ja',
  defaultNS: DEFAULT_NAMESPACE,
  debug: process.env.REACT_APP_ENV === 'dev',
  resources,
});

export default i18n;
