import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import { NOTIFICATION_TYPES } from './constants';

const FormButtons = ({ type, onCancel, onSendTest }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between" mt={2} mr={-1}>
      {type === NOTIFICATION_TYPES.EMAIL ? (
        <Button
          variant="contained"
          onClick={onSendTest}
          aria-label="send test email"
        >
          {t('sendTestEmail', { ns: 'buttons' })}
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={onSendTest}
          aria-label="send test webhook"
        >
          {t('sendTestWebhook', { ns: 'buttons' })}
        </Button>
      )}

      <Box>
        <Button
          variant="text"
          style={{ marginRight: '0.5em' }}
          color="default"
          onClick={onCancel}
        >
          {t('cancel', { ns: 'buttons' })}
        </Button>
        <Button variant="text" type="submit">
          {t('confirm', { ns: 'buttons' })}
        </Button>
      </Box>
    </Box>
  );
};

FormButtons.propTypes = {
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSendTest: PropTypes.func.isRequired,
};

export default FormButtons;
