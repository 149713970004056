import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { keys, startCase, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useSearch } from '../../../contexts/SearchContext';
import { sentenceCase } from '../../../utils/utils';
import Accordion from '../../elements/accordion/Accordion';

const MenuList = ({ fqKey, content }) => {
  const { query, refreshSearchWithQuery } = useSearch();

  const addToFilterQuery = (e) => {
    refreshSearchWithQuery(e.target.value);
  };

  return (
    <List dense style={{ width: '100%' }} disablePadding>
      {keys(content).map((item) => (
        <ListItem key={item} dense>
          <ListItemIcon style={{ minWidth: 0 }}>
            <Checkbox
              edge="start"
              tabIndex={-1}
              disableRipple
              onChange={addToFilterQuery}
              value={`${fqKey}:${item}`}
              checked={
                query.jsonQuery.filter.indexOf(`${fqKey}:${item}`) !== -1
              }
            />
          </ListItemIcon>
          <ListItemText primary={`${item} (${content[item]})`} />
        </ListItem>
      ))}
    </List>
  );
};

const FacetingMenu = ({ faceting }) => {
  const { t } = useTranslation();
  const facetKeys = keys(faceting);

  return !isEmpty(facetKeys) ? (
    facetKeys.map((key) => (
      <Accordion
        dense
        key={key}
        header={startCase(key)}
        body={<MenuList fqKey={key} content={faceting[key]} />}
        defaultExpanded
      />
    ))
  ) : (
    <Card variant="outlined">
      <Box p={0.8}>
        {sentenceCase(
          t('unset', {
            ns: 'descriptions',
            text: t('facet', { ns: 'instance' }),
          })
        )}
      </Box>
    </Card>
  );
};

MenuList.propTypes = {
  fqKey: PropTypes.string,
  content: PropTypes.object,
};

FacetingMenu.propTypes = {
  faceting: PropTypes.object,
};

export default FacetingMenu;
