import { useEffect, useState } from 'react';

import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { fileManagerApis } from '../../../apis/fileManagerApis';
import { projectApis } from '../../../apis/projectApis';
import Loading from '../../../components/elements/loading/Loading';
import FileBrowser from '../../../components/page-sections/file-browser/FileBrowser';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { useServer } from '../../../contexts/ServerContext';
import { formatFileList } from '../../../helpers/fileManagerHelpers';
import useFileManager from '../../../hooks/useFileManager';

const HOME_PATH = '/home/gps/scripts';
const BASE_FOLDER_CHAIN = [{ id: 'base_file', name: 'scripts', isDir: true }];

const ServerFileManagerPage = () => {
  const { t } = useTranslation();
  const { projectId, gpsId } = useParams();
  const { server } = useServer();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [folderChain, setFolderChain] = useState(BASE_FOLDER_CHAIN);
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState(HOME_PATH);
  const [project, setProject] = useState(null);

  const { actions, updateFileList } = useFileManager(setFileList, path);

  const handlePageLoad = async () => {
    setLoading(true);
    try {
      const [{ data: projectRes }, { data: fileListRes }] = await requestAll([
        projectApis.getProject(projectId),
        fileManagerApis.getFileList(projectId, gpsId, path),
      ]);
      const formattedFileList = formatFileList(fileListRes);
      setProject(projectRes);
      setFileList(formattedFileList);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handlePageLoad();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateFileList();
    // eslint-disable-next-line
  }, [history, path]);

  const getStructure = (file) => {
    const homePathLength = HOME_PATH.split('/').length - 1;
    const { newPath, newFolderChain } = actions.getStructure(
      file,
      path,
      folderChain,
      homePathLength
    );
    setPath(newPath);
    setFolderChain(newFolderChain);
  };
  const create = (folderName) => actions.create(folderName);
  const upload = (files, type) => actions.upload(files, type);
  const edit = (file) => actions.edit(file, history);
  const download = (files) => actions.download(files);
  const remove = (files) => actions.remove(files);
  const rename = (oldName, newName) => actions.rename(oldName, newName);

  const actionFunctions = {
    getStructure,
    create,
    upload,
    edit,
    download,
    remove,
    rename,
  };

  return (
    <Container>
      {loading && <Loading style={{ marginTop: '1em' }} />}
      {error && (
        <Alert style={{ marginTop: '1em' }} severity="error">
          {t('fetchServerError', { ns: 'notifications' })}
        </Alert>
      )}
      {!loading && project && server && (
        <section>
          <Breadcrumbs projectName={project.name} serverName={server.name} />
          <TitlePanel title={t('fileManager', { ns: 'titles' })} />
          <FileBrowser
            files={fileList}
            folderChain={folderChain}
            actionFunctions={actionFunctions}
          />
        </section>
      )}
    </Container>
  );
};

export default ServerFileManagerPage;
