import { useEffect, useState } from 'react';

import { TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PAYMENT_TYPES } from '../../../../constants';
import { PAYMENT_STATUS } from '../../../../constants/paymentConstants';
import { currencyFormat } from '../../../../utils/paymentUtils';
import { formatDate, formatToTokyoDate } from '../../../../utils/timeUtils';
import Button from '../../../elements/button/Button';
import Text from '../../../elements/text/Text';
import CollapsedTable from './CollapsedTable';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  success: {
    color: theme.palette.success.main,
  },
}));

const Row = ({ data, downloadReceipt }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [data]);

  const renderPayment = (status) => {
    switch (status) {
      case PAYMENT_STATUS.SUCCESS:
        if (data.paymentType === PAYMENT_TYPES.CARD) {
          const fileName = t('billing.receiptName', {
            ns: 'project',
            number: data.number,
            date: formatDate(data.date),
          });

          return (
            <Button
              color="primary"
              size="small"
              startIcon={<GetAppIcon />}
              onClick={() => downloadReceipt(data.id, fileName)}
            >
              {t('receipt', { ns: 'buttons' })}
            </Button>
          );
        } else {
          return (
            <Button
              className={classes.success}
              color="primary"
              variant="text"
              size="small"
              disabled
            >
              <Text className={classes.success} caption>
                {t('billing.success', { ns: 'project' })}
              </Text>
            </Button>
          );
        }
      case PAYMENT_STATUS.FAILED:
        return (
          <Tooltip title={t('failedPayment', { ns: 'tooltips' })}>
            <span>
              <Button
                variant="text"
                startIcon={<ErrorIcon color="error" />}
                disabled
              >
                <Text color="error" caption>
                  {t('billing.failed', { ns: 'project' })}
                </Text>
              </Button>
            </span>
          </Tooltip>
        );
      default:
        return (
          <Button size="small" disabled>
            {t('billing.processing', { ns: 'project' })}
          </Button>
        );
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{data.number}</TableCell>
        <TableCell>{formatToTokyoDate(data.date)}</TableCell>
        <TableCell>{data.description}</TableCell>
        <TableCell>{currencyFormat(data.amountNet)}</TableCell>
        <TableCell>{currencyFormat(data.amountTax)}</TableCell>
        <TableCell>{currencyFormat(data.amountNet + data.amountTax)}</TableCell>
        <TableCell>{renderPayment(data.status)}</TableCell>
      </TableRow>
      <CollapsedTable itemsData={data.items} open={open} />
    </>
  );
};

Row.propTypes = {
  data: PropTypes.object.isRequired,
  downloadReceipt: PropTypes.func.isRequired,
};

export default Row;
