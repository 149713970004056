import { useState, useEffect } from 'react';

import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { modelApis } from '../../../apis/modelApis';
import { projectApis } from '../../../apis/projectApis';
import Loading from '../../../components/elements/loading/Loading';
import InstanceDetails from '../../../components/page-sections/instance-details/InstanceDetails';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import Overview from '../../../components/sections/overview/Overview';

const ModelOverviewPage = () => {
  const { projectId, modelId } = useParams();
  const { t } = useTranslation();

  const [project, setProject] = useState(null);
  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [{ data: projectRes }, { data: modelRes }] = await requestAll([
        projectApis.getProject(projectId),
        modelApis.getModel(projectId, modelId),
      ]);

      setProject(projectRes);
      setModel(modelRes);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {loading && <Loading style={{ marginTop: '1em' }} />}
      {error && (
        <Alert style={{ marginTop: '1em' }} severity="error">
          {t('fetchModelError', { ns: 'notifications' })}
        </Alert>
      )}
      {!loading && model && (
        <section>
          <Breadcrumbs projectName={project.name} modelName={model.name} />
          <Overview data={model} isModel />
          <InstanceDetails instance={model} />
        </section>
      )}
    </Container>
  );
};

export default ModelOverviewPage;
