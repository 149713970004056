import { useEffect, useState, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import { red } from '@material-ui/core/colors';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import { isEmpty, map, pick } from 'lodash';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Button from '../../../elements/button/Button';
import Modal from '../../../sections/modal/Modal';
import DraggableList from '../drag-drop/DraggableList';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SynonymSetModal = ({
  open,
  handleClose,
  handleCreate,
  handleEdit,
  set,
}) => {
  const { t } = useTranslation();
  const seqRef = useRef(0);

  const [reorderList, setReorderList] = useState([]);
  const [submitError, setSubmitError] = useState('');

  const newWordSchema = yup.object().shape({
    newWord: yup
      .string()
      .trim()
      .test('duplicate', '', function (value) {
        const { path, createError } = this;
        if (!value) return true;
        if (map(reorderList, 'value').includes(value)) {
          return createError({
            path,
            message: t('duplicateValueAdded', {
              ns: 'validations',
              value: value,
            }),
          });
        }
        return true;
      }),
  });

  const schema = yup.object().shape({
    comment: yup
      .string()
      .max(500, t('maxLength', { ns: 'validations', max: '500' })),
  });

  const {
    control: inFormControl,
    reset: inFormReset,
    setValue,
    watch,
    trigger,
    formState: { errors: inFormErrors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(newWordSchema),
    shouldUnregister: true,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const newWord = watch('newWord');

  const addWord = () => {
    trigger('newWord').then((isValid) => {
      if (isValid) {
        setReorderList((prevList) => [
          ...prevList,
          { id: `temp-${seqRef.current}`, value: newWord, notSaved: true },
        ]);
        seqRef.current++;
        setValue('newWord', '');
      }
    });
  };

  const onRemoveWord = (index) => {
    const updatedList = reorderList.filter((_, i) => index !== i);
    setReorderList(updatedList);
  };

  const closeModal = () => {
    setSubmitError('');
    handleClose();
  };

  const discardUnsavedChanges = () => {
    inFormReset({ newWord: '' });
    setSubmitError('');
    if (set) {
      // Edit
      setReorderList(set.items);
      reset({ comment: set.comment || '' });
    } else {
      // Create
      setReorderList([]);
      reset({ comment: '' });
    }
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const updatedList = reorder(reorderList, source.index, destination.index);
    setReorderList(updatedList);
  };

  const onSubmit = async (data) => {
    const cleanedList = map(reorderList, (item) => pick(item, ['value']));
    const payload = { ...data, items: cleanedList };
    const error = set
      ? await handleEdit(set.id, payload)
      : await handleCreate(payload);

    if (error) {
      setSubmitError(error);
    } else {
      setReorderList([]);
      closeModal();
    }
  };

  useEffect(() => {
    if (set) {
      setReorderList(set.items);
    }

    // eslint-disable-next-line
  }, [open]);

  const title = set
    ? t('synonym.editSynonymSet', { ns: 'sectionTitles' })
    : t('synonym.createSynonymSet', { ns: 'sectionTitles' });

  return (
    <Modal openModal={open} title={title} handleClose={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {submitError && (
            <Grid item xs={12}>
              <Alert severity="error">{submitError}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="comment"
              control={control}
              defaultValue={set?.comment || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  autoComplete="off"
                  error={!!errors?.comment}
                  helperText={errors?.comment?.message}
                  label={t('synonym.comment', { ns: 'fields' })}
                  inputProps={{ 'aria-label': 'comment' }}
                  rows={2}
                  multiline
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="newWord"
              control={inFormControl}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoComplete="off"
                  label={t('synonym.newWord', { ns: 'fields' })}
                  variant="outlined"
                  error={!!inFormErrors?.newWord}
                  helperText={inFormErrors?.newWord?.message}
                  inputProps={{
                    'aria-label': 'new word',
                  }}
                  style={{ marginBottom: 10 }}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title={t('synonym.addToList', { ns: 'project' })}
                        placement="top"
                        arrow={true}
                        open={!!newWord}
                      >
                        <span>
                          <IconButton
                            title={t('synonym.addWord', { ns: 'project' })}
                            aria-label="add word"
                            type="button"
                            edge="end"
                            style={{ marginRight: -8 }}
                            disabled={!newWord}
                            onClick={addWord}
                          >
                            <AddIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ),
                  }}
                />
              )}
            />

            <Paper variant="outlined">
              {isEmpty(reorderList) ? (
                <Box m={1}>
                  {t('synonym.atLeastOneWord', { ns: 'project' })}
                </Box>
              ) : (
                <DraggableList
                  items={reorderList}
                  onDragEnd={onDragEnd}
                  onRemoveWord={onRemoveWord}
                />
              )}
            </Paper>
          </Grid>
        </Grid>

        <DialogActions>
          <Button
            variant="text"
            type="button"
            onClick={discardUnsavedChanges}
            aria-label="discard"
            style={{ color: red[500] }}
          >
            {set
              ? t('discard', { ns: 'buttons' })
              : t('reset', { ns: 'buttons' })}
          </Button>
          <Button
            color="default"
            variant="text"
            type="reset"
            onClick={closeModal}
          >
            {t('cancel', { ns: 'buttons' })}
          </Button>

          <Button
            variant="text"
            type="submit"
            aria-label="confirm"
            disabled={!isValid || isEmpty(reorderList)}
          >
            {t('confirm', { ns: 'buttons' })}
          </Button>
        </DialogActions>
      </form>
    </Modal>
  );
};

SynonymSetModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func,
  handleEdit: PropTypes.func,
  set: PropTypes.shape({
    id: PropTypes.string.isRequired,
    comment: PropTypes.string,
    items: PropTypes.array,
  }),
};

export default SynonymSetModal;
