import { useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Pagination from '@material-ui/lab/Pagination';
import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../contexts/UserContext';
import { formatDateTimeUTC } from '../../../utils/timeUtils';
import { formatCreatorName, getInitials } from '../../../utils/utils';
import Text from '../../elements/text/Text';
import NoContent from '../../sections/no-content/NoContent';
import SynonymSetActions from './SynonymSetActions';

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: '0.9rem',
    width: theme.spacing(4) - 2,
    height: theme.spacing(4) - 2,
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
  enabled: {
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: '0.9rem',
    marginLeft: 4,
    marginRight: 1,
  },
}));

const SynonymSetTable = ({
  isSearching,
  data,
  handlePageChange,
  ...synonymSetFuncs
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useUser();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { totalPages, content: sets, number: page } = data;

  const [isPageChanging, setIsPageChanging] = useState(false);

  const TABLE_HEADERS = [
    t('synonym.set', { ns: 'project' }),
    t('synonym.editors', { ns: 'project' }),
    t('synonym.lastUpdate', { ns: 'project' }),
  ];

  const handlePagination = async (_, page) => {
    setIsPageChanging(true);
    await handlePageChange(page - 1);
    setIsPageChanging(false);
  };

  const getDisplayWords = (words) => {
    const displayNum = 3;
    if (words.length <= displayNum) return words.join(', ');
    return (
      <Box display="flex" alignItems="center">
        {words.slice(0, displayNum).join(', ')}
        {words.length > displayNum &&
          (isSmallScreen ? (
            '...'
          ) : (
            <Tooltip
              title={
                <>
                  {words.slice(displayNum, words.length).map((word) => (
                    <Box key={word}>{word}</Box>
                  ))}
                </>
              }
            >
              <Box display="flex" alignItems="center">
                <InfoOutlinedIcon className={classes.icon} />
                <Text caption>
                  {t('synonym.moreWord', {
                    count: words.length - displayNum,
                    ns: 'project',
                  })}
                </Text>
              </Box>
            </Tooltip>
          ))}
      </Box>
    );
  };

  return (
    <>
      {!isEmpty(sets) ? (
        <>
          <TableContainer>
            <Table size="small" aria-label="set-table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  {TABLE_HEADERS.map((header, i) => (
                    <TableCell align={i !== 0 ? 'center' : 'left'} key={header}>
                      {header}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {sets.map((set) => (
                  <TableRow key={set.id} aria-label="table row">
                    <TableCell width={20}>
                      {set.comment ? (
                        <Tooltip title={set.comment} aria-label="comment">
                          <ChatOutlinedIcon className={classes.enabled} />
                        </Tooltip>
                      ) : (
                        <ChatOutlinedIcon
                          className={classes.disabled}
                          aria-label="no comment"
                        />
                      )}
                    </TableCell>
                    <TableCell style={{ width: '50%' }}>
                      {getDisplayWords(map(set.items, 'value'))}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: '10%' }}>
                      <AvatarGroup
                        classes={{ avatar: classes.avatar }}
                        aria-label="avatar group"
                      >
                        {set.editorUsers.map((editor, i) => {
                          const name = formatCreatorName(null, editor);
                          return (
                            <Tooltip key={i} title={name}>
                              <Avatar
                                key={i}
                                alt={name}
                                src={editor?.icon}
                                style={{
                                  zIndex: set.editorUsers.length - i,
                                }}
                              >
                                {getInitials(name)}
                              </Avatar>
                            </Tooltip>
                          );
                        })}
                      </AvatarGroup>
                    </TableCell>
                    <TableCell align="center">
                      {formatDateTimeUTC(set.updateDate, user?.timeZone)}
                    </TableCell>
                    <TableCell>
                      <SynonymSetActions set={set} {...synonymSetFuncs} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Pagination
              count={totalPages}
              page={page + 1}
              shape="rounded"
              disabled={isPageChanging}
              onChange={handlePagination}
            />
          </Box>
        </>
      ) : (
        <NoContent
          title={
            isSearching
              ? t('noSynonymSetResult', { ns: 'descriptions' })
              : t('noSynonymSet', { ns: 'descriptions' })
          }
        />
      )}
    </>
  );
};

SynonymSetTable.propTypes = {
  isSearching: PropTypes.bool,
  data: PropTypes.shape({
    content: PropTypes.array,
    totalPages: PropTypes.number,
    number: PropTypes.number,
  }),
  handlePageChange: PropTypes.func.isRequired,
};

export default SynonymSetTable;
