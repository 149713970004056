import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { isLast } from '../../../utils/utils';
import { getBreadcrumbRoutes } from './helpers';

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
}));

const Breadcrumbs = ({
  projectName,
  instanceName,
  extensionName,
  dictionaryName,
  jobName,
  serverName,
  modelName,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // If you're getting a breadcrumb match error,
  // check to make sure you added the route to this helper function.
  const routes = getBreadcrumbRoutes(
    projectName,
    instanceName,
    extensionName,
    dictionaryName,
    jobName,
    serverName,
    modelName
  );

  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className={classes.breadcrumb}
    >
      {breadcrumbs.map(({ match, breadcrumb, key }, i) =>
        isLast(breadcrumbs, i) ? (
          <Typography key={key} variant="caption" color="textPrimary">
            {t(breadcrumb, { ns: 'titles' })}
          </Typography>
        ) : (
          <Link
            key={key}
            component={RouterLink}
            to={match.url}
            color="inherit"
            variant="caption"
          >
            {t(breadcrumb, { ns: 'titles' })}
          </Link>
        )
      )}
    </MuiBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  projectName: PropTypes.string,
  instanceName: PropTypes.string,
  extensionName: PropTypes.string,
  dictionaryName: PropTypes.string,
  jobName: PropTypes.string,
  serverName: PropTypes.string,
  modelName: PropTypes.string,
};

export default Breadcrumbs;
