import i18next from 'i18next';
import * as yup from 'yup';

import i18n from '../../../i18n';
import { sentenceCase } from '../../../utils/utils';

const inputReq = (field, nameSpace) =>
  sentenceCase(
    i18n.t('inputRequired', {
      ns: 'validations',
      field: i18n.t(field, { ns: nameSpace }),
    })
  );

const selectReq = (field, nameSpace) =>
  sentenceCase(
    i18n.t('selectRequired', {
      ns: 'validations',
      field: i18n.t(field, { ns: nameSpace }),
    })
  );

export const schema = () =>
  yup.object().shape({
    settings: yup.object({
      query: yup.object({
        requestHandler: yup.string().when('jsonQuery.params.dense', {
          is: true,
          then: yup.string(),
          otherwise: yup
            .string()
            .required(inputReq('search.requestHandler', 'fields')),
        }),
        collection: yup
          .string()
          .required(selectReq('search.collection', 'fields')),
        uniqueKey: yup
          .string()
          .required(i18n.t('noUniqueKey', { ns: 'validations' })),
        display: yup.object({
          title: yup.string(),
          body: yup.string(),
          image: yup.string(),
          url: yup.string(),
        }),
        rawQueryParams: yup.string(),
        jsonQuery: yup.object({
          query: yup.string().nullable(),
          sort: yup.string(),
          fields: yup.array(),
          params: yup.object({
            dense: yup.bool(),
            denseField: yup.string().when('dense', {
              is: true,
              then: yup
                .string()
                .required(inputReq('search.denseField', 'fields')),
              otherwise: yup.string(),
            }),
            denseTopK: yup.lazy((val) =>
              val === ''
                ? yup.string()
                : yup
                    .number()
                    .typeError(i18next.t('numRequired', { ns: 'validations' }))
                    .positive(
                      i18next.t('positiveRequired', { ns: 'validations' })
                    )
                    .integer(
                      i18next.t('wholeNumRequired', { ns: 'validations' })
                    )
            ),
            hl: yup.boolean(),
            fl: yup.string(),
            requireFieldMatch: yup.boolean(),
            usePhraseHighLighter: yup.boolean(),
            highlightMultiTerm: yup.boolean(),
            facet: yup.boolean(),
            facetField: yup.string(),
            facetQuery: yup.string(),
            df: yup.string(),
            defType: yup
              .string()
              .required(selectReq('search.queryParser', 'fields')),
            debugQuery: yup.boolean(),
            op: yup
              .string()
              .required(selectReq('search.queryOperator', 'fields')),
            alt: yup.string(),
            qf: yup.string(),
            mm: yup.string(),
            pf: yup.string(),
            ps: yup.string(),
            qs: yup.string(),
            tie: yup.string(),
            bq: yup.string(),
            bf: yup.string(),
            uf: yup.string(),
            pf2: yup.string(),
            pf3: yup.string(),
            ps2: yup.string(),
            ps3: yup.string(),
            boost: yup.string(),
            stopwords: yup.boolean(),
            lowercaseOperators: yup.boolean(),
          }),
        }),
      }),
      suggest: yup.object({
        collection: yup.string(),
        requestHandler: yup.string(),
        dictionary: yup.string(),
      }),
    }),
  });

export const saveQuerySchema = () =>
  yup.object().shape({
    queryName: yup
      .string()
      .required(inputReq('search.queryName', 'fields'))
      .max(50, i18n.t('maxLength', { ns: 'validations', max: '50' })),
  });

export const defaultValues = {
  settings: {
    query: {
      requestHandler: '/select',
      collection: '',
      uniqueKey: '',
      display: {
        title: '',
        body: '',
        image: '',
        url: '',
      },
      rawQueryParams: '',
      jsonQuery: {
        query: '',
        sort: '',
        params: {
          dense: false,
          hl: false,
          facet: false,
          defType: 'lucene',
          debugQuery: false,
          op: 'OR',
          df: '',
          stopwords: false,
          lowercaseOperators: false,
        },
      },
    },
    suggest: {
      collection: '',
      requestHandler: '',
      dictionary: '',
    },
  },
};
