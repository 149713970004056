import { useEffect, useState } from 'react';

import { FormHelperText, MenuItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CRAWLER_TYPES,
  JOB_TYPE,
} from '../../../../constants/crawlerConstants';
import Text from '../../../elements/text/Text';
import ProjectSetupIncomplete from '../../../sections/project-setup-incomplete/ProjectSetupIncomplete';
import {
  displayJobType,
  priceDisplay,
  scheduleDisplay,
  seedsDisplay,
} from '../crawlerUtils';

const useStyles = makeStyles({
  root: {
    '& tr > td, tr > th': {
      textAlign: 'center',
    },
  },
});

const CrawlStep1 = ({
  control,
  watch,
  setValue,
  getFreeJobs,
  project,
  projectSetup,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [freeJobs, setFreeJobs] = useState(null);

  const { errors } = useFormState({ control });

  const handleGetFreeJobs = async () => {
    const freeJobsRes = await getFreeJobs();
    setFreeJobs(freeJobsRes);
  };

  useEffect(() => {
    handleGetFreeJobs();
    // eslint-disable-next-line
  }, []);

  const hasFreeJobs = freeJobs?.length > 0;
  const tableHeaders = ['maxSeeds', 'schedule', 'monthPrice'];

  return (
    <Box style={{ overflow: 'hidden' }}>
      {projectSetup ? (
        <section>
          <TableContainer style={{ marginBottom: '1.5em' }}>
            <Table className={classes.root}>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <TableCell key={header} size="small">
                      {t(`stepper.${header}`, {
                        ns: 'crawler',
                      })}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(CRAWLER_TYPES).map((option, i) => (
                  <TableRow key={i}>
                    <TableCell size="small">
                      {seedsDisplay(option.SEEDS)}
                    </TableCell>
                    <TableCell size="small">
                      {scheduleDisplay(option.SCHEDULE)}
                    </TableCell>
                    <TableCell size="small">
                      {priceDisplay(option.PRICE)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Text style={{ marginBottom: '1em' }}>
                {t('crawlerBillingNote', { ns: 'descriptions' })}
              </Text>
              <Text variant="body2">
                {t('crawlerPaymentNote', { ns: 'descriptions' })}
              </Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={!!errors?.jobType}
              >
                <InputLabel>{t('crawlJobType', { ns: 'fields' })}</InputLabel>
                <Controller
                  name="jobType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={(e) => {
                        setValue('seeds', '');
                        setValue('subseeds', '');
                        field.onChange(e);
                      }}
                      label={t('crawlJobType', { ns: 'fields' })}
                      inputProps={{ 'aria-label': 'job select' }}
                    >
                      {Object.entries(CRAWLER_TYPES).map((option) => (
                        <MenuItem
                          value={option[0]}
                          key={option[0]}
                          disabled={option[0] === JOB_TYPE.FREE && !hasFreeJobs}
                        >
                          {displayJobType(option[0])}
                          {option[0] === JOB_TYPE.FREE &&
                            watch('jobType') !== JOB_TYPE.FREE && (
                              <Box
                                display="flex"
                                alignItems="center"
                                bgcolor="text.disabled"
                                borderRadius="5px"
                                ml={1}
                                px={1}
                                color="white"
                              >
                                <Text caption>
                                  {t('remaining', {
                                    ns: 'general',
                                    number: freeJobs?.length || 0,
                                  })}
                                </Text>
                              </Box>
                            )}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>{errors?.jobType?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </section>
      ) : (
        <ProjectSetupIncomplete
          serviceName={t('stepper.subscription', {
            ns: 'crawler',
          })}
          cards={project.cards}
          billingInfo={project.billingInfo}
          managers={project.managers}
        />
      )}
    </Box>
  );
};

CrawlStep1.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  getFreeJobs: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  projectSetup: PropTypes.bool,
  project: PropTypes.object.isRequired,
};

export default CrawlStep1;
