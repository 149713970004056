import { upperFirst } from 'lodash';

import {
  DEFAULT_SEARCH_QUERY,
  DISMAX_FIELDS,
  EDISMAX_FIELDS,
  Q_FIELDS,
  SUGGEST_FIELDS,
  HIGHLIGHT_FIELDS,
} from '../../../constants';

const updateFormObject = (obj, newKey, oldKey) => {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
};

const removeBlankFields = (fieldList, searchForm) => {
  fieldList.forEach((field) => {
    searchForm[field] === '' && delete searchForm[field];
  });
};

export const removeUnusedValues = (form) => {
  return JSON.parse(
    JSON.stringify(form, (key, value) => {
      if (value) {
        return value;
      }
    })
  );
};

const stringToArray = (field) => (field ? field.split(/\s+|,\s+|,/g) : []);

const assignFlValue = (queryData) => {
  const fieldValues = [queryData.settings.query.uniqueKey, 'score'];
  const { title, body, image, url } = queryData.settings.query.display;

  if (title) fieldValues.push(title);
  if (body) fieldValues.push(body);
  if (image) fieldValues.push(image);
  if (url) fieldValues.push(url);

  queryData.settings.query.jsonQuery.fields.push(fieldValues.join(','));

  return queryData;
};

const cleanQueryData = (queryData) => {
  const jsonQuery = queryData.settings.query.jsonQuery;

  const cleanedDf = jsonQuery.params.df || undefined;
  const cleanedQ = jsonQuery.query || DEFAULT_SEARCH_QUERY;

  jsonQuery.params.df = cleanedDf;
  jsonQuery.query = cleanedQ;

  return queryData;
};

export const cleanAndAssignQueryData = (queryData) => {
  return assignFlValue(cleanQueryData(queryData));
};

export const formatForWebApp = (baseUrl, form, filterQueries, facetQueries) => {
  const cleanedForm = cleanQueryData(form);

  const query = cleanedForm.settings.query;
  const formatFacetField = stringToArray(query.jsonQuery.params.facetField);

  const suggest = cleanedForm.settings.suggest;
  updateFormObject(suggest, 'qt', 'requestHandler');
  SUGGEST_FIELDS.map((field) =>
    updateFormObject(suggest, `suggest${upperFirst(field)}`, field)
  );
  suggest.suggestDictionary = stringToArray(suggest.suggestDictionary);

  if (query.jsonQuery.params.dense) {
    updateFormObject(query.jsonQuery.params, 'ksDense', 'dense');
    updateFormObject(query.jsonQuery.params, 'ksDenseField', 'denseField');
    updateFormObject(query.jsonQuery.params, 'ksDenseK', 'denseTopK');
    updateFormObject(query.jsonQuery.params, 'ksDenseModel', 'denseModel');
  }

  const webAppPayload = {
    ...query.jsonQuery.params,
    titleField: query.display.title,
    bodyField: query.display.body,
    imageField: query.display.image,
    urlField: query.display.url,
    rawQueryParameters: query.rawQueryParams,
    baseUrl: baseUrl,
    suggestBaseUrl: baseUrl,
    idField: query.uniqueKey,
    qt: query.requestHandler,
    fq: filterQueries,
    facetField: formatFacetField,
    facetQuery: facetQueries,
    sort: query.jsonQuery.sort,
    collection: query.collection,
    ...suggest,
  };

  Q_FIELDS.map((field) =>
    updateFormObject(webAppPayload, `q${upperFirst(field)}`, field)
  );
  HIGHLIGHT_FIELDS.map((field) =>
    updateFormObject(webAppPayload, `hl${upperFirst(field)}`, field)
  );

  removeBlankFields(DISMAX_FIELDS, webAppPayload);
  removeBlankFields(EDISMAX_FIELDS, webAppPayload);

  return webAppPayload;
};

// Query formatting functions
// helper functions followed by full query formatting function

const facetParamsToQueryFormat = (formParams, facetQueryChips) => {
  if (formParams.facet) {
    formParams['facet.query'] = facetQueryChips;
    if (formParams.facetField) {
      formParams['facet.field'] = formParams.facetField.split(/\s+|,\s+|,/g);
    }
  }
};

const highlightParamsToQueryFormat = (formParams) => {
  if (formParams.hl) {
    // a limitation of react-hook-form is that there is no support for field names that have periods in them.
    // for this reason, the form object has to be transformed before it is sent to the backend to match what solr expects
    HIGHLIGHT_FIELDS.map((field) =>
      updateFormObject(formParams, `hl.${field}`, field)
    );
  }
};

const qFieldsToQueryFormat = (params) => {
  Q_FIELDS.map((field) => updateFormObject(params, `q.${field}`, field));
};

const semanticParamsToQueryFormat = (formParams) => {
  if (formParams.dense) {
    updateFormObject(formParams, '_ks.dense', 'dense');
    updateFormObject(formParams, '_ks.dense.k', 'denseTopK');
    updateFormObject(formParams, '_ks.dense.field', 'denseField');
  }
};

const suggestDictionaryToQueryFormat = (form) => {
  if (form.settings.suggest.dictionary) {
    form.settings.suggest.dictionary =
      form.settings.suggest.dictionary.split(/\s+|,\s+|,/g);
  } else {
    form.settings.suggest.dictionary = [];
  }
};

export const convertFormToQuery = (form, fqChips, facetQueryChips) => {
  const jsonQuery = form.settings.query.jsonQuery;
  const params = jsonQuery.params;

  jsonQuery.filter = fqChips;
  jsonQuery.fields = [];
  jsonQuery.limit = 10;
  jsonQuery.offset = 0;

  facetParamsToQueryFormat(params, facetQueryChips);
  highlightParamsToQueryFormat(params);
  qFieldsToQueryFormat(params);
  semanticParamsToQueryFormat(params);
  suggestDictionaryToQueryFormat(form);

  const cleanedForm = removeUnusedValues(form);
  return cleanedForm;
};

// Form formatting functions
// helper functions followed by full form formatting function

const facetParamsToFormFormat = (params) => {
  updateFormObject(params, 'facetField', 'facet.field');
  if (params.facetField) {
    params.facetField = params.facetField.join(', ');
  }
  delete params['facet.query'];
};

const highlightParamsToFormFormat = (params) => {
  HIGHLIGHT_FIELDS.map((field) =>
    updateFormObject(params, field, `hl.${field}`)
  );
};

const qFieldsToFormFormat = (params) => {
  Q_FIELDS.map((field) => updateFormObject(params, field, `q.${field}`));
};

const semanticParamsToFormFormat = (params) => {
  updateFormObject(params, 'dense', '_ks.dense');
  updateFormObject(params, 'denseTopK', '_ks.dense.k');
  updateFormObject(params, 'denseField', '_ks.dense.field');
};

const suggestDictionaryToFormFormat = (query) => {
  if (query.settings.suggest.dictionary) {
    query.settings.suggest.dictionary =
      query.settings.suggest.dictionary.join(', ');
  }
};

// This is used within a larger form conversion function in the SearchForm.js component.
export const convertToForm = (query) => {
  const queryParams = query.settings.query.jsonQuery.params;

  facetParamsToFormFormat(queryParams);
  highlightParamsToFormFormat(queryParams);
  qFieldsToFormFormat(queryParams);
  semanticParamsToFormFormat(queryParams);
  suggestDictionaryToFormFormat(query);
};
