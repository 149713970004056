import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../contexts/UserContext';
import { formatDateUTC } from '../../../utils/timeUtils';
import Text from '../../elements/text/Text';

const MinorExtDetails = ({ extension }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const { copyright, developerName, downloadCount, license, versions } =
    extension;

  return (
    <Box>
      <Text caption>
        {t('ext.latestVersion', {
          ns: 'project',
          version: versions[0].version,
        })}
      </Text>
      <Text caption component="p">
        {`${t('developer', { ns: 'fields' })}: ${developerName}`}
      </Text>
      {license && (
        <Text caption component="p">
          {`${t('license', { ns: 'fields' })}: ${license}`}
        </Text>
      )}
      <Text caption component="p">
        {t('ext.downloadCount', {
          ns: 'project',
          count: downloadCount,
        })}
      </Text>
      <Text caption component="p">
        {t('ext.latestUpdate', {
          ns: 'project',
          date: formatDateUTC(versions[0].releaseDate, user?.timeZone),
        })}
      </Text>
      {copyright && (
        <Text caption>
          {`${t('copyright', { ns: 'fields' })}: ${copyright}`}
        </Text>
      )}
    </Box>
  );
};

MinorExtDetails.propTypes = {
  extension: PropTypes.object.isRequired,
};

export default MinorExtDetails;
