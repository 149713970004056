import { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TIMEZONE_LIST } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';

const TimezoneSelect = ({
  getValues,
  errors,
  setValue,
  variant = 'standard',
}) => {
  const { t } = useTranslation();
  const { user } = useUser();

  // Sets the timezone to either the form value or users timezone
  const { timezone } = getValues();
  const [zone, setZone] = useState(
    TIMEZONE_LIST.find(({ zoneId }) => zoneId === (timezone || user?.timeZone))
  );

  useEffect(() => {
    setValue('timezone', zone?.zoneId, { shouldValidate: true });
    // eslint-disable-next-line
  }, [zone]);

  return (
    <Autocomplete
      size="small"
      value={zone}
      fullWidth
      options={TIMEZONE_LIST}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.label === value.label}
      groupBy={(option) => option.label.slice(1, 10)}
      onChange={(e, v) => setZone(v)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('timezone', { ns: 'fields' })}
          variant={variant}
          inputProps={{
            ...params.inputProps,
            'aria-label': 'timezone select',
          }}
          error={!!errors.timezone}
          helperText={errors.timezone?.message}
        />
      )}
    />
  );
};

TimezoneSelect.propTypes = {
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

export default TimezoneSelect;
