import { useState } from 'react';

import { makeStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FILE_SIZE, MAX_FILE_NUMBER } from '../../../constants';
import { fileTooBig, fileNotZip, formatBytes } from '../../../utils/utils';
import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';
import ModalButtons from '../../sections/modal/ModalButtons';
import FileUpload from '../file-upload/FileUpload';

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.main,
    paddingBottom: theme.spacing(2),
  },
}));

const ImportModal = ({
  collectionName,
  openModal,
  handleImport,
  handleClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [sizeError, setSizeError] = useState(false);
  const [formatError, setFormatError] = useState(false);

  const handleCollectionImport = (options) => {
    handleImport(collectionName, options, files);
  };

  const handleImportCancel = () => {
    handleClose();
    setFiles([]);
    setFormatError(false);
    setSizeError(false);
  };

  const onDrop = (acceptedFiles) => {
    if (isEmpty(acceptedFiles) || files.length >= MAX_FILE_NUMBER) return;

    setSizeError(fileTooBig(acceptedFiles[0].size, FILE_SIZE.BYTES));
    setFormatError(fileNotZip(acceptedFiles[0].type));

    if (
      !fileTooBig(acceptedFiles[0].size, FILE_SIZE.BYTES) &&
      !fileNotZip(acceptedFiles[0].type)
    ) {
      setSizeError(false);
      setFormatError(false);
      setFiles([...files, ...acceptedFiles]);
    }
  };

  const { handleSubmit, control } = useForm();
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const removeFile = (fileIndex) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(fileIndex), 1);
    setFiles(newFiles);
  };

  return (
    <Modal
      openModal={openModal}
      title={t('collectionImport', { ns: 'instance', name: collectionName })}
      handleClose={handleImportCancel}
    >
      <Text body className={classes.warning}>
        {t('collectionImportWarning', { ns: 'descriptions' })}
      </Text>
      <form onSubmit={handleSubmit(handleCollectionImport)}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <FileUpload
            files={files}
            open={open}
            handleRemove={removeFile}
            formatError={formatError}
            sizeError={sizeError}
            sizeLimit={formatBytes(FILE_SIZE.BYTES, 0)}
            helperText={t('dropOneZipFile', { ns: 'instance' })}
            fileErrorMsg={t('onlyZipFile', { ns: 'descriptions' })}
          />
          <input {...getInputProps()} />
        </div>
        <div>
          <Controller
            name="clear"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label={t('deleteConf', { ns: 'instance' })}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="apply"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label={t('reloadAfterImport', { ns: 'instance' })}
              />
            )}
          />
        </div>
        <ModalButtons
          onCancel={handleImportCancel}
          cancelProps={{ type: 'reset' }}
          actionProps={{
            type: 'submit',
            disabled: sizeError || formatError || isEmpty(files),
          }}
          actionText={t('confirm', { ns: 'buttons' })}
        />
      </form>
    </Modal>
  );
};

ImportModal.propTypes = {
  collectionName: PropTypes.string,
  openModal: PropTypes.bool,
  handleImport: PropTypes.func,
  handleClose: PropTypes.func,
};

export default ImportModal;
