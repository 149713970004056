import { Box } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import PropTypes from 'prop-types';

import Text from '../../../elements/text/Text';

const TextChip = ({ title, text }) => {
  return text ? (
    <Box
      display="inline-flex"
      bgcolor={grey[300]}
      px={0.5}
      borderRadius={3}
      mb={0.5}
      mr={0.5}
    >
      <Text caption style={{ fontWeight: 600 }}>
        {title}:{' '}
      </Text>
      <Text caption style={{ marginLeft: '0.5em' }}>
        {text}
      </Text>
    </Box>
  ) : null;
};

TextChip.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default TextChip;
