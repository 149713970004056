import makeStyles from '@material-ui/core/styles/makeStyles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    border: `1px dashed ${theme.palette.grey[700]}`,
    borderRadius: theme.shape.borderRadius,
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const UploadBox = ({
  sizeLimit,
  sizeError,
  formatError,
  open,
  helperText,
  fileErrorMsg,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.dropzone}>
      <Text>{helperText}</Text>
      <Button
        type="button"
        variant="text"
        onClick={open}
        aria-label="select upload file"
        endIcon={<CloudUploadIcon />}
      >
        {t('chooseFile', { ns: 'buttons' })}
      </Button>
      <Text
        color={sizeError ? 'error' : 'initial'}
        caption
        component="p"
        style={{ marginTop: 15 }}
      >
        {sizeError
          ? t('invalidSize', { ns: 'validations', size: sizeLimit })
          : t('sizeLimit', { ns: 'validations', size: sizeLimit })}
      </Text>
      {formatError && (
        <Text color="error" caption component="p">
          {fileErrorMsg}
        </Text>
      )}
    </div>
  );
};

UploadBox.propTypes = {
  open: PropTypes.func,
  sizeLimit: PropTypes.string,
  formatError: PropTypes.bool,
  sizeError: PropTypes.bool,
  helperText: PropTypes.string,
  fileErrorMsg: PropTypes.string,
};

export default UploadBox;
