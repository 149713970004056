import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import NoContent from '../../../sections/no-content/NoContent';
import { tableDisplay } from '../crawlerUtils';

const useStyles = makeStyles(() => ({
  root: {
    '& tr:last-child > *': {
      borderBottom: 'unset',
    },
  },
}));

const ArgumentsTable = ({ args }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return args ? (
    <TableContainer>
      <Table size="small">
        <TableBody className={classes.root}>
          {Object.entries(args).map((field) =>
            tableDisplay(field[0], field[1], field[0])
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Box mb={1}>
      <NoContent title={t('stepper.noArguments', { ns: 'crawler' })} />
    </Box>
  );
};

ArgumentsTable.propTypes = {
  args: PropTypes.object,
};

export default ArgumentsTable;
