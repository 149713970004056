import { useState } from 'react';

import {
  Box,
  IconButton,
  Tooltip,
  Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Text from '../text/Text';

const CopyableText = ({ id, label, text, upperCase, iconOnly }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [copied, setCopy] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState(
    t('copy', { ns: 'tooltips' })
  );

  const handleClick = () => {
    navigator.clipboard.writeText(text);
    setCopy(true);
    setTooltipMessage(t('copied', { ns: 'tooltips' }));
  };

  return iconOnly ? (
    <Tooltip title={tooltipMessage} arrow={true}>
      <IconButton onClick={handleClick} aria-label="copy text">
        {copied ? (
          <DoneIcon fontSize="small" style={{ color: green[500] }} />
        ) : (
          <AssignmentIcon />
        )}
      </IconButton>
    </Tooltip>
  ) : (
    <Box display="flex" alignItems="center" mt={1}>
      <Tooltip title={tooltipMessage} arrow={true} placement="right">
        <Button
          id={id}
          size="small"
          variant="outlined"
          style={{ textTransform: upperCase ? 'uppercase' : 'lowercase' }}
          endIcon={
            copied ? (
              <DoneIcon fontSize="large" style={{ color: green[500] }} />
            ) : (
              <AssignmentIcon color="disabled" />
            )
          }
          onClick={handleClick}
        >
          <Text
            caption
            style={{
              paddingRight: '0.5em',
              textTransform: 'capitalize',
            }}
          >
            {label}:
          </Text>
          {isSmallScreen ? t('copy', { ns: 'buttons' }) : text}
        </Button>
      </Tooltip>
    </Box>
  );
};

CopyableText.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
  upperCase: PropTypes.bool,
  iconOnly: PropTypes.bool,
};

CopyableText.defaultTypes = {
  copied: false,
};

export default CopyableText;
