import { useState } from 'react';

import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation, Trans } from 'react-i18next';

import { FILE_SIZE, MAX_FILE_MANAGER_NUMBER } from '../../../constants';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { formatBytes, sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    border: `1px dashed ${theme.palette.grey[700]}`,
    borderRadius: theme.shape.borderRadius,
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  icon: {
    fontSize: 50,
    color: theme.palette.grey[700],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const UploadForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showToast } = useToast();
  const [files, setFiles] = useState([]);

  const onDrop = (uploadedFiles) => {
    if (isEmpty(uploadedFiles) || files.length >= MAX_FILE_MANAGER_NUMBER)
      return;
    const remainingFileSpace = MAX_FILE_MANAGER_NUMBER - files.length;
    const validFiles = uploadedFiles.filter(
      (file) => file.size <= FILE_SIZE.BYTES
    );
    const acceptedFiles =
      validFiles.length > remainingFileSpace
        ? validFiles.slice(0, remainingFileSpace)
        : validFiles;
    setFiles([...files, ...acceptedFiles]);
  };

  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    noClick: true,
    noKeyboard: true,
  });

  const removeFile = (fileIndex) => {
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
  };

  const hasFile = files.length > 0;

  const handleSubmit = (e) => {
    e.preventDefault();
    hasFile
      ? onSubmit(files)
      : showToast(
          TOAST_TYPE.ERROR,
          sentenceCase(
            t('selectRequired', {
              ns: 'validations',
              field: t('file', { ns: 'fields' }),
            })
          )
        );
  };

  const uploadBox = (
    <div className={classes.dropzone}>
      <DescriptionOutlinedIcon className={classes.icon} />
      <Trans
        i18nKey="dropFileSelect"
        ns="instance"
        components={[
          <Text key="0" />,
          <Text key="1" component="p" caption />,
          <Button
            key="2"
            type="button"
            variant="text"
            onClick={open}
            aria-label="select upload file"
          />,
        ]}
      />
      <Text caption component="p" style={{ marginTop: 15 }}>
        {t('sizeLimit', {
          ns: 'validations',
          size: formatBytes(FILE_SIZE.BYTES, 0),
        })}
      </Text>
      <Text caption component="p">
        {t('fileExcluded', {
          ns: 'validations',
          size: formatBytes(FILE_SIZE.BYTES, 0),
        })}
      </Text>
    </div>
  );

  const fileList = files.map((file, i) => (
    <ListItem key={file.path}>
      <ListItemAvatar>
        <Avatar>
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={file.path} secondary={formatBytes(file.size)} />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="remove file"
          onClick={() => removeFile(i)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ));

  const fileSection = (
    <div className={classes.fileList}>
      <Text bodyBold color="textSecondary">
        {t('selectedFile', { ns: 'instance' })}
      </Text>
      <List dense aria-label="file list" disablePadding>
        {fileList}
      </List>
    </div>
  );

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        {!hasFile && uploadBox}
        {hasFile && fileSection}

        <form onSubmit={handleSubmit}>
          <input {...getInputProps()} aria-label="upload file" />
          <Box textAlign="right" my={2}>
            <Button
              type="submit"
              disabled={!hasFile}
              aria-label="upload submit"
            >
              {t('upload', { ns: 'buttons' })}
            </Button>
          </Box>
        </form>
      </div>
    </section>
  );
};

UploadForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UploadForm;
