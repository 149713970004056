import { useState, createContext, useContext, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { collectionApis } from '../apis/collectionApis';
import { solrApis } from '../apis/solrApis';
import { SolrError } from '../customError';
import { matchError } from '../helpers';
import i18next from '../i18n';

export const SearchContext = createContext();

const SearchProvider = ({ children }) => {
  const { projectId, instanceId } = useParams();

  const originalFilterQueries = useRef([]);
  const cache = useRef({});

  const [results, setResults] = useState([]);
  const [query, setQuery] = useState([]);
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const [isPageChanging, setIsPageChanging] = useState(false);
  const [isLoadingStoredFields, setIsLoadingStoredFields] = useState(false);
  const [storedFields, setStoredFields] = useState(null);

  // This is for syncing the fq on query form and query results
  const [filtersInForm, setFiltersInForm] = useState([]);

  // This is to track what fq are added in faceting panel
  const [filtersFromSearch, setFiltersFromSearch] = useState([]);

  const resetFilters = () => {
    setFiltersInForm([]);
    setFiltersFromSearch([]);
    originalFilterQueries.current = [];
  };

  const clearQuery = () => setQuery([]);
  const clearStoreFields = () => setStoredFields(null);

  const addOrRemoveFromFilters = (list, value) => {
    const copied = [...(list || [])];
    const index = copied.indexOf(value);
    index > -1 ? copied.splice(index, 1) : copied.push(value);
    return copied;
  };

  const validateSolrError = (data) => {
    // Handle Solr errors
    if (data.solrResponse.error)
      throw new SolrError(data.solrResponse.error.msg);
    if (data.solrResponse.htmlError)
      throw new SolrError(i18next.t('handlerError', { ns: 'errors' }));
  };

  const handleError = (error) => {
    if (error instanceof SolrError) {
      setError(error.message);
    } else {
      const { message } = matchError(error);
      setError(message);
    }
  };

  const fetchStoredFields = async (uniqueKeyQuery) => {
    setIsLoadingStoredFields(true);

    if (cache.current[uniqueKeyQuery]) {
      const data = cache.current[uniqueKeyQuery];
      setStoredFields(data);
    } else {
      try {
        // send request with minimal params
        const storedFieldsQuery = {
          requestHandler: query.requestHandler,
          collection: query.collection,
          uniqueKey: query.uniqueKey,
          jsonQuery: {
            fields: ['*'],
            query: uniqueKeyQuery,
          },
        };

        const { data } = await solrApis.executeQuery(
          projectId,
          instanceId,
          storedFieldsQuery
        );

        validateSolrError(data);

        const doc = data.solrResponse.response.docs?.[0];
        cache.current[uniqueKeyQuery] = doc; // set response in cache;
        setStoredFields(doc);
      } catch (error) {
        setStoredFields(null);
      }
    }

    setIsLoadingStoredFields(false);
  };

  const fetchSearchResults = async (query) => {
    cache.current = {};

    try {
      const { data } = await solrApis.executeQuery(
        projectId,
        instanceId,
        query
      );

      validateSolrError(data);

      setResults({ url: data.url, ...data.solrResponse });

      originalFilterQueries.current = query.jsonQuery.filter.filter(
        (f) => !filtersFromSearch.includes(f)
      );

      setQuery(data.query);
      setError('');
    } catch (error) {
      handleError(error);
      setResults([]);
    }
  };

  const executeSearch = async (query) => {
    setIsFetching(true);
    await fetchSearchResults(query);
    setIsFetching(false);
  };

  const handlePageChange = async (offset) => {
    setIsPageChanging(true);

    const newQuery = { ...query };
    newQuery.jsonQuery.offset = offset;

    await fetchSearchResults(newQuery);
    setIsPageChanging(false);
  };

  const refreshSearchWithQuery = async (fq) => {
    const searchFilters = addOrRemoveFromFilters(filtersFromSearch, fq);
    setFiltersFromSearch(searchFilters);

    if (filtersInForm.includes(fq)) {
      const formFilters = addOrRemoveFromFilters(filtersInForm, fq);
      setFiltersInForm(formFilters);
    }
  };

  const updateFilters = async (fq) => {
    const filters = addOrRemoveFromFilters(filtersInForm, fq);
    setFiltersInForm(filters);

    if (originalFilterQueries.current.includes(fq)) {
      const newFilters = addOrRemoveFromFilters(
        originalFilterQueries.current,
        fq
      );
      originalFilterQueries.current = newFilters;
    }

    if (filtersFromSearch.includes(fq)) {
      const newFilters = addOrRemoveFromFilters(filtersFromSearch, fq);
      setFiltersFromSearch(newFilters);
    }
  };

  const deleteDocument = async (docId) => {
    const deleteBody = { id: docId };
    await collectionApis.deleteDoc(
      projectId,
      instanceId,
      query.collection,
      deleteBody
    );

    // refetch search results
    fetchSearchResults(query);
  };

  useEffect(() => {
    if (!query.jsonQuery) return;

    const refreshSearch = async () => {
      query.jsonQuery.filter = [
        ...new Set([...originalFilterQueries.current, ...filtersFromSearch]),
      ];
      // reset start
      query.jsonQuery.offset = 0;

      await fetchSearchResults(query);
    };

    refreshSearch();
    setFiltersInForm([
      ...new Set([
        ...originalFilterQueries.current,
        ...filtersInForm,
        ...filtersFromSearch,
      ]),
    ]);

    // eslint-disable-next-line
  }, [filtersFromSearch]);

  return (
    <SearchContext.Provider
      value={{
        isFetching,
        isPageChanging,
        error,
        query,
        results,
        executeSearch,
        refreshSearchWithQuery,
        handlePageChange,
        filtersInForm,
        updateFilters,
        resetFilters,
        isLoadingStoredFields,
        storedFields,
        fetchStoredFields,
        clearStoreFields,
        clearQuery,
        deleteDocument,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context)
    throw new Error('useSearch must be used within SearchContextProvider');

  return context;
};

SearchProvider.propTypes = {
  children: PropTypes.node,
};

export default SearchProvider;
