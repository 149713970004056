import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  makeStyles,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  MenuItem,
  Menu,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MoreVert from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import {
  getSolrSubdomain,
  isSolrCloud,
  sentenceCase,
} from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';
import ModalButtons from '../../sections/modal/ModalButtons';
import NoContent from '../../sections/no-content/NoContent';
import AddModal from './AddModal';
import DeleteDocsModal from './DeleteDocsModal';
import DeleteModal from './DeleteModal';
import ImportModal from './ImportModal';

// Allows strings containing alphanumeric characters, -, _ and . between 1 and 15 characters
const COLLECTION_NAME_FORMAT_VALIDATION = /^[a-zA-Z0-9-_.]{1,50}$/g;

const useStyles = makeStyles((theme) => ({
  spaceLeft: {
    marginLeft: 10,
  },
  redText: {
    color: theme.palette.error.main,
  },
}));

const InstanceCollectionSection = ({
  collections,
  instance,
  onAdd,
  onReload,
  onUpdate,
  onDelete,
  onImport,
  onExport,
  onDeleteDocs,
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { showToast } = useToast();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('collectionName', { ns: 'fields' }),
          })
        )
      )
      .matches(
        COLLECTION_NAME_FORMAT_VALIDATION,
        t('collectionNameFormat', { ns: 'validations' })
      )
      .notOneOf(
        collections.map((collection) => collection.name),
        sentenceCase(
          t('duplicate', {
            ns: 'validations',
            field: t('collectionName', { ns: 'fields' }),
          })
        )
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const [selectedCollection, setSelectedCollection] = useState(null);
  const [menu, setMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [reloadModal, setReloadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteDocModal, setDeleteDocModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [solrDomain, setSolrDomain] = useState('');

  const { subdomain, type, category, instances } = instance;

  useEffect(() => {
    setSolrDomain(
      getSolrSubdomain(subdomain, type, category, { isCluster: true })
    );
    // eslint-disable-next-line
  }, [instance]);

  const handleOpenMenu = (event, collection) => {
    setAnchorEl(event.target);
    setMenu(collection);
  };

  const handleCloseMenu = () => setMenu(null);

  const toggleAddModal = () => setAddModal(!addModal);
  const toggleUpdateModal = () => setUpdateModal(!updateModal);
  const toggleReloadModal = (collection) => {
    setReloadModal(!reloadModal);
    collection
      ? setSelectedCollection(collection)
      : setTimeout(() => setSelectedCollection(null), 100);
  };
  const toggleDeleteModal = (collection) => {
    setDeleteModal(!deleteModal);
    setMenu(null);
    collection
      ? setSelectedCollection(collection)
      : setTimeout(() => setSelectedCollection(null), 100);
  };
  const toggleDeleteDocModal = (collection) => {
    setDeleteDocModal(!deleteDocModal);
    setMenu(null);
    collection
      ? setSelectedCollection(collection)
      : setTimeout(() => setSelectedCollection(null), 100);
  };
  const toggleImportModal = (collection) => {
    setImportModal(!importModal);
    setMenu(null);
    collection
      ? setSelectedCollection(collection)
      : setTimeout(() => setSelectedCollection(null), 100);
  };

  const handleAdd = (data) => {
    toggleAddModal();
    onAdd(data);
  };

  const handleUpdate = (data) => {
    toggleUpdateModal();
    onUpdate(selectedCollection, data.name);
  };

  const handleReload = () => {
    toggleReloadModal();
    onReload(selectedCollection);
  };

  const handleDelete = (options) => {
    toggleDeleteModal();
    setMenu(null);
    onDelete(selectedCollection, options);
  };

  const handleImport = (collectionName, options, file) => {
    toggleImportModal();
    onImport(collectionName, options, file);
  };

  const handleExport = (collection) => {
    setMenu(null);
    onExport(collection);
  };

  const handleDeleteDocs = () => {
    toggleDeleteDocModal();
    onDeleteDocs(selectedCollection);
  };

  const handleCopy = (collectionName) => {
    const collectionUrl = `${solrDomain}/${collectionName}/`;
    navigator.clipboard.writeText(collectionUrl);
    showToast(
      TOAST_TYPE.INFO,
      t('copiedCollectionUrl', { ns: 'notifications' })
    );
  };

  return (
    <section>
      <Text>{t('collectionDescription', { ns: 'descriptions' })}</Text>
      <Button variant="text" onClick={toggleAddModal}>
        <AddIcon />
        {t('add', {
          ns: 'buttons',
          text: t('collection', { ns: 'instance' }),
        })}
      </Button>

      <Grid container>
        <Grid item xs={12}>
          <List data-testid="collection-list">
            {isLoading && <LinearProgress color="secondary" />}
            {isEmpty(collections) ? (
              <NoContent
                title={sentenceCase(
                  t('empty', {
                    ns: 'descriptions',
                    text: t('collection', { ns: 'instance' }),
                  })
                )}
              />
            ) : (
              collections.map((collection) => (
                <div key={collection.name}>
                  <ListItem>
                    <ListItemText
                      primary={collection.name}
                      secondary={
                        <>
                          {collection.shards && (
                            <Text caption>
                              {t('shards', { ns: 'fields' })}:{' '}
                              {collection.shards}
                            </Text>
                          )}
                          {collection.replicationFactor && (
                            <Text className={classes.spaceLeft} caption>
                              {t('replicationFactor', { ns: 'fields' })}:{' '}
                              {collection.replicationFactor}
                            </Text>
                          )}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Tooltip
                        title={t('copyCollectionUrl', { ns: 'instance' })}
                        arrow={true}
                      >
                        <IconButton
                          onClick={() => handleCopy(collection.name)}
                          aria-label="copy text"
                        >
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        aria-label="reload"
                        onClick={() => toggleReloadModal(collection.name)}
                      >
                        <RefreshIcon />
                      </IconButton>
                      {isSmallScreen ? (
                        <IconButton
                          aria-label="more-actions-icon"
                          onClick={(e) => handleOpenMenu(e, collection.name)}
                        >
                          <MoreVert />
                        </IconButton>
                      ) : (
                        <Button
                          variant="text"
                          size="small"
                          className={classes.spaceLeft}
                          onClick={(e) => handleOpenMenu(e, collection.name)}
                          aria-label="more-actions"
                        >
                          {t('more', { ns: 'buttons' })}
                          <ArrowDropDown />
                        </Button>
                      )}
                      <Menu
                        anchorEl={anchorEl}
                        open={collection.name === menu}
                        onClose={() => handleCloseMenu(collection.name)}
                      >
                        <MenuItem
                          onClick={() => toggleImportModal(collection.name)}
                        >
                          {t('import', { ns: 'buttons' })}
                        </MenuItem>
                        <MenuItem onClick={() => handleExport(collection.name)}>
                          {t('export', { ns: 'buttons' })}
                        </MenuItem>
                        <MenuItem
                          className={classes.redText}
                          onClick={() => toggleDeleteDocModal(collection.name)}
                        >
                          {t('deleteDocs', { ns: 'instance' })}
                        </MenuItem>
                        <MenuItem
                          className={classes.redText}
                          onClick={() => toggleDeleteModal(collection.name)}
                        >
                          {t('delete', { ns: 'buttons' })}
                        </MenuItem>
                      </Menu>
                      {/* To be added back in later version
                    <IconButton
                      edge="end"
                      aria-label="update"
                      onClick={() => toggleAddUpdateModal(collection)}
                    >
                      <EditIcon />
                    </IconButton>
                    */}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </div>
              ))
            )}
          </List>
        </Grid>
      </Grid>

      <AddModal
        openModal={addModal}
        instanceType={type}
        instanceNodes={instances.length}
        schema={schema}
        handleAdd={handleAdd}
        handleClose={toggleAddModal}
      />

      <Modal
        openModal={updateModal}
        title={t('update', {
          ns: 'project',
          text: t('collection', { ns: 'instance' }),
        })}
        handleClose={() => toggleUpdateModal(null)}
      >
        <Text gutterBottom>
          {t('enterNewCollectionName', { ns: 'sentences' })}
        </Text>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <TextField
            {...register('name')}
            id="name"
            fullWidth
            autoComplete="off"
            error={!!errors?.name}
            helperText={errors?.name?.message}
            label={t('collectionName', { ns: 'fields' })}
            defaultValue={selectedCollection}
          />
          <ModalButtons
            onCancel={toggleUpdateModal}
            onAction={handleUpdate}
            actionText={t('save', { ns: 'buttons' })}
            cancelProps={{ type: 'reset' }}
            actionProps={{ disabled: !isValid }}
          />
        </form>
      </Modal>

      <Modal
        openModal={reloadModal}
        title={t('collectionReload', { ns: 'instance' })}
        handleClose={() => toggleReloadModal(null)}
        handleConfirm={handleReload}
        showActions
      >
        <Text>
          {t('reloadCollection', {
            ns: 'descriptions',
            text: selectedCollection,
          })}
        </Text>
      </Modal>

      <DeleteModal
        openModal={deleteModal}
        isSolrCloud={isSolrCloud(type)}
        collectionName={selectedCollection}
        handleClose={() => toggleDeleteModal(null)}
        handleDelete={handleDelete}
      />

      <ImportModal
        openModal={importModal}
        collectionName={selectedCollection}
        handleImport={handleImport}
        handleClose={() => toggleImportModal(null)}
      />

      <DeleteDocsModal
        openModal={deleteDocModal}
        collectionName={selectedCollection}
        onDeleteDocs={handleDeleteDocs}
        onClose={() => toggleDeleteDocModal(null)}
      />
    </section>
  );
};

InstanceCollectionSection.propTypes = {
  collections: PropTypes.array.isRequired,
  instance: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onDeleteDocs: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default InstanceCollectionSection;
