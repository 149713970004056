import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import { getInitials } from '../../../utils/utils';
import Image from '../../elements/image/Image';
import Text from '../../elements/text/Text';

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: theme.spacing(6),
  },
  badge: {
    color: theme.palette.grey[600],
    borderColor: theme.palette.grey[400],
    fontSize: theme.typography.caption.fontSize,
  },
  spaceLeft: {
    marginLeft: theme.spacing(1),
  },
}));

const ProjectListItem = ({ project }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListItem>
      <ListItemAvatar>
        <Image
          size="small"
          shape="rounded"
          alt={project.name}
          src={project?.icon}
        >
          {getInitials(project.name)}
        </Image>
      </ListItemAvatar>

      <ListItemText
        primary={
          <Box display="flex" alignItems="center">
            <Link
              component={RouterLink}
              to={buildPath({
                path: PRIVATE_ROUTE.PROJECT_OVERVIEW,
                params: { projectId: project.id },
              })}
              color="inherit"
            >
              {project.name}
            </Link>
            <Chip
              aria-label="user role"
              label={project.role}
              size="small"
              variant="outlined"
              className={`${classes.badge} ${classes.spaceLeft}`}
            />
          </Box>
        }
        secondary={
          isSmallScreen && (
            <Text caption aria-label="members">
              {t('memberCount', { ns: 'project', count: project.memberCount })}
            </Text>
          )
        }
      />

      {!isSmallScreen && (
        <ListItemSecondaryAction>
          <Box display="flex">
            <PeopleIcon className={classes.spaceRight} />
            <Text aria-label="members">{project.memberCount}</Text>
          </Box>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

ProjectListItem.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectListItem;
