import { useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import Title from '../../elements/title/Title';
import Modal from '../../sections/modal/Modal';

const useStyles = makeStyles((theme) => ({
  responsive: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

const PasswordReset = ({ handleReset }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleModal = () => setOpen((prev) => !prev);

  const onReset = async () => {
    await handleReset();
    toggleModal();
  };

  return (
    <>
      <Title subTitle text={t('passwordReset', { ns: 'sectionTitles' })} />

      <Text>{t('passwordChange', { ns: 'descriptions' })}</Text>
      <Text>{t('passwordResetButtonClicked', { ns: 'descriptions' })}</Text>

      <Box className={classes.responsive} my={2}>
        <Button
          color="danger"
          onClick={toggleModal}
          aria-label="reset password"
        >
          {t('reset', { ns: 'buttons' })}
        </Button>
        <Modal
          openModal={open}
          title={t('confirmation', {
            ns: 'sectionTitles',
            text: t('passwordReset', { ns: 'sectionTitles' }),
          })}
          handleConfirm={onReset}
          handleClose={toggleModal}
          showActions
        >
          <Text>
            {sentenceCase(
              t('resetAction', {
                ns: 'descriptions',
                text: t('password', { ns: 'fields' }),
              })
            )}
          </Text>
          <Text>{t('reset', { ns: 'descriptions' })}</Text>
        </Modal>
      </Box>
    </>
  );
};

PasswordReset.propTypes = {
  handleReset: PropTypes.func.isRequired,
};

export default PasswordReset;
