import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { useUser } from '../../../contexts/UserContext';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import { formatDateTimeUTC } from '../../../utils/timeUtils';
import {
  getInitials,
  formatUserInfo,
  runningNodeExists,
} from '../../../utils/utils';
import Image from '../../elements/image/Image';
import Status from '../../elements/status/Status';
import Text from '../../elements/text/Text';
import UserTooltip from '../../elements/user-tooltip/UserTooltip';
import InstanceChip from '../instance-chip/InstanceChip';
import UserAvatar from '../user-avatar/UserAvatar';

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: theme.spacing(6),
  },
  badge: {
    color: theme.palette.grey[600],
    borderColor: theme.palette.grey[400],
    fontSize: theme.typography.caption.fontSize,
  },
  spaceLeft: {
    marginLeft: theme.spacing(1),
  },
  creator: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

const InstanceListItem = ({ instance }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useUser();
  const { projectId } = useParams();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListItem>
      <ListItemAvatar>
        <Image
          size="small"
          shape="rounded"
          alt={instance.name}
          src={instance?.icon}
        >
          {getInitials(instance.name)}
        </Image>
      </ListItemAvatar>

      <ListItemText
        primary={
          <Box display="flex" alignItems="center">
            <Link
              component={RouterLink}
              to={buildPath({
                path: PRIVATE_ROUTE.INSTANCE_OVERVIEW,
                params: { projectId, instanceId: instance.id },
              })}
              color="inherit"
            >
              {instance.name}
            </Link>
            <Status
              instanceState={instance.status}
              solrRunning={runningNodeExists(instance.instances)}
            />
            <InstanceChip
              category={instance.category}
              plan={instance.instances[0].type}
              ml={2}
            />
          </Box>
        }
        secondary={
          isSmallScreen && (
            <Box>
              <UserTooltip user={instance.author}>
                <Text
                  className={classes.creator}
                  component="a"
                  caption
                  aria-label="createdBy"
                >
                  {t('creator', { ns: 'instance' })}:{' '}
                  {instance.author.publicName}
                </Text>
              </UserTooltip>

              <Text caption className={classes.createdDate}>
                {t('createdAt', {
                  ns: 'instance',
                  date: formatDateTimeUTC(instance.createDate, user?.timeZone),
                })}
              </Text>
            </Box>
          )
        }
      />

      {!isSmallScreen && (
        <ListItemSecondaryAction>
          <Box display="flex" alignItems="center">
            <UserTooltip user={instance.author}>
              <UserAvatar
                user={formatUserInfo(instance.createdBy, instance.author)}
              />
            </UserTooltip>

            {instance.createDate && (
              <Box ml={1}>
                <Text caption className={classes.createdDate}>
                  {t('createdAt', {
                    ns: 'instance',
                    date: formatDateTimeUTC(
                      instance.createDate,
                      user?.timeZone
                    ),
                  })}
                </Text>
              </Box>
            )}
          </Box>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

InstanceListItem.propTypes = {
  instance: PropTypes.object.isRequired,
};

export default InstanceListItem;
