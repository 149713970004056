import { MenuItem, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LANGUAGE_LIST } from '../../../constants';
import { cookies } from '../../../utils/utils';
import Title from '../../elements/title/Title';

const LanguageForm = ({ onSubmit }) => {
  const { i18n, t } = useTranslation();

  const handleLanguageChange = async (e) => {
    const { name, value } = e.target;

    await onSubmit(name, value);
    cookies.set('locale', value);
    i18n.changeLanguage(value);
  };

  const select = (
    <Select
      value={i18n.language}
      name="locale"
      label="Language"
      onChange={handleLanguageChange}
      inputProps={{ 'aria-label': 'language select' }}
      style={{
        backgroundColor: 'initial',
      }}
      fullWidth
    >
      {LANGUAGE_LIST.map((lang) => (
        <MenuItem key={lang.code} value={lang.code}>
          {lang.label}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <>
      <Title subTitle text={t('languageSettings', { ns: 'sectionTitles' })} />

      <Grid container spacing={1} alignItems="flex-end">
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          {select}
        </Grid>
      </Grid>
    </>
  );
};

LanguageForm.propTypes = {
  onSubmit: PropTypes.func,
  isPublic: PropTypes.bool,
};

export default LanguageForm;
