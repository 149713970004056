import { Box, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { INSTANCE_PLANS } from '../../../constants';
import Text from '../../elements/text/Text';
import Title from '../../elements/title/Title';
import VirusScansSection from './VirusScansSection';

const AntivirusSection = ({
  antivirus,
  instanceSize,
  onAntivirusChange,
  fetchVirusScans,
}) => {
  const { t } = useTranslation();
  const handleChange = (event) => onAntivirusChange(event.target.checked);

  return (
    <section>
      <Title text={t('antivirus.settings', { ns: 'instance' })} subTitle />
      <Box pl={4}>
        <Box display="flex" alignItems="center">
          <Text bodyBold>{t('antivirus.switch', { ns: 'instance' })}</Text>
          <Switch
            name="antivirus"
            checked={antivirus?.enabled || false}
            color="primary"
            onChange={handleChange}
            inputProps={{ 'aria-label': 'antivirus switch' }}
            disabled={instanceSize === INSTANCE_PLANS.SMALL}
          />
        </Box>
        <VirusScansSection
          antivirus={antivirus}
          instanceSize={instanceSize}
          fetchVirusScans={fetchVirusScans}
        />
      </Box>
    </section>
  );
};

AntivirusSection.propTypes = {
  antivirus: PropTypes.object,
  instanceSize: PropTypes.string,
  fetchVirusScans: PropTypes.func,
  onAntivirusChange: PropTypes.func.isRequired,
};

export default AntivirusSection;
