import { saveAs } from 'file-saver';
import { camelCase, find, isEmpty, keys, startCase } from 'lodash';

import brandImage from '../assets/brand/kandasearch-brand-logo.svg';
import AmexIcon from '../assets/card-brands/amex.svg';
import DinerClubIcon from '../assets/card-brands/dinersclub.svg';
import GeneralIcon from '../assets/card-brands/general.svg';
import JcbIcon from '../assets/card-brands/jcb.svg';
import MastercardIcon from '../assets/card-brands/mastercard.svg';
import VisaIcon from '../assets/card-brands/visa.svg';
import Svg from '../components/elements/svg/Svg';
import { MIME_TYPE } from '../components/page-sections/file-browser/constants';
import { INSTANCE_CATEGORY, INSTANCE_TYPES } from '../constants';

export const sentenceCase = (string) =>
  string
    .toLowerCase()
    .replace(/(^\s*\w|[\\.\\!\\?]\s*\w)/g, (letter) => letter.toUpperCase());

export const getCardIcon = (type, width) => {
  const props = { width: width, style: { display: 'flex' } };
  switch (type.toLowerCase()) {
    case 'amex':
      return <Svg src={AmexIcon} {...props} />;
    case 'dinerclub':
      return <Svg src={DinerClubIcon} {...props} />;
    case 'jcb':
      return <Svg src={JcbIcon} {...props} />;
    case 'mastercard':
      return <Svg src={MastercardIcon} {...props} />;
    case 'visa':
      return <Svg src={VisaIcon} {...props} />;
    default:
      return <Svg src={GeneralIcon} {...props} />;
  }
};

export const isLast = (list, index) => index === list.length - 1;

export const getFileFormData = (key, file, fileName = undefined) => {
  const formData = new FormData();
  fileName ? formData.append(key, file, fileName) : formData.append(key, file);
  return formData;
};

export const mapBlobFormData = (
  data,
  requestTextProp,
  fileProp = { formKey: 'icon', requestKey: 'image' }
) => {
  const formData = new FormData();

  const nonFileData = keys(data).reduce((dataObj, prop) => {
    data[fileProp.formKey] && prop === fileProp.formKey
      ? formData.append(fileProp.requestKey, data[fileProp.formKey] || null)
      : (dataObj[prop] = data[prop] ?? null);
    return dataObj;
  }, {});

  formData.append(
    requestTextProp,
    new Blob([JSON.stringify(nonFileData)], {
      type: 'application/json',
    })
  );

  return formData;
};

export const getInitials = (text) => {
  if (!text) return '';
  return text.slice(0, 2).toUpperCase();
};

export const getSolrSubdomain = (
  subdomain,
  instanceType,
  instanceCategory,
  { isCluster, subscriptNumber }
) => {
  const solrDomain =
    instanceCategory === INSTANCE_CATEGORY.COMMUNITY
      ? process.env.REACT_APP_KANDASEARCH_SOLR_COMMUNITY_DOMAIN
      : process.env.REACT_APP_KANDASEARCH_DOMAIN;

  if (solrDomain) {
    if (isSolrCloud(instanceType) && !isCluster)
      return `https://${subdomain}--${subscriptNumber}.${solrDomain}/solr`;
    if (instanceType === INSTANCE_TYPES.STANDALONE || isCluster)
      return `https://${subdomain}.${solrDomain}/solr`;
  } else {
    return '';
  }
};

export const getGpsSubdomain = (subdomain) => {
  if (!subdomain) return '';
  return `https://${subdomain}.${process.env.REACT_APP_KANDASEARCH_DOMAIN}`;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const runningNodeExists = (instances) => {
  if (!instances) return false;
  return instances.some((node) => node.status === true);
};

export const formatExtensionType = (type) => {
  if (!type) return;

  const className = camelCase(type);
  const display = startCase(type.toLowerCase());
  return { className, display };
};

export const fileTooBig = (size, sizeLimit) => size > sizeLimit;
export const fileNotZip = (type) =>
  type !== MIME_TYPE.zip && type !== MIME_TYPE['window-zip'];
export const fileNotJsonOrXml = (type) =>
  type !== MIME_TYPE.json && type !== MIME_TYPE.xml;
export const fileNotJsonOrCsv = (type) =>
  type !== MIME_TYPE.json && type !== MIME_TYPE.csv && type !== MIME_TYPE.txt;

export const match = (list, match, matchProp) => {
  if (matchProp) {
    return find(list, (i) => i[matchProp] === match) || '';
  } else {
    return find(list, (i) => i === match) || '';
  }
};

export const createAndDownloadFile = (name, url) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const isSolrCloud = (type) => type === INSTANCE_TYPES.SOLRCLOUD;

export const formatCreatorName = (createBy, user) => {
  if (createBy === 'system') {
    return 'KandaSearch';
  }

  return user?.publicName || '';
};

export const formatUserInfo = (createdBy, user) => {
  if (createdBy === 'system') {
    user.name = 'KandaSearch';
    user.icon = brandImage;
  }

  return user;
};

export const isVersionCompatible = (originalVersion, targetVersion) => {
  const regex = /[0-9]+\.[0-9]+/g;
  if (typeof originalVersion === 'string') {
    if (!originalVersion) return true;

    const origin = originalVersion.match(regex)?.[0];
    const target = targetVersion.match(regex)?.[0];
    return origin === target;
  }

  if (Array.isArray(originalVersion)) {
    if (isEmpty(originalVersion)) return true;

    return originalVersion.some((ver) => {
      const origin = ver.match(regex)?.[0];
      const target = targetVersion.match(regex)?.[0];
      return origin === target;
    });
  }
};

export const isEnglish = (value) =>
  /^[A-Za-z0-9][A-Za-z0-9_-\s]*[A-Za-z0-9]$/.test(value);
export const isJapanese = (value) =>
  /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/.test(
    value
  );

export const cookies = {
  set: (name, value, expireDays = 30) => {
    const date = new Date();
    date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/';
  },
  get: (name) => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
    for (const c of cookies) {
      const cookie = c.trim();
      if (cookie.indexOf(`${name}=`) === 0)
        return cookie.substring(`${name}=`.length, cookie.length);
    }
    return '';
  },
};

export const processHealthCheckResponse = (healthCheck) => {
  if (healthCheck) {
    const id = healthCheck.id;
    const settings = {
      checkInterval: healthCheck.period,
      checkMethod: healthCheck.type,
      renoticeInterval: healthCheck.notificationPeriod,
    };
    const status = { ...healthCheck.result, checkDate: healthCheck.checkDate };

    return {
      id: id,
      settings: settings,
      status: status,
      monitor: healthCheck.enabled || false,
    };
  } else {
    return { id: null, settings: null, status: null, monitor: false };
  }
};

export const downloadFile = (file) => {
  const fileType = MIME_TYPE[file.ext];
  const blob = new Blob([file], { type: fileType });
  saveAs(blob, file.name);
};

export const getFilenameFromResponseHeader = (headers) => {
  const rawName = headers['content-disposition'].split('filename=')[1];
  const cleanedFileName = rawName.substring(1, rawName.length - 1);
  return cleanedFileName;
};

export const mapSolrStatusToInstance = (instance, solrStatus) => {
  instance.instances.forEach((node) => {
    const nodeStatus = solrStatus.find((item) => item.id === node.id);
    node.status = nodeStatus.status;
  });

  return instance;
};

export const updateObjectValues = (oldData, updatedValues) => {
  const newData = { ...oldData };
  // updatedValues should be an object with same keys as oldData
  const updatedValueKeys = Object.keys(updatedValues);

  if (updatedValueKeys.length > 0) {
    updatedValueKeys.forEach((valKey) => {
      newData[valKey] = updatedValues[valKey];
    });
  }

  return newData;
};

export const mapCidrs = (cidrArray) => {
  if (cidrArray.length > 0) {
    const formattedCidrs = cidrArray.map((c) => ({ cidr: c.cidr }));
    return formattedCidrs;
  } else {
    return [];
  }
};
