import { useState } from 'react';

import { Box } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { FILE_SIZE, MAX_FILE_NUMBER } from '../../../constants';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import {
  fileNotZip,
  fileTooBig,
  formatBytes,
  sentenceCase,
} from '../../../utils/utils';
import Button from '../../elements/button/Button';
import FileUpload from '../file-upload/FileUpload';

const ZipFileUploadForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { showToast } = useToast();

  const [files, setFiles] = useState([]);
  const [sizeError, setSizeError] = useState(false);
  const [formatError, setFormatError] = useState(false);

  const onDrop = (acceptedFiles) => {
    if (isEmpty(acceptedFiles) || files.length >= MAX_FILE_NUMBER) return;

    setSizeError(fileTooBig(acceptedFiles[0].size, FILE_SIZE.BYTES));
    setFormatError(fileNotZip(acceptedFiles[0].type));

    if (
      !fileTooBig(acceptedFiles[0].size, FILE_SIZE.BYTES) &&
      !fileNotZip(acceptedFiles[0].type)
    ) {
      setSizeError(false);
      setFormatError(false);
      setFiles([...files, ...acceptedFiles]);
    }
  };

  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const removeFile = (fileIndex) => {
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
  };

  const hasFile = files.length > 0;

  const handleSubmit = (e) => {
    e.preventDefault();
    hasFile
      ? onSubmit(files)
      : showToast(
          TOAST_TYPE.ERROR,
          sentenceCase(
            t('selectRequired', {
              ns: 'validations',
              field: t('file', { ns: 'fields' }),
            })
          )
        );
  };

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <FileUpload
          files={files}
          open={open}
          handleRemove={removeFile}
          formatError={formatError}
          sizeError={sizeError}
          sizeLimit={formatBytes(FILE_SIZE.BYTES, 0)}
          helperText={t('dropOneZipFile', { ns: 'instance' })}
          fileErrorMsg={t('onlyZipFile', { ns: 'descriptions' })}
        />
        <form onSubmit={handleSubmit}>
          <input {...getInputProps()} aria-label="upload file" />
          <Box textAlign="right" my={2}>
            <Button
              type="submit"
              disabled={!hasFile}
              aria-label="upload submit"
            >
              {t('upload', { ns: 'buttons' })}
            </Button>
          </Box>
        </form>
      </div>
    </section>
  );
};

ZipFileUploadForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ZipFileUploadForm;
