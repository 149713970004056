import { useEffect, useState } from 'react';

import { Box, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { userApis } from '../../../apis/userApis';
import Loading from '../../../components/elements/loading/Loading';
import FA2Section from '../../../components/page-sections/2fa/FA2Section';
import SocialCards from '../../../components/page-sections/social-signin/SocialCards';
import DeleteSection from '../../../components/sections/delete/DeleteSection';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { useUser } from '../../../contexts/UserContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { sentenceCase } from '../../../utils/utils';

// Page
const InstanceSettingsPage = () => {
  const { t, i18n } = useTranslation();

  const { keycloak } = useKeycloak();
  const { user, refreshUser } = useUser();

  const [userProviders, setUserProviders] = useState(null);
  const [areProjects, setAreProjects] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { showToast } = useToast();

  const fetchData = async (user) => {
    try {
      if (!user) return;

      setLoading(true);
      const [userProvidersRequest, getProjectsRequest] = await requestAll([
        userApis.getUserProviders(user.id, window.location.href),
        userApis.getUserProjects(user.id),
      ]);

      setUserProviders(userProvidersRequest.data);
      setAreProjects(!getProjectsRequest.data.empty);
    } catch (error) {
      handleErrors(history, error, showToast);
      setRequestError(true);
    } finally {
      setLoading(false);
    }
  };

  // component events
  const toggle2FA = async () => {
    const otpState = user.otp;

    try {
      !otpState
        ? await userApis.enableUser2FA(user.id)
        : await userApis.disableUser2FA(user.id);
    } catch {
      showToast(
        TOAST_TYPE.ERROR,
        otpState
          ? t('enabled2FAError', { ns: 'notifications' })
          : t('disabled2FAError', { ns: 'notifications' })
      );
    } finally {
      await keycloak.updateToken(1800);
      if (otpState) {
        await refreshUser();
      } else {
        keycloak.login({ locale: i18n.language });
      }
    }
  };

  const onConnect = (provider, url) => {
    window.location.href = url;
  };

  const onDisconnect = async (provider) => {
    try {
      await userApis.deleteUserProvider(user.id, provider);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('socialProviders', { ns: 'sectionTitles' }),
          })
        )
      );
    } catch {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('socialProviders', { ns: 'sectionTitles' }),
          })
        )
      );
    } finally {
      fetchData(user);
    }
  };

  const onDelete = async () => {
    try {
      await userApis.deleteUser(user.id);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('account', { ns: 'user' }),
          })
        )
      );

      setTimeout(() => {
        keycloak.logout({
          redirectUri: process.env.REACT_APP_KANDASEARCH_HOME_PAGE,
        });
      }, 2000);
    } catch {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('account', { ns: 'user' }),
          })
        )
      );
    }
  };

  useEffect(() => {
    fetchData(user);
    // eslint-disable-next-line
  }, [user]);

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : requestError ? (
        <Box mt={2}>
          <Alert severity="error">
            {sentenceCase(
              t('loading', {
                ns: 'errors',
                content: t('account', { ns: 'titles' }),
              })
            )}
          </Alert>
        </Box>
      ) : (
        <>
          <TitlePanel title={t('account', { ns: 'titles' })} />
          {user && <FA2Section enabled={user.otp} toggle2FA={toggle2FA} />}
          {userProviders && (
            <SocialCards
              providerData={userProviders}
              handleConnect={onConnect}
              handleDisconnect={onDisconnect}
            />
          )}
          {user && (
            <DeleteSection
              title={t('accountDeletion', { ns: 'sectionTitles' })}
              description={t('accountDeletionWarning', { ns: 'descriptions' })}
              button={t('deleteAccount', { ns: 'buttons' })}
              validation={user.login}
              onDelete={onDelete}
              disableMessage={
                areProjects
                  ? t('removeProjectBeforeDeleteAccount', {
                      ns: 'descriptions',
                    })
                  : ''
              }
            />
          )}
        </>
      )}
    </Container>
  );
};

export default InstanceSettingsPage;
