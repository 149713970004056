import { SIDEBAR_TYPE as TYPE } from '../components/sections/sidebar/constants';
import ForbiddenError from '../pages/errors/403';
import NotFoundError from '../pages/errors/404';
import InternalServerError from '../pages/errors/500';
import ServerFileEditorPage from '../pages/private/gps/ServerFileEditorPage';
import ServerFileManagerPage from '../pages/private/gps/ServerFileManagerPage';
import ServerJobsDetailsPage from '../pages/private/gps/ServerJobsDetailsPage';
import ServerJobsPage from '../pages/private/gps/ServerJobsPage';
import ServerOverviewPage from '../pages/private/gps/ServerOverviewPage';
import ServerSettingsPage from '../pages/private/gps/ServerSettingsPage';
import ModelOverviewPage from '../pages/private/model/ModelOverviewPage';
import ModelSettingsPage from '../pages/private/model/ModelSettingsPage';
import ProjectModelListPage from '../pages/private/model/ProjectModelListPage';
import AdminImageAssetsPage from '../pages/private/other/AdminImageAssetsPage';
import ExtensionDetailsPage from '../pages/private/other/ExtensionDetailsPage';
import ExtensionLibraryPage from '../pages/private/other/ExtensionLibraryPage';
import UserAccountPage from '../pages/private/other/UserAccountPage';
import UserProfilePage from '../pages/private/other/UserProfilePage';
import BillingPage from '../pages/private/project/billing-page/BillingPage';
import NewProjectPage from '../pages/private/project/NewProjectPage';
import ProjectBackupPage from '../pages/private/project/ProjectBackupPage';
import ProjectCrawlerDetailsPage from '../pages/private/project/ProjectCrawlerDetailsPage';
import ProjectCrawlerStatusDetailsPage from '../pages/private/project/ProjectCrawlerJobStatusPage';
import ProjectCrawlerPage from '../pages/private/project/ProjectCrawlerPage';
import ProjectInstanceListPage from '../pages/private/project/ProjectInstanceListPage';
import ProjectMemberInvitationPage from '../pages/private/project/ProjectMemberInvitationPage';
import ProjectMemberPage from '../pages/private/project/ProjectMemberPage';
import ProjectOverviewPage from '../pages/private/project/ProjectOverviewPage';
import ProjectRestorePage from '../pages/private/project/ProjectRestorePage';
import ProjectServerListPage from '../pages/private/project/ProjectServerListPage';
import ProjectSettingsPage from '../pages/private/project/ProjectSettingsPage';
import ProjectsPage from '../pages/private/project/ProjectsPage';
import ProjectSubsExtension from '../pages/private/project/ProjectSubsExtensions';
import ProjectSynonymDictionariesPage from '../pages/private/project/ProjectSynonymDictionariesPage';
import ProjectSynonymDictionaryPage from '../pages/private/project/ProjectSynonymDictionaryPage';
import ConfigPage from '../pages/private/solr/ConfigPage';
import FileEditorPage from '../pages/private/solr/FileEditorPage';
import FileManagerPage from '../pages/private/solr/FileManagerPage';
import InstanceCollectionPage from '../pages/private/solr/InstanceCollectionsPage';
import InstanceHealthCheckPage from '../pages/private/solr/InstanceHealthCheckPage';
import InstanceMonitoringPage from '../pages/private/solr/InstanceMonitoringPage';
import InstanceOverviewPage from '../pages/private/solr/InstanceOverviewPage';
import InstanceSearchPage from '../pages/private/solr/InstanceSearchPage';
import InstanceSettingsPage from '../pages/private/solr/InstanceSettingsPage';
import NewInstancePage from '../pages/private/solr/NewInstancePage';
import { PRIVATE_ROUTE } from './routes';

const PAGE_ROUTES = {
  private: [
    {
      path: PRIVATE_ROUTE.ADMIN_IMAGE_ASSETS,
      component: AdminImageAssetsPage,
      exact: true,
    },
    {
      path: PRIVATE_ROUTE.USER_PROFILE,
      component: UserProfilePage,
      exact: true,
      layoutProps: { sidebar: TYPE.USER },
    },
    {
      path: PRIVATE_ROUTE.USER_ACCOUNT,
      component: UserAccountPage,
      exact: true,
      layoutProps: { sidebar: TYPE.USER },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_NEW,
      component: NewProjectPage,
      exact: true,
    },
    {
      path: PRIVATE_ROUTE.PROJECTS,
      component: ProjectsPage,
      exact: true,
    },
    {
      path: PRIVATE_ROUTE.PROJECT_MEMBER_INVITATION,
      component: ProjectMemberInvitationPage,
      exact: true,
    },
    {
      path: PRIVATE_ROUTE.EXTENSIONS,
      component: ExtensionLibraryPage,
      exact: true,
    },
    {
      path: PRIVATE_ROUTE.EXTENSION_DETAILS,
      component: ExtensionDetailsPage,
      exact: true,
    },
    {
      path: PRIVATE_ROUTE.PROJECT_OVERVIEW,
      component: ProjectOverviewPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_MEMBER,
      component: ProjectMemberPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_BILLING,
      component: BillingPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT, requireAdmin: true },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_BACKUP,
      component: ProjectBackupPage,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_RESTORE,
      component: ProjectRestorePage,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_SUBS_EXTENSIONS,
      component: ProjectSubsExtension,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_SYNONYM_DICTIONARIES,
      component: ProjectSynonymDictionariesPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_SYNONYM_DICTIONARY,
      component: ProjectSynonymDictionaryPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_CRAWLER,
      component: ProjectCrawlerPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_CRAWLER_DETAILS,
      component: ProjectCrawlerDetailsPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_CRAWLER_JOB_STATUS,
      component: ProjectCrawlerStatusDetailsPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_SETTINGS,
      component: ProjectSettingsPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT, requireAdmin: true },
    },
    {
      path: PRIVATE_ROUTE.INSTANCE_NEW,
      component: NewInstancePage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT, requireAdmin: true },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_INSTANCE_LIST,
      component: ProjectInstanceListPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.INSTANCE_OVERVIEW,
      component: InstanceOverviewPage,
      exact: true,
      layoutProps: { sidebar: TYPE.INSTANCE },
    },
    {
      path: PRIVATE_ROUTE.INSTANCE_COLLECTIONS,
      component: InstanceCollectionPage,
      exact: true,
      layoutProps: { sidebar: TYPE.INSTANCE },
    },
    {
      path: PRIVATE_ROUTE.INSTANCE_CONFIG,
      component: ConfigPage,
      exact: true,
      layoutProps: { sidebar: TYPE.INSTANCE },
    },
    {
      path: PRIVATE_ROUTE.INSTANCE_FILE_MANAGER,
      component: FileManagerPage,
      exact: true,
      layoutProps: { sidebar: TYPE.INSTANCE },
    },
    {
      path: PRIVATE_ROUTE.INSTANCE_FILE_EDITOR,
      component: FileEditorPage,
      exact: true,
      layoutProps: { sidebar: TYPE.INSTANCE },
    },
    {
      path: PRIVATE_ROUTE.INSTANCE_HEALTH_CHECK,
      component: InstanceHealthCheckPage,
      exact: true,
      layoutProps: { sidebar: TYPE.INSTANCE },
    },
    {
      path: PRIVATE_ROUTE.INSTANCE_SEARCH,
      component: InstanceSearchPage,
      exact: true,
      layoutProps: { sidebar: TYPE.INSTANCE },
    },
    {
      path: PRIVATE_ROUTE.INSTANCE_MONITORING,
      component: InstanceMonitoringPage,
      exact: true,
      layoutProps: { sidebar: TYPE.INSTANCE },
    },
    {
      path: PRIVATE_ROUTE.INSTANCE_SETTINGS,
      component: InstanceSettingsPage,
      exact: true,
      layoutProps: { sidebar: TYPE.INSTANCE, requireAdmin: true },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_GPS_LIST,
      component: ProjectServerListPage,
      exact: true,
      layoutProps: { sidebar: TYPE.PROJECT },
    },
    {
      path: PRIVATE_ROUTE.GPS_OVERVIEW,
      component: ServerOverviewPage,
      exact: true,
      layoutProps: { sidebar: TYPE.GPS },
    },
    {
      path: PRIVATE_ROUTE.GPS_JOBS,
      component: ServerJobsPage,
      exact: true,
      layoutProps: { sidebar: TYPE.GPS },
    },
    {
      path: PRIVATE_ROUTE.GPS_JOBS_DETAILS,
      component: ServerJobsDetailsPage,
      exact: true,
      layoutProps: { sidebar: TYPE.GPS },
    },
    {
      path: PRIVATE_ROUTE.GPS_FILE_MANAGER,
      component: ServerFileManagerPage,
      exact: true,
      layoutProps: { sidebar: TYPE.GPS },
    },
    {
      path: PRIVATE_ROUTE.GPS_FILE_EDITOR,
      component: ServerFileEditorPage,
      exact: true,
      layoutProps: { sidebar: TYPE.GPS },
    },
    {
      path: PRIVATE_ROUTE.GPS_SETTINGS,
      component: ServerSettingsPage,
      exact: true,
      layoutProps: { sidebar: TYPE.GPS, requireAdmin: true },
    },
    {
      path: PRIVATE_ROUTE.PROJECT_MODEL_LIST,
      component: ProjectModelListPage,
      exact: true,
      layoutProps: { sidebar: TYPE.MODEL },
    },
    {
      path: PRIVATE_ROUTE.MODEL_OVERVIEW,
      component: ModelOverviewPage,
      exact: true,
      layoutProps: { sidebar: TYPE.MODEL },
    },
    {
      path: PRIVATE_ROUTE.MODEL_SETTINGS,
      component: ModelSettingsPage,
      exact: true,
      layoutProps: { sidebar: TYPE.MODEL },
    },
    { path: PRIVATE_ROUTE.ERROR403, component: ForbiddenError, exact: true },
    { path: PRIVATE_ROUTE.ERROR404, component: NotFoundError, exact: true },
    {
      path: PRIVATE_ROUTE.ERROR500,
      component: InternalServerError,
      exact: true,
    },
  ],
};

export default PAGE_ROUTES;
