import { INSTANCE_CATEGORY } from '../constants';

export const INSTANCE_STATE = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  TERMINATING: 'TERMINATING',
  TERMINATED: 'TERMINATED',
  FAILED: 'FAILED',
};

export const isInstanceRunning = (instance) => {
  if (!instance) return false;

  return instance.status === INSTANCE_STATE.RUNNING;
};

export const isInstanceCreated = (instanceState) => {
  if (!instanceState) return false;

  return (
    instanceState !== INSTANCE_STATE.PENDING &&
    instanceState !== INSTANCE_STATE.TERMINATED
  );
};

export const isInstanceProcessing = (instance) => {
  if (!instance) return false;

  return (
    instance.status === INSTANCE_STATE.PENDING ||
    instance.status === INSTANCE_STATE.TERMINATING
  );
};

export const isServerCreated = (instanceState) => {
  if (!instanceState) return false;
  return instanceState === INSTANCE_STATE.CREATED;
};

export const isNotCommunityInstance = (instance) => {
  if (!instance) return false;
  return instance.category !== INSTANCE_CATEGORY.COMMUNITY;
};
