import { useState, useEffect } from 'react';

import { Container } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';

import { requestAll } from '../../../../apis/config';
import { projectApis } from '../../../../apis/projectApis';
import { solrApis } from '../../../../apis/solrApis';
import Loading from '../../../../components/elements/loading/Loading';
import BillingHistory from '../../../../components/page-sections/billing/billing-history/BillingHistory';
import BillingInfo from '../../../../components/page-sections/billing/billing-info/BillingInfo';
import PaymentMethod from '../../../../components/page-sections/billing/payment-method/PaymentMethod';
import { MIME_TYPE } from '../../../../components/page-sections/file-browser/constants';
import Breadcrumbs from '../../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../../components/sections/title-panel/TitlePanel';
import { PAYMENT_TYPES } from '../../../../constants';
import { handleErrors } from '../../../../helpers';
import useToast, { TOAST_TYPE } from '../../../../hooks/useToast';
import { sentenceCase } from '../../../../utils/utils';

const BillingPage = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { showToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [project, setProject] = useState(null);
  const [card, setCard] = useState(null);
  const [billingInfo, setBillingInfo] = useState(null);
  const [billHistory, setBillHistory] = useState([]);
  const [canDeletePayment, setCanDeletePayment] = useState(false);

  const fetchData = async () => {
    try {
      const [projectRes, instancesRes, cardRes, infoRes, billHistoryRes] =
        await requestAll([
          projectApis.getProject(projectId),
          solrApis.getInstances(projectId),
          projectApis.getProjectCards(projectId),
          projectApis.getProjectBillingInfo(projectId),
          projectApis.getProjectBillingHistory(projectId),
        ]);

      setProject(projectRes.data);
      instancesRes.data.content.length === 0 && setCanDeletePayment(true);
      setCard(cardRes.data[0] || null);
      setBillingInfo(infoRes.data.content[0] || null);
      setBillHistory(billHistoryRes.data);
      setLoading(false);
    } catch (error) {
      handleErrors(history, error, showToast);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleBillingSubmit = async (data) => {
    const billingPayload = {
      ...data,
      paymentType: billingInfo?.paymentType || PAYMENT_TYPES.CARD,
    };

    try {
      billingInfo
        ? await projectApis.updateProjectBillingInfo(
            projectId,
            billingInfo?.id,
            billingPayload
          )
        : await projectApis.createProjectBillingInfo(projectId, billingPayload);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('billingInfo', { ns: 'sectionTitles' }),
          })
        )
      );

      const infoRes = await projectApis.getProjectBillingInfo(projectId);
      setBillingInfo(infoRes.data.content[0]);
    } catch (error) {
      const message = t('submit', {
        ns: 'errors',
        content: t('billing', { ns: 'titles' }),
      });
      showToast(TOAST_TYPE.ERROR, sentenceCase(message));
    }
  };

  const handleAddPayment = async (data) => {
    try {
      const requestPayload = { stripeCardTokenId: data.id };
      await projectApis.createProjectCard(projectId, requestPayload);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('addSuccess', {
            ns: 'notifications',
            text: t('billing.creditCard', { ns: 'project' }),
          })
        )
      );

      const cardRes = await projectApis.getProjectCards(projectId);
      setCard(cardRes.data[0] || null);
    } catch (error) {
      const message = t('submit', {
        ns: 'errors',
        content: t('billing.creditCard', { ns: 'project' }),
      });
      showToast(TOAST_TYPE.ERROR, sentenceCase(message));
    }
  };

  const handleDeletePayment = async () => {
    try {
      await projectApis.deleteProjectCard(projectId, card.id);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('billing.creditCard', { ns: 'project' }),
          })
        )
      );

      const cardRes = await projectApis.getProjectCards(projectId);
      setCard(cardRes.data[0] || null);
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('delete', { ns: 'errors' }));
    }
  };

  const handleUpdatePayment = async (data) => {
    try {
      await projectApis.updateProjectCard(projectId, card.id, data);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('billing.creditCard', { ns: 'project' }),
          })
        )
      );

      const cardRes = await projectApis.getProjectCards(projectId);
      setCard(cardRes.data[0] || null);
    } catch (error) {
      const message = t('submit', {
        ns: 'errors',
        content: t('billing.creditCard', { ns: 'project' }),
      });
      showToast(TOAST_TYPE.ERROR, sentenceCase(message));
    }
  };

  const handleHistoryPageChange = async (page) => {
    try {
      const billHistoryRes = await projectApis.getProjectBillingHistory(
        projectId,
        page
      );
      setBillHistory(billHistoryRes.data);
    } catch (error) {
      const message = t('fetch', {
        ns: 'errors',
        content: t('billing', { ns: 'titles' }),
      });
      showToast(TOAST_TYPE.ERROR, sentenceCase(message));
    }
  };

  const downloadReceipt = async (bpsId, fileName) => {
    try {
      const { data } = await projectApis.downloadReceipt(projectId, bpsId);
      const blob = new Blob([data], { type: MIME_TYPE.pdf });
      FileSaver.saveAs(blob, fileName);

      const message = t('fileDownloadSuccess', { ns: 'notifications' });
      showToast(TOAST_TYPE.SUCCESS, sentenceCase(message));
    } catch (error) {
      const message = t('fileDownloadError', { ns: 'notifications' });
      showToast(TOAST_TYPE.ERROR, sentenceCase(message));
    }
  };

  return (
    <Container>
      {loading && <Loading />}
      {!loading && !error && (
        <>
          <Breadcrumbs projectName={project.name} />
          <TitlePanel title={t('billing', { ns: 'titles' })} />
          <PaymentMethod
            billingInfo={billingInfo}
            card={card}
            handleUpdate={handleUpdatePayment}
            handleAdd={handleAddPayment}
            handleDelete={handleDeletePayment}
            canDelete={canDeletePayment}
          />
          <BillingInfo
            billingInfo={billingInfo}
            handleConfirm={handleBillingSubmit}
          />
          <BillingHistory
            billingData={billHistory}
            handlePagination={handleHistoryPageChange}
            downloadReceipt={downloadReceipt}
          />
        </>
      )}
    </Container>
  );
};

export default BillingPage;
