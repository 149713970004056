import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { sentenceCase } from '../../../../utils/utils';
import Modal from '../../../sections/modal/Modal';

const BillingInfoModal = ({
  billingData,
  visible,
  handleClose,
  handleConfirm,
}) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    payerCompany: yup
      .string()
      .max(50, t('maxLength', { ns: 'validations', max: 50 }))
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('companyName', { ns: 'fields' }),
          })
        )
      ),
    payerSection: yup
      .string()
      .max(50, t('maxLength', { ns: 'validations', max: '50' })),
    payerName: yup
      .string()
      .max(50, t('maxLength', { ns: 'validations', max: '50' }))
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('contactName', { ns: 'fields' }),
          })
        )
      ),
    payerEmail: yup
      .string()
      .email(
        sentenceCase(
          t('invalidFormat', {
            ns: 'validations',
            field: t('email', { ns: 'fields' }),
          })
        )
      )
      .max(256, t('maxLength', { ns: 'validations', max: '256' }))
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('email', { ns: 'fields' }),
          })
        )
      ),
    payerAddress: yup
      .string()
      .max(512, t('maxLength', { ns: 'validations', max: '512' }))
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('address', { ns: 'fields' }),
          })
        )
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleFormSubmit = (data) => {
    handleConfirm(data);
    handleClose();
  };

  return (
    <Modal
      showActions
      openModal={visible}
      title={t('billingInfo', { ns: 'sectionTitles' })}
      handleClose={handleClose}
      handleConfirm={handleSubmit(handleFormSubmit)}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="payerCompany"
              control={control}
              defaultValue={billingData?.payerCompany || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('companyName', { ns: 'fields' })}
                  error={!!errors?.payerCompany}
                  helperText={errors?.payerCompany?.message}
                  fullWidth
                  inputProps={{ 'aria-label': 'companyName' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="payerSection"
              control={control}
              defaultValue={billingData?.payerSection || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('departmentName', { ns: 'fields' })}
                  error={!!errors?.payerSection}
                  helperText={errors?.payerSection?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="payerName"
              control={control}
              defaultValue={billingData?.payerName || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('contactName', { ns: 'fields' })}
                  error={!!errors?.payerName}
                  helperText={errors?.payerName?.message}
                  fullWidth
                  inputProps={{ 'aria-label': 'contact' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="payerEmail"
              control={control}
              defaultValue={billingData?.payerEmail || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('email', { ns: 'fields' })}
                  error={!!errors?.payerEmail}
                  helperText={errors?.payerEmail?.message}
                  fullWidth
                  inputProps={{ 'aria-label': 'email address' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="payerAddress"
              control={control}
              defaultValue={billingData?.payerAddress || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('address', { ns: 'fields' })}
                  error={!!errors?.payerAddress}
                  helperText={errors?.payerAddress?.message}
                  fullWidth
                  inputProps={{ 'aria-label': 'address' }}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

BillingInfoModal.propTypes = {
  billingData: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default BillingInfoModal;
