import { useState } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
// import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../../elements/button/Button';
import Text from '../../../elements/text/Text';

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));

const CollapsibleRow = ({ rowHeader, colSpan, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <Box display="flex" justifyContent="space-between">
            <Text bodyBold>{rowHeader}</Text>
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex">
            <Button
              color="light"
              style={{ marginLeft: '-0.5em' }}
              variant="text"
              size="small"
              onClick={() => setOpen(!open)}
              endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {t('clickForDetails', { ns: 'buttons' })}
            </Button>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={colSpan}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {children}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

CollapsibleRow.propTypes = {
  rowHeader: PropTypes.string.isRequired,
  colSpan: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default CollapsibleRow;
