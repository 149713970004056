import { Box, TableCell } from '@material-ui/core';
import StorageIcon from '@material-ui/icons/Storage';
import PropTypes from 'prop-types';

import { formatBytes, sentenceCase } from '../../../../utils/utils';
import Text from '../../../elements/text/Text';

const TypeCell = ({ category, size }) => {
  return (
    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
      <Text body>{sentenceCase(category)}</Text>
      {size && (
        <Box
          display="inline-flex"
          alignItems="center"
          px={0.5}
          border="1px solid gray"
          borderRadius={3}
        >
          <StorageIcon fontSize="small" />
          <Text caption style={{ marginLeft: '0.5em', cursor: 'default' }}>
            {!isNaN(size) ? formatBytes(size) : '-'}
          </Text>
        </Box>
      )}
    </TableCell>
  );
};

TypeCell.propTypes = {
  category: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default TypeCell;
