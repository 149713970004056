import { useRef } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  variantSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  variantError: {
    backgroundColor: theme.palette.error.main,
  },
  variantInfo: {
    backgroundColor: theme.palette.info.main,
  },
  variantWarning: {
    backgroundColor: theme.palette.warning.main,
  },
}));

const ToastProvider = ({ children }) => {
  const classes = useStyles();
  const notistackRef = useRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      preventDuplicate
      ariaAttributes={{ 'aria-label': 'toast' }}
      classes={classes}
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      iconVariant={{
        error: (
          <CancelOutlinedIcon
            style={{ fontSize: 22, paddingRight: '0.5rem' }}
          />
        ),
        info: (
          <ErrorOutlineIcon style={{ fontSize: 22, paddingRight: '0.5rem' }} />
        ),
        success: (
          <CheckCircleOutlineIcon
            style={{ fontSize: 22, paddingRight: '0.5rem' }}
          />
        ),
        warning: (
          <WarningRoundedIcon
            style={{ fontSize: 22, paddingRight: '0.5rem' }}
          />
        ),
      }}
      action={(key) => (
        <IconButton
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={onClickDismiss(key)}
        >
          <CloseIcon />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node,
};

export default ToastProvider;
