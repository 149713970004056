import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import WarningIcon from '@material-ui/icons/Warning';
import { values } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation } from 'react-router-dom';

import {
  isInstanceRunning,
  isNotCommunityInstance,
} from '../../../apis/constants';
import { runningNodeExists } from '../../../utils/utils';
import Logo from '../logo/Logo';
import { SIDEBAR_TYPE as TYPE, INSTANCE_SIDEBAR_ITEMS } from './constants';
import { getSidebarItems } from './helpers';
import { useStyles } from './Sidebar.styles';

const SideBarItem = ({ open, item, isAdmin, ec2Instance }) => {
  const { t } = useTranslation();
  const classes = useStyles(open);
  const { pathname } = useLocation();

  const ec2Running = isInstanceRunning(ec2Instance);
  const solrRunning = runningNodeExists(ec2Instance?.instances);
  const notCommunity = isNotCommunityInstance(ec2Instance);

  const itemEnabled = (success, failure) => {
    switch (item.name) {
      case INSTANCE_SIDEBAR_ITEMS.COLLECTION:
        return ec2Running && solrRunning ? success : failure;
      case INSTANCE_SIDEBAR_ITEMS.CONFIG:
        return ec2Running && solrRunning ? success : failure;
      case INSTANCE_SIDEBAR_ITEMS.FILE_MANAGER:
        return ec2Running ? success : failure;
      case INSTANCE_SIDEBAR_ITEMS.SEARCH:
        return solrRunning ? success : failure;
      case INSTANCE_SIDEBAR_ITEMS.MONITOR:
        return ec2Running && solrRunning && notCommunity ? success : failure;
      case INSTANCE_SIDEBAR_ITEMS.HEALTH_CHECK:
        return notCommunity ? success : failure;
      default:
        return success;
    }
  };

  const solrDownWarning = notCommunity
    ? itemEnabled(null, <WarningIcon className={classes.warningIcon} />)
    : item.name !== INSTANCE_SIDEBAR_ITEMS.MONITOR &&
      item.name !== INSTANCE_SIDEBAR_ITEMS.HEALTH_CHECK
    ? itemEnabled(null, <WarningIcon className={classes.warningIcon} />)
    : null;

  if (item.requireAdmin && !isAdmin) return null;

  return (
    <Link to={itemEnabled(item.path, '#')} className={classes.link}>
      <ListItem
        button
        disabled={itemEnabled(false, true)}
        aria-label="sidebar-menu"
        className={pathname === item.path ? classes.active : ''}
      >
        <ListItemIcon className={classes.sidebarIcon}>{item.icon}</ListItemIcon>
        <ListItemText primary={t(item.name, { ns: 'titles' })} />
        <ListItemSecondaryAction>{solrDownWarning}</ListItemSecondaryAction>
      </ListItem>
    </Link>
  );
};

const Sidebar = ({ open, type, handleToggle, isAdmin, instance, server }) => {
  const { t } = useTranslation();
  const classes = useStyles(open);
  const { projectId, instanceId, gpsId, modelId } = useParams();

  const menuItems = getSidebarItems(type, {
    projectId,
    instanceId,
    gpsId,
    modelId,
  });

  const ec2Instance = instance || server || null;

  return (
    <Drawer
      variant="permanent"
      className={classes.sidebar}
      classes={{ paper: classes.sidebar }}
      aria-label="side-navigation"
    >
      <Logo open={open} />
      <Divider />
      <List className={classes.sidebarMain}>
        {menuItems.map((item, index) => (
          <SideBarItem
            key={index}
            open={open}
            item={item}
            isAdmin={isAdmin}
            ec2Instance={ec2Instance}
          />
        ))}
      </List>
      <List className={`${classes.sidebar} ${classes.sidebarFooter}`}>
        <Divider />
        <ListItem button onClick={handleToggle} aria-label="collapse">
          <ListItemIcon className={classes.sidebarIcon}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ListItemIcon>
          <ListItemText primary={t('collapse', { ns: 'project' })} />
        </ListItem>
      </List>
    </Drawer>
  );
};

SideBarItem.propTypes = {
  open: PropTypes.bool,
  item: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  ec2Instance: PropTypes.object,
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.oneOf(values(TYPE)),
  handleToggle: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  instance: PropTypes.object,
  server: PropTypes.object,
  model: PropTypes.object,
};

export default Sidebar;
