import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { requestAll } from '../../../apis/config';
import { fileManagerApis } from '../../../apis/fileManagerApis';
import { projectApis } from '../../../apis/projectApis';
import Loading from '../../../components/elements/loading/Loading';
import FileBrowser from '../../../components/page-sections/file-browser/FileBrowser';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import SelectDropdown from '../../../components/sections/select-dropdown/SelectDropdown';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { DEFAULT_SUBSCRIPT_NUMBER } from '../../../constants';
import { useSolrInstance } from '../../../contexts/SolrContext';
import { formatFileList } from '../../../helpers/fileManagerHelpers';
import useFileManager from '../../../hooks/useFileManager';
import { isSolrCloud, sentenceCase } from '../../../utils/utils';

// Constants
const HOME_PATH = '/var/solr';
const BASE_FOLDER_CHAIN = [{ id: 'base_file', name: 'solr', isDir: true }];

const FileManagerPage = () => {
  const history = useHistory();
  const { projectId, instanceId } = useParams();
  const { instance } = useSolrInstance();
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [folderChain, setFolderChain] = useState(BASE_FOLDER_CHAIN);
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState(HOME_PATH);
  const [project, setProject] = useState(null);
  const [subscriptNum, setSubscriptNum] = useState(DEFAULT_SUBSCRIPT_NUMBER);

  const { actions, updateFileList } = useFileManager(
    setFileList,
    path,
    subscriptNum
  );

  const switchInstanceNode = (newSubscriptNum) => {
    setSubscriptNum(newSubscriptNum);
    setPath(HOME_PATH);
    setFolderChain(BASE_FOLDER_CHAIN);
  };

  const handlePageLoad = async () => {
    setLoading(true);
    try {
      const [{ data: projectRes }, { data: fileListRes }] = await requestAll([
        projectApis.getProject(projectId),
        fileManagerApis.getFileList(projectId, instanceId, path, subscriptNum),
      ]);
      const formattedFileList = formatFileList(fileListRes);
      setProject(projectRes);
      setFileList(formattedFileList);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handlePageLoad();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateFileList();
    // eslint-disable-next-line
  }, [history, path, subscriptNum]);

  const getStructure = (file) => {
    const homePathLength = HOME_PATH.split('/').length - 1;
    const { newPath, newFolderChain } = actions.getStructure(
      file,
      path,
      folderChain,
      homePathLength
    );
    setPath(newPath);
    setFolderChain(newFolderChain);
  };

  const create = (folderName) => actions.create(folderName);
  const upload = (files, type) => actions.upload(files, type);
  const edit = (file) => actions.edit(file, history);
  const download = (files) => actions.download(files);
  const remove = (files) => actions.remove(files);
  const rename = (oldName, newName) => actions.rename(oldName, newName);

  const actionFunctions = {
    getStructure,
    create,
    upload,
    edit,
    download,
    remove,
    rename,
  };

  return (
    <Container>
      {loading && <Loading style={{ marginTop: '1em' }} />}
      {error && (
        <Alert severity="error" style={{ marginTop: '1em' }}>
          {sentenceCase(
            t('loading', {
              ns: 'errors',
              content: t('requestedFileList', { ns: 'instance' }),
            })
          )}
        </Alert>
      )}
      {!loading && project && instance && (
        <section>
          <Breadcrumbs
            projectName={project.name}
            instanceName={instance.name}
          />
          <TitlePanel title={t('fileManager', { ns: 'titles' })} />
          <Box mb={2}>
            {isSolrCloud(instance.type) && (
              <SelectDropdown
                title={sentenceCase(
                  t('selected', {
                    ns: 'instance',
                    text: t('instanceNode', { ns: 'instance' }),
                  })
                )}
                selectList={instance.instances}
                value={subscriptNum}
                valueKey="subscriptNumber"
                nameKey="subscriptNumber"
                prefix={instance.name}
                onChange={switchInstanceNode}
              />
            )}
          </Box>
          <FileBrowser
            files={fileList}
            folderChain={folderChain}
            actionFunctions={actionFunctions}
          />
        </section>
      )}
    </Container>
  );
};

export default FileManagerPage;
