import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { getInitials } from '../../../utils/utils';
import UserTooltip from '../../elements/user-tooltip/UserTooltip';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '0.9rem',
    width: theme.spacing(4) - 2,
    height: theme.spacing(4) - 2,
    margin: 'auto',
    '&:hover': {
      filter: 'brightness(85%)',
    },
  },
}));

const UserAvatar = ({ user }) => {
  const classes = useStyles();

  return (
    <UserTooltip user={user}>
      <ButtonBase style={{ borderRadius: 20 }}>
        <Avatar
          src={user?.icon}
          alt={user?.publicName || ''}
          variant="circle"
          className={classes?.icon}
        >
          {getInitials(user?.publicName || '')}
        </Avatar>
      </ButtonBase>
    </UserTooltip>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserAvatar;
