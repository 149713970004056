import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { TASK_STATUS } from '../../../constants/serverConstants';
import { useUser } from '../../../contexts/UserContext';
import { PRIVATE_ROUTE, buildPath } from '../../../routes/routes';
import { formatDateTimeUTC } from '../../../utils/timeUtils';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import JobStatusCell from './JobStatusCell';

const ServerJobsList = ({ jobs, onPageChange }) => {
  const { t } = useTranslation();
  const { projectId, gpsId } = useParams();
  const { user } = useUser();
  const history = useHistory();

  const navigateToJobLog = (instanceId, jobId) => {
    const logPage = buildPath({
      path: PRIVATE_ROUTE.GPS_JOBS_DETAILS,
      params: { projectId, gpsId, jobId },
    });

    history.push({
      pathname: logPage,
      state: {
        modelId: instanceId,
        redirect: history.location.pathname,
      },
    });
  };

  const tableHeaders = [
    t('job', { ns: 'gps' }),
    t('status', { ns: 'gps' }),
    t('startDate', { ns: 'gps' }),
    t('endDate', { ns: 'gps' }),
    '',
  ];

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell key={header} align={index === 0 ? 'left' : 'center'}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.content.map((job) => (
              <TableRow key={job.id}>
                <TableCell>
                  <Text text={job.id} />
                </TableCell>
                <JobStatusCell status={job.status} error={job.error} />
                <TableCell align="center">
                  <Text
                    text={
                      job.startDate
                        ? formatDateTimeUTC(job.startDate, user?.timeZone)
                        : '-'
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  <Text
                    text={
                      job.endDate
                        ? formatDateTimeUTC(job.endDate, user?.timeZone)
                        : '-'
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  <Button
                    size="small"
                    onClick={() => navigateToJobLog(job.instanceSetId, job.id)}
                    disabled={job.status === TASK_STATUS.PENDING}
                  >
                    {t('viewLogs', { ns: 'gps' })}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {jobs.totalPages > 1 && (
        <Box display="flex" justifyContent="center" m={1}>
          <Pagination
            count={jobs.totalPages}
            shape="rounded"
            onChange={onPageChange}
          />
        </Box>
      )}
    </>
  );
};

ServerJobsList.propTypes = {
  jobs: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default ServerJobsList;
