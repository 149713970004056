import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Title from '../../elements/title/Title';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

const TitlePanel = ({ children, title, visible }) => {
  const classes = useStyles();

  return (
    <Box className={classes.flex}>
      <Title text={title} />
      {visible && children}
    </Box>
  );
};

TitlePanel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

export default TitlePanel;
