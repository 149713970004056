import { useState } from 'react';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';

import { UnControlled as CodeMirror } from '@leifandersen/react-codemirror2';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    top: 0,
    zIndex: 4,
    right: 20,
  },
  card: {
    backgroundColor: '#263238',
    color: '#EEFFFF',
    maxHeight: 350,
    overflowY: 'auto',
  },
  buttonRadius: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
});

const CopyBox = ({ content, isJson }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    setCopied(true);
  };

  const button = (
    <Button
      size="small"
      color="light"
      startIcon={copied ? <DoneIcon /> : <AssignmentIcon />}
      onClick={handleCopy}
      className={classes.buttonRadius}
      aria-label="copy"
    >
      {copied ? t('copied', { ns: 'tooltips' }) : t('copy', { ns: 'tooltips' })}
    </Button>
  );

  return (
    <Box position="relative">
      <>
        <Box className={classes.wrapper}>{button}</Box>

        {isJson ? (
          <CodeMirror
            value={content}
            options={{
              mode: 'application/json',
              theme: 'material',
              lineNumbers: true,
              lineWrapping: true,
              readOnly: 'nocursor',
            }}
          />
        ) : (
          <Card variant="outlined" className={classes.card}>
            <Box
              p={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text style={{ wordBreak: 'break-all' }}>{content}</Text>
            </Box>
          </Card>
        )}
      </>
    </Box>
  );
};

CopyBox.propTypes = {
  content: PropTypes.string,
  isJson: PropTypes.bool,
};

export default CopyBox;
