import { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
  blue,
  green,
  grey,
  red,
  orange,
  yellow,
} from '@material-ui/core/colors';
import PropTypes from 'prop-types';

import Text from '../../elements/text/Text';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '0.8em',
    padding: 2,
  },
}));

const StatusCaption = forwardRef(
  ({ icon: IconComponent, text, color, ...restProps }, ref) => {
    const classes = useStyles();

    // These are the accepted colors. Feel free to expand if necessary.
    const captionColor = () => {
      switch (color) {
        case 'red':
          return red[500];
        case 'orange':
          return orange[700];
        case 'yellow':
          return yellow[800];
        case 'green':
          return green[500];
        case 'blue':
          return blue[500];
        case 'grey':
          return grey[700];
        default:
      }
    };

    return (
      <Box ref={ref} display="flex" alignItems="center" {...restProps}>
        <IconComponent
          className={classes.icon}
          style={{ color: captionColor(color) }}
        />
        <Text variant="caption" style={{ color: captionColor(color) }}>
          {text}
        </Text>
      </Box>
    );
  }
);

StatusCaption.displayName = 'StatusCaption';

StatusCaption.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default StatusCaption;
