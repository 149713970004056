export const PERIOD_TYPES = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const DAYS = [
  { value: 1, label: 'days.monday', name: 'MONDAY' },
  { value: 2, label: 'days.tuesday', name: 'TUESDAY' },
  { value: 3, label: 'days.wednesday', name: 'WEDNESDAY' },
  { value: 4, label: 'days.thursday', name: 'THURSDAY' },
  { value: 5, label: 'days.friday', name: 'FRIDAY' },
  { value: 6, label: 'days.saturday', name: 'SATURDAY' },
  { value: 7, label: 'days.sunday', name: 'SUNDAY' },
];
