import { makeStyles, Card, DialogActions } from '@material-ui/core';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import Button from '../../elements/button/Button';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: theme.spacing(2),
  },
}));

const StripeCardForm = ({ handleSubmit, handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { showToast } = useToast();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      showToast(TOAST_TYPE.ERROR, error.message);
    } else {
      handleSubmit(token);
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Card className={classes.cardContainer}>
          <CardElement />
        </Card>

        <DialogActions>
          {Boolean(handleClose) && (
            <Button
              color="default"
              variant="text"
              type="reset"
              onClick={handleClose}
            >
              {t('cancel', { ns: 'buttons' })}
            </Button>
          )}
          <Button variant="text" disabled={!stripe} type="submit">
            {t('saveCard', { ns: 'buttons' })}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

StripeCardForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
};

export default StripeCardForm;
