import { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  CRAWLER_DAYS,
  CRAWLER_PERIOD_TYPES,
  CRAWLER_TYPES,
} from '../../../../constants/crawlerConstants';
import Text from '../../../elements/text/Text';
import TimezoneSelect from '../../../elements/timezone-select/TimezoneSelect';
import Modal from '../../../sections/modal/Modal';
import { filteredPeriods } from '../crawlerUtils';

const CrawlStep5 = ({ control, getValues, setValue }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { errors } = useFormState({ control });
  const { periodType, periodTime, jobType } = getValues();

  const [modal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment(periodTime || '00:00', 'HH:mm')
  );

  const toggleModal = () => setModal(!modal);
  const toProfilePage = () => history.push('/profile');
  const resetSchedule = () => {
    setValue('day', '');
    setValue('date', '');
  };

  return (
    <>
      <Box my={2}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Text title>
            {t('crawler.scheduleSettings', { ns: 'sectionTitles' })}
          </Text>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl error={!!errors?.periodType}>
            <Controller
              name="periodType"
              control={control}
              defaultValue={CRAWLER_PERIOD_TYPES.MANUAL.value}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  value={field.value}
                  row
                  onChange={(e) => {
                    resetSchedule();
                    field.onChange(e.target.value);
                  }}
                >
                  {filteredPeriods(CRAWLER_TYPES[jobType].SCHEDULE).map(
                    (type) => (
                      <FormControlLabel
                        key={type.value}
                        value={type.value}
                        control={<Radio />}
                        label={t(type.label, { ns: 'menus' })}
                      />
                    )
                  )}
                </RadioGroup>
              )}
            />
            <FormHelperText>{errors?.periodType?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          {periodType !== CRAWLER_PERIOD_TYPES.MANUAL.value && (
            <>
              <Grid item xs={12}>
                <Text variant="body2">
                  {t('crawlerTimezone', { ns: 'descriptions' })}
                </Text>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="periodTime"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        ampm={false}
                        minutesStep={10}
                        label={t('timePeriod', { ns: 'fields' })}
                        mask="__:__"
                        value={selectedDate}
                        onChange={(date) => {
                          setSelectedDate(date || '');
                          onChange(date ? moment(date).format('HH:mm') : '');
                        }}
                        inputProps={{ 'aria-label': 'time-input' }}
                        inputVariant="outlined"
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                          size: 'small',
                        }}
                        okLabel={t('ok', { ns: 'buttons' })}
                        cancelLabel={t('cancel', { ns: 'buttons' })}
                        error={!!errors?.periodTime}
                        helperText={errors?.periodTime?.message}
                        variant="outline"
                        fullWidth
                        size="small"
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TimezoneSelect
                  getValues={getValues}
                  errors={errors}
                  setValue={setValue}
                  variant="outlined"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            {periodType === CRAWLER_PERIOD_TYPES.WEEKLY.value && (
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={!!errors?.day}
              >
                <InputLabel>{t('day', { ns: 'fields' })}</InputLabel>
                <Controller
                  name="day"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('day', { ns: 'fields' })}
                      inputProps={{ 'aria-label': 'day-input' }}
                    >
                      {CRAWLER_DAYS.map((day) => (
                        <MenuItem key={day.value} value={day.value}>
                          {t(day.label, { ns: 'menus' })}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>{errors?.day?.message}</FormHelperText>
              </FormControl>
            )}
            {periodType === CRAWLER_PERIOD_TYPES.MONTHLY.value && (
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={!!errors?.date}
              >
                <InputLabel>{t('date', { ns: 'fields' })}</InputLabel>
                <Controller
                  name="date"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('date', { ns: 'fields' })}
                      inputProps={{ 'aria-label': 'date-input' }}
                    >
                      {[...Array(31).keys()].map((date) => (
                        <MenuItem key={date} value={date + 1}>
                          {date + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>{errors?.date?.message}</FormHelperText>
              </FormControl>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Modal
        openModal={modal}
        title={t('warning', { ns: 'general' })}
        showActions
        handleClose={toggleModal}
        handleConfirm={toProfilePage}
      >
        {t('loseAllData', { ns: 'descriptions' })}
      </Modal>
    </>
  );
};

CrawlStep5.propTypes = {
  control: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default CrawlStep5;
