import { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { instanceApis } from '../../../apis/instanceApis';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import InstanceStepper from '../../../components/page-sections/instance-stepper/InstanceStepper';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import {
  CLUSTER_TYPES,
  DEFAULT_SUBSCRIPT_NUMBER,
  INSTANCE_CATEGORY,
  INSTANCE_PLANS,
} from '../../../constants';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import { mapBlobFormData, sentenceCase } from '../../../utils/utils';

const formatRequest = (data) => {
  const {
    plan,
    name,
    description = null,
    icon = null,
    subdomain,
    cidrs,
    solrVersion,
  } = data;

  const instances = [
    {
      type: plan === INSTANCE_CATEGORY.COMMUNITY ? INSTANCE_PLANS.SMALL : plan,
      subscriptNumber: DEFAULT_SUBSCRIPT_NUMBER,
    },
  ];

  const instanceSetProp = {
    solrVersion,
    name,
    description,
    icon,
    subdomain,
    clusterType: CLUSTER_TYPES.SOLR_CLUSTER,
    cidrs,
    instances,
  };

  return instanceSetProp;
};

const NewInstance = () => {
  const { t } = useTranslation();

  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [community, setCommunity] = useState(0);

  const history = useHistory();
  const { projectId } = useParams();
  const { showToast } = useToast();

  const fetchData = async () => {
    try {
      const [projectRes, cardsRes, billingInfoRes, managersRes, instancesRes] =
        await requestAll([
          projectApis.getProject(projectId),
          projectApis.getProjectCards(projectId),
          projectApis.getProjectBillingInfo(projectId),
          projectApis.getProjectManagers(projectId),
          solrApis.getInstances(projectId, {
            category: INSTANCE_CATEGORY.COMMUNITY,
          }),
        ]);

      const project = {
        ...projectRes.data,
        cards: cardsRes.data,
        billingInfo: billingInfoRes.data.content,
        managers: managersRes.data.content,
      };

      setProject(project);

      // maximum community instances allowed: 2
      setCommunity(instancesRes.data.content.length || 0);
    } catch (error) {
      handleErrors(history, error, showToast);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [projectId, history]);

  const checkDuplicate = async (subdomain) => {
    try {
      return { isValid: await instanceApis.checkSubdomain(subdomain) };
    } catch (e) {
      showToast(
        TOAST_TYPE.ERROR,
        t('unableToCheckSubdomain', { ns: 'notifications' })
      );
      return { isValid: false };
    }
  };

  const onSubmit = async (data) => {
    const instanceSetData = formatRequest(data);
    const formData = mapBlobFormData(instanceSetData, 'instance-set');

    let instance;

    try {
      if (data.plan === INSTANCE_CATEGORY.COMMUNITY) {
        const { data: instanceRes } =
          await solrApis.createCommunitySolrInstance(projectId, formData);
        instance = instanceRes;
      } else {
        const { data: instanceRes } = await solrApis.createStandardSolrInstance(
          projectId,
          formData
        );
        instance = instanceRes;
      }

      const message = t('createSuccessWithName', {
        ns: 'notifications',
        text: t('instance', { ns: 'instance' }),
        name: instance.name,
      });
      showToast(TOAST_TYPE.SUCCESS, message);

      const instanceOverview = buildPath({
        path: PRIVATE_ROUTE.INSTANCE_OVERVIEW,
        params: { projectId, instanceId: instance.id },
      });
      setTimeout(() => history.push(instanceOverview), 800);
    } catch (error) {
      const message = t('submit', {
        ns: 'errors',
        content: t('instance', { ns: 'instance' }),
      });
      showToast(TOAST_TYPE.ERROR, message);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading &&
        (project ? (
          <>
            <Breadcrumbs projectName={project?.name} />
            <InstanceStepper
              community={community}
              onSubmit={onSubmit}
              project={project}
              onCheckDuplicate={checkDuplicate}
            />
          </>
        ) : (
          <Box mt={2}>
            <Alert severity="error">
              {sentenceCase(
                t('loading', {
                  ns: 'errors',
                  content: t('requestedContent', { ns: 'instance' }),
                })
              )}
            </Alert>
          </Box>
        ))}
    </>
  );
};

export default NewInstance;
