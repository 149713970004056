import { useState, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormHelperText, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { INSTANCE_TYPES, PLUGIN_DESTINATIONS } from '../../../constants';
import {
  isSolrCloud,
  isVersionCompatible,
  sentenceCase,
} from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Loading from '../../elements/loading/Loading';
import Text from '../../elements/text/Text';

const PluginForm = ({
  extensionType,
  version,
  handleAdd,
  getInstances,
  getInstanceNodes,
  getCollections,
  getLibPath,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [instances, setInstances] = useState(null);
  const [nodes, setNodes] = useState(null);
  const [collections, setCollections] = useState(null);
  const [path, setPath] = useState(null);
  const [versionWarning, setVersionWarning] = useState('');

  const schema = yup.object().shape({
    instance: yup.mixed().test(
      'instanceRequired',
      sentenceCase(
        t('selectRequired', {
          ns: 'validations',
          field: t('instance', { ns: 'instance' }),
        })
      ),
      (value) => !isEmpty(value)
    ),
    instanceNodes: yup.array().when('instance', {
      is: (instance) => {
        if (!instance) return false;
        return isSolrCloud(instance.type);
      },
      then: yup.array().min(
        1,
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('instanceNode', { ns: 'instance' }),
          })
        )
      ),
      otherwise: yup.array(),
    }),
    downloadTo: yup.string().when('instance', {
      is: (instance) => {
        if (!instance) return false;
        return instance.type === INSTANCE_TYPES.STANDALONE;
      },
      then: yup.string().required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('ext.downloadDestination', { ns: 'project' }),
          })
        )
      ),
      otherwise: yup.string(),
    }),
    collectionName: yup.string().when(['instance', 'downloadTo'], {
      is: (instance) => {
        if (!instance) return false;
        return (
          instance.type === INSTANCE_TYPES.STANDALONE &&
          downloadTo === 'collection'
        );
      },
      then: yup.string().required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('collection', { ns: 'instance' }),
          })
        )
      ),
      otherwise: yup.string(),
    }),
  });

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { extensionType, version },
  });

  const { instance, downloadTo, collectionName } = watch();

  const getCollectionData = async () => {
    setIsLoading(true);
    try {
      const data = await getCollections(instance);
      setCollections(data);
    } catch (error) {
      setCollections(null);
    } finally {
      setIsLoading(false);
    }
  };

  const getInstanceNodeData = async () => {
    setIsLoading(true);
    try {
      const data = await getInstanceNodes(instance.id);
      setNodes(data);
    } catch (error) {
      setNodes(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getInstanceData = async () => {
      try {
        const data = await getInstances();
        setInstances(data);
      } catch (error) {
        setInstances(null);
      }
    };

    getInstanceData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!instance) return;

    const extensionType = getValues('extensionType');
    const version = getValues('version');

    // check version difference
    const isCompatible = isVersionCompatible(
      version.supportedSolrVersions,
      instance.solrVersion
    );

    setVersionWarning(
      isCompatible
        ? ''
        : t('downloadVersionDiffWarning', {
            ns: 'descriptions',
            origin: version.supportedSolrVersions.join(', '),
            target: instance.solrVersion,
          })
    );

    // reset when instance is changed
    setNodes(null);
    reset({ extensionType, version, instance });

    // fetch instance nodes
    if (isSolrCloud(instance?.type)) {
      getInstanceNodeData();
    }

    // eslint-disable-next-line
  }, [instance]);

  useEffect(() => {
    if (downloadTo) {
      getCollectionData();
      setValue('collectionName', '');
    }

    // eslint-disable-next-line
  }, [downloadTo, instance]);

  useEffect(() => {
    setValue('instanceNodes', nodes);

    // eslint-disable-next-line
  }, [nodes]);

  useEffect(() => {
    setPath(null);
    if (!downloadTo && !nodes) return;
    const collectionNameNeeded = downloadTo === 'collection';

    const getPath = () => {
      const path = getLibPath(instance, downloadTo, collectionName);
      setPath(path);
      setValue('path', path);
    };

    if (collectionNameNeeded) {
      collectionName && getPath();
    } else {
      getPath();
    }

    // eslint-disable-next-line
  }, [downloadTo, nodes, collectionName]);

  const onSubmit = (data) => {
    setIsProcessing(true);
    handleAdd(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="plugin extension form">
      <Grid container spacing={2}>
        {versionWarning && (
          <Grid item xs={12}>
            <Alert severity="warning">{versionWarning}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.instance}>
            <InputLabel>
              {t('ext.instanceToDownload', { ns: 'project' })}
            </InputLabel>
            <Select
              {...register('instance')}
              defaultValue=""
              inputProps={{ 'aria-label': 'instance select' }}
            >
              {isEmpty(instances) ? (
                <MenuItem value="">
                  {t('noInstances', { ns: 'instance' })}
                </MenuItem>
              ) : (
                instances?.map((instance) => (
                  <MenuItem key={instance.id} value={instance}>
                    {instance.name}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>{errors.instance?.message}</FormHelperText>
          </FormControl>
        </Grid>
        {instance && (
          <Grid item xs={12}>
            {isSolrCloud(instance?.type) ? (
              <>
                <Text>
                  {t('pluginDefaultDestinationSolrCloud', {
                    ns: 'descriptions',
                  })}
                </Text>
                {!isLoading && nodes ? (
                  <FormControl fullWidth error={!!errors.instanceNodes}>
                    <Controller
                      name="instanceNodes"
                      control={control}
                      defaultValue={nodes}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            multiple
                            displayEmpty
                            label="instanceNodes"
                            inputProps={{
                              'aria-label': 'nodes multiple select',
                            }}
                            renderValue={(selected) => {
                              if (selected.length === 0)
                                return sentenceCase(
                                  t('selectRequired', {
                                    ns: 'validations',
                                    field: t('instanceNode', {
                                      ns: 'instance',
                                    }),
                                  })
                                );
                              return selected
                                .map((option) => option.name)
                                .join(', ');
                            }}
                          >
                            {nodes?.map((item) => (
                              <MenuItem key={item.id} value={item}>
                                <Checkbox
                                  checked={map(field.value, 'id').includes(
                                    item.id
                                  )}
                                  inputProps={{
                                    'aria-label': 'select checkbox',
                                  }}
                                />
                                <ListItemText primary={item.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                    <FormHelperText>
                      {errors.instanceNodes?.message}
                    </FormHelperText>
                  </FormControl>
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <>
                <Text>
                  {t('pluginDefaultDestinationStandalone', {
                    ns: 'descriptions',
                  })}
                </Text>
                <FormControl fullWidth error={!!errors.downloadTo}>
                  <Controller
                    name="downloadTo"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <RadioGroup {...field} value={field.value || ''}>
                        <FormHelperText>
                          {errors.downloadTo?.message}
                        </FormHelperText>
                        {PLUGIN_DESTINATIONS.map((item) => (
                          <FormControlLabel
                            key={item.value}
                            value={item.value}
                            control={<Radio />}
                            label={t(item.label, { ns: 'menus' })}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </FormControl>
                {downloadTo === 'collection' &&
                  (isLoading ? (
                    <Loading />
                  ) : (
                    <FormControl fullWidth error={!!errors.collectionName}>
                      <InputLabel>
                        {t('ext.collectionToDownload', { ns: 'project' })}
                      </InputLabel>
                      <Select
                        {...register('collectionName')}
                        defaultValue={''}
                        inputProps={{ 'aria-label': 'collection select' }}
                      >
                        {isEmpty(collections) ? (
                          <MenuItem value="">
                            {t('noCollections', { ns: 'instance' })}
                          </MenuItem>
                        ) : (
                          collections?.map((collection) => (
                            <MenuItem key={collection} value={collection}>
                              {collection}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      <FormHelperText>
                        {errors.collectionName?.message}
                      </FormHelperText>
                    </FormControl>
                  ))}
              </>
            )}
          </Grid>
        )}
      </Grid>
      <Box textAlign="center" my={2}>
        {path && (
          <Box textAlign="center" my={1}>
            <Text color="primary">
              {t('ext.downloadPath', { ns: 'project', path })}
            </Text>
          </Box>
        )}
        <Button
          type="submit"
          disabled={!isEmpty(errors) || isProcessing}
          aria-label="confirm"
        >
          {t('download', { ns: 'buttons' })}
        </Button>
      </Box>
    </form>
  );
};

PluginForm.propTypes = {
  extensionType: PropTypes.string.isRequired,
  version: PropTypes.object.isRequired,
  handleAdd: PropTypes.func.isRequired,
  getInstances: PropTypes.func.isRequired,
  getInstanceNodes: PropTypes.func.isRequired,
  getCollections: PropTypes.func.isRequired,
  getLibPath: PropTypes.func.isRequired,
};

export default PluginForm;
