import { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SORT_TYPES } from '../../../constants';
import { sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Loading from '../../elements/loading/Loading';
import NoContent from '../../sections/no-content/NoContent';
import ExtensionFilters from '../marketplace/ExtensionFilters';
import ExtensionSort from '../marketplace/ExtensionSort';
import ExtensionCard from './ExtensionCard';

const ExtensionList = ({
  isLibrary,
  getExtensions,
  handleDelete,
  ...restProps
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [extensions, setExtensions] = useState([]);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState(SORT_TYPES.POPULAR);

  const toggleFilters = (filterList) => setFilters(filterList);
  const clearFilters = () => setFilters([]);
  const handleSort = (sortType) => setSort(sortType);

  const loadExtensions = async () => {
    setLoading(true);
    const extensionRes = await getExtensions();
    setExtensions(extensionRes);
    setLoading(false);
  };

  const filterSortExtensions = async () => {
    setLoading(true);
    const extensionRes = await getExtensions(filters, sort);
    setExtensions(extensionRes);
    setLoading(false);
  };

  useEffect(() => {
    loadExtensions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterSortExtensions();
    // eslint-disable-next-line
  }, [filters, handleDelete, sort]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ExtensionFilters
          filters={filters}
          loading={loading}
          onToggle={toggleFilters}
        />
        {!isEmpty(filters) && (
          <Button
            style={{ float: 'left' }}
            variant="text"
            size="small"
            aria-label="clear"
            startIcon={<ClearIcon />}
            onClick={clearFilters}
          >
            {t('clear', { ns: 'buttons' })}
          </Button>
        )}
        {isLibrary && (
          <ExtensionSort loading={loading} sort={sort} onSort={handleSort} />
        )}
      </Grid>
      {loading ? (
        <Grid item xs={12}>
          <Loading />
        </Grid>
      ) : isEmpty(extensions) ? (
        <Box textAlign="center" width={'100%'}>
          <NoContent
            title={sentenceCase(
              t('noFound', {
                ns: 'descriptions',
                text: t('extension', { ns: 'project' }),
              })
            )}
          />
        </Box>
      ) : (
        <>
          {extensions.map((extension) => (
            <Grid item key={extension.id} xs={12} md={6} lg={4}>
              <ExtensionCard
                data={extension}
                isLibrary={isLibrary}
                handleDelete={handleDelete}
                {...restProps}
              />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

ExtensionList.propTypes = {
  getExtensions: PropTypes.func.isRequired,
  isLibrary: PropTypes.bool,
  handleDelete: PropTypes.func,
};

export default ExtensionList;
