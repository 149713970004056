import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { projectApis } from '../../../apis/projectApis';
import ProjectForm from '../../../components/page-sections/project-form/ProjectForm';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import { mapBlobFormData } from '../../../utils/utils';

const NewProjectPage = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { showToast } = useToast();

  const handleSubmit = async (data) => {
    const formData = mapBlobFormData(data, 'project');
    try {
      const formResponse = await projectApis.createProject(formData);
      const message = t('createSuccessWithName', {
        ns: 'notifications',
        text: t('project', { ns: 'project' }),
        name: formResponse.data.name,
      });
      const projectOverview = buildPath({
        path: PRIVATE_ROUTE.PROJECT_OVERVIEW,
        params: { projectId: formResponse.data.id },
      });

      showToast(TOAST_TYPE.SUCCESS, message);
      setTimeout(() => history.push(projectOverview), 800);
    } catch (error) {
      const message = t('submit', {
        ns: 'submit',
        content: t('project', { ns: 'project' }),
      });
      showToast(TOAST_TYPE.ERROR, message);
    }
  };

  return (
    <Container>
      <Breadcrumbs />
      <TitlePanel title={t('newProject', { ns: 'sectionTitles' })} />
      <ProjectForm onSubmit={handleSubmit} />
    </Container>
  );
};

export default NewProjectPage;
