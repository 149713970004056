import { useState } from 'react';

import { MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import Zoom from 'react-medium-image-zoom';

import Text from '../../../components/elements/text/Text';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import Dropdown from '../../elements/dropdown/Dropdown';
import Modal from '../../sections/modal/Modal';
import GalleryForm from './GalleryForm';

const useStyles = makeStyles((theme) => ({
  moreBtn: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 99,

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: 500,
  },
}));

const GalleryImage = ({ image, handleEdit, handleDelete }) => {
  const classes = useStyles();
  const { showToast } = useToast();

  const [anchorEl, setAnchorEl] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Dropdown menu
  const handleMenuClick = (e) => setAnchorEl(e.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // Open and close edit modal
  const openEditModal = () => {
    handleMenuClose();
    setEditModalOpen(true);
  };
  const closeEditModal = () => setEditModalOpen(false);

  // Open and close delete modal
  const openDeleteModal = () => {
    handleMenuClose();
    setDeleteModalOpen(true);
  };
  const closeDeleteModal = () => setDeleteModalOpen(false);

  const handleEditImage = async (data) => {
    await handleEdit(image.id, data);
    closeEditModal();
  };

  const handleDeleteImage = async () => {
    await handleDelete(image.id);
    closeDeleteModal();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(image.url);
    showToast(TOAST_TYPE.SUCCESS, 'イメージのリンクをコピーしました。');
    handleMenuClose();
  };

  const dropdownMenu = [
    {
      label: 'Edit',
      action: openEditModal,
      text: 'Edit',
    },
    {
      label: 'Copy Link',
      action: handleCopy,
      text: 'Copy Link',
    },
    {
      label: 'Delete',
      action: openDeleteModal,
      text: 'Delete',
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <IconButton
        aria-label="more"
        size="small"
        className={classes.moreBtn}
        onClick={handleMenuClick}
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>

      <Zoom
        wrapStyle={{ width: '100%' }}
        zoomMargin={40}
        overlayBgColorStart={'rgba(0, 0, 0, 0.75)'}
        overlayBgColorEnd={'rgba(0, 0, 0, 0.85)'}
      >
        <img alt={image.title} src={image.caching} style={{ width: '100%' }} />
      </Zoom>

      <Text align="center" className={classes.title} gutterBottom>
        {image.title}
      </Text>

      <Dropdown anchorEl={anchorEl} onClose={handleMenuClose}>
        {dropdownMenu.map((item, i) => (
          <MenuItem key={i} aria-label={item.label} onClick={item.action}>
            {item.text}
          </MenuItem>
        ))}
      </Dropdown>

      {/* Edit modal */}
      <Modal
        openModal={editModalOpen}
        title={'イメージの編集'}
        handleClose={closeEditModal}
      >
        <GalleryForm
          image={image}
          handleEdit={handleEditImage}
          handleModalClose={closeEditModal}
        />
      </Modal>

      {/* Delete modal */}
      <Modal
        openModal={deleteModalOpen}
        title={'イメージの削除'}
        handleConfirm={handleDeleteImage}
        handleClose={closeDeleteModal}
        showActions
      >
        <Text>{`${image.title} を削除しようとしています。`}</Text>
        <Text>本当にこのイメージを削除しますか？</Text>
      </Modal>
    </div>
  );
};

GalleryImage.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    caching: PropTypes.string,
  }),
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default GalleryImage;
