import { useMediaQuery, useTheme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  draggingListItem: {
    background: theme.palette.grey[100],
  },
  black: {
    color: theme.palette.common.black,
    backgroundColor: 'inherit',
  },
  warningChip: {
    borderColor: theme.palette.warning.dark,
    color: theme.palette.warning.dark,
    marginRight: theme.spacing(5),
  },
  item: {
    textDecoration: 'none',
  },
  wordBreak: {
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
}));

const DraggableListItem = ({ item, index, onRemoveWord }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const unSavedChip = (
    <Chip
      size="small"
      label={t('synonym.notSaved', { ns: 'project' })}
      variant="outlined"
      className={classes.warningChip}
    />
  );

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${classes.item} ${
            snapshot.isDragging ? classes.draggingListItem : ''
          }`}
        >
          <ListItemIcon>
            <Avatar className={classes.black}>
              <DragHandleIcon color="action" />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={item.value}
            primaryTypographyProps={{
              className: classes.wordBreak,
              component: 'p',
              'data-testid': 'synonym word',
            }}
            secondary={isXsScreen && item.notSaved && unSavedChip}
          />

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              title={t('delete', { ns: 'buttons' })}
              aria-label="delete"
              onClick={() => onRemoveWord(index)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </ListItemSecondaryAction>
          {!isXsScreen && item.notSaved ? (
            unSavedChip
          ) : (
            // Bugfix for draggable items
            <div />
          )}
        </ListItem>
      )}
    </Draggable>
  );
};

DraggableListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    notSaved: PropTypes.bool,
  }),
  index: PropTypes.number.isRequired,
  onRemoveWord: PropTypes.func.isRequired,
};

export default DraggableListItem;
