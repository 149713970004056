import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

const TextInput = ({
  name,
  label,
  control,
  errors,
  defaultValue,
  size,
  ...restProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field }) => (
        <TextField
          {...field}
          rows={size || 0}
          label={label}
          variant="outlined"
          inputProps={{ 'aria-label': name }}
          fullWidth
          multiline
          size="small"
          error={!!errors?.[name]}
          helperText={errors?.[name]?.message}
          {...restProps}
        />
      )}
    />
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  size: PropTypes.number,
};

export default TextInput;
