import PropTypes from 'prop-types';

import { EXTENSION_TYPES } from '../../../constants/extensionConstants';
import DictionaryForm from './DictionaryForm';
import PluginForm from './PluginForm';

const AddForm = ({ extensionType, ...restProps }) => {
  return extensionType === EXTENSION_TYPES.SOLR_PLUGIN ? (
    <PluginForm extensionType={extensionType} {...restProps} />
  ) : (
    <DictionaryForm extensionType={extensionType} {...restProps} />
  );
};

AddForm.propTypes = {
  extensionType: PropTypes.string.isRequired,
};
export default AddForm;
