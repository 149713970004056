import { PRIVATE_ROUTE } from '../../../routes/routes';

export const getBreadcrumbRoutes = (
  projectName,
  instanceName,
  extensionName,
  dictionaryName,
  jobName,
  serverName,
  modelName
) => [
  {
    path: PRIVATE_ROUTE.PROJECT_NEW,
    breadcrumb: 'newProject',
  },
  {
    path: PRIVATE_ROUTE.PROJECTS,
    breadcrumb: 'projects',
  },
  {
    path: PRIVATE_ROUTE.EXTENSIONS,
    breadcrumb: 'extensionLibrary',
  },
  {
    path: PRIVATE_ROUTE.EXTENSION_DETAILS,
    breadcrumb: extensionName,
  },
  {
    path: PRIVATE_ROUTE.PROJECT_OVERVIEW,
    breadcrumb: projectName,
  },
  {
    path: PRIVATE_ROUTE.PROJECT_MEMBER,
    breadcrumb: 'members',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_BACKUP,
    breadcrumb: 'backup',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_RESTORE,
    breadcrumb: 'restore',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_CRAWLER,
    breadcrumb: 'crawler',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_CRAWLER_SUBSCRIBE,
    breadcrumb: 'subscribeCrawler',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_CRAWLER_DETAILS,
    breadcrumb: jobName,
  },
  {
    path: PRIVATE_ROUTE.PROJECT_CRAWLER_JOB_STATUS,
    breadcrumb: 'status',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_BILLING,
    breadcrumb: 'billing',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_SUPPORT,
    breadcrumb: 'support',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_SUPPORT_SUBSCRIBE,
    breadcrumb: 'subscribeSupport',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_SUBS_EXTENSIONS,
    breadcrumb: 'subExtensions',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_SYNONYM_DICTIONARIES,
    breadcrumb: 'synonymDict',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_SYNONYM_DICTIONARY,
    breadcrumb: dictionaryName,
  },
  {
    path: PRIVATE_ROUTE.PROJECT_SETTINGS,
    breadcrumb: 'settings',
  },
  {
    path: PRIVATE_ROUTE.INSTANCE_NEW,
    breadcrumb: 'newInstance',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_INSTANCE_LIST,
    breadcrumb: 'instances',
  },
  {
    path: PRIVATE_ROUTE.INSTANCE_OVERVIEW,
    breadcrumb: instanceName,
  },
  {
    path: PRIVATE_ROUTE.INSTANCE_COLLECTIONS,
    breadcrumb: 'collections',
  },
  {
    path: PRIVATE_ROUTE.INSTANCE_CONFIG,
    breadcrumb: 'config',
  },
  {
    path: PRIVATE_ROUTE.INSTANCE_FILE_MANAGER,
    breadcrumb: 'fileManager',
  },
  {
    path: PRIVATE_ROUTE.INSTANCE_FILE_EDITOR,
    breadcrumb: 'fileEditor',
  },
  {
    path: PRIVATE_ROUTE.INSTANCE_HEALTH_CHECK,
    breadcrumb: 'healthCheck',
  },
  {
    path: PRIVATE_ROUTE.INSTANCE_SEARCH,
    breadcrumb: 'search',
  },
  {
    path: PRIVATE_ROUTE.INSTANCE_MONITORING,
    breadcrumb: 'monitor',
  },
  {
    path: PRIVATE_ROUTE.INSTANCE_SETTINGS,
    breadcrumb: 'settings',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_GPS_LIST,
    breadcrumb: 'gps',
  },
  {
    path: PRIVATE_ROUTE.GPS_OVERVIEW,
    breadcrumb: serverName,
  },
  {
    path: PRIVATE_ROUTE.GPS_JOBS,
    breadcrumb: 'job',
  },
  {
    path: PRIVATE_ROUTE.GPS_JOBS_DETAILS,
    breadcrumb: 'jobDetails',
  },
  {
    path: PRIVATE_ROUTE.GPS_FILE_EDITOR,
    breadcrumb: 'fileEditor',
  },
  {
    path: PRIVATE_ROUTE.GPS_FILE_MANAGER,
    breadcrumb: 'fileManager',
  },
  {
    path: PRIVATE_ROUTE.GPS_SETTINGS,
    breadcrumb: 'settings',
  },
  {
    path: PRIVATE_ROUTE.PROJECT_MODEL_LIST,
    breadcrumb: 'models',
  },
  {
    path: PRIVATE_ROUTE.MODEL_OVERVIEW,
    breadcrumb: modelName,
  },
  {
    path: PRIVATE_ROUTE.MODEL_SETTINGS,
    breadcrumb: 'settings',
  },
];
