import { isEmpty } from 'lodash';

import { PAYMENT_TYPES } from '../constants';

export const projectSetupComplete = (cards, billingInfo, managers) => {
  const isInvoicePayment =
    billingInfo?.[0]?.paymentType === PAYMENT_TYPES.INVOICE;
  const hasCard = !isEmpty(cards);
  const hasBillingInfo = !isEmpty(billingInfo);
  const hasManagers = !isEmpty(managers);

  const billingComplete = isInvoicePayment || (hasCard && hasBillingInfo);
  const setupComplete = billingComplete && hasManagers;

  return setupComplete;
};

export const currencyFormat = (value) =>
  new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  }).format(value);
