import { useState, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SYNONYM_FILTERS } from '../../../../constants';
import { sentenceCase } from '../../../../utils/utils';
import Button from '../../../elements/button/Button';
import Loading from '../../../elements/loading/Loading';
import Text from '../../../elements/text/Text';
import Modal from '../../../sections/modal/Modal';
import { deploySchema } from './dictionarySchema';

const mapObjectToKeyValuePair = (object) =>
  Object.keys(object).reduce((output, current) => {
    const pair = { key: current, val: object[current].toString() };
    output.push(pair);
    return output;
  }, []);

const mapKeyValuePairToObject = (args) =>
  Object.values(args).reduce((output, current) => {
    if (current.key) output[current.key.trim()] = current.val.trim();
    return output;
  }, {});

const initialState = {
  instanceId: '',
  collection: '',
  filter: SYNONYM_FILTERS.SYNONYM_GRAPH_FILTER,
  filename: '',
  resource: '',
  reload: true,
};

const DeployModal = ({
  open,
  handleClose,
  dictionary,
  handleDeploy,
  getInstances,
  getCollections,
  getResources,
  getInitArgs,
}) => {
  const { t } = useTranslation();

  // Track loading and error status
  const [isInstancesLoading, setIsInstancesLoading] = useState(false);
  const [isCollectionsLoading, setIsCollectionsLoading] = useState(false);
  const [isResourcesLoading, setIsResourcesLoading] = useState(false);
  const [isInitArgsLoading, setIsInitArgsLoading] = useState(false);
  const [hasInitArgsError, setHasInitArgsError] = useState(false);
  const [duplicateKey, setDuplicateKey] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Data for select menu
  const [instances, setInstances] = useState(null);
  const [collections, setCollections] = useState(null);
  const [managedResources, setManagedResources] = useState(null);

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(deploySchema),
    defaultValues: initialState,
    shouldUnregister: true,
  });

  const {
    fields: initArgsFields,
    append,
    remove,
    insert,
  } = useFieldArray({
    control,
    name: 'initArgs',
    defaultValues: {
      initArgs: [],
    },
  });

  const { instanceId, collection, filter, resource, reload, initArgs } =
    watch();

  const isManaged = filter === SYNONYM_FILTERS.MANAGED_SYNONYM_GRAPH_FILTER;

  // Validate whether property key is unique
  const validateUnique = () => {
    const keys = initArgs.map((arg) => arg.key).filter((v) => v);
    const notUnique = keys.length !== new Set(keys).size;
    setDuplicateKey(notUnique);
  };

  const clearFormAndClose = () => {
    setDuplicateKey(false);
    reset(initialState);
    handleClose();
  };

  const onDeploy = async (data) => {
    if (filter === SYNONYM_FILTERS.MANAGED_SYNONYM_GRAPH_FILTER) {
      const formatInitArgs = mapKeyValuePairToObject(data.initArgs);
      setIsSubmitting(true);
      await handleDeploy(dictionary.id, { ...data, initArgs: formatInitArgs });
      setIsSubmitting(false);
    } else {
      setIsSubmitting(true);
      await handleDeploy(dictionary.id, data);
      setIsSubmitting(false);
    }
    clearFormAndClose();
  };

  const fetchInstances = async () => {
    try {
      setIsInstancesLoading(true);
      const data = await getInstances();
      setInstances(data);
      setIsInstancesLoading(false);
    } catch (error) {
      setInstances(null);
      setIsInstancesLoading(false);
    }
  };

  const fetchCollections = async (instance) => {
    try {
      setIsCollectionsLoading(true);
      const collections = await getCollections(instance);
      setCollections(collections);
      setIsCollectionsLoading(false);
    } catch (error) {
      setCollections(null);
      setIsCollectionsLoading(false);
    }
  };

  const fetchManagedResources = async () => {
    try {
      setIsResourcesLoading(true);
      const resources = await getResources(instanceId, collection);
      setManagedResources(resources);
      setIsResourcesLoading(false);
    } catch (error) {
      setManagedResources(null);
      setIsResourcesLoading(false);
    }
  };

  const fetchInitArgs = async () => {
    try {
      setIsInitArgsLoading(true);
      const keyValPair = mapObjectToKeyValuePair(
        await getInitArgs(instanceId, collection, resource)
      );
      remove();
      keyValPair.length ? keyValPair.map((arg) => append(arg)) : append([]);
      setIsInitArgsLoading(false);
    } catch (error) {
      remove();
      append([]);
      setIsInitArgsLoading(false);
      setHasInitArgsError(true);
    }
  };

  useEffect(() => {
    if (open) {
      // Fetch instances when modal opens
      fetchInstances();
    } else {
      // Reset states when modal closes
      setInstances(null);
      setCollections(null);
      setManagedResources(null);
    }

    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    // Reset when instance is changed
    reset({ ...initialState, instanceId, reload });
    setCollections(null);

    if (!instanceId) return;
    fetchCollections(instances.find((inst) => inst.id === instanceId));

    // eslint-disable-next-line
  }, [instanceId]);

  useEffect(() => {
    // Reset when collection is changed
    reset({ ...initialState, instanceId, collection, reload });
    setManagedResources(null);

    // eslint-disable-next-line
  }, [collection]);

  useEffect(() => {
    // In case of SynonymGraphFilter
    if (!isManaged) {
      reset({ ...initialState, instanceId, collection, filter, reload });
      return;
    }

    // In case of ManagedSynonymGraphFilter
    setValue('reload', true);
    if (!managedResources) fetchManagedResources();
    if (resource) fetchInitArgs();

    // eslint-disable-next-line
  }, [resource, isManaged]);

  // When arguments are changed, validate property uniqueness
  useEffect(() => {
    if (!initArgs) return;
    validateUnique();

    // eslint-disable-next-line
  }, [initArgs]);

  return (
    <Modal
      openModal={open}
      title={t('synonym.deployDictionary', {
        ns: 'sectionTitles',
      })}
      handleClose={clearFormAndClose}
    >
      <form onSubmit={handleSubmit(onDeploy)}>
        <Grid container spacing={2}>
          {/* Instance Select */}
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.instanceId}>
              {isInstancesLoading ? (
                <Loading />
              ) : (
                <>
                  <InputLabel>{t('instance', { ns: 'instance' })}</InputLabel>
                  <Controller
                    name="instanceId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputProps={{ 'aria-label': 'instance select' }}
                      >
                        {isEmpty(instances) ? (
                          <MenuItem disabled>
                            {sentenceCase(
                              t('noFound', {
                                ns: 'descriptions',
                                text: t('instance', { ns: 'instance' }),
                              })
                            )}
                          </MenuItem>
                        ) : (
                          instances.map((inst) => (
                            <MenuItem key={inst.id} value={inst.id}>
                              {inst.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    )}
                  />
                </>
              )}
              <FormHelperText>{errors.instanceId?.message}</FormHelperText>
            </FormControl>
          </Grid>

          {/* Collection Select */}
          {instanceId && (
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.collection}>
                {isCollectionsLoading ? (
                  <Loading />
                ) : (
                  <>
                    <InputLabel>
                      {t('collection', { ns: 'instance' })}
                    </InputLabel>
                    <Controller
                      name="collection"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          inputProps={{ 'aria-label': 'collection select' }}
                        >
                          {isEmpty(collections) ? (
                            <MenuItem disabled>
                              {sentenceCase(
                                t('noFound', {
                                  ns: 'descriptions',
                                  text: t('collection', { ns: 'instance' }),
                                })
                              )}
                            </MenuItem>
                          ) : (
                            collections.map((collection) => (
                              <MenuItem key={collection} value={collection}>
                                {collection}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      )}
                    />
                  </>
                )}
                <FormHelperText>{errors.collection?.message}</FormHelperText>
              </FormControl>
            </Grid>
          )}

          {collection && (
            <>
              {/* Filter Select */}
              <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.filter}>
                  <InputLabel>
                    {t('synonym.filter', { ns: 'fields' })}
                  </InputLabel>
                  <Controller
                    name="filter"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputProps={{ 'aria-label': 'filter type' }}
                      >
                        {values(SYNONYM_FILTERS).map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText>{errors.filter?.message}</FormHelperText>
                </FormControl>
              </Grid>

              {/* File Name Input */}
              {!isManaged && (
                <Grid item xs={12}>
                  <Controller
                    name="filename"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t('fileName', { ns: 'fields' })}
                        error={!!errors.filename}
                        helperText={errors.filename?.message}
                        inputProps={{ 'aria-label': 'file name' }}
                      />
                    )}
                  />
                </Grid>
              )}

              {/* Managed Resources Select */}
              {isManaged && (
                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth error={!!errors.resource}>
                      {isResourcesLoading ? (
                        <Loading />
                      ) : (
                        <>
                          <InputLabel>
                            {t('synonym.managedResource', { ns: 'fields' })}
                          </InputLabel>
                          <Controller
                            name="resource"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                inputProps={{ 'aria-label': 'resource select' }}
                              >
                                {isEmpty(managedResources) ? (
                                  <MenuItem disabled>
                                    {sentenceCase(
                                      t('noFound', {
                                        ns: 'descriptions',
                                        text: t('synonym.managedResource', {
                                          ns: 'fields',
                                        }),
                                      })
                                    )}
                                  </MenuItem>
                                ) : (
                                  managedResources.map((resource) => (
                                    <MenuItem key={resource} value={resource}>
                                      {resource}
                                    </MenuItem>
                                  ))
                                )}
                              </Select>
                            )}
                          />
                        </>
                      )}
                      <FormHelperText>
                        {errors.resource?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {/* Initial Arguments Array */}
                  {isInitArgsLoading ? (
                    <Box flex={1}>
                      <Loading />
                    </Box>
                  ) : (
                    resource && (
                      <Grid item xs={12}>
                        <Box mb={2}>
                          <Text bodyBold>
                            {t('synonym.initArgs', { ns: 'fields' })}
                          </Text>
                        </Box>

                        {hasInitArgsError && (
                          <Text gutterBottom color="error">
                            {sentenceCase(
                              t('failedToFetchExistingData', {
                                ns: 'errors',
                                content: t('synonym.initArgs', {
                                  ns: 'fields',
                                }),
                              })
                            )}
                          </Text>
                        )}

                        {/* Error for Duplicate Key */}
                        {duplicateKey && (
                          <Box display="flex" alignItems="center" mb={2}>
                            <ReportProblemOutlinedIcon color="error" />
                            <Text color="error">
                              {t('duplicateKey', { ns: 'validations' })}
                            </Text>
                          </Box>
                        )}

                        {isEmpty(initArgsFields) && (
                          <Button
                            variant="text"
                            startIcon={<AddIcon />}
                            onClick={() => append([{ key: '', val: '' }])}
                          >
                            {t('add', {
                              ns: 'buttons',
                              text: t('synonym.keyValPair', { ns: 'project' }),
                            })}
                          </Button>
                        )}

                        {initArgsFields.map((f, index) => (
                          <Grid container spacing={3} key={f.id}>
                            <Grid item xs={12} sm={5}>
                              <Controller
                                name={`initArgs[${index}].key`}
                                control={control}
                                defaultValue={f.key}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    label={t('argKey', { ns: 'fields' })}
                                    size="small"
                                    error={!!errors?.initArgs?.[index]?.key}
                                    helperText={
                                      errors.initArgs?.message ||
                                      errors.initArgs?.[index]?.key?.message
                                    }
                                    inputProps={{
                                      'aria-label': `initArgs[${index}].key`,
                                    }}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      trigger(`initArgs[${index}].val`);
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <Controller
                                name={`initArgs[${index}].val`}
                                control={control}
                                defaultValue={f.val}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    label={t('argValue', { ns: 'fields' })}
                                    size="small"
                                    error={!!errors?.initArgs?.[index]?.val}
                                    helperText={
                                      errors?.initArgs?.[index]?.val?.message
                                    }
                                    inputProps={{
                                      'aria-label': `initArgs[${index}].val`,
                                    }}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      trigger(`initArgs[${index}].key`);
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-evenly"
                              flex={1}
                            >
                              <IconButton
                                onClick={() =>
                                  insert(index + 1, { key: '', val: '' })
                                }
                                size="small"
                                aria-label={`add-${index}`}
                              >
                                <AddIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => remove(index)}
                                size="small"
                                aria-label={`remove-${index}`}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    )
                  )}
                </>
              )}
            </>
          )}

          {/* Reload Checkbox */}
          <Grid item xs={12}>
            <Controller
              name="reload"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      inputProps={{ 'aria-label': 'reload' }}
                      disabled={isManaged}
                    />
                  }
                  label={t('synonym.reloadAfterDeploy', { ns: 'project' })}
                />
              )}
            />
          </Grid>
        </Grid>

        <DialogActions>
          <Button
            color="default"
            variant="text"
            type="reset"
            onClick={clearFormAndClose}
          >
            {t('cancel', { ns: 'buttons' })}
          </Button>
          {isSubmitting ? (
            <Button variant="text" disabled>
              {t('confirm', { ns: 'buttons' })}
              <CircularProgress size={15} style={{ marginLeft: 10 }} />
            </Button>
          ) : (
            <Button
              variant="text"
              type="submit"
              disabled={!isValid || duplicateKey}
              aria-label="confirm"
            >
              {t('confirm', { ns: 'buttons' })}
            </Button>
          )}
        </DialogActions>
      </form>
    </Modal>
  );
};

DeployModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dictionary: PropTypes.object,
  handleDeploy: PropTypes.func.isRequired,
  getInstances: PropTypes.func.isRequired,
  getCollections: PropTypes.func.isRequired,
  getResources: PropTypes.func.isRequired,
  getInitArgs: PropTypes.func.isRequired,
};

export default DeployModal;
