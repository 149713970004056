import { Box, Link } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { PAYMENT_TYPES } from '../../../constants';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import { sentenceCase } from '../../../utils/utils';
import Text from '../../elements/text/Text';

const ProjectSetupIncomplete = ({
  serviceName,
  cards,
  billingInfo,
  managers,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const hasManagers = !isEmpty(managers);
  const isInvoicePayment =
    billingInfo?.[0]?.paymentType === PAYMENT_TYPES.INVOICE;
  const billingComplete =
    isInvoicePayment || (!isEmpty(cards) && !isEmpty(billingInfo));

  return (
    <Box my={5}>
      <Box my={2}>
        <Text align="center" gutterBottom>
          {sentenceCase(
            t('prerequisite', { ns: 'descriptions', name: serviceName })
          )}
        </Text>
      </Box>
      {!billingComplete && (
        <Box my={2}>
          <Text align="center" bodyBold gutterBottom>
            {t('requiredSetting', { ns: 'descriptions' })}
          </Text>
          <Text align="center">
            <Trans
              i18nKey="registerOnPage"
              ns="descriptions"
              values={{ page: t('billing.page', { ns: 'project' }) }}
              components={[
                <Link
                  key="0"
                  aria-label="to billing page"
                  component={RouterLink}
                  to={buildPath({
                    path: PRIVATE_ROUTE.PROJECT_BILLING,
                    params: { projectId },
                  })}
                />,
              ]}
            />
          </Text>
        </Box>
      )}

      {!hasManagers && (
        <Box my={2}>
          <Text align="center" gutterBottom bodyBold>
            {t('pm.infoSetting', { ns: 'project' })}
          </Text>
          <Text align="center">
            <Trans
              i18nKey="registerOnPage"
              ns="descriptions"
              values={{
                page: t('settingsPage', {
                  ns: 'project',
                  text: t('project', { ns: 'project' }),
                }),
              }}
              components={[
                <Link
                  key="0"
                  aria-label="to project settings page"
                  component={RouterLink}
                  to={buildPath({
                    path: PRIVATE_ROUTE.PROJECT_SETTINGS,
                    params: { projectId },
                  })}
                />,
              ]}
            />
          </Text>
        </Box>
      )}
    </Box>
  );
};

ProjectSetupIncomplete.propTypes = {
  serviceName: PropTypes.string.isRequired,
  cards: PropTypes.array.isRequired,
  billingInfo: PropTypes.array.isRequired,
  managers: PropTypes.array.isRequired,
};

export default ProjectSetupIncomplete;
