import { Box, IconButton, makeStyles, Paper } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatMonthNum } from '../../../../utils/timeUtils';
import { getCardIcon, sentenceCase } from '../../../../utils/utils';
import Button from '../../../elements/button/Button';
import Text from '../../../elements/text/Text';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '94%',
    },
  },
  cardText: {
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
  },
  noCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em',
    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '94%',
      flexDirection: 'column',
      gap: '0.5em',
    },
  },
}));

const PaymentCardSection = ({
  card,
  canDelete,
  toggleAddModal,
  toggleUpdateModal,
  toggleDeleteModal,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return card ? (
    <Paper variant="outlined" className={classes.cardContainer}>
      <Box display="flex" alignItems="center">
        {getCardIcon(card?.brand, 50)}
        <Box className={classes.cardText}>
          <Text bodyBold>
            {t('billing.creditCardEnding', {
              ns: 'project',
              number: card.number,
            })}
          </Text>
          <Text caption>
            {t('billing.creditCardExpiration', {
              ns: 'project',
              number: `${formatMonthNum(card.expireMonth)}/${card.expireYear
                .toString()
                .slice(2)}`,
            })}
          </Text>
        </Box>
      </Box>
      <Box className={classes.buttons}>
        <IconButton onClick={toggleUpdateModal} aria-label="edit">
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={canDelete ? toggleDeleteModal : toggleAddModal}
          aria-label="delete"
        >
          <DeleteForeverIcon />
        </IconButton>
      </Box>
    </Paper>
  ) : (
    <Paper variant="outlined" className={classes.noCardContainer}>
      <Text subtitleBold>
        {sentenceCase(
          t('noFound', {
            ns: 'descriptions',
            text: t('billing.creditCard', { ns: 'project' }),
          })
        )}
      </Text>
      <Button startIcon={<CreditCardIcon />} onClick={toggleAddModal}>
        {t('add', {
          ns: 'buttons',
          text: t('billing.creditCard', { ns: 'project' }),
        })}
      </Button>
    </Paper>
  );
};

PaymentCardSection.propTypes = {
  card: PropTypes.object,
  canDelete: PropTypes.bool,
  toggleAddModal: PropTypes.func.isRequired,
  toggleUpdateModal: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
};

export default PaymentCardSection;
