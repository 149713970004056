import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { formatBytes } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import ImageUpload from '../../sections/image-upload/ImageUpload';
import { FILE_SIZE, SUPPORTED_FORMATS } from '../instance-stepper/constants';

const GalleryForm = ({ image, handleCreate, handleEdit, handleModalClose }) => {
  const schema = yup.object().shape({
    title: yup.string().trim().required('イメージ名の入力が必要です。'),
    file: yup
      .mixed()
      .test(
        'required',
        'イメージの選択が必要です。',
        (value) => image?.url || !!value
      )
      .test(
        'fileFormat',
        'サポートされるファイル形式はPNG（png）、JPEG（jpg・jpeg）です。',
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .test(
        'fileSize',
        `ファイルサイズの上限(${formatBytes(
          FILE_SIZE.BYTES,
          0
        )})を超えています。`,
        (value) => !value || (value && value.size <= FILE_SIZE.BYTES)
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    !image ? await handleCreate(data) : await handleEdit(data);
    handleModalClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ImageUpload
            name="file"
            control={control}
            imageSrc={image?.caching || ''}
            limit={FILE_SIZE.BYTES}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="title"
            control={control}
            defaultValue={image?.title || ''}
            render={({ field }) => (
              <TextField
                {...field}
                label={'イメージ名'}
                error={!!errors?.title}
                helperText={errors?.title?.message}
                inputProps={{ 'aria-label': 'title' }}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>

      <DialogActions>
        <Button
          color="default"
          variant="text"
          type="reset"
          onClick={handleModalClose}
        >
          {'キャンセル'}
        </Button>
        <Button variant="text" type="submit">
          {'確認'}
        </Button>
      </DialogActions>
    </form>
  );
};

GalleryForm.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    caching: PropTypes.string,
  }),
  handleCreate: PropTypes.func,
  handleEdit: PropTypes.func,
  handleModalClose: PropTypes.func,
};

export default GalleryForm;
