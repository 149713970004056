import { SORT_TYPES } from '../constants';
import { getToken, instance } from './config';

const getExtensions = (
  filter = null,
  sort = SORT_TYPES.POPULAR,
  size = 100,
  page = 0
) =>
  instance.get(`/extensions`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { size, sort, page, filter, visibility: 'PUBLIC' },
  });

const getExtension = (extensionId) =>
  instance.get(`/extensions/${extensionId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const getProjectExtensions = (
  projectId,
  filter = null,
  sort = SORT_TYPES.POPULAR
) =>
  instance.get(`projects/${projectId}/extensions`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { filter, sort },
  });

const increaseDownloadCount = (extensionId) =>
  instance.put(`/extensions/${extensionId}/downloads`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

// Options contains optional params: instSubscript, collectionName
const downloadExtension = (projectId, extensionId, versionId, params) =>
  instance.post(
    `/projects/${projectId}/extensions/${extensionId}/versions/${versionId}/install`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: params,
    }
  );

export const extensionApis = {
  getExtensions,
  getProjectExtensions,
  getExtension,
  increaseDownloadCount,
  downloadExtension,
};
