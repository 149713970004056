export const DATA_TYPES = {
  DATA: 'DATA',
  GRAPH: 'GRAPH',
};

export const TABLE_HEADERS = [
  'table.dateTime',
  'table.cpu',
  'table.ram',
  'table.disk',
];

export const MONITOR_PERIODS = [
  { label: 'time.min', value: 900 },
  { label: 'time.hour', value: 3600 },
  { label: 'time.day', value: 86400 },
  { label: 'time.week', value: 604800 },
];

export const REFRESH_RATES = [
  { label: 'refresh.off', value: 0 },
  { label: 'refresh.seconds15', value: 15000 },
  { label: 'refresh.seconds30', value: 30000 },
  { label: 'refresh.minutes1', value: 60000 },
];
