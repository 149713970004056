import { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useUser } from '../../../contexts/UserContext';
import Text from '../../elements/text/Text';
import CustomTick from './CustomTick';
import {
  formatDiskLabels,
  formatLabels,
  formattedChartData,
  formattedIoData,
  graphColors,
} from './utils';

const MonitoringGraphs = ({ monitoringData }) => {
  const { t } = useTranslation('monitoring');
  const { user } = useUser();

  const [showGraphs, setShowGraphs] = useState({
    cpu: true,
    ram: true,
    diskUsage: true,
  });

  const resultsArray = Object.entries(monitoringData.result);
  const diskIoKeys = resultsArray?.[0]?.[1]?.ioUtilization
    ? Object.keys(resultsArray[0][1].ioUtilization)
    : [];
  const solrDisk = monitoringData.dataDiskName;

  useEffect(() => {
    // You need to pass the disk io keys to showGraphs state to make them toggable on the graph
    const showGraphCopy = { ...showGraphs };
    diskIoKeys.forEach((disk) => (showGraphCopy[disk] = true));

    setShowGraphs(showGraphCopy);
    // eslint-disable-next-line
  }, []);

  const selectLine = (e) =>
    setShowGraphs({
      ...showGraphs,
      [e.dataKey]: !showGraphs[e.dataKey],
      hover: null,
    });

  const formatXAxis = (tickItem) =>
    moment(tickItem * 1000)
      .tz(user?.timeZone)
      .format(
        t('format.monitorDate', {
          ns: 'general',
          interpolation: { escapeValue: false },
        })
      );

  return (
    <Box data-testid="graph">
      <Text title>{t('usageData')}</Text>
      <ResponsiveContainer width="95%" height={400}>
        <LineChart
          data={formattedChartData(monitoringData)}
          margin={{ bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Line
            dot={false}
            type="linear"
            dataKey="cpu"
            stroke={graphColors.green}
            key="cpu"
            hide={!showGraphs.cpu}
          />
          <Line
            dot={false}
            type="linear"
            dataKey="ram"
            stroke={graphColors.blue}
            key="ram"
            hide={!showGraphs.ram}
          />
          <Line
            dot={false}
            type="linear"
            dataKey="diskUsage"
            stroke={graphColors.orange}
            key="diskUsage"
            hide={!showGraphs.diskUsage}
          />
          <Legend
            onClick={selectLine}
            verticalAlign="top"
            height={36}
            formatter={(value) => formatLabels(value)}
          />
          <XAxis
            dataKey="timestamp"
            tickFormatter={formatXAxis}
            tick={<CustomTick />}
          />
          <YAxis dataKey="cpu" domain={[0, 100]} />
          <Tooltip
            labelFormatter={(label) => formatXAxis(label, user)}
            formatter={(value, name) => [`${value}%`, formatLabels(name)]}
          />
        </LineChart>
      </ResponsiveContainer>

      <Text title style={{ marginTop: '1em' }}>
        {t('diskIo')}
      </Text>
      <ResponsiveContainer width="95%" height={400}>
        <LineChart
          data={formattedIoData(monitoringData, diskIoKeys)}
          margin={{ bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {diskIoKeys.map((diskKey, i) => (
            <Line
              dot={false}
              type="linear"
              dataKey={diskKey}
              stroke={Object.values(graphColors)[i]}
              key={diskKey}
              hide={!showGraphs[diskKey]}
            />
          ))}
          <Legend
            onClick={selectLine}
            verticalAlign="top"
            height={36}
            formatter={(value) => t(formatDiskLabels(value, solrDisk))}
          />
          <XAxis
            dataKey="timestamp"
            tickFormatter={formatXAxis}
            tick={<CustomTick />}
          />
          <YAxis dataKey="percentage" domain={[0, 100]} />
          <Tooltip
            labelFormatter={(label) => formatXAxis(label)}
            formatter={(value, name) => [
              `${value}%`,
              t(formatDiskLabels(name, solrDisk)),
            ]}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

MonitoringGraphs.propTypes = {
  monitoringData: PropTypes.object.isRequired,
};

export default MonitoringGraphs;
