import { useState } from 'react';

import { TextField, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import Modal from '../modal/Modal';
import ModalButtons from '../modal/ModalButtons';

const useStyles = makeStyles((theme) => ({
  confirm: {
    backgroundColor: theme.palette.action.hover,
    borderBottom: 'solid 2px black',
  },
}));

const DeleteButton = ({ buttonText, onFormSubmit, title, validation }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [modal, setModal] = useState(false);
  const [valid, setValid] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
    setValid(false);
  };

  const handleFormSubmit = async (data) => await onFormSubmit(data);

  const { register, handleSubmit } = useForm();

  const Form = (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <TextField
        {...register('validation')}
        fullWidth
        autoComplete="off"
        error={!valid}
        inputProps={{
          onChange: (e) =>
            e.target.value === validation ? setValid(true) : setValid(false),
        }}
      />
      <ModalButtons
        onCancel={toggleModal}
        cancelProps={{ type: 'reset' }}
        actionProps={{ disabled: !valid, type: 'submit' }}
        actionText={t('confirm', { ns: 'buttons' })}
      />
    </form>
  );

  return (
    <>
      <Button color="danger" onClick={toggleModal} style={{ marginTop: '1em' }}>
        {buttonText || t('delete', { ns: 'buttons' })}
      </Button>
      {modal && (
        <Modal
          title={t('confirmation', { text: title, ns: 'sectionTitles' })}
          form={Form}
          openModal={modal}
          handleClose={toggleModal}
        >
          <Text text={t('completeDelete', { ns: 'descriptions' })} />
          <Text text={t('unableUndoAfterConfirm', { ns: 'descriptions' })} />
          <Text style={{ marginTop: '1em' }}>
            <Trans
              i18nKey="deleteValidation"
              ns="descriptions"
              values={{ validation }}
              components={[<span key="0" className={classes.confirm} />]}
            />
          </Text>
        </Modal>
      )}
    </>
  );
};

DeleteButton.propTypes = {
  buttonText: PropTypes.string,
  onFormSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  validation: PropTypes.string.isRequired,
};

export default DeleteButton;
