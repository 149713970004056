import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  light: {
    background: theme.palette.grey[200],
    color: theme.palette.text.primary,
    '&:hover': {
      background: theme.palette.grey[300],
    },
    '&.MuiButton-outlined': {
      background: theme.palette.common.white,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
  dark: {
    background: theme.palette.grey[600],
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.grey[800],
    },
    '&.MuiButton-outlined': {
      background: theme.palette.common.white,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.common.white,
      },
    },
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    '&.MuiButton-outlined': {
      background: theme.palette.common.white,
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: red[50],
      },
    },
  },
}));
