import { Box, Checkbox, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../contexts/UserContext';
import Text from '../../elements/text/Text';
import Title from '../../elements/title/Title';

const NewsForm = ({ onSubmit }) => {
  const { user } = useUser();
  const { t } = useTranslation();

  const handleNewsChange = async (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    await onSubmit(name, value);
  };

  return (
    <>
      <Title subTitle text={t('newsPreferences', { ns: 'sectionTitles' })} />

      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Box display="flex" alignItems="center" mt={1}>
            <Checkbox
              inputProps={{ 'aria-label': 'subscribe to newsletter' }}
              checked={user?.newsSubscription}
              name="newsSubscription"
              onChange={handleNewsChange}
            />
            <Text>{t('newsLabel', { ns: 'user' })} </Text>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

NewsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default NewsForm;
