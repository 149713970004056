import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { INSTANCE_PLANS } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import { formatDateTimeUTC } from '../../../utils/timeUtils';
import Loading from '../../elements/loading/Loading';
import Text from '../../elements/text/Text';
import ScanResult from './ScanResult';

const VirusScansSection = ({ antivirus, instanceSize, fetchVirusScans }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [virusScans, setVirusScans] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleFetchVirusScans = async () => {
    if (antivirus?.enabled) {
      setLoading(true);
      const virusScanRes = await fetchVirusScans(antivirus.id);
      setVirusScans(virusScanRes);
    } else {
      setVirusScans([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleFetchVirusScans();
    // eslint-disable-next-line
  }, [antivirus]);

  return (
    <>
      {antivirus?.enabled ? (
        <Box display="flex" mb={1}>
          <Text body>{t('antivirus.status', { ns: 'instance' })}</Text>
          <Text component="div" style={{ fontWeight: 'bold' }}>
            <Box color="success.main" ml={1}>
              {t('antivirus.enabled', { ns: 'instance' })}
            </Box>
          </Text>
          <Tooltip title={t('virusScan', { ns: 'tooltips' })} placement="right">
            <InfoIcon fontSize="small" style={{ marginLeft: '0.5em' }} />
          </Tooltip>
        </Box>
      ) : (
        <Box display="flex" mb={1}>
          <Text body>{t('antivirus.status', { ns: 'instance' })}</Text>
          <Text component="div" style={{ fontWeight: 'bold' }}>
            <Box ml={1}>{t('antivirus.disabled', { ns: 'instance' })}</Box>
          </Text>
        </Box>
      )}

      <Box display="flex" mb={1}>
        {instanceSize === INSTANCE_PLANS.SMALL && (
          <Text bodyBold>{t('antivirus.noAntivirus', { ns: 'instance' })}</Text>
        )}
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <Box>
          {virusScans.map((scan, i) =>
            scan.clamscanStatus === 'NEVER_RUN' ? (
              <Accordion key={scan.id} disabled>
                <AccordionSummary>
                  <Text bodyBold>
                    {t('antivirus.noVirusScan', {
                      ns: 'instance',
                      node: scan.subscriptNumber,
                    })}
                  </Text>
                </AccordionSummary>
              </Accordion>
            ) : (
              <Accordion key={scan.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Text bodyBold>
                    {t('antivirus.recentScan', {
                      ns: 'instance',
                      node: scan.subscriptNumber,
                      date: formatDateTimeUTC(
                        scan.clamscanResult?.startDate,
                        user?.timeZone
                      ),
                    })}
                  </Text>
                </AccordionSummary>
                <AccordionDetails>
                  {scan.clamscanStatus === 'ERROR' ? (
                    <Alert severity="error" style={{ width: '100%' }}>
                      {t('antivirus.error', { ns: 'instance' })}
                    </Alert>
                  ) : (
                    <ScanResult result={scan} timezone={user?.timeZone} />
                  )}
                </AccordionDetails>
              </Accordion>
            )
          )}
        </Box>
      )}
    </>
  );
};

VirusScansSection.propTypes = {
  antivirus: PropTypes.object,
  instanceSize: PropTypes.string,
  fetchVirusScans: PropTypes.func,
};

export default VirusScansSection;
