import { instance, getToken } from './config';

const getBackups = (projectId) =>
  instance.get(`/projects/${projectId}/backups`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const restoreFileBackup = (projectId, backupId, data) =>
  instance.post(
    `/projects/${projectId}/backups/${backupId}/restore?FILE`,
    data,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const restoreIndexBackup = (projectId, backupId, data) =>
  instance.post(
    `/projects/${projectId}/backups/${backupId}/restore?INDEX`,
    data,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const getBackupDownload = (projectId, backupId) =>
  instance.get(`/projects/${projectId}/backups/${backupId}/link`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteBackup = (projectId, backupId) =>
  instance.delete(`/projects/${projectId}/backups/${backupId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

export const backupApis = {
  getBackups,
  restoreFileBackup,
  restoreIndexBackup,
  getBackupDownload,
  deleteBackup,
};
