import { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import ExtensionIcon from '@material-ui/icons/Extension';
import ForumIcon from '@material-ui/icons/Forum';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useUser } from '../../../contexts/UserContext';
import { PUBLIC_ROUTE, PRIVATE_ROUTE, buildPath } from '../../../routes/routes';
import Dropdown from '../../elements/dropdown/Dropdown';
import Image from '../../elements/image/Image';
import Logo from '../logo/Logo';
import { useStyles } from './PrivateHeader.styles';

const PrivateHeader = ({ open, fullWidth, handleLogout, isAdmin }) => {
  const classes = useStyles(open);
  const history = useHistory();
  const { t } = useTranslation('titles');
  const { projectId } = useParams();
  const { user } = useUser();

  const [createAnchor, setCreateAnchor] = useState(null);
  const [userAnchor, setUserAnchor] = useState(null);

  const toggleCreateDropdown = (e) =>
    createAnchor ? setCreateAnchor(null) : setCreateAnchor(e.currentTarget);

  const toggleUserDropdown = (e) =>
    userAnchor ? setUserAnchor(null) : setUserAnchor(e.currentTarget);

  const routeTo = (path, toggleFunc) => {
    toggleFunc?.();
    history.push(path);
  };

  const isJaLocale = user?.locale === 'ja';

  const createDropdown =
    projectId && isAdmin
      ? [
          {
            name: t('newProject'),
            handleClick: () =>
              routeTo(PRIVATE_ROUTE.PROJECT_NEW, toggleCreateDropdown),
          },
          {
            name: t('newInstance'),
            handleClick: () =>
              routeTo(
                buildPath({
                  path: PRIVATE_ROUTE.INSTANCE_NEW,
                  params: { projectId },
                }),
                toggleCreateDropdown
              ),
          },
        ]
      : [
          {
            name: t('newProject'),
            handleClick: () =>
              routeTo(PRIVATE_ROUTE.PROJECT_NEW, toggleCreateDropdown),
          },
        ];

  const userDropdown = [
    {
      name: t('profile'),
      handleClick: () =>
        routeTo(PRIVATE_ROUTE.USER_PROFILE, toggleUserDropdown),
    },
    {
      name: t('account'),
      handleClick: () =>
        routeTo(PRIVATE_ROUTE.USER_ACCOUNT, toggleUserDropdown),
    },
    {
      name: t('logout'),
      handleClick: handleLogout,
    },
  ];

  return (
    <AppBar
      elevation={3}
      position="fixed"
      className={fullWidth ? classes.expand : classes.shift}
    >
      <Toolbar className={classes.noPadding}>
        {fullWidth && <Logo />}
        <div className={classes.grow} />

        <Tooltip title={t('forum')}>
          <IconButton
            color="inherit"
            className={classes.menuButton}
            target="_blank"
            href={isJaLocale ? PUBLIC_ROUTE.FORUM : PUBLIC_ROUTE.EN_FORUM}
          >
            <ForumIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('docs')}>
          <IconButton
            color="inherit"
            className={classes.menuButton}
            target="_blank"
            href={
              isJaLocale
                ? PUBLIC_ROUTE.DOCUMENTATION
                : PUBLIC_ROUTE.EN_DOCUMENTATION
            }
          >
            <LibraryBooksIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('extensionLibrary')}>
          <IconButton
            color="inherit"
            className={classes.menuButton}
            aria-label="go to extension page"
            onClick={() =>
              routeTo(
                buildPath({
                  path: PRIVATE_ROUTE.EXTENSIONS,
                })
              )
            }
          >
            <ExtensionIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="create-menu"
          onClick={toggleCreateDropdown}
        >
          <AddIcon />
        </IconButton>
        <Dropdown
          id="create-menu"
          anchorEl={createAnchor}
          onClose={toggleCreateDropdown}
        >
          {createDropdown.map(({ name, handleClick }) => (
            <MenuItem key={name} onClick={handleClick}>
              {name}
            </MenuItem>
          ))}
        </Dropdown>
        <IconButton
          aria-label="user-menu"
          className={classes.menuButton}
          onClick={toggleUserDropdown}
          style={{ padding: 0 }}
        >
          <Image alt={`avatar-${user?.initials}`} src={user?.icon}>
            {user?.initials}
          </Image>
        </IconButton>

        <Dropdown
          id="user-menu"
          anchorEl={userAnchor}
          onClose={toggleUserDropdown}
        >
          {userDropdown.map(({ name, handleClick }, i) =>
            i === userDropdown.length - 1 ? (
              <div key={name}>
                <Divider />
                <MenuItem aria-label="log out" onClick={handleClick}>
                  {name}
                </MenuItem>
              </div>
            ) : (
              <MenuItem key={name} onClick={handleClick}>
                {name}
              </MenuItem>
            )
          )}
        </Dropdown>
      </Toolbar>
    </AppBar>
  );
};

PrivateHeader.propTypes = {
  open: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleLogout: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default PrivateHeader;
