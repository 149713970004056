import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';

import LanguageForm from './LanguageForm';
import NewsForm from './NewsForm';
import PasswordReset from './PasswordReset';
import PublicNameForm from './PublicNameForm';
import TimezoneForm from './TimezoneForm';
import ProfileUpdateForm from './UserInfoForm';

const UserProfile = ({
  user,
  userInfoSubmit,
  passwordReset,
  handleRemoveImage,
  updateUserPreferences,
}) => {
  return (
    <Container maxWidth="md">
      <Grid container style={{ marginTop: 5, margin: 'auto' }}>
        <Grid item sm={12} md={6}>
          <LanguageForm onSubmit={updateUserPreferences} />
        </Grid>
        <Grid item sm={12} md={6}>
          <PublicNameForm onSubmit={updateUserPreferences} />
        </Grid>
        <Grid item sm={12} md={6}>
          <TimezoneForm onSubmit={updateUserPreferences} />
        </Grid>
      </Grid>
      <ProfileUpdateForm
        user={user}
        onSubmit={userInfoSubmit}
        handleRemoveImage={handleRemoveImage}
      />
      <NewsForm onSubmit={updateUserPreferences} />
      <PasswordReset handleReset={passwordReset} />
    </Container>
  );
};

UserProfile.propTypes = {
  user: PropTypes.object.isRequired,
  userInfoSubmit: PropTypes.func.isRequired,
  passwordReset: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
  updateUserPreferences: PropTypes.func.isRequired,
};

export default UserProfile;
