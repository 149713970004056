import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PAYMENT_TYPES, TIMEZONE_LIST } from '../../../../constants';
import {
  CRAWLER_PERIOD_TYPES,
  CRAWLER_TYPES,
  JOB_TYPE,
} from '../../../../constants/crawlerConstants';
import { getTime } from '../../../../utils/timeUtils';
import Text from '../../../elements/text/Text';
import PaymentTable from '../../../sections/payment-table/PaymentTable';
import {
  fieldDisplay,
  boxFieldDisplay,
  displayJobType,
  getScheduledDay,
} from '../crawlerUtils';

const CrawlStep6 = ({ billingInfo, getValues, instances, card }) => {
  const { t, i18n } = useTranslation();

  const isInvoicePayment = billingInfo?.paymentType === PAYMENT_TYPES.INVOICE;

  const formatSchedule = (schedule) => {
    if (schedule.period === CRAWLER_PERIOD_TYPES.MANUAL.value)
      return t('frequency.none', { ns: 'menus' });

    const jobPeriod = find(CRAWLER_PERIOD_TYPES, {
      value: schedule.period,
    });

    const time = getTime(schedule.time);
    const day = getScheduledDay(schedule.period, schedule?.day);
    const zone = TIMEZONE_LIST.find(
      ({ zoneId }) => zoneId === schedule.timezone
    ).label;

    return i18n.language === 'ja'
      ? `${t(jobPeriod.label, { ns: 'menus' })}${day} ${time} ${zone}`
      : `${t(jobPeriod.label, { ns: 'menus' })} ${day} ${time} ${zone}`;
  };

  const filteredCrawlArgs = getValues('crawlArgs').filter(
    (args) => !!args.name && !!args.val
  );

  const jobSettingsFields = [
    fieldDisplay(t('jobName', { ns: 'fields' }), getValues('jobName')),
    fieldDisplay(
      t('jobDescription', { ns: 'fields' }),
      getValues('jobDescription')
    ),
    fieldDisplay(
      t('stepper.jobType', { ns: 'crawler' }),
      displayJobType(getValues('jobType'))
    ),
    fieldDisplay(t('user', { ns: 'fields' }), getValues('user')),
    fieldDisplay(
      t('seeds', { ns: 'fields' }),
      getValues('seeds')
        .split('\n')
        .map((seed) => (
          <Text body style={{ marginLeft: '1em' }} key={seed}>
            {seed}
          </Text>
        ))
    ),
  ];

  const instance = find(
    instances,
    (instance) => instance.id === getValues('instance')
  );

  const solrSettingsFields = [
    'collection',
    'uniqueKey',
    'updateHandler',
    'removeHandler',
    'statusHandler',
    'commitTime',
    'commitAfterJob',
  ].map((field) => (
    <div key={field}>
      {fieldDisplay(t(field, { ns: 'fields' }), getValues(field).toString())}
    </div>
  ));

  const maxSeeds = CRAWLER_TYPES[getValues('jobType')].SEEDS;
  const maxSeedsDisplay =
    maxSeeds > 0 ? maxSeeds : t('stepper.unlimited', { ns: 'crawler' });
  const schedule = {
    period: getValues('periodType'),
    time: getValues('periodTime'),
    day: getValues('day') || getValues('date'),
    timezone: getValues('timezone'),
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column" width="50%">
          <Box mb={2}>
            <Text subtitleBold>
              {t('crawler.jobSettings', { ns: 'sectionTitles' })}
            </Text>
            {jobSettingsFields}
            {getValues('subseeds') && (
              <Text component="div" bodyBold style={{ marginLeft: '1em' }}>
                {`${t('additionalSeeds', { ns: 'fields' })}: `}
                {getValues('subseeds')
                  .split('\n')
                  .map((subseed, i) => (
                    <Text body style={{ marginLeft: '1em' }} key={i}>
                      {subseed}
                    </Text>
                  ))}
              </Text>
            )}
          </Box>

          <Box mb={2}>
            <Text subtitleBold>
              {t('crawler.solrSettings', { ns: 'sectionTitles' })}
            </Text>
            {fieldDisplay(t('instance', { ns: 'fields' }), instance.name)}
            {solrSettingsFields}
            {!isEmpty(filteredCrawlArgs) &&
              fieldDisplay(
                t('arguments', { ns: 'crawler' }),
                filteredCrawlArgs.map((field) => (
                  <div key={field.name}>
                    {fieldDisplay(field.name, field.val)}
                  </div>
                ))
              )}
          </Box>

          <Box mb={2}>
            <Text subtitleBold>
              {t('crawler.scheduleSettings', { ns: 'sectionTitles' })}
            </Text>
            <Text bodyBold style={{ marginLeft: '1em' }}>
              {formatSchedule(schedule)}
            </Text>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="45%">
          <Text subtitleBold>
            {t('crawler.extraSettings', { ns: 'sectionTitles' })}
          </Text>
          {[
            'includeInCrawl',
            'includeInIndex',
            'excludeFromCrawl',
            'excludeFromIndex',
            'excludeContentFromIndex',
          ].map((field) => (
            <Box key={field}>
              {boxFieldDisplay(t(field, { ns: 'fields' }), getValues(field))}
            </Box>
          ))}
        </Box>
      </Box>

      {getValues('jobType') !== JOB_TYPE.FREE && (
        <>
          <Divider />
          <Text
            subtitleBold
            style={{ marginTop: '1em', marginBottom: '0.5em' }}
          >
            {t('billingInfo', { ns: 'sectionTitles' })}
          </Text>
          <PaymentTable
            rows={[
              [
                t('stepper.maxSeedsJob', {
                  ns: 'crawler',
                  number: maxSeedsDisplay,
                }),
                CRAWLER_TYPES[getValues('jobType')].PRICE,
              ],
            ]}
            isInvoicePayment={isInvoicePayment}
            card={card}
          />
        </>
      )}
    </Box>
  );
};

CrawlStep6.propTypes = {
  getValues: PropTypes.func.isRequired,
  instances: PropTypes.array.isRequired,
  billingInfo: PropTypes.object,
  card: PropTypes.object,
};

export default CrawlStep6;
