import { MenuItem, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PUBLIC_NAME_LIST } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import Title from '../../elements/title/Title';

const PublicNameForm = ({ onSubmit }) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const NAMES = { USERNAME: user?.login, FULL_NAME: user?.fullName };

  const handlePublicNameChange = async (e) => {
    const { name, value } = e.target;
    await onSubmit(name, value);
  };

  const select = (
    <Select
      value={user?.authorDisplay}
      name="authorDisplay"
      onChange={handlePublicNameChange}
      inputProps={{ 'aria-label': 'preferred name select' }}
      fullWidth
    >
      {PUBLIC_NAME_LIST.map((name) => (
        <MenuItem key={name.code} value={name.code}>
          {t(name.label, { ns: 'user' })} ({NAMES[name.code]})
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <>
      <Title subTitle text={t('publicNameSettings', { ns: 'sectionTitles' })} />

      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
          {select}
        </Grid>
      </Grid>
    </>
  );
};

PublicNameForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PublicNameForm;
