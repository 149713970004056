import { Chip, TableCell, Tooltip, makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BACKUP_STATUS } from '../../../../constants';
import { sentenceCase } from '../../../../utils/utils';
import Text from '../../../elements/text/Text';
import TextChip from './TextChip';

const useStyles = makeStyles((theme) => ({
  created: {
    backgroundColor: theme.palette.success.light,
    color: 'white',
  },
  failed: {
    backgroundColor: theme.palette.error.light,
    color: 'white',
  },
  loading: {
    backgroundColor: theme.palette.warning.light,
    color: 'white',
  },
}));

const StatusCell = ({ subject, status, schedules, error }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const statusColor =
    status === BACKUP_STATUS.CREATED
      ? classes.created
      : status === BACKUP_STATUS.FAILED
      ? classes.failed
      : classes.loading;

  const statusIcon =
    status === BACKUP_STATUS.CREATED ? (
      <CheckCircleOutlineIcon fontSize="small" className={statusColor} />
    ) : status === BACKUP_STATUS.FAILED ? (
      <ErrorOutlineIcon fontSize="small" className={statusColor} />
    ) : (
      <QueryBuilderIcon fontSize="small" className={statusColor} />
    );

  return (
    <TableCell>
      <Text text={subject} />
      {status && (
        <Tooltip placement="right" title={error || ''}>
          <Chip
            aria-label="status"
            className={statusColor}
            size="small"
            icon={statusIcon}
            label={
              <Text caption>{sentenceCase(status).replace(/_/g, ' ')}</Text>
            }
          />
        </Tooltip>
      )}
      {schedules && (
        <TextChip
          title={t('backup.saved', { ns: 'project' })}
          text={schedules}
        />
      )}
    </TableCell>
  );
};

StatusCell.propTypes = {
  subject: PropTypes.string.isRequired,
  status: PropTypes.string,
  schedules: PropTypes.number,
  error: PropTypes.string,
};

export default StatusCell;
