import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FolderIcon from '@material-ui/icons/Folder';
import PropTypes from 'prop-types';

import { formatBytes } from '../../../utils/utils';

const FileList = ({ files, removeFile }) => {
  const handleRemove = (index) => {
    removeFile(index);
  };

  return files?.map((file, i) => (
    <ListItem key={file.path}>
      <ListItemAvatar>
        <Avatar>
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={file.path} secondary={formatBytes(file.size)} />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="remove file"
          onClick={() => handleRemove(i)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ));
};

FileList.propTypes = {
  files: PropTypes.array.isRequired,
  removeFile: PropTypes.func.isRequired,
};

export default FileList;
