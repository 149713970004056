import { useState } from 'react';

import {
  Box,
  Button,
  ClickAwayListener,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneIcon from '@material-ui/icons/Done';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Image from '../image/Image';

const UserCard = ({ publicName, icon, email }) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);

  const handleCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(email);
    setCopied(true);
  };

  return (
    <Box display="flex" p={1}>
      <Image src={icon} alt={publicName} shape="circle" size="large" />
      <Box display="block" ml={2} mt={2}>
        <Tooltip
          title={publicName}
          placement="top"
          arrow
          style={{ cursor: 'default' }}
        >
          <Typography variant="h4" gutterBottom>
            {truncate(publicName, { length: 20 })}
          </Typography>
        </Tooltip>
        {email && (
          <Box display="flex" alignItems="center">
            <Typography style={{ cursor: 'default' }}>
              {truncate(email, { length: 20 })}
            </Typography>
            <Tooltip
              title={
                copied
                  ? t('copied', { ns: 'tooltips' })
                  : t('copyEmail', { ns: 'tooltips' })
              }
            >
              <Button
                variant="outlined"
                onClick={(e) => handleCopy(e)}
                style={{ minWidth: 0, marginLeft: '5px', padding: '5px' }}
              >
                {copied ? (
                  <DoneIcon fontSize="small" style={{ color: green[500] }} />
                ) : (
                  <AssignmentIcon fontSize="small" />
                )}
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

UserCard.propTypes = {
  publicName: PropTypes.string.isRequired,
  icon: PropTypes.string,
  email: PropTypes.string,
};

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #dadde9',
    maxWidth: '450px',
    pointerEvents: 'auto',
  },
}))(Tooltip);

const UserTooltip = ({ children, user }) => {
  const [open, setOpen] = useState(false);

  const openTooltip = () => setOpen(true);
  const closeTooltip = () => setOpen(false);

  const { publicName, icon, email } = user;

  return (
    <ClickAwayListener onClickAway={closeTooltip}>
      <CustomTooltip
        open={open}
        onClose={closeTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<UserCard publicName={publicName} icon={icon} email={email} />}
        data-testid="user-tooltip"
        placement="bottom-end"
      >
        <Box onClick={openTooltip}>{children}</Box>
      </CustomTooltip>
    </ClickAwayListener>
  );
};

UserTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserTooltip;
