import { makeStyles } from '@material-ui/core/styles';

import { LAYOUT_WIDTH } from '../../layouts/constants';

export const useStyles = makeStyles((theme) => ({
  sidebar: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: (open) => (open ? LAYOUT_WIDTH.MAX : LAYOUT_WIDTH.MIN),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
  imageOnly: {
    '& .nav-brand-name': {
      marginLeft: '10px',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.grey[700],
  },
  sidebarMain: {
    flexGrow: 1,
    marginBottom: LAYOUT_WIDTH.MIN / 2 + 1,
    overflow: 'auto',
    overflowX: 'hidden',
  },
  sidebarIcon: {
    paddingLeft: (open) => (open ? 0 : 2),
    minWidth: (open) => (open ? theme.spacing(4) + 4 : theme.spacing(5) + 4),
    transition: theme.transitions.create(['min-width', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginTop: theme.spacing(0.5),
    borderRadius: (open) => (open ? 0 : '50%'),
    backgroundColor: (open) => (open ? 'initial' : theme.palette.common.white),
    fontSize: (open) => (open ? '1.5rem' : '1rem'),
    transition: theme.transitions.create(
      ['font-size', 'background-color', 'border-radius'],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }
    ),
  },
  sidebarFooter: {
    position: 'fixed',
    bottom: 0,
    background: '#fff',
    overflow: 'hidden',
    padding: 0,
  },
  active: {
    background: '#f4f4f4',
  },
}));
