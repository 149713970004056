import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentenceCase } from '../../../../utils/utils';
import Title from '../../../elements/title/Title';
import NoContent from '../../../sections/no-content/NoContent';
import Row from './Row';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(2),
    minHeight: '257px',
  },
  noHistory: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

const BillingHistory = ({ billingData, handlePagination, downloadReceipt }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const TABLE_HEADERS = [
    t('billing.number', { ns: 'project' }),
    t('billing.date', { ns: 'project' }),
    t('billing.description', { ns: 'project' }),
    t('billing.net', { ns: 'project' }),
    t('billing.tax', { ns: 'project' }),
    t('billing.total', { ns: 'project' }),
    t('billing.status', { ns: 'project' }),
  ];

  const handlePageChange = (event, page) => {
    handlePagination(page - 1);
  };

  return (
    <>
      <Title subTitle text={t('billingHistory', { ns: 'sectionTitles' })} />
      {billingData?.content?.length > 0 ? (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  {TABLE_HEADERS.map((header, i) => (
                    <TableCell align={i !== 0 ? 'center' : 'left'} key={header}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {billingData?.content.map((item, i) => (
                  <Row key={i} data={item} downloadReceipt={downloadReceipt} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Pagination
              count={billingData.totalPages}
              shape="rounded"
              onChange={handlePageChange}
            />
          </Box>
        </>
      ) : (
        <NoContent
          title={sentenceCase(
            t('empty', {
              ns: 'descriptions',
              text: t('billing.history', { ns: 'project' }),
            })
          )}
        />
      )}
    </>
  );
};

BillingHistory.propTypes = {
  billingData: PropTypes.object.isRequired,
  handlePagination: PropTypes.func.isRequired,
  downloadReceipt: PropTypes.func.isRequired,
};

export default BillingHistory;
