import { DEFAULT_SUBSCRIPT_NUMBER } from '../constants';
import { instance, getToken } from './config';

const createFolder = (projectId, instanceId, path, instanceSubscriptNumber) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/files/dir/touch`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: { path, instanceSubscriptNumber },
    }
  );

const renameFolderFile = (
  projectId,
  instanceId,
  oldPath,
  newPath,
  instanceSubscriptNumber
) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/files/rename`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: {
        instanceSubscriptNumber,
        oldPath,
        newPath,
      },
    }
  );

const deleteFolderFile = (
  projectId,
  instanceId,
  instanceSubscriptNumber = DEFAULT_SUBSCRIPT_NUMBER,
  basePath,
  names
) =>
  instance.delete(`/projects/${projectId}/instance-sets/${instanceId}/files`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      instanceSubscriptNumber,
      basePath: basePath,
      names: names,
    },
  });

const downloadFiles = (
  projectId,
  instanceId,
  isDownload,
  instanceSubscriptNumber = DEFAULT_SUBSCRIPT_NUMBER,
  basePath,
  names
) =>
  instance.get(`/projects/${projectId}/instance-sets/${instanceId}/files`, {
    responseType: isDownload ? 'arraybuffer' : '',
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      instanceSubscriptNumber,
      basePath: basePath,
      names: names,
    },
  });

const getFileList = (
  projectId,
  instanceId,
  path,
  instanceSubscriptNumber = DEFAULT_SUBSCRIPT_NUMBER
) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/files/list`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: {
        instanceSubscriptNumber,
        path,
      },
    }
  );

const uploadFiles = (
  projectId,
  instanceId,
  instanceSubscriptNumber,
  basePath,
  body
) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/files/reg`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        instanceSubscriptNumber,
        basePath: basePath,
      },
      timeout: 60000,
    }
  );

const uploadAndUnzipFiles = (
  projectId,
  instanceId,
  instanceSubscriptNumber,
  basePath,
  body
) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/files/dir`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        instanceSubscriptNumber,
        basePath: basePath,
      },
      timeout: 60000,
    }
  );

export const fileManagerApis = {
  createFolder,
  deleteFolderFile,
  downloadFiles,
  getFileList,
  renameFolderFile,
  uploadAndUnzipFiles,
  uploadFiles,
};
