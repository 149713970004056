import { useState } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import DictionaryActions from './DictionaryActions';
import SynonymSetModal from './modals/SynonymSetModal';
import SearchBar from './SearchBar';

const useStyles = makeStyles((theme) => ({
  addButton: ({ isSmallScreen }) => ({
    flex: 1,
    height: '50%',
    marginLeft: isSmallScreen ? 0 : theme.spacing(1),
    marginRight: theme.spacing(1),
  }),
}));

const SynonymActionGroup = ({
  dictionary,
  handleCreateSynonymSet,
  isSearching,
  searchConfig,
  updateSearchConfig,
  numberFound,
  ...dictionaryActions
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isSmallScreen });

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const openCreateModal = () => setCreateModalOpen(true);
  const closeCreateModal = () => setCreateModalOpen(false);

  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? 'column-reverse' : 'row'}
      mb={2}
    >
      <SearchBar
        isSearching={isSearching}
        numberFound={numberFound}
        searchConfig={searchConfig}
        updateSearchConfig={updateSearchConfig}
      />

      <Box display="flex" my={isSmallScreen ? 2 : 0}>
        <Button
          startIcon={<AddIcon />}
          size="small"
          className={classes.addButton}
          onClick={openCreateModal}
          aria-label="add synonym set"
        >
          {t('add', {
            ns: 'buttons',
            text: t('synonym.set', { ns: 'project' }),
          })}
        </Button>

        <DictionaryActions dictionary={dictionary} {...dictionaryActions} />
      </Box>

      <SynonymSetModal
        open={createModalOpen}
        handleClose={closeCreateModal}
        handleCreate={handleCreateSynonymSet}
      />
    </Box>
  );
};

SynonymActionGroup.propTypes = {
  dictionary: PropTypes.object,
  handleCreateSynonymSet: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  searchConfig: PropTypes.object,
  updateSearchConfig: PropTypes.func,
  numberFound: PropTypes.number,
};

export default SynonymActionGroup;
