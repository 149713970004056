import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LANDING_PAGE } from '../../../routes/routes';
import Button from '../../elements/button/Button';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[100],
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  errorType: {
    fontSize: '11rem',
    fontWeight: 200,
    color: 'transparent',
  },
  forbidden: {
    background: 'linear-gradient(10deg, #ffdb65, #ffa600)',
    '-webkit-background-clip': 'text',
    backgroundClip: 'text',
  },
  notFound: {
    background: 'linear-gradient(10deg, #e9e9e9, #808080)',
    '-webkit-background-clip': 'text',
    backgroundClip: 'text',
  },
  internalError: {
    background: 'linear-gradient(10deg, #82eefc, #008cff)',
    '-webkit-background-clip': 'text',
    backgroundClip: 'text',
  },
  title: {
    fontSize: '2.8rem',
    marginTop: '-3.5rem',
  },
  content: {
    fontSize: '0.9rem',
  },
}));

const Error = ({ error }) => {
  const { t } = useTranslation();
  const classes = useStyles(error.type);
  const history = useHistory();
  const { type, title, content } = error;

  const goBack = () => history.goBack();
  const goToLandingPage = () => history.push(LANDING_PAGE);

  return (
    <div className={classes.container}>
      <Typography
        className={clsx(classes.errorType, {
          [classes.forbidden]: type === 403,
          [classes.notFound]: type === 404,
          [classes.internalError]: type === 500,
        })}
      >
        {type}
      </Typography>
      <Box textAlign="center">
        <Typography className={classes.title}>{title.toUpperCase()}</Typography>
        <Box my={2}>
          {content.map((item) => (
            <Typography key={item} className={classes.content}>
              {item}
            </Typography>
          ))}
        </Box>
        <Box my={4}>
          {history.length > 1 && (
            <Button
              aria-label="to previous page"
              onClick={goBack}
              variant="outlined"
              style={{ marginRight: '0.5rem' }}
            >
              {t('back', { ns: 'buttons' })}
            </Button>
          )}
          <Button aria-label="to landing page" onClick={goToLandingPage}>
            {t('backToHome', { ns: 'buttons' })}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

Error.propTypes = {
  error: PropTypes.object.isRequired,
};

export default Error;
