import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { BACKUP_LABELS, BACKUP_TYPES } from '../../../../constants';
import { useUser } from '../../../../contexts/UserContext';
import { formatDateTimeUTC } from '../../../../utils/timeUtils';
import {
  match,
  sentenceCase,
  isSolrCloud,
  isVersionCompatible,
} from '../../../../utils/utils';
import Button from '../../../elements/button/Button';
import Text from '../../../elements/text/Text';
import Modal from '../../../sections/modal/Modal';

const useStyles = makeStyles((theme) => ({
  textPadLeft: {
    paddingLeft: theme.spacing(16),
    lineHeight: 1.43,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '49%',
  },
  modalItem: {
    marginBottom: theme.spacing(2),
  },
  noTopMargin: {
    marginTop: 0,
  },
  fileCard: {
    overflow: 'auto',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const RestoreModal = ({
  open,
  backup,
  onRestore,
  getInstances,
  getCollections,
  onClose,
  isFile,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useUser();

  const titles = [
    t('backupSubject', { ns: 'fields' }),
    t('instance', { ns: 'instance' }),
    t('backup.executeDate', { ns: 'project' }),
    t('backupType', { ns: 'fields' }),
  ];

  const indexTitles = [t('collection', { ns: 'instance' })];
  const fileTitles = [
    t('nodeNumber', { ns: 'instance' }),
    [
      t('includedFiles', { ns: 'fields' }),
      t('excludedFiles', { ns: 'fields' }),
    ],
  ];

  const data = [
    backup?.subject,
    backup?.instanceSetName,
    backup?.createDate,
    backup?.category,
  ];

  const indexData = [backup?.collection];
  const fileData = [
    backup?.instanceSubscript,
    [backup?.includeFiles, backup?.excludeFiles],
  ];

  const [instanceList, setInstanceList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [versionWarning, setVersionWarning] = useState('');

  const schema = yup.object().shape({
    instance: yup.string().required(
      sentenceCase(
        t('selectRequired', {
          ns: 'validations',
          field: t('instance', { ns: 'instance' }),
        })
      )
    ),
    collection: yup.mixed().test(
      'collectionRequired',
      sentenceCase(
        t('selectRequired', {
          ns: 'validations',
          field: t('collection', { ns: 'instance' }),
        })
      ),
      (val) => (!isFile ? !!val : true)
    ),
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchInstance = watch('instance');

  const getInstanceData = async () => {
    try {
      const instanceRes = await getInstances();
      setInstanceList(instanceRes);
    } catch {
      setInstanceList([]);
    }
  };

  const getCollectionData = async () => {
    try {
      const chosenInstance = match(instanceList, watchInstance, 'id');
      const collectionsRes = await getCollections(chosenInstance);
      setCollectionList(collectionsRes);
    } catch {
      setCollectionList([]);
    }
  };

  useEffect(() => {
    if (!backup) return;
    getInstanceData();
    // eslint-disable-next-line
  }, [backup]);

  useEffect(() => {
    if (isFile || !watchInstance) return;

    const chosenInstance = match(instanceList, watchInstance, 'id');

    const isCompatible = isVersionCompatible(
      backup.solrVersion,
      chosenInstance.solrVersion
    );
    setVersionWarning(
      isCompatible
        ? ''
        : t('restoreVersionDiffWarning', {
            ns: 'descriptions',
            origin: backup.solrVersion,
            target: chosenInstance.solrVersion,
          })
    );

    setValue('collection', '');
    getCollectionData();
    // eslint-disable-next-line
  }, [watchInstance]);

  const handleRestore = (data) => {
    const col = data.collection || backup.collection;
    onRestore(data.instance, col, isFile);
  };

  const handleClose = () => onClose();

  return (
    <Modal
      title={
        isFile
          ? t('backup.fileRestore', { ns: 'project' })
          : t('backup.indexRestore', { ns: 'project' })
      }
      openModal={open}
      handleClose={handleClose}
    >
      {titles.map((section, index) => (
        <Box display="flex" className={classes.modalItem} key={index}>
          <Box className={classes.title}>
            <Text bodyBold>{section}</Text>
          </Box>
          <Box className={classes.noTopMargin}>
            {section === t('backup.executeDate', { ns: 'project' }) ? (
              <Text data-testid={data[index]}>
                {data[index]
                  ? formatDateTimeUTC(data[index], user?.timeZone)
                  : '-'}
              </Text>
            ) : section === t('backupType', { ns: 'fields' }) ? (
              <Text data-testid={data[index]}>
                {t(find(BACKUP_LABELS, { value: data[index] })?.label, {
                  ns: 'menus',
                })}
              </Text>
            ) : (
              <Text data-testid={data[index]}>{data[index]}</Text>
            )}
          </Box>
        </Box>
      ))}

      {!isFile &&
        indexTitles.map((section, index) => (
          <Box display="flex" className={classes.modalItem} key={index}>
            <Box className={classes.title}>
              <Text bodyBold>{section}</Text>
            </Box>
            <Box className={classes.noTopMargin}>
              <Text data-testid={indexData[index]}>{indexData[index]}</Text>
            </Box>
          </Box>
        ))}

      {isFile &&
        fileTitles.map((section, index) =>
          index === 0 ? (
            isSolrCloud(backup.solrType) &&
            backup.category === BACKUP_TYPES.FILE && (
              <Box display="flex" className={classes.modalItem} key={index}>
                <Box className={classes.title}>
                  <Text bodyBold>{section}</Text>
                </Box>
                <Box className={classes.noTopMargin}>
                  <Text data-testid={fileData[index]}>{fileData[index]}</Text>
                </Box>
              </Box>
            )
          ) : (
            <Box key={index}>
              {/* Included && Excluded Files */}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                {fileTitles[index].map((section, fileIndex) => (
                  <Box flexDirection="column" width="50%" key={fileIndex}>
                    <Text bodyBold style={{ fontSize: '0.75em' }}>
                      {section}
                    </Text>
                    <Card
                      variant="outlined"
                      aria-label="preview card"
                      className={classes.fileCard}
                    >
                      <Box p={1} height={'5em'} overflow="auto">
                        <Text style={{ whiteSpace: 'pre-wrap' }}>
                          {fileData[index][fileIndex]}
                        </Text>
                      </Box>
                    </Card>
                  </Box>
                ))}
              </Box>

              {/* Backed Up Files */}
              <Box display="flex" flexDirection="column">
                <Text bodyBold style={{ fontSize: '0.75em' }}>
                  {t('backup.backedUpFiles', { ns: 'project' })}
                </Text>
                <Card
                  variant="outlined"
                  aria-label="preview card"
                  className={classes.fileCard}
                >
                  <Box p={1} height="5em" overflow="auto">
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                      {backup?.files}
                    </Text>
                  </Box>
                </Card>
              </Box>
            </Box>
          )
        )}

      <form onSubmit={handleSubmit(handleRestore)}>
        <Grid container spacing={2}>
          {versionWarning && <Alert severity="warning">{versionWarning}</Alert>}
          {/* Instance Select */}
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.instance}>
              <InputLabel>
                {t('backup.restoreTo', {
                  ns: 'project',
                  text: t('instance', { ns: 'instance' }),
                })}
              </InputLabel>
              <Controller
                control={control}
                name="instance"
                shouldUnregister
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    label={t('backup.restoreTo', {
                      ns: 'project',
                      text: t('instance', { ns: 'instance' }),
                    })}
                    inputProps={{ 'aria-label': 'instance-dropdown' }}
                  >
                    {instanceList?.map((i) => (
                      <MenuItem value={i.id} key={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>{errors.instance?.message}</FormHelperText>
            </FormControl>
          </Grid>

          {/* Collection Select */}
          {!isFile && watchInstance && (
            <Grid item xs={12} mt={2}>
              <FormControl fullWidth error={!!errors.collection}>
                <InputLabel>
                  {t('backup.restoreTo', {
                    ns: 'project',
                    text: t('collection', { ns: 'instance' }),
                  })}
                </InputLabel>
                <Controller
                  control={control}
                  name="collection"
                  shouldUnregister
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('backup.restoreTo', {
                        ns: 'project',
                        text: t('collection', { ns: 'instance' }),
                      })}
                      inputProps={{ 'aria-label': 'collection-dropdown' }}
                    >
                      {collectionList?.map((c) => (
                        <MenuItem value={c} key={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>{errors.collection?.message}</FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Box
          display="flex"
          marginBottom={1}
          marginTop={3}
          justifyContent="center"
        >
          <Button type="submit">{t('restore', { ns: 'titles' })}</Button>
        </Box>
      </form>
    </Modal>
  );
};

RestoreModal.propTypes = {
  backup: PropTypes.object,
  getInstances: PropTypes.func,
  getCollections: PropTypes.func,
  onRestore: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  isFile: PropTypes.bool,
};

export default RestoreModal;
