import { useEffect, useState } from 'react';

import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { monitoringApis } from '../../../apis/monitoringApis';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import MonitoringData from '../../../components/page-sections/monitoring/MonitoringData';
import MonitoringSettings from '../../../components/page-sections/monitoring/MonitoringSettings';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import {
  DEFAULT_SUBSCRIPT_NUMBER,
  INSTANCE_CATEGORY,
} from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { PRIVATE_ROUTE } from '../../../routes/routes';
import { localDateTimeToUTC } from '../../../utils/timeUtils';

const InstanceMonitoringPage = () => {
  const { projectId, instanceId } = useParams();
  const { showToast } = useToast();
  const { user } = useUser();
  const { t } = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [monitorError, setMonitorError] = useState(false);
  const [project, setProject] = useState(null);
  const [instance, setInstance] = useState(null);
  const [settings, setSettings] = useState({
    node: DEFAULT_SUBSCRIPT_NUMBER,
    term: 900,
    endDateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
    refresh: 0,
  });
  const [monitoringData, setMonitoringData] = useState(null);

  const loadPage = async () => {
    setLoading(true);
    try {
      const [{ data: projectRes }, { data: instanceRes }] = await requestAll([
        projectApis.getProject(projectId),
        solrApis.getInstance(projectId, instanceId),
      ]);
      setProject(projectRes);
      setInstance(instanceRes);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('fetchProjectInstanceError', { ns: 'notifications' })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPage();
    // eslint-disable-next-line
  }, []);

  const handleNodeChange = (node) => {
    setSettings({ ...settings, node: node });
  };

  const handleTermChange = (term) => {
    setSettings({ ...settings, term: term });
  };

  const handleDateTimeChange = (dateTime) => {
    setSettings({ ...settings, endDateTime: dateTime });
  };

  const handleRefreshChange = (refresh) => {
    setSettings({ ...settings, refresh: refresh });
  };

  const handleFetchMonitoringData = async () => {
    const setStep = (term) => (term / 100 < 15 ? 15 : term / 100);
    const params = {
      ...settings,
      step: setStep(settings.term),
      endDateTime: localDateTimeToUTC(
        settings.endDateTime,
        user?.timeZone,
        'YYYY-MM-DDTHH:mm:ss'
      ),
    };

    try {
      const { data: response } = await monitoringApis.getMetrics(
        projectId,
        instance.id,
        'application/json',
        params
      );
      setMonitoringData(response);
      setMonitorError(false);
    } catch (error) {
      setMonitorError(true);
    }
  };

  useEffect(() => {
    if (!instance) return;
    handleFetchMonitoringData();
    // eslint-disable-next-line
  }, [instance, settings]);

  useEffect(() => {
    if (settings.refresh === 0) return;
    const interval = setInterval(() => {
      handleDateTimeChange(
        moment().tz(user?.timeZone).format('YYYY-MM-DDTHH:mm:ss')
      );
    }, settings.refresh);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [settings.refresh, settings.term]);

  const handleDownload = async () => {
    try {
      const params = {
        ...settings,
        step: settings.term / 100,
        endDateTime: localDateTimeToUTC(
          settings.endDateTime,
          user?.timeZone,
          'YYYY-MM-DDTHH:mm:ss'
        ),
      };

      const { data: response } = await monitoringApis.getMetrics(
        projectId,
        instance.id,
        'text/csv',
        params
      );

      const blob = new Blob([response], {
        type: 'text/csv',
      });
      saveAs(blob, moment(settings.endDateTime).format('YYYY-MM-DD HH_mm_ss'));
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('fetchMetricsError', { ns: 'notifications' })
      );
    }
  };

  useEffect(() => {
    if (instance?.category === INSTANCE_CATEGORY.COMMUNITY) {
      history.replace(PRIVATE_ROUTE.ERROR403);
    }
    // eslint-disable-next-line
  }, [instance]);

  return (
    <Container>
      {loading && <Loading />}
      {!loading && (!project || !instance) && (
        <Alert severity="error" style={{ marginTop: '1em' }}>
          {t('fetchProjectInstanceError', { ns: 'notifications' })}
        </Alert>
      )}
      {!loading && project && instance && (
        <>
          <Breadcrumbs
            projectName={project.name}
            instanceName={instance.name}
          />
          <TitlePanel title={t('monitor', { ns: 'titles' })} />
          <MonitoringSettings
            instance={instance}
            settings={settings}
            setSettings={setSettings}
            onNodeChange={handleNodeChange}
            onDateTimeChange={handleDateTimeChange}
            onTermChange={handleTermChange}
            onRefreshChange={handleRefreshChange}
          />
          {(monitoringData || monitorError) && (
            <MonitoringData
              monitoringData={monitoringData}
              onDownload={handleDownload}
              error={monitorError}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default InstanceMonitoringPage;
