import { useEffect } from 'react';

import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { RadioButtonUnchecked } from '@material-ui/icons';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { INSTANCE_CATEGORY, PLAN_LIST } from '../../../constants';
import { DEFAULT_COMMUNITY_SOLR_VERSION } from './constants';
import EnterpriseCard from './EnterpriseCard';
import { useStyles } from './InstanceStepper.styles';
import PlanCard from './PlanCard';
import StepTitle from './StepTitle';

const Plan = ({ community }) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();
  const classes = useStyles();

  useEffect(() => {
    if (watch('plan') !== INSTANCE_CATEGORY.COMMUNITY) return;
    setValue('solrVersion', DEFAULT_COMMUNITY_SOLR_VERSION);
    // eslint-disable-next-line
  }, [watch('plan')]);

  // Maximum community instances allowed: 2
  const communityAvailable = community < 2;

  return (
    <section>
      <StepTitle title={t('selectPlan', { ns: 'descriptions' })} />
      <Controller
        name="plan"
        control={control}
        render={({ field }) => (
          <RadioGroup
            aria-label="plan selection"
            className={classes.radio}
            {...field}
          >
            <Grid container spacing={2}>
              {PLAN_LIST.map((plan) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={plan.name}
                  style={{ display: 'flex' }}
                >
                  <FormControlLabel
                    value={
                      plan.category === INSTANCE_CATEGORY.COMMUNITY
                        ? plan.category
                        : plan.type
                    }
                    control={
                      <Radio
                        style={{ color: 'white' }}
                        checkedIcon={<CircleCheckedFilled />}
                        icon={
                          plan.category !== INSTANCE_CATEGORY.COMMUNITY ? (
                            <RadioButtonUnchecked />
                          ) : (
                            communityAvailable && <RadioButtonUnchecked />
                          )
                        }
                        disabled={
                          plan.category === INSTANCE_CATEGORY.COMMUNITY &&
                          !communityAvailable
                        }
                      />
                    }
                    label={<PlanCard plan={plan} community={community} />}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        )}
      />
      <EnterpriseCard />
    </section>
  );
};

Plan.propTypes = {
  community: PropTypes.number.isRequired,
};

export default Plan;
