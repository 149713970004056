import { useState } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';

const QueryButtons = ({
  onSaveModalToggle,
  onLoadModalToggle,
  onReset,
  onDownload,
  style,
}) => {
  const { t } = useTranslation();
  const [downloading, setDownloading] = useState(false);

  const handleSaveModalToggle = () => onSaveModalToggle();
  const handleLoadModalToggle = () => onLoadModalToggle();
  const handleReset = () => onReset();
  const handleDownload = async () => {
    setDownloading(true);
    await onDownload();
    setDownloading(false);
  };

  return (
    <Box className={style.buttonContainer}>
      <Box>
        <Button
          variant="outlined"
          className={style.notLastButton}
          startIcon={<SaveIcon />}
          onClick={handleSaveModalToggle}
        >
          {t('save', { ns: 'buttons' })}
        </Button>
        <Button
          variant="outlined"
          className={style.notLastButton}
          onClick={handleLoadModalToggle}
        >
          {t('queryList', { ns: 'buttons' })}
        </Button>
        <Button
          variant={downloading ? 'contained' : 'outlined'}
          disabled={downloading}
          startIcon={downloading && <CircularProgress size={20} />}
          className={style.notLastButton}
          onClick={handleDownload}
        >
          {downloading
            ? t('pending', { ns: 'buttons' })
            : t('downloadWebApp', { ns: 'buttons' })}
        </Button>
      </Box>
      <Box>
        <Button
          variant="outlined"
          className={style.lastButton}
          onClick={handleReset}
        >
          {t('clear', { ns: 'buttons' })}
        </Button>
      </Box>
    </Box>
  );
};

QueryButtons.propTypes = {
  onSaveModalToggle: PropTypes.func.isRequired,
  onLoadModalToggle: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default QueryButtons;
