import { useState, useEffect } from 'react';

import { Box, Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { collectionApis } from '../../../apis/collectionApis';
import { isInstanceCreated } from '../../../apis/constants';
import { instanceApis } from '../../../apis/instanceApis';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import Storage from '../../../components/elements/storage/Storage';
import InstanceDetails from '../../../components/page-sections/instance-details/InstanceDetails';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import List from '../../../components/sections/list/List';
import Overview from '../../../components/sections/overview/Overview';
import SolrButtons from '../../../components/sections/overview/SolrButtons';
import { useSolrInstance } from '../../../contexts/SolrContext';
import { useUser } from '../../../contexts/UserContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import {
  getSolrSubdomain,
  runningNodeExists,
  isSolrCloud,
  getFileFormData,
} from '../../../utils/utils';

const InstanceOverviewPage = () => {
  const { t } = useTranslation();

  const { projectId, instanceId } = useParams();
  const { showToast } = useToast();
  const { user } = useUser();
  const { instance, refreshSolrStatus } = useSolrInstance();
  const history = useHistory();

  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [storage, setStorage] = useState(null);
  const [solrProcessing, setSolrProcessing] = useState(false);

  const handleFetchData = async () => {
    try {
      const { data: projectRes } = await projectApis.getProject(projectId);
      setProject(projectRes);
      setSolrProcessing(false);
    } catch (error) {
      handleErrors(error, history, showToast);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line
  }, [projectId]);

  const handleFetchStorage = async () => {
    const storageRes =
      isInstanceCreated(instance.status) &&
      (await instanceApis.getStorage(projectId, instanceId));
    setStorage(storageRes.data);
  };

  useEffect(() => {
    if (!instance) return;
    handleFetchStorage();
    // eslint-disable-next-line
  }, [instance]);

  const handleStartInstance = async () => {
    setSolrProcessing(true);
    try {
      await solrApis.startSolr(projectId, instanceId);
      await refreshSolrStatus();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('solrStartSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('solrStartError', { ns: 'notifications' }));
    } finally {
      setSolrProcessing(false);
    }
  };

  const handleStopInstance = async () => {
    setSolrProcessing(true);
    try {
      await solrApis.stopSolr(projectId, instanceId);
      await refreshSolrStatus();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('solrStopSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.SUCCESS,
        t('solrStopError', { ns: 'notifications' })
      );
    } finally {
      setSolrProcessing(false);
    }
  };

  const handleRestartInstance = async () => {
    setSolrProcessing(true);
    try {
      // manually change instance status while reloading
      instance.instances[0].status = false;

      await solrApis.restartSolr(projectId, instanceId);
      await refreshSolrStatus();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('solrRestartSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      await refreshSolrStatus();
      showToast(
        TOAST_TYPE.ERROR,
        t('solrRestartError', { ns: 'notifications' })
      );
    } finally {
      setSolrProcessing(false);
    }
  };

  const handleStartNode = async (subscriptNum) => {
    try {
      await solrApis.startSolrNode(projectId, instanceId, subscriptNum);
      await refreshSolrStatus();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('solrNodeStartSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('solrNodeStartError', { ns: 'notifications' })
      );
    }
  };

  const handleStopNode = async (subscriptNum) => {
    try {
      await solrApis.stopSolrNode(projectId, instanceId, subscriptNum);
      await refreshSolrStatus();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('solrNodeStopSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('solrNodeStopError', { ns: 'notifications' })
      );
    }
  };

  const getCollections = async (instance) => {
    if (!instance) return;
    if (isSolrCloud(instance.type)) {
      const { data } = await collectionApis.getSolrCloudCollections(
        projectId,
        instance.id
      );
      return data.collections;
    } else {
      const { data } = await collectionApis.getCollections(
        projectId,
        instance.id
      );
      return Object.keys(data.status);
    }
  };

  const handleIndexDocuments = async (collection, documents) => {
    try {
      const bodyPayload = getFileFormData(
        'document',
        documents,
        documents.name
      );

      await collectionApis.indexDocuments(
        projectId,
        instanceId,
        collection,
        bodyPayload
      );
      showToast(TOAST_TYPE.SUCCESS, t('indexSuccess', { ns: 'notifications' }));
    } catch (error) {
      const errorMessage = (status) => {
        switch (status) {
          case 400:
            return t('indexDocsError', { ns: 'notifications' });
          case 404:
            return t('indexCollectionError', { ns: 'notifications' });
          case 500:
            return t('internalServerError', { ns: 'errors' });
          default:
            return t('unknown', { ns: 'errors', status: status || '' });
        }
      };
      showToast(TOAST_TYPE.ERROR, errorMessage(error?.response?.status));
    }
  };

  return (
    <Container>
      {loading && <Loading />}
      {!loading && project && instance && (
        <>
          <Breadcrumbs
            projectName={project.name}
            instanceName={instance.name}
          />
          <Box mb={1}>
            <Overview
              data={instance}
              isInstance
              runningNodeExists={runningNodeExists(instance.instances)}
              onIndexDocuments={handleIndexDocuments}
            >
              <SolrButtons
                instance={instance}
                solrProcessing={solrProcessing}
                onStartInstance={handleStartInstance}
                onStopInstance={handleStopInstance}
                onRestartInstance={handleRestartInstance}
              />
            </Overview>
          </Box>

          <Grid container>
            <Grid item xs={12} md={9}>
              <InstanceDetails
                isSolr
                instance={instance}
                subdomainUrl={getSolrSubdomain(
                  instance.subdomain,
                  instance.type,
                  instance.category,
                  {
                    isCluster: true,
                  }
                )}
                isAdmin={user.isAdmin?.(projectId)}
                getCollections={getCollections}
                onIndexDocuments={handleIndexDocuments}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {storage && (
                <Storage
                  available={Number(storage.available)}
                  total={Number(storage.size)}
                />
              )}
            </Grid>
          </Grid>
          {isSolrCloud(instance.type) && (
            <List
              isSolrNodeList
              instance={instance}
              data={instance.instances}
              onStartNode={handleStartNode}
              onStopNode={handleStopNode}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default InstanceOverviewPage;
