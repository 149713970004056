import { useEffect, useState } from 'react';

import { Container, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { projectApis } from '../../../apis/projectApis';
import Loading from '../../../components/elements/loading/Loading';
import MemberCardList from '../../../components/page-sections/project-member/MemberCardList';
import MemberInviteButton from '../../../components/page-sections/project-member/MemberInviteButton';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { USER_ROLES } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { PRIVATE_ROUTE } from '../../../routes/routes';
import { sentenceCase } from '../../../utils/utils';

// Page
const ProjectMemberPage = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const history = useHistory();
  const { showToast } = useToast();
  const { user, refreshUser } = useUser();

  const [loading, setLoading] = useState(true);
  const [requestError, setRequestError] = useState(false);
  const [project, setProject] = useState(null);
  const [projectMembers, setProjectMembers] = useState(null);
  const [page, setPage] = useState(0);

  const fetchData = async () => {
    try {
      const [{ data: projectRes }, { data: projectMembersRes }] =
        await requestAll([
          projectApis.getProject(projectId),
          projectApis.getProjectMembers(projectId, page),
        ]);

      setProject(projectRes);
      setProjectMembers(projectMembersRes);
    } catch (error) {
      handleErrors(history, error, showToast);
      setRequestError(true);
    }
  };

  const onPageLoad = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  const projectHasOtherAdmins = (id) => {
    const members = projectMembers.content;
    if (members.length < 2) return false;

    // check if there are other project members who are admins
    const projectAdmins = members.filter((member) => {
      return (
        member.id !== id &&
        member.projects.find((project) => projectId === project.projectId)
          .role === USER_ROLES.ADMIN
      );
    });

    return projectAdmins.length > 0;
  };

  const handleInvite = async (data) => {
    try {
      await projectApis.inviteProjectMember(projectId, {
        invitee: data.email,
        role: data.role,
      });
      showToast(
        TOAST_TYPE.SUCCESS,
        t('inviteSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('inviteError', { ns: 'notifications' }));
    }
  };

  const handleUpdate = async (member, role) => {
    const currentProjectRole = member.projects.find(
      (project) => project.id === projectId
    );

    // No change if role doesn't change
    if (currentProjectRole === role) return;

    if (role === USER_ROLES.USER) {
      if (!projectHasOtherAdmins(member.id)) {
        showToast(
          TOAST_TYPE.ERROR,
          t('oneAdminRequired', { ns: 'notifications' })
        );
        return;
      }
    }

    try {
      await projectApis.updateProjectMember(projectId, member.id, {
        role: role,
      });
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('memberRole', { ns: 'fields' }),
          })
        )
      );
      await fetchData();
      if (member.id === user.id) refreshUser();
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('memberRole', { ns: 'fields' }),
          })
        )
      );
    }
  };

  const handleDelete = async (member) => {
    if (!projectHasOtherAdmins(member.id)) {
      showToast(
        TOAST_TYPE.ERROR,
        t('oneAdminRequired', { ns: 'notifications' })
      );
      return;
    }

    try {
      await projectApis.deleteProjectMember(projectId, member.id);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteFormProjectSuccess', {
            ns: 'notifications',
            text: t('user', { ns: 'user' }),
          })
        )
      );
      if (member.id === user.id) {
        setTimeout(() => {
          history.replace(PRIVATE_ROUTE.PROJECTS);
        }, 2000);
      } else {
        fetchData(projectId);
      }
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteFormProjectError', {
            ns: 'notifications',
            text: t('user', { ns: 'user' }),
          })
        )
      );
    }
  };

  const handlePageChange = (_, page) => setPage(page - 1);

  useEffect(() => {
    onPageLoad();
    // eslint-disable-next-line
  }, [projectId, page]);

  return (
    <Container>
      {loading && <Loading />}
      {requestError && (
        <Box mt={2}>
          <Alert severity="error">
            {sentenceCase(
              t('loading', {
                ns: 'errors',
                content: t('projectMembers', { ns: 'project' }),
              })
            )}
          </Alert>
        </Box>
      )}
      {!loading && project && user && projectMembers && (
        <>
          <Breadcrumbs projectName={project.name} />
          <TitlePanel visible title={t('members', { ns: 'titles' })}>
            {user?.isAdmin?.(projectId) && (
              <MemberInviteButton onSubmit={handleInvite} />
            )}
          </TitlePanel>
          <MemberCardList
            data={projectMembers}
            isAdmin={user.isAdmin?.(projectId)}
            onChange={handleUpdate}
            onRemove={handleDelete}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Container>
  );
};

export default ProjectMemberPage;
