import { Accordion as MuiAccordion } from '@material-ui/core';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

const Accordion = ({ id, header, body, actions, dense, ...restProps }) => {
  return (
    <MuiAccordion {...restProps} aria-label="accordion">
      <AccordionSummary
        id={id}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        aria-label="accordion-summary"
      >
        {header}
      </AccordionSummary>
      <AccordionDetails
        aria-label="accordion-details"
        style={dense ? { padding: 0 } : {}}
      >
        {body}
      </AccordionDetails>
      {actions && (
        <>
          <Divider />
          <AccordionActions aria-label="accordion-actions">
            {actions}
          </AccordionActions>
        </>
      )}
    </MuiAccordion>
  );
};

Accordion.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  body: PropTypes.node.isRequired,
  actions: PropTypes.node,
  dense: PropTypes.bool,
};

export default Accordion;
