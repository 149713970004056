import { instance, getToken } from './config';

const getCollections = (projectId, instanceId) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/admin/cores?action=STATUS&indexInfo=false`,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const getCollectionStatus = (projectId, instanceId) =>
  instance.get(
    `projects/${projectId}/instance-sets/${instanceId}/collections/admin/cores?action=STATUS`,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const getClusterStatus = (projectId, instanceId) =>
  instance.get(
    `projects/${projectId}/instance-sets/${instanceId}/collections/admin/collections?action=CLUSTERSTATUS`,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const createCollection = (projectId, instanceId, data) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/collections`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
    }
  );

const reloadCollection = (projectId, instanceId, collectionName) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/${collectionName}/reload`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const renameCollection = (
  projectId,
  instanceId,
  collectionName,
  otherCollectionName
) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/admin/cores?action=RENAME&core=${collectionName}&other=${otherCollectionName}`,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const deleteCollection = (projectId, instanceId, collectionName, options) =>
  instance.delete(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/${collectionName}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: options,
    }
  );

const deleteAllDocs = (projectId, instanceId, collectionName) =>
  instance.delete(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/${collectionName}/documents`,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const deleteDoc = (projectId, instanceId, collectionName, data) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/${collectionName}/documents/delete`,
    data,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const getCollectionRootPath = (projectId, instanceId, data, instSubscript) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/zookeeper/getCollectionRoot`,
    data,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: { instSubscript },
    }
  );

const getSolrCloudCollections = (projectId, instanceId) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/admin/collections?action=LIST`,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const importCollection = (
  projectId,
  instanceId,
  collectionName,
  options,
  data
) =>
  instance.put(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/${collectionName}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
      params: options,
    }
  );

const exportCollection = (projectId, instanceId, collectionName) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/${collectionName}`,
    {
      responseType: 'arraybuffer',
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const indexDocuments = (projectId, instanceId, collectionName, documents) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/${collectionName}/index`,
    documents,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );

const getSchemaUniqueKey = (projectId, instanceId, collectionName) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/${collectionName}/schema/uniquekey`,
    null,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );

export const collectionApis = {
  getCollections,
  getCollectionStatus,
  getClusterStatus,
  createCollection,
  renameCollection,
  reloadCollection,
  deleteCollection,
  deleteAllDocs,
  deleteDoc,
  importCollection,
  exportCollection,
  getCollectionRootPath,
  getSolrCloudCollections,
  indexDocuments,
  getSchemaUniqueKey,
};
