import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { isInstanceRunning } from '../../../apis/constants';
import { useUser } from '../../../contexts/UserContext';
import IndexingButton from '../indexing-button/IndexingButton';
import CidrDetails from './CidrDetails';
import InstanceMetadata from './InstanceMetadata';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const InstanceDetails = ({
  instance,
  subdomainUrl,
  isAdmin,
  isSolr,
  getCollections,
  onIndexDocuments,
}) => {
  const classes = useStyles();
  const { user } = useUser();

  return (
    <>
      <Box className={classes.details}>
        {isSolr && isInstanceRunning(instance) && (
          <IndexingButton
            instance={instance}
            getCollections={getCollections}
            onUpload={onIndexDocuments}
          />
        )}
      </Box>
      {subdomainUrl && (
        <CidrDetails
          instance={instance}
          isAdmin={isAdmin}
          solrUrl={subdomainUrl}
        />
      )}
      <InstanceMetadata instance={instance} timezone={user?.timeZone} />
    </>
  );
};

export default InstanceDetails;

InstanceDetails.propTypes = {
  instance: PropTypes.object.isRequired,
  subdomainUrl: PropTypes.string,
  isAdmin: PropTypes.bool,
  isSolr: PropTypes.bool,
  getCollections: PropTypes.func,
  onIndexDocuments: PropTypes.func,
};
