import { useState } from 'react';

import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { USER_ROLE_LIST } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import Image from '../../elements/image/Image';
import Text from '../../elements/text/Text';
import UserTooltip from '../../elements/user-tooltip/UserTooltip';
import RemoveModal from './RemoveModal';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  name: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: '90%',
  },
  closeIcon: {
    position: 'absolute',
    right: '0px',
    minWidth: 0,
    padding: '0.5em',
    margin: '0.25em',
  },
  image: {
    borderRadius: 50,
    '&:hover': {
      filter: 'brightness(85%)',
    },
  },
}));

const MemberCard = ({ member, isAdmin, onChangeRole, onRemove }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { projectId } = useParams();
  const classes = useStyles();

  const [modal, setModal] = useState(false);

  const handleToggleModal = () => setModal(!modal);
  const handleRemove = async (e) => {
    e.preventDefault();
    await onRemove(member);
    handleToggleModal();
  };

  const currentProject = member.projects.find(
    (project) => project.projectId === projectId
  );

  return (
    <>
      <Card className={classes.card}>
        {(isAdmin || member.id === user.id) && (
          <Tooltip
            title={t('removeMember', { ns: 'project' })}
            placement="top"
            arrow
          >
            <Button
              className={classes.closeIcon}
              aria-label="remove-user"
              onClick={handleToggleModal}
            >
              <CloseIcon fontSize="small" />
            </Button>
          </Tooltip>
        )}
        <CardContent className={classes.cardContent}>
          <UserTooltip user={member}>
            <ButtonBase className={classes.image}>
              <Image
                src={member.icon}
                alt={member.fullName}
                shape="circle"
                size="large"
              />
            </ButtonBase>
          </UserTooltip>
          <Text noWrap className={classes.name} subtitleBold>
            {member.publicName}
          </Text>
          {member.projects &&
            member.projects.length > 0 &&
            (isAdmin ? (
              <FormControl
                variant="outlined"
                size="small"
                aria-label="edit-role"
              >
                <Select
                  value={currentProject.role}
                  onChange={(e) => onChangeRole(member, e.target.value)}
                >
                  {USER_ROLE_LIST.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {t(role.label, { ns: 'menus' })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box
                aria-label="role"
                border={`solid 1px ${grey[400]}`}
                bgcolor={grey[200]}
                color={grey[700]}
                borderRadius="5px"
                p={`${0.25}em ${1}em`}
              >
                {member.projects[0].role}
              </Box>
            ))}
        </CardContent>
      </Card>
      <RemoveModal
        member={member}
        modal={modal}
        toggleModal={handleToggleModal}
        onConfirm={(e) => handleRemove(e)}
      />
    </>
  );
};

MemberCard.propTypes = {
  member: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  onChangeRole: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default MemberCard;
