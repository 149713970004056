import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import NoContent from '../../sections/no-content/NoContent';

const Table = ({ headers, rows, small, emptyText }) => {
  return isEmpty(rows) ? (
    <NoContent title={emptyText} />
  ) : (
    <TableContainer>
      <MuiTable size={small ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell key={i} align={i + 1 > 1 ? 'center' : 'left'}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i}>
              {row.map((item, j) => (
                <TableCell key={j} align={j + 1 > 1 ? 'center' : 'left'}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

Table.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  small: PropTypes.bool,
  emptyText: PropTypes.string.isRequired,
};

export default Table;
