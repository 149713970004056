export const SOLR_VERSION = [
  '9.6.1',
  '9.5.0',
  '9.4.1',
  '9.3.0',
  '9.2.1',
  '9.1.1',
  '8.11.3',
];
export const DEFAULT_COMMUNITY_SOLR_VERSION = '9.6.1';
export const FILE_SIZE = { BYTES: 3145728, MEGABYTES: 3 };
export const SUPPORTED_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];
