import { Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { INSTANCE_STATE } from '../../../apis/constants';

const useStyle = makeStyles((theme) => ({
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  animation: {
    animationName: '$blinker',
    animationDuration: '1.5s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  led: {
    paddingLeft: theme.spacing(1),
    fontSize: '1rem',
  },
}));

// Component
const Status = ({ instanceState, solrRunning }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const paletteType = theme.palette.type;

  const classes = useStyle();

  const getState = (instanceState, solrRunning) => {
    if (instanceState === INSTANCE_STATE.PENDING) {
      return {
        color: theme.palette.info[paletteType],
        blinking: true,
        message: t('instancePending', { ns: 'tooltips' }),
      };
    } else if (instanceState === INSTANCE_STATE.FAILED) {
      return {
        color: theme.palette.error[paletteType],
        blinking: false,
        message: t('instanceFailed', { ns: 'tooltips' }),
      };
    } else if (instanceState === INSTANCE_STATE.TERMINATING) {
      return {
        color: theme.palette.warning[paletteType],
        blinking: false,
        message: t('instanceTerminating', { ns: 'tooltips' }),
      };
    }

    if (solrRunning) {
      return {
        color: theme.palette.success[paletteType],
        blinking: instanceState === INSTANCE_STATE.PENDING,
        message: t('instanceRunning', { ns: 'tooltips' }),
      };
    } else {
      return {
        color: '#777',
        blinking: instanceState === INSTANCE_STATE.PENDING,
        message: t('instanceStopped', { ns: 'tooltips' }),
      };
    }
  };

  const { color, blinking, message } = getState(instanceState, solrRunning);

  return (
    <Tooltip title={message} placement="top">
      <FiberManualRecordIcon
        data-testid="status"
        className={`${classes.led} ${blinking && classes.animation}`}
        style={{ color: color }}
      />
    </Tooltip>
  );
};

Status.propTypes = {
  instanceState: PropTypes.string.isRequired,
  solrRunning: PropTypes.bool,
};

export default Status;
