import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';

const SuggestForm = ({ collections, control, errors }) => {
  const { t } = useTranslation();
  const { width, ref } = useResizeDetector();

  const useStyles = makeStyles(() => ({
    inputContainer: {
      display: 'flex',
      margin: -8,
      flexWrap: 'wrap',
    },
    input: {
      maxWidth: width > 600 ? '50%' : '100%',
      flexBasis: width > 600 ? '50%' : '100%',
      padding: 8,
      boxSizing: 'border-box',
    },
    inputFull: {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: 8,
      boxSizing: 'border-box',
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Box className={classes.inputContainer} ref={ref}>
        <Box className={classes.input}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            error={!!errors?.settings?.suggest?.collection}
          >
            <InputLabel>{t('search.collection', { ns: 'fields' })}</InputLabel>
            <Controller
              name="settings.suggest.collection"
              control={control}
              render={({ field }) => (
                <Select
                  label={t('search.collection', { ns: 'fields' })}
                  inputProps={{ 'aria-label': 'collection' }}
                  {...field}
                >
                  {collections.map((collection) => (
                    <MenuItem key={collection} value={collection}>
                      {collection}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {errors?.settings?.suggest?.collection?.message}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box className={classes.input}>
          <Controller
            name="settings.suggest.requestHandler"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t('search.requestHandler', { ns: 'fields' })}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ 'aria-label': 'suggest request handler' }}
                error={!!errors?.settings?.suggest?.RequestHandler}
                helperText={errors?.settings?.suggest?.RequestHandler?.message}
              />
            )}
          />
        </Box>
        <Box className={classes.input}>
          <Controller
            name="settings.suggest.dictionary"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t('search.dictionary', { ns: 'fields' })}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ 'aria-label': 'suggest dictionary' }}
                error={!!errors?.settings?.suggest?.dictionary}
                helperText={errors?.setting?.suggest?.dictionary?.message}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};

SuggestForm.propTypes = {
  collections: PropTypes.array,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default SuggestForm;
