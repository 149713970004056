import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import * as FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { collectionApis } from '../../../apis/collectionApis';
import { isInstanceRunning } from '../../../apis/constants';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import { synonymApis } from '../../../apis/synonymApis';
import Loading from '../../../components/elements/loading/Loading';
import { MIME_TYPE } from '../../../components/page-sections/file-browser/constants';
import CreateDictionary from '../../../components/page-sections/synonym/CreateDictionary';
import SynonymDictionaryTable from '../../../components/page-sections/synonym/SynonymDictionaryTable';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { SYNONYM_FILTERS } from '../../../constants';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import {
  getFileFormData,
  isSolrCloud,
  mapBlobFormData,
  sentenceCase,
} from '../../../utils/utils';

const ProjectSynonymDictionariesPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { showToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [synonymDictionaries, setSynonymDictionaries] = useState(null);

  const fetchDictionaries = async () => {
    const { data: dictionaries } = await synonymApis.getSynonymDictionaries(
      projectId
    );
    setSynonymDictionaries(dictionaries.content);
  };

  const getInstances = async () => {
    const { data } = await solrApis.getInstances(projectId);
    return data.content.filter((instance) => isInstanceRunning(instance));
  };

  const getCollections = async (instance) => {
    if (!instance) return;

    if (isSolrCloud(instance.type)) {
      const { data } = await collectionApis.getSolrCloudCollections(
        projectId,
        instance.id
      );

      return data.collections;
    } else {
      const { data } = await collectionApis.getCollections(
        projectId,
        instance.id
      );
      return Object.keys(data.status);
    }
  };

  const getResources = async (instanceId, collection) => {
    const { data } = await synonymApis.getManagedResources(
      projectId,
      instanceId,
      collection
    );
    return data;
  };

  const getInitArgs = async (instanceId, collection, resource) => {
    const { data } = await synonymApis.getInitArgs(
      projectId,
      instanceId,
      collection,
      resource
    );
    return data;
  };

  const handleCreateDictionary = async (data) => {
    try {
      const { data: createdDictionary } =
        await synonymApis.createSynonymDictionary(projectId, data);

      // Trying to import file
      if (data.file) {
        try {
          const format = data.file.type === MIME_TYPE.json ? 'json' : 'csv';
          const formData = getFileFormData('dictionary', data.file);
          await synonymApis.importSynonymDictionary(
            projectId,
            createdDictionary.id,
            formData,
            { format, clear: false }
          );
        } catch (error) {
          // clean database
          await synonymApis.deleteSynonymDictionary(
            projectId,
            createdDictionary.id
          );
          throw error;
        }
      }

      await fetchDictionaries();

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('createSuccess', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        showToast(TOAST_TYPE.ERROR, t('timeoutError', { ns: 'notifications' }));
      } else {
        showToast(
          TOAST_TYPE.ERROR,
          sentenceCase(
            t('createError', {
              ns: 'notifications',
              text: t('synonym.dictionary', { ns: 'project' }),
            })
          )
        );
      }
    }
  };

  const handleRename = async (id, data) => {
    try {
      await synonymApis.updateSynonymDictionary(projectId, id, data);
      await fetchDictionaries();
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    }
  };

  const handleImport = async (id, file, options) => {
    try {
      const format = file.type === MIME_TYPE.json ? 'json' : 'csv';
      const formData = getFileFormData('dictionary', file);
      const dictionaryText = t('synonym.dictionary', { ns: 'project' });
      showToast(
        TOAST_TYPE.INFO,
        sentenceCase(
          t('importStart', { ns: 'notifications', text: dictionaryText })
        )
      );
      await synonymApis.importSynonymDictionary(projectId, id, formData, {
        format,
        ...options,
      });
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('importSuccess', { ns: 'notifications', text: dictionaryText })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('importError', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    }
  };

  const handleExport = async (id, data) => {
    const { comments, fileType } = data;
    const format = fileType.toLowerCase();
    try {
      showToast(
        TOAST_TYPE.INFO,
        t('prepareForDownload', { ns: 'notifications' })
      );
      const response = await synonymApis.exportSynonymDictionary(
        projectId,
        id,
        { format, comments }
      );
      const blob = new Blob([response.data], {
        type: MIME_TYPE[format],
      });
      FileSaver.saveAs(blob, data.fileName);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('exportSuccess', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        showToast(TOAST_TYPE.ERROR, t('timeoutError', { ns: 'notifications' }));
      } else {
        showToast(
          TOAST_TYPE.ERROR,
          t('exportError', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        );
      }
    }
  };

  const handleDeploy = async (dictionaryId, data) => {
    try {
      const { initArgs, filter, ...requestParams } = data;
      const managed = filter === SYNONYM_FILTERS.MANAGED_SYNONYM_GRAPH_FILTER;

      if (filter === SYNONYM_FILTERS.MANAGED_SYNONYM_GRAPH_FILTER) {
        const requestBody = mapBlobFormData(initArgs, 'initArgs');
        await synonymApis.deploySynonymDictionary(
          projectId,
          dictionaryId,
          requestBody,
          { ...requestParams, managed }
        );
      }

      if (filter === SYNONYM_FILTERS.SYNONYM_GRAPH_FILTER) {
        await synonymApis.deploySynonymDictionary(
          projectId,
          dictionaryId,
          null,
          { ...requestParams, managed }
        );
      }

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deploySuccess', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        showToast(TOAST_TYPE.ERROR, t('timeoutError', { ns: 'notifications' }));
      } else {
        showToast(
          TOAST_TYPE.ERROR,
          t('deployError', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        );
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await synonymApis.deleteSynonymDictionary(projectId, id);
      await fetchDictionaries();
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('synonym.dictionary', { ns: 'project' }),
          })
        )
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data: project } = await projectApis.getProject(projectId);
        await fetchDictionaries();

        setProject(project);
        setIsLoading(false);
      } catch (error) {
        handleErrors(history, error, showToast);
        setIsLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [projectId]);

  const content =
    project && synonymDictionaries ? (
      <>
        <Breadcrumbs projectName={project.name} />
        <TitlePanel visible title={t('synonymDict', { ns: 'titles' })}>
          <CreateDictionary handleCreateDictionary={handleCreateDictionary} />
        </TitlePanel>
        <SynonymDictionaryTable
          list={synonymDictionaries}
          handleRename={handleRename}
          handleImport={handleImport}
          handleExport={handleExport}
          handleDeploy={handleDeploy}
          handleDelete={handleDelete}
          getInstances={getInstances}
          getCollections={getCollections}
          getResources={getResources}
          getInitArgs={getInitArgs}
        />
      </>
    ) : (
      <Box mt={2}>
        <Alert severity="error">
          {sentenceCase(
            t('loading', {
              ns: 'errors',
              content: t('synonym.dictionary', { ns: 'project' }),
            })
          )}
        </Alert>
      </Box>
    );

  return (
    <Container>
      {isLoading && <Loading />}
      {!isLoading && content}
    </Container>
  );
};

export default ProjectSynonymDictionariesPage;
