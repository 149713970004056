import { entries, has, reduce } from 'lodash';

export const LANDING_PAGE = '/';

export const PUBLIC_ROUTE = {
  DOCUMENTATION: process.env.REACT_APP_KANDASEARCH_HOME_PAGE + '/doc',
  EN_DOCUMENTATION: process.env.REACT_APP_KANDASEARCH_HOME_PAGE + '/en/doc',
  FORUM: process.env.REACT_APP_KANDASEARCH_HOME_PAGE + '/forum',
  EN_FORUM: process.env.REACT_APP_KANDASEARCH_HOME_PAGE + '/en/forum',
};

export const PRIVATE_ROUTE = {
  ADMIN_IMAGE_ASSETS: '/admin/images',
  USER_PROFILE: '/profile',
  USER_ACCOUNT: '/account',
  PROJECT_NEW: '/projects/new',
  PROJECTS: '/projects',
  PROJECT_OVERVIEW: '/:projectId',
  PROJECT_MEMBER: '/:projectId/members',
  PROJECT_MEMBER_INVITATION: '/projects/:projectId/invites/:inviteId',
  PROJECT_BILLING: '/:projectId/billing',
  PROJECT_BACKUP: '/:projectId/backup',
  PROJECT_RESTORE: '/:projectId/restore',
  PROJECT_SUBS_EXTENSIONS: '/:projectId/subscribed-extensions',
  PROJECT_SYNONYM_DICTIONARIES: '/:projectId/synonym-dictionaries',
  PROJECT_SYNONYM_DICTIONARY: '/:projectId/synonym-dictionaries/:dictionaryId',
  PROJECT_CRAWLER: '/:projectId/crawler',
  PROJECT_CRAWLER_DETAILS: '/:projectId/crawler/:crawlerJobId',
  PROJECT_CRAWLER_JOB_STATUS: '/:projectId/crawler/:crawlerJobId/status',
  PROJECT_SETTINGS: '/:projectId/settings',
  INSTANCE_NEW: '/:projectId/new-instance',
  PROJECT_INSTANCE_LIST: '/:projectId/instance',
  INSTANCE_OVERVIEW: '/:projectId/instance/:instanceId',
  INSTANCE_COLLECTIONS: '/:projectId/instance/:instanceId/collections',
  INSTANCE_CONFIG: '/:projectId/instance/:instanceId/config',
  INSTANCE_FILE_MANAGER: '/:projectId/instance/:instanceId/file-manager',
  INSTANCE_FILE_EDITOR: '/:projectId/instance/:instanceId/file-editor',
  INSTANCE_HEALTH_CHECK: '/:projectId/instance/:instanceId/health-check',
  INSTANCE_SETTINGS: '/:projectId/instance/:instanceId/settings',
  INSTANCE_SEARCH: '/:projectId/instance/:instanceId/search',
  INSTANCE_MONITORING: '/:projectId/instance/:instanceId/monitoring',
  EXTENSIONS: '/extensions',
  EXTENSION_DETAILS: '/extensions/:extensionId',
  PROJECT_GPS_LIST: '/:projectId/gps',
  GPS_OVERVIEW: '/:projectId/gps/:gpsId',
  GPS_JOBS: '/:projectId/gps/:gpsId/jobs',
  GPS_JOBS_DETAILS: '/:projectId/gps/:gpsId/jobs/:jobId',
  GPS_FILE_EDITOR: '/:projectId/gps/:gpsId/file-editor',
  GPS_FILE_MANAGER: '/:projectId/gps/:gpsId/file-manager',
  GPS_SETTINGS: '/:projectId/gps/:gpsId/settings',
  PROJECT_MODEL_LIST: '/:projectId/model',
  MODEL_OVERVIEW: '/:projectId/model/:modelId',
  MODEL_SETTINGS: '/:projectId/model/:modelId/settings',
  ERROR403: '/error/forbidden',
  ERROR404: '/error/not-found',
  ERROR500: '/error/server-error',
};

export const buildPath = (args) => {
  if (!has(args, 'params')) return args.path;

  return reduce(
    entries(args.params),
    (result, [prams, value]) => result.replace(`:${prams}`, '' + value),
    args.path
  );
};
