import { TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BACKUP_TYPES } from '../../../../constants';
import { isSolrCloud } from '../../../../utils/utils';
import TextChip from './TextChip';

const InstanceCell = ({
  instance,
  collection,
  node,
  solrType,
  category,
  solrVersion,
}) => {
  const { t } = useTranslation();
  const showNode = isSolrCloud(solrType) && category === BACKUP_TYPES.FILE;
  return (
    <TableCell>
      <TextChip title={t('instance', { ns: 'instance' })} text={instance} />
      <TextChip title={t('collection', { ns: 'instance' })} text={collection} />
      {showNode && (
        <TextChip title={t('nodeNumber', { ns: 'instance' })} text={node} />
      )}
      <TextChip
        title={t('backup.version', { ns: 'project' })}
        text={solrVersion}
      />
    </TableCell>
  );
};

InstanceCell.propTypes = {
  instance: PropTypes.string,
  collection: PropTypes.string,
  node: PropTypes.string,
  solrType: PropTypes.string,
  category: PropTypes.string,
  solrVersion: PropTypes.string,
};

export default InstanceCell;
