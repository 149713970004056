import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import { TabPanel, TabContext, TabList, Autocomplete } from '@material-ui/lab';
import DOMPurify from 'dompurify';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';

import { useSearch } from '../../../contexts/SearchContext';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import AdditionalParamsFormSection from './form-sections/AdditionalParamsFormSection';
import CheckboxFormSection from './form-sections/CheckboxFormSection';
import FacetParamsFormSection from './form-sections/FacetParamsFormSection';
import HighlightParamsFormSection from './form-sections/HighlightParamsFormSection';
import QueryInformationFormSection from './form-sections/QueryInformationFormSection';
import SearchParamsFormSection from './form-sections/SearchParamsFormSection';
import SemanticSearchFormSection from './form-sections/SemanticSearchFormSection';
import { defaultValues, schema } from './formValidation';
import LoadQueryModal from './modals/LoadQueryModal';
import SaveQueryModal from './modals/SaveQueryModal';
import QueryButtons from './QueryButtons';
import {
  formatForWebApp,
  cleanAndAssignQueryData,
  convertFormToQuery,
  convertToForm,
} from './searchFormUtils';
import SuggestForm from './SuggestForm';

const SearchForm = ({
  onCollectionChange,
  queryFromUrl,
  collections,
  onSaveQuery,
  onDeleteQuery,
  getQueryList,
  onQuerySuggest,
  onDownload,
  baseUrl,
}) => {
  const { t } = useTranslation();
  const {
    executeSearch,
    filtersInForm,
    resetFilters,
    updateFilters,
    clearQuery,
  } = useSearch();
  const { width, ref } = useResizeDetector();
  const { showToast } = useToast();

  const useStyles = makeStyles(() => ({
    buttonContainer: {
      display: 'flex',
      flexDirection: width > 550 ? 'row' : 'column',
      justifyContent: 'space-between',
      marginTop: '1em',
    },
    notLastButton: {
      marginRight: '1em',
      width: width <= 550 && '100%',
      marginBottom: width > 550 ? 0 : '0.5em',
    },
    lastButton: {
      marginBottom: width > 550 ? 0 : '0.5em',
      width: width <= 550 && '100%',
    },
    clearButton: {
      width: width <= 550 && '100%',
    },
    inputContainer: {
      display: 'flex',
      margin: -8,
      flexWrap: 'wrap',
    },
    input: {
      maxWidth: width > 600 ? '50%' : '100%',
      flexBasis: width > 600 ? '50%' : '100%',
      padding: 8,
      boxSizing: 'border-box',
    },
    inputFull: {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: 8,
      boxSizing: 'border-box',
    },
  }));

  const classes = useStyles();

  const [tab, setTab] = useState('0');
  const [parser, setParser] = useState('lucene');
  const [facet, setFacet] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');
  const [fqChips, setFqChips] = useState([]);
  const [facetQuery, setFacetQuery] = useState('');
  const [facetQueryChips, setFacetQueryChips] = useState([]);
  const [suggest, setSuggest] = useState([]);
  const [loadModal, setLoadModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);

  useEffect(() => {
    if (queryFromUrl) {
      executeSearch(queryFromUrl.settings.query);
      convertQueryToForm(queryFromUrl);
    }
    // eslint-disable-next-line
  }, []);

  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema()),
  });

  const collection = watch('settings.query.collection');
  const dense = watch('settings.query.jsonQuery.params.dense');

  const handleTabChange = (e, value) => setTab(value);
  const handleLoadModalToggle = () => setLoadModal(!loadModal);
  const handleSaveModalToggle = async () => {
    const canToggle = await trigger();
    canToggle && setSaveModal(!saveModal);
  };

  const convertQueryToForm = (query) => {
    const params = query.settings.query.jsonQuery.params;

    setFqChips(query.settings.query.jsonQuery.filter);
    setFacetQueryChips(params['facet.query']);
    setFilterQuery('');
    setFacetQuery('');
    setHighlight(params.hl);
    setFacet(params.facet);
    setParser(params.defType);

    // This reverts the changes made to the form object that are done in the convertFormToQuery function above.
    // This is so it can be easily merged into the form object passed to react-hook-forms
    convertToForm(query);

    setValue('settings', query.settings);
  };

  const handleSaveQuery = (name) => {
    const cleanForm = convertFormToQuery(getValues(), fqChips, facetQueryChips);

    cleanForm.name = name.queryName;
    cleanForm.collection = cleanForm.settings.query.collection;

    onSaveQuery(cleanAndAssignQueryData(cleanForm));
    handleSaveModalToggle();
  };

  const handleLoadQuery = async (query) => {
    clearErrors();
    reset();
    await clearQuery();
    await resetFilters();
    executeSearch(query.settings.query);
    convertQueryToForm(query);
  };

  const handleDeleteQuery = (queryId) => onDeleteQuery(queryId);
  const handleParserChange = (event) => setParser(event.target.value);
  const handleFacetCheck = (event) => setFacet(event.target.checked);
  const handleHighlightCheck = (event) => setHighlight(event.target.checked);
  const fqChange = (e) => setFilterQuery(e.target.value);
  const facetQueryChange = (e) => setFacetQuery(e.target.value);
  const handleAddChip = (field) => {
    if (field === 'fq') {
      if (!filterQuery || includes(fqChips, filterQuery)) return;
      updateFilters(filterQuery);
      setFilterQuery('');
    } else {
      if (!facetQuery || includes(facetQueryChips, facetQuery)) return;
      const currentChips = facetQueryChips || [];

      setFacetQueryChips([...currentChips, facetQuery]);
      setFacetQuery('');
    }
  };
  const handleDeleteChip = (field, chipToDelete) => {
    if (field === 'fq') {
      updateFilters(chipToDelete);
    } else {
      const removedChipList = facetQueryChips.filter(
        (chip) => chip !== chipToDelete
      );
      setFacetQueryChips(removedChipList);
    }
  };

  const onSubmit = (data) => {
    const cleanedForm = {
      ...convertFormToQuery(data, fqChips, facetQueryChips),
    };
    // clean empty df field or else Solr will return a 400 error
    const cleanedQuery = cleanAndAssignQueryData(cleanedForm).settings.query;
    executeSearch(cleanedQuery);
  };

  const handleResetForm = async () => {
    reset();
    await resetFilters();

    setValue('settings.query.jsonQuery.params.defType', 'lucene');
    setValue('settings.query.collection', '');
    setValue('settings.query.jsonQuery.params.op', 'OR');
    setParser('lucene');
    setHighlight(false);
    setFacet(false);
    setFilterQuery('');
    setFacetQuery('');
    setFacetQueryChips([]);
  };

  const transformSuggestOptions = (query, suggestList) => {
    const suggestOptions = suggestList.map((dictionaryResult) => {
      return dictionaryResult[1][query].suggestions.map((suggestion) => {
        return {
          dictionary: dictionaryResult[0],
          label: suggestion.term.replaceAll(/<\/?b>/g, ''),
          ...suggestion,
        };
      });
    });

    return suggestOptions;
  };

  const handleQuerySuggest = async (query) => {
    const suggestForm = getValues([
      'settings.suggest.collection',
      'settings.suggest.requestHandler',
      'settings.suggest.dictionary',
    ]);

    if (!suggestForm[0] || !suggestForm[1] || !suggestForm[2]) return;

    const dictionaryMap = suggestForm[2].split(/\s+|,\s+|,/g);
    const suggestRes = await onQuerySuggest(
      query,
      suggestForm[0],
      suggestForm[1],
      dictionaryMap
    );

    if (!suggestRes) return;

    const suggestList = suggestRes.solrResponse.suggest;
    const formatSuggestions = transformSuggestOptions(
      query,
      Object.entries(suggestList)
    );

    setSuggest(formatSuggestions.flat());
  };

  useEffect(() => {
    setFqChips(filtersInForm);
  }, [filtersInForm]);

  const handleDownloadWebApp = async () => {
    if (!baseUrl) {
      showToast(
        TOAST_TYPE.ERROR,
        t('webAppDomainError', { ns: 'notifications' })
      );
      return;
    }

    const form = getValues();
    const formattedForm = formatForWebApp(
      baseUrl,
      form,
      fqChips,
      facetQueryChips
    );

    const { collection, idField, qt } = formattedForm;

    if (collection && idField && qt) {
      await onDownload(formattedForm);
    } else {
      showToast(
        TOAST_TYPE.ERROR,
        t('webAppFormError', { ns: 'notifications' })
      );
    }
  };

  const handleCollectionChange = async () => {
    const uniqueKey = await onCollectionChange(collection);
    setValue('settings.query.uniqueKey', uniqueKey, { shouldValidate: true });
  };

  useEffect(() => {
    if (!collection) return;
    handleCollectionChange();
    // eslint-disable-next-line
  }, [collection]);

  return (
    <Container>
      <QueryButtons
        onSaveModalToggle={handleSaveModalToggle}
        onLoadModalToggle={handleLoadModalToggle}
        onDownload={handleDownloadWebApp}
        onReset={handleResetForm}
        style={classes}
      />

      <form onSubmit={handleSubmit(onSubmit)} aria-label="search-form">
        <TabContext value={tab}>
          <TabList
            variant="fullWidth"
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Query" value="0" />
            <Tab label="Suggest" value="1" />
          </TabList>

          <TabPanel value="0" ref={ref}>
            <Box display="flex" alignItems="start">
              <Controller
                name="settings.query.jsonQuery.query"
                control={control}
                defaultValue={''}
                render={({ field: { onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    options={suggest}
                    getOptionLabel={(option) => option?.label || option || ''}
                    groupBy={(option) => option.dictionary}
                    renderOption={(option) => {
                      const term = {
                        __html: DOMPurify.sanitize(option.term, {
                          ALLOWED_TAGS: ['b'],
                        }),
                      };
                      return <div dangerouslySetInnerHTML={term} />;
                    }}
                    onInputChange={(e, val, reason) => {
                      handleQuerySuggest(val);
                      // this captures the user-input value and sets it to the rhf controller "value"
                      if (reason === 'input') {
                        onChange(val);
                      }
                    }}
                    // this captures the suggested term from solr only when the user selects it from the dropdown.
                    onChange={(e, data) => onChange(data?.label || '')}
                    fullWidth
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        error={!!errors?.settings?.query?.jsonQuery?.query}
                        helperText={
                          errors?.settings?.query?.jsonQuery?.query?.message
                        }
                        inputProps={{
                          ...params.inputProps,
                          'aria-label': 'query',
                        }}
                      />
                    )}
                  />
                )}
              />
              <Button
                style={{ marginLeft: '1em', height: 40 }}
                type="submit"
                variant="contained"
                color="primary"
                aria-label="search"
              >
                {t('search', { ns: 'buttons' })}
              </Button>
            </Box>

            <QueryInformationFormSection
              control={control}
              errors={errors}
              collections={collections}
              style={classes}
            />

            <SemanticSearchFormSection
              control={control}
              errors={errors}
              style={classes}
              dense={dense}
            />

            <SearchParamsFormSection
              control={control}
              errors={errors}
              filterQuery={filterQuery}
              fqChange={fqChange}
              fqChips={fqChips}
              onAddChip={handleAddChip}
              onDeleteChip={handleDeleteChip}
              onParserChange={handleParserChange}
              style={classes}
            />

            {parser !== 'lucene' && (
              <AdditionalParamsFormSection
                control={control}
                errors={errors}
                parser={parser}
                style={classes}
              />
            )}

            <CheckboxFormSection
              parser={parser}
              control={control}
              onHighlightCheck={handleHighlightCheck}
              onFacetCheck={handleFacetCheck}
            />

            {highlight && (
              <HighlightParamsFormSection
                control={control}
                errors={errors}
                style={classes}
              />
            )}

            {facet && (
              <FacetParamsFormSection
                control={control}
                errors={errors}
                facetQuery={facetQuery}
                facetQueryChips={facetQueryChips}
                facetQueryChange={facetQueryChange}
                onAddChip={handleAddChip}
                onDeleteChip={handleDeleteChip}
                style={classes}
              />
            )}
          </TabPanel>

          <TabPanel value="1">
            <SuggestForm
              control={control}
              errors={errors}
              collections={collections}
              style={classes}
            />
          </TabPanel>
        </TabContext>
      </form>

      <SaveQueryModal
        open={saveModal}
        onSaveQuery={handleSaveQuery}
        onToggle={handleSaveModalToggle}
      />

      <LoadQueryModal
        open={loadModal}
        getQueryList={getQueryList}
        onLoadQuery={handleLoadQuery}
        onDeleteQuery={handleDeleteQuery}
        onToggle={handleLoadModalToggle}
      />
    </Container>
  );
};

SearchForm.propTypes = {
  baseUrl: PropTypes.string,
  collections: PropTypes.array.isRequired,
  getQueryList: PropTypes.func.isRequired,
  onCollectionChange: PropTypes.func.isRequired,
  onDeleteQuery: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onQuerySuggest: PropTypes.func.isRequired,
  onSaveQuery: PropTypes.func.isRequired,
  queryFromUrl: PropTypes.object,
};

export default SearchForm;
