import { useState } from 'react';

import { Box, Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import GetAppIcon from '@material-ui/icons/GetApp';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isDownloadToInstance } from '../../../constants/extensionConstants';
import { isLast } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import AddForm from './AddForm';

const VersionList = ({
  extensionType,
  versions,
  handleDownload,
  ...restProps
}) => {
  const { t } = useTranslation();

  const [selectedVersion, setSelectedVersion] = useState(null);

  const selectVersion = (version) => setSelectedVersion(version);
  const clearSelectedVersion = () => setSelectedVersion(null);

  const versionList = versions.map((ver, i) => (
    <div key={ver.id}>
      <ListItem dense aria-label="version item">
        <ListItemText
          primary={
            <>
              <Text bodyBold>{ver.version}</Text>
              {!isEmpty(ver.supportedSolrVersions) && (
                <Text captionUpperCase>
                  {`${t('ext.supportedSolrVersions', {
                    ns: 'project',
                  })}: ${ver.supportedSolrVersions.join(', ')}`}
                </Text>
              )}
            </>
          }
          secondary={
            <Text
              caption
              color="textSecondary"
              aria-label="version description"
            >
              {ver.description || ''}
            </Text>
          }
        />
        <ListItemSecondaryAction>
          {isDownloadToInstance(extensionType) ? (
            <IconButton
              edge="end"
              aria-label="download to instance"
              title={t('downloadToInstance', { ns: 'buttons' })}
              onClick={() => selectVersion(ver)}
            >
              <CloudDownloadIcon />
            </IconButton>
          ) : (
            <IconButton
              edge="end"
              aria-label="download to local"
              title={t('download', { ns: 'buttons' })}
              onClick={() => handleDownload(ver)}
            >
              <GetAppIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      {!isLast(versions, i) && <Divider />}
    </div>
  ));

  const selectedVersionView = (
    <>
      <Box my={1}>
        <Text component="span" style={{ marginRight: 10 }}>
          {t('ext.selectedVersion', {
            ns: 'project',
            version: selectedVersion?.version,
          })}
        </Text>
        <Button
          variant="outlined"
          color="light"
          size="small"
          onClick={clearSelectedVersion}
          aria-label="clear version"
        >
          {t('clear', { ns: 'buttons' })}
        </Button>
      </Box>
      <Box>
        <AddForm
          extensionType={extensionType}
          version={selectedVersion}
          {...restProps}
        />
      </Box>
    </>
  );

  return (
    <>
      <List style={{ marginTop: 10 }} aria-label="version list">
        <Text bodyBold>{t('ext.versions', { ns: 'project' })}</Text>
        {!selectedVersion ? versionList : selectedVersionView}
      </List>
    </>
  );
};

VersionList.propTypes = {
  extensionType: PropTypes.string,
  versions: PropTypes.array,
  handleDownload: PropTypes.func,
};

export default VersionList;
