import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { USER_ROLE_LIST } from '../../../constants';
import { sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';

const MemberInviteForm = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();

  const [role, setRole] = useState('');

  const handleChange = (e) => setRole(e.target.value);
  const submitForm = (data) => onSubmit(data);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('email', { ns: 'fields' }),
          })
        )
      )
      .email(
        sentenceCase(
          t('invalidFormat', {
            ns: 'validations',
            field: t('email', { ns: 'fields' }),
          })
        )
      ),
    role: yup
      .string()
      .oneOf(['ADMIN', 'USER'])
      .required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('memberRole', { ns: 'fields' }),
          })
        )
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <TextField
        {...register('email')}
        label={t('email', { ns: 'fields' })}
        fullWidth
        variant="outlined"
        size="small"
        error={!!errors?.email}
        helperText={errors?.email?.message}
        inputProps={{ 'aria-label': 'email' }}
        style={{ marginBottom: '1em' }}
      />

      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        error={!!errors?.role}
      >
        <InputLabel id="role">{t('memberRole', { ns: 'fields' })}</InputLabel>
        <Select
          {...register('role')}
          aria-label="role"
          label={t('memberRole', { ns: 'fields' })}
          value={role}
          onChange={handleChange}
          style={{ overflow: 'hidden' }}
        >
          {USER_ROLE_LIST.map((role) => (
            <MenuItem key={role.value} value={role.value}>
              {t(role.label, { ns: 'menus' })}
            </MenuItem>
          ))}
        </Select>
        {errors?.role && (
          <FormHelperText>{errors?.role?.message}</FormHelperText>
        )}
      </FormControl>

      <Box
        display="flex"
        justifyContent="flex-end"
        mt={2}
        mb={1}
        style={{ gap: '1em' }}
      >
        <Button color="default" variant="text" onClick={onClose}>
          {t('cancel', { ns: 'buttons' })}
        </Button>
        <Button type="submit" variant="text">
          {t('sendInvitation', { ns: 'buttons' })}
        </Button>
      </Box>
    </form>
  );
};

MemberInviteForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MemberInviteForm;
