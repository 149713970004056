import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatDateTimeUTC } from '../../../utils/timeUtils';
import Text from '../../elements/text/Text';
import UserAvatar from '../../sections/user-avatar/UserAvatar';

const useStyles = makeStyles((theme) => ({
  metadata: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(0.5),
  },
}));

const InstanceMetadata = ({ instance, timezone }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box>
      <Text style={{ marginBottom: '1em' }}>{instance.description}</Text>
      {instance.solrVersion && (
        <Text className={classes.metadata} caption>
          {`${t('solrVersion', { ns: 'fields' })}: ${instance.solrVersion}`}
        </Text>
      )}
      <Box display="flex" alignItems="center">
        <Text className={classes.metadata} caption>
          {t('createdAt', {
            ns: 'instance',
            date: formatDateTimeUTC(instance.createDate, timezone),
          })}
        </Text>
        <UserAvatar user={instance.author} />
      </Box>
    </Box>
  );
};

InstanceMetadata.propTypes = {
  instance: PropTypes.object,
  timezone: PropTypes.string,
};

export default InstanceMetadata;
