import i18next from 'i18next';
import { uniqueId } from 'lodash';

import { TOAST_TYPE } from './hooks/useToast';
import { PRIVATE_ROUTE } from './routes/routes';

export const matchError = (error) => {
  const status = error?.response?.status || error.message;
  switch (status) {
    case 401:
      return {
        code: 401,
        message: i18next.t('unauthorized', { ns: 'errors' }),
      };
    case 403:
      return {
        code: 403,
        message: i18next.t('forbidden', { ns: 'errors' }),
        redirect: PRIVATE_ROUTE.ERROR403,
      };
    case 404:
      return {
        code: 404,
        message: i18next.t('notFound', { ns: 'errors' }),
        redirect: PRIVATE_ROUTE.ERROR404,
      };
    case 500:
    case 'Network Error':
      return {
        code: 500,
        message: i18next.t('internalServerError', { ns: 'errors' }),
        redirect: PRIVATE_ROUTE.ERROR500,
      };
    default:
      return {
        message: i18next.t('unknown', { ns: 'errors', status: status || '' }),
      };
  }
};

export const handleErrors = (history, error, showToast) => {
  const { code, message, redirect } = matchError(error);
  code === 403 || code === 404 || code === 500
    ? history.replace(redirect)
    : showToast(TOAST_TYPE.ERROR, message);
};

export const getFileExtName = (name) => {
  if (!(name.split('.').length > 1)) return '';
  return name.split('.').pop();
};

export const mapToFileBrowser = (files, seq) => {
  if (!files) return;
  return files
    .filter((file) => !/^\.+$/.test(file.filename))
    .map((file) => ({
      id: uniqueId(`file_${seq}_`),
      name: file.filename,
      isDir: file.attrs?.dir,
      size: file.attrs?.size,
      ext: getFileExtName(file.filename),
      modDate: new Date(file.attrs?.mtime * 1000),
    }));
};
