import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { extensionApis } from '../../../apis/extensionApis';
import Title from '../../../components/elements/title/Title';
import ExtensionList from '../../../components/page-sections/extension-list/ExtensionList';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';

const ExtensionLibraryPage = () => {
  const { t } = useTranslation();

  const getExtensions = async (filters, sort) => {
    const { data } = !isEmpty(filters)
      ? await extensionApis.getExtensions(filters.join(','), sort)
      : await extensionApis.getExtensions(undefined, sort);

    const extensions = data.content;
    return extensions;
  };

  return (
    <Container>
      <Breadcrumbs />
      <Box mt={2}>
        <Title text={t('extensionLibrary', { ns: 'titles' })} gutterBottom />
        <ExtensionList getExtensions={getExtensions} isLibrary={true} />
      </Box>
    </Container>
  );
};

export default ExtensionLibraryPage;
