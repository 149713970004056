import { useMediaQuery } from '@material-ui/core';
import {
  pink,
  cyan,
  green,
  purple,
  blueGrey,
  orange,
} from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PropTypes from 'prop-types';

import { EXTENSION_TYPES } from '../../../constants';

const useStyles = makeStyles({
  buttonGroup: {
    display: 'flex',
    '& > button': {
      flexGrow: 1,
      '&:hover': {
        color: 'white',
        opacity: 0.5,
        textShadow: '1px 1px #333',
      },
      '&.Mui-selected': {
        color: 'white',
        '&:hover': {
          color: 'white',
          opacity: 0.5,
          textShadow: '1px 1px #333',
        },
      },
    },
    width: '100%',
    marginBottom: '0.5em',
  },
  data: {
    color: pink[500],
    '&:hover': {
      backgroundColor: pink[500],
    },
    '&.Mui-selected': {
      backgroundColor: pink[500],
      '&:hover': {
        backgroundColor: pink[500],
      },
    },
  },
  solrCollectionConfig: {
    color: cyan[700],
    '&:hover': {
      backgroundColor: cyan[700],
    },
    '&.Mui-selected': {
      backgroundColor: cyan[700],
      '&:hover': {
        backgroundColor: cyan[700],
      },
    },
  },
  solrModel: {
    color: orange[700],
    '&:hover': {
      backgroundColor: orange[700],
    },
    '&.Mui-selected': {
      backgroundColor: orange[700],
      '&:hover': {
        backgroundColor: orange[700],
      },
    },
  },
  solrDictionary: {
    color: green[500],
    '&:hover': {
      backgroundColor: green[500],
    },
    '&.Mui-selected': {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[500],
      },
    },
  },
  solrPlugin: {
    color: purple[500],
    '&:hover': {
      backgroundColor: purple[500],
    },
    '&.Mui-selected': {
      backgroundColor: purple[500],
      '&:hover': {
        backgroundColor: purple[500],
      },
    },
  },
  misc: {
    color: blueGrey[600],
    '&:hover': {
      backgroundColor: blueGrey[600],
    },
    '&.Mui-selected': {
      backgroundColor: blueGrey[600],
      '&:hover': {
        backgroundColor: blueGrey[600],
      },
    },
  },
});

const extensionTypes = [
  { name: EXTENSION_TYPES.DATA, style: 'data' },
  {
    name: EXTENSION_TYPES.SOLR_COLLECTION_CONFIG,
    style: 'solrCollectionConfig',
  },
  { name: EXTENSION_TYPES.SOLR_DICTIONARY, style: 'solrDictionary' },
  { name: EXTENSION_TYPES.SOLR_PLUGIN, style: 'solrPlugin' },
  {
    name: EXTENSION_TYPES.SOLR_MODEL,
    style: 'solrModel',
  },
  { name: EXTENSION_TYPES.MISC, style: 'misc' },
];

const ExtensionFilters = ({ filters, loading, onToggle }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:720px)');

  return (
    <>
      <ToggleButtonGroup
        size="small"
        orientation={isSmallScreen ? 'vertical' : 'horizontal'}
        onChange={(e, v) => onToggle(v)}
        value={filters}
        className={classes.buttonGroup}
      >
        {extensionTypes.map((extType) => (
          <ToggleButton
            key={extType.name}
            className={classes[extType.style]}
            value={extType.name}
            disabled={loading}
          >
            {extType.name.replace(/_/g, ' ')}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};

ExtensionFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

export default ExtensionFilters;
