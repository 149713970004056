import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import i18next from 'i18next';
import { find, isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { PAYMENT_TYPES } from '../../../constants';
import {
  CRAWLER_DAYS,
  CRAWLER_PERIOD_TYPES,
  CRAWLER_STATUS,
  CRAWLER_TYPES,
  DEFAULT_HANDLER_VALUE,
  JOB_TYPE,
} from '../../../constants/crawlerConstants';
import i18n from '../../../i18n';
import { currencyFormat } from '../../../utils/paymentUtils';
import { formatDateTimeUTC, nth } from '../../../utils/timeUtils';
import Text from '../../elements/text/Text';

export const projectSetupComplete = (project) => {
  const { managers, cards, billingInfo } = project;
  const isInvoicePayment =
    billingInfo?.[0]?.paymentType === PAYMENT_TYPES.INVOICE;

  const billingSetup =
    isInvoicePayment || (!isEmpty(cards) && !isEmpty(billingInfo));
  const projectSetup = billingSetup && !isEmpty(managers);

  return projectSetup;
};

const requestSchedule = (
  periodType,
  timezone,
  periodTime,
  periodDate,
  periodDay
) => {
  if (periodType === CRAWLER_PERIOD_TYPES.MANUAL.value) return;

  const scheduleRequest = {
    period: periodType,
    zone: timezone,
    time: periodTime,
  };

  if (periodType === CRAWLER_PERIOD_TYPES.MONTHLY.value) {
    scheduleRequest.day = parseInt(periodDate);
  }
  if (periodType === CRAWLER_PERIOD_TYPES.WEEKLY.value) {
    scheduleRequest.day = periodDay;
  }

  return scheduleRequest;
};

export const transformToRequest = (formData) => {
  const requestSeeds = formData.seeds.split('\n');
  const requestSubseeds = formData.subseeds.split('\n');

  const cleanedArgs = formData.crawlArgs.filter((argPair) => {
    return argPair.name !== '' && argPair.value !== '';
  });

  const transformedArgs = {};

  !isEmpty(cleanedArgs) &&
    cleanedArgs.forEach((argPair) => {
      transformedArgs[argPair.name] = argPair.val;
    });

  const transformedSchedule = requestSchedule(
    formData.periodType,
    formData.timezone,
    formData.periodTime,
    formData.date,
    formData.day
  );

  return {
    type: formData.jobType,
    name: formData.jobName,
    description: formData.jobDescription,
    solrInstanceSetId: formData.instance,
    collection: formData.collection,
    data: {
      updateHandler: formData.updateHandler,
      removeHandler: formData.removeHandler,
      statusHandler: formData.statusHandler,
      idField: formData.uniqueKey,
      commitOnComplete: formData.commitAfterJob,
      commitWithin: formData.commitTime,
      arguments: transformedArgs,
      email: formData.user,
      seeds: requestSeeds,
      subseeds: requestSubseeds,
      includes: formData.includeInCrawl,
      includesIndex: formData.includeInIndex,
      excludes: formData.excludeFromCrawl,
      excludesIndex: formData.excludeFromIndex,
      excludesContentIndex: formData.excludeContentFromIndex,
      schedule: transformedSchedule,
    },
  };
};

const formSchedule = (response) => {
  const { schedule } = response.data;
  if (!schedule) return;

  const formFields = {
    periodType: schedule.period,
    periodTime: moment(schedule.time, 'HH:mm:ss').format('HH:mm'),
    timezone: schedule.zone,
  };

  if (schedule.period === 'MONTHLY') {
    formFields.date = schedule.day;
  }

  if (schedule.period === 'WEEKLY') {
    formFields.day = schedule.day;
  }

  return formFields;
};

export const transformToForm = (response) => {
  const transformSeeds = response.data.seeds.join('\n');
  const transformSubseeds = response.data.subseeds.join('\n');

  const transformArgs = (rawArgData) => {
    if (!rawArgData) return [{ name: '', val: '' }];

    return Object.entries(rawArgData).map((args) => ({
      name: args[0],
      val: args[1],
    }));
  };

  return {
    id: response.id,
    instance: response.solrInstanceSet.id,
    jobType: response.type,
    jobName: response.name,
    jobDescription: response?.description,
    user: response.data.email,
    type: response.type,
    seeds: transformSeeds,
    subseeds: transformSubseeds,
    includeInCrawl: response.data.includes,
    includeInIndex: response.data.includesIndex,
    excludeFromCrawl: response.data?.excludes,
    excludeFromIndex: response.data?.excludesIndex,
    excludeContentFromIndex: response.data?.excludesContentIndex,
    collection: response.collection,
    uniqueKey: response.data.idField,
    updateHandler: response.data.updateHandler,
    removeHandler: response.data.removeHandler,
    statusHandler: response.data.statusHandler,
    commitTime: response.data?.commitWithin,
    commitAfterJob: response.data?.commitOnComplete,
    crawlArgs: transformArgs(response.data.arguments),
    periodType:
      response.data.schedule?.period || CRAWLER_PERIOD_TYPES.MANUAL.value,
    ...formSchedule(response),
  };
};

export const fieldDisplay = (label, value) => {
  return (
    value && (
      <Text bodyBold style={{ marginLeft: '1em' }}>
        {label}:{' '}
        <Text component="span" body>
          {value}
        </Text>
      </Text>
    )
  );
};

export const boxFieldDisplay = (label, value) => {
  return (
    value && (
      <>
        <Text bodyBold style={{ marginLeft: '1em' }}>
          {label}
        </Text>
        <Card
          variant="outlined"
          aria-label={label}
          style={{ marginLeft: '1em', marginBottom: '1em' }}
        >
          <Box p={1} height={'5em'} overflow="auto">
            <Text style={{ whiteSpace: 'pre-wrap' }}>{value}</Text>
          </Box>
        </Card>
      </>
    )
  );
};

export const tableDisplay = (label, value, key = null) => {
  return (
    <TableRow {...(key && { key: key })}>
      <TableCell>{label}</TableCell>
      <TableCell style={{ whiteSpace: 'pre-wrap' }}>{value}</TableCell>
    </TableRow>
  );
};

export const seedsDisplay = (seeds) =>
  seeds > 0
    ? i18n.t('stepper.seeds', { ns: 'crawler', count: seeds })
    : i18n.t('stepper.unlimited', { ns: 'crawler' });

export const scheduleDisplay = (maxSchedule) => {
  switch (maxSchedule) {
    case CRAWLER_PERIOD_TYPES.WEEKLY.value:
      return i18n.t('stepper.weekly', { ns: 'crawler' });
    case CRAWLER_PERIOD_TYPES.DAILY.value:
      return i18n.t('stepper.daily', { ns: 'crawler' });
    default:
      return i18n.t('stepper.weekly', { ns: 'crawler' });
  }
};

export const priceDisplay = (price) =>
  price > 0
    ? i18n.t('stepper.price', {
        ns: 'crawler',
        price: currencyFormat(price),
      })
    : i18n.t('stepper.free', { ns: 'crawler' });

export const displayJobType = (jobType) =>
  `${seedsDisplay(CRAWLER_TYPES[jobType].SEEDS)} - ${priceDisplay(
    CRAWLER_TYPES[jobType].PRICE
  )}`;

export const filteredPeriods = (slotSchedule) => {
  switch (slotSchedule || 'MANUAL') {
    case CRAWLER_PERIOD_TYPES.DAILY.value:
      return Object.values(CRAWLER_PERIOD_TYPES);
    case CRAWLER_PERIOD_TYPES.WEEKLY.value:
      return [
        CRAWLER_PERIOD_TYPES.MANUAL,
        CRAWLER_PERIOD_TYPES.WEEKLY,
        CRAWLER_PERIOD_TYPES.MONTHLY,
      ];
    case CRAWLER_PERIOD_TYPES.MONTHLY.value:
      return [CRAWLER_PERIOD_TYPES.MANUAL, CRAWLER_PERIOD_TYPES.MONTHLY];
    default:
      return [CRAWLER_PERIOD_TYPES.MANUAL];
  }
};

export const stepperDefaultValues = {
  jobType: '',
  jobName: '',
  user: '',
  jobDescription: '',
  seeds: '',
  subseeds: '',
  includeInCrawl: '.*',
  includeInIndex: '.*',
  excludeFromCrawl: '',
  excludeFromIndex: '',
  excludeContentFromIndex: '',
  instance: '',
  collection: '',
  uniqueKey: '',
  updateHandler: DEFAULT_HANDLER_VALUE[0],
  removeHandler: DEFAULT_HANDLER_VALUE[1],
  statusHandler: DEFAULT_HANDLER_VALUE[2],
  commitTime: 5000,
  commitAfterJob: false,
  crawlArgs: [{ name: '', val: '' }],
  periodType: CRAWLER_PERIOD_TYPES.MANUAL.value,
  periodTime: '00:00',
  day: '',
  date: '',
};

export const dateFormat = (dateTime, timezone, status, isEnd) => {
  return dateTime
    ? formatDateTimeUTC(dateTime, timezone)
    : isEnd && status === CRAWLER_STATUS.ABORTING
    ? i18next.t('table.aborted', { ns: 'crawler' })
    : '-';
};

export const combineAllSeeds = (job) => [...job.seeds, ...job.subseeds];

export const getMaxSeeds = (jobType) => {
  switch (jobType) {
    case JOB_TYPE.FREE:
      return 1;
    case JOB_TYPE.SMALL:
      return 2;
    case JOB_TYPE.MEDIUM:
      return -1;
    default:
      return 0;
  }
};

export const jobProgress = (job) => {
  if (job?.status === CRAWLER_STATUS.RUNNING) {
    return {
      documents: job.documentsFound,
      active: job.documentsActive,
      processed: job.documentsProcessed,
    };
  } else {
    return null;
  }
};

export const getScheduledDay = (periodType, periodDay) => {
  if (periodType === CRAWLER_PERIOD_TYPES.WEEKLY.value) {
    const label = find(CRAWLER_DAYS, { value: periodDay }).label;
    return i18n.language === 'ja'
      ? i18n.t(label, { ns: 'menus' })
      : `on ${i18n.t(label, { ns: 'menus' })}`;
  }

  if (periodType === CRAWLER_PERIOD_TYPES.MONTHLY.value) {
    return i18n.language === 'ja'
      ? `${periodDay}${i18n.t('day', { ns: 'general' })}`
      : `on the ${nth(periodDay)}`;
  }

  return '';
};
