import { getToken, instance } from './config';

const getMetrics = (projectId, instanceId, type, params) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/monitoring/metrics`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: type,
      },
      params: {
        instSubscript: params.node,
        step: params.step,
        term: params.term,
        endDate: params.endDateTime,
      },
    }
  );

export const monitoringApis = {
  getMetrics,
};
