import { useState } from 'react';

import {
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import Title from '../../elements/title/Title';
import Modal from '../../sections/modal/Modal';
import NoContent from '../../sections/no-content/NoContent';
import ProjectManagerForm from './ProjectManagerForm';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  button: {
    margin: theme.spacing(2),
    float: 'right',
  },
  table: {
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '96%',
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const ProjectManager = ({ handleSubmit, projectManager }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [addModal, setAddModal] = useState(false);
  const toggleAddModal = () => setAddModal(!addModal);

  const managerKeys = [
    t('name', { ns: 'fields' }),
    t('jobTitle', { ns: 'fields' }),
    t('companyName', { ns: 'fields' }),
    t('address', { ns: 'fields' }),
    t('phoneNumber', { ns: 'fields' }),
    t('email', { ns: 'fields' }),
  ];

  const managerValues = projectManager.length
    ? [
        projectManager[0].name,
        projectManager[0].title,
        projectManager[0].company,
        projectManager[0].address,
        projectManager[0].phone,
        projectManager[0].email,
      ]
    : [];

  return (
    <>
      <Title subTitle text={t('projectManager', { ns: 'sectionTitles' })} />
      <Container maxWidth="md" className={classes.margin}>
        {projectManager.length ? (
          <Card>
            <Button
              className={classes.button}
              color="light"
              size="small"
              onClick={toggleAddModal}
              aria-label="edit"
            >
              {t('change', { ns: 'buttons' })}
            </Button>
            <TableContainer className={classes.table}>
              <Table size="small">
                <TableBody>
                  {managerKeys.map((item, i) => (
                    <TableRow className={classes.tableRow} key={item}>
                      <TableCell>
                        <Text body text={item} />
                      </TableCell>
                      <TableCell>{managerValues[i]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        ) : (
          <NoContent
            title={sentenceCase(
              t('empty', {
                ns: 'descriptions',
                text: t('pm.info', { ns: 'project' }),
              })
            )}
            button={
              <Button
                onClick={toggleAddModal}
                aria-label={'add a project manager'}
              >
                {t('addProjectManager', { ns: 'buttons' })}
              </Button>
            }
          />
        )}
      </Container>

      <Modal
        openModal={addModal}
        title={t('pm.info', { ns: 'project' })}
        handleClose={toggleAddModal}
      >
        <ProjectManagerForm
          onSubmit={handleSubmit}
          onClose={toggleAddModal}
          data={projectManager[0]}
        />
      </Modal>
    </>
  );
};

ProjectManager.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  projectManager: PropTypes.array.isRequired,
};

export default ProjectManager;
