import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, AccordionSummary, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../elements/button/Button';
import Text from '../../../elements/text/Text';
import { schema } from '../crawler-stepper/crawlerFormSchema';
import CrawlStep2 from '../crawler-stepper/CrawlStep2';
import CrawlStep3 from '../crawler-stepper/CrawlStep3';
import CrawlStep4 from '../crawler-stepper/CrawlStep4';
import CrawlStep5 from '../crawler-stepper/CrawlStep5';

const EditCrawlerJob = ({
  instances,
  job,
  processing,
  getMembers,
  getCollections,
  getUniqueKey,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    trigger,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: job,
    resolver: yupResolver(schema()),
  });

  const handleClose = () => onClose();

  const jobSettingsError =
    !!errors?.jobName ||
    !!errors?.user ||
    !!errors?.seeds ||
    !!errors?.subseeds;
  const extraSettingsError =
    !!errors?.includeInCrawl || !!errors?.includeInIndex;
  const solrSettingsError =
    !!errors?.instance ||
    !!errors?.collection ||
    !!errors?.uniqueKey ||
    !!errors?.updateHandler ||
    !!errors?.removeHandler ||
    !!errors?.statusHandler ||
    !!errors?.commitTime ||
    !!errors?.commitAfterJob ||
    !!errors?.crawlArgs;
  const scheduleSettingsError =
    !!errors?.periodType ||
    !!errors?.periodTime ||
    !!errors?.day ||
    !!errors?.date;

  const titles = [
    [t('crawler.jobSettings', { ns: 'sectionTitles' }), jobSettingsError],
    [t('crawler.extraSettings', { ns: 'sectionTitles' }), extraSettingsError],
    [t('crawler.solrSettings', { ns: 'sectionTitles' }), solrSettingsError],
    [
      t('crawler.scheduleSettings', { ns: 'sectionTitles' }),
      scheduleSettingsError,
    ],
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {[
        <CrawlStep2
          key={1}
          control={control}
          getMembers={getMembers}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
        />,
        <CrawlStep3 key={2} control={control} />,
        <CrawlStep4
          key={3}
          control={control}
          getCollections={getCollections}
          getUniqueKey={getUniqueKey}
          instances={instances}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />,
        <CrawlStep5
          key={4}
          control={control}
          getValues={getValues}
          setValue={setValue}
        />,
      ].map((step, i) => (
        <Accordion key={i}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {titles[i][1] && (
              <WarningIcon color="error" style={{ marginRight: '0.5em' }} />
            )}
            <Text color={titles[i][1] ? 'error' : 'inherit'}>
              {titles[i][0]}
            </Text>
          </AccordionSummary>
          <Box p={2}>{step}</Box>
        </Accordion>
      ))}
      <Box mt={2} display="flex" justifyContent="flex-end" gridGap="1em">
        {!processing && (
          <Button
            color="default"
            variant="text"
            type="reset"
            onClick={handleClose}
          >
            {t('cancel', { ns: 'buttons' })}
          </Button>
        )}
        <Button
          variant={processing ? 'contained' : 'text'}
          type="submit"
          disabled={processing}
          startIcon={processing && <CircularProgress size={20} />}
        >
          {processing
            ? t('pending', { ns: 'buttons' })
            : t('confirm', { ns: 'buttons' })}
        </Button>
      </Box>
    </form>
  );
};

EditCrawlerJob.propTypes = {
  instances: PropTypes.array,
  job: PropTypes.object,
  processing: PropTypes.bool,
  getMembers: PropTypes.func.isRequired,
  getCollections: PropTypes.func.isRequired,
  getUniqueKey: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditCrawlerJob;
