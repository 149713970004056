import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextDivider from '../../../elements/text-divider/TextDivider';
import TextInput from '../../../elements/text-input/TextInput';

const QueryInformationFormSection = ({
  control,
  errors,
  collections,
  style,
}) => {
  const { t } = useTranslation();

  const formFields = ['title', 'body', 'image', 'url'];

  return (
    <>
      <TextDivider>
        {t('search.queryInformation', { ns: 'sectionTitles' })}
      </TextDivider>

      <Box className={style.inputContainer} style={{ marginTop: 2 }}>
        <Box className={style.input}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            error={!!errors?.settings?.query?.collection}
          >
            <InputLabel>{t('search.collection', { ns: 'fields' })}</InputLabel>
            <Controller
              name="settings.query.collection"
              control={control}
              render={({ field }) => (
                <Select
                  label={t('search.collection', { ns: 'fields' })}
                  inputProps={{ 'aria-label': 'collection' }}
                  {...field}
                >
                  {collections?.map((collection) => (
                    <MenuItem key={collection} value={collection}>
                      {collection}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {errors?.settings?.query?.collection?.message}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box className={style.input}>
          <Controller
            name="settings.query.requestHandler"
            aria-label="request handler"
            control={control}
            defaultValue="/select"
            render={({ field }) => (
              <TextField
                {...field}
                label={t('search.requestHandler', { ns: 'fields' })}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ 'aria-label': 'request handler' }}
                error={!!errors?.settings?.query?.requestHandler}
                helperText={errors?.settings?.query?.requestHandler?.message}
              />
            )}
          />
        </Box>
        <Box className={style.input}>
          <Controller
            name="settings.query.uniqueKey"
            aria-label="unique key"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t('search.uniqueKey', { ns: 'fields' })}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ 'aria-label': 'unique key' }}
                disabled
                error={!!errors?.settings?.query?.uniqueKey}
                helperText={errors?.settings?.query?.uniqueKey?.message}
              />
            )}
          />
        </Box>

        {formFields.map((formField, i) => (
          <Box className={style.input} key={i}>
            <TextInput
              name={`settings.query.display.${formField}`}
              control={control}
              label={t(`search.${formField}`, { ns: 'fields' })}
              errors={errors}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

QueryInformationFormSection.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  collections: PropTypes.array,
  style: PropTypes.object,
};

export default QueryInformationFormSection;
