export const EXTENSION_TYPES = {
  SOLR_PLUGIN: 'SOLR_PLUGIN',
  SOLR_DICTIONARY: 'SOLR_DICTIONARY',
  SOLR_COLLECTION_CONFIG: 'SOLR_COLLECTION_CONFIG',
  SOLR_MODEL: 'SOLR_MODEL',
  DATA: 'DATA',
  MISC: 'MISC',
};

export const isDownloadToInstance = (type) =>
  type === EXTENSION_TYPES.SOLR_PLUGIN ||
  type === EXTENSION_TYPES.SOLR_DICTIONARY;

export const PLUGIN_DESTINATIONS = [
  {
    default: true,
    value: 'data',
    label: 'labels.dataLibDir',
  },
  {
    default: false,
    value: 'collection',
    label: 'labels.collectionLibDir',
  },
];

export const SORT_TYPES = {
  POPULAR: 'downloadCount,DESC',
  RECENT: 'versionReleaseDate,DESC',
};
