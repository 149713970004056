import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { duotoneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import './MarkdownReader.styles.scss';
import './CustomMarkdownReader.styles.scss';

const SyntaxHighlight = {
  // Disabled prop-types definition in the following line because we don't control the props
  // eslint-disable-next-line react/prop-types
  code({ inline, className, ...props }) {
    const match = (className || '').match(/language-(\w+)/);

    return !inline ? (
      <SyntaxHighlighter
        className="code-highlight"
        PreTag="div"
        style={duotoneDark}
        language={match?.[1] || ''}
        {...props}
      />
    ) : (
      <code className={className} {...props} />
    );
  },
};

const MarkdownReader = ({ markdown }) => {
  return (
    <Box className="markdown-body" mb={5}>
      <ReactMarkdown
        components={SyntaxHighlight}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
      >
        {markdown}
      </ReactMarkdown>
    </Box>
  );
};

MarkdownReader.propTypes = {
  markdown: PropTypes.string,
};

export default MarkdownReader;
