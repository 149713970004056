import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  // 30px
  small: {
    fontSize: '0.9rem',
    width: theme.spacing(4) - 2,
    height: theme.spacing(4) - 2,
  },
  // 45px
  medium: {
    width: theme.spacing(6) - 3,
    height: theme.spacing(6) - 3,
  },
  // 100px
  large: {
    fontSize: '3rem',
    width: theme.spacing(13) - 4,
    height: theme.spacing(13) - 4,
  },
}));

const Image = ({ src, size, alt, shape, children, ...restProps }) => {
  const classes = useStyles();
  const sizes = {
    small: classes.small,
    medium: classes.medium,
    large: classes.large,
  };

  const imageSize = sizes[size] || classes.medium;

  return (
    <Avatar
      src={src}
      alt={alt}
      variant={shape}
      className={imageSize}
      data-testid="image"
      {...restProps}
    >
      {children}
    </Avatar>
  );
};

Image.propTypes = {
  shape: PropTypes.oneOf(['square', 'circle', 'rounded']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  src: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.string,
};

export default Image;
