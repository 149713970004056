import './SplitPane.styles.scss';
import PropTypes from 'prop-types';
import Split from 'react-split';

const SplitPane = ({ children }) => {
  return (
    <Split
      className="split"
      minSize={350}
      style={{ minHeight: 'calc(100vh - 12rem)', justifyContent: 'center' }}
    >
      {children}
    </Split>
  );
};

SplitPane.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default SplitPane;
