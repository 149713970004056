import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  textDivider: {
    width: '100%',
    borderBottom: '1px solid #AAA',
    lineHeight: '0.1em',
    '& span': {
      background: '#fff',
      padding: '10px',
      color: '#AAA',
    },
  },
}));

const TextDivider = ({ children }) => {
  const classes = useStyles();

  return (
    <Box mt="2em" mb="2em">
      <Typography className={classes.textDivider}>
        <span>{children}</span>
      </Typography>
    </Box>
  );
};

TextDivider.propTypes = {
  children: PropTypes.string,
};

export default TextDivider;
