import { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useUser } from '../../../../contexts/UserContext';
import { PRIVATE_ROUTE, buildPath } from '../../../../routes/routes';
import { formatDateTimeUTC } from '../../../../utils/timeUtils';
import { formatUserInfo } from '../../../../utils/utils';
import CopyableText from '../../../elements/copyable-text/CopyableText';
import Loading from '../../../elements/loading/Loading';
import Modal from '../../../sections/modal/Modal';
import NoContent from '../../../sections/no-content/NoContent';
import UserAvatar from '../../../sections/user-avatar/UserAvatar';

const LoadQueryModal = ({
  open,
  getQueryList,
  onLoadQuery,
  onDeleteQuery,
  onToggle,
}) => {
  const { projectId, instanceId } = useParams();
  const { t } = useTranslation();
  const { user } = useUser();

  const tableHeaders = [
    t('queryName', { ns: 'instance' }),
    t('collection', { ns: 'instance' }),
    t('creator', { ns: 'instance' }),
    t('createDate', { ns: 'instance' }),
    '',
  ];

  const [queryList, setQueryList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchQueryList = async () => {
    const queryListRes = await getQueryList();
    setQueryList(queryListRes);
    setLoading(false);
  };

  useEffect(() => {
    if (!open) return;
    fetchQueryList();
    // eslint-disable-next-line
  }, [open]);

  const handleToggle = () => onToggle();
  const handleLoad = (query) => {
    onLoadQuery(query);
    onToggle();
  };
  const handleDelete = async (queryId) => {
    await onDeleteQuery(queryId);
    fetchQueryList();
  };

  return (
    <Modal
      openModal={open}
      title={t('search.queryList', { ns: 'sectionTitles' })}
      handleClose={handleToggle}
      maxWidth="md"
    >
      {loading && <Loading />}
      {!loading &&
        (queryList.length > 0 ? (
          <TableContainer style={{ maxHeight: 350, marginBottom: '2em' }}>
            <Table aria-label="load query table" stickyHeader>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {queryList.map((query) => (
                  <TableRow key={query.id}>
                    <TableCell>{query.name}</TableCell>
                    <TableCell>{query.collection}</TableCell>
                    <TableCell>
                      <UserAvatar
                        user={formatUserInfo(query.createdBy, query.author)}
                      />
                    </TableCell>
                    <TableCell>
                      {formatDateTimeUTC(query.createdAt, user?.timeZone)}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={t('loadQuery', { ns: 'tooltips' })}
                        placement="bottom"
                        arrow
                        aria-label="load"
                      >
                        <IconButton onClick={() => handleLoad(query)}>
                          <SystemUpdateAltIcon />
                        </IconButton>
                      </Tooltip>
                      <CopyableText
                        iconOnly
                        text={`${window.location.origin}${buildPath({
                          path: PRIVATE_ROUTE.INSTANCE_SEARCH,
                          params: {
                            projectId: projectId,
                            instanceId: instanceId,
                          },
                        })}?query=${query.id}`}
                        tooltip={t('copyQueryLink', { ns: 'tooltips' })}
                      />
                      <Tooltip
                        title={t('deleteQuery', { ns: 'tooltips' })}
                        placement="bottom"
                        arrow
                      >
                        <IconButton
                          onClick={() => handleDelete(query.id)}
                          aria-label="delete"
                        >
                          <DeleteForeverIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box mb={2}>
            <NoContent title={t('noQuery', { ns: 'instance' })} />
          </Box>
        ))}
    </Modal>
  );
};

LoadQueryModal.propTypes = {
  open: PropTypes.bool,
  getQueryList: PropTypes.func.isRequired,
  onLoadQuery: PropTypes.func.isRequired,
  onDeleteQuery: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default LoadQueryModal;
