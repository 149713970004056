import {
  Card,
  CardContent,
  CardActions,
  makeStyles,
  Grid,
  Box,
  Link,
} from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import github from '../../../../src/assets/providers/github.svg';
import gitlab from '../../../../src/assets/providers/gitlab.svg';
import google from '../../../../src/assets/providers/google.svg';
import linkedin from '../../../../src/assets/providers/linkedin.svg';
import Svg from '../../elements/svg/Svg';
import Text from '../../elements/text/Text';
import Title from '../../elements/title/Title';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 150,
  },
  image: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1.0,
    },
  },
  imageConnected: {
    opacity: 1.0,
  },
  cardAction: {
    color: 'white',
    backgroundColor: theme.palette.action.disabled,
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.active,
      cursor: 'pointer',
    },
  },
  cardActionConnected: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      cursor: 'pointer',
    },
  },
  centerOnSmall: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    marginTop: theme.spacing(1),
  },
}));

const imageMap = {
  google: google,
  gitlab: gitlab,
  github: github,
  linkedin: linkedin,
};

const SocialCards = ({ providerData, handleConnect, handleDisconnect }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Title subTitle text={t('socialProviders', { ns: 'sectionTitles' })} />
      <Grid container spacing={2} className={classes.centerOnSmall}>
        {providerData.map((provider) => {
          return (
            <Grid key={provider.id} item>
              <Card className={classes.card}>
                <CardContent>
                  <Box
                    className={
                      provider.connected
                        ? classes.imageConnected
                        : classes.image
                    }
                    mb={2}
                    display="flex"
                    justifyContent="center"
                  >
                    <Svg src={imageMap[provider.id]} width="50%" />
                  </Box>
                  <Text subtitle align="center">
                    {_.capitalize(provider.name)}
                  </Text>
                </CardContent>
                {provider.connected ? (
                  <Link
                    onClick={() => handleDisconnect(provider.id)}
                    color="inherit"
                    underline="none"
                  >
                    <CardActions
                      data-testid="disconnect-action"
                      className={classes.cardActionConnected}
                    >
                      <Text bodyBold>
                        {t('deactivateSocialLogin', { ns: 'buttons' })}
                      </Text>
                    </CardActions>
                  </Link>
                ) : (
                  <Link
                    onClick={() => handleConnect(provider.id, provider.url)}
                    color="inherit"
                    underline="none"
                  >
                    <CardActions
                      data-testid="connect-action"
                      className={classes.cardAction}
                    >
                      <Text bodyBold>
                        {t('activateSocialLogin', { ns: 'buttons' })}
                      </Text>
                    </CardActions>
                  </Link>
                )}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SocialCards;

SocialCards.propTypes = {
  providerData: PropTypes.object.isRequired,
  handleConnect: PropTypes.func.isRequired,
  handleDisconnect: PropTypes.func.isRequired,
};
