import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { crawlerApis } from '../../../apis/crawlerApis';
import { projectApis } from '../../../apis/projectApis';
import Button from '../../../components/elements/button/Button';
import Loading from '../../../components/elements/loading/Loading';
import CrawlerJobDetails from '../../../components/page-sections/web-crawler/crawler-details/CrawlerJobDetails';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { handleErrors } from '../../../helpers';
import useToast from '../../../hooks/useToast';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';

const ProjectCrawlerDetailsPage = () => {
  const { projectId, crawlerJobId } = useParams();
  const history = useHistory();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [crawlJob, setCrawlJob] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [projectRes, crawlJobRes] = await requestAll([
        projectApis.getProject(projectId),
        crawlerApis.getCrawlJob(projectId, crawlerJobId),
      ]);

      setProject(projectRes.data);

      const foundJob = crawlJobRes?.data;

      if (foundJob) {
        setCrawlJob(foundJob);
      } else {
        return history.replace(PRIVATE_ROUTE.ERROR404);
      }
    } catch (error) {
      handleErrors(history, error, showToast);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!projectId) return;
    fetchData();
    // eslint-disable-next-line
  }, [projectId, crawlerJobId]);

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <Breadcrumbs projectName={project?.name} jobName={crawlJob.name} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TitlePanel
          visible
          title={t('crawler.details', { ns: 'sectionTitles' })}
        />
        <Button
          variant="text"
          component={RouterLink}
          to={buildPath({
            path: PRIVATE_ROUTE.PROJECT_CRAWLER,
            params: { projectId },
          })}
        >
          {t('returnToJobOverview', { ns: 'buttons' })}
        </Button>
      </Box>
      <Box>
        <CrawlerJobDetails job={crawlJob} />
      </Box>
    </Container>
  );
};

export default ProjectCrawlerDetailsPage;
