import { useEffect, useState } from 'react';

import { Box, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { INSTANCE_STATE } from '../../../apis/constants';
import { instanceApis } from '../../../apis/instanceApis';
import { modelApis } from '../../../apis/modelApis';
import { projectApis } from '../../../apis/projectApis';
import Loading from '../../../components/elements/loading/Loading';
import InstanceForm from '../../../components/page-sections/instance-settings/InstanceForm';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import DeleteSection from '../../../components/sections/delete/DeleteSection';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { PRIVATE_ROUTE, buildPath } from '../../../routes/routes';
import { sentenceCase, updateObjectValues } from '../../../utils/utils';

const instanceSettingsChanged = (currentData, newData) =>
  currentData.name !== newData.name ||
  currentData.description !== newData.description ||
  currentData.subdomain !== newData.subdomain;

const ModelSettingsPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { projectId, modelId } = useParams();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [model, setModel] = useState(null);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    try {
      const [{ data: projectRes }, { data: modelRes }] = await requestAll([
        projectApis.getProject(projectId),
        modelApis.getModel(projectId, modelId),
      ]);

      setProject(projectRes);
      setModel(modelRes);
    } catch (error) {
      handleErrors(history, error, showToast);
      setError(true);
    }
  };

  useEffect(() => {
    if (!projectId || !modelId) return;
    setLoading(true);
    fetchData();
    setLoading(false);
    // eslint-disable-next-line
  }, [projectId, modelId]);

  const handleUpdateInstanceImage = async (imageFile) => {
    try {
      const formData = new FormData();
      formData.append('image', imageFile);

      await instanceApis.updateInstanceImage(projectId, modelId, formData);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('instanceImage', { ns: 'instance' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('instanceImage', { ns: 'instance' }),
          })
        )
      );
    }
  };

  const formatInstanceData = (data) => {
    const { name, description, subdomain } = model;
    const initialInstanceData = {
      name,
      description,
      subdomain,
    };

    const instancePayload = updateObjectValues(initialInstanceData, data);
    return instancePayload;
  };

  const handleInstanceSettingsUpdate = async (settingsData) => {
    const instancePayload = formatInstanceData(settingsData);

    try {
      await modelApis.updateModel(projectId, modelId, instancePayload);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('instanceInfo', { ns: 'instance' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('instanceInfo', { ns: 'instance' }),
          })
        )
      );
    }
  };

  const handleUpdateInstance = async (data) => {
    const { name, description, subdomain, clusterType } = model;
    const { icon, ...restProps } = data;

    const initialInstanceData = {
      name,
      description,
      subdomain,
      clusterType,
    };

    const settingsPayload = updateObjectValues(initialInstanceData, restProps);

    if (icon) {
      await handleUpdateInstanceImage(icon);
    }

    if (instanceSettingsChanged(model, data)) {
      await handleInstanceSettingsUpdate(settingsPayload);
    }

    fetchData(projectId, modelId);
  };

  const handleRemoveModelImage = async () => {
    try {
      await instanceApis.deleteInstanceImage(projectId, modelId);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('deleteSuccess', {
          ns: 'notifications',
          text: t('avatar', { ns: 'fields' }),
        })
      );
      fetchData(projectId, modelId);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('avatar', { ns: 'fields' }),
          })
        )
      );
    }
  };

  const handleCheckDuplicateSubdomainInstance = async (subdomain) => {
    if (subdomain === model.subdomain) {
      return { isValid: true };
    }

    try {
      return { isValid: await instanceApis.checkSubdomain(subdomain) };
    } catch (e) {
      showToast(
        TOAST_TYPE.ERROR,
        t('unableToCheckSubdomain', { ns: 'notifications' })
      );

      return { isValid: false };
    }
  };

  const handleDeleteModel = async () => {
    try {
      // delete instance
      await modelApis.deleteModel(projectId, modelId);

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('instance', { ns: 'instance' }),
          })
        )
      );
      const projectOverview = buildPath({
        path: PRIVATE_ROUTE.PROJECT_OVERVIEW,
        params: { projectId: projectId },
      });
      setTimeout(() => history.push(projectOverview), 800);
    } catch (errors) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('model', { ns: 'model' }),
          })
        )
      );
    }
  };

  return (
    <Container>
      {loading && <Loading />}
      {!loading && error && (
        <Box mt={2}>
          <Alert severity="error">
            {sentenceCase(
              t('loading', {
                ns: 'errors',
                content: t('settingsPage', {
                  ns: 'project',
                  text: t('model', { ns: 'model' }),
                }),
              })
            )}
          </Alert>
        </Box>
      )}
      {!loading && project && model && (
        <>
          <Breadcrumbs projectName={project.name} modelName={model.name} />
          <TitlePanel title={t('settings', { ns: 'titles' })} />
          <InstanceForm
            data={model}
            checkDuplicate={handleCheckDuplicateSubdomainInstance}
            onSubmit={handleUpdateInstance}
            onRemoveImage={handleRemoveModelImage}
            disableSubdomainMessage=""
            model
          />
          <DeleteSection
            title={t('modelDeletion', { ns: 'sectionTitles' })}
            description={sentenceCase(
              t('deleteWarning', {
                ns: 'descriptions',
                text: t('model', { ns: 'model ' }),
              })
            )}
            validation={model.name}
            onDelete={handleDeleteModel}
            disableMessage={
              model.status !== INSTANCE_STATE.RUNNING
                ? t('instanceIsBusy', { ns: 'descriptions' })
                : ''
            }
          />
        </>
      )}
    </Container>
  );
};

export default ModelSettingsPage;
