import { Divider, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  INSTANCE_CATEGORY,
  PAYMENT_TYPES,
  PLAN_LIST,
} from '../../../constants';
import Text from '../../elements/text/Text';
import InfoConfirmation from './InfoConfirmation';
import PaymentConfirmation from './PaymentConfirmation';
import StepTitle from './StepTitle';

const Confirmation = ({ billingInfo, card }) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext();
  const { plan } = getValues();

  const selectedPlan =
    plan === INSTANCE_CATEGORY.COMMUNITY
      ? PLAN_LIST[0]
      : PLAN_LIST.slice(1).find((pl) => {
          return pl.type === plan;
        });

  const isInvoicePayment =
    billingInfo[0]?.paymentType === PAYMENT_TYPES.INVOICE;

  return (
    <section>
      <StepTitle title={t('confirmInfoBelow', { ns: 'descriptions' })} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Text subtitle color="primary" align="center">
            {t('instanceInfo', { ns: 'instance' })}
          </Text>
          <Divider />
          <InfoConfirmation selectedPlan={selectedPlan} />
        </Grid>
        <Divider />
        <Grid item xs={12} md={6}>
          <Text id="payment-details" subtitle color="primary" align="center">
            {t('paymentDetails', { ns: 'instance' })}
          </Text>
          <Divider />
          <PaymentConfirmation
            card={card}
            isInvoicePayment={isInvoicePayment}
            selectedPlan={selectedPlan}
          />
        </Grid>
      </Grid>
    </section>
  );
};

Confirmation.propTypes = {
  billingInfo: PropTypes.array.isRequired,
  card: PropTypes.object,
};

export default Confirmation;
