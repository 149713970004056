import { Box, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CLUSTER_TYPES } from '../../../constants';
import Text from '../../elements/text/Text';
import InstanceChip from '../../sections/instance-chip/InstanceChip';
import ModelChip from '../../sections/instance-chip/ModelChip';

const SettingsDetails = ({ data }) => {
  const { t } = useTranslation();

  const isSolrInstance = data.clusterType === CLUSTER_TYPES.SOLR_CLUSTER;
  const isModelInstance = data.clusterType === CLUSTER_TYPES.MODEL_CLUSTER;
  const isSolrOrModelInstance = isSolrInstance || isModelInstance;

  return (
    <Paper variant="outlined">
      <Box p={1}>
        {isSolrOrModelInstance && (
          <Box display="flex">
            <Text bodyBold>
              {isSolrInstance && t('instancePlan', { ns: 'descriptions' })}
              {isModelInstance && t('modelType', { ns: 'model' })}:
            </Text>
            {isSolrInstance && (
              <InstanceChip
                category={data.category}
                plan={data.instances[0].type}
                ml={1}
              />
            )}
            {isModelInstance && <ModelChip category={data.category} ml={1} />}
          </Box>
        )}
        <Text bodyBold>
          {t('instanceStorage', {
            ns: 'descriptions',
            storageSize: data.instances[0].storageSize,
          })}
        </Text>
      </Box>
    </Paper>
  );
};

SettingsDetails.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SettingsDetails;
