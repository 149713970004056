import { Table, TableBody, TableContainer } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../../contexts/UserContext';
import { sentenceCase } from '../../../../utils/utils';
import NoContent from '../../../sections/no-content/NoContent';
import { dateFormat, tableDisplay } from '../crawlerUtils';

const CrawlerStatusTable = ({ job, status }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  return status ? (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {tableDisplay(t('jobName', { ns: 'fields' }), job.name)}
          {tableDisplay(
            t('status', { ns: 'project' }),
            status.status.replace('_', ' ')
          )}
          {status.error &&
            tableDisplay(
              t('table.errorMessage', { ns: 'crawler' }),
              status.error
            )}
          {tableDisplay(
            t('startTime', { ns: 'project' }),
            dateFormat(status.startDate, user?.timeZone, status.status)
          )}
          {tableDisplay(
            t('endTime', { ns: 'project' }),
            dateFormat(status.endDate, user?.timeZone, status.status)
          )}
          {tableDisplay(
            t('table.documents', { ns: 'crawler' }),
            status.documentsFound
          )}
          {tableDisplay(
            t('table.active', { ns: 'crawler' }),
            status.documentsActive
          )}
          {tableDisplay(
            t('table.processed', { ns: 'crawler' }),
            status.documentsProcessed
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <NoContent
      title={sentenceCase(
        t('empty', {
          ns: 'descriptions',
          text: t('crawler.jobStatus', { ns: 'sectionTitles' }),
        })
      )}
    />
  );
};

CrawlerStatusTable.propTypes = {
  job: PropTypes.object.isRequired,
  status: PropTypes.object,
};

export default CrawlerStatusTable;
