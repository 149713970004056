import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63b4ff',
      main: '#1a85cd',
      dark: '#00599c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#78e1df',
      main: '#41afad',
      dark: '#007f7e',
      contrastText: '#fff',
    },
  },
  typography: {
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '1.85rem',
    },
    h3: {
      fontSize: '1.65rem',
    },
    h4: {
      fontSize: '1.45rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1.15rem',
    },
    subtitle1: {
      fontSize: '1.15rem',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '1.1rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    fontFamily: 'Open Sans',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  text: {
    primary: 'rgba(19, 19, 19, 0.87)',
  },
});

export default theme;
