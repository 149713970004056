import moment from 'moment-timezone';
import * as yup from 'yup';

import { CRAWLER_PERIOD_TYPES } from '../../../../constants/crawlerConstants';
import i18n from '../../../../i18n';
import { sentenceCase } from '../../../../utils/utils';

// Return schema as a function to capture i18n language setting
const argsSchema = () =>
  yup.object().shape(
    {
      name: yup.string().when('val', {
        is: (v) => v !== '',
        then: yup
          .string()
          .required(i18n.t('bothCrawlArgsRequired', { ns: 'validations' })),
        otherwise: yup.string(),
      }),
      val: yup.string().when('name', {
        is: (v) => v !== '',
        then: yup
          .string()
          .required(i18n.t('bothCrawlArgsRequired', { ns: 'validations' })),
        otherwise: yup.string(),
      }),
    },
    ['name', 'val']
  );

// Return schema as a function to capture i18n language setting
export const schema = () =>
  yup.object().shape(
    {
      jobType: yup.string().required(
        sentenceCase(
          i18n.t('selectRequired', {
            ns: 'validations',
            field: i18n.t('availableJob', { ns: 'fields' }),
          })
        )
      ),
      jobName: yup
        .string()
        .required(
          sentenceCase(
            i18n.t('inputRequired', {
              ns: 'validations',
              field: i18n.t('jobName', { ns: 'fields' }),
            })
          )
        )
        .max(50, i18n.t('maxLength', { ns: 'validations', max: '50' })),
      user: yup.string().required(
        sentenceCase(
          i18n.t('inputRequired', {
            ns: 'validations',
            field: i18n.t('user', { ns: 'fields' }),
          })
        )
      ),
      jobDescription: yup
        .string()
        .max(255, i18n.t('maxLength', { ns: 'validations', max: '255' })),
      seeds: yup
        .string()
        .required(
          sentenceCase(
            i18n.t('inputRequired', {
              ns: 'validations',
              field: i18n.t('seeds', { ns: 'fields' }),
            })
          )
        )
        .test(
          'noDuplicates',
          i18n.t('duplicate', {
            ns: 'errors',
            item: i18n.t('stepper.seed', { ns: 'crawler' }),
          }),
          (val) => {
            if (!val) return;
            const seedArr = val.split('\n');
            const uniqueSeeds = new Set(seedArr);
            return [...uniqueSeeds].length === seedArr.length;
          }
        ),
      subseeds: yup
        .mixed()
        .test(
          'matchesSeeds',
          i18n.t('subseedContainsSeed', { ns: 'validations' }),
          (val, ctx) => {
            if (!val) return true;
            const seeds = ctx.parent.seeds;
            const seedArr = seeds.length > 0 && seeds.split('\n');
            const subseedArr = val.split('\n');

            const match = subseedArr.filter((subseed) =>
              seedArr.some((seed) => subseed.includes(seed))
            );
            return match.length === subseedArr.length;
          }
        ),
      includeInCrawl: yup.string().required(
        sentenceCase(
          i18n.t('inputRequired', {
            ns: 'validations',
            field: i18n.t('includeInCrawl', { ns: 'fields' }),
          })
        )
      ),
      includeInIndex: yup.string().required(
        sentenceCase(
          i18n.t('inputRequired', {
            ns: 'validations',
            field: i18n.t('includeInIndex', { ns: 'fields' }),
          })
        )
      ),
      excludeFromCrawl: yup.string(),
      excludeFromIndex: yup.string(),
      excludeContentFromIndex: yup.string(),
      instance: yup.string().required(
        sentenceCase(
          i18n.t('inputRequired', {
            ns: 'validations',
            field: i18n.t('instanceName', { ns: 'fields' }),
          })
        )
      ),
      collection: yup.string().required(
        sentenceCase(
          i18n.t('inputRequired', {
            ns: 'validations',
            field: i18n.t('collectionName', { ns: 'fields' }),
          })
        )
      ),
      uniqueKey: yup
        .string()
        .required(sentenceCase(i18n.t('noUniqueKey', { ns: 'validations' }))),
      updateHandler: yup.string().required(
        sentenceCase(
          i18n.t('inputRequired', {
            ns: 'validations',
            field: i18n.t('updateHandler', { ns: 'fields' }),
          })
        )
      ),
      removeHandler: yup.string().required(
        sentenceCase(
          i18n.t('inputRequired', {
            ns: 'validations',
            field: i18n.t('removeHandler', { ns: 'fields' }),
          })
        )
      ),
      statusHandler: yup.string().required(
        sentenceCase(
          i18n.t('inputRequired', {
            ns: 'validations',
            field: i18n.t('statusHandler', { ns: 'fields' }),
          })
        )
      ),
      commitAfterJob: yup.bool().when('commitTime', {
        is: (val) => val === undefined,
        then: yup
          .bool()
          .oneOf([true], i18n.t('commitTimeOrAfterJob', { ns: 'validations' })),
        otherwise: yup.bool().oneOf([true, false]),
      }),
      commitTime: yup.number().when('commitAfterJob', {
        is: (val) => val === false,
        then: yup
          .number()
          .min(5000, i18n.t('minCommitTime', { ns: 'validations' }))
          .transform((v, o) => (o === '' ? undefined : v))
          .required(i18n.t('commitTimeOrAfterJob', { ns: 'validations' })),
        otherwise: yup
          .number()
          .min(5000, i18n.t('minCommitTime', { ns: 'validations' }))
          .transform((v, o) => (o === '' ? undefined : v)),
      }),
      crawlArgs: yup.array().of(argsSchema()),
      periodType: yup.string(),
      periodTime: yup.string().when('periodType', {
        is: (v) => v !== CRAWLER_PERIOD_TYPES.MANUAL.value,
        then: yup
          .string()
          .test(
            'is-time',
            sentenceCase(
              i18n.t('invalid', {
                ns: 'validations',
                field: i18n.t('timePeriod', { ns: 'fields' }),
              })
            ),
            (time) => moment(time, 'HH:mm', true).isValid()
          )
          .required(
            sentenceCase(
              i18n.t('inputRequired', {
                ns: 'validations',
                field: i18n.t('timePeriod', { ns: 'fields' }),
              })
            )
          ),
        otherwise: yup.string(),
      }),
      timezone: yup.string().when('periodType', {
        is: (v) => v !== CRAWLER_PERIOD_TYPES.MANUAL.value,
        then: yup.string().required(
          sentenceCase(
            i18n.t('inputRequired', {
              ns: 'validations',
              field: i18n.t('timezone', { ns: 'fields' }),
            })
          )
        ),
        otherwise: yup.string(),
      }),
      day: yup.string().when('periodType', {
        is: (v) => v === CRAWLER_PERIOD_TYPES.WEEKLY.value,
        then: yup.string().required(
          sentenceCase(
            i18n.t('inputRequired', {
              ns: 'validations',
              field: i18n.t('day', { ns: 'fields' }),
            })
          )
        ),
        otherwise: yup.string(),
      }),
      date: yup.string().when('periodType', {
        is: (v) => v === CRAWLER_PERIOD_TYPES.MONTHLY.value,
        then: yup.string().required(
          sentenceCase(
            i18n.t('inputRequired', {
              ns: 'validations',
              field: i18n.t('date', { ns: 'fields' }),
            })
          )
        ),
        otherwise: yup.string(),
      }),
    },
    ['commitAfterJob', 'commitTime']
  );
