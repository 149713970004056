import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { INSTANCE_CATEGORY, TAX_RATE } from '../../../constants';
import { currencyFormat } from '../../../utils/paymentUtils';
import { formatBytes } from '../../../utils/utils';
import Text from '../../elements/text/Text';
import {
  getDaysCharged,
  getNewFullAmount,
  getNewPaymentDetails,
  getPaymentPeriod,
} from '../helpers';
import CreditCard from './CreditCard';

const PaymentConfirmation = ({ card, isInvoicePayment, selectedPlan }) => {
  const { t } = useTranslation();

  const amount = getNewPaymentDetails(selectedPlan);
  const fullCharge = getNewFullAmount(selectedPlan);
  const daysCharged = getDaysCharged();

  const paymentRows = [
    {
      label: `${t('billing.chargePlan', {
        ns: 'project',
        text: capitalize(selectedPlan.name),
      })} (${formatBytes(selectedPlan.storage)})`,
      price: currencyFormat(amount.priceBeforeTax),
    },
    {
      label: `${t('billing.tax', { ns: 'project' })} (${TAX_RATE * 100}%)`,
      price: currencyFormat(amount.tax),
    },
    {
      label: t('billing.chargeDays', { ns: 'project', number: daysCharged }),
      price: currencyFormat(amount.priceAfterTax),
    },
  ];

  const chargeTypeRow = () => {
    if (selectedPlan.category === INSTANCE_CATEGORY.COMMUNITY) {
      return (
        <Box mb={1}>
          <Text style={{ marginBottom: '0.5em' }}>
            {t('communityCreate', { ns: 'descriptions' })}
          </Text>
          <Text>{t('deleteAfterThirty', { ns: 'descriptions' })}</Text>
        </Box>
      );
    } else if (isInvoicePayment) {
      return (
        <Box mb={1}>
          <Text component="div">
            <Trans
              i18nKey="instanceConfirm"
              ns="descriptions"
              values={{
                plan: capitalize(selectedPlan.name),
              }}
              components={[<Text bodyBold component="span" key="1" />]}
            />
          </Text>
          <Text>{t('chargeByInvoice', { ns: 'descriptions' })}</Text>
          <Text>{t('invoiceSentByMail', { ns: 'descriptions' })}</Text>
        </Box>
      );
    } else {
      return (
        <Box mb={1}>
          <Text component="div">
            <Trans
              i18nKey="instanceConfirm"
              ns="descriptions"
              values={{
                plan: capitalize(selectedPlan.name),
              }}
              components={[<Text bodyBold component="span" key="1" />]}
            />
          </Text>
          <Text gutterBottom>{t('chargeByCard', { ns: 'descriptions' })}</Text>
          <CreditCard card={card} />
        </Box>
      );
    }
  };

  return (
    <Box p={1}>
      {chargeTypeRow()}
      <TableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>
                {t('billing.chargePeriod', {
                  ns: 'project',
                  text: getPaymentPeriod(),
                })}
              </TableCell>
              <TableCell align="right">
                {t('billing.price', { ns: 'project' })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentRows.map((row) => (
              <TableRow key={row.label}>
                <TableCell>{row.label}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={1}>
        <Text>
          {t('nextFullCharge', {
            ns: 'descriptions',
            price: currencyFormat(fullCharge),
          })}
        </Text>
      </Box>
    </Box>
  );
};

PaymentConfirmation.propTypes = {
  card: PropTypes.object,
  isInvoicePayment: PropTypes.bool,
  selectedPlan: PropTypes.object.isRequired,
};

export default PaymentConfirmation;
