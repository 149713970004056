import * as yup from 'yup';

import i18n from '../../../i18n';

export const schema = () =>
  yup.object().shape({
    checkInterval: yup
      .number()
      .required(
        i18n.t('inputRequired', {
          ns: 'validations',
          field: i18n.t('monitor.checkInterval', { ns: 'fields' }),
        })
      )
      .typeError(i18n.t('numRequired', { ns: 'validations' }))
      .min(1, i18n.t('minValue', { ns: 'validations', num: 1 })),
    renoticeInterval: yup
      .number()
      .required(
        i18n.t('inputRequired', {
          ns: 'validations',
          field: i18n.t('monitor.renoticeInterval', { ns: 'fields' }),
        })
      )
      .typeError(i18n.t('numRequired', { ns: 'validations' }))
      .min(1, i18n.t('minValue', { ns: 'validations', num: 1 })),
    checkMethod: yup.string().required(
      i18n.t('inputRequired', {
        ns: 'validations',
        field: i18n.t('monitor.checkMethod', { ns: 'fields' }),
      })
    ),
  });
