import { useState } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import extPlaceholder from '../../../assets/ext-placeholder.png';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import { currencyFormat } from '../../../utils/paymentUtils';
import { formatExtensionType } from '../../../utils/utils';
import Badge from '../../elements/badge/Badge';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import MarkdownReader from '../../sections/markdown-reader/MarkdownReader';
import MinorExtDetails from './MinorExtDetails';
import SubToExtButton from './SubToExtButton';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& .MuiAvatar-img': {
      objectFit: 'contain',
    },
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1em',
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
  icon: {
    width: 200,
    height: 200,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
    },
    border: '1px solid lightgray',
  },
  titleWrapper: {
    marginLeft: '1em',
    order: 2,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      textAlign: 'center',
      order: 1,
    },
  },
  extraInfo: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  price: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    fontWeight: 'bold',
    width: '100%',
  },
  menuItem: {
    minWidth: 200,
  },
  descBox: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  returnButton: {
    marginLeft: 'auto',
    order: 3,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      flexGrow: 1,
      '& > .MuiButton-text': {
        width: '100%',
      },
    },
  },
  descriptionUrl: {
    marginY: '2em',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const ExtensionDetails = ({ extension, fetchProjects, onSubscribe }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [projects, setProjects] = useState([]);

  const handleMenuOpen = async (event) => {
    const projectRes = await fetchProjects();
    setProjects(projectRes);
  };

  const handleSubscribe = async (projectId) => {
    await onSubscribe(projectId);
  };

  const {
    name,
    icon,
    type,
    description,
    descriptionUrl,
    price,
    free,
    details,
  } = extension;

  const extensionType = formatExtensionType(type);
  const handleReturnToLibrary = () =>
    history.push(
      buildPath({
        path: PRIVATE_ROUTE.EXTENSIONS,
      })
    );

  return (
    <>
      <Box mt={2} className={classes.wrapper}>
        <Box className={classes.iconWrapper}>
          <Avatar
            variant="square"
            alt={name}
            src={icon || extPlaceholder}
            className={classes.icon}
          />
          <Box my={1}>
            <Chip
              label={
                free
                  ? t('billing.free', { ns: 'project' })
                  : currencyFormat(price)
              }
              className={classes.price}
            />
          </Box>
          <SubToExtButton
            projects={projects}
            onMenuOpen={handleMenuOpen}
            onSubscribe={handleSubscribe}
            styling={classes.menuItem}
          />
        </Box>
        <Box className={classes.titleWrapper}>
          <Box mb={2}>
            <Badge
              label={extensionType.display}
              color={extensionType.className}
            />
            <Text title>{name}</Text>
          </Box>
          {!isXsScreen && <MinorExtDetails extension={extension} />}
        </Box>
        <Box order={4}>
          {isXsScreen && <MinorExtDetails extension={extension} />}
        </Box>
        <Box className={classes.returnButton}>
          <Button variant="text" onClick={handleReturnToLibrary}>
            {t('backToExtensionList', { ns: 'buttons' })}
          </Button>
        </Box>
      </Box>
      {description && (
        <Box className={classes.descBox}>
          <Text>{description}</Text>
        </Box>
      )}
      {descriptionUrl && (
        <Button
          variant="text"
          target="_blank"
          href={descriptionUrl}
          rel="noreferrer"
          endIcon={<LaunchIcon />}
          className={classes.descriptionUrl}
        >
          {t('readMoreOnLink', { ns: 'buttons' })}
        </Button>
      )}
      {details && <MarkdownReader markdown={details} />}
    </>
  );
};

ExtensionDetails.propTypes = {
  extension: PropTypes.object.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  onSubscribe: PropTypes.func.isRequired,
};

export default ExtensionDetails;
