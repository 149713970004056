import { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { find } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import {
  BACKUP_LABELS,
  PERIOD_TYPES,
  BACKUP_PERIOD_TYPE_LABELS,
  BACKUP_TYPES,
  DEFAULT_EXCLUDE_FILES,
  DEFAULT_STANDARD_INCLUDE_FILES,
  DEFAULT_BACKUP_TIME,
  DAYS,
  DEFAULT_TIMEZONE,
  INSTANCE_CATEGORY,
  DEFAULT_COMMUNITY_INCLUDE_FILES,
} from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import { isSolrCloud, sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Loading from '../../elements/loading/Loading';
import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';

// Style
const useStyle = makeStyles((theme) => ({
  iconButton: {
    padding: 0,
    '& .MuiIconButton-root': {
      padding: theme.spacing(0.8),
      fontSize: theme.spacing(1.5),
    },
  },
}));

// component
const ScheduledBackupForm = ({
  data,
  handleCreate,
  handleEdit,
  getPreview,
  getInstanceNodes,
  getInstances,
  getCollections,
  handleModalClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const history = useHistory();
  const { user } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [instances, setInstances] = useState(null);
  const [instanceNodes, setInstanceNodes] = useState(null);
  const [collections, setCollections] = useState(null);
  const [preview, setPreview] = useState(null);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  const toProfilePage = () => history.push('/profile');

  const [selectedDate, setSelectedDate] = useState(
    moment(data?.periodTime || DEFAULT_BACKUP_TIME, 'HH:mm')
  );

  // validation
  const schema = yup.object().shape({
    subject: yup
      .string()
      .max(50, t('maxLength', { ns: 'validations', max: '50' }))
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('backupSubject', { ns: 'fields' }),
          })
        )
      ),
    instance: yup.mixed().test(
      'instanceRequired',
      sentenceCase(
        t('selectRequired', {
          ns: 'validations',
          field: t('instance', { ns: 'instance' }),
        })
      ),
      (value) => (data ? true : !!value)
    ),
    subscript: yup.string().when(['instance', 'backupType'], {
      is: (instance, backupType) =>
        isSolrCloud(instance?.type) && backupType === BACKUP_TYPES.FILE,
      then: yup.string().required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('instanceNode', { ns: 'instance' }),
          })
        )
      ),
      otherwise: yup.string(),
    }),
    backupType: yup.string().required(
      sentenceCase(
        t('selectRequired', {
          ns: 'validations',
          field: t('backupType', { ns: 'fields' }),
        })
      )
    ),
    collectionName: yup.string().when('backupType', {
      is: (backupType) => backupType === BACKUP_TYPES.INDEX && !data,
      then: yup.string().required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('collection', { ns: 'instance' }),
          })
        )
      ),
      otherwise: yup.string(),
    }),
    includeFiles: yup.string(),
    excludeFiles: yup.string(),
    periodType: yup.string().required(
      sentenceCase(
        t('selectRequired', {
          ns: 'validations',
          field: t('backup.schedule', { ns: 'project' }),
        })
      )
    ),
    periodTime: yup.string().required(
      sentenceCase(
        t('selectRequired', {
          ns: 'validations',
          field: t('timePeriod', { ns: 'fields' }),
        })
      )
    ),
    day: yup.string().when('periodType', {
      is: PERIOD_TYPES.WEEK,
      then: yup.string().required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('day', { ns: 'fields' }),
          })
        )
      ),
      otherwise: yup.string(),
    }),
    date: yup.string().when('periodType', {
      is: PERIOD_TYPES.MONTH,
      then: yup.string().required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('date', { ns: 'fields' }),
          })
        )
      ),
      otherwise: yup.string(),
    }),
    updateTimeZone: yup.boolean(),
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      subject: data?.subject || '',
      backupType: data?.category || '',
      excludeFiles: data ? data.excludeFiles : DEFAULT_EXCLUDE_FILES,
      periodType: data?.periodType || '',
      periodTime: data?.periodTime || DEFAULT_BACKUP_TIME,
      day: data && data.periodType === PERIOD_TYPES.WEEK ? data.periodDay : '',
      date:
        data && data.periodType === PERIOD_TYPES.MONTH ? data.periodDay : '',
      historySize: data?.historySize || 1,
      subscript: data?.instanceSubscript || '',
      updateTimeZone: false,
    },
  });

  const { instance, subscript, backupType, periodType } = watch();

  const requireNode = (type) =>
    isSolrCloud(type) && backupType === BACKUP_TYPES.FILE;

  const getInstanceNodesData = async (instanceId) => {
    try {
      const data = await getInstanceNodes(instanceId);
      setInstanceNodes(data);
    } catch (error) {
      setInstanceNodes(null);
    }
  };

  const getInstancesData = async () => {
    try {
      const data = await getInstances();
      setInstances(data);
    } catch (error) {
      setInstances(null);
    }
  };

  const getCollectionData = async () => {
    setIsLoading(true);
    try {
      const data = await getCollections(instance);
      setCollections(data);
    } catch (error) {
      setCollections(null);
    }
    setIsLoading(false);
  };

  const getPreviewData = async () => {
    setIsLoading(true);
    try {
      const formValues = data
        ? { ...getValues(), instance: { id: data.instanceSetId } }
        : getValues();
      const preview = await getPreview(formValues);
      setPreview(preview);
    } catch (error) {
      setPreview(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getInstancesData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!instance) return;

    // reset necessary fields
    setPreview(null);
    [
      'backupType',
      'subscript',
      'collectionName',
      'includeFiles',
      'excludeFiles',
    ].forEach((field) => setValue(field, undefined, { shouldTouch: true }));

    if (isSolrCloud(instance.type)) {
      getInstanceNodesData(instance.id);
    }

    // eslint-disable-next-line
  }, [instance]);

  useEffect(() => {
    if (!subscript) return;

    // reset necessary field
    setPreview(null);

    // eslint-disable-next-line
  }, [subscript]);

  useEffect(() => {
    if (!backupType) return;

    if (backupType === BACKUP_TYPES.INDEX) {
      ['subscript', 'includeFiles', 'excludeFiles'].forEach((field) =>
        trigger(field)
      );
      getCollectionData();
    } else {
      trigger('collectionName');
    }

    // eslint-disable-next-line
  }, [backupType]);

  const onSubmit = async (submitData) => {
    data ? await handleEdit(submitData, data) : await handleCreate(submitData);
    handleModalClose?.();
  };

  const refreshPreview = () => {
    getPreviewData();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="center">
          {/* form field: subject */}
          <Grid item xs={12}>
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('backupSubject', { ns: 'fields' })}
                  error={!!errors.subject}
                  helperText={errors.subject?.message}
                  inputProps={{ 'aria-label': 'subject' }}
                  InputLabelProps={data ? { shrink: true } : {}}
                />
              )}
            />
          </Grid>

          {/* form field: instance */}
          <Grid item xs={12}>
            {data ? (
              <TextField
                fullWidth
                disabled
                defaultValue={data.instanceSetName}
                label={t('instanceToBackup', { ns: 'fields' })}
                InputLabelProps={{ shrink: !!data }}
                inputProps={{ 'aria-label': 'instance name' }}
              />
            ) : (
              <FormControl fullWidth error={!!errors.instance}>
                <InputLabel>
                  {t('instanceToBackup', { ns: 'fields' })}
                </InputLabel>
                <Select
                  {...register('instance')}
                  defaultValue={''}
                  inputProps={{ 'aria-label': 'instance select' }}
                >
                  {instances?.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.instance?.message}</FormHelperText>
              </FormControl>
            )}
          </Grid>
          {/* form field: backup type */}
          <Grid item xs={12}>
            {data ? (
              <Text>
                {t(find(BACKUP_LABELS, { value: data.category }).label, {
                  ns: 'menus',
                })}
              </Text>
            ) : (
              <FormControl fullWidth error={!!errors.backupType}>
                <Text>{t('backupType', { ns: 'fields' })}</Text>
                <Controller
                  name="backupType"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} value={field.value || ''}>
                      <FormHelperText>
                        {errors.backupType?.message}
                      </FormHelperText>
                      {BACKUP_LABELS.map((item) => (
                        <FormControlLabel
                          key={item.value}
                          value={item.value}
                          label={t(item.label, { ns: 'menus' })}
                          control={<Radio disabled={!instance} />}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            )}
          </Grid>
          {/* form field for index backup: collection name */}
          {backupType === BACKUP_TYPES.INDEX && (
            <Grid item xs={12}>
              {data ? (
                <TextField
                  fullWidth
                  disabled
                  defaultValue={data.collection}
                  label={t('collectionToBackup', { ns: 'fields' })}
                  InputLabelProps={{ shrink: !!data }}
                  inputProps={{ 'aria-label': 'collection name' }}
                />
              ) : isLoading ? (
                <Loading />
              ) : (
                <FormControl fullWidth error={!!errors.collectionName}>
                  <InputLabel>
                    {t('collectionToBackup', { ns: 'fields' })}
                  </InputLabel>
                  <Controller
                    name="collectionName"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputProps={{ 'aria-label': 'collection select' }}
                      >
                        {collections?.map((collection) => (
                          <MenuItem key={collection} value={collection}>
                            {collection}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText>
                    {errors.collectionName?.message}
                  </FormHelperText>
                </FormControl>
              )}
            </Grid>
          )}
          {/* form field: instance node */}
          {requireNode(data ? data.instanceSetType : instance?.type) && (
            <Grid item xs={12}>
              {data ? (
                <TextField
                  fullWidth
                  disabled
                  defaultValue={`${data.instanceSetName}-${data.instanceSubscript}`}
                  label={t('instanceNodeToBackup', { ns: 'fields' })}
                  InputLabelProps={{ shrink: !!data }}
                  inputProps={{ 'aria-label': 'instance node' }}
                />
              ) : (
                <FormControl fullWidth error={!!errors.subscript}>
                  <InputLabel>
                    {t('instanceNodeToBackup', { ns: 'fields' })}
                  </InputLabel>
                  <Controller
                    name="subscript"
                    control={control}
                    defaultValue={'all'}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputProps={{ 'aria-label': 'instance node select' }}
                      >
                        {instanceNodes?.map((node) => (
                          <MenuItem key={node.id} value={node.subscriptNumber}>
                            {node.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText>{errors.subscript?.message}</FormHelperText>
                </FormControl>
              )}
            </Grid>
          )}
          {/* form field for file backup: included and excluded files */}
          {backupType === BACKUP_TYPES.FILE && (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6}>
                <Controller
                  name="includeFiles"
                  control={control}
                  defaultValue={
                    data
                      ? data?.includeFiles
                      : instance?.category === INSTANCE_CATEGORY.COMMUNITY
                      ? DEFAULT_COMMUNITY_INCLUDE_FILES
                      : DEFAULT_STANDARD_INCLUDE_FILES
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      rows={5}
                      label={t('includedFiles', { ns: 'fields' })}
                      variant="outlined"
                      error={!!errors?.includeFiles}
                      helperText={errors?.includeFiles?.message}
                      inputProps={{ 'aria-label': 'include files' }}
                      fullWidth
                      multiline
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="excludeFiles"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      rows={5}
                      label={t('excludedFiles', { ns: 'fields' })}
                      variant="outlined"
                      error={!!errors?.excludeFiles}
                      helperText={errors?.excludeFiles?.message}
                      inputProps={{ 'aria-label': 'exclude files' }}
                      fullWidth
                      multiline
                    />
                  )}
                />
              </Grid>

              {/* file preview */}
              <Grid item xs={12}>
                <Box textAlign="right" mb={1}>
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<RefreshIcon />}
                    onClick={refreshPreview}
                    aria-label="refresh preview"
                    disabled={
                      isLoading ||
                      (requireNode(
                        data ? data.instanceSetType : instance?.type
                      ) &&
                        !subscript)
                    }
                  >
                    {t('refreshPreviewFile', { ns: 'buttons' })}
                  </Button>
                </Box>
                <Card
                  variant="outlined"
                  aria-label="preview card"
                  style={{ maxHeight: 165, overflow: 'auto' }}
                >
                  <Box p={1}>
                    {isLoading ? (
                      <Loading />
                    ) : preview !== null ? (
                      <Text style={{ whiteSpace: 'pre-wrap' }}>
                        {preview || t('noPreviewFiles', { ns: 'descriptions' })}
                      </Text>
                    ) : (
                      <Text caption>
                        {t('showPreviewFiles', { ns: 'descriptions' })}
                      </Text>
                    )}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* Schedule */}
        <Box mt={2}>
          <Text>{t('backup.schedule', { ns: 'project' })}</Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl error={!!errors.periodType}>
              <Controller
                name="periodType"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} value={field.value || ''} row>
                    {BACKUP_PERIOD_TYPE_LABELS.map((type) => (
                      <FormControlLabel
                        key={type.value}
                        value={type.value}
                        control={<Radio />}
                        label={t(type.label, { ns: 'menus' })}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
              <FormHelperText>{errors.periodType?.message}</FormHelperText>
            </FormControl>
          </Grid>
          {!handleEdit && (
            <Grid item xs={12}>
              <Text>
                <Trans
                  i18nKey="userTimeZone"
                  ns="descriptions"
                  components={[<strong key="0" />]}
                  values={{
                    timeZone: user?.timeZone || DEFAULT_TIMEZONE,
                  }}
                />
              </Text>
              <Trans
                i18nKey="changeTimeZone"
                ns="descriptions"
                components={[
                  <Link
                    key="0"
                    component="button"
                    type="button"
                    style={{ paddingBottom: '0.25em' }}
                    variant="body1"
                    onClick={toggleModal}
                    aria-label="user profile"
                  />,
                ]}
              />
            </Grid>
          )}
          <Grid container spacing={5} item xs={12}>
            <Grid item xs={12} sm={6}>
              {periodType && (
                <Controller
                  name="periodTime"
                  control={control}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        ampm={false}
                        minutesStep={10}
                        label={t('timePeriod', { ns: 'fields' })}
                        mask="__:__"
                        value={selectedDate}
                        onChange={(date) => {
                          setSelectedDate(date || '');
                          onChange(date ? moment(date).format('HH:mm') : '');
                        }}
                        inputProps={{ 'aria-label': 'time-input' }}
                        KeyboardButtonProps={{ 'aria-label': 'change time' }}
                        okLabel={t('ok', { ns: 'buttons' })}
                        cancelLabel={t('cancel', { ns: 'buttons' })}
                        error={!!error}
                        helperText={error ? error.message : null}
                        className={classes.iconButton}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {periodType === PERIOD_TYPES.WEEK && (
                <FormControl fullWidth error={!!errors.day}>
                  <InputLabel>{t('day', { ns: 'fields' })}</InputLabel>
                  <Controller
                    name="day"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputProps={{ 'aria-label': 'day-input' }}
                      >
                        {DAYS.map((day) => (
                          <MenuItem key={day.value} value={day.value}>
                            {t(day.label, { ns: 'menus' })}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText>{errors.day?.message}</FormHelperText>
                </FormControl>
              )}
              {periodType === PERIOD_TYPES.MONTH && (
                <FormControl fullWidth error={!!errors.date}>
                  <InputLabel>{t('date', { ns: 'fields' })}</InputLabel>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputProps={{ 'aria-label': 'date-input' }}
                      >
                        {[...Array(31).keys()].map((date) => (
                          <MenuItem key={date} value={date + 1}>
                            {date + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText>{errors.date?.message}</FormHelperText>
                </FormControl>
              )}
            </Grid>
          </Grid>
          {handleEdit && (
            <Grid item xs={12}>
              <FormControlLabel
                label={
                  <Trans
                    i18nKey="updateTimeZone"
                    ns="fields"
                    components={[<strong key="0" />]}
                    values={{
                      zone: user?.timeZone,
                    }}
                  />
                }
                control={
                  <Controller
                    name="updateTimeZone"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        inputProps={{ 'aria-label': 'update timezone' }}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
          )}
        </Grid>

        <Box display="flex" alignItems="center" mt={1}>
          <Text>{t('numberToKeep', { ns: 'fields' })}: </Text>
          <Box style={{ minWidth: 100, marginLeft: 16 }}>
            <FormControl fullWidth error={!!errors.historySize}>
              <Controller
                name="historySize"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    inputProps={{ 'aria-label': 'number to keep' }}
                  >
                    {[...Array(50).keys()].map((item) => (
                      <MenuItem key={item} value={item + 1}>
                        {item + 1}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>{errors.historySize?.message}</FormHelperText>
            </FormControl>
          </Box>
        </Box>

        <Box textAlign="center" my={2}>
          <Button type="submit">
            {data
              ? t('updateBackup', { ns: 'buttons' })
              : t('createBackup', { ns: 'buttons' })}
          </Button>
        </Box>
      </form>

      <Modal
        openModal={modal}
        title={t('warning', { ns: 'general' })}
        showActions
        handleClose={toggleModal}
        handleConfirm={toProfilePage}
      >
        {t('loseAllData', { ns: 'descriptions' })}
      </Modal>
    </>
  );
};

const isRequired = ({ props, propName, isEdit = false }) => {
  const condition = isEdit
    ? props.data !== undefined
    : props.data === undefined;

  if (condition && typeof props[propName] !== 'function') {
    return new Error(`${propName} function is required`);
  }
};

ScheduledBackupForm.propTypes = {
  data: PropTypes.object,
  handleCreate: (props, propName) => isRequired({ props, propName }),
  getInstances: (props, propName) => isRequired({ props, propName }),
  getInstanceNodes: (props, propName) => isRequired({ props, propName }),
  getCollections: (props, propName) => isRequired({ props, propName }),
  handleEdit: (props, propName) =>
    isRequired({ props, propName, isEdit: true }),
  getPreview: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func,
};

export default ScheduledBackupForm;
