import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { MAX_FILE_NUMBER, TEMP_FILE_SIZE } from '../../../constants';
import {
  fileNotJsonOrCsv,
  fileTooBig,
  formatBytes,
  sentenceCase,
} from '../../../utils/utils';
import Button from '../../elements/button/Button';
import FileUpload from '../file-upload/FileUpload';

const useStyles = makeStyles({
  noPadding: {
    padding: 0,
  },
  noBottomPadding: {
    paddingBottom: 0,
  },
});

const CreateDictionary = ({ handleCreateDictionary }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [tab, setTab] = useState('0');
  const [files, setFiles] = useState([]);
  const [invalidAttributes, setInvalidAttributes] = useState({
    size: false,
    format: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleTabChange = (_, newValue) => setTab(newValue);

  const openModal = () => setOpenCreateModal(true);
  const closeModal = () => {
    setOpenCreateModal(false);
    setFiles([]);
    setInvalidAttributes({
      size: false,
      format: false,
    });
    setTab('0');
  };

  const schema = yup.object().shape({
    name: yup.string().required(
      sentenceCase(
        t('inputRequired', {
          ns: 'validations',
          field: t('synonym.dictionaryName', { ns: 'fields' }),
        })
      )
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const onCreateDictionary = async (data) => {
    setIsSubmitting(true);
    await handleCreateDictionary({ ...data, file: files[0] });
    setIsSubmitting(false);
    closeModal();
  };

  const invalidForm = () => {
    if (tab === '0') return !isValid;
    if (tab === '1') {
      return (
        !isValid ||
        isEmpty(files) ||
        Object.values(invalidAttributes).some((v) => v)
      );
    }
  };

  const removeFile = (fileIndex) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(fileIndex), 1);
    setFiles(newFiles);
  };

  const onDrop = (acceptedFiles) => {
    if (isEmpty(acceptedFiles) || files.length >= MAX_FILE_NUMBER) return;

    const invalidSize = fileTooBig(acceptedFiles[0].size, TEMP_FILE_SIZE.BYTES);
    const invalidFormat = fileNotJsonOrCsv(acceptedFiles[0].type);

    if (!invalidSize && !invalidFormat) {
      setFiles([...files, ...acceptedFiles]);
    }

    setInvalidAttributes({
      size: invalidSize,
      format: invalidFormat,
    });
  };

  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <Button
        id="new-dictionary"
        onClick={openModal}
        aria-label="new dictionary"
      >
        {t('add', {
          ns: 'buttons',
          text: t('synonym.dictionary', { ns: 'project' }),
        })}
      </Button>

      <Dialog open={openCreateModal} fullWidth>
        <TabContext value={tab}>
          <TabList
            onChange={handleTabChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label={t('createNew', { ns: 'instance' })} value="0" />
            <Tab label={t('createByImport', { ns: 'instance' })} value="1" />
          </TabList>
          <DialogTitle className={classes.noBottomPadding}>
            {t('addition', {
              ns: 'project',
              text: t('synonym.dictionary', { ns: 'project' }),
            })}
          </DialogTitle>
          <form onSubmit={handleSubmit(onCreateDictionary)}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ padding: 0 }}>
                  <TabPanel value="0" className={classes.noPadding} />
                  <TabPanel value="1" className={classes.noPadding}>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <FileUpload
                        files={files}
                        open={open}
                        handleRemove={removeFile}
                        formatError={invalidAttributes.format}
                        sizeError={invalidAttributes.size}
                        sizeLimit={formatBytes(TEMP_FILE_SIZE.BYTES, 0)}
                        helperText={t('dropOneJsonOrCsvFile', {
                          ns: 'instance',
                        })}
                        fileErrorMsg={t('onlyJsonCsvFile', {
                          ns: 'descriptions',
                        })}
                      />
                      <input {...getInputProps()} aria-label="upload file" />
                    </div>
                  </TabPanel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('name')}
                    id="name"
                    fullWidth
                    autoFocus
                    autoComplete="off"
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                    label={t('synonym.dictionaryName', { ns: 'fields' })}
                    inputProps={{ 'aria-label': 'dictionary name' }}
                    defaultValue={''}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box mx={2} mb={1}>
                <Button
                  color="default"
                  variant="text"
                  type="reset"
                  onClick={closeModal}
                >
                  {t('cancel', { ns: 'buttons' })}
                </Button>
                {isSubmitting ? (
                  <Button variant="text" disabled>
                    {t('create', { ns: 'buttons' })}
                    <CircularProgress size={15} style={{ marginLeft: 10 }} />
                  </Button>
                ) : (
                  <Button variant="text" disabled={invalidForm()} type="submit">
                    {t('create', { ns: 'buttons' })}
                  </Button>
                )}
              </Box>
            </DialogActions>
          </form>
        </TabContext>
      </Dialog>
    </>
  );
};

CreateDictionary.propTypes = {
  handleCreateDictionary: PropTypes.func,
};

export default CreateDictionary;
