import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { formatMonthNum } from '../../../../utils/timeUtils';
import { sentenceCase } from '../../../../utils/utils';
import Modal from '../../../sections/modal/Modal';
import ModalButtons from '../../../sections/modal/ModalButtons';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const UpdateCardModal = ({ card, visible, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('creditCardHolder', { ns: 'fields' }),
          })
        )
      )
      .max(50, t('maxLength', { ns: 'validations', max: '50' })),
    expDate: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('creditCardExpiration', { ns: 'fields' }),
          })
        )
      )
      .matches(
        /^(0[1-9]|1[0-2])\/([2-9][0-9])$/,
        sentenceCase(
          `${t('invalidFormat', {
            ns: 'validations',
            field: t('creditCardExpiration', { ns: 'fields' }),
          })} ${t('example', { ns: 'validations', example: '09/23' })}`
        )
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const confirmUpdate = (data) => {
    const payload = {
      name: data.name,
      expireMonth: data.expDate.slice(0, 2),
      expireYear: `20${data.expDate.slice(3)}`,
    };

    onConfirm(payload);
    onClose();
  };

  const closeUpdate = () => onClose();

  return (
    <Modal
      openModal={visible}
      title={t('update', {
        ns: 'project',
        text: t('billing.creditCardInfo', { ns: 'project' }),
      })}
      handleClose={onClose}
    >
      <form onSubmit={handleSubmit(confirmUpdate)}>
        <Controller
          name="name"
          control={control}
          defaultValue={card?.name || ''}
          render={({ field }) => (
            <TextField
              {...field}
              id="name"
              className={classes.textField}
              label={t('creditCardHolder', { ns: 'fields' })}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              fullWidth
              inputProps={{ maxLength: 50 }}
            />
          )}
        />

        <Controller
          name="expDate"
          control={control}
          defaultValue={`${formatMonthNum(card?.expireMonth)}/${card?.expireYear
            .toString()
            .slice(2)}`}
          render={({ field }) => (
            <TextField
              {...field}
              id="exp-date"
              className={classes.textField}
              label={`${t('creditCardExpiration', { ns: 'fields' })}${t(
                'billing.expExample',
                { ns: 'project' }
              )}`}
              error={!!errors?.expDate}
              helperText={errors?.expDate?.message}
              fullWidth
              inputProps={{ maxLength: 5 }}
            />
          )}
        />
        <ModalButtons
          onCancel={closeUpdate}
          cancelProps={{ type: 'reset' }}
          actionText={t('confirm', { ns: 'buttons' })}
          actionProps={{ type: 'submit', disabled: !isDirty || !isValid }}
        />
      </form>
    </Modal>
  );
};

UpdateCardModal.propTypes = {
  card: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default UpdateCardModal;
