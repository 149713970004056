import { Box, Chip, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { INSTANCE_CATEGORY, PLAN_LIST } from '../../../constants';

const useStyles = makeStyles(() => ({
  legacy: {
    color: 'white',
    backgroundColor: '#BDBDBD',
    fontWeight: 'bold',
  },
  community: {
    color: 'white',
    backgroundColor: '#505050',
    fontWeight: 'bold',
  },
  standard: {
    color: 'white',
    backgroundColor: '#0c63a6',
    fontWeight: 'bold',
  },
  enterprise: {
    color: 'white',
    backgroundColor: '#fdc60e',
    fontWeight: 'bold',
  },
}));

const InstanceChip = ({ category, plan, ...restProps }) => {
  const classes = useStyles();

  const selectedPlan = () => {
    switch (category) {
      case INSTANCE_CATEGORY.COMMUNITY:
        return INSTANCE_CATEGORY.COMMUNITY;
      case INSTANCE_CATEGORY.STANDARD:
        return PLAN_LIST.slice(1).find((pl) => {
          return pl.type === plan;
        }).name;
      case INSTANCE_CATEGORY.ENTERPRISE:
        return INSTANCE_CATEGORY.ENTERPRISE;
      default:
        return INSTANCE_CATEGORY.LEGACY;
    }
  };

  return (
    <Box {...restProps}>
      <Chip
        size="small"
        label={selectedPlan().toUpperCase()}
        className={classes[category.toLowerCase()]}
      />
    </Box>
  );
};

InstanceChip.propTypes = {
  category: PropTypes.string.isRequired,
  plan: PropTypes.string.isRequired,
};

export default InstanceChip;
