import AssessmentIcon from '@material-ui/icons/Assessment';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import BugReportIcon from '@material-ui/icons/BugReport';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import DvrIcon from '@material-ui/icons/Dvr';
import ExtensionIcon from '@material-ui/icons/Extension';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FolderOpenRoundedIcon from '@material-ui/icons/FolderOpenRounded';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ImportContactsRoundedIcon from '@material-ui/icons/ImportContactsRounded';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import PersonIcon from '@material-ui/icons/Person';
import RestoreIcon from '@material-ui/icons/Restore';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsSystemDaydreamIcon from '@material-ui/icons/SettingsSystemDaydream';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';

import { PRIVATE_ROUTE, buildPath } from '../../../routes/routes';
import {
  SIDEBAR_TYPE as TYPE,
  PROJECT_SIDEBAR_ITEMS,
  INSTANCE_SIDEBAR_ITEMS,
  USER_ITEMS,
  GPS_SIDEBAR_ITEMS,
  MODEL_SIDEBAR_ITEMS,
} from './constants';

export const getSidebarItems = (
  type,
  { projectId, instanceId, gpsId, modelId }
) => {
  switch (type) {
    case TYPE.PROJECT:
      return [
        {
          name: PROJECT_SIDEBAR_ITEMS.OVERVIEW,
          path: buildPath({
            path: PRIVATE_ROUTE.PROJECT_OVERVIEW,
            params: { projectId },
          }),
          icon: <ImportContactsRoundedIcon />,
          requireAdmin: false,
        },
        {
          name: PROJECT_SIDEBAR_ITEMS.MEMBER,
          path: buildPath({
            path: PRIVATE_ROUTE.PROJECT_MEMBER,
            params: { projectId },
          }),
          icon: <PeopleAltRoundedIcon />,
          requireAdmin: false,
        },
        {
          name: PROJECT_SIDEBAR_ITEMS.BACKUP,
          path: buildPath({
            path: PRIVATE_ROUTE.PROJECT_BACKUP,
            params: { projectId },
          }),
          icon: <BackupOutlinedIcon />,
          requireAdmin: false,
        },
        {
          name: PROJECT_SIDEBAR_ITEMS.RESTORE,
          path: buildPath({
            path: PRIVATE_ROUTE.PROJECT_RESTORE,
            params: { projectId },
          }),
          icon: <RestoreIcon />,
          requireAdmin: false,
        },
        {
          name: PROJECT_SIDEBAR_ITEMS.CRAWLER,
          path: buildPath({
            path: PRIVATE_ROUTE.PROJECT_CRAWLER,
            params: { projectId },
          }),
          icon: <BugReportIcon />,
          requireAdmin: false,
        },
        {
          name: PROJECT_SIDEBAR_ITEMS.PROJECT_BILLING,
          path: buildPath({
            path: PRIVATE_ROUTE.PROJECT_BILLING,
            params: { projectId },
          }),
          icon: <CreditCardIcon />,
          requireAdmin: true,
        },
        {
          name: PROJECT_SIDEBAR_ITEMS.SUBS_EXTENSIONS,
          path: buildPath({
            path: PRIVATE_ROUTE.PROJECT_SUBS_EXTENSIONS,
            params: { projectId },
          }),
          icon: <ExtensionIcon />,
          requireAdmin: false,
        },
        {
          name: PROJECT_SIDEBAR_ITEMS.SYNONYM_DICTIONARIES,
          path: buildPath({
            path: PRIVATE_ROUTE.PROJECT_SYNONYM_DICTIONARIES,
            params: { projectId },
          }),
          icon: <ImportContactsIcon />,
          requireAdmin: false,
        },
        {
          name: PROJECT_SIDEBAR_ITEMS.SETTINGS,
          path: buildPath({
            path: PRIVATE_ROUTE.PROJECT_SETTINGS,
            params: { projectId },
          }),
          icon: <SettingsIcon />,
          requireAdmin: true,
        },
      ];
    case TYPE.INSTANCE:
      return [
        {
          name: INSTANCE_SIDEBAR_ITEMS.OVERVIEW,
          path: buildPath({
            path: PRIVATE_ROUTE.INSTANCE_OVERVIEW,
            params: { projectId, instanceId },
          }),
          icon: <ImportContactsRoundedIcon />,
          requireAdmin: false,
          community: true,
        },
        {
          name: INSTANCE_SIDEBAR_ITEMS.COLLECTION,
          path: buildPath({
            path: PRIVATE_ROUTE.INSTANCE_COLLECTIONS,
            params: { projectId, instanceId },
          }),
          icon: <DnsRoundedIcon />,
          requireAdmin: false,
          community: true,
        },
        {
          name: INSTANCE_SIDEBAR_ITEMS.CONFIG,
          path: buildPath({
            path: PRIVATE_ROUTE.INSTANCE_CONFIG,
            params: { projectId, instanceId },
          }),
          icon: <SettingsSystemDaydreamIcon />,
          requireAdmin: false,
          community: true,
        },
        {
          name: INSTANCE_SIDEBAR_ITEMS.FILE_MANAGER,
          path: buildPath({
            path: PRIVATE_ROUTE.INSTANCE_FILE_MANAGER,
            params: { projectId, instanceId },
          }),
          icon: <FolderOpenRoundedIcon />,
          requireAdmin: false,
          community: true,
        },
        {
          name: INSTANCE_SIDEBAR_ITEMS.SEARCH,
          path: buildPath({
            path: PRIVATE_ROUTE.INSTANCE_SEARCH,
            params: { projectId, instanceId },
          }),
          icon: <FindInPageIcon />,
          requireAdmin: false,
          community: true,
        },
        {
          name: INSTANCE_SIDEBAR_ITEMS.HEALTH_CHECK,
          path: buildPath({
            path: PRIVATE_ROUTE.INSTANCE_HEALTH_CHECK,
            params: { projectId, instanceId },
          }),
          icon: <LocalHospitalIcon />,
          requireAdmin: false,
          community: false,
        },
        {
          name: INSTANCE_SIDEBAR_ITEMS.MONITOR,
          path: buildPath({
            path: PRIVATE_ROUTE.INSTANCE_MONITORING,
            params: { projectId, instanceId },
          }),
          icon: <AssessmentIcon />,
          requireAdmin: false,
          community: false,
        },
        {
          name: INSTANCE_SIDEBAR_ITEMS.SETTINGS,
          path: buildPath({
            path: PRIVATE_ROUTE.INSTANCE_SETTINGS,
            params: { projectId, instanceId },
          }),
          icon: <SettingsIcon />,
          requireAdmin: true,
          community: false,
        },
      ];
    case TYPE.GPS:
      return [
        {
          name: GPS_SIDEBAR_ITEMS.OVERVIEW,
          path: buildPath({
            path: PRIVATE_ROUTE.GPS_OVERVIEW,
            params: { projectId, gpsId },
          }),
          icon: <ImportContactsRoundedIcon />,
        },
        {
          name: GPS_SIDEBAR_ITEMS.JOBS,
          path: buildPath({
            path: PRIVATE_ROUTE.GPS_JOBS,
            params: { projectId, gpsId },
          }),
          icon: <DvrIcon />,
        },
        {
          name: GPS_SIDEBAR_ITEMS.FILE_MANAGER,
          path: buildPath({
            path: PRIVATE_ROUTE.GPS_FILE_MANAGER,
            params: { projectId, gpsId },
          }),
          icon: <FolderOpenRoundedIcon />,
        },
        {
          name: GPS_SIDEBAR_ITEMS.SETTINGS,
          path: buildPath({
            path: PRIVATE_ROUTE.GPS_SETTINGS,
            params: { projectId, gpsId },
          }),
          requireAdmin: true,
          icon: <SettingsIcon />,
        },
      ];
    case TYPE.MODEL:
      return [
        {
          name: MODEL_SIDEBAR_ITEMS.OVERVIEW,
          path: buildPath({
            path: PRIVATE_ROUTE.MODEL_OVERVIEW,
            params: { projectId, modelId },
          }),
          icon: <ImportContactsRoundedIcon />,
        },
        {
          name: MODEL_SIDEBAR_ITEMS.SETTINGS,
          path: buildPath({
            path: PRIVATE_ROUTE.MODEL_SETTINGS,
            params: { projectId, modelId },
          }),
          requireAdmin: true,
          icon: <SettingsIcon />,
        },
      ];
    case TYPE.USER:
      return [
        {
          name: USER_ITEMS.PROFILE,
          path: PRIVATE_ROUTE.USER_PROFILE,
          icon: <PersonIcon />,
          requireAdmin: false,
        },
        {
          name: USER_ITEMS.ACCOUNT,
          path: PRIVATE_ROUTE.USER_ACCOUNT,
          icon: <VerifiedUserRoundedIcon />,
          requireAdmin: false,
        },
      ];
    default:
      return [];
  }
};
