import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import DraggableListItem from './DraggableListItem';

const DraggableList = ({ items, onDragEnd, onRemoveWord }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DraggableListItem
                item={item}
                index={index}
                key={item.id}
                onRemoveWord={onRemoveWord}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DraggableList.propTypes = {
  items: PropTypes.array,
  onDragEnd: PropTypes.func.isRequired,
  onRemoveWord: PropTypes.func.isRequired,
};

export default DraggableList;
