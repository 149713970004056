import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TIMEZONE_LIST } from '../../../../constants';
import { CRAWLER_PERIOD_TYPES } from '../../../../constants/crawlerConstants';
import { getTime } from '../../../../utils/timeUtils';
import Text from '../../../elements/text/Text';
import NoContent from '../../../sections/no-content/NoContent';
import { displayJobType, getScheduledDay, tableDisplay } from '../crawlerUtils';
import ArgumentsTable from './ArgumentsTable';
import CollapsibleRow from './CollapsibleRow';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  sectionWrapper: {
    padding: '1em',
    marginBottom: '1em',
    backgroundColor: theme.palette.action.hover,
    borderRadius: '5px',
  },
  seedWrapper: {
    padding: '0.25em 0.75em',
    marginBottom: '0.5em',
    backgroundColor: theme.palette.action.selected,
    borderRadius: '5px',
  },
  expandSection: {
    backgroundColor: theme.palette.grey[100],
    whiteSpace: 'pre-wrap',
    padding: '0.5em 1em',
  },
}));

const CrawlerJobDetails = ({ job }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const data = job.data;
  const args = job.data.arguments;

  const formatSchedule = (schedule) => {
    if (!schedule) return t('frequency.none', { ns: 'menus' });

    const jobPeriod = find(CRAWLER_PERIOD_TYPES, {
      value: schedule.period,
    });

    const time = getTime(schedule.time);
    const day = getScheduledDay(schedule.period, schedule?.day);
    const zone = TIMEZONE_LIST.find(
      ({ zoneId }) => zoneId === schedule.zone
    ).label;

    return i18n.language === 'ja'
      ? `${t(jobPeriod.label, { ns: 'menus' })}${day} ${time} ${zone}`
      : `${t(jobPeriod.label, { ns: 'menus' })} ${day} ${time} ${zone}`;
  };

  return job ? (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {tableDisplay(t('jobName', { ns: 'fields' }), job.name)}
          {tableDisplay(t('jobDescription', { ns: 'fields' }), job.description)}
          {tableDisplay(
            t('stepper.jobType', { ns: 'crawler' }),
            displayJobType(job.type)
          )}
          {tableDisplay('Crawl Schedule', formatSchedule(data?.schedule))}
          {tableDisplay(t('user', { ns: 'fields' }), data.email)}
          <CollapsibleRow rowHeader={t('seeds', { ns: 'fields' })} colSpan={2}>
            <Box className={classes.expandSection} mb={1}>
              {data.seeds.map((seed) => (
                <Text key={seed}>{seed}</Text>
              ))}
            </Box>
          </CollapsibleRow>
          <CollapsibleRow
            rowHeader={t('additionalSeeds', { ns: 'fields' })}
            colSpan={2}
          >
            {data.subseeds ? (
              <Box className={classes.expandSection} mb={1}>
                {data.subseeds.map((seed) => (
                  <Text key={seed}>{seed}</Text>
                ))}
              </Box>
            ) : (
              <Box mb={1}>
                <NoContent
                  title={t('noFound', {
                    ns: 'descriptions',
                    text: t('additionalSeeds', { ns: 'fields' }),
                  })}
                />
              </Box>
            )}
          </CollapsibleRow>
          {tableDisplay(
            t('instance', { ns: 'instance' }),
            job.solrInstanceSet.name
          )}
          {tableDisplay(t('collection', { ns: 'instance' }), job.collection)}
          {tableDisplay(t('uniqueKey', { ns: 'fields' }), data.idField)}
          {['updateHandler', 'removeHandler', 'statusHandler'].map((field) =>
            tableDisplay(t(field, { ns: 'fields' }), data[field], field)
          )}
          {tableDisplay(t('commitTime', { ns: 'fields' }), data.commitWithin)}
          {tableDisplay(
            t('commitAfterJob', { ns: 'fields' }),
            data.commitOnComplete ? 'True' : 'False'
          )}
          <CollapsibleRow
            rowHeader={t('arguments', { ns: 'crawler' })}
            colSpan={2}
          >
            <ArgumentsTable args={args} />
          </CollapsibleRow>
          {[
            ['includes', 'includeInCrawl'],
            ['includesIndex', 'includeInIndex'],
            ['excludes', 'excludeFromCrawl'],
            ['excludesIndex', 'excludeFromIndex'],
            ['excludesContentIndex', 'excludeContentFromIndex'],
          ].map((field) => (
            <CollapsibleRow
              rowHeader={t(field[1], { ns: 'fields' })}
              colSpan={2}
              key={field[0]}
            >
              {data[field[0]] ? (
                <Box className={classes.expandSection} mb={1}>
                  {data[field[0]]}
                </Box>
              ) : (
                <Box mb={1}>
                  <NoContent
                    title={t('noFound', {
                      ns: 'descriptions',
                      text: t(field[1], { ns: 'fields' }),
                    })}
                  />
                </Box>
              )}
            </CollapsibleRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <NoContent title={t('noCrawlJobSelected', { ns: 'descriptions' })} />
  );
};

CrawlerJobDetails.propTypes = {
  job: PropTypes.object,
};

export default CrawlerJobDetails;
