import { instance, getToken } from './config';

const getSynonymDictionaries = (projectId) =>
  instance.get(`/projects/${projectId}/sds`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const createSynonymDictionary = (projectId, data) =>
  instance.post(`/projects/${projectId}/sds`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const getSynonymDictionary = (projectId, dictionaryId) =>
  instance.get(`/projects/${projectId}/sds/${dictionaryId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateSynonymDictionary = (projectId, dictionaryId, data) =>
  instance.put(`/projects/${projectId}/sds/${dictionaryId}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteSynonymDictionary = (projectId, dictionaryId) =>
  instance.delete(`/projects/${projectId}/sds/${dictionaryId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const importSynonymDictionary = (projectId, dictionaryId, data, options) =>
  instance.post(`/projects/${projectId}/sds/${dictionaryId}/import`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: options,
    timeout: 150000,
  });

const exportSynonymDictionary = (projectId, dictionaryId, options) =>
  instance.post(`/projects/${projectId}/sds/${dictionaryId}/export`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: options,
    timeout: 150000,
    responseType: 'arraybuffer',
  });

const deploySynonymDictionary = (projectId, dictionaryId, data, params) =>
  instance.post(`/projects/${projectId}/sds/${dictionaryId}/deploy`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params,
    timeout: 150000,
  });

const getManagedResources = (projectId, instanceId, collection) =>
  instance.get(`/projects/${projectId}/sds/resources`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { instanceId, collection },
  });

const getInitArgs = (projectId, instanceId, collection, resource) =>
  instance.get(`/projects/${projectId}/sds/init-args`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { instanceId, collection, resource },
  });

const getSynonymSets = (
  projectId,
  dictionaryId,
  page = 0,
  size = 10,
  sort = 'number,asc'
) =>
  instance.get(`/projects/${projectId}/sds/${dictionaryId}/sdes`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { page, size, sort },
  });

const createSynonymSet = (projectId, dictionaryId, data) =>
  instance.post(`/projects/${projectId}/sds/${dictionaryId}/sdes`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateSynonymSet = (projectId, dictionaryId, synonymSetId, data) =>
  instance.put(
    `/projects/${projectId}/sds/${dictionaryId}/sdes/${synonymSetId}`,
    data,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const deleteSynonymSet = (projectId, dictionaryId, synonymSetId) =>
  instance.delete(
    `/projects/${projectId}/sds/${dictionaryId}/sdes/${synonymSetId}`,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const searchSynonym = (
  projectId,
  dictionaryId,
  value,
  exact,
  page = 0,
  size = 10,
  sort = 'number,asc'
) =>
  instance.post(
    `/projects/${projectId}/sds/${dictionaryId}/sdes/search`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: { value, exact, page, size, sort },
    }
  );

export const synonymApis = {
  getSynonymDictionaries,
  createSynonymDictionary,
  getSynonymDictionary,
  updateSynonymDictionary,
  deleteSynonymDictionary,
  importSynonymDictionary,
  exportSynonymDictionary,
  deploySynonymDictionary,
  getManagedResources,
  getInitArgs,
  getSynonymSets,
  createSynonymSet,
  updateSynonymSet,
  deleteSynonymSet,
  searchSynonym,
};
