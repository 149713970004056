export const DEFAULT_TIMEZONE = 'Asia/Tokyo';

// A list of timezone matching ZoneId
export const TIMEZONE_LIST = [
  { label: '(UTC-12:00) Etc/GMT+12', zoneId: 'Etc/GMT+12' },
  { label: '(UTC-11:00) Pacific/Pago Pago', zoneId: 'Pacific/Pago_Pago' },
  { label: '(UTC-11:00) Pacific/Samoa', zoneId: 'Pacific/Samoa' },
  { label: '(UTC-11:00) Pacific/Niue', zoneId: 'Pacific/Niue' },
  { label: '(UTC-11:00) US/Samoa', zoneId: 'US/Samoa' },
  { label: '(UTC-11:00) Etc/GMT+11', zoneId: 'Etc/GMT+11' },
  { label: '(UTC-11:00) Pacific/Midway', zoneId: 'Pacific/Midway' },
  { label: '(UTC-10:00) Pacific/Honolulu', zoneId: 'Pacific/Honolulu' },
  { label: '(UTC-10:00) Pacific/Rarotonga', zoneId: 'Pacific/Rarotonga' },
  { label: '(UTC-10:00) Pacific/Tahiti', zoneId: 'Pacific/Tahiti' },
  { label: '(UTC-10:00) Pacific/Johnston', zoneId: 'Pacific/Johnston' },
  { label: '(UTC-10:00) US/Hawaii', zoneId: 'US/Hawaii' },
  { label: '(UTC-10:00) SystemV/HST10', zoneId: 'SystemV/HST10' },
  { label: '(UTC-10:00) Etc/GMT+10', zoneId: 'Etc/GMT+10' },
  { label: '(UTC-09:30) Pacific/Marquesas', zoneId: 'Pacific/Marquesas' },
  { label: '(UTC-09:00) Etc/GMT+9', zoneId: 'Etc/GMT+9' },
  { label: '(UTC-09:00) Pacific/Gambier', zoneId: 'Pacific/Gambier' },
  { label: '(UTC-09:00) America/Atka', zoneId: 'America/Atka' },
  { label: '(UTC-09:00) SystemV/YST9', zoneId: 'SystemV/YST9' },
  { label: '(UTC-09:00) America/Adak', zoneId: 'America/Adak' },
  { label: '(UTC-09:00) US/Aleutian', zoneId: 'US/Aleutian' },
  { label: '(UTC-08:00) Etc/GMT+8', zoneId: 'Etc/GMT+8' },
  { label: '(UTC-08:00) US/Alaska', zoneId: 'US/Alaska' },
  { label: '(UTC-08:00) America/Juneau', zoneId: 'America/Juneau' },
  { label: '(UTC-08:00) America/Metlakatla', zoneId: 'America/Metlakatla' },
  { label: '(UTC-08:00) America/Yakutat', zoneId: 'America/Yakutat' },
  { label: '(UTC-08:00) Pacific/Pitcairn', zoneId: 'Pacific/Pitcairn' },
  { label: '(UTC-08:00) America/Sitka', zoneId: 'America/Sitka' },
  { label: '(UTC-08:00) America/Anchorage', zoneId: 'America/Anchorage' },
  { label: '(UTC-08:00) SystemV/PST8', zoneId: 'SystemV/PST8' },
  { label: '(UTC-08:00) America/Nome', zoneId: 'America/Nome' },
  { label: '(UTC-08:00) SystemV/YST9YDT', zoneId: 'SystemV/YST9YDT' },
  { label: '(UTC-07:00) Canada/Yukon', zoneId: 'Canada/Yukon' },
  { label: '(UTC-07:00) US/Pacific-New', zoneId: 'US/Pacific-New' },
  { label: '(UTC-07:00) Etc/GMT+7', zoneId: 'Etc/GMT+7' },
  { label: '(UTC-07:00) US/Arizona', zoneId: 'US/Arizona' },
  { label: '(UTC-07:00) America/Dawson Creek', zoneId: 'America/Dawson_Creek' },
  { label: '(UTC-07:00) Canada/Pacific', zoneId: 'Canada/Pacific' },
  { label: '(UTC-07:00) PST8PDT', zoneId: 'PST8PDT' },
  { label: '(UTC-07:00) SystemV/MST7', zoneId: 'SystemV/MST7' },
  { label: '(UTC-07:00) America/Dawson', zoneId: 'America/Dawson' },
  { label: '(UTC-07:00) Mexico/BajaNorte', zoneId: 'Mexico/BajaNorte' },
  { label: '(UTC-07:00) America/Tijuana', zoneId: 'America/Tijuana' },
  { label: '(UTC-07:00) America/Creston', zoneId: 'America/Creston' },
  { label: '(UTC-07:00) America/Hermosillo', zoneId: 'America/Hermosillo' },
  { label: '(UTC-07:00) America/Santa Isabel', zoneId: 'America/Santa_Isabel' },
  { label: '(UTC-07:00) America/Vancouver', zoneId: 'America/Vancouver' },
  { label: '(UTC-07:00) America/Ensenada', zoneId: 'America/Ensenada' },
  { label: '(UTC-07:00) America/Phoenix', zoneId: 'America/Phoenix' },
  { label: '(UTC-07:00) America/Whitehorse', zoneId: 'America/Whitehorse' },
  { label: '(UTC-07:00) America/Fort Nelson', zoneId: 'America/Fort_Nelson' },
  { label: '(UTC-07:00) SystemV/PST8PDT', zoneId: 'SystemV/PST8PDT' },
  { label: '(UTC-07:00) America/Los Angeles', zoneId: 'America/Los_Angeles' },
  { label: '(UTC-07:00) US/Pacific', zoneId: 'US/Pacific' },
  { label: '(UTC-06:00) America/El Salvador', zoneId: 'America/El_Salvador' },
  { label: '(UTC-06:00) America/Guatemala', zoneId: 'America/Guatemala' },
  { label: '(UTC-06:00) America/Belize', zoneId: 'America/Belize' },
  { label: '(UTC-06:00) America/Managua', zoneId: 'America/Managua' },
  { label: '(UTC-06:00) America/Tegucigalpa', zoneId: 'America/Tegucigalpa' },
  { label: '(UTC-06:00) Etc/GMT+6', zoneId: 'Etc/GMT+6' },
  { label: '(UTC-06:00) Pacific/Easter', zoneId: 'Pacific/Easter' },
  { label: '(UTC-06:00) Mexico/BajaSur', zoneId: 'Mexico/BajaSur' },
  { label: '(UTC-06:00) America/Regina', zoneId: 'America/Regina' },
  { label: '(UTC-06:00) America/Denver', zoneId: 'America/Denver' },
  { label: '(UTC-06:00) Pacific/Galapagos', zoneId: 'Pacific/Galapagos' },
  { label: '(UTC-06:00) America/Yellowknife', zoneId: 'America/Yellowknife' },
  {
    label: '(UTC-06:00) America/Swift Current',
    zoneId: 'America/Swift_Current',
  },
  { label: '(UTC-06:00) America/Inuvik', zoneId: 'America/Inuvik' },
  { label: '(UTC-06:00) America/Mazatlan', zoneId: 'America/Mazatlan' },
  { label: '(UTC-06:00) America/Boise', zoneId: 'America/Boise' },
  { label: '(UTC-06:00) America/Costa Rica', zoneId: 'America/Costa_Rica' },
  { label: '(UTC-06:00) MST7MDT', zoneId: 'MST7MDT' },
  { label: '(UTC-06:00) SystemV/CST6', zoneId: 'SystemV/CST6' },
  { label: '(UTC-06:00) America/Chihuahua', zoneId: 'America/Chihuahua' },
  { label: '(UTC-06:00) America/Ojinaga', zoneId: 'America/Ojinaga' },
  { label: '(UTC-06:00) Chile/EasterIsland', zoneId: 'Chile/EasterIsland' },
  { label: '(UTC-06:00) US/Mountain', zoneId: 'US/Mountain' },
  { label: '(UTC-06:00) America/Edmonton', zoneId: 'America/Edmonton' },
  { label: '(UTC-06:00) Canada/Mountain', zoneId: 'Canada/Mountain' },
  {
    label: '(UTC-06:00) America/Cambridge Bay',
    zoneId: 'America/Cambridge_Bay',
  },
  { label: '(UTC-06:00) Navajo', zoneId: 'Navajo' },
  { label: '(UTC-06:00) SystemV/MST7MDT', zoneId: 'SystemV/MST7MDT' },
  { label: '(UTC-06:00) Canada/Saskatchewan', zoneId: 'Canada/Saskatchewan' },
  { label: '(UTC-06:00) America/Shiprock', zoneId: 'America/Shiprock' },
  { label: '(UTC-05:00) America/Panama', zoneId: 'America/Panama' },
  { label: '(UTC-05:00) America/Chicago', zoneId: 'America/Chicago' },
  { label: '(UTC-05:00) America/Eirunepe', zoneId: 'America/Eirunepe' },
  { label: '(UTC-05:00) Etc/GMT+5', zoneId: 'Etc/GMT+5' },
  { label: '(UTC-05:00) Mexico/General', zoneId: 'Mexico/General' },
  { label: '(UTC-05:00) America/Porto Acre', zoneId: 'America/Porto_Acre' },
  { label: '(UTC-05:00) America/Guayaquil', zoneId: 'America/Guayaquil' },
  { label: '(UTC-05:00) America/Rankin Inlet', zoneId: 'America/Rankin_Inlet' },
  { label: '(UTC-05:00) US/Central', zoneId: 'US/Central' },
  { label: '(UTC-05:00) America/Rainy River', zoneId: 'America/Rainy_River' },
  { label: '(UTC-05:00) America/Indiana/Knox', zoneId: 'America/Indiana/Knox' },
  {
    label: '(UTC-05:00) America/North Dakota/Beulah',
    zoneId: 'America/North_Dakota/Beulah',
  },
  { label: '(UTC-05:00) America/Monterrey', zoneId: 'America/Monterrey' },
  { label: '(UTC-05:00) America/Jamaica', zoneId: 'America/Jamaica' },
  { label: '(UTC-05:00) America/Atikokan', zoneId: 'America/Atikokan' },
  {
    label: '(UTC-05:00) America/Coral Harbour',
    zoneId: 'America/Coral_Harbour',
  },
  {
    label: '(UTC-05:00) America/North Dakota/Center',
    zoneId: 'America/North_Dakota/Center',
  },
  { label: '(UTC-05:00) America/Cayman', zoneId: 'America/Cayman' },
  {
    label: '(UTC-05:00) America/Indiana/Tell City',
    zoneId: 'America/Indiana/Tell_City',
  },
  { label: '(UTC-05:00) America/Mexico City', zoneId: 'America/Mexico_City' },
  { label: '(UTC-05:00) America/Matamoros', zoneId: 'America/Matamoros' },
  { label: '(UTC-05:00) CST6CDT', zoneId: 'CST6CDT' },
  { label: '(UTC-05:00) America/Knox IN', zoneId: 'America/Knox_IN' },
  { label: '(UTC-05:00) America/Bogota', zoneId: 'America/Bogota' },
  { label: '(UTC-05:00) America/Menominee', zoneId: 'America/Menominee' },
  { label: '(UTC-05:00) America/Resolute', zoneId: 'America/Resolute' },
  { label: '(UTC-05:00) SystemV/EST5', zoneId: 'SystemV/EST5' },
  { label: '(UTC-05:00) Canada/Central', zoneId: 'Canada/Central' },
  { label: '(UTC-05:00) Brazil/Acre', zoneId: 'Brazil/Acre' },
  { label: '(UTC-05:00) America/Cancun', zoneId: 'America/Cancun' },
  { label: '(UTC-05:00) America/Lima', zoneId: 'America/Lima' },
  {
    label: '(UTC-05:00) America/Bahia Banderas',
    zoneId: 'America/Bahia_Banderas',
  },
  { label: '(UTC-05:00) US/Indiana-Starke', zoneId: 'US/Indiana-Starke' },
  { label: '(UTC-05:00) America/Rio Branco', zoneId: 'America/Rio_Branco' },
  { label: '(UTC-05:00) SystemV/CST6CDT', zoneId: 'SystemV/CST6CDT' },
  { label: '(UTC-05:00) Jamaica', zoneId: 'Jamaica' },
  { label: '(UTC-05:00) America/Merida', zoneId: 'America/Merida' },
  {
    label: '(UTC-05:00) America/North Dakota/New Salem',
    zoneId: 'America/North_Dakota/New_Salem',
  },
  { label: '(UTC-05:00) America/Winnipeg', zoneId: 'America/Winnipeg' },
  { label: '(UTC-04:00) America/Cuiaba', zoneId: 'America/Cuiaba' },
  { label: '(UTC-04:00) America/Marigot', zoneId: 'America/Marigot' },
  {
    label: '(UTC-04:00) America/Indiana/Petersburg',
    zoneId: 'America/Indiana/Petersburg',
  },
  { label: '(UTC-04:00) Chile/Continental', zoneId: 'Chile/Continental' },
  { label: '(UTC-04:00) America/Grand Turk', zoneId: 'America/Grand_Turk' },
  { label: '(UTC-04:00) Cuba', zoneId: 'Cuba' },
  { label: '(UTC-04:00) Etc/GMT+4', zoneId: 'Etc/GMT+4' },
  { label: '(UTC-04:00) America/Manaus', zoneId: 'America/Manaus' },
  { label: '(UTC-04:00) America/Fort Wayne', zoneId: 'America/Fort_Wayne' },
  { label: '(UTC-04:00) America/St Thomas', zoneId: 'America/St_Thomas' },
  { label: '(UTC-04:00) America/Anguilla', zoneId: 'America/Anguilla' },
  { label: '(UTC-04:00) America/Havana', zoneId: 'America/Havana' },
  { label: '(UTC-04:00) US/Michigan', zoneId: 'US/Michigan' },
  { label: '(UTC-04:00) America/Barbados', zoneId: 'America/Barbados' },
  { label: '(UTC-04:00) America/Louisville', zoneId: 'America/Louisville' },
  { label: '(UTC-04:00) America/Curacao', zoneId: 'America/Curacao' },
  { label: '(UTC-04:00) America/Guyana', zoneId: 'America/Guyana' },
  { label: '(UTC-04:00) America/Martinique', zoneId: 'America/Martinique' },
  { label: '(UTC-04:00) America/Puerto Rico', zoneId: 'America/Puerto_Rico' },
  {
    label: '(UTC-04:00) America/Port of Spain',
    zoneId: 'America/Port_of_Spain',
  },
  { label: '(UTC-04:00) SystemV/AST4', zoneId: 'SystemV/AST4' },
  {
    label: '(UTC-04:00) America/Indiana/Vevay',
    zoneId: 'America/Indiana/Vevay',
  },
  {
    label: '(UTC-04:00) America/Indiana/Vincennes',
    zoneId: 'America/Indiana/Vincennes',
  },
  { label: '(UTC-04:00) America/Kralendijk', zoneId: 'America/Kralendijk' },
  { label: '(UTC-04:00) America/Antigua', zoneId: 'America/Antigua' },
  { label: '(UTC-04:00) America/Indianapolis', zoneId: 'America/Indianapolis' },
  { label: '(UTC-04:00) America/Iqaluit', zoneId: 'America/Iqaluit' },
  { label: '(UTC-04:00) America/St Vincent', zoneId: 'America/St_Vincent' },
  {
    label: '(UTC-04:00) America/Kentucky/Louisville',
    zoneId: 'America/Kentucky/Louisville',
  },
  { label: '(UTC-04:00) America/Dominica', zoneId: 'America/Dominica' },
  { label: '(UTC-04:00) America/Asuncion', zoneId: 'America/Asuncion' },
  { label: '(UTC-04:00) EST5EDT', zoneId: 'EST5EDT' },
  { label: '(UTC-04:00) America/Nassau', zoneId: 'America/Nassau' },
  {
    label: '(UTC-04:00) America/Kentucky/Monticello',
    zoneId: 'America/Kentucky/Monticello',
  },
  { label: '(UTC-04:00) Brazil/West', zoneId: 'Brazil/West' },
  { label: '(UTC-04:00) America/Aruba', zoneId: 'America/Aruba' },
  {
    label: '(UTC-04:00) America/Indiana/Indianapolis',
    zoneId: 'America/Indiana/Indianapolis',
  },
  { label: '(UTC-04:00) America/Santiago', zoneId: 'America/Santiago' },
  { label: '(UTC-04:00) America/La Paz', zoneId: 'America/La_Paz' },
  { label: '(UTC-04:00) America/Thunder Bay', zoneId: 'America/Thunder_Bay' },
  {
    label: '(UTC-04:00) America/Indiana/Marengo',
    zoneId: 'America/Indiana/Marengo',
  },
  { label: '(UTC-04:00) America/Blanc-Sablon', zoneId: 'America/Blanc-Sablon' },
  {
    label: '(UTC-04:00) America/Santo Domingo',
    zoneId: 'America/Santo_Domingo',
  },
  { label: '(UTC-04:00) US/Eastern', zoneId: 'US/Eastern' },
  { label: '(UTC-04:00) Canada/Eastern', zoneId: 'Canada/Eastern' },
  {
    label: '(UTC-04:00) America/Port-au-Prince',
    zoneId: 'America/Port-au-Prince',
  },
  {
    label: '(UTC-04:00) America/St Barthelemy',
    zoneId: 'America/St_Barthelemy',
  },
  { label: '(UTC-04:00) America/Nipigon', zoneId: 'America/Nipigon' },
  { label: '(UTC-04:00) US/East-Indiana', zoneId: 'US/East-Indiana' },
  { label: '(UTC-04:00) America/St Lucia', zoneId: 'America/St_Lucia' },
  { label: '(UTC-04:00) America/Montserrat', zoneId: 'America/Montserrat' },
  {
    label: '(UTC-04:00) America/Lower Princes',
    zoneId: 'America/Lower_Princes',
  },
  { label: '(UTC-04:00) America/Detroit', zoneId: 'America/Detroit' },
  { label: '(UTC-04:00) America/Tortola', zoneId: 'America/Tortola' },
  { label: '(UTC-04:00) America/Porto Velho', zoneId: 'America/Porto_Velho' },
  { label: '(UTC-04:00) America/Campo Grande', zoneId: 'America/Campo_Grande' },
  { label: '(UTC-04:00) America/Virgin', zoneId: 'America/Virgin' },
  { label: '(UTC-04:00) America/Pangnirtung', zoneId: 'America/Pangnirtung' },
  { label: '(UTC-04:00) America/Montreal', zoneId: 'America/Montreal' },
  {
    label: '(UTC-04:00) America/Indiana/Winamac',
    zoneId: 'America/Indiana/Winamac',
  },
  { label: '(UTC-04:00) America/Boa Vista', zoneId: 'America/Boa_Vista' },
  { label: '(UTC-04:00) America/Grenada', zoneId: 'America/Grenada' },
  { label: '(UTC-04:00) America/New York', zoneId: 'America/New_York' },
  { label: '(UTC-04:00) America/St Kitts', zoneId: 'America/St_Kitts' },
  { label: '(UTC-04:00) America/Caracas', zoneId: 'America/Caracas' },
  { label: '(UTC-04:00) America/Guadeloupe', zoneId: 'America/Guadeloupe' },
  { label: '(UTC-04:00) America/Toronto', zoneId: 'America/Toronto' },
  { label: '(UTC-04:00) SystemV/EST5EDT', zoneId: 'SystemV/EST5EDT' },
  {
    label: '(UTC-03:00) America/Argentina/Catamarca',
    zoneId: 'America/Argentina/Catamarca',
  },
  { label: '(UTC-03:00) Canada/Atlantic', zoneId: 'Canada/Atlantic' },
  {
    label: '(UTC-03:00) America/Argentina/Cordoba',
    zoneId: 'America/Argentina/Cordoba',
  },
  { label: '(UTC-03:00) America/Araguaina', zoneId: 'America/Araguaina' },
  {
    label: '(UTC-03:00) America/Argentina/Salta',
    zoneId: 'America/Argentina/Salta',
  },
  { label: '(UTC-03:00) Etc/GMT+3', zoneId: 'Etc/GMT+3' },
  { label: '(UTC-03:00) America/Montevideo', zoneId: 'America/Montevideo' },
  { label: '(UTC-03:00) Brazil/East', zoneId: 'Brazil/East' },
  {
    label: '(UTC-03:00) America/Argentina/Mendoza',
    zoneId: 'America/Argentina/Mendoza',
  },
  {
    label: '(UTC-03:00) America/Argentina/Rio Gallegos',
    zoneId: 'America/Argentina/Rio_Gallegos',
  },
  { label: '(UTC-03:00) America/Catamarca', zoneId: 'America/Catamarca' },
  { label: '(UTC-03:00) America/Cordoba', zoneId: 'America/Cordoba' },
  { label: '(UTC-03:00) America/Sao Paulo', zoneId: 'America/Sao_Paulo' },
  {
    label: '(UTC-03:00) America/Argentina/Jujuy',
    zoneId: 'America/Argentina/Jujuy',
  },
  { label: '(UTC-03:00) America/Cayenne', zoneId: 'America/Cayenne' },
  { label: '(UTC-03:00) America/Recife', zoneId: 'America/Recife' },
  { label: '(UTC-03:00) America/Buenos Aires', zoneId: 'America/Buenos_Aires' },
  { label: '(UTC-03:00) America/Paramaribo', zoneId: 'America/Paramaribo' },
  { label: '(UTC-03:00) America/Moncton', zoneId: 'America/Moncton' },
  { label: '(UTC-03:00) America/Mendoza', zoneId: 'America/Mendoza' },
  { label: '(UTC-03:00) America/Santarem', zoneId: 'America/Santarem' },
  { label: '(UTC-03:00) Atlantic/Bermuda', zoneId: 'Atlantic/Bermuda' },
  { label: '(UTC-03:00) America/Maceio', zoneId: 'America/Maceio' },
  { label: '(UTC-03:00) Atlantic/Stanley', zoneId: 'Atlantic/Stanley' },
  { label: '(UTC-03:00) America/Halifax', zoneId: 'America/Halifax' },
  { label: '(UTC-03:00) Antarctica/Rothera', zoneId: 'Antarctica/Rothera' },
  {
    label: '(UTC-03:00) America/Argentina/San Luis',
    zoneId: 'America/Argentina/San_Luis',
  },
  {
    label: '(UTC-03:00) America/Argentina/Ushuaia',
    zoneId: 'America/Argentina/Ushuaia',
  },
  { label: '(UTC-03:00) Antarctica/Palmer', zoneId: 'Antarctica/Palmer' },
  { label: '(UTC-03:00) America/Punta Arenas', zoneId: 'America/Punta_Arenas' },
  { label: '(UTC-03:00) America/Glace Bay', zoneId: 'America/Glace_Bay' },
  { label: '(UTC-03:00) America/Fortaleza', zoneId: 'America/Fortaleza' },
  { label: '(UTC-03:00) America/Thule', zoneId: 'America/Thule' },
  {
    label: '(UTC-03:00) America/Argentina/La Rioja',
    zoneId: 'America/Argentina/La_Rioja',
  },
  { label: '(UTC-03:00) America/Belem', zoneId: 'America/Belem' },
  { label: '(UTC-03:00) America/Jujuy', zoneId: 'America/Jujuy' },
  { label: '(UTC-03:00) America/Bahia', zoneId: 'America/Bahia' },
  { label: '(UTC-03:00) America/Goose Bay', zoneId: 'America/Goose_Bay' },
  {
    label: '(UTC-03:00) America/Argentina/San Juan',
    zoneId: 'America/Argentina/San_Juan',
  },
  {
    label: '(UTC-03:00) America/Argentina/ComodRivadavia',
    zoneId: 'America/Argentina/ComodRivadavia',
  },
  {
    label: '(UTC-03:00) America/Argentina/Tucuman',
    zoneId: 'America/Argentina/Tucuman',
  },
  { label: '(UTC-03:00) America/Rosario', zoneId: 'America/Rosario' },
  { label: '(UTC-03:00) SystemV/AST4ADT', zoneId: 'SystemV/AST4ADT' },
  {
    label: '(UTC-03:00) America/Argentina/Buenos Aires',
    zoneId: 'America/Argentina/Buenos_Aires',
  },
  { label: '(UTC-02:30) America/St Johns', zoneId: 'America/St_Johns' },
  { label: '(UTC-02:30) Canada/Newfoundland', zoneId: 'Canada/Newfoundland' },
  { label: '(UTC-02:00) America/Miquelon', zoneId: 'America/Miquelon' },
  { label: '(UTC-02:00) Etc/GMT+2', zoneId: 'Etc/GMT+2' },
  { label: '(UTC-02:00) America/Godthab', zoneId: 'America/Godthab' },
  { label: '(UTC-02:00) America/Noronha', zoneId: 'America/Noronha' },
  { label: '(UTC-02:00) Brazil/DeNoronha', zoneId: 'Brazil/DeNoronha' },
  {
    label: '(UTC-02:00) Atlantic/South Georgia',
    zoneId: 'Atlantic/South_Georgia',
  },
  { label: '(UTC-01:00) Etc/GMT+1', zoneId: 'Etc/GMT+1' },
  { label: '(UTC-01:00) Atlantic/Cape Verde', zoneId: 'Atlantic/Cape_Verde' },
  { label: '(UTC+14:00) Pacific/Kiritimati', zoneId: 'Pacific/Kiritimati' },
  { label: '(UTC+14:00) Etc/GMT-14', zoneId: 'Etc/GMT-14' },
  { label: '(UTC+13:00) Pacific/Fakaofo', zoneId: 'Pacific/Fakaofo' },
  { label: '(UTC+13:00) Pacific/Enderbury', zoneId: 'Pacific/Enderbury' },
  { label: '(UTC+13:00) Pacific/Apia', zoneId: 'Pacific/Apia' },
  { label: '(UTC+13:00) Pacific/Tongatapu', zoneId: 'Pacific/Tongatapu' },
  { label: '(UTC+13:00) Etc/GMT-13', zoneId: 'Etc/GMT-13' },
  { label: '(UTC+12:45) NZ-CHAT', zoneId: 'NZ-CHAT' },
  { label: '(UTC+12:45) Pacific/Chatham', zoneId: 'Pacific/Chatham' },
  { label: '(UTC+12:00) Pacific/Kwajalein', zoneId: 'Pacific/Kwajalein' },
  { label: '(UTC+12:00) Antarctica/McMurdo', zoneId: 'Antarctica/McMurdo' },
  { label: '(UTC+12:00) Pacific/Wallis', zoneId: 'Pacific/Wallis' },
  { label: '(UTC+12:00) Pacific/Fiji', zoneId: 'Pacific/Fiji' },
  { label: '(UTC+12:00) Pacific/Funafuti', zoneId: 'Pacific/Funafuti' },
  { label: '(UTC+12:00) Pacific/Nauru', zoneId: 'Pacific/Nauru' },
  { label: '(UTC+12:00) Kwajalein', zoneId: 'Kwajalein' },
  { label: '(UTC+12:00) NZ', zoneId: 'NZ' },
  { label: '(UTC+12:00) Pacific/Wake', zoneId: 'Pacific/Wake' },
  {
    label: '(UTC+12:00) Antarctica/South Pole',
    zoneId: 'Antarctica/South_Pole',
  },
  { label: '(UTC+12:00) Pacific/Tarawa', zoneId: 'Pacific/Tarawa' },
  { label: '(UTC+12:00) Pacific/Auckland', zoneId: 'Pacific/Auckland' },
  { label: '(UTC+12:00) Asia/Kamchatka', zoneId: 'Asia/Kamchatka' },
  { label: '(UTC+12:00) Etc/GMT-12', zoneId: 'Etc/GMT-12' },
  { label: '(UTC+12:00) Asia/Anadyr', zoneId: 'Asia/Anadyr' },
  { label: '(UTC+12:00) Pacific/Majuro', zoneId: 'Pacific/Majuro' },
  { label: '(UTC+11:00) Pacific/Ponape', zoneId: 'Pacific/Ponape' },
  { label: '(UTC+11:00) Pacific/Bougainville', zoneId: 'Pacific/Bougainville' },
  { label: '(UTC+11:00) Antarctica/Macquarie', zoneId: 'Antarctica/Macquarie' },
  { label: '(UTC+11:00) Pacific/Pohnpei', zoneId: 'Pacific/Pohnpei' },
  { label: '(UTC+11:00) Pacific/Efate', zoneId: 'Pacific/Efate' },
  { label: '(UTC+11:00) Pacific/Norfolk', zoneId: 'Pacific/Norfolk' },
  { label: '(UTC+11:00) Asia/Magadan', zoneId: 'Asia/Magadan' },
  { label: '(UTC+11:00) Pacific/Kosrae', zoneId: 'Pacific/Kosrae' },
  { label: '(UTC+11:00) Asia/Sakhalin', zoneId: 'Asia/Sakhalin' },
  { label: '(UTC+11:00) Pacific/Noumea', zoneId: 'Pacific/Noumea' },
  { label: '(UTC+11:00) Etc/GMT-11', zoneId: 'Etc/GMT-11' },
  { label: '(UTC+11:00) Asia/Srednekolymsk', zoneId: 'Asia/Srednekolymsk' },
  { label: '(UTC+11:00) Pacific/Guadalcanal', zoneId: 'Pacific/Guadalcanal' },
  { label: '(UTC+10:30) Australia/Lord Howe', zoneId: 'Australia/Lord_Howe' },
  { label: '(UTC+10:30) Australia/LHI', zoneId: 'Australia/LHI' },
  { label: '(UTC+10:00) Australia/Hobart', zoneId: 'Australia/Hobart' },
  { label: '(UTC+10:00) Pacific/Yap', zoneId: 'Pacific/Yap' },
  { label: '(UTC+10:00) Australia/Tasmania', zoneId: 'Australia/Tasmania' },
  { label: '(UTC+10:00) Pacific/Port Moresby', zoneId: 'Pacific/Port_Moresby' },
  { label: '(UTC+10:00) Australia/ACT', zoneId: 'Australia/ACT' },
  { label: '(UTC+10:00) Australia/Victoria', zoneId: 'Australia/Victoria' },
  { label: '(UTC+10:00) Pacific/Chuuk', zoneId: 'Pacific/Chuuk' },
  { label: '(UTC+10:00) Australia/Queensland', zoneId: 'Australia/Queensland' },
  { label: '(UTC+10:00) Australia/Canberra', zoneId: 'Australia/Canberra' },
  { label: '(UTC+10:00) Australia/Currie', zoneId: 'Australia/Currie' },
  { label: '(UTC+10:00) Pacific/Guam', zoneId: 'Pacific/Guam' },
  { label: '(UTC+10:00) Pacific/Truk', zoneId: 'Pacific/Truk' },
  { label: '(UTC+10:00) Australia/NSW', zoneId: 'Australia/NSW' },
  { label: '(UTC+10:00) Asia/Vladivostok', zoneId: 'Asia/Vladivostok' },
  { label: '(UTC+10:00) Pacific/Saipan', zoneId: 'Pacific/Saipan' },
  {
    label: '(UTC+10:00) Antarctica/DumontDUrville',
    zoneId: 'Antarctica/DumontDUrville',
  },
  { label: '(UTC+10:00) Australia/Sydney', zoneId: 'Australia/Sydney' },
  { label: '(UTC+10:00) Australia/Brisbane', zoneId: 'Australia/Brisbane' },
  { label: '(UTC+10:00) Etc/GMT-10', zoneId: 'Etc/GMT-10' },
  { label: '(UTC+10:00) Asia/Ust-Nera', zoneId: 'Asia/Ust-Nera' },
  { label: '(UTC+10:00) Australia/Melbourne', zoneId: 'Australia/Melbourne' },
  { label: '(UTC+10:00) Australia/Lindeman', zoneId: 'Australia/Lindeman' },
  { label: '(UTC+09:30) Australia/North', zoneId: 'Australia/North' },
  { label: '(UTC+09:30) Australia/Yancowinna', zoneId: 'Australia/Yancowinna' },
  { label: '(UTC+09:30) Australia/Adelaide', zoneId: 'Australia/Adelaide' },
  {
    label: '(UTC+09:30) Australia/Broken Hill',
    zoneId: 'Australia/Broken_Hill',
  },
  { label: '(UTC+09:30) Australia/South', zoneId: 'Australia/South' },
  { label: '(UTC+09:30) Australia/Darwin', zoneId: 'Australia/Darwin' },
  { label: '(UTC+09:00) Etc/GMT-9', zoneId: 'Etc/GMT-9' },
  { label: '(UTC+09:00) Pacific/Palau', zoneId: 'Pacific/Palau' },
  { label: '(UTC+09:00) Asia/Chita', zoneId: 'Asia/Chita' },
  { label: '(UTC+09:00) Asia/Dili', zoneId: 'Asia/Dili' },
  { label: '(UTC+09:00) Asia/Jayapura', zoneId: 'Asia/Jayapura' },
  { label: '(UTC+09:00) Asia/Yakutsk', zoneId: 'Asia/Yakutsk' },
  { label: '(UTC+09:00) Asia/Pyongyang', zoneId: 'Asia/Pyongyang' },
  { label: '(UTC+09:00) ROK', zoneId: 'ROK' },
  { label: '(UTC+09:00) Asia/Seoul', zoneId: 'Asia/Seoul' },
  { label: '(UTC+09:00) Asia/Khandyga', zoneId: 'Asia/Khandyga' },
  { label: '(UTC+09:00) Japan', zoneId: 'Japan' },
  { label: '(UTC+09:00) Asia/Tokyo', zoneId: 'Asia/Tokyo' },
  { label: '(UTC+08:45) Australia/Eucla', zoneId: 'Australia/Eucla' },
  { label: '(UTC+08:00) Asia/Kuching', zoneId: 'Asia/Kuching' },
  { label: '(UTC+08:00) Asia/Chungking', zoneId: 'Asia/Chungking' },
  { label: '(UTC+08:00) Etc/GMT-8', zoneId: 'Etc/GMT-8' },
  { label: '(UTC+08:00) Australia/Perth', zoneId: 'Australia/Perth' },
  { label: '(UTC+08:00) Asia/Macao', zoneId: 'Asia/Macao' },
  { label: '(UTC+08:00) Asia/Macau', zoneId: 'Asia/Macau' },
  { label: '(UTC+08:00) Asia/Choibalsan', zoneId: 'Asia/Choibalsan' },
  { label: '(UTC+08:00) Asia/Shanghai', zoneId: 'Asia/Shanghai' },
  { label: '(UTC+08:00) Antarctica/Casey', zoneId: 'Antarctica/Casey' },
  { label: '(UTC+08:00) Asia/Ulan Bator', zoneId: 'Asia/Ulan_Bator' },
  { label: '(UTC+08:00) Asia/Chongqing', zoneId: 'Asia/Chongqing' },
  { label: '(UTC+08:00) Asia/Ulaanbaatar', zoneId: 'Asia/Ulaanbaatar' },
  { label: '(UTC+08:00) Asia/Taipei', zoneId: 'Asia/Taipei' },
  { label: '(UTC+08:00) Asia/Manila', zoneId: 'Asia/Manila' },
  { label: '(UTC+08:00) PRC', zoneId: 'PRC' },
  { label: '(UTC+08:00) Asia/Ujung Pandang', zoneId: 'Asia/Ujung_Pandang' },
  { label: '(UTC+08:00) Asia/Harbin', zoneId: 'Asia/Harbin' },
  { label: '(UTC+08:00) Singapore', zoneId: 'Singapore' },
  { label: '(UTC+08:00) Asia/Brunei', zoneId: 'Asia/Brunei' },
  { label: '(UTC+08:00) Australia/West', zoneId: 'Australia/West' },
  { label: '(UTC+08:00) Asia/Hong Kong', zoneId: 'Asia/Hong_Kong' },
  { label: '(UTC+08:00) Asia/Makassar', zoneId: 'Asia/Makassar' },
  { label: '(UTC+08:00) Hongkong', zoneId: 'Hongkong' },
  { label: '(UTC+08:00) Asia/Kuala Lumpur', zoneId: 'Asia/Kuala_Lumpur' },
  { label: '(UTC+08:00) Asia/Irkutsk', zoneId: 'Asia/Irkutsk' },
  { label: '(UTC+08:00) Asia/Singapore', zoneId: 'Asia/Singapore' },
  { label: '(UTC+07:00) Asia/Pontianak', zoneId: 'Asia/Pontianak' },
  { label: '(UTC+07:00) Etc/GMT-7', zoneId: 'Etc/GMT-7' },
  { label: '(UTC+07:00) Asia/Phnom Penh', zoneId: 'Asia/Phnom_Penh' },
  { label: '(UTC+07:00) Asia/Novosibirsk', zoneId: 'Asia/Novosibirsk' },
  { label: '(UTC+07:00) Antarctica/Davis', zoneId: 'Antarctica/Davis' },
  { label: '(UTC+07:00) Asia/Tomsk', zoneId: 'Asia/Tomsk' },
  { label: '(UTC+07:00) Asia/Jakarta', zoneId: 'Asia/Jakarta' },
  { label: '(UTC+07:00) Asia/Barnaul', zoneId: 'Asia/Barnaul' },
  { label: '(UTC+07:00) Indian/Christmas', zoneId: 'Indian/Christmas' },
  { label: '(UTC+07:00) Asia/Ho Chi Minh', zoneId: 'Asia/Ho_Chi_Minh' },
  { label: '(UTC+07:00) Asia/Hovd', zoneId: 'Asia/Hovd' },
  { label: '(UTC+07:00) Asia/Bangkok', zoneId: 'Asia/Bangkok' },
  { label: '(UTC+07:00) Asia/Vientiane', zoneId: 'Asia/Vientiane' },
  { label: '(UTC+07:00) Asia/Novokuznetsk', zoneId: 'Asia/Novokuznetsk' },
  { label: '(UTC+07:00) Asia/Krasnoyarsk', zoneId: 'Asia/Krasnoyarsk' },
  { label: '(UTC+07:00) Asia/Saigon', zoneId: 'Asia/Saigon' },
  { label: '(UTC+06:30) Asia/Yangon', zoneId: 'Asia/Yangon' },
  { label: '(UTC+06:30) Asia/Rangoon', zoneId: 'Asia/Rangoon' },
  { label: '(UTC+06:30) Indian/Cocos', zoneId: 'Indian/Cocos' },
  { label: '(UTC+06:00) Asia/Kashgar', zoneId: 'Asia/Kashgar' },
  { label: '(UTC+06:00) Etc/GMT-6', zoneId: 'Etc/GMT-6' },
  { label: '(UTC+06:00) Asia/Almaty', zoneId: 'Asia/Almaty' },
  { label: '(UTC+06:00) Asia/Dacca', zoneId: 'Asia/Dacca' },
  { label: '(UTC+06:00) Asia/Omsk', zoneId: 'Asia/Omsk' },
  { label: '(UTC+06:00) Asia/Dhaka', zoneId: 'Asia/Dhaka' },
  { label: '(UTC+06:00) Indian/Chagos', zoneId: 'Indian/Chagos' },
  { label: '(UTC+06:00) Asia/Qyzylorda', zoneId: 'Asia/Qyzylorda' },
  { label: '(UTC+06:00) Asia/Bishkek', zoneId: 'Asia/Bishkek' },
  { label: '(UTC+06:00) Antarctica/Vostok', zoneId: 'Antarctica/Vostok' },
  { label: '(UTC+06:00) Asia/Urumqi', zoneId: 'Asia/Urumqi' },
  { label: '(UTC+06:00) Asia/Thimbu', zoneId: 'Asia/Thimbu' },
  { label: '(UTC+06:00) Asia/Thimphu', zoneId: 'Asia/Thimphu' },
  { label: '(UTC+05:45) Asia/Kathmandu', zoneId: 'Asia/Kathmandu' },
  { label: '(UTC+05:45) Asia/Katmandu', zoneId: 'Asia/Katmandu' },
  { label: '(UTC+05:30) Asia/Kolkata', zoneId: 'Asia/Kolkata' },
  { label: '(UTC+05:30) Asia/Colombo', zoneId: 'Asia/Colombo' },
  { label: '(UTC+05:30) Asia/Calcutta', zoneId: 'Asia/Calcutta' },
  { label: '(UTC+05:00) Asia/Aqtau', zoneId: 'Asia/Aqtau' },
  { label: '(UTC+05:00) Etc/GMT-5', zoneId: 'Etc/GMT-5' },
  { label: '(UTC+05:00) Asia/Samarkand', zoneId: 'Asia/Samarkand' },
  { label: '(UTC+05:00) Asia/Karachi', zoneId: 'Asia/Karachi' },
  { label: '(UTC+05:00) Asia/Yekaterinburg', zoneId: 'Asia/Yekaterinburg' },
  { label: '(UTC+05:00) Asia/Dushanbe', zoneId: 'Asia/Dushanbe' },
  { label: '(UTC+05:00) Indian/Maldives', zoneId: 'Indian/Maldives' },
  { label: '(UTC+05:00) Asia/Oral', zoneId: 'Asia/Oral' },
  { label: '(UTC+05:00) Asia/Tashkent', zoneId: 'Asia/Tashkent' },
  { label: '(UTC+05:00) Antarctica/Mawson', zoneId: 'Antarctica/Mawson' },
  { label: '(UTC+05:00) Asia/Aqtobe', zoneId: 'Asia/Aqtobe' },
  { label: '(UTC+05:00) Asia/Ashkhabad', zoneId: 'Asia/Ashkhabad' },
  { label: '(UTC+05:00) Asia/Ashgabat', zoneId: 'Asia/Ashgabat' },
  { label: '(UTC+05:00) Asia/Atyrau', zoneId: 'Asia/Atyrau' },
  { label: '(UTC+05:00) Indian/Kerguelen', zoneId: 'Indian/Kerguelen' },
  { label: '(UTC+04:30) Iran', zoneId: 'Iran' },
  { label: '(UTC+04:30) Asia/Tehran', zoneId: 'Asia/Tehran' },
  { label: '(UTC+04:30) Asia/Kabul', zoneId: 'Asia/Kabul' },
  { label: '(UTC+04:00) Asia/Yerevan', zoneId: 'Asia/Yerevan' },
  { label: '(UTC+04:00) Etc/GMT-4', zoneId: 'Etc/GMT-4' },
  { label: '(UTC+04:00) Asia/Dubai', zoneId: 'Asia/Dubai' },
  { label: '(UTC+04:00) Indian/Reunion', zoneId: 'Indian/Reunion' },
  { label: '(UTC+04:00) Indian/Mauritius', zoneId: 'Indian/Mauritius' },
  { label: '(UTC+04:00) Europe/Saratov', zoneId: 'Europe/Saratov' },
  { label: '(UTC+04:00) Europe/Samara', zoneId: 'Europe/Samara' },
  { label: '(UTC+04:00) Indian/Mahe', zoneId: 'Indian/Mahe' },
  { label: '(UTC+04:00) Asia/Baku', zoneId: 'Asia/Baku' },
  { label: '(UTC+04:00) Asia/Muscat', zoneId: 'Asia/Muscat' },
  { label: '(UTC+04:00) Europe/Volgograd', zoneId: 'Europe/Volgograd' },
  { label: '(UTC+04:00) Europe/Astrakhan', zoneId: 'Europe/Astrakhan' },
  { label: '(UTC+04:00) Asia/Tbilisi', zoneId: 'Asia/Tbilisi' },
  { label: '(UTC+04:00) Europe/Ulyanovsk', zoneId: 'Europe/Ulyanovsk' },
  { label: '(UTC+03:00) Asia/Aden', zoneId: 'Asia/Aden' },
  { label: '(UTC+03:00) Africa/Nairobi', zoneId: 'Africa/Nairobi' },
  { label: '(UTC+03:00) Europe/Istanbul', zoneId: 'Europe/Istanbul' },
  { label: '(UTC+03:00) Etc/GMT-3', zoneId: 'Etc/GMT-3' },
  { label: '(UTC+03:00) Europe/Zaporozhye', zoneId: 'Europe/Zaporozhye' },
  { label: '(UTC+03:00) Israel', zoneId: 'Israel' },
  { label: '(UTC+03:00) Indian/Comoro', zoneId: 'Indian/Comoro' },
  { label: '(UTC+03:00) Antarctica/Syowa', zoneId: 'Antarctica/Syowa' },
  { label: '(UTC+03:00) Africa/Mogadishu', zoneId: 'Africa/Mogadishu' },
  { label: '(UTC+03:00) Europe/Bucharest', zoneId: 'Europe/Bucharest' },
  { label: '(UTC+03:00) Africa/Asmera', zoneId: 'Africa/Asmera' },
  { label: '(UTC+03:00) Europe/Mariehamn', zoneId: 'Europe/Mariehamn' },
  { label: '(UTC+03:00) Asia/Istanbul', zoneId: 'Asia/Istanbul' },
  { label: '(UTC+03:00) Europe/Tiraspol', zoneId: 'Europe/Tiraspol' },
  { label: '(UTC+03:00) Europe/Moscow', zoneId: 'Europe/Moscow' },
  { label: '(UTC+03:00) Europe/Chisinau', zoneId: 'Europe/Chisinau' },
  { label: '(UTC+03:00) Europe/Helsinki', zoneId: 'Europe/Helsinki' },
  { label: '(UTC+03:00) Asia/Beirut', zoneId: 'Asia/Beirut' },
  { label: '(UTC+03:00) Asia/Tel Aviv', zoneId: 'Asia/Tel_Aviv' },
  { label: '(UTC+03:00) Africa/Djibouti', zoneId: 'Africa/Djibouti' },
  { label: '(UTC+03:00) Europe/Simferopol', zoneId: 'Europe/Simferopol' },
  { label: '(UTC+03:00) Europe/Sofia', zoneId: 'Europe/Sofia' },
  { label: '(UTC+03:00) Asia/Gaza', zoneId: 'Asia/Gaza' },
  { label: '(UTC+03:00) Africa/Asmara', zoneId: 'Africa/Asmara' },
  { label: '(UTC+03:00) Europe/Riga', zoneId: 'Europe/Riga' },
  { label: '(UTC+03:00) Asia/Baghdad', zoneId: 'Asia/Baghdad' },
  { label: '(UTC+03:00) Asia/Damascus', zoneId: 'Asia/Damascus' },
  { label: '(UTC+03:00) Africa/Dar es Salaam', zoneId: 'Africa/Dar_es_Salaam' },
  { label: '(UTC+03:00) Africa/Addis Ababa', zoneId: 'Africa/Addis_Ababa' },
  { label: '(UTC+03:00) Europe/Uzhgorod', zoneId: 'Europe/Uzhgorod' },
  { label: '(UTC+03:00) Asia/Jerusalem', zoneId: 'Asia/Jerusalem' },
  { label: '(UTC+03:00) Asia/Riyadh', zoneId: 'Asia/Riyadh' },
  { label: '(UTC+03:00) Asia/Kuwait', zoneId: 'Asia/Kuwait' },
  { label: '(UTC+03:00) Europe/Kirov', zoneId: 'Europe/Kirov' },
  { label: '(UTC+03:00) Africa/Kampala', zoneId: 'Africa/Kampala' },
  { label: '(UTC+03:00) Europe/Minsk', zoneId: 'Europe/Minsk' },
  { label: '(UTC+03:00) Asia/Qatar', zoneId: 'Asia/Qatar' },
  { label: '(UTC+03:00) Europe/Kiev', zoneId: 'Europe/Kiev' },
  { label: '(UTC+03:00) Asia/Bahrain', zoneId: 'Asia/Bahrain' },
  { label: '(UTC+03:00) Europe/Vilnius', zoneId: 'Europe/Vilnius' },
  { label: '(UTC+03:00) Indian/Antananarivo', zoneId: 'Indian/Antananarivo' },
  { label: '(UTC+03:00) Indian/Mayotte', zoneId: 'Indian/Mayotte' },
  { label: '(UTC+03:00) Europe/Tallinn', zoneId: 'Europe/Tallinn' },
  { label: '(UTC+03:00) Turkey', zoneId: 'Turkey' },
  { label: '(UTC+03:00) Africa/Juba', zoneId: 'Africa/Juba' },
  { label: '(UTC+03:00) Asia/Nicosia', zoneId: 'Asia/Nicosia' },
  { label: '(UTC+03:00) Asia/Famagusta', zoneId: 'Asia/Famagusta' },
  { label: '(UTC+03:00) W-SU', zoneId: 'W-SU' },
  { label: '(UTC+03:00) EET', zoneId: 'EET' },
  { label: '(UTC+03:00) Asia/Hebron', zoneId: 'Asia/Hebron' },
  { label: '(UTC+03:00) Asia/Amman', zoneId: 'Asia/Amman' },
  { label: '(UTC+03:00) Europe/Nicosia', zoneId: 'Europe/Nicosia' },
  { label: '(UTC+03:00) Europe/Athens', zoneId: 'Europe/Athens' },
  { label: '(UTC+02:00) Africa/Cairo', zoneId: 'Africa/Cairo' },
  { label: '(UTC+02:00) Africa/Mbabane', zoneId: 'Africa/Mbabane' },
  { label: '(UTC+02:00) Europe/Brussels', zoneId: 'Europe/Brussels' },
  { label: '(UTC+02:00) Europe/Warsaw', zoneId: 'Europe/Warsaw' },
  { label: '(UTC+02:00) CET', zoneId: 'CET' },
  { label: '(UTC+02:00) Europe/Luxembourg', zoneId: 'Europe/Luxembourg' },
  { label: '(UTC+02:00) Etc/GMT-2', zoneId: 'Etc/GMT-2' },
  { label: '(UTC+02:00) Libya', zoneId: 'Libya' },
  { label: '(UTC+02:00) Africa/Kigali', zoneId: 'Africa/Kigali' },
  { label: '(UTC+02:00) Africa/Tripoli', zoneId: 'Africa/Tripoli' },
  { label: '(UTC+02:00) Europe/Kaliningrad', zoneId: 'Europe/Kaliningrad' },
  { label: '(UTC+02:00) Africa/Windhoek', zoneId: 'Africa/Windhoek' },
  { label: '(UTC+02:00) Europe/Malta', zoneId: 'Europe/Malta' },
  { label: '(UTC+02:00) Europe/Busingen', zoneId: 'Europe/Busingen' },
  { label: '(UTC+02:00) Europe/Skopje', zoneId: 'Europe/Skopje' },
  { label: '(UTC+02:00) Europe/Sarajevo', zoneId: 'Europe/Sarajevo' },
  { label: '(UTC+02:00) Europe/Rome', zoneId: 'Europe/Rome' },
  { label: '(UTC+02:00) Europe/Zurich', zoneId: 'Europe/Zurich' },
  { label: '(UTC+02:00) Europe/Gibraltar', zoneId: 'Europe/Gibraltar' },
  { label: '(UTC+02:00) Africa/Lubumbashi', zoneId: 'Africa/Lubumbashi' },
  { label: '(UTC+02:00) Europe/Vaduz', zoneId: 'Europe/Vaduz' },
  { label: '(UTC+02:00) Europe/Ljubljana', zoneId: 'Europe/Ljubljana' },
  { label: '(UTC+02:00) Europe/Berlin', zoneId: 'Europe/Berlin' },
  { label: '(UTC+02:00) Europe/Stockholm', zoneId: 'Europe/Stockholm' },
  { label: '(UTC+02:00) Europe/Budapest', zoneId: 'Europe/Budapest' },
  { label: '(UTC+02:00) Europe/Zagreb', zoneId: 'Europe/Zagreb' },
  { label: '(UTC+02:00) Europe/Paris', zoneId: 'Europe/Paris' },
  { label: '(UTC+02:00) Africa/Ceuta', zoneId: 'Africa/Ceuta' },
  { label: '(UTC+02:00) Europe/Prague', zoneId: 'Europe/Prague' },
  { label: '(UTC+02:00) Antarctica/Troll', zoneId: 'Antarctica/Troll' },
  { label: '(UTC+02:00) Africa/Gaborone', zoneId: 'Africa/Gaborone' },
  { label: '(UTC+02:00) Europe/Copenhagen', zoneId: 'Europe/Copenhagen' },
  { label: '(UTC+02:00) Europe/Vienna', zoneId: 'Europe/Vienna' },
  { label: '(UTC+02:00) Europe/Tirane', zoneId: 'Europe/Tirane' },
  { label: '(UTC+02:00) MET', zoneId: 'MET' },
  { label: '(UTC+02:00) Europe/Amsterdam', zoneId: 'Europe/Amsterdam' },
  { label: '(UTC+02:00) Africa/Maputo', zoneId: 'Africa/Maputo' },
  { label: '(UTC+02:00) Europe/San Marino', zoneId: 'Europe/San_Marino' },
  { label: '(UTC+02:00) Poland', zoneId: 'Poland' },
  { label: '(UTC+02:00) Europe/Andorra', zoneId: 'Europe/Andorra' },
  { label: '(UTC+02:00) Europe/Oslo', zoneId: 'Europe/Oslo' },
  { label: '(UTC+02:00) Europe/Podgorica', zoneId: 'Europe/Podgorica' },
  { label: '(UTC+02:00) Africa/Bujumbura', zoneId: 'Africa/Bujumbura' },
  { label: '(UTC+02:00) Atlantic/Jan Mayen', zoneId: 'Atlantic/Jan_Mayen' },
  { label: '(UTC+02:00) Africa/Maseru', zoneId: 'Africa/Maseru' },
  { label: '(UTC+02:00) Europe/Madrid', zoneId: 'Europe/Madrid' },
  { label: '(UTC+02:00) Africa/Blantyre', zoneId: 'Africa/Blantyre' },
  { label: '(UTC+02:00) Africa/Lusaka', zoneId: 'Africa/Lusaka' },
  { label: '(UTC+02:00) Africa/Harare', zoneId: 'Africa/Harare' },
  { label: '(UTC+02:00) Africa/Khartoum', zoneId: 'Africa/Khartoum' },
  { label: '(UTC+02:00) Africa/Johannesburg', zoneId: 'Africa/Johannesburg' },
  { label: '(UTC+02:00) Europe/Belgrade', zoneId: 'Europe/Belgrade' },
  { label: '(UTC+02:00) Europe/Bratislava', zoneId: 'Europe/Bratislava' },
  { label: '(UTC+02:00) Arctic/Longyearbyen', zoneId: 'Arctic/Longyearbyen' },
  { label: '(UTC+02:00) Egypt', zoneId: 'Egypt' },
  { label: '(UTC+02:00) Europe/Vatican', zoneId: 'Europe/Vatican' },
  { label: '(UTC+02:00) Europe/Monaco', zoneId: 'Europe/Monaco' },
  { label: '(UTC+01:00) Europe/London', zoneId: 'Europe/London' },
  { label: '(UTC+01:00) Etc/GMT-1', zoneId: 'Etc/GMT-1' },
  { label: '(UTC+01:00) Europe/Jersey', zoneId: 'Europe/Jersey' },
  { label: '(UTC+01:00) Europe/Guernsey', zoneId: 'Europe/Guernsey' },
  { label: '(UTC+01:00) Europe/Isle of Man', zoneId: 'Europe/Isle_of_Man' },
  { label: '(UTC+01:00) Africa/Tunis', zoneId: 'Africa/Tunis' },
  { label: '(UTC+01:00) Africa/Malabo', zoneId: 'Africa/Malabo' },
  { label: '(UTC+01:00) GB-Eire', zoneId: 'GB-Eire' },
  { label: '(UTC+01:00) Africa/Lagos', zoneId: 'Africa/Lagos' },
  { label: '(UTC+01:00) Africa/Algiers', zoneId: 'Africa/Algiers' },
  { label: '(UTC+01:00) GB', zoneId: 'GB' },
  { label: '(UTC+01:00) Portugal', zoneId: 'Portugal' },
  { label: '(UTC+01:00) Africa/Sao Tome', zoneId: 'Africa/Sao_Tome' },
  { label: '(UTC+01:00) Africa/Ndjamena', zoneId: 'Africa/Ndjamena' },
  { label: '(UTC+01:00) Atlantic/Faeroe', zoneId: 'Atlantic/Faeroe' },
  { label: '(UTC+01:00) Eire', zoneId: 'Eire' },
  { label: '(UTC+01:00) Atlantic/Faroe', zoneId: 'Atlantic/Faroe' },
  { label: '(UTC+01:00) Europe/Dublin', zoneId: 'Europe/Dublin' },
  { label: '(UTC+01:00) Africa/Libreville', zoneId: 'Africa/Libreville' },
  { label: '(UTC+01:00) Africa/El Aaiun', zoneId: 'Africa/El_Aaiun' },
  { label: '(UTC+01:00) Africa/Douala', zoneId: 'Africa/Douala' },
  { label: '(UTC+01:00) Africa/Brazzaville', zoneId: 'Africa/Brazzaville' },
  { label: '(UTC+01:00) Africa/Porto-Novo', zoneId: 'Africa/Porto-Novo' },
  { label: '(UTC+01:00) Atlantic/Madeira', zoneId: 'Atlantic/Madeira' },
  { label: '(UTC+01:00) Europe/Lisbon', zoneId: 'Europe/Lisbon' },
  { label: '(UTC+01:00) Atlantic/Canary', zoneId: 'Atlantic/Canary' },
  { label: '(UTC+01:00) Africa/Casablanca', zoneId: 'Africa/Casablanca' },
  { label: '(UTC+01:00) Europe/Belfast', zoneId: 'Europe/Belfast' },
  { label: '(UTC+01:00) Africa/Luanda', zoneId: 'Africa/Luanda' },
  { label: '(UTC+01:00) Africa/Kinshasa', zoneId: 'Africa/Kinshasa' },
  { label: '(UTC+01:00) Africa/Bangui', zoneId: 'Africa/Bangui' },
  { label: '(UTC+01:00) WET', zoneId: 'WET' },
  { label: '(UTC+01:00) Africa/Niamey', zoneId: 'Africa/Niamey' },
  { label: '(UTC+00:00) GMT', zoneId: 'GMT' },
  { label: '(UTC+00:00) Etc/GMT-0', zoneId: 'Etc/GMT-0' },
  { label: '(UTC+00:00) Atlantic/St Helena', zoneId: 'Atlantic/St_Helena' },
  { label: '(UTC+00:00) Etc/GMT+0', zoneId: 'Etc/GMT+0' },
  { label: '(UTC+00:00) Africa/Banjul', zoneId: 'Africa/Banjul' },
  { label: '(UTC+00:00) Etc/GMT', zoneId: 'Etc/GMT' },
  { label: '(UTC+00:00) Africa/Freetown', zoneId: 'Africa/Freetown' },
  { label: '(UTC+00:00) Africa/Bamako', zoneId: 'Africa/Bamako' },
  { label: '(UTC+00:00) Africa/Conakry', zoneId: 'Africa/Conakry' },
  { label: '(UTC+00:00) Universal', zoneId: 'Universal' },
  { label: '(UTC+00:00) Africa/Nouakchott', zoneId: 'Africa/Nouakchott' },
  { label: '(UTC+00:00) UTC', zoneId: 'UTC' },
  { label: '(UTC+00:00) Etc/Universal', zoneId: 'Etc/Universal' },
  { label: '(UTC+00:00) Atlantic/Azores', zoneId: 'Atlantic/Azores' },
  { label: '(UTC+00:00) Africa/Abidjan', zoneId: 'Africa/Abidjan' },
  { label: '(UTC+00:00) Africa/Accra', zoneId: 'Africa/Accra' },
  { label: '(UTC+00:00) Etc/UCT', zoneId: 'Etc/UCT' },
  { label: '(UTC+00:00) GMT0', zoneId: 'GMT0' },
  { label: '(UTC+00:00) Zulu', zoneId: 'Zulu' },
  { label: '(UTC+00:00) Africa/Ouagadougou', zoneId: 'Africa/Ouagadougou' },
  { label: '(UTC+00:00) Atlantic/Reykjavik', zoneId: 'Atlantic/Reykjavik' },
  { label: '(UTC+00:00) Etc/Zulu', zoneId: 'Etc/Zulu' },
  { label: '(UTC+00:00) Iceland', zoneId: 'Iceland' },
  { label: '(UTC+00:00) Africa/Lome', zoneId: 'Africa/Lome' },
  { label: '(UTC+00:00) Greenwich', zoneId: 'Greenwich' },
  { label: '(UTC+00:00) Etc/GMT0', zoneId: 'Etc/GMT0' },
  { label: '(UTC+00:00) America/Danmarkshavn', zoneId: 'America/Danmarkshavn' },
  { label: '(UTC+00:00) Africa/Dakar', zoneId: 'Africa/Dakar' },
  { label: '(UTC+00:00) America/Scoresbysund', zoneId: 'America/Scoresbysund' },
  { label: '(UTC+00:00) Africa/Bissau', zoneId: 'Africa/Bissau' },
  { label: '(UTC+00:00) Etc/Greenwich', zoneId: 'Etc/Greenwich' },
  { label: '(UTC+00:00) Africa/Timbuktu', zoneId: 'Africa/Timbuktu' },
  { label: '(UTC+00:00) UCT', zoneId: 'UCT' },
  { label: '(UTC+00:00) Africa/Monrovia', zoneId: 'Africa/Monrovia' },
  { label: '(UTC+00:00) Etc/UTC', zoneId: 'Etc/UTC' },
];
