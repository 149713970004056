import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { INSTANCE_TYPES } from '../../../constants';
import {
  MONITOR_PERIODS,
  REFRESH_RATES,
} from '../../../constants/monitoringConstants';
import { useUser } from '../../../contexts/UserContext';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text.js';

const useStyles = makeStyles((theme) => ({
  responsive: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1em',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(1050)]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

const MonitoringSettings = ({
  instance,
  settings,
  onNodeChange,
  onTermChange,
  onDateTimeChange,
  onRefreshChange,
}) => {
  const { t } = useTranslation('monitoring');
  const classes = useStyles();
  const { user } = useUser();

  const isSolrCloud = instance.type === INSTANCE_TYPES.SOLRCLOUD;

  return (
    <section>
      {isSolrCloud && (
        <Box display="flex" alignItems="center" style={{ gap: '1em' }}>
          <Text bodyBold>{t('instanceNode')}</Text>
          <FormControl
            size="small"
            style={{ minWidth: 250 }}
            variant="outlined"
          >
            <Select
              onChange={(e) => onNodeChange(e.target.value)}
              value={settings.node}
            >
              {instance.instances.map((node) => (
                <MenuItem value={node.subscriptNumber} key={node.id}>
                  {`${instance.name} - ${node.subscriptNumber}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Box className={classes.responsive}>
        <Box display="flex" style={{ gap: '1em' }}>
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 150 }}
          >
            <InputLabel>{t('timePeriod')}</InputLabel>
            <Select
              label={t('timePeriod')}
              onChange={(e) => onTermChange(e.target.value)}
              defaultValue={900}
            >
              {MONITOR_PERIODS.map((period) => (
                <MenuItem value={period.value} key={period.value}>
                  {t(period.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label={t('endDateTime')}
            type="datetime-local"
            variant="outlined"
            size="small"
            disabled={settings.refresh > 0}
            value={moment(settings.endDateTime)
              .tz(user?.timeZone)
              .format('YYYY-MM-DDTHH:mm:ss')}
            onChange={(e) => onDateTimeChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: moment()
                .add(1, 's')
                .tz(user?.timeZone)
                .format('YYYY-MM-DDTHH:mm:ss'),
            }}
            data-testid="datetime"
          />

          <Button
            variant="outlined"
            disabled={settings.refresh > 0}
            onClick={() =>
              onDateTimeChange(moment().format('YYYY-MM-DDTHH:mm:ss'))
            }
          >
            {t('now')}
          </Button>
        </Box>

        <Box display="flex" style={{ gap: '0.5em' }}>
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 150 }}
          >
            <InputLabel>{t('refreshRate', { ns: 'monitoring' })}</InputLabel>
            <Select
              label={t('refreshRate', { ns: 'monitoring' })}
              onChange={(e) => onRefreshChange(e.target.value)}
              defaultValue={0}
            >
              {REFRESH_RATES.map((period) => (
                <MenuItem value={period.value} key={period.value}>
                  {t(period.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </section>
  );
};

MonitoringSettings.propTypes = {
  instance: PropTypes.object,
  settings: PropTypes.object.isRequired,
  onNodeChange: PropTypes.func.isRequired,
  onTermChange: PropTypes.func.isRequired,
  onDateTimeChange: PropTypes.func.isRequired,
  onRefreshChange: PropTypes.func.isRequired,
};

export default MonitoringSettings;
