import Error from '../../components/sections/error/Error';
import i18next from '../../i18n';

const internalServer = {
  type: 500,
  title: 'Internal Server Error',
  content: [
    i18next.t('internalServerErrorPage', { ns: 'errors' }),
    i18next.t('internalServerErrorPageSubtext', { ns: 'errors' }),
  ],
};

const InternalServerError = () => <Error error={internalServer} />;
export default InternalServerError;
