import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const MENU_ITEM_MAX_HEIGHT = 250;

const ORIGIN = {
  ANCHOR: { vertical: 'bottom', horizontal: 'left' },
  TRANSFORM: { vertical: 'top', horizontal: 'left' },
};

const useStyles = makeStyles((theme) => ({
  menuItemStyles: {
    maxHeight: MENU_ITEM_MAX_HEIGHT,
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(4),
    },
  },
}));

const Dropdown = ({
  children,
  anchorEl,
  onClose,
  MenuListProps = { className: {} },
  ...restProps
}) => {
  const classes = useStyles();
  const { className: providedClasses, ...otherProps } = MenuListProps;

  const concatenatedClasses = (providedClasses) => {
    return !providedClasses || Object.keys(providedClasses).length === 0
      ? classes.menuItemStyles
      : classes.menuItemStyles.concat(' ', providedClasses);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={ORIGIN.ANCHOR}
      transformOrigin={ORIGIN.TRANSFORM}
      open={Boolean(anchorEl)}
      onClose={onClose}
      getContentAnchorEl={null}
      keepMounted
      MenuListProps={{
        ...otherProps,
        className: concatenatedClasses(providedClasses),
      }}
      {...restProps}
    >
      {children}
    </Menu>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  anchorEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(Element),
  ]),
  onClose: PropTypes.func.isRequired,
  MenuListProps: PropTypes.object,
};

export default Dropdown;
