import { instance, getToken } from './config';

const getHealthCheck = (projectId, instanceId) =>
  instance.get(`/projects/${projectId}/instance-sets/${instanceId}/hcs`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const createHealthCheck = (projectId, instanceId, body) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs`,
    body,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const updateHealthCheck = (projectId, instanceId, healthCheckId, body) =>
  instance.put(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}`,
    body,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const enableHealthCheck = (projectId, instanceId, healthCheckId) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/enable`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const disableHealthCheck = (projectId, instanceId, healthCheckId) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/disable`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const executeHealthCheck = (projectId, instanceId, healthCheckId) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/execute`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const getNotifications = (projectId, instanceId, healthCheckId, page = 0) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/notifications?page=${page}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const createEmailNotif = (projectId, instanceId, healthCheckId, body) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/notifications/email`,
    body,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const createWebhookNotif = (projectId, instanceId, healthCheckId, body) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/notifications/webhook`,
    body,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const editEmailNotif = (projectId, instanceId, healthCheckId, notifId, body) =>
  instance.put(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/notifications/email/${notifId}`,
    body,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const editWebhookNotif = (
  projectId,
  instanceId,
  healthCheckId,
  notifId,
  body
) =>
  instance.put(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/notifications/webhook/${notifId}`,
    body,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const deleteNotif = (projectId, instanceId, healthCheckId, notifId) =>
  instance.delete(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/notifications/${notifId}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const sendTestEmail = (projectId, instanceId, healthCheckId, body) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/notifications/email/test`,
    body,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const sendTestWebhook = (projectId, instanceId, healthCheckId, body) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/hcs/${healthCheckId}/notifications/webhook/test`,
    body,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

export const healthCheckApis = {
  getHealthCheck,
  createHealthCheck,
  updateHealthCheck,
  enableHealthCheck,
  disableHealthCheck,
  executeHealthCheck,
  getNotifications,
  createEmailNotif,
  createWebhookNotif,
  editEmailNotif,
  editWebhookNotif,
  deleteNotif,
  sendTestEmail,
  sendTestWebhook,
};
