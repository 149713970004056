import { values } from 'lodash';
import * as yup from 'yup';

import { INSTANCE_CATEGORY, INSTANCE_PLANS } from '../../../constants';
import i18next from '../../../i18n';
import { formatBytes, sentenceCase } from '../../../utils/utils';
import { FILE_SIZE, SOLR_VERSION, SUPPORTED_FORMATS } from './constants';

export const instanceSchema = () => ({
  plan: yup
    .string()
    .oneOf(
      values([INSTANCE_CATEGORY.COMMUNITY, ...Object.values(INSTANCE_PLANS)])
    )
    .required(),
  solrVersion: yup.string().oneOf(SOLR_VERSION).required(),
  name: yup
    .string()
    .required(
      sentenceCase(
        i18next.t('inputRequired', {
          ns: 'validations',
          field: i18next.t('instanceName', { ns: 'fields' }),
        })
      )
    )
    .min(2, i18next.t('minLength', { ns: 'validations', min: '2' }))
    .max(32, i18next.t('maxLength', { ns: 'validations', max: '32' }))
    .matches(
      /^[ぁ-んァ-ン一-龯ーA-Za-z0-9][ぁ-んァ-ン一-龯ーA-Za-z0-9_-\s]*[ぁ-んァ-ン一-龯ーA-Za-z0-9]$/,
      i18next.t('nameFormat', { ns: 'validations' })
    ),
  description: yup
    .string()
    .max(256, i18next.t('maxLength', { ns: 'validations', max: '256' })),
  icon: yup
    .mixed()
    .test(
      'fileFormat',
      i18next.t('invalidImageFormat', { ns: 'validations' }),
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      'fileSize',
      i18next.t('invalidSize', {
        ns: 'validations',
        size: formatBytes(FILE_SIZE.BYTES, 0),
      }),
      (value) => !value || (value && value.size <= FILE_SIZE.BYTES)
    ),
  subdomain: yup
    .string()
    .required(
      sentenceCase(
        i18next.t('inputRequired', {
          ns: 'validations',
          field: i18next.t('instanceSubdomain', { ns: 'fields' }),
        })
      )
    )
    .max(64, i18next.t('maxLength', { ns: 'validations', max: '64' }))
    .matches(
      /^[0-9A-Za-z][0-9A-Za-z-]*[0-9A-Za-z]$/,
      i18next.t('invalidSubdomain', { ns: 'validations' })
    ),
  cidrs: yup.array(),
});

export const schema = (checkDuplicate) =>
  yup.object().shape({
    type: instanceSchema().type,
    plan: instanceSchema().plan,
    solrVersion: instanceSchema().solrVersion,
    icon: instanceSchema().icon,
    name: instanceSchema().name,
    description: instanceSchema().description,
    subdomain: instanceSchema().subdomain.test(
      'isUnique',
      sentenceCase(
        i18next.t('duplicate', {
          ns: 'validations',
          field: i18next.t('instanceSubdomain', { ns: 'fields' }),
        })
      ),
      async (value) => {
        if (!value) return true;
        const { isValid } = await checkDuplicate(value);
        return isValid;
      }
    ),
    cidrs: instanceSchema().ipAddresses,
    storageSize: instanceSchema().storageSize,
  });
