import { useState } from 'react';

import { Checkbox, DialogActions, FormControlLabel } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MAX_FILE_NUMBER, TEMP_FILE_SIZE } from '../../../../constants';
import {
  fileNotJsonOrCsv,
  fileTooBig,
  formatBytes,
} from '../../../../utils/utils';
import Button from '../../../elements/button/Button';
import Modal from '../../../sections/modal/Modal';
import FileUpload from '../../file-upload/FileUpload';

const ImportModal = ({ open, handleClose, dictionary, handleImport }) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [invalidAttributes, setInvalidAttributes] = useState({
    size: false,
    format: false,
  });

  const onImport = async (options) => {
    setIsSubmitting(true);
    await handleImport(dictionary.id, files[0], options);
    setIsSubmitting(false);
    onImportModelClose();
  };

  const onImportModelClose = () => {
    setFiles([]);
    setInvalidAttributes({
      size: false,
      format: false,
    });
    handleClose();
  };

  const removeFile = (fileIndex) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(fileIndex), 1);
    setFiles(newFiles);
  };

  const onDrop = (acceptedFiles) => {
    if (isEmpty(acceptedFiles) || files.length >= MAX_FILE_NUMBER) return;

    const invalidSize = fileTooBig(acceptedFiles[0].size, TEMP_FILE_SIZE.BYTES);
    const invalidFormat = fileNotJsonOrCsv(acceptedFiles[0].type);

    if (!invalidSize && !invalidFormat) {
      setFiles([...files, ...acceptedFiles]);
    }

    setInvalidAttributes({
      size: invalidSize,
      format: invalidFormat,
    });
  };

  const { handleSubmit, control } = useForm();
  const {
    getRootProps,
    open: openSelect,
    getInputProps,
  } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Modal
      openModal={open}
      title={t('synonym.importToDictionary', {
        ns: 'sectionTitles',
        name: dictionary.name,
      })}
      handleClose={onImportModelClose}
    >
      <form onSubmit={handleSubmit(onImport)}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <FileUpload
            files={files}
            open={openSelect}
            handleRemove={removeFile}
            formatError={invalidAttributes.format}
            sizeError={invalidAttributes.size}
            sizeLimit={formatBytes(TEMP_FILE_SIZE.BYTES, 0)}
            helperText={t('dropOneJsonOrCsvFile', {
              ns: 'instance',
            })}
            fileErrorMsg={t('onlyJsonCsvFile', {
              ns: 'descriptions',
            })}
          />
          <input {...getInputProps()} aria-label="upload file" />
        </div>
        <div>
          <Controller
            name="clear"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} disabled checked={field.value} />}
                label={t('synonym.clearData', { ns: 'project' })}
              />
            )}
          />
        </div>

        <DialogActions>
          <Button
            color="default"
            variant="text"
            type="reset"
            onClick={onImportModelClose}
          >
            {t('cancel', { ns: 'buttons' })}
          </Button>
          {isSubmitting ? (
            <Button variant="text" disabled>
              {t('confirm', { ns: 'buttons' })}
              <CircularProgress size={15} style={{ marginLeft: 10 }} />
            </Button>
          ) : (
            <Button
              disabled={
                isEmpty(files) ||
                Object.values(invalidAttributes).some((v) => v)
              }
              variant="text"
              type="submit"
            >
              {t('confirm', { ns: 'buttons' })}
            </Button>
          )}
        </DialogActions>
      </form>
    </Modal>
  );
};

ImportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dictionary: PropTypes.object,
  handleImport: PropTypes.func.isRequired,
};

export default ImportModal;
