import { Box, Chip, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  cpu: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#0c63a6',
  },
  gpu: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#fdc60e',
  },
}));

const ModelChip = ({ category, ...restProps }) => {
  const classes = useStyles();

  return (
    <Box {...restProps}>
      <Chip
        size="small"
        label={category}
        className={category ? classes[category.toLowerCase()] : ''}
      />
    </Box>
  );
};

ModelChip.propTypes = {
  category: PropTypes.string.isRequired,
};

export default ModelChip;
