import { useState } from 'react';

import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Modal from '../../sections/modal/Modal';

const DeleteNotificationButton = ({ notif, onDelete }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [modal, setModal] = useState(false);

  const handleOpenModal = () => setModal(true);
  const handleCloseModal = () => setModal(false);
  const handleDelete = () => {
    onDelete(notif.id);
    handleCloseModal();
  };

  return (
    <>
      {isSmallScreen ? (
        <IconButton onClick={handleOpenModal}>
          <DeleteForeverIcon />
        </IconButton>
      ) : (
        <Button
          color="danger"
          startIcon={<DeleteForeverIcon />}
          onClick={handleOpenModal}
        >
          {t('delete', { ns: 'buttons' })}
        </Button>
      )}
      <Modal
        openModal={modal}
        title={t('monitoring.notifDeleteConfirm', { ns: 'sectionTitles' })}
        showActions
        deleteButton
        handleConfirm={handleDelete}
        handleClose={handleCloseModal}
      >
        {t('areYouSure', { ns: 'descriptions', text: 'notification' })}
        {t('cantUndo', { ns: 'descriptions' })}
      </Modal>
    </>
  );
};

DeleteNotificationButton.propTypes = {
  notif: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteNotificationButton;
