import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../contexts/UserContext';

const CustomTick = (props) => {
  const { user } = useUser();
  const { t } = useTranslation();

  const { x, y, payload } = props;

  const formatXAxis = (tickItem) =>
    moment(tickItem * 1000)
      .tz(user?.timeZone)
      .format(
        t('format.monitorDate', {
          ns: 'general',
        })
      );

  const [time, date] = formatXAxis(payload.value).split(' ');

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        transform="rotate(0)"
      >
        <tspan x={0} dy="1em">
          {time}
        </tspan>
        <tspan x={0} dy="1.2em">
          {date}
        </tspan>
      </text>
    </g>
  );
};

export default CustomTick;

CustomTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};
