import { useEffect, useState } from 'react';

import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { requestAll } from '../../../apis/config';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import Title from '../../../components/elements/title/Title';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import List from '../../../components/sections/list/List';
import { mapSolrStatusToInstance } from '../../../utils/utils';

const ProjectInstanceListPage = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();

  const [project, setProject] = useState(null);
  const [instanceList, setInstanceList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [{ data: projectRes }, { data: instancesRes }] = await requestAll([
        projectApis.getProject(projectId),
        solrApis.getInstances(projectId),
      ]);

      const instancesWithSolrRes = await Promise.all(
        instancesRes.content.map(async (instance) => {
          try {
            const { data: solrStatus } = await solrApis.getSolrStatus(
              projectId,
              instance.id
            );
            const solrWithStatus = mapSolrStatusToInstance(
              instance,
              solrStatus
            );
            return solrWithStatus;
          } catch (error) {
            return { ...instance, solrRunning: [] };
          }
        })
      );

      setProject(projectRes);
      setInstanceList(instancesWithSolrRes);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {loading && <Loading style={{ marginTop: '1em' }} />}
      {error && (
        <Alert style={{ marginTop: '1em' }} severity="error">
          {t('fetchInstanceError', { ns: 'notifications' })}
        </Alert>
      )}
      {!loading && instanceList && (
        <section>
          <Breadcrumbs projectName={project.name} />
          <Title text={t('instances', { ns: 'instance' })} />
          <List isInstanceList data={instanceList} />
        </section>
      )}
    </Container>
  );
};

export default ProjectInstanceListPage;
