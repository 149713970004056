import { useEffect, useState } from 'react';

import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { INDEXING_FILE_SIZE, MAX_FILE_NUMBER } from '../../../constants';
import {
  fileNotJsonOrXml,
  fileTooBig,
  formatBytes,
} from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Loading from '../../elements/loading/Loading';
import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';
import ModalButtons from '../../sections/modal/ModalButtons';
import FileUpload from '../file-upload/FileUpload';

const IndexingButton = ({ instance, getCollections, onUpload }) => {
  const { t } = useTranslation();

  const [collection, setCollection] = useState('');
  const [collections, setCollections] = useState([]);
  const [files, setFiles] = useState([]);
  const [formatError, setFormatError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [modal, setModal] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  const toggleModal = () => setModal(!modal);
  const fetchCollections = async () => {
    setLoading(true);
    try {
      const collectionRes = await getCollections(instance);
      setCollections(collectionRes);
      setCollection(collectionRes[0]);
    } catch {
      setCollections([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!instance || !modal) return;
    fetchCollections();
    // eslint-disable-next-line
  }, [modal]);

  const handleChangeCollection = (event) => setCollection(event.target.value);

  const onDrop = (acceptedFiles) => {
    if (isEmpty(acceptedFiles) || files.length >= MAX_FILE_NUMBER) return;

    setSizeError(fileTooBig(acceptedFiles[0].size, INDEXING_FILE_SIZE.BYTES));
    setFormatError(fileNotJsonOrXml(acceptedFiles[0].type));

    if (
      !fileTooBig(acceptedFiles[0].size, INDEXING_FILE_SIZE.BYTES) &&
      !fileNotJsonOrXml(acceptedFiles[0].type)
    ) {
      setSizeError(false);
      setFormatError(false);
      setFiles([...files, ...acceptedFiles]);
    }
  };

  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });
  const removeFile = () => setFiles([]);
  const handleIndexDocuments = async (e) => {
    e.preventDefault();
    setProcessing(true);
    await onUpload(collection, files[0]);
    await toggleModal();
    setProcessing(false);
    setFiles([]);
  };

  return (
    <section>
      <Button
        variant="outlined"
        onClick={toggleModal}
        endIcon={<KeyboardArrowDownIcon />}
        aria-label="register docs"
      >
        {t('registerDocuments', { ns: 'buttons' })}
      </Button>
      <Modal
        openModal={modal}
        title={t('fileUpload', { ns: 'instance' })}
        handleClose={toggleModal}
      >
        {!loading ? (
          isEmpty(collections) ? (
            <Box my={1}>
              <Alert severity="error" aria-label="no-collections">
                {t('noCollectionsFound', { ns: 'descriptions' })}
              </Alert>
            </Box>
          ) : (
            <>
              <Box
                display="flex"
                alignItems="center"
                ml={1}
                style={{ gap: '0.5em' }}
              >
                <Text bodyBold>{t('collection', { ns: 'instance' })}</Text>
                <FormControl variant="outlined" size="small">
                  <Select
                    value={collection}
                    onChange={handleChangeCollection}
                    variant="outlined"
                  >
                    {collections.map((c) => (
                      <MenuItem key={c} value={c}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mt={2}>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <FileUpload
                    files={files}
                    open={open}
                    handleRemove={removeFile}
                    formatError={formatError}
                    sizeLimit={formatBytes(INDEXING_FILE_SIZE.BYTES, 0)}
                    sizeError={sizeError}
                    helperText={t('dropOneJsonOrXmlFile', { ns: 'instance' })}
                    fileErrorMsg={t('onlyJsonXmlFile', { ns: 'descriptions' })}
                  />
                </div>
                <ModalButtons
                  actionProps={{
                    disabled: files.length === 0 || processing,
                    startIcon: files.length > 0 && processing && (
                      <CircularProgress size={20} />
                    ),
                  }}
                  actionText={t('indexDocs', { ns: 'instance' })}
                  onCancel={toggleModal}
                  onAction={handleIndexDocuments}
                />
              </Box>
            </>
          )
        ) : (
          <Loading />
        )}
      </Modal>
    </section>
  );
};

IndexingButton.propTypes = {
  instance: PropTypes.object.isRequired,
  getCollections: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

export default IndexingButton;
