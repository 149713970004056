import { useState } from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentenceCase } from '../../../../utils/utils';
import Button from '../../../elements/button/Button';
import Text from '../../../elements/text/Text';
import Title from '../../../elements/title/Title';
import NoContent from '../../../sections/no-content/NoContent';
import BillingInfoModal from './BillingInfoModal';

const useStyles = makeStyles((theme) => ({
  hasData: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  noData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  button: {
    margin: theme.spacing(2),
    float: 'right',
  },
  table: {
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '96%',
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const BillingInfo = ({ billingInfo, handleConfirm }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const billingKeys = [
    t('companyName', { ns: 'fields' }),
    t('departmentName', { ns: 'fields' }),
    t('billingContact', { ns: 'fields' }),
    t('email', { ns: 'fields' }),
    t('address', { ns: 'fields' }),
  ];

  const billingValues = [
    billingInfo?.payerCompany,
    billingInfo?.payerSection,
    billingInfo?.payerName,
    billingInfo?.payerEmail,
    billingInfo?.payerAddress,
  ];

  return (
    <>
      <Title subTitle text={t('billingInfo', { ns: 'sectionTitles' })} />
      {billingInfo ? (
        <Box className={classes.hasData} aria-label="billing-info-section">
          <Card>
            <Button
              className={classes.button}
              color="light"
              size="small"
              onClick={toggleModal}
            >
              {t('change', { ns: 'buttons' })}
            </Button>
            <TableContainer className={classes.table}>
              <Table size="small">
                <TableBody>
                  {billingKeys.map((item, i) => (
                    <TableRow className={classes.tableRow} key={item}>
                      <TableCell>
                        <Text body text={item} />
                      </TableCell>
                      <TableCell>{billingValues[i]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Box>
      ) : (
        <NoContent
          title={sentenceCase(
            t('empty', {
              ns: 'descriptions',
              text: t('billingInfo', { ns: 'sectionTitles' }),
            })
          )}
          button={
            <Button onClick={toggleModal}>
              {t('add', {
                ns: 'buttons',
                text: t('billingInfo', { ns: 'sectionTitles' }),
              })}
            </Button>
          }
        />
      )}

      <BillingInfoModal
        billingData={billingInfo}
        visible={modal}
        handleClose={toggleModal}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

BillingInfo.propTypes = {
  billingInfo: PropTypes.object,
  handleConfirm: PropTypes.func.isRequired,
};

export default BillingInfo;
