import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  TextField,
  Container,
  Link,
  DialogActions,
  Box,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';

import { INSTANCE_STATE } from '../../../apis/constants';
import { IPIFY_API } from '../../../apis/externalApis';
import { DEFAULT_SUBNET_MASK, MAX_CIDRS_LIMIT } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';
import { formatDateTimeUTC } from '../../../utils/timeUtils';
import Button from '../../elements/button/Button';
import Table from '../../elements/table/Table';
import Text from '../../elements/text/Text';
import Title from '../../elements/title/Title';
import Modal from '../../sections/modal/Modal';
import UserAvatar from '../../sections/user-avatar/UserAvatar';
import { createCompactRows, createRows, schema, tableHeaders } from './utils';

const convertToRawCidrs = (cidrList) => {
  if (cidrList.length === 0) {
    return [];
  } else {
    return cidrList.map((c) => c.cidr);
  }
};

const convertToFormattedCidrs = (cidrList) => {
  if (cidrList.length === 0) {
    return [];
  } else {
    return cidrList.map((c) => ({ cidr: c }));
  }
};

const CidrAddressSection = ({
  cidrs,
  instanceState,
  onUpdateCidrRange,
  compact,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [addModal, setAddModal] = useState(false);
  const [removeIpRange, setRemoveIpRange] = useState(null);

  const rawCidrList = convertToRawCidrs(cidrs);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema(rawCidrList)),
    shouldUnregister: true,
  });

  const toggleAddModal = () => setAddModal(!addModal);
  const openRemoveModal = (selectedIp) => setRemoveIpRange(selectedIp);
  const closeRemoveModal = () => setRemoveIpRange(null);
  const onAddSubmit = (data) => {
    toggleAddModal();
    onUpdateCidrRange([...cidrs, data]);
  };
  const handleRemove = () => {
    const updatedCidrList = rawCidrList.filter(
      (cidr) => cidr !== removeIpRange
    );
    const formattedCidrs = convertToFormattedCidrs(updatedCidrList);
    onUpdateCidrRange(formattedCidrs);
    closeRemoveModal();
  };
  const generateCIDR = async () => {
    const { data: detectedIp } = await axios.get(IPIFY_API);
    setValue('cidr', detectedIp + DEFAULT_SUBNET_MASK, {
      shouldValidate: true,
    });
  };

  const addIpRangeForm = (
    <form onSubmit={handleSubmit(onAddSubmit)}>
      <Controller
        name="cidr"
        control={control}
        defaultValue={''}
        render={({ field }) => (
          <TextField
            {...field}
            id="cidr"
            fullWidth
            autoComplete="off"
            error={!!errors?.cidr}
            helperText={errors?.cidr?.message}
            label={t('ipRange', { ns: 'fields' })}
          />
        )}
      />

      <DialogActions style={{ marginRight: '-0.5em' }}>
        <Button
          color="default"
          variant="text"
          type="reset"
          onClick={toggleAddModal}
        >
          {t('cancel', { ns: 'buttons' })}
        </Button>
        <Button
          variant="text"
          onClick={generateCIDR}
          aria-label="generate CIDR"
        >
          {t('generateCIDR', { ns: 'buttons' })}
        </Button>
        <Button disabled={!isValid} onClick={handleSubmit(onAddSubmit)}>
          {t('save', { ns: 'buttons' })}
        </Button>
      </DialogActions>
    </form>
  );

  const userAvatar = (user) => (
    <Box display="flex" justifyContent="center" alignItems="center">
      <UserAvatar user={user} />
      <Text style={{ marginLeft: '0.5em' }}>{user.publicName}</Text>
    </Box>
  );

  const deleteButton = (ip) => (
    <Button
      key={ip}
      aria-label="delete"
      color="danger"
      size="small"
      onClick={() => openRemoveModal(ip)}
      disabled={instanceState !== INSTANCE_STATE.RUNNING}
      startIcon={<DeleteIcon />}
    >
      {t('delete', { ns: 'buttons' })}
    </Button>
  );

  const tableRows = cidrs.map((c) =>
    compact
      ? createCompactRows(c.cidr, deleteButton(c.cidr))
      : createRows(
          c.cidr,
          userAvatar(c.meta.createUser),
          formatDateTimeUTC(c.meta.createDate, user.timeZone),
          deleteButton(c.cidr)
        )
  );

  return (
    <section>
      {!compact && (
        <Title subTitle text={t('allowedIpRange', { ns: 'sectionTitles' })} />
      )}
      <Container>
        <Text>
          <Trans
            t={t}
            i18nKey="cidrSection"
            ns="descriptions"
            components={[
              <Link
                key="0"
                target="_blank"
                href="https://www.keycdn.com/support/what-is-cidr"
                rel="noreferrer"
              />,
            ]}
          />
        </Text>

        {instanceState !== INSTANCE_STATE.RUNNING ? (
          <Alert
            severity="info"
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
          >
            {t('instanceIsBusy', { ns: 'descriptions' })}
          </Alert>
        ) : (
          <>
            {rawCidrList.length < MAX_CIDRS_LIMIT && (
              <Button
                variant="text"
                onClick={toggleAddModal}
                style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
              >
                <AddIcon />
                {t('add', {
                  ns: 'buttons',
                  text: t('ipRange', { ns: 'fields' }),
                })}
              </Button>
            )}
            <Table
              small
              headers={tableHeaders(compact)}
              rows={tableRows}
              emptyText={t('noIpSet', { ns: 'descriptions' })}
            />
          </>
        )}

        <Modal
          openModal={addModal}
          title={t('addition', {
            ns: 'project',
            text: t('ipRange', { ns: 'fields' }),
          })}
          handleClose={toggleAddModal}
        >
          {addIpRangeForm}
        </Modal>
        <Modal
          openModal={!!removeIpRange}
          title={t('deletion', {
            ns: 'project',
            text: t('ipRange', { ns: 'fields' }),
          })}
          handleClose={closeRemoveModal}
          handleConfirm={handleRemove}
          showActions
        >
          <Text>
            {t('deleteIpRange', {
              ns: 'descriptions',
              text: removeIpRange,
            })}
          </Text>
        </Modal>
      </Container>
    </section>
  );
};

CidrAddressSection.propTypes = {
  cidrs: PropTypes.array.isRequired,
  instanceState: PropTypes.string,
  onUpdateCidrRange: PropTypes.func.isRequired,
  compact: PropTypes.bool,
};

export default CidrAddressSection;
