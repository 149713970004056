import 'process';

import Keycloak from 'keycloak-js';

const keycloakConfig = {
  clientId: process.env.REACT_APP_KEYCLOAK_RESOURCE || 'ks-react-client',
  publicClient: true,
  realm: process.env.REACT_APP_KEYCLOAK_REALM || 'development',
  sslRequired: process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED || 'external',
  url:
    process.env.REACT_APP_KEYCLOAK_URL || 'https://auth.kandasearch.com/auth/',
};

export const keycloak = new Keycloak(keycloakConfig);
export const initOptions = { pkceMethod: 'S256' };
