import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentenceCase } from '../../../../utils/utils';
import Text from '../../../elements/text/Text';
import Modal from '../../../sections/modal/Modal';

const DeleteModal = ({
  open,
  handleClose,
  id,
  handleDelete,
  isSynonymSet = false,
}) => {
  const { t } = useTranslation();

  const onDelete = async () => {
    await handleDelete(id);
    handleClose();
  };

  const title = !isSynonymSet
    ? t('synonym.deleteDictionary', {
        ns: 'sectionTitles',
      })
    : t('synonym.deleteSynonymSet', {
        ns: 'sectionTitles',
      });

  const target = !isSynonymSet
    ? t('synonym.dictionary', { ns: 'project' })
    : t('synonym.set', { ns: 'project' });

  return (
    <Modal
      openModal={open}
      title={title}
      showActions
      handleClose={handleClose}
      handleConfirm={onDelete}
    >
      <Text>
        {sentenceCase(
          t('areYouSure', {
            ns: 'descriptions',
            text: target,
          })
        )}
      </Text>
      <Text>{t('cantUndo', { ns: 'descriptions' })}</Text>
    </Modal>
  );
};

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  isSynonymSet: PropTypes.bool,
};

export default DeleteModal;
