import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-css';

import NoContent from '../../sections/no-content/NoContent';
import GalleryImage from './GalleryImage';

import 'react-medium-image-zoom/dist/styles.css';
import './masonry-default.css';

const breakpointColumns = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
};

const Gallery = ({ images, ...otherProps }) => {
  if (isEmpty(images)) {
    return <NoContent title={'アップロードしたイメージが見つかりません。'} />;
  }

  return (
    <Masonry
      breakpointCols={breakpointColumns}
      className="masonry-grid"
      columnClassName="masonry-grid-column"
    >
      {images.map((img) => (
        <GalleryImage key={img.id} image={img} {...otherProps} />
      ))}
    </Masonry>
  );
};

Gallery.propTypes = {
  images: PropTypes.array,
};

export default Gallery;
