import { CircularProgress, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isInstanceRunning } from '../../../apis/constants';
import { INSTANCE_CATEGORY, INSTANCE_TYPES } from '../../../constants';
import Button from '../../elements/button/Button';

const SolrButtons = ({
  instance,
  solrProcessing,
  onStartInstance,
  onStopInstance,
  onRestartInstance,
}) => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { category, type } = instance;
  const status = instance?.instances[0]?.status;

  const isStandalone = type === INSTANCE_TYPES.STANDALONE;
  const isRunning = isInstanceRunning(instance);
  const isCommunityInstance = category === INSTANCE_CATEGORY.COMMUNITY;

  return (
    isStandalone &&
    (solrProcessing ? (
      <Button
        fullWidth={isSmallScreen}
        disabled
        startIcon={solrProcessing && <CircularProgress size={20} />}
      >
        {t('pending', { ns: 'buttons' })}
      </Button>
    ) : isCommunityInstance ? (
      <Button
        id="restart-button"
        fullWidth={isSmallScreen}
        disabled={!isRunning}
        onClick={onRestartInstance}
      >
        {t('restart', { ns: 'buttons' })}
      </Button>
    ) : status ? (
      <Button
        id="stop-button"
        fullWidth={isSmallScreen}
        color="light"
        onClick={onStopInstance}
      >
        {t('stop', { ns: 'buttons' })}
      </Button>
    ) : (
      <Button
        id="start-button"
        fullWidth={isSmallScreen}
        onClick={onStartInstance}
        disabled={!isRunning}
      >
        {t('start', { ns: 'buttons' })}
      </Button>
    ))
  );
};

SolrButtons.propTypes = {
  instance: PropTypes.object,
  solrProcessing: PropTypes.bool,
  onStartInstance: PropTypes.func.isRequired,
  onStopInstance: PropTypes.func.isRequired,
  onRestartInstance: PropTypes.func.isRequired,
};

export default SolrButtons;
