import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Container, Step, StepLabel, Stepper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { INSTANCE_CATEGORY, INSTANCE_PLANS } from '../../../constants';
import { projectSetupComplete } from '../../../utils/paymentUtils';
import Button from '../../elements/button/Button';
import Confirmation from './Confirmation';
import { DEFAULT_COMMUNITY_SOLR_VERSION } from './constants';
import InstanceForm from './InstanceForm';
import Payment from './Payment';
import Plan from './Plan';
import { schema } from './schema';

const InstanceStepper = ({
  community,
  project,
  onCheckDuplicate,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);

  const handleBack = () => setStep(step - 1);
  const handleNext = () => setStep(step + 1);

  const stepTitles = [
    t('planSelection', { ns: 'instance' }),
    t('methodInfo', { ns: 'sectionTitles' }),
    t('instanceInfo', { ns: 'instance' }),
    t('finalConfirmation', { ns: 'instance' }),
  ];

  const { handleSubmit, ...formMethods } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema(onCheckDuplicate)),
    defaultValues: {
      plan: INSTANCE_PLANS.MEDIUM,
      card: project.card?.[0],
      solrVersion: DEFAULT_COMMUNITY_SOLR_VERSION,
      name: '',
      description: '',
      subdomain: '',
      cidrs: [],
    },
  });

  const { cards, billingInfo, managers } = project;
  const { plan, name, description, subdomain } = formMethods.watch();

  const isCommunity = plan === INSTANCE_CATEGORY.COMMUNITY;

  const canProceed = () => {
    const errors = formMethods.formState.errors;
    const noErrors = Object.keys(errors).length === 0;

    switch (step) {
      case 0:
        return !errors?.plan;
      case 1:
        return (
          isCommunity || projectSetupComplete(cards, billingInfo, managers)
        );
      case 2:
        return !!name && !!subdomain && noErrors;
      default:
        return false;
    }
  };

  useEffect(() => {
    canProceed();
    // eslint-disable-next-line
  }, [plan, name, description, subdomain, step]);

  return (
    <FormProvider {...formMethods}>
      <Stepper activeStep={step} alternativeLabel>
        {stepTitles.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container maxWidth="md">
          {step === 0 && <Plan community={community} />}
          {step === 1 && <Payment project={project} />}
          {step === 2 && <InstanceForm projectName={project.name} />}
          {step === 3 && (
            <Confirmation card={cards?.[0]} billingInfo={billingInfo} />
          )}
          <Box display="flex" justifyContent="space-between" mt={1}>
            <Button
              aria-label="previous step"
              variant="outlined"
              disabled={!step}
              onClick={handleBack}
            >
              {t('previousStep', { ns: 'buttons' })}
            </Button>
            {step === stepTitles.length - 1 ? (
              <Button arial-label="confirm" onClick={handleSubmit(onSubmit)}>
                {t('confirm', { ns: 'buttons' })}
              </Button>
            ) : (
              <Button
                aria-label="next step"
                onClick={handleNext}
                disabled={!canProceed()}
              >
                {t('nextStep', { ns: 'buttons' })}
              </Button>
            )}
          </Box>
        </Container>
      </form>
    </FormProvider>
  );
};

InstanceStepper.propTypes = {
  community: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  onCheckDuplicate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InstanceStepper;
