import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentenceCase, formatUserInfo } from '../../../../utils/utils';
import Text from '../../../elements/text/Text';
import Modal from '../../../sections/modal/Modal';
import NoContent from '../../../sections/no-content/NoContent';
import UserAvatar from '../../../sections/user-avatar/UserAvatar';
import ExecutionDateCell from '../table-columns/ExecutionDateCell';
import InstanceCell from '../table-columns/InstanceCell';
import StatusCell from '../table-columns/StatusCell';

const RestoreTable = ({ restoreList, handleDelete }) => {
  const { t } = useTranslation();

  const [selectedRestore, setSelectedRestore] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const TABLE_HEADERS = [
    t('backupSubject', { ns: 'fields' }),
    t('targetInstance', { ns: 'instance' }),
    t('creator', { ns: 'project' }),
    t('backup.executeDate', { ns: 'project' }),
  ];

  const handleDeleteModalOpen = (item) => {
    setSelectedRestore(item);
    setOpenDeleteModal(true);
  };
  const handleDeleteModalClose = () => {
    setSelectedRestore(null);
    setOpenDeleteModal(false);
  };

  const onDelete = async (id) => {
    await handleDelete(id);
    handleDeleteModalClose();
  };

  return isEmpty(restoreList) ? (
    <NoContent
      title={sentenceCase(
        t('empty', {
          ns: 'descriptions',
          text: t('backup.restoreData', { ns: 'project' }),
        })
      )}
    />
  ) : (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" aria-label="table">
        <TableHead>
          <TableRow>
            {TABLE_HEADERS.map((header, i) => (
              <TableCell align={i !== 0 ? 'center' : 'left'} key={header}>
                {header}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {restoreList.map((restore, index) => (
            <TableRow key={index} aria-label="table row">
              <StatusCell subject={restore.subject} status={restore.status} />
              <InstanceCell
                instance={restore.targetInstanceSetName}
                collection={restore.targetCollection}
                category={restore.backupCategory}
                solrType={restore.targetSolrType}
                node={restore.instanceSubscript}
                solrVersion={restore.solrVersion}
              />
              <TableCell align="center">
                <UserAvatar
                  user={formatUserInfo(restore.createdBy, restore.author)}
                />
              </TableCell>
              <ExecutionDateCell
                start={restore.startDate}
                end={restore.endDate}
              />
              <TableCell align="right">
                <IconButton
                  aria-label="delete"
                  title={t('delete', { ns: 'buttons' })}
                  onClick={() => handleDeleteModalOpen(restore)}
                >
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Delete modal */}
      <Modal
        openModal={openDeleteModal}
        title={t('deletion', {
          ns: 'project',
          text: t('backup.restoreData', { ns: 'project' }),
        })}
        handleClose={handleDeleteModalClose}
        handleConfirm={() => onDelete(selectedRestore?.id)}
        showActions
      >
        <Text>{t('deleteRestore', { ns: 'descriptions' })}</Text>
      </Modal>
    </TableContainer>
  );
};

RestoreTable.propTypes = {
  restoreList: PropTypes.array,
  handleDelete: PropTypes.func.isRequired,
};

export default RestoreTable;
