import { Box } from '@material-ui/core';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { INSTANCE_CATEGORY } from '../../../constants';
import { formatBytes } from '../../../utils/utils';
import Text from '../../elements/text/Text';

const InfoConfirmation = ({ selectedPlan }) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext();
  const { solrVersion, name, description, subdomain, cidrs } = getValues();

  const isCommunity = selectedPlan.category === INSTANCE_CATEGORY.COMMUNITY;

  const infoContent = [
    {
      id: 'instance-plan',
      title: t('instancePlan', { ns: 'fields' }),
      body: isCommunity
        ? capitalize(t('plan', { ns: 'instance', type: selectedPlan.name }))
        : `${t('standard', { ns: 'instance' })} - ${capitalize(
            t('plan', { ns: 'instance', type: selectedPlan.name })
          )}`,
    },
    {
      id: 'solr-version',
      title: t('solrVersion', { ns: 'fields' }),
      body: solrVersion,
    },
    {
      id: 'storage',
      title: t('instanceBackupQuota', { ns: 'fields' }),
      body: formatBytes(selectedPlan.storage),
    },
    {
      id: 'instance-name',
      title: t('instanceName', { ns: 'fields' }),
      body: name,
    },
    description && {
      id: 'description',
      title: t('instanceDesc', { ns: 'fields' }),
      body: description,
    },
    {
      id: 'subdomain',
      title: t('instanceSubdomain', { ns: 'fields' }),
      body: subdomain,
    },
    cidrs.length > 0 && {
      id: 'ip-range',
      title: t('allowedIpRange', { ns: 'sectionTitles' }),
      body: cidrs.map((c) => c.cidr).join(', '),
    },
  ];

  return (
    <Box p={1}>
      {infoContent.map((contentItem, i) => (
        <div key={i} style={{ marginBottom: '0.5em' }}>
          <Text bodyBold>{contentItem.title}</Text>
          <Text style={{ marginLeft: '1em' }}>{contentItem.body}</Text>
        </div>
      ))}
    </Box>
  );
};

InfoConfirmation.propTypes = {
  selectedPlan: PropTypes.object.isRequired,
};

export default InfoConfirmation;
