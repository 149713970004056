import { useState, useEffect } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BACKUP_STATUS, BACKUP_TYPES } from '../../../../constants';
import { formatUserInfo } from '../../../../utils/utils';
import Button from '../../../elements/button/Button';
import Text from '../../../elements/text/Text';
import Modal from '../../../sections/modal/Modal';
import NoContent from '../../../sections/no-content/NoContent';
import UserAvatar from '../../../sections/user-avatar/UserAvatar';
import DownloadModal from '../download-modal/DownloadModal';
import RestoreModal from '../restore-modal/RestoreModal';
import ExecutionDateCell from '../table-columns/ExecutionDateCell';
import InstanceCell from '../table-columns/InstanceCell';
import StatusCell from '../table-columns/StatusCell';
import TypeCell from '../table-columns/TypeCell';
import ActionDropdown from './ActionDropdown';

const useStyles = makeStyles((theme) => ({
  tableMargin: {
    marginTop: theme.spacing(4),
  },
  tableButton: {
    marginLeft: theme.spacing(1),
  },
  successText: {
    color: theme.palette.success.main,
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

const BackupListTable = ({
  backupList,
  onRestore,
  onDownload,
  onDownloadTOD,
  onDelete,
  getInstances,
  getCollections,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [backup, setBackup] = useState(null);
  const [instanceList, setInstanceList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const TABLE_HEADERS = [
    t('backupSubject', { ns: 'fields' }),
    t('instance', { ns: 'instance' }),
    t('type', { ns: 'project' }),
    t('creator', { ns: 'project' }),
    t('backup.executeDate', { ns: 'project' }),
  ];

  const handleMenuOpen = (event, backup) => {
    setAnchorEl(event.currentTarget);
    setBackup(backup);
  };

  const fetchInstanceList = async () => {
    try {
      const instances = await getInstances();
      setInstanceList(instances);
    } catch {
      setInstanceList(null);
    }
  };

  useEffect(() => {
    fetchInstanceList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAnchorEl(null);
    !deleteModal && !restoreModal && !downloadModal && setBackup(null);
  }, [deleteModal, restoreModal, downloadModal]);

  const openDeleteModal = () => setDeleteModal(true);
  const closeDeleteModal = () => setDeleteModal(false);

  const openRestoreModal = () => setRestoreModal(true);
  const closeRestoreModal = () => setRestoreModal(false);

  const openDownloadModal = () => setDownloadModal(true);
  const closeDownloadModal = () => setDownloadModal(false);

  const handleRestore = async (instanceId, collection, isFile) => {
    await onRestore(backup, instanceId, collection, isFile);
    closeRestoreModal();
  };

  const handleDownload = async () => {
    await onDownload(backup);
    closeDownloadModal();
  };

  const handleDownloadToAnotherDirectory = async () => {
    await onDownloadTOD(backup);
    closeDownloadModal();
  };

  const handleDelete = async () => {
    await onDelete(backup);
    closeDeleteModal();
  };

  return (
    <section>
      {!isEmpty(backupList) ? (
        <TableContainer className={classes.tableMargin}>
          <Table size="small" aria-label="restore-table">
            <TableHead>
              <TableRow>
                {TABLE_HEADERS.map((header, i) => (
                  <TableCell align={i !== 0 ? 'center' : 'left'} key={header}>
                    <Text bodyBold>{header}</Text>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {backupList.map((backupItem) => (
                <TableRow key={backupItem.id} aria-label="table row">
                  <StatusCell
                    subject={backupItem.subject}
                    status={backupItem.status}
                    error={backupItem.error}
                  />
                  <InstanceCell
                    instance={backupItem.instanceSetName}
                    collection={backupItem.collection}
                    category={backupItem.category}
                    solrType={backupItem.solrType}
                    node={backupItem.instanceSubscript}
                    solrVersion={backupItem.solrVersion}
                  />
                  <TypeCell
                    category={backupItem.category}
                    size={backupItem.size}
                  />
                  <TableCell align="center" style={{ minWidth: 50 }}>
                    <UserAvatar
                      user={formatUserInfo(
                        backupItem.createdBy,
                        backupItem.author
                      )}
                    />
                  </TableCell>
                  <ExecutionDateCell
                    start={backupItem.startDate}
                    end={backupItem.endDate}
                  />
                  <TableCell align="right">
                    <Button
                      aria-label="action-button"
                      variant="outlined"
                      size="small"
                      onClick={(event) => handleMenuOpen(event, backupItem)}
                      disabled={
                        backupItem?.status !== BACKUP_STATUS.CREATED &&
                        backupItem?.status !== BACKUP_STATUS.FAILED
                      }
                      style={{ minWidth: 110 }}
                      endIcon={<ExpandMoreIcon />}
                    >
                      {t('actions', { ns: 'buttons' })}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoContent title={t('noBackups', { ns: 'descriptions' })} />
      )}

      <Modal
        openModal={deleteModal}
        title={t('backup.deleteRestorePoint', { ns: 'project' })}
        showActions
        handleClose={closeDeleteModal}
        handleConfirm={handleDelete}
      >
        {t('deleteConfirm', { ns: 'descriptions', schedule: backup?.subject })}
      </Modal>

      <RestoreModal
        open={restoreModal}
        backup={backup}
        getInstances={getInstances}
        getCollections={getCollections}
        onRestore={handleRestore}
        onClose={closeRestoreModal}
        isFile={backup?.category === BACKUP_TYPES.FILE}
      />

      {instanceList && backupList && (
        <DownloadModal
          open={downloadModal}
          backup={backup}
          onDownload={handleDownload}
          onDownloadTOD={handleDownloadToAnotherDirectory}
          onClose={closeDownloadModal}
          isFile={backup?.category === BACKUP_TYPES.FILE}
        />
      )}

      <ActionDropdown
        anchorEl={anchorEl}
        isFile={backup?.category === BACKUP_TYPES.FILE}
        isFailed={backup?.status === BACKUP_STATUS.FAILED}
        showRestore={openRestoreModal}
        showDownload={openDownloadModal}
        showDelete={openDeleteModal}
        handleClose={() => setAnchorEl(null)}
      />
    </section>
  );
};

BackupListTable.propTypes = {
  backupList: PropTypes.array,
  onRestore: PropTypes.func,
  onDownload: PropTypes.func,
  onDownloadTOD: PropTypes.func,
  onDelete: PropTypes.func,
  getInstances: PropTypes.func,
  getCollections: PropTypes.func,
  getInstanceNodes: PropTypes.func,
};

export default BackupListTable;
