import { makeStyles } from '@material-ui/core/styles';

import { LAYOUT_WIDTH } from '../../layouts/constants';

export const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  noPadding: {
    paddingLeft: 0,
  },
  expand: {
    marginLeft: 0,
    width: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  shift: {
    marginLeft: (open) => (open ? LAYOUT_WIDTH.MAX : LAYOUT_WIDTH.MIN),
    width: (open) =>
      open
        ? `calc(100% - ${LAYOUT_WIDTH.MAX}px)`
        : `calc(100% - ${LAYOUT_WIDTH.MIN}px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
}));
