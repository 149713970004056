import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
  makeStyles,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import { schema } from './settingsSchema';

const useStyles = makeStyles({
  gridItem: {
    marginBottom: '0.5em',
  },
});

const HealthCheckForm = ({ healthSettings, onSaveSettings }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      checkInterval: '',
      renoticeInterval: '',
      checkMethod: 'PING',
    },
    resolver: yupResolver(schema()),
  });

  useEffect(() => {
    setValue('checkInterval', healthSettings?.checkInterval || '');
    setValue('renoticeInterval', healthSettings?.renoticeInterval || '');
    setValue('checkMethod', healthSettings?.checkMethod || 'PING');

    // eslint-disable-next-line
  }, [healthSettings]);

  return (
    <form onSubmit={handleSubmit(onSaveSettings)}>
      <Grid container spacing={2} style={{ marginTop: '1em' }}>
        <Grid item sm={12} md={4} className={classes.gridItem}>
          <Controller
            name="checkInterval"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t('monitor.checkInterval', { ns: 'fields' })}
                variant="outlined"
                size="small"
                error={!!errors?.checkInterval}
                helperText={errors?.checkInterval?.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={4} className={classes.gridItem}>
          <Controller
            name="renoticeInterval"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t('monitor.renoticeInterval', { ns: 'fields' })}
                variant="outlined"
                size="small"
                error={!!errors?.renoticeInterval}
                helperText={errors?.renoticeInterval?.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            error={!!errors?.checkMethod}
          >
            <InputLabel>
              {t('monitor.checkMethod', { ns: 'fields' })}
            </InputLabel>
            <Controller
              name="checkMethod"
              control={control}
              defaultValue="PING"
              render={({ field }) => (
                <Select {...field} label="Check Method">
                  <MenuItem value="PING">PING</MenuItem>
                </Select>
              )}
            />
            {!!errors?.checkMethod && (
              <FormHelperText>{errors.checkMethod.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={12} md={3} style={{ paddingTop: 0 }}>
          <Button style={{ width: isSmallScreen && '100%' }} type="submit">
            {t('saveSettings', { ns: 'buttons' })}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

HealthCheckForm.propTypes = {
  healthSettings: PropTypes.object,
  onSaveSettings: PropTypes.func.isRequired,
};

export default HealthCheckForm;
