import { matchPath, useLocation } from 'react-router';

const formatWithMatch = ({ pathSection, routes }) => {
  let result;
  routes.some(({ breadcrumb, path }) => {
    const match = matchPath(pathSection, {
      exact: true,
      path,
    });
    if (!match) return false;
    result = {
      key: match.url,
      match,
      breadcrumb,
    };
    return true;
  });
  return result;
};

const useBreadcrumbs = (routes) => {
  const getBreadcrumbs = ({ routes, location: { pathname } }) => {
    const matches = [];

    pathname
      .split('/')
      .filter((x) => x)
      .reduce((prevSection, currentSection) => {
        const pathSection = `${prevSection}/${currentSection}`;

        const breadcrumb = formatWithMatch({
          pathSection,
          routes,
        });

        matches.push(breadcrumb);
        return pathSection;
      }, '');

    return matches;
  };

  return getBreadcrumbs({
    routes: routes || [],
    location: useLocation(),
  });
};

export default useBreadcrumbs;
