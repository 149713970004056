import { useState } from 'react';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Modal from '../../sections/modal/Modal';
import CopyBox from './CopyBox';

const ButtonSet = ({ json, url }) => {
  const { t } = useTranslation();

  const [openJsonModal, setOpenJsonModal] = useState(false);
  const [openUrlModal, setOpenUrlModal] = useState(false);

  const handleJsonModalOpen = () => setOpenJsonModal(true);
  const handleJsonModalClose = () => setOpenJsonModal(false);

  const handleUrlModalOpen = () => setOpenUrlModal(true);
  const handleUrlModalClose = () => setOpenUrlModal(false);

  return (
    <>
      <ButtonGroup disableElevation variant="outlined" color="primary">
        <Button onClick={handleJsonModalOpen} aria-label="view json ">
          {t('showJsonResponse', { ns: 'buttons' })}
        </Button>
        <Button onClick={handleUrlModalOpen} aria-label="view solr url">
          {t('showSolrUrl', { ns: 'buttons' })}
        </Button>
      </ButtonGroup>

      <Modal
        openModal={openJsonModal}
        title={t('jsonResponse', { ns: 'instance' })}
        handleClose={handleJsonModalClose}
      >
        <CopyBox isJson content={JSON.stringify(json, null, 4)} />
      </Modal>

      <Modal
        openModal={openUrlModal}
        title={t('requestUrl', { ns: 'instance' })}
        handleClose={handleUrlModalClose}
      >
        <CopyBox content={url} />
      </Modal>
    </>
  );
};

ButtonSet.propTypes = {
  json: PropTypes.object,
  url: PropTypes.string,
};

export default ButtonSet;
