import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const CheckboxFormSection = ({
  parser,
  control,
  onHighlightCheck,
  onFacetCheck,
}) => {
  const { t } = useTranslation();

  const handleHighlightCheck = (event) => onHighlightCheck(event);
  const handleFacetCheck = (event) => onFacetCheck(event);

  return (
    <Grid container style={{ marginTop: '1em' }}>
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            label={t('search.highlight', { ns: 'fields' })}
            control={
              <Controller
                name="settings.query.jsonQuery.params.hl"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    inputProps={{ 'aria-label': 'highlighting' }}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      handleHighlightCheck(e);
                    }}
                  />
                )}
              />
            }
          />
          <FormControlLabel
            label={t('search.facet', { ns: 'fields' })}
            control={
              <Controller
                name="settings.query.jsonQuery.params.facet"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    inputProps={{ 'aria-label': 'facet' }}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      handleFacetCheck(e);
                    }}
                  />
                )}
              />
            }
          />
          <FormControlLabel
            label={t('search.debug', { ns: 'fields' })}
            control={
              <Controller
                name="settings.query.jsonQuery.params.debugQuery"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    inputProps={{ 'aria-label': 'debug' }}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                  />
                )}
              />
            }
          />
          {parser === 'edismax' && (
            <>
              <FormControlLabel
                label={t('search.stopwords', { ns: 'fields' })}
                control={
                  <Controller
                    name="settings.query.jsonQuery.params.stopwords"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        inputProps={{ 'aria-label': 'stopwords' }}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
              <FormControlLabel
                label={t('search.lowercaseOps', { ns: 'fields' })}
                control={
                  <Controller
                    name="settings.query.jsonQuery.params.lowercaseOperators"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        inputProps={{ 'aria-label': 'lowercase' }}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </>
          )}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

CheckboxFormSection.propTypes = {
  parser: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  onHighlightCheck: PropTypes.func.isRequired,
  onFacetCheck: PropTypes.func.isRequired,
};

export default CheckboxFormSection;
