import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import RefreshIcon from '@material-ui/icons/Refresh';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  BACKUP_LABELS,
  BACKUP_TYPES,
  DEFAULT_EXCLUDE_FILES,
  DEFAULT_STANDARD_INCLUDE_FILES,
  DEFAULT_COMMUNITY_INCLUDE_FILES,
  INSTANCE_CATEGORY,
} from '../../../constants';
import { isSolrCloud, sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Loading from '../../elements/loading/Loading';
import Text from '../../elements/text/Text';

const ManualBackupForm = ({
  handleBackup,
  getPreview,
  getInstanceNodes,
  getInstances,
  getCollections,
  handleModalClose,
}) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    subject: yup
      .string()
      .max(50, t('maxLength', { ns: 'validations', max: '50' }))
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('backupSubject', { ns: 'fields' }),
          })
        )
      ),
    instance: yup.mixed().test(
      'instanceRequired',
      sentenceCase(
        t('selectRequired', {
          ns: 'validations',
          field: t('instance', { ns: 'instance' }),
        })
      ),
      (value) => !!value
    ),
    backupType: yup.string().required(
      sentenceCase(
        t('selectRequired', {
          ns: 'validations',
          field: t('backupType', { ns: 'fields' }),
        })
      )
    ),
    subscript: yup.string().when(['instance', 'backupType'], {
      is: (instance, backupType) =>
        isSolrCloud(instance?.type) && backupType === BACKUP_TYPES.FILE,
      then: yup.string().required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('instanceNode', { ns: 'instance' }),
          })
        )
      ),
      otherwise: yup.string(),
    }),
    collectionName: yup.string().when('backupType', {
      is: BACKUP_TYPES.INDEX,
      then: yup.string().required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('collection', { ns: 'instance' }),
          })
        )
      ),
      otherwise: yup.string(),
    }),
    includeFiles: yup.string(),
    excludeFiles: yup.string(),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [instances, setInstances] = useState(null);
  const [instanceNodes, setInstanceNodes] = useState(null);
  const [collections, setCollections] = useState(null);
  const [preview, setPreview] = useState(null);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { instance, subscript, backupType } = watch();

  const requireNode =
    isSolrCloud(instance?.type) && backupType === BACKUP_TYPES.FILE;

  const getInstanceNodesData = async (instanceId) => {
    try {
      const data = await getInstanceNodes(instanceId);
      setInstanceNodes(data);
    } catch (error) {
      setInstanceNodes(null);
    }
  };

  const getInstancesData = async () => {
    try {
      const data = await getInstances();
      setInstances(data);
    } catch (error) {
      setInstances(null);
    }
  };

  const getCollectionData = async () => {
    setIsLoading(true);
    try {
      const data = await getCollections(instance);
      setCollections(data);
    } catch (error) {
      setCollections(null);
    }
    setIsLoading(false);
  };

  const getPreviewData = async () => {
    setIsLoading(true);
    try {
      const data = await getPreview(getValues());
      setPreview(data);
    } catch (error) {
      setPreview(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getInstancesData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!instance) return;

    // reset necessary field
    setPreview(null);
    [
      'backupType',
      'subscript',
      'collectionName',
      'includeFiles',
      'excludeFiles',
    ].forEach((field) => setValue(field, undefined, { shouldTouch: true }));

    if (isSolrCloud(instance.type)) {
      getInstanceNodesData(instance.id);
    }

    // eslint-disable-next-line
  }, [instance]);

  useEffect(() => {
    if (!subscript) return;

    // reset necessary field
    setPreview(null);

    // eslint-disable-next-line
  }, [subscript]);

  useEffect(() => {
    if (!backupType) return;

    if (backupType === BACKUP_TYPES.INDEX) {
      ['subscript', 'includeFiles', 'excludeFiles'].forEach((field) =>
        trigger(field)
      );
      getCollectionData();
    } else {
      trigger('collectionName');
    }
    // eslint-disable-next-line
  }, [backupType]);

  const onSubmit = async (data) => {
    await handleBackup(data);
    handleModalClose();
  };

  const refreshPreview = () => {
    getPreviewData();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="manual backup form">
      <Grid container spacing={2} justify="center">
        <Grid item xs={12}>
          <TextField
            {...register('subject')}
            id="subject"
            label={t('backupSubject', { ns: 'fields' })}
            error={!!errors?.subject}
            helperText={errors?.subject?.message}
            inputProps={{ 'aria-label': 'subject' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.instance}>
            <InputLabel>{t('instanceToBackup', { ns: 'fields' })}</InputLabel>
            <Select
              {...register('instance')}
              defaultValue={''}
              inputProps={{ 'aria-label': 'instance select' }}
            >
              {instances?.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.instance?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.backupType}>
            <Text>{t('backupType', { ns: 'fields' })}</Text>
            <Controller
              name="backupType"
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <RadioGroup {...field} value={field.value || ''}>
                  <FormHelperText>{errors.backupType?.message}</FormHelperText>
                  {BACKUP_LABELS.map((item) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio disabled={!instance} />}
                      label={t(item.label, { ns: 'menus' })}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>

        {backupType === BACKUP_TYPES.INDEX &&
          (isLoading ? (
            <Loading />
          ) : (
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.collectionName}>
                <InputLabel>
                  {t('collectionToBackup', { ns: 'fields' })}
                </InputLabel>
                <Controller
                  name="collectionName"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <Select
                      {...field}
                      inputProps={{ 'aria-label': 'collection select' }}
                    >
                      {collections?.map((collection) => (
                        <MenuItem key={collection} value={collection}>
                          {collection}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>
                  {errors.collectionName?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          ))}

        {requireNode && (
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.subscript}>
              <InputLabel>
                {t('instanceNodeToBackup', { ns: 'fields' })}
              </InputLabel>
              <Controller
                name="subscript"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Select
                    {...field}
                    inputProps={{ 'aria-label': 'instance node select' }}
                  >
                    {instanceNodes?.map((node) => (
                      <MenuItem key={node.id} value={node.subscriptNumber}>
                        {node.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>{errors.subscript?.message}</FormHelperText>
            </FormControl>
          </Grid>
        )}

        {backupType === BACKUP_TYPES.FILE && (
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6}>
              <Controller
                name="includeFiles"
                control={control}
                defaultValue={
                  instance.category === INSTANCE_CATEGORY.COMMUNITY
                    ? DEFAULT_COMMUNITY_INCLUDE_FILES
                    : DEFAULT_STANDARD_INCLUDE_FILES
                }
                render={({ field }) => (
                  <TextField
                    {...field}
                    rows={5}
                    label={t('includedFiles', { ns: 'fields' })}
                    variant="outlined"
                    error={!!errors?.includeFiles}
                    helperText={errors?.includeFiles?.message}
                    inputProps={{ 'aria-label': 'include files' }}
                    fullWidth
                    multiline
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="excludeFiles"
                control={control}
                defaultValue={DEFAULT_EXCLUDE_FILES}
                render={({ field }) => (
                  <TextField
                    {...field}
                    rows={5}
                    label={t('excludedFiles', { ns: 'fields' })}
                    variant="outlined"
                    error={!!errors?.excludeFiles}
                    helperText={errors?.excludeFiles?.message}
                    inputProps={{ 'aria-label': 'exclude files' }}
                    fullWidth
                    multiline
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="right" mb={1}>
                <Button
                  size="small"
                  variant="outlined"
                  endIcon={<RefreshIcon />}
                  onClick={refreshPreview}
                  aria-label="refresh preview"
                  disabled={isLoading || (requireNode && !subscript)}
                >
                  {t('refreshPreviewFile', { ns: 'buttons' })}
                </Button>
              </Box>
              <Card
                variant="outlined"
                aria-label="preview card"
                style={{ maxHeight: 165, overflow: 'auto' }}
              >
                <Box p={1}>
                  {isLoading ? (
                    <Loading />
                  ) : preview !== null ? (
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                      {preview || t('noPreviewFiles', { ns: 'descriptions' })}
                    </Text>
                  ) : (
                    <Text caption>
                      {t('showPreviewFiles', { ns: 'descriptions' })}
                    </Text>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Box textAlign="center" my={2}>
        <Button type="submit" disabled={!isEmpty(errors)} aria-label="back up">
          {t('createManualBackup', { ns: 'buttons' })}
        </Button>
      </Box>
    </form>
  );
};

ManualBackupForm.propTypes = {
  handleBackup: PropTypes.func.isRequired,
  getPreview: PropTypes.func.isRequired,
  getInstanceNodes: PropTypes.func.isRequired,
  getInstances: PropTypes.func.isRequired,
  getCollections: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default ManualBackupForm;
