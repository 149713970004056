import { DialogActions } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';

const ModalButtons = ({
  cancelProps,
  actionProps,
  actionText,
  cancelText,
  danger,
  onCancel,
  onAction,
}) => {
  const { t } = useTranslation();

  return (
    <DialogActions>
      <Button
        color="default"
        variant="text"
        onClick={onCancel}
        {...cancelProps}
      >
        {cancelText || t('cancel', { ns: 'buttons' })}
      </Button>
      <Button
        variant={danger ? 'contained' : 'text'}
        color={danger && 'danger'}
        onClick={onAction}
        data-testid="modal-confirm"
        {...actionProps}
      >
        {actionText}
      </Button>
    </DialogActions>
  );
};

ModalButtons.propTypes = {
  cancelProps: PropTypes.object,
  actionProps: PropTypes.object,
  actionText: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  danger: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onAction: PropTypes.func,
};

export default ModalButtons;
