import { useState } from 'react';

import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { INSTANCE_TYPES } from '../../../constants';
import { getSolrSubdomain } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import CopyableText from '../../elements/copyable-text/CopyableText';
import Status from '../../elements/status/Status';
import Text from '../../elements/text/Text';

const SolrNodeListItem = ({ instance, solrNode, onStartNode, onStopNode }) => {
  const { t } = useTranslation();

  const [solrProcessing, setSolrProcessing] = useState(false);

  const handleStartNode = async (subscriptNum) => {
    setSolrProcessing(true);
    await onStartNode(subscriptNum);
    setSolrProcessing(false);
  };

  const handleStopNode = async (subscriptNum) => {
    setSolrProcessing(true);
    await onStopNode(subscriptNum);
    setSolrProcessing(false);
  };

  return (
    <ListItem>
      <ListItemText
        primary={
          <Box display="flex" alignItems="center">
            <Text>
              {instance.name}-{solrNode.subscriptNumber}
            </Text>
            <Status
              instanceState={instance.status}
              solrRunning={solrNode.status}
            />
          </Box>
        }
        secondary={
          instance.subdomain && (
            <CopyableText
              label={t('subdomainUrl', { ns: 'instance' })}
              text={getSolrSubdomain(
                instance.subdomain,
                INSTANCE_TYPES.SOLRCLOUD,
                instance.category,
                {
                  subscriptNumber: solrNode.subscriptNumber,
                }
              )}
            />
          )
        }
      />

      <ListItemSecondaryAction>
        <Box display="flex">
          {solrProcessing ? (
            <Button disabled size="small">
              {t('pending', { ns: 'buttons' })}
            </Button>
          ) : solrNode.status ? (
            <Button
              aria-label={`node${solrNode.subscriptNumber}-button`}
              color="light"
              size="small"
              onClick={() => handleStopNode(solrNode.subscriptNumber)}
            >
              {t('stop', { ns: 'buttons' })}
            </Button>
          ) : (
            <Button
              aria-label={`node${solrNode.subscriptNumber}-button`}
              size="small"
              onClick={() => handleStartNode(solrNode.subscriptNumber)}
            >
              {t('start', { ns: 'buttons' })}
            </Button>
          )}
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

SolrNodeListItem.propTypes = {
  instance: PropTypes.object.isRequired,
  solrNode: PropTypes.object.isRequired,
  onStartNode: PropTypes.func.isRequired,
  onStopNode: PropTypes.func.isRequired,
};

export default SolrNodeListItem;
