import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { keys } from 'lodash';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  EXPORT_FILE_TYPE,
  FILENAME_RESERVED_REGEX,
} from '../../../../constants';
import { sentenceCase } from '../../../../utils/utils';
import Button from '../../../elements/button/Button';
import Text from '../../../elements/text/Text';
import Modal from '../../../sections/modal/Modal';

const ExportModal = ({ open, handleClose, dictionary, handleExport }) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup.object().shape({
    fileName: yup.string().required(
      sentenceCase(
        t('inputRequired', {
          ns: 'validations',
          field: t('fileName', { ns: 'fields' }),
        })
      )
    ),
    fileType: yup.string().required(),
    comments: yup.boolean().when('fileType', {
      is: EXPORT_FILE_TYPE.CSV,
      then: yup.boolean().required(
        sentenceCase(
          t('selectRequired', {
            ns: 'validations',
            field: t('synonym.fileType', { ns: 'fields' }),
          })
        )
      ),
      otherwise: yup.boolean(),
    }),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
    defaultValues: {
      fileType: EXPORT_FILE_TYPE.CSV,
      comments: false,
    },
  });

  const isCSV = watch('fileType') === EXPORT_FILE_TYPE.CSV;

  const onExport = async (data) => {
    setIsSubmitting(true);
    const sanitizedData = {
      ...data,
      fileName: data.fileName.replace(FILENAME_RESERVED_REGEX, '_'),
    };
    await handleExport(dictionary.id, sanitizedData);
    setIsSubmitting(false);
    handleClose();
  };

  return (
    <Modal
      openModal={open}
      title={t('synonym.exportDictionary', {
        ns: 'sectionTitles',
      })}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onExport)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="fileName"
              defaultValue={dictionary.name}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoFocus
                  autoComplete="off"
                  error={!!errors?.fileName}
                  helperText={errors?.fileName?.message}
                  label={t('fileName', { ns: 'fields' })}
                  inputProps={{ 'aria-label': 'file name' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.fileType}>
              <Text>{t('synonym.fileType', { ns: 'fields' })}</Text>
              <Controller
                name="fileType"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <RadioGroup {...field} value={field.value || ''}>
                    <FormHelperText>{errors.fileType?.message}</FormHelperText>
                    {keys(EXPORT_FILE_TYPE).map((type) => (
                      <FormControlLabel
                        key={type}
                        value={type}
                        control={<Radio />}
                        label={t('synonym.fileType', {
                          ns: 'project',
                          type: type,
                        })}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>
          {isCSV && (
            <Grid item xs={12}>
              <Controller
                name="comments"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        inputProps={{ 'aria-label': 'include comment' }}
                      />
                    }
                    label={t('synonym.includeComment', { ns: 'project' })}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>

        <DialogActions>
          <Button
            color="default"
            variant="text"
            type="reset"
            onClick={handleClose}
          >
            {t('cancel', { ns: 'buttons' })}
          </Button>
          {isSubmitting ? (
            <Button variant="text" disabled>
              {t('download', { ns: 'buttons' })}
              <CircularProgress size={15} style={{ marginLeft: 10 }} />
            </Button>
          ) : (
            <Button
              variant="text"
              type="submit"
              disabled={!isValid}
              aria-label="download"
            >
              {t('download', { ns: 'buttons' })}
            </Button>
          )}
        </DialogActions>
      </form>
    </Modal>
  );
};

ExportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dictionary: PropTypes.object,
  handleExport: PropTypes.func.isRequired,
};

export default ExportModal;
