import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const EnterpriseCard = () => {
  const { t } = useTranslation();

  return (
    <Card
      variant="outlined"
      style={{
        backgroundImage: 'linear-gradient(to bottom right, #1863a6, #112a80)',
        color: 'white',
        marginTop: '1em',
      }}
    >
      <CardHeader
        title={
          <Trans
            i18nKey="enterprise"
            ns="descriptions"
            components={[
              <span
                key="0"
                style={{
                  color: '#fdc60e',
                  fontWeight: 'bold',
                }}
              />,
            ]}
          />
        }
        style={{ paddingBottom: 0 }}
      />
      <CardContent>{t('enterpriseDesc', { ns: 'descriptions' })}</CardContent>
    </Card>
  );
};

export default EnterpriseCard;
