import { useEffect, useRef } from 'react';

import { isInstanceProcessing } from '../apis/constants';

const useSolrInstanceInterval = (
  callback,
  instance,
  delay,
  forceStop = false
) => {
  const storedCallback = useRef();

  const processing = isInstanceProcessing(instance);

  useEffect(() => {
    storedCallback.current = callback;
  });

  useEffect(() => {
    const execute = () => storedCallback.current();

    if (processing) {
      if (forceStop) return;
      const interval = setInterval(execute, delay);
      return () => clearInterval(interval);
    }
  }, [processing, delay, forceStop]);
};

export default useSolrInstanceInterval;
