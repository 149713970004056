import { useEffect, useState } from 'react';

import { Box, Grid, Container, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import { isEmpty, keys, omit, pickBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';

import { DEFAULT_SEARCH_ROW } from '../../../constants';
import { useSearch } from '../../../contexts/SearchContext';
import { sentenceCase } from '../../../utils/utils';
import Loading from '../../elements/loading/Loading';
import Text from '../../elements/text/Text';
import NoContent from '../../sections/no-content/NoContent';
import ButtonSet from './ButtonSet';
import FacetingMenu from './FacetingMenu';
import ResultCard from './ResultCard';

const SearchResults = () => {
  const { t } = useTranslation();
  const {
    isFetching,
    query,
    error,
    results,
    isPageChanging,
    handlePageChange,
  } = useSearch();
  const [currentPage, setCurrentPage] = useState(1);
  const { width, ref } = useResizeDetector();

  const useStyles = makeStyles(() => ({
    inputContainer: {
      display: 'flex',
      margin: -8,
      flexWrap: 'wrap',
    },
    faceting: {
      width: width > 500 ? '33%' : '100%',
      flexBasis: width > 500 ? '33%' : '100%',
      padding: 8,
      boxSizing: 'border-box',
    },
    results: {
      maxWidth: width > 500 ? '67%' : '100%',
      flexBasis: width > 500 ? '67%' : '100%',
      padding: 8,
      boxSizing: 'border-box',
    },
  }));
  const classes = useStyles();

  const {
    facet_counts: facetCounts,
    response,
    highlighting = {},
    url,
  } = results;
  const { uniqueKey } = query;

  const pages = Math.ceil(response?.numFound / DEFAULT_SEARCH_ROW);

  const formattedFaceting = (facet) => {
    const cleanedFacet = pickBy(facet, (item) => !isEmpty(keys(item)));

    const extractFacetFields = (facetFields) => {
      const formatArrayToObject = (valueArray) => {
        return valueArray.reduce((prev, current, idx, arr) => {
          if (idx % 2 !== 0 && arr[idx - 1]) {
            prev[arr[idx - 1]] = current;
          }
          return prev;
        }, {});
      };

      return keys(facetFields).reduce((prev, key) => {
        prev[key] = formatArrayToObject(facetFields[key]);
        return prev;
      }, {});
    };

    const extractFacetQueries = (facetQueries) => {
      const querySet = new Set();
      keys(facetQueries).forEach((query) =>
        querySet.add(query.substring(0, query.indexOf(':')))
      );

      return Array.from(querySet).reduce((queryObj, queryKey) => {
        const filteredQueries = pickBy(facetQueries, (_, key) =>
          key.includes(queryKey)
        );
        const formatKeyValuePair = keys(filteredQueries).reduce((obj, key) => {
          const formattedKey = key.replace(/^(.*?):/, '');
          obj[formattedKey] = facetQueries[key];
          return obj;
        }, {});

        queryObj[queryKey] = formatKeyValuePair;
        return queryObj;
      }, {});
    };

    const outputObject = keys(cleanedFacet).reduce((newObj, key) => {
      switch (key) {
        case 'facet_fields':
          return { ...newObj, ...extractFacetFields(cleanedFacet[key]) };
        case 'facet_queries':
          return { ...newObj, ...extractFacetQueries(cleanedFacet[key]) };
        default:
          return newObj;
      }
    }, {});

    return outputObject;
  };

  const formattedDocs = (docs, highlighting) =>
    docs.map((doc) => ({
      ...doc,
      ...(highlighting[doc[uniqueKey]] && {
        highlighting: highlighting[doc[uniqueKey]],
      }),
    }));

  const faceting = formattedFaceting(facetCounts);

  const displayDocs = response
    ? formattedDocs(response.docs, highlighting)
    : [];

  const onPageChange = (_, value) => {
    setCurrentPage(value);
    const newStart = (value - 1) * DEFAULT_SEARCH_ROW;
    handlePageChange(newStart);
  };

  const errorSection = (
    <Box my={2}>
      <Alert severity="error">
        {sentenceCase(
          t('loading', {
            ns: 'errors',
            content: t('searchResults', { ns: 'instance' }),
          })
        )}
        <Text>{error}</Text>
      </Alert>
    </Box>
  );

  useEffect(() => {
    if (query?.jsonQuery?.offset !== undefined) {
      setCurrentPage(query?.jsonQuery?.offset / 10 + 1);
    }
  }, [query?.jsonQuery?.offset]);

  const searchResultContent = (
    <Box className={classes.inputContainer}>
      <Box className={classes.faceting}>
        <FacetingMenu faceting={faceting} />
      </Box>
      <Box className={classes.results}>
        <Box
          display="flex"
          flexDirection={width > 500 ? 'row' : 'column'}
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <ButtonSet json={omit(results, ['url'])} url={url} />
          <Text align="right">
            {t('numberFound', {
              ns: 'instance',
              text: response?.numFound.toLocaleString() || 0,
            })}
          </Text>
        </Box>

        {isEmpty(displayDocs) ? (
          <NoContent
            title={sentenceCase(
              t('notFound', {
                ns: 'descriptions',
                text: t('search', { ns: 'titles' }),
              })
            )}
          />
        ) : (
          <Grid container spacing={1}>
            {displayDocs.map((doc, index) => (
              <Grid item xs={12} key={index}>
                <ResultCard doc={doc} />
              </Grid>
            ))}
          </Grid>
        )}

        {pages > 1 && (
          <Box
            mt={2}
            display="flex"
            flex="1"
            justifyContent="center"
            alignItems="center"
          >
            <Pagination
              count={pages}
              page={currentPage}
              shape="rounded"
              disabled={isPageChanging}
              onChange={onPageChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Container ref={ref}>
      {isFetching ? (
        <Loading />
      ) : error ? (
        errorSection
      ) : isEmpty(query) ? (
        <NoContent
          title={sentenceCase(t('searchNotExecuted', { ns: 'descriptions' }))}
        />
      ) : (
        searchResultContent
      )}
    </Container>
  );
};

export default SearchResults;
