import Error from '../../components/sections/error/Error';
import i18next from '../../i18n';

const forbidden = {
  type: 403,
  title: 'Forbidden',
  content: [
    i18next.t('forbiddenPage', { ns: 'errors' }),
    i18next.t('forbiddenPageSubtext', { ns: 'errors' }),
  ],
};

const ForbiddenError = () => <Error error={forbidden} />;

export default ForbiddenError;
