export const EXPORT_FILE_TYPE = { CSV: 'CSV', JSON: 'JSON' };

export const SYNONYM_FILTERS = {
  SYNONYM_GRAPH_FILTER: 'SynonymGraphFilter',
  MANAGED_SYNONYM_GRAPH_FILTER: 'ManagedSynonymGraphFilter',
};

// eslint-disable-next-line
export const FILENAME_RESERVED_REGEX = /[<>:"/\\|?*\u0000-\u001F]/g;

// temporary restrict the file size for import
export const TEMP_FILE_SIZE = { BYTES: 512000, MEGABYTES: 0.5 };
