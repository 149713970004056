import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Text from '../../elements/text/Text';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  showOnSmall: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

const StepTitle = ({ title, showOnSmall }) => {
  const classes = useStyles();

  return (
    <Text
      subtitleBold
      align="center"
      text={title}
      className={clsx(classes.title, {
        [classes.showOnSmall]: showOnSmall,
      })}
    />
  );
};

StepTitle.propTypes = {
  title: PropTypes.string.isRequired,
  showOnSmall: PropTypes.bool,
};

export default StepTitle;
