import { instance, getToken } from './config';

const getCrawlJobs = (projectId, page) =>
  instance.get(
    `/projects/${projectId}/cjs?sort=createdAt,desc&size=5&page=${page}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const getCrawlJob = (projectId, jobId) =>
  instance.get(`/projects/${projectId}/cjs/${jobId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const getJobSlots = (projectId) =>
  instance.get(`/projects/${projectId}/cjss?shared=true&occupied=false`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const createCrawlJob = (projectId, body) =>
  instance.post(`/projects/${projectId}/cjs`, body, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const startCrawlJob = (projectId, jobId) =>
  instance.post(`/projects/${projectId}/cjs/${jobId}/start`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const stopCrawlJob = (projectId, jobId) =>
  instance.post(`/projects/${projectId}/cjs/${jobId}/stop`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteCrawlJob = (projectId, jobId) =>
  instance.delete(`/projects/${projectId}/cjs/${jobId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    // timeout: 30000,
  });

const editCrawlJob = (projectId, jobId, body) =>
  instance.put(`/projects/${projectId}/cjs/${jobId}`, body, {
    headers: { Authorization: `Bearer ${getToken()}` },
    // timeout: 25000,
  });

const resetSeeds = (projectId, jobId) =>
  instance.post(`/projects/${projectId}/cjs/${jobId}/reseed`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const reindexCrawlJob = (projectId, jobId) =>
  instance.post(`/projects/${projectId}/cjs/${jobId}/reindex`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const getCrawlJobStatus = (projectId, jobId) =>
  instance.post(`/projects/${projectId}/cjs/${jobId}/state`, null, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

export const crawlerApis = {
  getCrawlJobs,
  getCrawlJob,
  createCrawlJob,
  getJobSlots,
  startCrawlJob,
  stopCrawlJob,
  deleteCrawlJob,
  editCrawlJob,
  resetSeeds,
  reindexCrawlJob,
  getCrawlJobStatus,
};
