import { instance, getToken } from './config';

const getServers = (projectId, params = {}) =>
  instance.get(`/projects/${projectId}/gps-instance-sets`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { ...params, size: 1000 },
  });

const getServer = (projectId, serverId) =>
  instance.get(`/projects/${projectId}/gps-instance-sets/${serverId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const createServer = (projectId, data) =>
  instance.post(`/projects/${projectId}/gps-instance-sets`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'multipart/form-data',
    },
  });

const deleteServer = (projectId, serverId) =>
  instance.delete(`/projects/${projectId}/gps-instance-sets/${serverId}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

const updateServer = (projectId, serverId, payload) =>
  instance.put(
    `/projects/${projectId}/gps-instance-sets/${serverId}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );

const getIndexingTasks = (projectId, gpsId, page = 0) =>
  instance.get(`/projects/${projectId}/tasks`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: {
      targetId: gpsId,
      sort: 'date,desc',
      page,
    },
  });

// instanceId here is the the model instance Id
const getIndexingTask = (projectId, instanceId, taskId) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/tasks/${taskId}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

// instanceId here is the model instance Id
const getTaskLog = (projectId, instanceId, taskId) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/tasks/${taskId}/log`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

export const serverApis = {
  getServers,
  getServer,
  createServer,
  deleteServer,
  updateServer,
  getIndexingTasks,
  getIndexingTask,
  getTaskLog,
};
