import { instance, getToken } from './config';

const getModels = (projectId, params = {}) =>
  instance.get(`/projects/${projectId}/model-instance-sets`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { ...params, size: 1000 },
  });

const getModel = (projectId, modelId) =>
  instance.get(`/projects/${projectId}/model-instance-sets/${modelId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateModel = (projectId, modelId, payload) =>
  instance.put(
    `/projects/${projectId}/model-instance-sets/${modelId}`,
    payload,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const deleteModel = (projectId, modelId) =>
  instance.delete(`/projects/${projectId}/model-instance-sets/${modelId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

export const modelApis = {
  getModels,
  getModel,
  updateModel,
  deleteModel,
};
