import { Box, TableCell, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentenceCase } from '../../../utils/utils';
import Text from '../../elements/text/Text';
import { statusColor, statusIcon, useStyles } from './styles';

const JobStatusCell = ({ status, error }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableCell>
      {status && (
        <Tooltip placement="right" title={error || ''}>
          <Box display="flex" alignItems="center" justifyContent="center">
            {statusIcon(status, classes)}
            <Text className={statusColor(status, classes)}>
              {sentenceCase(t(status.toLowerCase(), { ns: 'gps' }))}
            </Text>
          </Box>
        </Tooltip>
      )}
    </TableCell>
  );
};

JobStatusCell.propTypes = {
  status: PropTypes.string,
  error: PropTypes.string,
};

export default JobStatusCell;
