import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TAX_RATE } from '../../../constants';
import { currencyFormat } from '../../../utils/paymentUtils';
import Text from '../../elements/text/Text';
import { getDaysCharged, getPaymentPeriod } from '../../page-sections/helpers';
import CreditCard from '../../page-sections/instance-stepper/CreditCard';

const PaymentTable = ({ rows, isInvoicePayment, card }) => {
  const { t } = useTranslation();

  const createRow = (desc, price) => ({ desc, price });

  // 0 is description, 1 is price
  const charges = rows.map((row) =>
    createRow(capitalize(row[0]), currencyFormat(row[1]))
  );

  const TABLE_ROW_TAX = `${t('billing.tax', { ns: 'project' })} (${
    TAX_RATE * 100
  }%)`;
  const daysCharged = getDaysCharged();
  const pricesSum = rows.reduce((prev, curr) => prev + curr[1], 0);
  const daysThisMonth = moment().daysInMonth();
  const firstMonthPayment = (pricesSum / daysThisMonth) * daysCharged;
  const firstMonthTotal = firstMonthPayment + firstMonthPayment * TAX_RATE;

  const tableRows = [
    ...charges,
    createRow(TABLE_ROW_TAX, `${currencyFormat(pricesSum * TAX_RATE)}`),
    createRow(
      t('billing.chargeDays', { ns: 'project', number: daysCharged }),
      `${currencyFormat(firstMonthTotal)}`
    ),
  ];

  return (
    <section>
      {isInvoicePayment ? (
        <Box mb={1}>
          <Text>
            {t('chargeByInvoice', { ns: 'descriptions' })}{' '}
            {t('invoiceSentAfterPurchase', { ns: 'descriptions' })}
          </Text>
        </Box>
      ) : (
        <Box mb={1}>
          <Text gutterBottom>{t('chargeByCard', { ns: 'descriptions' })}</Text>{' '}
          <CreditCard card={card} />
        </Box>
      )}

      <TableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>
                {t('billing.chargePeriod', {
                  ns: 'project',
                  text: getPaymentPeriod(),
                })}
              </TableCell>
              <TableCell align="right">
                {t('billing.price', { ns: 'project' })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row) => (
              <TableRow key={row.desc}>
                <TableCell>{row.desc}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={1}>
        <Text>
          {t('nextFullCharge', {
            ns: 'descriptions',
            price: currencyFormat(pricesSum + pricesSum * TAX_RATE),
          })}
        </Text>
        <Text color="error">{t('notRefundable', { ns: 'descriptions' })}</Text>
      </Box>
    </section>
  );
};

PaymentTable.propTypes = {
  rows: PropTypes.array.isRequired,
  isInvoicePayment: PropTypes.bool,
  card: PropTypes.object,
};

export default PaymentTable;
