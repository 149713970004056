import { saveAs } from 'file-saver';
import { findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { fileManagerApis } from '../apis/fileManagerApis';
import { MIME_TYPE } from '../components/page-sections/file-browser/constants';
import {
  CLUSTER_TYPES,
  DEFAULT_SUBSCRIPT_NUMBER,
  FILE_MANAGER_UPLOAD_TYPE,
} from '../constants';
import { formatFileList } from '../helpers/fileManagerHelpers';
import { PRIVATE_ROUTE, buildPath } from '../routes/routes';
import { getFilenameFromResponseHeader, sentenceCase } from '../utils/utils';
import useToast, { TOAST_TYPE } from './useToast';

const useFileManager = (
  setFileList,
  path,
  subscript = DEFAULT_SUBSCRIPT_NUMBER
) => {
  const { projectId, instanceId, gpsId } = useParams();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const updateFileList = async () => {
    try {
      const { data } = await fileManagerApis.getFileList(
        projectId,
        instanceId || gpsId,
        path,
        subscript
      );
      const formattedFileList = formatFileList(data);
      setFileList(formattedFileList);
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('filePath', { ns: 'errors' }));
    }
  };

  const doFileManagerAction = async (request, success, failure) => {
    // Sets file manager to loading
    setFileList([null]);
    try {
      await request;
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t(success, {
            ns: 'notifications',
            text: t('file', { ns: 'fields' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t(failure, {
          ns: 'notifications',
          text: t('file', { ns: 'fields' }),
        })
      );
    } finally {
      await updateFileList(path, subscript, setFileList);
    }
  };

  const getStructure = (file, prevPath, prevFolder, homePathLength) => {
    if (!file) return;
    setFileList([null]);
    const folderIndex = findIndex(prevFolder, { id: file?.id });
    if (folderIndex < 0) {
      // Move into folder
      return {
        newPath: `${prevPath}/${file.name}`,
        newFolderChain: [...prevFolder, file],
      };
    } else {
      // Move out of folder
      const basePath = prevPath.split('/').slice(0, homePathLength);
      const newFolderChain = prevFolder.slice(0, folderIndex + 1);
      const pathArray = newFolderChain.map((file) => file.name);
      const newPath = [...basePath, ...pathArray].join('/');
      return {
        newPath: newPath,
        newFolderChain: newFolderChain,
      };
    }
  };

  const create = (folderName) => {
    doFileManagerAction(
      fileManagerApis.createFolder(
        projectId,
        instanceId || gpsId,
        `${path}/${folderName}`,
        subscript
      ),
      'createSuccess',
      'createError'
    );
  };

  const upload = (files, type) => {
    const fileForm = new FormData();
    files.forEach((file) => {
      fileForm.append('files', file);
    });
    if (type === FILE_MANAGER_UPLOAD_TYPE.REG) {
      doFileManagerAction(
        fileManagerApis.uploadFiles(
          projectId,
          instanceId || gpsId,
          subscript,
          path,
          fileForm
        ),
        'uploadSuccess',
        'uploadError'
      );
    } else if (type === FILE_MANAGER_UPLOAD_TYPE.ZIP) {
      doFileManagerAction(
        fileManagerApis.uploadAndUnzipFiles(
          projectId,
          instanceId || gpsId,
          subscript,
          path,
          fileForm
        ),
        'uploadSuccess',
        'uploadError'
      );
    }
  };

  const edit = (file, history) => {
    let editPath;
    let clusterType;

    if (instanceId) {
      editPath = PRIVATE_ROUTE.INSTANCE_FILE_EDITOR;
      clusterType = CLUSTER_TYPES.SOLR_CLUSTER;
    } else if (gpsId) {
      editPath = PRIVATE_ROUTE.GPS_FILE_EDITOR;
      clusterType = CLUSTER_TYPES.GPS_CLUSTER;
    }
    const editorPage = buildPath({
      path: editPath,
      params: { projectId, instanceId, gpsId },
    });
    history.push({
      pathname: editorPage,
      state: {
        path,
        file,
        subscript,
        clusterType: clusterType,
        redirect: history.location.pathname,
      },
    });
  };

  const download = async (files) => {
    try {
      showToast(
        TOAST_TYPE.INFO,
        t('prepareForDownload', { ns: 'notifications' })
      );

      const isDownload = true;
      const fileNames = files.map((file) => file.name).join(',');

      const response = await fileManagerApis.downloadFiles(
        projectId,
        instanceId,
        isDownload,
        subscript,
        path,
        fileNames
      );
      const name = getFilenameFromResponseHeader(response.headers);
      const mimeType =
        files.length > 1
          ? MIME_TYPE.zip
          : files[0].dir
          ? MIME_TYPE.zip
          : files[0].ext;
      const blob = new Blob([response.data], { type: mimeType });
      saveAs(blob, name);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('downloadSuccess', { ns: 'notifications' })
      );
    } catch {
      showToast(TOAST_TYPE.ERROR, t('downloadError', { ns: 'notifications' }));
    }
  };

  const remove = (files) => {
    const fileNames = files.map((file) => file.name).join(',');
    doFileManagerAction(
      fileManagerApis.deleteFolderFile(
        projectId,
        instanceId || gpsId,
        subscript,
        path,
        fileNames
      ),
      'deleteSuccess',
      'deleteError'
    );
  };

  const rename = (oldName, newName) => {
    const oldPath = `${path}/${oldName}`;
    const newPath = `${path}/${newName}`;
    doFileManagerAction(
      fileManagerApis.renameFolderFile(
        projectId,
        instanceId || gpsId,
        oldPath,
        newPath,
        subscript
      ),
      'updateSuccess',
      'updateError'
    );
  };

  const actionFunctions = {
    getStructure,
    create,
    upload,
    edit,
    download,
    remove,
    rename,
  };

  return {
    actions: actionFunctions,
    updateFileList,
  };
};

export default useFileManager;
