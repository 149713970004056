import { useSnackbar } from 'notistack';

export const TOAST_TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
};

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showToast = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  return { showToast };
};

export default useToast;
