import {
  TableRow,
  TableCell,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { currencyFormat } from '../../../../utils/paymentUtils';
import { sentenceCase } from '../../../../utils/utils';
import Text from '../../../elements/text/Text';

const CollapsedTable = ({ itemsData, open }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            {itemsData.length ? (
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t('billing.type', { ns: 'project' })}
                    </TableCell>
                    <TableCell>
                      {t('billing.description', { ns: 'project' })}
                    </TableCell>
                    <TableCell>{t('billing.net', { ns: 'project' })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemsData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.serviceType}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{currencyFormat(item.amountNet)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box display="flex" my={3} justifyContent="center">
                <Text>
                  {sentenceCase(
                    t('empty', {
                      ns: 'descriptions',
                      text: t('billing.transactionDetails', { ns: 'project' }),
                    })
                  )}
                </Text>
              </Box>
            )}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

CollapsedTable.propTypes = {
  itemsData: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CollapsedTable;
