import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import Title from '../../elements/title/Title';
import Modal from '../../sections/modal/Modal';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const FA2Section = ({ enabled = false, toggle2FA }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    toggle2FA();
  };

  return (
    <section className={classes.root}>
      <Title subTitle text={t('twoFA', { ns: 'sectionTitles' })} gutterBottom />
      <Text gutterBottom>{t('twoFA', { ns: 'descriptions' })}</Text>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Text>
            {enabled
              ? t('twoFAEnabled', { ns: 'descriptions' })
              : t('twoFADisabled', { ns: 'descriptions' })}
          </Text>
        </Grid>
        <Grid item>
          <Button
            onClick={enabled ? toggle2FA : handleOpen}
            color={enabled ? 'dark' : 'primary'}
          >
            {enabled
              ? t('disable2FA', { ns: 'buttons' })
              : t('enable2FA', { ns: 'buttons' })}
          </Button>
        </Grid>
      </Grid>
      <Modal
        openModal={open}
        title={t('twoFASettings', { ns: 'sectionTitles' })}
        showActions
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      >
        {t('twoFAProcess', { ns: 'descriptions' })}
      </Modal>
    </section>
  );
};

FA2Section.propTypes = {
  enabled: PropTypes.bool,
  toggle2FA: PropTypes.func.isRequired,
};

export default FA2Section;
