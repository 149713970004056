import i18next from 'i18next';

import { sentenceCase } from '../../../utils/utils';
import { NOTIFICATION_TYPES } from './constants';

export const validateEmails = (newEmail, emailList) => {
  if (newEmail.trim() === '') {
    return i18next.t('noEmail', { ns: 'validations' });
  }

  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/gi;
  if (!emailRegex.test(newEmail)) {
    return sentenceCase(
      i18next.t('invalid', {
        ns: 'validations',
        field: i18next.t('email', { ns: 'fields' }),
      })
    );
  }

  if (emailList.some((mail) => newEmail === mail)) {
    return i18next.t('duplicateValueAdded', {
      ns: 'validations',
      value: i18next.t('email', { ns: 'fields' }),
    });
  }

  return null;
};

export const emailIsProjectMember = (emailList, projectMembers) => {
  const foundMembers = [];

  emailList.forEach((email) => {
    const memberIndex = projectMembers.findIndex(
      (member) => member.email === email
    );
    if (memberIndex > -1) {
      foundMembers.push(projectMembers[memberIndex]);
    }
  });

  return foundMembers;
};

export const emailIsNotProjectMember = (emailList, projectMembers) => {
  const foundMembers = [];

  emailList.forEach((email) => {
    const memberIndex = projectMembers.findIndex(
      (member) => member.email === email
    );
    if (memberIndex === -1) {
      foundMembers.push(email);
    }
  });

  return foundMembers;
};

export const formatEmailPayload = (data, emails) => {
  const { subject, body } = data;
  const payload = {
    type: NOTIFICATION_TYPES.EMAIL,
    settings: {
      subject: subject,
      body: body,
      emails: emails,
    },
  };

  return payload;
};

export const formatWebhookPayload = (data) => {
  const { text, url } = data;

  const payload = {
    type: NOTIFICATION_TYPES.WEBHOOK,
    settings: {
      text: text,
      url: url,
    },
  };

  return payload;
};

/* eslint-disable no-template-curly-in-string */
export const keywords = [
  {
    label: 'ksWebUrl',
    value: '${KS_WEB_URL}',
  },
  {
    label: 'ksUrl',
    value: '${KS_URL}',
  },
  {
    label: 'projectId',
    value: '${PROJECT_ID}',
  },
  {
    label: 'projectName',
    value: '${PROJECT_NAME}',
  },
  {
    label: 'projectUrl',
    value: '${PROJECT_URL}',
  },
  {
    label: 'instanceId',
    value: '${INSTANCE_ID}',
  },
  {
    label: 'instanceName',
    value: '${INSTANCE_NAME}',
  },
  {
    label: 'instanceUrl',
    value: '${INSTANCE_URL}',
  },
  //   {
  //     label: 'instanceSolrUrl',
  //     value: '${ks.instance.solr.url}',
  //   },
  //   {
  //     label: 'instanceNode',
  //     value: '${ks.instance.node}',
  //   },
  //   {
  //     label: 'instanceNodeSolrUrl',
  //     value: '${ks.instance.node.solr.url}',
  //   },
  {
    label: 'error',
    value: '${ERRORS}',
  },
];
/* eslint-disable no-template-curly-in-string */
