import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import Text from '../../elements/text/Text';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const NoContent = ({ title, button }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      className={classes.background}
      data-testid="no-content"
      textAlign="center"
      py={5}
      px={3}
    >
      {isSmallScreen ? (
        <Box>
          <Text caption color="textSecondary">
            {title}
          </Text>
          {button}
        </Box>
      ) : (
        <Box>
          <Text subtitle color="textSecondary">
            {title}
          </Text>
          {button}
        </Box>
      )}
    </Box>
  );
};

export default NoContent;

NoContent.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.node,
};
