import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BACKUP_LABELS } from '../../../../constants';
import { formatDateTime } from '../../../../utils/timeUtils';
import Button from '../../../elements/button/Button';
import Text from '../../../elements/text/Text';
import Modal from '../../../sections/modal/Modal';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '49%',
  },
  modalItem: {
    marginBottom: theme.spacing(2),
  },
  noTopMargin: {
    marginTop: 0,
  },
  fileCard: {
    overflow: 'auto',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  nodeSelect: {
    width: '98%',
    marginBottom: theme.spacing(2),
  },
}));

const DownloadModal = ({
  open,
  backup,
  onDownload,
  onDownloadTOD,
  onClose,
  isFile,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const fieldData = [
    [t('backupSubject', { ns: 'fields' }), backup?.subject],
    [t('instance', { ns: 'instance' }), backup?.instanceSetName],
    [t('backup.executeDate', { ns: 'project' }), backup?.createDate],
    [
      t('type', { ns: 'project' }),
      t(find(BACKUP_LABELS, { value: backup?.category })?.label, {
        ns: 'menus',
      }),
    ],
    [t('collection', { ns: 'instance' }), backup?.collection],
    [t('nodeNumber', { ns: 'instance' }), backup?.instanceSubscript],
  ];

  const fileData = [
    [t('includedFiles', { ns: 'fields' }), backup?.includeFiles],
    [t('excludedFiles', { ns: 'fields' }), backup?.excludeFiles],
  ];

  return (
    backup && (
      <Modal
        title={t('backup.fileDownload', { ns: 'project' })}
        openModal={open}
        handleClose={onClose}
      >
        {fieldData.map(
          (field) =>
            (!isFile || field[0] !== t('collection', { ns: 'instance' })) && (
              <Box display="flex" className={classes.modalItem} key={field[0]}>
                <Box className={classes.title}>
                  <Text bodyBold>{field[0]}</Text>
                </Box>
                <Box className={classes.noTopMargin}>
                  {field[0] === t('backup.executeDate', { ns: 'project' }) ? (
                    <Text data-testid={field[1]}>
                      {field[1] ? formatDateTime(field[1]) : '-'}
                    </Text>
                  ) : (
                    <Text data-testid={field[1]}>{field[1]}</Text>
                  )}
                </Box>
              </Box>
            )
        )}

        {isFile && (
          <>
            {/* Included && Excluded Files */}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {fileData.map((fileType) => (
                <Box flexDirection="column" width="50%" key={fileType[0]}>
                  <Text bodyBold style={{ fontSize: '0.75em' }}>
                    {fileType[0]}
                  </Text>
                  <Card
                    variant="outlined"
                    aria-label="preview card"
                    className={classes.fileCard}
                  >
                    <Box p={1} height={'5em'} overflow="auto">
                      <Text style={{ whiteSpace: 'pre-wrap' }}>
                        {fileType[1]}
                      </Text>
                    </Box>
                  </Card>
                </Box>
              ))}
            </Box>

            {/* Backed Up Files */}
            <Box display="flex" flexDirection="column">
              <Text bodyBold style={{ fontSize: '0.75em' }}>
                {t('backup.backedUpFiles', { ns: 'project' })}
              </Text>
              <Card
                variant="outlined"
                aria-label="preview card"
                className={classes.fileCard}
              >
                <Box p={1} height="5em" overflow="auto">
                  <Text style={{ whiteSpace: 'pre-wrap' }}>
                    {backup?.files}
                  </Text>
                </Box>
              </Card>
            </Box>
          </>
        )}

        <Box display="flex" justifyContent="center">
          <Button
            onClick={onDownload}
            style={{ marginRight: 16, alignSelf: 'flex-start' }}
          >
            {t('backup.pcDownload', { ns: 'project' })}
          </Button>
          <Box display="flex" flexDirection="column">
            <Button onClick={onDownloadTOD}>
              {t('backup.cloudDownload', { ns: 'project' })}
            </Button>
            <Text caption style={{ alignSelf: 'center' }}>
              {t('backup.downloadLocation', { ns: 'project' })}
            </Text>
          </Box>
        </Box>
      </Modal>
    )
  );
};

DownloadModal.propTypes = {
  backup: PropTypes.object,
  onDownload: PropTypes.func,
  onDownloadTOD: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  isFile: PropTypes.bool,
  isSolrCloud: PropTypes.bool,
};

export default DownloadModal;
