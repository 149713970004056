import { instance, getToken } from './config';

const getImages = (page = 0, size = 1000) =>
  instance.get('/images', {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { page, size, sort: 'createdAt,desc' },
  });

const createImage = (data) =>
  instance.post('/images', data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const updateImage = (imageId, data) =>
  instance.put(`/images/${imageId}`, data, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteImage = (imageId) =>
  instance.delete(`/images/${imageId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

export const imageApis = {
  getImages,
  createImage,
  updateImage,
  deleteImage,
};
