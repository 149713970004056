import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentenceCase } from '../../../../utils/utils';
import Modal from '../../../sections/modal/Modal';

const DeleteCardModal = ({ visible, onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal
      openModal={visible}
      title={t('confirmation', {
        ns: 'sectionTitles',
        text: t('billing.creditCard', { ns: 'project' }),
      })}
      showActions
      handleClose={onClose}
      handleConfirm={onConfirm}
    >
      {sentenceCase(
        t('areYouSure', {
          ns: 'descriptions',
          text: t('billing.creditCard', { ns: 'project' }),
        })
      )}
      {t('cantUndo', { ns: 'descriptions' })}
    </Modal>
  );
};

DeleteCardModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DeleteCardModal;
