import { useEffect, useState } from 'react';

import { Box, Container, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';

import { crawlerApis } from '../../../apis/crawlerApis';
import { projectApis } from '../../../apis/projectApis';
import Button from '../../../components/elements/button/Button';
import Loading from '../../../components/elements/loading/Loading';
import CrawlerStatusTable from '../../../components/page-sections/web-crawler/crawler-status/CrawlerStatusTable';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import { sentenceCase } from '../../../utils/utils';

const useStyles = makeStyles((theme) => ({
  titlePanel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1em',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  returnButton: {
    marginRight: '1em',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: '1em',
    },
  },
}));

const ProjectCrawlerStatusDetailsPage = () => {
  const { projectId, crawlerJobId } = useParams();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [job, setJob] = useState(null);
  const [status, setStatus] = useState(null);

  const fetchProject = async () => {
    try {
      const { data: projectRes } = await projectApis.getProject(projectId);
      setProject(projectRes);
    } catch (error) {
      handleErrors(history, error, showToast);
    }
  };

  const fetchCrawlJob = async () => {
    setLoading(true);
    try {
      const { data: crawlJobRes } = await crawlerApis.getCrawlJob(
        projectId,
        crawlerJobId
      );
      setJob(crawlJobRes);
      await handleGetJobStatus(crawlerJobId);
    } catch (error) {
      return history.replace(PRIVATE_ROUTE.ERROR404);
    } finally {
      setLoading(false);
    }
  };

  const handlePageLoad = async () => {
    await fetchProject();
    await fetchCrawlJob();
  };

  useEffect(() => {
    if (!projectId || !crawlerJobId) return;
    handlePageLoad();
    // eslint-disable-next-line
  }, []);

  const handleGetJobStatus = async (jobSlotId) => {
    try {
      const { data: statusRes } = await crawlerApis.getCrawlJobStatus(
        projectId,
        jobSlotId
      );

      setStatus(statusRes);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('fetch', {
            ns: 'errors',
            content: t('crawler.jobStatus', { ns: 'sectionTitles' }),
          })
        )
      );
      setStatus(null);
    }
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Breadcrumbs projectName={project?.name} jobName={job?.name} />
          <Box className={classes.titlePanel}>
            <TitlePanel
              visible
              title={t('crawler.jobStatus', { ns: 'sectionTitles' })}
            />
            <Box className={classes.buttonContainer}>
              <Button
                variant="text"
                className={classes.returnButton}
                component={RouterLink}
                to={buildPath({
                  path: PRIVATE_ROUTE.PROJECT_CRAWLER,
                  params: { projectId },
                })}
              >
                {t('returnToJobOverview', { ns: 'buttons' })}
              </Button>
              <Button onClick={fetchCrawlJob}>
                {t('reload', { ns: 'buttons' })}
              </Button>
            </Box>
          </Box>

          <CrawlerStatusTable job={job} status={status} />
        </>
      )}
    </Container>
  );
};

export default ProjectCrawlerStatusDetailsPage;
