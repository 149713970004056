import { useEffect, useState } from 'react';

import {
  makeStyles,
  Box,
  useTheme,
  useMediaQuery,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ReferenceLine,
  Tooltip,
  Cell,
} from 'recharts';

import Text from '../../elements/text/Text';
import NoContent from '../../sections/no-content/NoContent';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    display: 'flex',
    justify: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    opacity: '0.8',
    borderRadius: 5,
    color: 'white',
    padding: '2px 10px 2px 10px',
  },
  indent: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const S3BarChart = ({ getStorage }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [storage, setStorage] = useState(null);

  const fetchStorage = async () => {
    const storageRes = await getStorage();
    setStorage(storageRes);
  };

  useEffect(() => {
    fetchStorage();
    // eslint-disable-next-line
  }, []);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <section>
      <Text>{t('backupDataUse', { ns: 'sectionTitles' })}</Text>
      {!storage ? (
        <NoContent title={t('noDataUsage', { ns: 'descriptions' })} />
      ) : isSmallScreen ? (
        <Table size="small" className={classes.indent}>
          <TableBody>
            {storage.usage.map((entry) => {
              return (
                <TableRow key={entry.name}>
                  <TableCell>{entry.name}:</TableCell>
                  <TableCell>{entry.value} GB</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <div data-testid="s3-barchart">
          <BarChart
            width={600}
            height={120}
            data={storage.usage}
            layout="vertical"
            margin={{ left: 50 }}
            className={classes.indent}
          >
            <XAxis type="number" domain={[0, 'auto']} />
            <YAxis
              type="category"
              dataKey="name"
              tickLine={false}
              width={120}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="value" barSize={20} fill={theme.palette.info.main}>
              {storage.usage.map((entry, index) => {
                return (
                  <Cell
                    key={index}
                    fill={
                      entry.value > storage.quota
                        ? theme.palette.error.light
                        : theme.palette.info.light
                    }
                  />
                );
              })}
            </Bar>
            <ReferenceLine x={storage.quota} />
          </BarChart>
        </div>
      )}
    </section>
  );
};

S3BarChart.propTypes = {
  getStorage: PropTypes.func,
};

export default S3BarChart;

const CustomTooltip = ({ active, payload, label }) => {
  const classes = useStyles();

  if (active && payload && payload.length) {
    return (
      <Box className={classes.tooltip}>
        <Text caption>
          {label}: {payload[0].value} GB
        </Text>
      </Box>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
};
