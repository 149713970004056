import moment from 'moment-timezone';

import { DEFAULT_TIMEZONE } from '../constants';
import i18n from '../i18n';

export const formatMonthNum = (month) => {
  return month < 10 ? `0${month}` : month;
};

export const formatNewsDate = (utcDateTime) =>
  moment
    .utc(utcDateTime)
    .tz(DEFAULT_TIMEZONE)
    .format(i18n.t('format.newsDate', { ns: 'general' }));

export const formatDate = (date) => {
  return moment(date).format(i18n.t('format.fullDate', { ns: 'general' }));
};

export const formatDateUTC = (date, timezone) => {
  return moment
    .utc(date)
    .tz(timezone || DEFAULT_TIMEZONE)
    .format('YYYY-MM-DD');
};

export const formatDateTime = (dateTime) => {
  return moment(dateTime).format(
    i18n.t('format.fullDateTime', { ns: 'general' })
  );
};

export const formatDateTimeUTC = (utcDateTime, timeZone, showZone = false) => {
  const format = showZone ? 'YYYY-MM-DD HH:mm:ss (z)' : 'YYYY-MM-DD HH:mm:ss';
  return moment
    .utc(utcDateTime)
    .tz(timeZone || DEFAULT_TIMEZONE)
    .format(format);
};

export const getRemainDays = (dateString) => {
  const now = moment.tz(DEFAULT_TIMEZONE).startOf('day');
  const end = moment(dateString).add(7, 'days').startOf('day');
  return Math.abs(now.diff(end, 'days'));
};

export const getTime = (time) => moment(time, 'HH:mm').format('HH:mm');

export const nth = (n) =>
  (n += ['', 'st', 'nd', 'rd'][(n % 100 >> 3) ^ 1 && n % 10] || 'th');

export const formatToTokyoDate = (date) => {
  return moment.utc(date).tz(DEFAULT_TIMEZONE).format('YYYY-MM-DD');
};

export const localDateTimeToUTC = (datetime, timezone, format) => {
  return moment(datetime).tz(timezone).utc().format(format);
};
