import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as yup from 'yup';

import { formatBytes, sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Title from '../../elements/title/Title';
import ImageUpload from '../../sections/image-upload/ImageUpload';
import { FILE_SIZE, SUPPORTED_FORMATS } from '../instance-stepper/constants';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  space: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ProjectForm = ({ onSubmit, data, handleRemoveImage }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('projectName', { ns: 'fields' }),
          })
        )
      )
      .min(2, t('minLength', { ns: 'validations', min: '2' }))
      .max(25, t('maxLength', { ns: 'validations', max: '25' }))
      .matches(
        /^[ぁ-んァ-ン一-龯ー0-9A-Za-z_-]+$/,
        t('nameFormat', { ns: 'validations' })
      ),
    description: yup
      .string()
      .max(256, t('maxLength', { ns: 'validations', max: '256' })),
    icon: yup
      .mixed()
      .test(
        'fileFormat',
        t('invalidImageFormat', { ns: 'validations' }),
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .test(
        'fileSize',
        t('invalidSize', {
          ns: 'validations',
          size: formatBytes(FILE_SIZE.BYTES, 0),
        }),
        (value) => !value || (value && value.size <= FILE_SIZE.BYTES)
      ),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const goBack = () => history.goBack();

  return (
    <>
      {data && (
        <Title subTitle text={t('editProject', { ns: 'sectionTitles' })} />
      )}
      <Container maxWidth="md" className={data ? classes.margin : ''}>
        <div className={classes.space} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ImageUpload
                name="icon"
                control={control}
                limit={FILE_SIZE.BYTES}
                imageSrc={data?.icon}
                onRemoveImage={handleRemoveImage}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue={data ? data.name : ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('projectName', { ns: 'fields' })}
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                    inputProps={{ 'aria-label': 'name' }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('description')}
                label={t('projectDesc', { ns: 'fields' })}
                defaultValue={data ? data?.description : ''}
                error={!!errors?.description}
                helperText={errors?.description?.message}
                inputProps={{
                  'aria-label': 'description',
                  maxLength: 256,
                }}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center">
                {data ? (
                  <Button
                    type="submit"
                    disabled={!isDirty || !isValid}
                    aria-label="update"
                    style={{ marginLeft: 10 }}
                  >
                    {t('save', { ns: 'buttons' })}
                  </Button>
                ) : (
                  <>
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={goBack}
                      aria-label="go back"
                      style={{ marginRight: 10 }}
                    >
                      {t('back', { ns: 'buttons' })}
                    </Button>
                    <Button
                      type="submit"
                      aria-label="create"
                      disabled={!isValid}
                    >
                      {t('create', { ns: 'buttons' })}
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

ProjectForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
  handleRemoveImage: PropTypes.func,
};

export default ProjectForm;
