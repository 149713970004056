import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  titleSpace: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subTitleSpace: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Title = ({ subTitle, text, ...restProps }) => {
  const classes = useStyles();

  if (subTitle) {
    return (
      <Typography variant="h4" className={classes.subTitleSpace} {...restProps}>
        {text}
      </Typography>
    );
  } else {
    return (
      <Typography
        variant="h2"
        component="h1"
        className={classes.titleSpace}
        {...restProps}
      >
        {text}
      </Typography>
    );
  }
};

Title.propTypes = {
  subTitle: PropTypes.bool,
  text: PropTypes.string,
};

Title.defaultProps = {
  subTitle: false,
};

export default Title;
