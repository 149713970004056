import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_KANDASEARCH_API_BASE_URL;
const VERSION = 'v1';

export const instance = axios.create({
  baseURL: `${API_BASE_URL}/${VERSION}`,
  timeout: 15000,
});

export const getToken = () => sessionStorage.getItem('user-token');

export const requestAll = (requestArray) => axios.all(requestArray);
