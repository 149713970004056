import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../elements/button/Button';
import CrawlStep1 from '../crawler-stepper/CrawlStep1';
import { stepperDefaultValues } from '../crawlerUtils';
import { schema } from './crawlerFormSchema';
import CrawlStep2 from './CrawlStep2';
import CrawlStep3 from './CrawlStep3';
import CrawlStep4 from './CrawlStep4';
import CrawlStep5 from './CrawlStep5';
import CrawlStep6 from './CrawlStep6';

const CrawlerStepper = ({
  onClose,
  onSubmit,
  instances,
  getCollections,
  getFreeJobs,
  getMembers,
  getUniqueKey,
  projectSetup,
  project,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  const nextStep = async () => {
    switch (step) {
      case 0:
        return (await trigger('jobType')) && setStep(step + 1);
      case 1:
        return (
          (await trigger(['jobName', 'user', 'seeds', 'subseeds'])) &&
          setStep(step + 1)
        );
      case 2:
        return (
          (await trigger(['includeInCrawl', 'includeInIndex'])) &&
          setStep(step + 1)
        );
      case 3:
        return (
          (await trigger([
            'instance',
            'collection',
            'uniqueKey',
            'updateHandler',
            'removeHandler',
            'statusHandler',
            'commitTime',
            'commitAfterJob',
            'crawlArgs',
          ])) && setStep(step + 1)
        );
      case 4:
        return (
          (await trigger(['periodType', 'periodTime', 'date', 'day'])) &&
          setStep(step + 1)
        );
      default:
        return false;
    }
  };
  const prevStep = () => setStep(step - 1);

  const isFirstStep = step === 0;
  const isLastStep = step === 5;

  const { control, handleSubmit, watch, setValue, getValues, trigger } =
    useForm({
      mode: 'onChange',
      defaultValues: stepperDefaultValues,
      shouldUnregister: false,
      resolver: yupResolver(schema()),
    });

  const stepTitles = [
    'crawler.typeSettings',
    'crawler.jobSettings',
    'crawler.extraSettings',
    'crawler.solrSettings',
    'crawler.scheduleSettings',
    'crawler.confirm',
  ];

  return (
    <>
      <Stepper
        activeStep={step}
        style={{ marginTop: '-2em', marginBottom: '-2em' }}
        alternativeLabel
      >
        {stepTitles.map((step) => (
          <Step key={step}>
            <StepLabel>{t(step, { ns: 'sectionTitles' })}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <form autoComplete="off">
        <Box mt={2}>
          {step === 0 && (
            <CrawlStep1
              control={control}
              watch={watch}
              getFreeJobs={getFreeJobs}
              setValue={setValue}
              project={project}
              projectSetup={projectSetup}
            />
          )}
          {step === 1 && (
            <CrawlStep2
              control={control}
              getMembers={getMembers}
              getValues={getValues}
              watch={watch}
            />
          )}
          {step === 2 && <CrawlStep3 control={control} />}
          {step === 3 && (
            <CrawlStep4
              control={control}
              getCollections={getCollections}
              getUniqueKey={getUniqueKey}
              instances={instances}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
            />
          )}
          {step === 4 && (
            <CrawlStep5
              control={control}
              getValues={getValues}
              setValue={setValue}
            />
          )}
          {step === 5 && (
            <CrawlStep6
              getValues={getValues}
              card={project.cards[0]}
              billingInfo={project.billingInfo[0]}
              instances={instances}
            />
          )}
        </Box>

        <Box mt={2} mb={1} display="flex" justifyContent="space-between">
          <Button variant="text" onClick={isFirstStep ? onClose : prevStep}>
            {isFirstStep
              ? t('cancel', { ns: 'buttons' })
              : t('previousStep', { ns: 'buttons' })}
          </Button>
          {isLastStep ? (
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              {t('createCrawlerJob', { ns: 'buttons' })}
            </Button>
          ) : (
            <Button variant="text" onClick={nextStep}>
              {t('nextStep', { ns: 'buttons' })}
            </Button>
          )}
        </Box>
      </form>
    </>
  );
};

CrawlerStepper.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  instances: PropTypes.array.isRequired,
  getCollections: PropTypes.func.isRequired,
  getFreeJobs: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  getUniqueKey: PropTypes.func.isRequired,
  processing: PropTypes.bool,
  projectSetup: PropTypes.bool,
  project: PropTypes.object.isRequired,
};

export default CrawlerStepper;
