import { useState } from 'react';

import { Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../../sections/modal/Modal';
import MemberInviteForm from './MemberInviteForm';

const MemberInviteButton = ({ onSubmit }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (data) => {
    onSubmit(data);
    handleClose();
  };

  return (
    <section>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        {t('addProjectMember', { ns: 'project' })}
      </Button>
      <Modal
        openModal={open}
        title={t('addProjectMember', { ns: 'project' })}
        form={
          <MemberInviteForm onSubmit={handleSubmit} onClose={handleClose} />
        }
        handleClose={handleClose}
      >
        <Typography style={{ marginBottom: '1em' }}>
          {t('userInviteDetails', { ns: 'descriptions' })}
        </Typography>
      </Modal>
    </section>
  );
};

MemberInviteButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MemberInviteButton;
