import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import StripeCardForm from '../../../sections/credit-card-form/StripeCardForm';
import Modal from '../../../sections/modal/Modal';

const AddCardModal = ({ visible, onClose, onConfirm, children }) => {
  const { t } = useTranslation('project');

  return (
    <>
      <Modal
        openModal={visible}
        title={t('addition', { text: t('billing.creditCard') })}
        handleClose={onClose}
      >
        {children}
        <StripeCardForm
          title="Stripe"
          handleSubmit={onConfirm}
          handleClose={onClose}
        />
      </Modal>
    </>
  );
};

export default AddCardModal;

AddCardModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.node,
};
