import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';

import Text from '../../elements/text/Text';

const SelectDropdown = ({
  title,
  selectList,
  value,
  valueKey,
  nameKey,
  prefix = '',
  onChange,
  topMargin,
  noPrefix,
  ariaLabel = 'selection switch',
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const getDefaultValue = () => {
    if (value) return value;
    return valueKey ? selectList[0][valueKey] : selectList[0];
  };

  return (
    <Box mt={topMargin ? 2 : 0} display="flex">
      {title && (
        <Text
          text={title}
          style={{ alignSelf: 'center', marginRight: '1em' }}
        />
      )}
      <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
        <Select
          onChange={handleChange}
          defaultValue={getDefaultValue()}
          inputProps={{ 'aria-label': ariaLabel }}
        >
          {selectList.map((item) => (
            <MenuItem
              key={valueKey ? item[valueKey] : item}
              value={valueKey ? item[valueKey] : item}
            >
              {noPrefix
                ? nameKey
                  ? `${item[nameKey]}`
                  : `${item}`
                : nameKey
                ? `${prefix}-${item[nameKey]}`
                : `${prefix}${item}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

SelectDropdown.propTypes = {
  title: PropTypes.string,
  selectList: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  valueKey: PropTypes.string,
  nameKey: PropTypes.string,
  prefix: PropTypes.string,
  topMargin: PropTypes.bool,
  noPrefix: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default SelectDropdown;
