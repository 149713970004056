import { makeStyles } from '@material-ui/core/styles';

import { LAYOUT_WIDTH } from '../../layouts/constants';

export const useStyles = makeStyles((theme) => ({
  sidebarHeader: {
    overflow: 'hidden',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#fff',
    paddingTop: theme.spacing(0.5),
    paddingLeft: (open) => (open ? theme.spacing(1) : 0),
    width: (open) => (open ? LAYOUT_WIDTH.MAX : LAYOUT_WIDTH.MIN),
    transition: theme.transitions.create(['padding', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    ...theme.mixins.toolbar,
  },
  sidebarBrand: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& .nav-brand-image': {
      paddingLeft: (open) => (open ? 0 : 11),
      transition: theme.transitions.create('padding', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
    },
    '& .nav-brand-name': {
      marginLeft: (open) => (open ? 0 : 5),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
    },
  },
}));
