import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AvatarGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../contexts/UserContext';
import Text from '../../elements/text/Text';
import Title from '../../elements/title/Title';
import DragAndDropItems from './DragAndDropItems';
import FormButtons from './FormButtons';
import { emailSchema } from './formSchemas';
import {
  emailIsNotProjectMember,
  emailIsProjectMember,
  formatEmailPayload,
  validateEmails,
} from './utils';

const EmailForm = ({
  notif,
  projectMembers,
  onEmailSubmit,
  onSendTest,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [users, setUsers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [emailError, setEmailError] = useState(null);

  useEffect(() => {
    if (!notif) return;

    const notifEmails = notif.settings.emails;

    const projectMemEmails = emailIsProjectMember(notifEmails, projectMembers);
    const outsideEmails = emailIsNotProjectMember(notifEmails, projectMembers);

    setEmails(outsideEmails);
    setUsers(projectMemEmails);

    setValue('projectMembers', projectMemEmails);
    setValue('subject', notif.settings.subject);
    setValue('body', notif.settings.body);
    // eslint-disable-next-line
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      projectMembers: '',
      email: '',
      subject: t('notifSubject', { ns: 'instance' }),
      body: t('notifMessage', { ns: 'instance' }),
    },
    resolver: yupResolver(emailSchema(emails)),
  });

  const { email } = watch();

  const handleAddEmail = () => {
    // check both project member emails and outside emails for duplicates
    const emailList = [...emails, ...users.map((user) => user.email)];

    const error = validateEmails(email, emailList);
    setEmailError(null);
    clearErrors(['email', 'projectMembers']);
    if (error) {
      setEmailError(error);
      return true;
    }

    const prevEmails = [...emails];
    setEmails([...prevEmails, email]);
    setValue('email', '');
  };

  const handleDeleteEmail = (deleteEmail) => {
    const prevEmails = [...emails];
    setEmails(prevEmails.filter((email) => email !== deleteEmail));
  };

  const renderProjectEmails = (selectedUsers) => {
    const selectList = selectedUsers.map((user) => user.publicName);
    return selectList.join(', ');
  };

  const handleProjectMembersChange = (event) => {
    // Checks the emails the user typed in.
    // If there is a duplicate between project member emails and typed in emails,
    // it removes the typed in email and keeps the user email.
    const addedUser = event.target.value[event.target.value.length - 1];
    const dupeEmail = emails.find((email) => email === addedUser.email);

    if (dupeEmail) {
      handleDeleteEmail(addedUser.email);
    }

    setUsers(event.target.value);
  };

  const handleEmailSubmit = async (data) => {
    const allEmails = data?.projectMembers
      ? [...data.projectMembers.map((member) => member.email), ...emails]
      : emails;

    const formattedPayload = formatEmailPayload(data, allEmails);
    await onEmailSubmit(formattedPayload);
    onCancel();
  };

  const handleSendTest = async () => {
    // validate the email before allowing user to send.
    await trigger(['subject', 'body']);
    if (!!errors.subject || !!errors.body) return;

    const formValues = getValues();
    const formattedPayload = formatEmailPayload(formValues, [user.email]);
    onSendTest(formattedPayload);
  };

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(handleEmailSubmit)} aria-label="email form">
        <Box display="flex">
          <Text
            bodyBold
            style={{ width: 160, paddingTop: '0.5em', marginRight: '1em' }}
          >
            {t('monitor.projectMember', { ns: 'fields' })}
          </Text>
          <Controller
            name="projectMembers"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                size="small"
                error={!!errors.projectMembers}
              >
                <InputLabel>
                  {t('monitor.projectMember', { ns: 'fields' })}
                </InputLabel>
                <Select
                  {...field}
                  label={t('monitor.projectMember', { ns: 'fields' })}
                  inputProps={{ 'aria-label': 'Project Members' }}
                  multiple
                  value={users}
                  renderValue={renderProjectEmails}
                  onChange={(e) => {
                    setEmailError(null);
                    clearErrors(['projectMembers', 'email']);
                    handleProjectMembersChange(e);
                    field.onChange(e);
                  }}
                  style={{ width: 300, marginRight: '1em' }}
                >
                  {projectMembers.map((member) => (
                    <MenuItem value={member} key={member.id}>
                      <Checkbox checked={users.indexOf(member) > -1} />
                      <ListItemText primary={member.publicName} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors?.projectMembers?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {users && (
            <AvatarGroup max={4}>
              {users.map((user) => (
                <Avatar
                  key={user.id}
                  aria-label="member avatar"
                  alt={user?.icon || user.publicName}
                  src={user?.icon || user.publicName}
                />
              ))}
            </AvatarGroup>
          )}
        </Box>
        <Box display="flex" mt={2}>
          <Text
            bodyBold
            style={{ width: 160, marginRight: '1em', paddingTop: '0.5em' }}
          >
            {t('monitor.emailAddress', { ns: 'fields' })}
          </Text>
          <Box>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('monitor.emailAddress', { ns: 'fields' })}
                  variant="outlined"
                  size="small"
                  style={{ width: 300, marginRight: '1em' }}
                  error={!!emailError || !!errors?.email}
                  helperText={emailError || errors?.email?.message}
                  onChange={(e) => {
                    setEmailError(null);
                    clearErrors(['projectMembers', 'email']);
                    field.onChange(e.target.value);
                  }}
                  inputProps={{ 'aria-label': 'email address' }}
                />
              )}
            />
            <IconButton
              aria-label="add email"
              style={{ alignSelf: 'flex-start', marginTop: '-0.15em' }}
              onClick={handleAddEmail}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
        {emails && (
          <Box mt={0.5} ml={22}>
            {emails.map((email) => (
              <Chip
                key={email}
                label={email}
                size="small"
                onDelete={() => handleDeleteEmail(email)}
                style={{ marginTop: '.25em', marginRight: '0.25em' }}
              />
            ))}
          </Box>
        )}
        <Title
          subTitle
          text={t('monitoring.emailSettings', { ns: 'sectionTitles' })}
        />
        <DragAndDropItems />
        <Controller
          name="subject"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('monitor.emailSubject', { ns: 'fields' })}
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors?.subject}
              helperText={errors?.subject?.message}
              style={{ marginTop: '0.5em' }}
              inputProps={{ 'aria-label': 'email subject' }}
            />
          )}
        />
        <Controller
          name="body"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('monitor.emailBody', { ns: 'fields' })}
              variant="outlined"
              multiline
              rows={5}
              fullWidth
              style={{ marginTop: '1em' }}
              inputProps={{ 'aria-label': 'email body' }}
            />
          )}
        />
        <FormButtons
          type="EMAIL"
          onCancel={onCancel}
          onSendTest={handleSendTest}
        />
      </form>
    </Box>
  );
};

EmailForm.propTypes = {
  notif: PropTypes.object,
  projectMembers: PropTypes.array.isRequired,
  onEmailSubmit: PropTypes.func.isRequired,
  onSendTest: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EmailForm;
