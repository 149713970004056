import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';

const DeleteModal = ({ collectionName, openModal, onDeleteDocs, onClose }) => {
  const { t } = useTranslation();

  const handleDeleteDocs = () => onDeleteDocs();
  const handleClose = () => onClose();

  return (
    <Modal
      openModal={openModal}
      title={t('deletion', {
        ns: 'project',
        text: t('collectionDocs', { ns: 'instance' }),
      })}
      handleClose={handleClose}
      handleConfirm={handleDeleteDocs}
      showActions
    >
      <Text>
        {t('deleteCollectionDocs', {
          ns: 'descriptions',
          text: collectionName,
        })}{' '}
        {t('cantUndo', { ns: 'descriptions' })}
      </Text>
    </Modal>
  );
};

DeleteModal.propTypes = {
  collectionName: PropTypes.string,
  openModal: PropTypes.bool,
  onDeleteDocs: PropTypes.func,
  onClose: PropTypes.func,
};

export default DeleteModal;
