import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { values } from 'lodash';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import { useServer } from '../../contexts/ServerContext';
import { useSolrInstance } from '../../contexts/SolrContext';
import { useUser } from '../../contexts/UserContext';
import useLocalStorage from '../../hooks/useLocalStorage';
import { PRIVATE_ROUTE } from '../../routes/routes';
import PrivateHeader from '../sections/header/PrivateHeader';
import { SIDEBAR_TYPE as TYPE } from '../sections/sidebar/constants';
import Sidebar from '../sections/sidebar/Sidebar';
import { LAYOUT_WIDTH } from './constants';

const useStyles = makeStyles((theme) => ({
  offset: {
    minHeight: theme.mixins.toolbar.minHeight + theme.spacing(1),
  },
  content: {
    maxWidth: ({ open, hasSidebar }) =>
      !hasSidebar
        ? '100%'
        : open
        ? `calc(100% - ${LAYOUT_WIDTH.MAX}px)`
        : `calc(100% - ${LAYOUT_WIDTH.MIN}px)`,
    marginLeft: ({ open, hasSidebar }) =>
      !hasSidebar ? 0 : open ? LAYOUT_WIDTH.MAX : LAYOUT_WIDTH.MIN,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const MainContent = ({ children, open, hasSidebar }) => {
  const classes = useStyles({ open, hasSidebar });

  return (
    <main className={classes.content}>
      <div className={classes.offset} />
      <Box pb={3}>{children}</Box>
    </main>
  );
};

const Layout = ({ sidebar, requireAdmin, ...restProps }) => {
  const history = useHistory();
  const { projectId } = useParams();
  const { user } = useUser();
  const { instance } = useSolrInstance();
  const { server } = useServer();

  const [open, setOpen] = useLocalStorage('sidebar:open', true);
  const [isAdmin, setIsAdmin] = useState({});

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  useEffect(() => {
    if (!user) return;
    const isAdmin = user.isAdmin(projectId);
    if (requireAdmin && !isAdmin) {
      history.replace(PRIVATE_ROUTE.ERROR403);
    }
    setIsAdmin({ isAdmin });
  }, [history, projectId, requireAdmin, user]);

  return (
    <>
      <PrivateHeader
        open={open}
        fullWidth={!sidebar}
        {...isAdmin}
        {...restProps}
      />

      {sidebar && (
        <Sidebar
          open={open}
          type={sidebar}
          handleToggle={handleToggle}
          instance={instance}
          server={server}
          {...isAdmin}
        />
      )}

      <MainContent hasSidebar={!!sidebar} open={open} {...restProps} />
    </>
  );
};

Layout.propTypes = {
  sidebar: PropTypes.oneOf(values(TYPE)),
  requireAdmin: PropTypes.bool,
};

MainContent.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  hasSidebar: PropTypes.bool,
};

export default Layout;
