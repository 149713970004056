import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';

const schema = yup.object().shape({
  query: yup
    .string()
    .trim()
    .required()
    .test('length', 'conditional length', (value, ctx) => {
      if (ctx.parent.isExact) return value.length > 0;
      return value.length > 1;
    }),
  isExact: yup.bool().required(),
});

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  button: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const SearchBar = ({
  isSearching,
  numberFound,
  searchConfig,
  updateSearchConfig,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      query: '',
      isExact: true,
    },
  });

  const isExact = watch('isExact');

  const onSubmit = async (data) => {
    updateSearchConfig(data);
  };

  const clearSearch = () => {
    setValue('query', '');
    updateSearchConfig({
      query: '',
      isExact: true,
    });
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          <Controller
            name="query"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{
                  'aria-label': 'search bar',
                  style: { paddingLeft: 10 },
                }}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  className: classes.input,
                }}
                autoComplete="off"
                placeholder={t('synonym.searchWord', { ns: 'project' })}
                onChange={(e) =>
                  setValue('query', e.target.value, { shouldValidate: true })
                }
              />
            )}
          />

          <Button
            type="submit"
            aria-label="search"
            variant="outlined"
            className={classes.button}
            disabled={!isValid}
          >
            {t('search', { ns: 'buttons' })}
          </Button>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={isSmallScreen ? 'column' : 'row'}
      >
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={isExact}
              inputProps={{ 'aria-label': 'search with exact match' }}
              onChange={(e) =>
                setValue('isExact', e.target.checked, {
                  shouldValidate: true,
                })
              }
              name="isExact"
            />
          }
          label={t('synonym.exactSearch', { ns: 'project' })}
        />
        {isSearching ? (
          <Box display="flex" alignItems="center">
            <Text caption style={{ marginRight: 10 }}>
              {t('synonym.totalHit', {
                ns: 'project',
                word: searchConfig.query,
                number: numberFound.toLocaleString(),
              })}
            </Text>
            <Button size="small" variant="text" onClick={() => clearSearch()}>
              {t('synonym.clearSearch', { ns: 'project' })}
            </Button>
          </Box>
        ) : (
          <Text caption style={{ marginRight: 10 }}>
            {t('synonym.totalWords', {
              ns: 'project',
              number: numberFound.toLocaleString(),
            })}
          </Text>
        )}
      </Box>
    </form>
  );
};

SearchBar.propTypes = {
  isSearching: PropTypes.bool,
  numberFound: PropTypes.number,
  searchConfig: PropTypes.object,
  updateSearchConfig: PropTypes.func,
};

export default SearchBar;
