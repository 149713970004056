import { useState } from 'react';

import { Box, Menu, MenuItem } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Loading from '../../elements/loading/Loading';

const SubToExtButton = ({ projects, onMenuOpen, onSubscribe, styling }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = async (event) => {
    setAnchorEl(event.currentTarget);
    setLoading(true);
    await onMenuOpen();
    setLoading(false);
  };

  const handleCloseMenu = () => setAnchorEl(null);
  const handleSubscribe = (projectId) => {
    onSubscribe(projectId);
    handleCloseMenu();
  };

  return (
    <>
      <Button onClick={(e) => handleOpenMenu(e)}>
        {t('addToProject', { ns: 'buttons' })}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {loading ? (
          <MenuItem className={styling}>
            <Box marginX="auto">
              <Loading />
            </Box>
          </MenuItem>
        ) : projects ? (
          !isEmpty(projects) ? (
            projects.map((project) => (
              <MenuItem
                className={styling}
                key={project.id}
                onClick={() => handleSubscribe(project.id)}
              >
                {project.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled className={styling}>
              {t('noFound', {
                ns: 'descriptions',
                text: t('project', { ns: 'project' }),
              })}
            </MenuItem>
          )
        ) : (
          <MenuItem disabled className={styling}>
            {t('projectLoadError', {
              ns: 'notifications',
            })}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

SubToExtButton.propTypes = {
  projects: PropTypes.array.isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  styling: PropTypes.string.isRequired,
};

export default SubToExtButton;
