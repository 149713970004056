import { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { projectApis } from '../../../apis/projectApis';
import { restoreApis } from '../../../apis/restoreApis';
import Loading from '../../../components/elements/loading/Loading';
import RestoreTable from '../../../components/page-sections/backup-restore/restore-table/RestoreTable';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { sentenceCase } from '../../../utils/utils';

const ProjectRestorePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [restoreList, setRestoreList] = useState(null);

  const getProject = async () => {
    const { data: project } = await projectApis.getProject(projectId);
    setProject(project);
  };

  const getRestoreList = async () => {
    const { data } = await restoreApis.getRestores(projectId);
    setRestoreList(data);
  };

  const handleDelete = async (restoreId) => {
    try {
      await restoreApis.deleteRestore(projectId, restoreId);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('backup.restoreData', { ns: 'project' }),
          })
        )
      );

      await getRestoreList();
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('backup.restoreData', { ns: 'project' }),
          })
        )
      );
    }
  };

  const fetchData = async () => {
    try {
      await getProject();
      await getRestoreList();
      setLoading(false);
    } catch (error) {
      handleErrors(history, error, showToast);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {loading && <Loading />}
      {!loading && (
        <>
          <Breadcrumbs projectName={project?.name} />
          <TitlePanel title={t('restore', { ns: 'titles' })} />
          <RestoreTable restoreList={restoreList} handleDelete={handleDelete} />
        </>
      )}
    </Container>
  );
};

export default ProjectRestorePage;
