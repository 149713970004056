import { TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../../contexts/UserContext';
import { formatDateTimeUTC } from '../../../../utils/timeUtils';
import TextChip from './TextChip';

const ExecutionDateCell = ({ start, end }) => {
  const { t } = useTranslation();
  const user = useUser();

  return (
    <TableCell>
      <TextChip
        title={t('startTime', { ns: 'project' })}
        text={start ? formatDateTimeUTC(start, user?.timeZone) : '-'}
      />
      <TextChip
        title={t('endTime', { ns: 'project' })}
        text={end ? formatDateTimeUTC(end, user?.timeZone) : '-'}
      />
    </TableCell>
  );
};

ExecutionDateCell.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
};

export default ExecutionDateCell;
