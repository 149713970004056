import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextInput from '../../../elements/text-input/TextInput';
import Text from '../../../elements/text/Text';
import { getMaxSeeds } from '../crawlerUtils';

const CrawlStep2 = ({ control, getMembers, getValues, watch }) => {
  const { t } = useTranslation();
  const { errors } = useFormState({ control });

  const [members, setMembers] = useState([]);

  const maxSeeds = getMaxSeeds(getValues('jobType'));
  const seeds = watch('seeds');
  const seedsArr = seeds ? seeds.split('\n') : [];
  const remainingSeeds = maxSeeds - seedsArr.length || 0;

  const fetchUsers = async () => {
    const userRes = await getMembers();
    setMembers(userRes);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  // Limit users from making more seeds than their job allows
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (remainingSeeds === 0) {
        event.preventDefault();
      }
    }
  };

  // Prevent user from bypassing limits by copy/pasting
  const handlePaste = (event) => {
    if (remainingSeeds === 0) {
      event.preventDefault();
    }
  };

  return (
    <Box style={{ overflow: 'hidden' }}>
      <section>
        <Box mb={2}>
          <Text caption style={{ color: 'gray' }}>
            {t('asteriskRequired', { ns: 'fields' })}
          </Text>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextInput
              name="jobName"
              label={`${t('jobName', { ns: 'fields' })} *`}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors?.user}
            >
              <InputLabel>{`${t('user', { ns: 'fields' })} *`}</InputLabel>
              <Controller
                name="user"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    label={`${t('user', { ns: 'fields' })} *`}
                    inputProps={{ 'aria-label': 'user select' }}
                  >
                    {members.map((user) => (
                      <MenuItem key={user.id} value={user.email}>
                        {user.fullName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>{errors?.user?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="jobDescription"
              label={t('jobDescription', { ns: 'fields' })}
              control={control}
              errors={errors}
              style={{ marginBottom: '0.5em' }}
            />
          </Grid>
        </Grid>
        {remainingSeeds > -1 && (
          <Grid container>
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
              <Text caption style={{ marginRight: '1em', fontWeight: 'bold' }}>
                {t('stepper.seedsRemaining', {
                  ns: 'crawler',
                  number: remainingSeeds,
                })}
              </Text>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="seeds"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={seeds}
                  label={`${t('seeds', { ns: 'fields' })} *`}
                  fullWidth
                  variant="outlined"
                  size="small"
                  multiline
                  rows={8}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  error={!!errors?.seeds}
                  helperText={errors?.seeds?.message}
                  inputProps={{ 'aria-label': 'seeds' }}
                  InputLabelProps={{ shrink: true }}
                  placeholder={t('oneSeedPerLine', { ns: 'descriptions' })}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              name="subseeds"
              label={t('additionalSeeds', { ns: 'fields' })}
              InputLabelProps={{ shrink: true }}
              placeholder={t('additionalSeedsInfo', { ns: 'descriptions' })}
              control={control}
              errors={errors}
              size={8}
            />
          </Grid>
        </Grid>
      </section>
    </Box>
  );
};

CrawlStep2.propTypes = {
  control: PropTypes.object.isRequired,
  getMembers: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
};

export default CrawlStep2;
