import { PERIOD_TYPES } from './dateTimeConstants';

export const DEFAULT_STANDARD_INCLUDE_FILES = `/log4j2.xml
/solr.in.sh
/data/*`;
export const DEFAULT_COMMUNITY_INCLUDE_FILES = `/data/*/conf/**`;
export const DEFAULT_EXCLUDE_FILES = `/data/lib/*`;
export const DEFAULT_BACKUP_TIME = '00:00';

export const BACKUP_STATUS = {
  WAITING_TO_CREATE: 'WAITING_TO_CREATE',
  CREATING: 'CREATING',
  CREATED: 'CREATED',
  WAITING_TO_DELETE: 'WAITING_TO_DELETE',
  DELETING: 'DELETING',
  FAILED: 'FAILED',
};

export const RESTORE_STATUS = {
  WAITING_TO_CREATE: 'WAITING_TO_CREATE',
  CREATING: 'CREATING',
  CREATED: 'CREATED',
  DELETED: 'DELETING',
  FAILED: 'FAILED',
};

export const BACKUP_STATUS_DICT = {
  WAITING_TO_CREATE: 'status.preparing',
  CREATING: 'status.creating',
  CREATED: 'status.created',
  WAITING_TO_DELETE: 'status.deleting',
  DELETING: 'status.deleting',
  FAILED: 'status.createFailed',
};

export const RESTORE_STATUS_DICT = {
  WAITING_TO_CREATE: 'status.preparing',
  CREATING: 'status.restoring',
  CREATED: 'status.restored',
  DELETED: 'status.deleted',
  FAILED: 'status.restoreFailed',
};

export const BACKUP_TYPES = {
  INDEX: 'INDEX',
  FILE: 'FILE',
};

export const BACKUP_LABELS = [
  {
    value: BACKUP_TYPES.INDEX,
    label: 'labels.indexBackup',
  },
  {
    value: BACKUP_TYPES.FILE,
    label: 'labels.fileBackup',
  },
];

export const BACKUP_PERIOD_TYPE_LABELS = [
  {
    value: PERIOD_TYPES.DAY,
    label: 'frequency.daily',
  },
  {
    value: PERIOD_TYPES.WEEK,
    label: 'frequency.weekly',
  },
  {
    value: PERIOD_TYPES.MONTH,
    label: 'frequency.monthly',
  },
];
