import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextDivider from '../../../elements/text-divider/TextDivider';

const FacetParamsFormSection = ({
  control,
  errors,
  facetQuery,
  facetQueryChips,
  facetQueryChange,
  onAddChip,
  onDeleteChip,
  style,
}) => {
  const { t } = useTranslation();

  const handleAddChip = (event) => onAddChip(event);
  const handleDeleteChip = (event, chip) => onDeleteChip(event, chip);
  const chipHelper = (value, list, helper, errorHelper) => {
    return includes(list, value)
      ? t('duplicate', { ns: 'errors', item: t(errorHelper, { ns: 'fields' }) })
      : t(helper, { ns: 'descriptions' });
  };

  return (
    <>
      <TextDivider>
        {t('search.facetOptions', { ns: 'sectionTitles' })}
      </TextDivider>
      <Box className={style.inputContainer}>
        <Box className={style.inputFull}>
          <Controller
            name="settings.query.jsonQuery.params.facetField"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  'aria-label': 'facet field',
                }}
                label={t('search.facetField', { ns: 'fields' })}
                variant="outlined"
                fullWidth
                size="small"
                error={!!errors?.settings?.query?.jsonQuery?.params?.facetField}
                helperText={
                  errors?.settings?.query?.jsonQuery?.params?.facetField
                    ?.message
                }
              />
            )}
          />
        </Box>

        <Box className={style.inputFull}>
          <TextField
            label={t('search.facetQuery', { ns: 'fields' })}
            value={facetQuery}
            onChange={facetQueryChange}
            fullWidth
            variant="outlined"
            size="small"
            error={includes(facetQueryChips, facetQuery)}
            helperText={chipHelper(
              facetQuery,
              facetQueryChips,
              'addFacetQuery',
              'search.facetQuery'
            )}
            inputProps={{
              'aria-label': 'facet query',
              style: { overflow: 'auto' },
            }}
            InputProps={{
              style: {
                flexWrap: 'wrap',
                paddingRight: 50,
                paddingTop: facetQueryChips ? '0.25em' : 0,
              },
              startAdornment:
                facetQueryChips &&
                facetQueryChips.map((item) => (
                  <Tooltip title={item} key={item}>
                    <Chip
                      size="small"
                      label={item}
                      style={{
                        padding: '0.25em',
                        marginTop: '0.25em',
                        marginRight: '0.25em',
                        maxWidth: 200,
                      }}
                      onDelete={() => handleDeleteChip('facetQuery', item)}
                    />
                  </Tooltip>
                )),
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={() => handleAddChip('facetQuery')}
                  style={{ position: 'absolute', right: 9 }}
                  data-testid="add"
                >
                  <AddIcon />
                </IconButton>
              ),
            }}
          />
        </Box>
      </Box>
    </>
  );
};

FacetParamsFormSection.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  facetQuery: PropTypes.string,
  facetQueryChips: PropTypes.array,
  facetQueryChange: PropTypes.func.isRequired,
  onAddChip: PropTypes.func.isRequired,
  onDeleteChip: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default FacetParamsFormSection;
