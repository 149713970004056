import { Button as MuiButton } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from './Button.styles';

const CUSTOM_COLORS = ['light', 'dark', 'danger'];

const Button = ({
  children,
  color = 'primary',
  variant = 'contained',
  size = 'medium',
  className,
  ...restProps
}) => {
  const classes = useStyles();

  if (CUSTOM_COLORS.includes(color)) {
    return (
      <MuiButton
        variant={variant}
        size={size}
        className={`${classes[color]} ${className || ''}`}
        {...restProps}
      >
        {children}
      </MuiButton>
    );
  } else {
    return (
      <MuiButton
        variant={variant}
        color={color}
        size={size}
        className={className}
        {...restProps}
      >
        {children}
      </MuiButton>
    );
  }
};

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf([
    'default',
    'light',
    'dark',
    'primary',
    'secondary',
    'danger',
  ]),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
};

export default Button;
