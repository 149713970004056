import { memo } from 'react';

import { useTheme, makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Tooltip, Label } from 'recharts';

import Text from '../text/Text';

const unit = 'GB';

const useStyles = makeStyles(() => ({
  tooltip: {
    display: 'flex',
    justify: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    opacity: '0.8',
    borderRadius: 5,
    color: 'white',
    padding: '2px 10px 2px 10px',
  },
}));

const Storage = memo(({ available, total }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const availableGB = available / 1024 ** 3;
  const totalGB = total / 1024 ** 3;

  const data = [
    {
      name: t('storageUsed', { ns: 'tooltips' }),
      value: +(totalGB - availableGB).toFixed(2),
    },
    {
      name: t('storageRemaining', { ns: 'tooltips' }),
      value: +availableGB.toFixed(2),
    },
  ];

  const percentUsed = Math.round(
    ((totalGB - availableGB) / totalGB) * 100
  ).toString();

  const COLORS = [theme.palette.primary.main, theme.palette.grey[300]];

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={1}>
        <Text>{t('instanceStorage', { ns: 'sectionTitles' })}</Text>
      </Box>
      <PieChart width={150} height={150}>
        <Pie
          nameKey="name"
          dataKey="value"
          data={data}
          innerRadius={50}
          outerRadius={75}
          startAngle={0}
          endAngle={360}
          paddingAngle={1}
        >
          {data.map((entry, i) => (
            <Cell
              class={entry}
              key={`cell-${i}`}
              fill={COLORS[i % COLORS.length]}
            />
          ))}
          <Label position="center">
            {t('percentageUsed', { ns: 'instance', number: percentUsed })}
          </Label>
        </Pie>

        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </Box>
  );
});

export default Storage;

Storage.propTypes = {
  available: PropTypes.number,
  total: PropTypes.number,
};

const CustomTooltip = ({ active, payload, label }) => {
  const classes = useStyles();

  if (active && payload && payload.length) {
    return (
      <Box className={classes.tooltip}>
        <Text caption>{`${payload[0].name}: ${payload[0].value} ${unit}`}</Text>
      </Box>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
};
