import { useState } from 'react';

import LaunchIcon from '@material-ui/icons/Launch';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';
import VersionList from './VersionList';

const DownloadExtensionButton = ({
  extension,
  onAddExtension,
  ...restProps
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const handleAddExtension = async (data) => {
    await onAddExtension(data);
    toggleModal();
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        onClick={toggleModal}
        aria-label="download extension"
      >
        {t('download', { ns: 'buttons' })}
      </Button>
      <Modal openModal={open} title={extension.name} handleClose={toggleModal}>
        {extension.description ? (
          <Text aria-label="extension description">
            {extension.description}
          </Text>
        ) : (
          <Text color="textSecondary">
            {sentenceCase(
              t('empty', {
                ns: 'descriptions',
                text: t('ext.details', { ns: 'project' }),
              })
            )}
          </Text>
        )}
        {extension.descriptionUrl && (
          <Button
            variant="text"
            target="_blank"
            href={extension.descriptionUrl}
            rel="noreferrer"
            endIcon={<LaunchIcon />}
            style={{ marginTop: 8 }}
          >
            {t('readMoreOnLink', { ns: 'buttons' })}
          </Button>
        )}
        <VersionList
          extensionType={extension.type}
          versions={extension.versions}
          handleAdd={handleAddExtension}
          {...restProps}
        />
      </Modal>
    </>
  );
};

DownloadExtensionButton.propTypes = {
  extension: PropTypes.object.isRequired,
  onAddExtension: PropTypes.func.isRequired,
};

export default DownloadExtensionButton;
