import { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import { saveAs } from 'file-saver';
import { find, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { collectionApis } from '../../../apis/collectionApis';
import { requestAll } from '../../../apis/config';
import { isInstanceRunning } from '../../../apis/constants';
import { extensionApis } from '../../../apis/extensionApis';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import ExtensionList from '../../../components/page-sections/extension-list/ExtensionList';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import {
  DEFAULT_SUBSCRIPT_NUMBER,
  EXTENSION_TYPES,
  PLUGIN_DESTINATIONS,
} from '../../../constants';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { isSolrCloud } from '../../../utils/utils';

const ProjectSubsExtension = () => {
  const { projectId } = useParams();
  const { showToast } = useToast();
  const history = useHistory();
  const { t } = useTranslation();

  const [project, setProject] = useState(null);
  const [subExtensions, setSubExtensions] = useState(null);
  const [reminder, setReminder] = useState(false);

  const getExtensions = async (filters, sort) => {
    const { data } = !isEmpty(filters)
      ? await extensionApis.getProjectExtensions(
          projectId,
          filters.join(','),
          sort
        )
      : await extensionApis.getProjectExtensions(projectId, undefined, sort);

    const extensionsWithVersion = !isEmpty(data)
      ? await getExtensionDetails(data)
      : [];

    return extensionsWithVersion;
  };

  const getSubExtensions = async () => {
    const { data } = await projectApis.getSubsExtensions(projectId);
    setSubExtensions(data);
  };

  useEffect(() => {
    getSubExtensions();
    // eslint-disable-next-line
  }, []);

  const getExtensionDetails = async (data, key = 'id') => {
    const requests = data.map((item) => extensionApis.getExtension(item[key]));
    const responses = await requestAll(requests);

    return responses.map((res) => res.data);
  };

  const getConfPath = async (instance, collectionName) => {
    const { data } = await collectionApis.getCollectionRootPath(
      projectId,
      instance.id,
      { name: collectionName, solrCloud: isSolrCloud(instance.type) }
    );
    return data.rootPath.split('/').slice(2).join('/');
  };

  const getLibPath = (instance, downloadTo, collectionName = '') => {
    if (isSolrCloud(instance.type)) {
      return 'solr/data/lib';
    } else {
      const selectedOpt = find(PLUGIN_DESTINATIONS, {
        value: downloadTo,
      });
      return selectedOpt.default
        ? 'solr/data/lib'
        : `solr/data/${collectionName}/lib`;
    }
  };

  const handleDownload = async (version) => {
    if (!version) return;

    try {
      const { resourcePath, fileName } = version;
      saveAs(resourcePath, fileName);
      await extensionApis.increaseDownloadCount(version.extensionId);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('downloadSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('downloadError', { ns: 'notifications' }));
    }
  };

  const handleAdd = async ({
    instance,
    version,
    extensionType,
    path,
    ...data
  }) => {
    const instanceId = instance.id;
    const requestPath = `/var/${path}`;
    const params = {
      instanceSetId: instanceId,
      instanceSubscriptNumber: DEFAULT_SUBSCRIPT_NUMBER,
      path: requestPath,
    };

    try {
      if (extensionType === EXTENSION_TYPES.SOLR_DICTIONARY) {
        params.collection = data.collectionName;
        await extensionApis.downloadExtension(
          projectId,
          version.extensionId,
          version.id,
          params
        );
      }

      if (extensionType === EXTENSION_TYPES.SOLR_PLUGIN) {
        if (isSolrCloud(instance.type)) {
          const downloadRequests = data.instanceNodes.map((node) => {
            params.instanceSubscriptNumber = node.subscriptNumber;
            params.collection = data.collectionName;
            return extensionApis.downloadExtension(
              projectId,
              version.extensionId,
              version.id,
              params
            );
          });

          await requestAll(downloadRequests);
        } else {
          await extensionApis.downloadExtension(
            projectId,
            version.extensionId,
            version.id,
            params
          );
        }
      }

      await extensionApis.increaseDownloadCount(version.extensionId);

      showToast(
        TOAST_TYPE.SUCCESS,
        t('downloadToInstanceSuccess', {
          ns: 'notifications',
          text: instance.name,
        })
      );

      setReminder(true);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('downloadToInstanceError', {
          ns: 'notifications',
          text: instance.name,
        })
      );
    }
  };

  const handleDelete = async (extension) => {
    try {
      const subscription = find(subExtensions, { extensionId: extension.id });
      await projectApis.unsubscribeExtension(projectId, subscription.id);
      await getSubExtensions();
      showToast(
        TOAST_TYPE.SUCCESS,
        t('deleteFormProjectSuccess', {
          ns: 'notifications',
          text: extension.name,
        })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('deleteFormProjectError', {
          ns: 'notifications',
          text: extension.name,
        })
      );
    }
  };

  const getInstances = async () => {
    const { data } = await solrApis.getInstances(projectId);
    return data.content.filter((instance) => isInstanceRunning(instance));
  };

  const getInstanceNodes = async (instanceId) => {
    const { data: response } = await solrApis.getInstance(
      projectId,
      instanceId
    );
    const instanceNodeList = response.instances.map((item) => ({
      ...item,
      name: `${response.name}-${item.subscriptNumber}`,
    }));
    return instanceNodeList;
  };

  const getCollections = async (instance) => {
    if (!instance) return;
    if (isSolrCloud(instance.type)) {
      const { data } = await collectionApis.getSolrCloudCollections(
        projectId,
        instance.id
      );
      return data.collections;
    } else {
      const { data } = await collectionApis.getCollections(
        projectId,
        instance.id
      );
      return Object.keys(data.status);
    }
  };

  const fetchData = async () => {
    try {
      const { data: project } = await projectApis.getProject(projectId);
      setProject(project);
    } catch (error) {
      handleErrors(history, error, showToast);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [projectId]);

  return (
    <Container>
      <Breadcrumbs projectName={project?.name} />
      <TitlePanel title={t('subExtensions', { ns: 'titles' })} />
      {reminder && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="info">
              {t('reloadReminder', { ns: 'descriptions' })}
            </Alert>
          </Grid>
        </Grid>
      )}
      <ExtensionList
        getExtensions={getExtensions}
        handleDelete={handleDelete}
        handleDownload={handleDownload}
        handleAdd={handleAdd}
        getInstances={getInstances}
        getInstanceNodes={getInstanceNodes}
        getCollections={getCollections}
        getConfPath={getConfPath}
        getLibPath={getLibPath}
      />
    </Container>
  );
};

export default ProjectSubsExtension;
