export const DISMAX_FIELDS = [
  'alt',
  'qf',
  'mm',
  'pf',
  'ps',
  'qs',
  'tie',
  'bq',
  'bf',
];

export const EDISMAX_FIELDS = ['uf', 'pf2', 'pf3', 'ps2', 'ps3', 'boost'];

export const Q_FIELDS = ['op', 'alt'];

export const HIGHLIGHT_FIELDS = [
  'fl',
  'requireFieldMatch',
  'usePhraseHighLighter',
  'highlightMultiTerm',
];

export const SUGGEST_FIELDS = ['dictionary', 'qt', 'collection'];

export const DEFAULT_SEARCH_QUERY = '*:*';

export const WEB_APP_NAME = 'ks-search-client.zip';
