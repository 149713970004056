import { useState, useEffect } from 'react';

import { Container } from '@material-ui/core';
import FileSaver from 'file-saver';
import { last } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';

import { collectionApis } from '../../../apis/collectionApis';
import { projectApis } from '../../../apis/projectApis';
import Loading from '../../../components/elements/loading/Loading';
import { MIME_TYPE } from '../../../components/page-sections/file-browser/constants';
import InstanceCollectionSection from '../../../components/page-sections/instance-collection/InstanceCollectionSection';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { useSolrInstance } from '../../../contexts/SolrContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import {
  isSolrCloud,
  mapBlobFormData,
  sentenceCase,
} from '../../../utils/utils';

const checkSolrResponse = async (data) => {
  if (parseInt(data?.responseHeader?.status) !== 0)
    throw Error(last(data?.error?.metadata));
};

const InstanceCollectionsPage = () => {
  const { t } = useTranslation();
  const { projectId, instanceId } = useParams();
  const history = useHistory();
  const { instance } = useSolrInstance();
  const { showToast } = useToast();

  const [project, setProject] = useState(null);
  const [collections, setCollections] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSolrProcessing, setIsSolrProcessing] = useState(false);

  const updateCollections = async () => {
    if (isSolrCloud(instance.type)) {
      const {
        data: {
          cluster: { collections: collectionRes },
        },
      } = await collectionApis.getClusterStatus(projectId, instanceId);
      const formatData = Object.keys(collectionRes).map((key) => ({
        name: key,
        ...collectionRes[key],
      }));
      const collectionData = formatData.map((collection) => {
        return {
          name: collection.name,
          replicationFactor: collection.replicationFactor,
          shards: Object.keys(collection.shards).length,
        };
      });
      setCollections(collectionData);
    } else {
      const {
        data: { status: collectionRes },
      } = await collectionApis.getCollections(projectId, instanceId);
      const formatData = Object.keys(collectionRes);
      const collectionData = formatData.map((collection) => {
        return {
          name: collection,
        };
      });
      setCollections(collectionData);
    }
  };

  const fetchData = async () => {
    try {
      const { data: project } = await projectApis.getProject(projectId);
      setProject(project);

      await updateCollections();
      setIsLoading(false);
    } catch (error) {
      handleErrors(history, error, showToast);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!instance) return;
    fetchData();
    // eslint-disable-next-line
  }, [projectId, instanceId, instance]);

  const handleAdd = async (data) => {
    try {
      setIsSolrProcessing(true);
      const formData = mapBlobFormData(data, 'info', {
        formKey: 'file',
        requestKey: 'file',
      });

      await collectionApis.createCollection(projectId, instanceId, formData);
      await updateCollections();

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('createSuccess', {
            ns: 'notifications',
            text: t('collection', { ns: 'instance' }),
          })
        )
      );
      setIsSolrProcessing(false);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('createError', {
            ns: 'notifications',
            text: t('collection', { ns: 'instance' }),
          })
        )
      );
      setIsSolrProcessing(false);
    }
  };

  const handleReload = async (collectionName) => {
    try {
      setIsSolrProcessing(true);
      await collectionApis.reloadCollection(
        projectId,
        instanceId,
        collectionName
      );

      showToast(
        TOAST_TYPE.SUCCESS,
        t('reloadSuccess', { ns: 'notifications' })
      );
      setIsSolrProcessing(false);
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('reloadError', { ns: 'notifications' }));
      setIsSolrProcessing(false);
    }
  };

  const handleUpdate = (collectionName, otherCollectionName) => {
    setIsSolrProcessing(true);

    collectionApis
      .renameCollection(
        projectId,
        instanceId,
        collectionName,
        otherCollectionName
      )
      .then((response) => checkSolrResponse(response.data))
      .then(() => updateCollections())
      .then(() =>
        showToast(
          TOAST_TYPE.SUCCESS,
          sentenceCase(
            t('updateSuccess', {
              ns: 'notifications',
              text: t('collectionName', { ns: 'fields' }),
            })
          )
        )
      )
      .catch((e) => {
        showToast(
          TOAST_TYPE.ERROR,
          `${sentenceCase(
            t('updateError', {
              ns: 'notifications',
              text: t('collectionName', { ns: 'fields' }),
            })
          )} ${e.message}`
        );
      })
      .then(() => {
        setIsSolrProcessing(false);
      });
  };

  const handleDelete = async (collectionName, options) => {
    setIsSolrProcessing(true);
    try {
      await collectionApis.deleteCollection(
        projectId,
        instanceId,
        collectionName,
        options
      );
      await updateCollections();
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('collection', { ns: 'instance' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        `${sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('collection', { ns: 'instance' }),
          })
        )} ${error.message}`
      );
    }
    setIsSolrProcessing(false);
  };

  const handleImport = async (collectionName, options, file) => {
    setIsSolrProcessing(true);
    try {
      const data = new FormData();
      data.append('file', new Blob(file), { type: MIME_TYPE.zip });
      await collectionApis.importCollection(
        projectId,
        instanceId,
        collectionName,
        options,
        data
      );
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('importSuccess', {
            ns: 'notifications',
            text: t('collection', { ns: 'instance' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        `${sentenceCase(
          t('importError', {
            ns: 'notifications',
            text: t('collection', { ns: 'instance' }),
          })
        )} ${error.message}`
      );
    }
    setIsSolrProcessing(false);
  };

  const handleExport = async (collectionName) => {
    setIsSolrProcessing(true);
    try {
      const exportRes = await collectionApis.exportCollection(
        projectId,
        instanceId,
        collectionName
      );
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('exportSuccess', {
            ns: 'notifications',
            text: t('collection', { ns: 'instance' }),
          })
        )
      );
      const blob = new Blob([exportRes.data], {
        type: 'application/zip',
      });
      FileSaver.saveAs(blob, collectionName);
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        `${sentenceCase(
          t('exportError', {
            ns: 'notifications',
            text: t('collection', { ns: 'instance' }),
          })
        )} ${error.message}`
      );
    }
    setIsSolrProcessing(false);
  };

  const handleDeleteDocs = async (collectionName) => {
    try {
      await collectionApis.deleteAllDocs(projectId, instanceId, collectionName);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('deleteSuccess', {
          ns: 'notifications',
          text: t('collectionDocs', { ns: 'instance' }),
        })
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        t('deleteError', {
          ns: 'notifications',
          text: t('collectionDocs', { ns: 'instance' }),
        })
      );
    }
  };

  return (
    <Container>
      {isLoading && <Loading />}
      {!isLoading && collections && (
        <>
          <Breadcrumbs
            projectName={project?.name}
            instanceName={instance?.name}
          />
          <TitlePanel title={t('collections', { ns: 'titles' })} />
          <InstanceCollectionSection
            collections={collections}
            instance={instance}
            onAdd={handleAdd}
            onReload={handleReload}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            onImport={handleImport}
            onExport={handleExport}
            onDeleteDocs={handleDeleteDocs}
            isLoading={isSolrProcessing}
          />
        </>
      )}
    </Container>
  );
};

export default InstanceCollectionsPage;
