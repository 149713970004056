import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../elements/dropdown/Dropdown';

const ActionDropdown = ({
  anchorEl,
  isFile,
  isFailed,
  handleClose,
  showRestore,
  showDownload,
  showDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Dropdown anchorEl={anchorEl} onClose={handleClose}>
      {!isFailed && (
        <section>
          <MenuItem aria-label="restore" onClick={showRestore}>
            {t('restore', { ns: 'titles' })}
          </MenuItem>
          {isFile && (
            <MenuItem aria-label="download" onClick={showDownload}>
              {t('download', { ns: 'buttons' })}
            </MenuItem>
          )}
        </section>
      )}
      <MenuItem aria-label="delete" onClick={showDelete}>
        {t('delete', { ns: 'buttons' })}
      </MenuItem>
    </Dropdown>
  );
};

ActionDropdown.propTypes = {
  anchorEl: PropTypes.object,
  isFile: PropTypes.bool,
  isFailed: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  showRestore: PropTypes.func.isRequired,
  showDownload: PropTypes.func.isRequired,
  showDelete: PropTypes.func.isRequired,
};

export default ActionDropdown;
