import { useState, createContext, useContext, useEffect } from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { isInstanceRunning } from '../apis/constants';
import { solrApis } from '../apis/solrApis';
import useSolrInstanceInterval from '../hooks/useSolrInstanceInterval';
import useToast, { TOAST_TYPE } from '../hooks/useToast';
import { mapSolrStatusToInstance } from '../utils/utils';

// Constants
const SOLR_INSTANCE_CONTEXT_ERROR =
  'SolrContext must be used within an Solr Instance Provider';

const SolrContext = createContext();

const SolrInstanceProvider = ({ children }) => {
  const { projectId, instanceId } = useParams();
  const { showToast } = useToast();

  const [solrInstance, setSolrInstance] = useState(null);
  const [forceStop, setForceStop] = useState(false);

  const getSolrStatus = async (solr = solrInstance) => {
    try {
      if (!isInstanceRunning(solr)) throw Error();
      const { data: solrStatus } = await solrApis.getSolrStatus(
        projectId,
        instanceId
      );

      const solrWithStatus = mapSolrStatusToInstance(solr, solrStatus);

      return solrWithStatus;
    } catch (error) {
      return solr;
    }
  };

  const getInstance = async () => {
    try {
      const { data: instance } = await solrApis.getInstance(
        projectId,
        instanceId
      );

      const instanceWithSolr = await getSolrStatus(instance);
      setSolrInstance(instanceWithSolr);
    } catch (error) {
      setForceStop(true);
      showToast(TOAST_TYPE.ERROR, i18next.t('instanceError', { ns: 'errors' }));
    }
  };

  const refreshSolrStatus = async () => {
    try {
      const instanceWithSolr = await getSolrStatus();
      setSolrInstance(instanceWithSolr);
    } catch (error) {
      setForceStop(true);
      showToast(TOAST_TYPE.ERROR, i18next.t('solrError', { ns: 'errors' }));
    }
  };

  useSolrInstanceInterval(
    async () => await getInstance(),
    solrInstance,
    20000,
    forceStop
  );

  useEffect(() => {
    instanceId && getInstance();

    // eslint-disable-next-line
  }, [instanceId]);

  return (
    <SolrContext.Provider
      value={{
        instance: solrInstance,
        refreshSolrStatus,
        refreshInstance: getInstance,
      }}
    >
      {children}
    </SolrContext.Provider>
  );
};

export const useSolrInstance = () => {
  const context = useContext(SolrContext);
  if (context === undefined) {
    throw new Error(SOLR_INSTANCE_CONTEXT_ERROR);
  }
  return context;
};

SolrInstanceProvider.propTypes = {
  children: PropTypes.node,
};

export default SolrInstanceProvider;
