import { ChonkyIconName, defineFileAction, ChonkyActions } from 'chonky';

const editableType = ['txt', 'xml', 'sh', 'properties', 'json', 'conf', 'js'];

const edit = defineFileAction({
  id: 'edit',
  requiresSelection: true,
  clickType: 'double',
  fileFilter: (file) => {
    return (
      file &&
      !file.isDir &&
      (editableType.includes(file?.ext) || file.name === 'managed-schema')
    );
  },

  button: {
    name: 'Edit',
    toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.archive,
  },
});

const rename = defineFileAction({
  id: 'rename',
  requiresSelection: true,
  button: {
    name: 'Rename',
    toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.terminal,
  },
});

const download = defineFileAction({
  id: 'download',
  requiresSelection: true,
  fileFilter: (file) => file,
  button: {
    name: 'Download',
    toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.download,
  },
});

const remove = defineFileAction({
  id: 'remove',
  requiresSelection: true,

  button: {
    name: 'Delete',
    toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.trash,
  },
});

export const fileActions = [
  ChonkyActions.CreateFolder,
  ChonkyActions.UploadFiles,
  edit,
  download,
  rename,
  remove,
];
