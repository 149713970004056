import i18next from 'i18next';
import moment from 'moment-timezone';

import { DEFAULT_TIMEZONE, TAX_RATE } from '../../constants';

export const getPaymentPeriod = () => {
  const today = moment
    .tz(DEFAULT_TIMEZONE)
    .format(i18next.t('format.monthAndDay', { ns: 'general' }));
  const lastDayOfMonth = moment
    .tz(DEFAULT_TIMEZONE)
    .endOf('month')
    .format(i18next.t('format.monthAndDay', { ns: 'general' }));
  return `${today}〜${lastDayOfMonth}`;
};

export const getDaysCharged = () => {
  const now = moment.tz(DEFAULT_TIMEZONE);
  return moment.tz(DEFAULT_TIMEZONE).endOf('month').diff(now, 'days') + 1;
};

export const daysInMonth = () => moment.tz(DEFAULT_TIMEZONE).daysInMonth();

export const getNewPaymentDetails = (selectedPlan) => {
  const lastDayInMonth = daysInMonth();
  const daysUntilEnd = getDaysCharged();

  // calculate single instance price
  const instancePriceSum = Number(selectedPlan.price);

  // calculate prorate + rounding
  const chargingProportion = Math.round(
    (instancePriceSum / lastDayInMonth) * daysUntilEnd
  );

  // multiple by instances count
  const priceBeforeTax = chargingProportion;
  const tax = Math.floor(priceBeforeTax * TAX_RATE);
  const priceAfterTax = priceBeforeTax + tax;

  return { priceBeforeTax, tax, priceAfterTax };
};

export const getNewFullAmount = (selectedPlan) => {
  const priceBeforeTax = Number(selectedPlan.price);
  const tax = Math.floor(priceBeforeTax * TAX_RATE);
  const priceAfterTax = priceBeforeTax + tax;
  return priceAfterTax;
};
