import { useState } from 'react';

import { Button, IconButton, Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';
import DOMPurify from 'dompurify';
import { isEmpty, truncate } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useSearch } from '../../../contexts/SearchContext';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { sentenceCase } from '../../../utils/utils';
import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';
import FieldTable from './FieldTable';
import styles from './ResultCard.module.scss';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 0,
    fontSize: theme.typography.subtitle2.fontSize,
    textTransform: 'initial',
    justifyContent: 'flex-start',
    minWidth: 'initial',
    cursor: 'help',
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  tooltip: {
    backgroundColor: theme.palette.grey[100],
    minWidth: 200,
    maxWidth: 500,
    padding: 0,
  },
  arrow: {
    color: theme.palette.grey[100],
  },
  block: {
    display: 'block',
  },
  image: {
    height: 'auto',
    margin: '0.5em auto',
    maxHeight: '300px',
  },
  score: {
    fontSize: '0.9rem',
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    color: theme.palette.primary.main,
  },
  uniqueKey: {},
  positionRight: {
    position: 'absolute',
    right: 0,
  },
}));

const formatToString = (content, separator) => {
  if (Array.isArray(content)) return content.join(separator);
  return content;
};

const isValidUrl = (urlString) => {
  let url;
  try {
    url = new URL(urlString);
  } catch {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const ResultCard = ({ doc }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { query, deleteDocument } = useSearch();
  const {
    display: { title, body, url, image },
    uniqueKey,
  } = query;

  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState(
    t('copyId', { ns: 'tooltips' })
  );

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const handleIdClick = () => {
    navigator.clipboard.writeText(doc[uniqueKey]);
    setCopied(true);
    setTooltipMessage(t('copied', { ns: 'tooltips' }));
  };

  const handleDeleteDoc = async () => {
    try {
      await deleteDocument(doc.id);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('doc', { ns: 'instance' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('doc', { ns: 'instance' }),
          })
        )
      );
    }
  };

  const html = (content) => (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content, {
          ALLOWED_TAGS: ['em', 'b'],
        }),
      }}
    />
  );

  return (
    <>
      <Card
        variant="outlined"
        style={{ position: 'relative' }}
        className={styles.card}
      >
        <div className={classes.positionRight}>
          <Box display="flex" justifyContent="flex-end">
            {doc.score && (
              <Box className={classes.score}>
                {`${t('score', { ns: 'instance' })}: ${doc.score}`}
              </Box>
            )}
            <IconButton
              size="small"
              aria-label="delete"
              title={t('deleteDoc', { ns: 'buttons' })}
              onClick={openModal}
            >
              <DeleteForeverIcon color="error" />
            </IconButton>
          </Box>
        </div>

        <Box
          my={doc.score ? 2.5 : 2}
          mx={2}
          pt={1}
          display="flex"
          flexDirection="column"
        >
          <Box>
            <Tooltip
              interactive
              arrow
              placement="bottom"
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
              }}
              className={classes.tooltip}
              title={<FieldTable docKey={doc[uniqueKey]} />}
              enterDelay={400}
            >
              <Box
                display="inline"
                style={{
                  backgroundColor: 'transparent',
                  verticalAlign: 'middle',
                }}
              >
                <Text caption style={{ cursor: 'help', paddingTop: 3 }}>
                  {doc[uniqueKey].length > 50
                    ? html(`${doc[uniqueKey].substring(0, 50)}...`)
                    : html(doc[uniqueKey])}
                </Text>
              </Box>
            </Tooltip>

            <Tooltip title={tooltipMessage} placement="right">
              <Button
                variant="outlined"
                style={{ minWidth: 0, padding: '5px', marginLeft: '0.5em' }}
                onClick={handleIdClick}
              >
                {copied ? (
                  <DoneIcon fontSize="small" style={{ color: green[500] }} />
                ) : (
                  <AssignmentIcon fontSize="small" color="disabled" />
                )}
              </Button>
            </Tooltip>
          </Box>

          {doc[image] && (
            <img className={classes.image} src={doc[image]} alt={doc[image]} />
          )}

          <Text component="div" bodyBold gutterBottom>
            {doc[title] &&
              (!isEmpty(doc.highlighting?.[title])
                ? html(formatToString(doc.highlighting[title], '\n'))
                : html(formatToString(doc[title], '\n')))}
          </Text>

          <Text component="div" style={{ whiteSpace: 'pre-wrap' }}>
            {doc[body] &&
              (!isEmpty(doc.highlighting?.[body])
                ? html(formatToString(doc.highlighting[body], '\n...'))
                : html(
                    truncate(formatToString(doc[body], '\n'), { length: 100 })
                  ))}
          </Text>

          {doc[url] && isValidUrl(doc[url]) && (
            <Text caption style={{ paddingTop: '1em' }}>
              <Link href={doc[url]} target="_blank">
                {doc[url]}
              </Link>
            </Text>
          )}
        </Box>
      </Card>
      <Modal
        openModal={open}
        title={t('deletion', {
          ns: 'project',
          text: t('doc', { ns: 'instance' }),
        })}
        handleConfirm={handleDeleteDoc}
        handleClose={closeModal}
        showActions
      >
        <Text>
          {sentenceCase(
            t('deleteAction', {
              ns: 'descriptions',
              text: t('doc', { ns: 'instance' }),
            })
          )}
        </Text>
        <Text>{t('delete', { ns: 'descriptions' })}</Text>
      </Modal>
    </>
  );
};

ResultCard.propTypes = {
  doc: PropTypes.object,
};

export default ResultCard;
