import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../contexts/UserContext';
import { formatDateTimeUTC } from '../../../utils/timeUtils';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import NoContent from '../../sections/no-content/NoContent';

const useStyles = makeStyles((theme) => ({
  status: {
    fontWeight: 'bold',
    marginRight: '2em',
  },
  statusOk: {
    color: theme.palette.success.main,
  },
  statusError: {
    color: theme.palette.error.main,
  },
  noStatus: {
    color: 'black',
  },
  oldStatus: {
    color: theme.palette.grey[500],
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  statusButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '1em',
    },
  },
  statusDate: {
    marginRight: '2em',
  },
  statusIcon: {
    fontSize: '1em',
    marginLeft: '1em',
  },
}));

const InstanceStatus = ({ monitor, status, onStatusCheck }) => {
  const classes = useStyles();
  const user = useUser();
  const { t } = useTranslation();

  const itemStatus = (status) =>
    monitor
      ? status === 'OK'
        ? classes.statusOk
        : classes.statusError
      : classes.oldStatus;

  const handleStatusCheck = () => onStatusCheck();

  return (
    <>
      <Box className={classes.statusContainer}>
        <Box mr={2}>{t('monitorStatus', { ns: 'instance' })}</Box>
        {status ? (
          <>
            <span className={`${classes.status} ${itemStatus(status.status)}`}>
              {status?.status}
            </span>
            <Box className={classes.statusDate}>
              (
              {t('updated', {
                ns: 'instance',
                dateTime: formatDateTimeUTC(status.checkDate, user.timeZone),
              })}
              )
            </Box>
          </>
        ) : (
          <>
            <span className={`${classes.status} noStatus`}>NO STATUS</span>
          </>
        )}

        <Button
          className={classes.statusButton}
          onClick={handleStatusCheck}
          disabled={!status}
        >
          {t('checkNow', { ns: 'buttons' })}
        </Button>
      </Box>

      <Box mt={1}>
        {status ? (
          status.nodes.map((node, i) => (
            <Accordion key={i}>
              <AccordionSummary
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {t('monitorNode', { ns: 'instance', node: node.name })}
                <FiberManualRecordIcon
                  className={`${classes.statusIcon} ${itemStatus(node.status)}`}
                />
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                {node.status === 'ERROR' && (
                  <Text bodyBold>{node.message}</Text>
                )}
                {node.collections.map((collection, i) => (
                  <Accordion key={i} style={{ width: '100%' }}>
                    <AccordionSummary
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {t('monitorCollection', {
                        ns: 'instance',
                        collection: collection.name,
                      })}
                      <FiberManualRecordIcon
                        className={`${classes.statusIcon} ${itemStatus(
                          collection.status
                        )}`}
                      />
                    </AccordionSummary>
                    <AccordionDetails>{collection.message}</AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <NoContent title={t('noHealthCheckFound', { ns: 'descriptions' })} />
        )}
      </Box>
    </>
  );
};

InstanceStatus.propTypes = {
  monitor: PropTypes.bool,
  status: PropTypes.object,
  onStatusCheck: PropTypes.func.isRequired,
};

export default InstanceStatus;
