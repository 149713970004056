import { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import { saveAs } from 'file-saver';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { collectionApis } from '../../../apis/collectionApis';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import SplitPane from '../../../components/elements/split-pane/SplitPane';
import SearchForm from '../../../components/page-sections/search-form/SearchForm';
import SearchResults from '../../../components/page-sections/search-results/SearchResults';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { WEB_APP_NAME } from '../../../constants';
import SearchProvider from '../../../contexts/SearchContext';
import { useSolrInstance } from '../../../contexts/SolrContext';
import { handleErrors } from '../../../helpers';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { isSolrCloud, getSolrSubdomain } from '../../../utils/utils';

const InstanceSearchPage = () => {
  const { t } = useTranslation();
  const { projectId, instanceId } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const { instance } = useSolrInstance();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [collections, setCollections] = useState([]);
  const [queryFromUrl, setQueryFromUrl] = useState(null);
  const [baseUrl, setBaseUrl] = useState('');

  const getCollections = async (instance) => {
    if (isSolrCloud(instance.type)) {
      const { data } = await collectionApis.getSolrCloudCollections(
        projectId,
        instance.id
      );
      return data.collections;
    } else {
      const { data } = await collectionApis.getCollections(
        projectId,
        instance.id
      );
      return Object.keys(data.status);
    }
  };

  const fetchData = async () => {
    try {
      const { data: projectRes } = await projectApis.getProject(projectId);
      setProject(projectRes);

      const collectionRes = await getCollections(instance);
      const searchId = new URLSearchParams(search).get('query');
      const query = await getQueryFromUrl(searchId);

      const baseUrl = getSolrSubdomain(
        instance.subdomain,
        instance.type,
        instance.category,
        {
          isCluster: true,
        }
      );

      setQueryFromUrl(query);
      setCollections(collectionRes);
      setBaseUrl(baseUrl);
    } catch (error) {
      handleErrors(error, history, showToast);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!instance) return;
    fetchData();
    // eslint-disable-next-line
  }, [projectId, instanceId, instance]);

  const getQueryFromUrl = async (searchId) => {
    if (!searchId) return;

    try {
      const { data: queryRes } = await solrApis.getQuery(
        projectId,
        instanceId,
        searchId
      );
      return queryRes;
    } catch {
      showToast(TOAST_TYPE.ERROR, t('loadQueryError', { ns: 'notifications' }));
      return null;
    }
  };

  const getQueryList = async () => {
    try {
      const { data: queryListRes } = await solrApis.getQueries(
        projectId,
        instanceId
      );
      const sortedQueryList = sortBy(queryListRes, 'createdAt').reverse();
      return sortedQueryList;
    } catch {
      showToast(
        TOAST_TYPE.ERROR,
        t('getQueriesError', { ns: 'notifications' })
      );
    }
  };

  const handleDeleteQuery = async (queryId) => {
    try {
      await solrApis.deleteQuery(projectId, instanceId, queryId);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('deleteSuccess', {
          ns: 'notifications',
          text: t('search.query', { ns: 'fields' }),
        })
      );
    } catch {
      showToast(
        TOAST_TYPE.ERROR,
        t('deleteError', {
          ns: 'notifications',
          text: t('search.query', { ns: 'fields' }),
        })
      );
    }
  };

  const handleSaveQuery = async (data) => {
    try {
      await solrApis.createQuery(projectId, instanceId, data);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('saveQuerySuccess', { ns: 'notifications' })
      );
    } catch {
      showToast(TOAST_TYPE.ERROR, t('saveQueryError', { ns: 'notifications' }));
    }
  };

  const handleQuerySuggest = async (
    query,
    collection,
    requestHandler,
    dictionaries
  ) => {
    const payload = {
      requestHandler: requestHandler,
      collection: collection,
    };

    const params = new URLSearchParams();
    params.append('suggest', true);
    params.append('suggest.q', query);
    dictionaries.map((dictionary) =>
      params.append('suggest.dictionary', dictionary)
    );

    try {
      const { data: suggestRes } = await solrApis.getQuerySuggest(
        projectId,
        instanceId,
        payload,
        params
      );
      if (suggestRes.solrResponse.error || suggestRes.solrResponse.htmlError)
        throw suggestRes;
      return suggestRes;
    } catch (e) {
      if (e.solrResponse) {
        e.solrResponse.error &&
          showToast(TOAST_TYPE.ERROR, e.solrResponse.error.msg);
        e.solrResponse.htmlError &&
          showToast(
            TOAST_TYPE.ERROR,
            t('suggestionHandlerError', { ns: 'notifications' })
          );
      } else {
        showToast(
          TOAST_TYPE.ERROR,
          t('suggestionError', { ns: 'notifications' })
        );
      }
      return null;
    }
  };

  const handleDownloadWebApp = async (data) => {
    try {
      showToast(
        TOAST_TYPE.INFO,
        t('prepareForDownload', { ns: 'notifications' })
      );

      const webAppRes = await projectApis.downloadWebApp(
        projectId,
        instanceId,
        data
      );

      const blob = new Blob([webAppRes.data], {
        type: 'application/zip',
      });

      const fileName = webAppRes.headers['content-disposition']
        ? webAppRes.headers['content-disposition'].split('filename=')[1]
        : WEB_APP_NAME;

      const cleanedFileName = fileName.substring(1, fileName.length - 1);

      saveAs(blob, cleanedFileName);

      showToast(
        TOAST_TYPE.SUCCESS,
        t('webAppSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        showToast(
          TOAST_TYPE.ERROR,
          t('webAppTimeoutError', { ns: 'notifications' })
        );
      } else {
        showToast(TOAST_TYPE.ERROR, t('webAppError', { ns: 'notifications' }));
      }
    }
  };

  const fetchUniqueKey = async (collection) => {
    try {
      const { data: uniqueKey } = await collectionApis.getSchemaUniqueKey(
        projectId,
        instance.id,
        collection
      );
      return uniqueKey;
    } catch (error) {
      return '';
    }
  };

  return (
    <SearchProvider>
      <Container>
        {loading && <Loading />}
        {!loading && (
          <>
            <Breadcrumbs
              projectName={project.name}
              instanceName={instance.name}
            />
            <TitlePanel title={t('search', { ns: 'titles' })} />
            <SplitPane style={{ justifyContent: 'center' }}>
              <div>
                <SearchForm
                  onCollectionChange={fetchUniqueKey}
                  queryFromUrl={queryFromUrl}
                  collections={collections}
                  onSaveQuery={handleSaveQuery}
                  onDeleteQuery={handleDeleteQuery}
                  getQueryList={getQueryList}
                  onQuerySuggest={handleQuerySuggest}
                  onDownload={handleDownloadWebApp}
                  baseUrl={baseUrl}
                />
              </div>
              <div>
                <SearchResults />
              </div>
            </SplitPane>
          </>
        )}
      </Container>
    </SearchProvider>
  );
};

export default InstanceSearchPage;
