import { ThemeProvider } from '@material-ui/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Loading from './components/elements/loading/Loading';
import PrivateLayoutRoute from './components/layouts/PrivateLayoutRoute';
import NotFound from './pages/errors/404';
import ROUTES from './routes/page-routes';
import { PRIVATE_ROUTE } from './routes/routes';
import { keycloak, initOptions } from './security/keycloak';
import theme from './styles/theme';

const onTokenRefresh = async ({ token }) =>
  sessionStorage.setItem('user-token', token);

const handleOnEvent = async (event, error) => {
  if (event === 'onAuthSuccess')
    sessionStorage.setItem('user-token', keycloak.token);

  if (event === 'onTokenExpired') keycloak.updateToken(120);
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={initOptions}
        LoadingComponent={<Loading fullscreen />}
        onEvent={(event, error) => handleOnEvent(event, error)}
        onTokens={onTokenRefresh}
        autoRefreshToken={false}
      >
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to={PRIVATE_ROUTE.PROJECTS} />}
            />
            {ROUTES.private.map((route) => (
              <PrivateLayoutRoute key={route.path} {...route} />
            ))}
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </ReactKeycloakProvider>
    </ThemeProvider>
  );
}

export default App;
