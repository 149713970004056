import { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import Modal from '../../sections/modal/Modal';

const RemoveExtensionButton = ({ extension, onDelete }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const handleDelete = async () => {
    await onDelete(extension);
    toggleModal();
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="danger"
        onClick={toggleModal}
        aria-label="delete extension"
      >
        {t('delete', { ns: 'buttons' })}
      </Button>
      <Modal
        openModal={open}
        title={t('deletion', {
          ns: 'project',
          text: t('extension', { ns: 'project' }),
        })}
        handleClose={toggleModal}
        handleConfirm={handleDelete}
        showActions
      >
        <Text>
          {t('deleteExtensionFromProject', {
            ns: 'descriptions',
            text: extension.name,
          })}
        </Text>
      </Modal>
    </>
  );
};

RemoveExtensionButton.propTypes = {
  extension: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default RemoveExtensionButton;
