import { useState } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FlipIcon from '@material-ui/icons/Flip';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import Button from '../../elements/button/Button';
import Dropdown from '../../elements/dropdown/Dropdown';
import DeleteModal from './modals/DeleteModal';
import DeployModal from './modals/DeployModal';
import ExportModal from './modals/ExportModal';
import ImportModal from './modals/ImportModal';
import RenameModal from './modals/RenameModal';

const DictionaryActions = ({
  dictionary,
  isTableRow = false,
  handleRename,
  handleImport,
  handleExport,
  handleDeploy,
  handleDelete,
  ...deployFormFuncs
}) => {
  const history = useHistory();
  const { projectId } = useParams();
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);

  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [deployModalOpen, setDeployModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Dropdown menu
  const handleMenuClick = (e) => setAnchorEl(e.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // Open and close rename modal
  const openRenameModal = () => {
    handleMenuClose();
    setRenameModalOpen(true);
  };
  const closeRenameModal = () => setRenameModalOpen(false);

  // Open and close deploy modal
  const openDeployModal = () => {
    handleMenuClose();
    setDeployModalOpen(true);
  };
  const closeDeployModal = () => setDeployModalOpen(false);

  // Open and close import modal
  const openImportModal = () => {
    handleMenuClose();
    setImportModalOpen(true);
  };
  const closeImportModal = () => setImportModalOpen(false);

  // Open and close export modal
  const openExportModal = () => {
    handleMenuClose();
    setExportModalOpen(true);
  };
  const closeExportModal = () => setExportModalOpen(false);

  // Open and close delete modal
  const openDeleteModal = () => {
    handleMenuClose();
    setDeleteModalOpen(true);
  };
  const closeDeleteModal = () => setDeleteModalOpen(false);

  const handleView = () => {
    const synonymSetsPage = buildPath({
      path: PRIVATE_ROUTE.PROJECT_SYNONYM_DICTIONARY,
      params: { projectId, dictionaryId: dictionary.id },
    });

    history.push(synonymSetsPage);
  };

  const BUTTON_ACTIONS = [
    {
      label: 'view dictionary',
      text: t('synonym.viewDictionary', { ns: 'project' }),
      action: handleView,
    },
    {
      label: 'rename',
      text: t('synonym.renameDictionary', { ns: 'project' }),
      action: openRenameModal,
    },
    {
      label: 'deploy',
      text: t('synonym.deployDictionary', { ns: 'project' }),
      action: openDeployModal,
    },
  ];

  const DROPDOWN_ACTIONS = [
    {
      label: 'import',
      text: t('synonym.importToDictionary', { ns: 'project' }),
      action: openImportModal,
    },
    {
      label: 'export',
      text: t('synonym.exportDictionaryAs', { ns: 'project' }),
      action: openExportModal,
    },
    {
      label: 'delete',
      text: t('synonym.deleteDictionary', { ns: 'project' }),
      action: openDeleteModal,
    },
  ];

  const FULL_ACTIONS = [...BUTTON_ACTIONS.slice(1), ...DROPDOWN_ACTIONS];

  const displayDropdownMenu = isTableRow ? DROPDOWN_ACTIONS : FULL_ACTIONS;

  const divStyles = isTableRow
    ? {
        display: 'flex',
        justifyContent: 'flex-end',
      }
    : {};

  const getIcon = (label) => {
    switch (label) {
      case 'view dictionary':
        return <MenuBookIcon aria-label={label} />;
      case 'rename':
        return <FlipIcon aria-label={label} />;
      case 'deploy':
        return <CloudUploadIcon aria-label={label} />;
      default:
        return null;
    }
  };

  return (
    <Box style={divStyles}>
      {isTableRow && (
        <>
          {BUTTON_ACTIONS.map((item) => (
            <IconButton key={item.label} onClick={item.action}>
              <Tooltip title={item.text}>{getIcon(item.label)}</Tooltip>
            </IconButton>
          ))}

          <IconButton onClick={handleMenuClick}>
            <Tooltip title={t('synonym.moreActions', { ns: 'project' })}>
              <MoreVertIcon aria-label="more actions" />
            </Tooltip>
          </IconButton>
        </>
      )}

      {!isTableRow && (
        <Button
          onClick={handleMenuClick}
          variant="outlined"
          title={t('synonym.moreActions', { ns: 'project' })}
          aria-label="dictionary actions"
          style={{ height: isSmallScreen ? '100%' : '50%' }}
        >
          <SettingsOutlinedIcon />
          <ArrowDropDownOutlinedIcon />
        </Button>
      )}

      <Dropdown anchorEl={anchorEl} onClose={handleMenuClose}>
        {displayDropdownMenu.map((item, i) => (
          <MenuItem key={i} aria-label={item.label} onClick={item.action}>
            {item.text}
          </MenuItem>
        ))}
      </Dropdown>

      {/* Modals */}
      <RenameModal
        open={renameModalOpen}
        handleClose={closeRenameModal}
        dictionary={dictionary}
        handleRename={handleRename}
      />
      <DeployModal
        open={deployModalOpen}
        handleClose={closeDeployModal}
        dictionary={dictionary}
        handleDeploy={handleDeploy}
        {...deployFormFuncs}
      />
      <ImportModal
        open={importModalOpen}
        handleClose={closeImportModal}
        dictionary={dictionary}
        handleImport={handleImport}
      />
      <ExportModal
        open={exportModalOpen}
        handleClose={closeExportModal}
        dictionary={dictionary}
        handleExport={handleExport}
      />
      <DeleteModal
        open={deleteModalOpen}
        handleClose={closeDeleteModal}
        id={dictionary.id}
        handleDelete={handleDelete}
      />
    </Box>
  );
};

DictionaryActions.propTypes = {
  dictionary: PropTypes.object,
  isTableRow: PropTypes.bool,
  handleRename: PropTypes.func.isRequired,
  handleImport: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  handleDeploy: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DictionaryActions;
