import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import MuiList from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isLast, sentenceCase } from '../../../utils/utils';
import Text from '../../elements/text/Text';
import NoContent from '../../sections/no-content/NoContent';
import GpsListItem from './GpsListItem';
import InstanceListItem from './InstanceListItem';
import ModelListItem from './ModelListItem';
import ProjectListItem from './ProjectListItem';
import SolrNodeListItem from './SolrNodeListItem';

const useStyles = makeStyles((theme) => ({
  spaceRight: {
    marginRight: theme.spacing(1),
  },
  member: {
    display: 'flex',
  },
  background: {
    backgroundColor: theme.palette.grey[100],
  },
  led: {
    paddingLeft: theme.spacing(1),
    fontSize: '1rem',
  },
}));

const List = ({
  isProjectList,
  isInstanceList,
  isSolrNodeList,
  isGpsList,
  isModelList,
  instance,
  data,
  onStartNode,
  onStopNode,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const emptyProject = (
    <Box textAlign="center" py={5} className={classes.background}>
      <Box my={2}>
        <Text titleLarge>
          {sentenceCase(
            t('empty', {
              ns: 'descriptions',
              text: t('project', { ns: 'project' }),
            })
          )}
        </Text>
      </Box>
      <Text>{t('createProjectFirst', { ns: 'descriptions' })}</Text>
      <Text>{t('waitForInvitation', { ns: 'descriptions' })}</Text>
    </Box>
  );

  const emptyInstance = (
    <NoContent
      title={sentenceCase(
        t('empty', {
          ns: 'descriptions',
          text: t('instance', { ns: 'instance' }),
        })
      )}
    />
  );

  return (
    <MuiList>
      {data.length > 0 ? (
        data.map((item, i) => (
          <div key={i}>
            {isProjectList && <ProjectListItem project={item} />}
            {isInstanceList && <InstanceListItem instance={item} />}
            {isGpsList && <GpsListItem gps={item} />}
            {isModelList && <ModelListItem model={item} />}
            {isSolrNodeList && (
              <SolrNodeListItem
                instance={instance}
                solrNode={item}
                onStartNode={onStartNode}
                onStopNode={onStopNode}
              />
            )}
            {!isLast(data, i) && <Divider />}
          </div>
        ))
      ) : (
        <>
          {isProjectList && emptyProject}
          {isInstanceList && emptyInstance}
        </>
      )}
    </MuiList>
  );
};

List.propTypes = {
  isProjectList: PropTypes.bool,
  isInstanceList: PropTypes.bool,
  isSolrNodeList: PropTypes.bool,
  isGpsList: PropTypes.bool,
  isModelList: PropTypes.bool,
  instance: PropTypes.object,
  data: PropTypes.array.isRequired,
  onStartNode: PropTypes.func,
  onStopNode: PropTypes.func,
};

export default List;
