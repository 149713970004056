import AddIcon from '@material-ui/icons/Add';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import Button from '../../../elements/button/Button';

const NewCrawlerButton = ({ isAdmin, toggleModal }) => {
  return (
    isAdmin && (
      <Button
        onClick={toggleModal}
        aria-label="add-crawler-job"
        startIcon={<AddIcon />}
      >
        {t('addCrawlerJob', { ns: 'buttons' })}
      </Button>
    )
  );
};

NewCrawlerButton.propTypes = {
  isAdmin: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
};

export default NewCrawlerButton;
