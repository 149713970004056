import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

const SelectInput = ({
  controlled,
  control,
  name,
  register,
  label,
  options,
  labelKey,
  valueKey,
  errors,
  ...selectProps
}) => {
  return (
    <FormControl
      fullWidth
      size="small"
      variant="outlined"
      error={!!errors?.[name]}
    >
      <InputLabel>{label}</InputLabel>
      {controlled ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...selectProps}
              label={label}
              inputProps={{ 'aria-label': label }}
            >
              {options.map((item, i) => (
                <MenuItem key={i} value={item[valueKey]}>
                  {item[labelKey]}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      ) : (
        <Select
          {...register}
          {...selectProps}
          label={label}
          inputProps={{ 'aria-label': label }}
        >
          {options.map((item, i) => (
            <MenuItem key={i} value={item[valueKey]}>
              {item[labelKey]}
            </MenuItem>
          ))}
        </Select>
      )}
      <FormHelperText>{errors?.[name]?.message}</FormHelperText>
    </FormControl>
  );
};

SelectInput.propTypes = {
  controlled: PropTypes.bool,
  control: PropTypes.object,
  name: PropTypes.string,
  register: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  errors: PropTypes.object,
};

export default SelectInput;
