import {
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  Avatar,
  Box,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { AvatarGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Text from '../../elements/text/Text';
import { NOTIFICATION_TYPES } from './constants';
import DeleteNotificationButton from './DeleteNotificationButton';
import EditNotificationButton from './EditNotificationButton';

const NotificationTable = ({
  data,
  projectMembers,
  onTestSubmit,
  onEdit,
  onDelete,
  isAdmin,
}) => {
  const { t } = useTranslation();

  const findProjectMemberImage = (email) => {
    const foundMember = projectMembers.find((member) => member.email === email);
    return foundMember?.icon || email;
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('notificationType', { ns: 'instance' })}</TableCell>
            <TableCell align="center">
              {t('recipient', { ns: 'instance' })}
            </TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((notification) => (
            <TableRow key={notification.id}>
              <TableCell>{notification.type}</TableCell>
              <TableCell align="center">
                {notification.type === NOTIFICATION_TYPES.EMAIL ? (
                  <Box display="flex" justifyContent="center">
                    <AvatarGroup max={4}>
                      {notification.settings.emails.map((email, i) => (
                        <Avatar
                          key={i}
                          alt={email}
                          src={findProjectMemberImage(email)}
                        />
                      ))}
                    </AvatarGroup>
                    <Tooltip
                      aria-label="info"
                      title={notification.settings.emails.map((email, i) => (
                        <p key={i}>{email}</p>
                      ))}
                    >
                      <InfoOutlinedIcon style={{ fontSize: '1em' }} />
                    </Tooltip>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    mx="auto"
                    alignItems="center"
                    maxWidth={300}
                  >
                    <Text noWrap>{notification.settings.url}</Text>
                    <Tooltip
                      aria-label="info"
                      title={notification.settings.url}
                    >
                      <InfoOutlinedIcon
                        style={{ marginLeft: '1em', fontSize: '1em' }}
                      />
                    </Tooltip>
                  </Box>
                )}
              </TableCell>
              <TableCell align="center">
                {isAdmin && (
                  <>
                    <EditNotificationButton
                      notif={notification}
                      projectMembers={projectMembers}
                      onSendTest={onTestSubmit}
                      onEdit={onEdit}
                    />
                    <DeleteNotificationButton
                      notif={notification}
                      onDelete={onDelete}
                    />
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

NotificationTable.propTypes = {
  data: PropTypes.array,
  projectMembers: PropTypes.array.isRequired,
  onTestSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

export default NotificationTable;
