import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextInput from '../../../elements/text-input/TextInput';
import Text from '../../../elements/text/Text';

const CrawlStep3 = ({ control }) => {
  const { t } = useTranslation();

  const { errors } = useFormState({ control });

  return (
    <Box style={{ overflow: 'hidden' }}>
      <section>
        <Box mb={2}>
          <Text caption style={{ color: 'gray' }}>
            {t('asteriskRequired', { ns: 'fields' })}
          </Text>
        </Box>
        <Grid container spacing={1}>
          {['includeInCrawl', 'includeInIndex'].map((inputField) => (
            <Grid item xs={12} sm={6} key={inputField}>
              <Tooltip title={t(inputField, { ns: 'descriptions' })}>
                <Box>
                  <TextInput
                    name={inputField}
                    label={`${t(inputField, { ns: 'fields' })} *`}
                    control={control}
                    errors={errors}
                    size={7}
                  />
                </Box>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={1} style={{ marginTop: '0.5em' }}>
          {[
            'excludeFromCrawl',
            'excludeFromIndex',
            'excludeContentFromIndex',
          ].map((inputField) => (
            <Grid item xs={12} sm={4} key={inputField}>
              <Tooltip title={t(inputField, { ns: 'descriptions' })}>
                <Box>
                  <TextInput
                    name={inputField}
                    label={t(inputField, { ns: 'fields' })}
                    control={control}
                    errors={errors}
                    size={7}
                  />
                </Box>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </section>
    </Box>
  );
};

CrawlStep3.propTypes = {
  control: PropTypes.object.isRequired,
};

export default CrawlStep3;
