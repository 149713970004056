import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextDivider from '../../../elements/text-divider/TextDivider';

const SearchParamsFormSection = ({
  control,
  errors,
  filterQuery,
  fqChange,
  fqChips,
  onAddChip,
  onDeleteChip,
  onParserChange,
  style,
}) => {
  const { t } = useTranslation();

  const handleAddChip = (field) => onAddChip(field);
  const handleDeleteChip = (field, chipToDelete) =>
    onDeleteChip(field, chipToDelete);
  const chipHelper = (value, list, helper, errorHelper) => {
    return includes(list, value)
      ? t('duplicate', { ns: 'errors', item: t(errorHelper, { ns: 'fields' }) })
      : t(helper, { ns: 'descriptions' });
  };
  const handleParserChange = (event) => onParserChange(event);

  return (
    <>
      <TextDivider>
        {t('search.searchParameters', { ns: 'sectionTitles' })}
      </TextDivider>

      <Box className={style.inputContainer}>
        <Box className={style.input}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            error={!!errors?.settings?.query?.jsonQuery?.params?.op}
          >
            <InputLabel>
              {t('search.queryOperator', { ns: 'fields' })}
            </InputLabel>
            <Controller
              name="settings.query.jsonQuery.params.op"
              control={control}
              defaultValue="OR"
              render={({ field }) => (
                <Select
                  label={t('search.queryOperator', { ns: 'fields' })}
                  {...field}
                  inputProps={{ 'aria-label': 'query operator' }}
                >
                  <MenuItem value="AND">AND</MenuItem>
                  <MenuItem value="OR">OR</MenuItem>
                </Select>
              )}
            />
            <FormHelperText>
              {errors?.settings?.query?.jsonQuery?.params?.op?.message}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box className={style.input}>
          <Controller
            name="settings.query.jsonQuery.params.df"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t('search.df', { ns: 'fields' })}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ 'aria-label': 'default field' }}
                error={!!errors?.settings?.query?.jsonQuery?.params?.df}
                helperText={
                  errors?.settings?.query?.jsonQuery?.params?.df?.message
                }
              />
            )}
          />
        </Box>
        <Box className={style.inputFull}>
          <TextField
            label={t('search.filterQuery', { ns: 'fields' })}
            value={filterQuery}
            onChange={fqChange}
            fullWidth
            variant="outlined"
            size="small"
            error={includes(fqChips, filterQuery)}
            helperText={chipHelper(
              filterQuery,
              fqChips,
              'addFilterQuery',
              'search.filterQuery'
            )}
            inputProps={{ 'aria-label': 'filter query' }}
            InputProps={{
              style: {
                flexWrap: 'wrap',
                paddingRight: 50,
                paddingTop: fqChips.length > 0 ? '0.25em' : 0,
              },
              startAdornment: fqChips.map((item) => (
                <Tooltip title={item} key={item}>
                  <Chip
                    size="small"
                    label={item}
                    style={{
                      padding: '0.25em',
                      marginTop: '0.25em',
                      marginRight: '0.25em',
                      maxWidth: 200,
                    }}
                    onDelete={() => handleDeleteChip('fq', item)}
                  />
                </Tooltip>
              )),
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={() => handleAddChip('fq')}
                  style={{ position: 'absolute', right: 9 }}
                  data-testid="add"
                >
                  <AddIcon />
                </IconButton>
              ),
            }}
          />
        </Box>
        <Box className={style.input}>
          <Controller
            name="settings.query.jsonQuery.sort"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t('search.sort', { ns: 'fields' })}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ 'aria-label': 'sort' }}
                error={!!errors?.settings?.query?.jsonQuery?.sort}
                helperText={errors?.settings?.query?.jsonQuery?.sort?.message}
              />
            )}
          />
        </Box>
        <Box className={style.input}>
          <Controller
            name="settings.query.rawQueryParams"
            aria-label="raw query parameters"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t('search.rawQueryParams', { ns: 'fields' })}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ 'aria-label': 'raw query parameters' }}
                error={!!errors?.settings?.query?.rawQueryParams}
                helperText={errors?.settings?.query?.rawQueryParams?.message}
              />
            )}
          />
        </Box>
        <Box className={style.input}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            error={!!errors?.settings?.query?.jsonQuery?.params?.defType}
          >
            <InputLabel>{t('search.queryParser', { ns: 'fields' })}</InputLabel>
            <Controller
              name="settings.query.jsonQuery.params.defType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('search.queryParser', { ns: 'fields' })}
                  defaultValue="lucene"
                  inputProps={{ 'aria-label': 'query parser' }}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleParserChange(e);
                  }}
                >
                  <MenuItem value="lucene">lucene</MenuItem>
                  <MenuItem value="dismax">dismax</MenuItem>
                  <MenuItem value="edismax">edismax</MenuItem>
                </Select>
              )}
            />
            <FormHelperText>
              {errors?.settings?.query?.jsonQuery?.params?.defType?.message}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

SearchParamsFormSection.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  filterQuery: PropTypes.string.isRequired,
  fqChange: PropTypes.func.isRequired,
  fqChips: PropTypes.array.isRequired,
  onAddChip: PropTypes.func.isRequired,
  onDeleteChip: PropTypes.func.isRequired,
  onParserChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default SearchParamsFormSection;
