import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { formatBytes, sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import Title from '../../elements/title/Title';
import ImageUpload from '../../sections/image-upload/ImageUpload';
import { FILE_SIZE, SUPPORTED_FORMATS } from '../instance-stepper/constants';

const UserInfoForm = ({ user, onSubmit, handleRemoveImage }) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('firstName', { ns: 'fields' }),
          })
        )
      )
      .max(50, t('maxLength', { ns: 'validations', max: '50' })),
    lastName: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('lastName', { ns: 'fields' }),
          })
        )
      )
      .max(50, t('maxLength', { ns: 'validations', max: '50' })),
    email: yup
      .string()
      .email(
        sentenceCase(
          t('invalidFormat', {
            ns: 'validations',
            field: t('email', { ns: 'fields' }),
          })
        )
      )
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('email', { ns: 'fields' }),
          })
        )
      ),
    icon: yup
      .mixed()
      .test(
        'fileFormat',
        t('invalidImageFormat', { ns: 'validations' }),
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .test(
        'fileSize',
        t('invalidSize', {
          ns: 'validations',
          size: formatBytes(FILE_SIZE.BYTES, 0),
        }),
        (value) => !value || (value && value.size <= FILE_SIZE.BYTES)
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Title subTitle text={t('editUserInfo', { ns: 'sectionTitles' })} />
      <Container maxWidth="md">
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} md={5} style={{ alignSelf: 'center' }}>
              <ImageUpload
                name="icon"
                control={control}
                imageSrc={user?.icon}
                limit={FILE_SIZE.BYTES}
                onRemoveImage={handleRemoveImage}
              />
            </Grid>
            <Grid container spacing={2} item xs={12} md={7}>
              <Grid item xs={12}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={user?.firstName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('firstName', { ns: 'fields' })}
                      error={!!errors?.firstName}
                      helperText={errors?.firstName?.message}
                      inputProps={{ 'aria-label': 'firstName' }}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={user?.lastName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('lastName', { ns: 'fields' })}
                      error={!!errors?.lastName}
                      helperText={errors?.lastName?.message}
                      inputProps={{ 'aria-label': 'lastName' }}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={user?.email}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="email"
                      label={t('email', { ns: 'fields' })}
                      error={!!errors?.email}
                      helperText={
                        errors?.email?.message ||
                        t('emailUpdateWarning', { ns: 'descriptions' })
                      }
                      inputProps={{ 'aria-label': 'email' }}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center" mt={4} mb={2}>
                <Button
                  type="submit"
                  aria-label="update profile"
                  disabled={!isDirty || !isValid}
                >
                  {t('update', { ns: 'buttons' })}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

UserInfoForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func,
};

export default UserInfoForm;
