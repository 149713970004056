import { useKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';

import ServerProvider from '../../contexts/ServerContext';
import SolrInstanceProvider from '../../contexts/SolrContext';
import ToastProvider from '../../contexts/ToastContext';
import UserProvider from '../../contexts/UserContext';
import Layout from './Layout';

const PrivateLayoutRoute = ({
  component: Component,
  layoutProps = {},
  ...routeProps
}) => {
  const { i18n } = useTranslation();
  const { keycloak } = useKeycloak();

  const handleLogout = () => {
    keycloak.logout({
      redirectUri: process.env.REACT_APP_KANDASEARCH_HOME_PAGE,
    });
  };

  return (
    <Route
      {...routeProps}
      render={(props) =>
        keycloak?.authenticated ? (
          <ToastProvider>
            <UserProvider>
              <SolrInstanceProvider>
                <ServerProvider>
                  <Layout handleLogout={handleLogout} {...layoutProps}>
                    <Component {...props} />
                  </Layout>
                </ServerProvider>
              </SolrInstanceProvider>
            </UserProvider>
          </ToastProvider>
        ) : (
          keycloak.login({ locale: i18n.language })
        )
      }
    />
  );
};

PrivateLayoutRoute.propTypes = {
  component: PropTypes.elementType,
  layoutProps: PropTypes.object,
};

export default PrivateLayoutRoute;
