import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Text from '../../elements/text/Text';
import FileList from './FileList';

const FileSection = (props) => {
  const { t } = useTranslation('instance');

  return (
    <div>
      <Text bodyBold color="textSecondary">
        {t('selectedFile')}
      </Text>
      <List dense={true} aria-label="file list" disablePadding>
        <FileList {...props} />
      </List>
    </div>
  );
};

export default FileSection;

FileSection.propTypes = {
  files: PropTypes.array.isRequired,
  removeFile: PropTypes.func.isRequired,
};
