export const JOB_TYPE = {
  FREE: 'FREE',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
};

export const CRAWLER_STATUS = {
  CREATING: 'CREATING',
  WAITING: 'WAITING',
  RUNNING: 'RUNNING',
  DELETING: 'DELETING',
  ABORTING: 'ABORTING',
  UPDATING: 'UPDATING',
  FAILED_CREATING: 'FAILED_CREATING',
  FAILED_ABORTING: 'FAILED_ABORTING',
  FAILED_RUNNING: 'FAILED_RUNNING',
  FAILED_DELETING: 'FAILED_DELETING',
  FAILED_UPDATING: 'FAILED_UPDATING',
};

export const DEFAULT_HANDLER_VALUE = [
  '/update/extract',
  '/update',
  '/admin/ping',
];

export const CRAWLER_PERIOD_TYPES = {
  MANUAL: { value: 'NONE', label: 'frequency.none' },
  DAILY: { value: 'DAILY', label: 'frequency.daily' },
  WEEKLY: { value: 'WEEKLY', label: 'frequency.weekly' },
  MONTHLY: { value: 'MONTHLY', label: 'frequency.monthly' },
};

export const CRAWLER_TYPES = {
  FREE: {
    PRICE: 0,
    SCHEDULE: CRAWLER_PERIOD_TYPES.WEEKLY.value,
    SEEDS: 1,
  },
  SMALL: {
    PRICE: 20000,
    SCHEDULE: CRAWLER_PERIOD_TYPES.DAILY.value,
    SEEDS: 2,
  },
  MEDIUM: {
    PRICE: 80000,
    SCHEDULE: CRAWLER_PERIOD_TYPES.DAILY.value,
    SEEDS: -1,
  },
};

export const SUBSCRIPTION_TYPE = {
  FREE: 'FREE',
  CHARGE: 'CHARGE',
};

export const CRAWLER_DAYS = [
  { value: 1, label: 'days.monday', name: 'MONDAY' },
  { value: 2, label: 'days.tuesday', name: 'TUESDAY' },
  { value: 3, label: 'days.wednesday', name: 'WEDNESDAY' },
  { value: 4, label: 'days.thursday', name: 'THURSDAY' },
  { value: 5, label: 'days.friday', name: 'FRIDAY' },
  { value: 6, label: 'days.saturday', name: 'SATURDAY' },
  { value: 7, label: 'days.sunday', name: 'SUNDAY' },
];
