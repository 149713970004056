import { useState } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DeleteModal from './modals/DeleteModal';
import SynonymSetModal from './modals/SynonymSetModal';

const SynonymSetActions = ({ set, handleEdit, handleDelete }) => {
  const { t } = useTranslation();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Open and close edit modal
  const openEditModal = () => setEditModalOpen(true);
  const closeEditModal = () => setEditModalOpen(false);

  // Open and close delete modal
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={openEditModal}>
          <Tooltip title={t('edit', { ns: 'buttons' })}>
            <EditIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={openDeleteModal}>
          <Tooltip title={t('delete', { ns: 'buttons' })}>
            <DeleteForeverIcon color="error" />
          </Tooltip>
        </IconButton>
      </Box>

      {/* Modals */}
      <SynonymSetModal
        open={editModalOpen}
        handleClose={closeEditModal}
        handleEdit={handleEdit}
        set={set}
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={closeDeleteModal}
        id={set.id}
        handleDelete={handleDelete}
        isSynonymSet
      />
    </>
  );
};

SynonymSetActions.propTypes = {
  set: PropTypes.shape({
    id: PropTypes.string.isRequired,
    comment: PropTypes.string,
    items: PropTypes.array,
  }),
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default SynonymSetActions;
