import { instance, getToken } from './config';

const getInstances = (projectId, params = {}) =>
  instance.get(`/projects/${projectId}/solr-instance-sets`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { ...params, size: 1000 },
  });

const getInstance = (projectId, instanceId) =>
  instance.get(`/projects/${projectId}/instance-sets/${instanceId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const getSolrStatus = (projectId, instanceId) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/solr/status`,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const createCommunitySolrInstance = (projectId, payload) =>
  instance.post(
    `/projects/${projectId}/solr-instance-sets/community`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  );

const createStandardSolrInstance = (projectId, payload) =>
  instance.post(`/projects/${projectId}/solr-instance-sets/standard`, payload, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'multipart/form-data',
    },
  });

const updateInstance = (projectId, instanceId, payload) =>
  instance.put(
    `/projects/${projectId}/solr-instance-sets/${instanceId}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );

const deleteSolrInstance = (projectId, instanceId) => {
  instance.delete(`/projects/${projectId}/solr-instance-sets/${instanceId}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

const startSolr = (projectId, instanceId) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/solr/start`,
    null,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const stopSolr = (projectId, instanceId) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/solr/stop`,
    null,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const restartSolr = (projectId, instanceId) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/solr/restart`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      timeout: 120000,
    }
  );

const upconfig = (projectId, instanceId, collectionName, clear) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/collections/${collectionName}/upconfig`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: { clear },
    }
  );

const getQuery = (projectId, instanceId, queryId) =>
  instance.get(
    `/projects/${projectId}/instance-sets/${instanceId}/queries/${queryId}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const getQueries = (projectId, instanceId) =>
  instance.get(`/projects/${projectId}/instance-sets/${instanceId}/queries`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const createQuery = (projectId, instanceId, data) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/queries`,
    data,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const deleteQuery = (projectId, instanceId, queryId) =>
  instance.delete(
    `/projects/${projectId}/instance-sets/${instanceId}/queries/${queryId}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  );

const executeQuery = (projectId, instanceId, data) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/search`,
    data,
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );

const getQuerySuggest = (projectId, instanceId, payload, params) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/suggest`,
    payload,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: params,
    }
  );

const startSolrNode = (projectId, instanceId, subscriptNum) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/solr/start?instSubscript=${subscriptNum}`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      timeout: 30000,
    }
  );

const stopSolrNode = (projectId, instanceId, subscriptNum) =>
  instance.post(
    `/projects/${projectId}/instance-sets/${instanceId}/solr/stop?instSubscript=${subscriptNum}`,
    null,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
      timeout: 30000,
    }
  );

export const solrApis = {
  getInstances,
  getInstance,
  getSolrStatus,
  deleteSolrInstance,
  createStandardSolrInstance,
  createCommunitySolrInstance,
  startSolr,
  stopSolr,
  restartSolr,
  upconfig,
  getQuery,
  getQueries,
  createQuery,
  deleteQuery,
  executeQuery,
  getQuerySuggest,
  startSolrNode,
  stopSolrNode,
  updateInstance,
};
