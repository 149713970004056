import { useState } from 'react';

import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Modal from '../../sections/modal/Modal';
import { NOTIFICATION_TYPES } from './constants';
import EmailForm from './EmailForm';
import WebhookForm from './WebhookForm';

const EditNotificationButton = ({
  notif,
  projectMembers,
  onSendTest,
  onEdit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [modal, setModal] = useState(false);

  const handleOpenModal = () => setModal(true);
  const handleCloseModal = () => setModal(false);

  const handleEdit = (payload) => onEdit(notif.id, payload);

  return (
    <>
      {isSmallScreen ? (
        <IconButton onClick={handleOpenModal}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button
          color="light"
          style={{ marginRight: '1em' }}
          startIcon={<EditIcon />}
          onClick={handleOpenModal}
        >
          {t('edit', { ns: 'buttons' })}
        </Button>
      )}

      <Modal
        openModal={modal}
        maxWidth="md"
        title={'Edit Notification'}
        handleClose={handleCloseModal}
      >
        {notif?.type === NOTIFICATION_TYPES.EMAIL && (
          <EmailForm
            notif={notif}
            projectMembers={projectMembers}
            onEmailSubmit={handleEdit}
            onCancel={handleCloseModal}
            onSendTest={onSendTest}
          />
        )}
        {notif?.type === NOTIFICATION_TYPES.WEBHOOK && (
          <WebhookForm
            notif={notif}
            projectMembers={projectMembers}
            onWebhookSubmit={handleEdit}
            onCancel={handleCloseModal}
            onSendTest={onSendTest}
          />
        )}
      </Modal>
    </>
  );
};

EditNotificationButton.propTypes = {
  notif: PropTypes.object.isRequired,
  projectMembers: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSendTest: PropTypes.func.isRequired,
};

export default EditNotificationButton;
