import { useState, createContext, useContext, useEffect } from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { serverApis } from '../apis/serverApis';
import useInstanceInterval from '../hooks/useInstanceInterval';
import useToast, { TOAST_TYPE } from '../hooks/useToast';

// Constants
const SERVER_CONTEXT_ERROR = 'ServerContext must be used within a GPS Provider';

const ServerContext = createContext();

const ServerProvider = ({ children }) => {
  const { projectId, gpsId } = useParams();
  const { showToast } = useToast();

  const [serverData, setServerData] = useState(null);
  const [forceStop, setForceStop] = useState(false);

  const getServer = async () => {
    try {
      const { data: server } = await serverApis.getServer(projectId, gpsId);
      setServerData(server);
    } catch (error) {
      setForceStop(true);
      showToast(TOAST_TYPE.ERROR, i18next.t('serverError', { ns: 'errors' }));
    }
  };

  useInstanceInterval(
    async () => await getServer(),
    serverData,
    5000,
    forceStop
  );

  useEffect(() => {
    gpsId && getServer();

    // eslint-disable-next-line
  }, [gpsId]);

  return (
    <ServerContext.Provider
      value={{
        server: serverData,
        refreshServer: getServer,
      }}
    >
      {children}
    </ServerContext.Provider>
  );
};

export const useServer = () => {
  const context = useContext(ServerContext);
  if (context === undefined) {
    throw new Error(SERVER_CONTEXT_ERROR);
  }
  return context;
};

ServerProvider.propTypes = {
  children: PropTypes.node,
};

export default ServerProvider;
