import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { sentenceCase } from '../../../../utils/utils';
import Modal from '../../../sections/modal/Modal';
import ModalButtons from '../../../sections/modal/ModalButtons';

const RenameModal = ({ open, handleClose, dictionary, handleRename }) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: yup.string().required(
      sentenceCase(
        t('inputRequired', {
          ns: 'validations',
          field: t('synonym.dictionaryName', { ns: 'fields' }),
        })
      )
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const onRename = async (data) => {
    await handleRename(dictionary.id, data);
    handleClose();
  };

  return (
    <Modal
      openModal={open}
      title={t('synonym.editDictionaryName', { ns: 'sectionTitles' })}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onRename)}>
        <Controller
          name="name"
          control={control}
          defaultValue={dictionary.name}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              autoFocus
              autoComplete="off"
              error={!!errors?.name}
              helperText={errors?.name?.message}
              label={t('synonym.dictionaryName', { ns: 'fields' })}
              inputProps={{ 'aria-label': 'dictionary name' }}
            />
          )}
        />
        <ModalButtons
          onCancel={handleClose}
          cancelProps={{ type: 'reset' }}
          actionProps={{
            type: 'submit',
            disabled: !isValid,
            'aria-label': 'update',
          }}
          actionText={t('update', { ns: 'buttons' })}
        />
      </form>
    </Modal>
  );
};

RenameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dictionary: PropTypes.object,
  handleRename: PropTypes.func.isRequired,
};

export default RenameModal;
