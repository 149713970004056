import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SORT_TYPES } from '../../../constants';
import Button from '../../elements/button/Button';

const ExtensionSort = ({ loading, sort, onSort }) => {
  const { t } = useTranslation();
  const handleSort = (val) => onSort(val);

  return (
    <Box display="flex" justifyContent="flex-end" mb={1}>
      <ButtonGroup disableElevation color="primary" size="small">
        <Button
          aria-label="popular"
          variant={sort === SORT_TYPES.POPULAR ? 'contained' : 'outlined'}
          onClick={() => handleSort(SORT_TYPES.POPULAR)}
          disabled={loading}
        >
          {t('ext.mostPopular', { ns: 'project' })}
        </Button>
        <Button
          aria-label="recent"
          variant={sort === SORT_TYPES.RECENT ? 'contained' : 'outlined'}
          onClick={() => handleSort(SORT_TYPES.RECENT)}
          disabled={loading}
        >
          {t('ext.mostRecent', { ns: 'project' })}
        </Button>
      </ButtonGroup>
    </Box>
  );
};

ExtensionSort.propTypes = {
  loading: PropTypes.bool,
  sort: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default ExtensionSort;
