import { Box, Card, CardActions, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import extPlaceholder from '../../../assets/ext-placeholder.png';
import { buildPath, PRIVATE_ROUTE } from '../../../routes/routes';
import { currencyFormat } from '../../../utils/paymentUtils';
import { formatExtensionType } from '../../../utils/utils';
import Badge from '../../elements/badge/Badge';
import Button from '../../elements/button/Button';
import Text from '../../elements/text/Text';
import DownloadExtensionButton from './DownloadExtensionButton';
import RemoveExtensionButton from './RemoveExtensionButton';

const useStyles = makeStyles((theme) => ({
  cover: {
    height: 125,
    width: 125,
    objectFit: 'contain',
    alignSelf: 'center',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 300,
  },
  cardHeader: {
    display: 'flex',
    maxHeight: 230,
    overflowY: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 250,
      flexDirection: 'column-reverse',
    },
  },
  price: {
    backgroundColor: theme.palette.action.hover,
    padding: '0.25em 0.75em',
    borderRadius: '5px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginBottom: '0.5em',
      width: '50%',
      padding: '0.25em',
    },
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  actionButtons: {
    display: 'flex',
    '& > button': {
      marginLeft: '1em',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '-0.5em',
        marginTop: '0.5em',
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
}));

const ExtensionCard = ({
  data,
  isLibrary,
  handleAdd,
  handleDelete,
  ...restProps
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const handleDetailPageLink = () =>
    history.push(
      buildPath({
        path: PRIVATE_ROUTE.EXTENSION_DETAILS,
        params: { extensionId: data.id },
      })
    );

  const extensionType = formatExtensionType(data.type);

  return (
    <Card
      variant="outlined"
      aria-label="extension card"
      className={classes.card}
    >
      <Box display="flex" flexDirection="column" p="0.5em">
        <Box className={classes.cardHeader}>
          <CardMedia
            className={classes.cover}
            image={data.icon || extPlaceholder}
            title={data.name}
            aria-label="extension icon"
            component="img"
          />
          <Box m={1} alignItems="center">
            <Badge
              label={extensionType.display}
              color={extensionType.className}
              aria-label="extension type"
            />
            <Text aria-label="extension name">{data.name}</Text>
          </Box>
        </Box>
        <Box mt={1} ml={0.25}>
          <Text
            caption
            component="p"
            color="textSecondary"
            aria-label="extension developer"
          >
            {`${t('developer', { ns: 'fields' })}: ${data.developerName}`}
          </Text>
          {data.license && (
            <Text
              caption
              component="p"
              color="textSecondary"
              aria-label="extension license"
            >
              {`${t('license', { ns: 'fields' })}: ${data.license}`}
            </Text>
          )}
          {data.copyright && (
            <Text
              caption
              component="p"
              color="textSecondary"
              aria-label="extension copyright"
            >
              {`${t('copyright', { ns: 'fields' })}: ${data.copyright}`}
            </Text>
          )}
        </Box>
      </Box>
      <CardActions className={classes.cardAction}>
        <Text
          className={classes.price}
          component="div"
          aria-label="extension price"
        >
          {data.free
            ? t('billing.free', { ns: 'project' })
            : currencyFormat(data.price)}
        </Text>
        <Box className={classes.actionButtons}>
          <Button
            variant="text"
            size="small"
            onClick={handleDetailPageLink}
            aria-label="view extension detail"
          >
            {t('details', { ns: 'buttons' })}
          </Button>
          {!isLibrary && (
            <>
              <DownloadExtensionButton
                extension={data}
                onAddExtension={handleAdd}
                {...restProps}
              />
              <RemoveExtensionButton extension={data} onDelete={handleDelete} />
            </>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

ExtensionCard.propTypes = {
  data: PropTypes.object.isRequired,
  isLibrary: PropTypes.bool,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default ExtensionCard;
