import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';

const CreateForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        sentenceCase(
          t('inputRequired', {
            ns: 'validations',
            field: t('folderName', { ns: 'fields' }),
          })
        )
      )
      .matches(
        /^([a-zA-Z0-9][^:?]*)$/,
        sentenceCase(
          t('invalid', {
            ns: 'validations',
            field: t('folderName', { ns: 'fields' }),
          })
        )
      )
      .max(255, t('maxLength', { ns: 'validations', max: '255' })),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label={t('folderName', { ns: 'fields' })}
            error={!!errors?.name}
            helperText={errors?.name?.message}
            inputProps={{ 'aria-label': 'folder name' }}
            autoFocus
            fullWidth
          />
        )}
      />
      <Box textAlign="right" my={2}>
        <Button
          type="submit"
          disabled={!isDirty || !isValid}
          aria-label="create folder submit"
        >
          {t('create', { ns: 'buttons' })}
        </Button>
      </Box>
    </form>
  );
};

CreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CreateForm;
