import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatUserInfo } from '../../../utils/utils';
import NoContent from '../../sections/no-content/NoContent';
import UserAvatar from '../../sections/user-avatar/UserAvatar';
import DictionaryActions from './DictionaryActions';

const SynonymDictionaryTable = ({ list, ...dictionaryActions }) => {
  const { t } = useTranslation();

  const TABLE_HEADERS = [
    t('synonym.dictionaryName', { ns: 'project' }),
    t('creator', { ns: 'project' }),
  ];

  return (
    <>
      {!isEmpty(list) ? (
        <TableContainer>
          <Table size="small" aria-label="dictionary-table">
            <TableHead>
              <TableRow>
                {TABLE_HEADERS.map((header, i) => (
                  <TableCell align={i !== 0 ? 'center' : 'left'} key={header}>
                    {header}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((item, index) => (
                <TableRow key={index} aria-label="table row">
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="center" width={200}>
                    <UserAvatar
                      user={formatUserInfo(item.createdBy, item.author)}
                    />
                  </TableCell>
                  <TableCell align="right" width={200}>
                    <DictionaryActions
                      dictionary={item}
                      {...dictionaryActions}
                      isTableRow
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoContent title={t('noSynonymDictionary', { ns: 'descriptions' })} />
      )}
    </>
  );
};

SynonymDictionaryTable.propTypes = {
  list: PropTypes.array,
};

export default SynonymDictionaryTable;
