import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { sentenceCase } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import TextInput from '../../elements/text-input/TextInput';
import Title from '../../elements/title/Title';
import ImageUpload from '../../sections/image-upload/ImageUpload';
import { FILE_SIZE } from '../instance-stepper/constants';
import { instanceSchema } from '../instance-stepper/schema';
import SettingsDetails from './SettingsDetails';

const useStyles = makeStyles((theme) => ({
  spaceOnSide: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  marginAuto: {
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  fullWidth: {
    width: '100%',
  },
}));

const InstanceForm = ({
  data,
  checkDuplicate,
  onSubmit,
  onRemoveImage,
  disableSubdomainMessage,
  server,
  model,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const schema = yup.object().shape({
    icon: instanceSchema().icon,
    name: instanceSchema().name,
    description: instanceSchema().description,
    subdomain:
      server || model
        ? yup.string()
        : instanceSchema().subdomain.test(
            'isUnique',
            sentenceCase(
              t('duplicate', {
                ns: 'validations',
                field: t('instanceSubdomain', { ns: 'fields' }),
              })
            ),
            async (value) => {
              const { isValid } = await checkDuplicate(value);
              return isValid;
            }
          ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Title
        subTitle
        text={
          server
            ? t('editServer', { ns: 'sectionTitles' })
            : model
            ? t('editModel', { ns: 'sectionTitles' })
            : t('editInstance', { ns: 'sectionTitles' })
        }
      />
      <Container maxWidth="md" className={classes.marginAuto}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
              <ImageUpload
                name="icon"
                imageSrc={data?.icon}
                control={control}
                limit={FILE_SIZE.BYTES}
                onRemoveImage={onRemoveImage}
                disabled={disableSubdomainMessage !== ''}
              />
            </Grid>
            <Grid
              container
              spacing={3}
              item
              xs={12}
              md={8}
              className={classes.fullWidth}
            >
              <Grid item xs={12}>
                <SettingsDetails data={data} />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="name"
                  label={
                    server
                      ? t('serverName', { ns: 'fields' })
                      : model
                      ? t('modelName', { ns: 'fields' })
                      : t('instanceName', { ns: 'fields' })
                  }
                  control={control}
                  defaultValue={data.name}
                  disabled={disableSubdomainMessage !== ''}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="description"
                  label={
                    server
                      ? t('serverDesc', { ns: 'fields' })
                      : model
                      ? t('modelDesc', { ns: 'fields' })
                      : t('instanceDesc', { ns: 'fields' })
                  }
                  control={control}
                  errors={errors}
                  defaultValue={data.description}
                  disabled={disableSubdomainMessage !== ''}
                  inputProps={{
                    'aria-label': 'description',
                    maxLength: 256,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="subdomain"
                  label={
                    server
                      ? t('serverSubdomain', { ns: 'fields' })
                      : model
                      ? t('modelSubdomain', { ns: 'fields' })
                      : t('instanceSubdomain', { ns: 'fields' })
                  }
                  control={control}
                  defaultValue={data.subdomain}
                  disabled={disableSubdomainMessage !== ''}
                  errors={errors}
                  helperText={
                    errors?.subdomain?.message || disableSubdomainMessage
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Button
                    type="submit"
                    disabled={
                      !isDirty ||
                      !isEmpty(errors) ||
                      disableSubdomainMessage !== ''
                    }
                    aria-label="update"
                  >
                    {t('update', { ns: 'buttons' })}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

InstanceForm.propTypes = {
  data: PropTypes.object.isRequired,
  checkDuplicate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  disableSubdomainMessage: PropTypes.string,
  server: PropTypes.bool,
  model: PropTypes.bool,
};

export default InstanceForm;
