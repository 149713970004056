import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { INSTANCE_CATEGORY } from '../../../constants';
import { currencyFormat } from '../../../utils/paymentUtils';
import Text from '../../elements/text/Text';
import { useStyles } from './InstanceStepper.styles';

const PlanCard = ({ plan, community }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { name, category, description, price, features } = plan;
  const isCommunity = category === INSTANCE_CATEGORY.COMMUNITY;

  // Maximum community instances allowed: 2
  const communityAvailable = 2 - community;

  return (
    <Card
      aria-label={`${name}-plan`}
      className={isCommunity ? classes.communityCard : classes.card}
    >
      <CardHeader
        title={t(capitalize(name), { ns: 'menus' })}
        subheader={t(description, { ns: 'menus' })}
        titleTypographyProps={{
          align: 'center',
          style: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.5em',
          },
        }}
        subheaderTypographyProps={{
          align: 'center',
          style: {
            color: 'white',
          },
        }}
        style={{ marginBottom: '-1.5em' }}
      />
      <CardContent>
        <Box mb={3}>
          <Text align="center" className={classes.planPrice}>
            {isCommunity
              ? t('features.free', { ns: 'menus' })
              : currencyFormat(price)}
          </Text>
          <Text align="center" className={classes.taxText}>
            {isCommunity
              ? t('remaining', { ns: 'general', number: communityAvailable })
              : t('features.monthlyNoTax', { ns: 'menus' })}
          </Text>
        </Box>
        {features.map((feature) => (
          <Box
            key={feature.content}
            display="flex"
            alignItems="flex-start"
            aria-label="feature item"
          >
            <div className={classes.contentFeatureIcon}>{feature.icon}</div>
            <Text className={classes.contentFeature}>
              {t(feature.content, { ns: 'menus' })}
            </Text>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object.isRequired,
  community: PropTypes.number.isRequired,
};

export default PlanCard;
