import { Box, Grid, TextField } from '@material-ui/core';
import { replace } from 'lodash';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { INSTANCE_STATE } from '../../../apis/constants';
import { INSTANCE_CATEGORY } from '../../../constants';
import { isEnglish } from '../../../utils/utils';
import Button from '../../elements/button/Button';
import SelectInput from '../../elements/select-input/SelectInput';
import TextInput from '../../elements/text-input/TextInput';
import Text from '../../elements/text/Text';
import ImageUpload from '../../sections/image-upload/ImageUpload';
import CidrAddressSection from '../cidr-address/CidrAddressSection';
import { SOLR_VERSION, FILE_SIZE } from './constants';
import { useStyles } from './InstanceStepper.styles';
import StepTitle from './StepTitle';

const InstanceForm = ({ projectName }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useFormContext();

  const { name, subdomain, cidrs, plan } = watch();

  const isCommunity = plan === INSTANCE_CATEGORY.COMMUNITY;

  const formatSubdomain = (instanceName) => {
    // mataches whitespace, underscores, and hyphens. Groups them together.
    const formattedInstanceName = replace(instanceName, /[\s_-]+/g, '-');
    return `${projectName}-${formattedInstanceName}`.toLowerCase();
  };

  const generateSubdomain = () => {
    const formattedSubdomain = formatSubdomain(name);
    setValue('subdomain', formattedSubdomain);
    trigger('subdomain');
  };

  const handleUpdateCidr = (cidr) => setValue('cidrs', cidr);

  const solrVersionOptions = SOLR_VERSION.map((version) => ({
    label:
      version === '9.3.0' || version === '9.4.1'
        ? `${version} ( Java 11 )`
        : version,
    value: version,
  }));

  const showGenerateSubdomain =
    name && isEnglish(projectName) && isEnglish(name) && !subdomain;

  return (
    <section>
      <StepTitle title={t('enterInstanceInfo', { ns: 'descriptions' })} />
      <Grid container alignItems="center" justify="center" spacing={2}>
        <Grid item xs={12} md={3}>
          <ImageUpload name="icon" control={control} limit={FILE_SIZE.BYTES} />
        </Grid>
        <Grid container item spacing={2} xs={12} md={9}>
          <Grid item xs={12} sm={4}>
            <SelectInput
              label={t('solrVersion', { ns: 'fields' })}
              controlled
              name="solrVersion"
              control={control}
              options={solrVersionOptions}
              labelKey="label"
              valueKey="value"
              disabled={isCommunity}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextInput
              name="name"
              control={control}
              label={t('instanceName', { ns: 'fields' })}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="description"
              control={control}
              label={t('instanceDesc', { ns: 'fields' })}
              errors={errors}
              inputProps={{ 'aria-label': 'description', maxLength: 256 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="subdomain"
              defaultValue=""
              render={({ field }) => (
                <Box style={{ position: 'relative' }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label={t('instanceSubdomain', { ns: 'fields' })}
                    error={!!errors?.subdomain}
                    helperText={errors?.subdomain?.message}
                    value={subdomain}
                    InputLabelProps={
                      getValues('subdomain') ? { shrink: true } : {}
                    }
                    inputProps={{ 'aria-label': 'subdomain' }}
                    fullWidth
                    {...field}
                  />

                  {showGenerateSubdomain && (
                    <Button
                      variant="text"
                      className={classes.subdomain}
                      onClick={generateSubdomain}
                      aria-label="generate subdomain"
                    >
                      {t('generate', { ns: 'buttons' })}
                    </Button>
                  )}
                </Box>
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Text bodyBold text={t('setCidr', { ns: 'instance' })} />
          <CidrAddressSection
            cidrs={cidrs}
            instanceState={INSTANCE_STATE.RUNNING}
            onUpdateCidrRange={handleUpdateCidr}
            compact
          />
        </Grid>
      </Grid>
    </section>
  );
};

InstanceForm.propTypes = {
  projectName: PropTypes.string.isRequired,
};

export default InstanceForm;
