import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { Pagination } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  CRAWLER_STATUS,
  JOB_TYPE,
} from '../../../../constants/crawlerConstants';
import { getInitials } from '../../../../utils/utils';
import Image from '../../../elements/image/Image';
import Text from '../../../elements/text/Text';
import NoContent from '../../../sections/no-content/NoContent';
import StatusCaption from '../../../sections/status-caption/StatusCaption';
import UserAvatar from '../../../sections/user-avatar/UserAvatar';
import { combineAllSeeds, jobProgress } from '../crawlerUtils';
import ActionButtons from './ActionButtons';
import JobStatusDisplay from './JobStatusDisplay';

const CrawlerTable = ({
  jobs,
  totalPages,
  isAdmin,
  onRunJob,
  onStopJob,
  onResetJob,
  onEditJob,
  onDeleteJob,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const handlePageChange = (_, page) => onPageChange(page - 1);
  const isJobFree = (job) => job.type === JOB_TYPE.FREE;

  const tableHeaders = [
    'table.name',
    'table.creator',
    'table.seeds',
    'table.instance',
    'table.collection',
    'table.status',
  ];

  return (
    <Box mt={2}>
      {isEmpty(jobs) && (
        <NoContent title={t('noCrawlJob', { ns: 'crawler' })} />
      )}
      {!isEmpty(jobs) && (
        <>
          <TableContainer>
            <Table aria-label="crawler table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((title, i) => (
                    <TableCell key={title} align={i === 0 ? 'left' : 'center'}>
                      {t(title, { ns: 'crawler' })}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {jobs.map((job) => (
                  <TableRow key={job.id}>
                    <TableCell>
                      <Text bodyBold>{job.name}</Text>
                      {!isJobFree(job) && (
                        <StatusCaption
                          icon={MonetizationOnOutlinedIcon}
                          text={t('table.paidCrawler', {
                            ns: 'crawler',
                          })}
                          color="green"
                        />
                      )}
                    </TableCell>
                    <TableCell align="center" size="small">
                      <UserAvatar user={job.author} />
                    </TableCell>
                    <TableCell align="center" style={{ maxWidth: 150 }}>
                      <Tooltip
                        title={combineAllSeeds(job.data).map((seed) => (
                          <Box key={seed}>{seed}</Box>
                        ))}
                      >
                        <Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{ cursor: 'default' }}
                          >
                            <Text variant="body2" noWrap align="center">
                              {job.data.seeds[0]}
                            </Text>
                            <InfoOutlinedIcon
                              style={{ fontSize: '1em', marginLeft: 2 }}
                            />
                          </Box>
                          {job.data.seeds.length > 1 && (
                            <Box>
                              <Text variant="caption">
                                {t('table.moreSeeds', {
                                  ns: 'crawler',
                                  seeds: combineAllSeeds(job.data).length - 1,
                                })}
                              </Text>
                            </Box>
                          )}
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" size="small">
                      <Tooltip title={job.solrInstanceSet.name || ''}>
                        <Box display="flex" justifyContent="center">
                          <Image
                            size="small"
                            shape="rounded"
                            alt="avatar"
                            src={job.solrInstanceSet.icon}
                          >
                            {getInitials(job.solrInstanceSet.name)}
                          </Image>
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{job.collection}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="center">
                        <JobStatusDisplay
                          status={job.status || CRAWLER_STATUS.LOADING}
                          progress={jobProgress(job)}
                          error={job.error}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <ActionButtons
                        job={job}
                        isAdmin={isAdmin}
                        onRunJob={onRunJob}
                        onStopJob={onStopJob}
                        onResetJob={onResetJob}
                        onEditJob={onEditJob}
                        onDeleteJob={onDeleteJob}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {totalPages > 1 && (
            <Box
              mt={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Pagination
                count={totalPages}
                shape="rounded"
                onChange={handlePageChange}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

CrawlerTable.propTypes = {
  jobs: PropTypes.array,
  totalPages: PropTypes.number,
  processing: PropTypes.bool,
  isAdmin: PropTypes.bool,
  onRunJob: PropTypes.func.isRequired,
  onStopJob: PropTypes.func.isRequired,
  onResetJob: PropTypes.func.isRequired,
  onEditJob: PropTypes.func.isRequired,
  onDeleteJob: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default CrawlerTable;
