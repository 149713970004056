import i18next from 'i18next';
import { isEmpty } from 'lodash';
import * as yup from 'yup';

import { sentenceCase } from '../../../utils/utils';

export const emailSchema = (emails) =>
  yup.object().shape({
    projectMembers: yup
      .array()
      .nullable()
      .test(
        'recipient check',
        i18next.t('notificationRecipient', { ns: 'validations' }),
        (value) => !isEmpty(value) || !isEmpty(emails)
      ),
    email: yup
      .array()
      .nullable()
      .when('projectMembers', {
        is: (value) => {
          return (isEmpty(value) || value === null) && isEmpty(emails);
        },
        then: yup
          .array()
          .nullable()
          .required(i18next.t('notificationRecipient', { ns: 'validations' })),
        otherwise: yup.array().nullable(),
      }),
    subject: yup.string().required(
      sentenceCase(
        i18next.t('inputRequired', {
          ns: 'validations',
          field: i18next.t('monitor.emailSubject', { ns: 'fields' }),
        })
      )
    ),
    body: yup.string(),
  });

export const webhookSchema = () =>
  yup.object().shape({
    url: yup.string().required(
      sentenceCase(
        i18next.t('inputRequired', {
          ns: 'validations',
          field: i18next.t('monitor.webhookUrl', { ns: 'fields' }),
        })
      )
    ),
    text: yup.string().required(
      sentenceCase(
        i18next.t('inputRequired', {
          ns: 'validations',
          field: i18next.t('monitor.webhookMessage', { ns: 'fields' }),
        })
      )
    ),
  });
