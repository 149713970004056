import { useState } from 'react';

import { Box, Tabs, Tab, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../../elements/button/Button';
import Modal from '../../sections/modal/Modal';
import { NOTIFICATION_TYPES } from './constants';
import EmailForm from './EmailForm';
import WebhookForm from './WebhookForm';

const TabPanel = ({ children, value, index }) => (
  <Box role="tabpanel" hidden={value !== index}>
    {children}
  </Box>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const NotificationButton = ({
  disabled,
  projectMembers,
  onEmailSubmit,
  onWebhookSubmit,
  onTestSubmit,
}) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const [tab, setTab] = useState(0);

  const handleOpen = () => setModal(true);
  const handleClose = () => {
    setTab(0);
    setModal(false);
  };

  const handleTabChange = (_, tab) => setTab(tab);

  return (
    <>
      {disabled ? (
        <Tooltip
          title={disabled && t('monitorWarning', { ns: 'descriptions' })}
          arrow
        >
          <div data-testid="tooltip">
            <Button startIcon={<AddIcon />} onClick={handleOpen} disabled>
              {t('addNotificationMethod', { ns: 'buttons' })}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button startIcon={<AddIcon />} onClick={handleOpen}>
          {t('addNotificationMethod', { ns: 'buttons' })}
        </Button>
      )}

      <Modal
        openModal={modal}
        maxWidth="md"
        title={t('monitoring.createNotification', { ns: 'sectionTitles' })}
        handleClose={handleClose}
      >
        <Tabs
          value={tab}
          onChange={handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          centered
          style={{ marginTop: '-1em' }}
        >
          {Object.values(NOTIFICATION_TYPES).map((item, i) => (
            <Tab key={i} label={item} />
          ))}
        </Tabs>
        <TabPanel value={tab} index={0}>
          <EmailForm
            projectMembers={projectMembers}
            onEmailSubmit={onEmailSubmit}
            onSendTest={onTestSubmit}
            onCancel={handleClose}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <WebhookForm
            onWebhookSubmit={onWebhookSubmit}
            onSendTest={onTestSubmit}
            onCancel={handleClose}
          />
        </TabPanel>
      </Modal>
    </>
  );
};

NotificationButton.propTypes = {
  disabled: PropTypes.bool,
  projectMembers: PropTypes.array.isRequired,
  onEmailSubmit: PropTypes.func.isRequired,
  onWebhookSubmit: PropTypes.func.isRequired,
  onTestSubmit: PropTypes.func.isRequired,
};

export default NotificationButton;
