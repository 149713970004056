export const BACKEND_USER_ROLES = {
  PRODUCT_MANAGER: 'PRODUCT_MANAGER',
  DEVELOPER: 'DEVELOPER',
  USER: 'USER',
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const USER_ROLE_LIST = [
  { value: USER_ROLES.ADMIN, label: 'roles.admin' },
  { value: USER_ROLES.USER, label: 'roles.user' },
];

export const LANGUAGE_LIST = [
  { code: 'ja', label: '日本語' },
  { code: 'en', label: 'English' },
];
