import { useState } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CRAWLER_STATUS } from '../../../../constants/crawlerConstants';
import { sentenceCase } from '../../../../utils/utils';
import Button from '../../../elements/button/Button';
import Dropdown from '../../../elements/dropdown/Dropdown';
import Text from '../../../elements/text/Text';
import Modal from '../../../sections/modal/Modal';

const ActionButtons = ({
  job,
  isAdmin,
  onRunJob,
  onStopJob,
  onResetJob,
  onEditJob,
  onDeleteJob,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);

  // Dropdown menu
  const handleMenuClick = (e) => setAnchorEl(e.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // Delete modal
  const openDeleteModal = () => {
    handleMenuClose();
    setDeleteModal(true);
  };
  const closeDeleteModal = () => setDeleteModal(false);

  // Reset modal
  const openResetModal = () => {
    handleMenuClose();
    setResetModal(true);
  };
  const closeResetModal = () => setResetModal(false);

  // Actions
  const handleStop = () => onStopJob(job.id);
  const handleRun = () => onRunJob(job.id);
  const handleReset = () => {
    onResetJob(job.id);
    closeResetModal();
  };
  const handleEdit = () => {
    handleMenuClose();
    onEditJob(job.id);
  };
  const handleDelete = async () => {
    handleMenuClose();
    await onDeleteJob(job.id);
    closeDeleteModal();
  };

  const isJobAvailable = (status) =>
    status === CRAWLER_STATUS.RUNNING || status === CRAWLER_STATUS.WAITING;
  const isJobRunning = (status) => status === CRAWLER_STATUS.RUNNING;
  const canTakeActions = (status) => status !== CRAWLER_STATUS.WAITING;

  const dropdownButtons = [
    {
      ariaLabel: 'view job details',
      actions: () => history.push(`crawler/${job.id}`),
      disabled: false,
      label: t('table.viewJobDetails', { ns: 'crawler' }),
    },
    {
      ariaLabel: 'view job status',
      actions: () => history.push(`crawler/${job.id}/status`),
      disabled: false,
      label: t('table.viewJobStatus', { ns: 'crawler' }),
    },
    {
      ariaLabel: 'edit job',
      actions: handleEdit,
      disabled: canTakeActions(job.status),
      label: t('table.editJob', { ns: 'crawler' }),
    },
    {
      ariaLabel: 'reset job',
      actions: openResetModal,
      disabled: canTakeActions(job.status),
      label: t('table.resetJob', { ns: 'crawler' }),
    },
  ];

  return (
    <>
      {isJobAvailable(job.status) && (
        <>
          {isJobRunning(job.status) ? (
            <IconButton onClick={handleStop}>
              <Tooltip title={t('table.stopJob', { ns: 'crawler' })}>
                <StopIcon aria-label="stop job" />
              </Tooltip>
            </IconButton>
          ) : (
            <IconButton onClick={handleRun}>
              <Tooltip title={t('table.runJob', { ns: 'crawler' })}>
                <PlayArrowIcon aria-label="run job" />
              </Tooltip>
            </IconButton>
          )}
        </>
      )}
      <IconButton onClick={handleMenuClick}>
        <MoreVertIcon aria-label="more actions" />
      </IconButton>

      <Dropdown anchorEl={anchorEl} onClose={handleMenuClose}>
        {dropdownButtons.map((button) => (
          <MenuItem
            key={button.ariaLabel}
            aria-label={button.ariaLabel}
            onClick={button.actions}
            disabled={button.disabled}
          >
            {button.label}
          </MenuItem>
        ))}
        {isAdmin && (
          <MenuItem
            aria-label="delete job"
            onClick={openDeleteModal}
            disabled={canTakeActions(job.status)}
          >
            {t('table.deleteJob', { ns: 'crawler' })}
          </MenuItem>
        )}
      </Dropdown>

      <Modal
        openModal={deleteModal}
        title={t('crawler.deleteJob', { ns: 'sectionTitles' })}
        handleClose={closeDeleteModal}
        handleConfirm={handleDelete}
      >
        <Text body style={{ marginBottom: '1em' }}>
          <Trans
            i18nKey="paidCrawlerWarning"
            ns="descriptions"
            components={[<b key="0" />]}
          />
        </Text>
        <Text bodyBold style={{ marginBottom: '1em' }}>
          {t('editCrawlJobInstead', { ns: 'descriptions' })}
        </Text>
        <Text body style={{ marginBottom: '1em' }}>
          {sentenceCase(
            t('areYouSure', {
              ns: 'descriptions',
              text: job.name,
            })
          )}
          {t('cantUndo', { ns: 'descriptions' })}
        </Text>
        <Box
          mt={2}
          mb={1}
          display="flex"
          justifyContent="flex-end"
          gridGap="1em"
        >
          <Button
            color="default"
            variant="text"
            type="reset"
            onClick={closeDeleteModal}
          >
            {t('cancel', { ns: 'buttons' })}
          </Button>
          <Button
            data-testid="modal-confirm"
            color="danger"
            variant="contained"
            onClick={handleDelete}
          >
            {t('delete', { ns: 'buttons' })}
          </Button>
        </Box>
      </Modal>

      <Modal
        openModal={resetModal}
        title={t('crawler.resetJob', { ns: 'sectionTitles' })}
        showActions
        handleClose={closeResetModal}
        handleConfirm={handleReset}
      >
        <Text body>{t('crawlerResetWarning', { ns: 'descriptions' })}</Text>
      </Modal>
    </>
  );
};

ActionButtons.propTypes = {
  job: PropTypes.object.isRequired,
  processing: PropTypes.bool,
  isAdmin: PropTypes.bool,
  onRunJob: PropTypes.func.isRequired,
  onStopJob: PropTypes.func.isRequired,
  onResetJob: PropTypes.func.isRequired,
  onEditJob: PropTypes.func.isRequired,
  onDeleteJob: PropTypes.func.isRequired,
};

export default ActionButtons;
