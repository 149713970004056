import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { collectionApis } from '../../../apis/collectionApis';
import { requestAll } from '../../../apis/config';
import { projectApis } from '../../../apis/projectApis';
import { solrApis } from '../../../apis/solrApis';
import Loading from '../../../components/elements/loading/Loading';
import ConfigHeader from '../../../components/page-sections/config-header/ConfigHeader';
import FileBrowser from '../../../components/page-sections/file-browser/FileBrowser';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import useFileManager from '../../../hooks/useFileManager';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { sentenceCase, isSolrCloud } from '../../../utils/utils';

// Constants
const BASE_FOLDER_CHAIN = [{ id: 'base_file', name: 'conf', isDir: true }];

const ConfigPage = () => {
  const { t } = useTranslation();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [project, setProject] = useState(null);
  const [instance, setInstance] = useState(null);
  const [collections, setCollections] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [fileList, setFileList] = useState(null);
  const [path, setPath] = useState('');
  const [folderChain, setFolderChain] = useState(BASE_FOLDER_CHAIN);

  const { actions, updateFileList } = useFileManager(setFileList, path);

  const history = useHistory();
  const { projectId, instanceId } = useParams();
  const { showToast } = useToast();

  const handleUpconfig = async (clear) => {
    try {
      await solrApis.upconfig(projectId, instanceId, selectedCollection, clear);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('upconfigSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('upconfigError', { ns: 'notifications' }));
    }
  };
  const handleReload = async () => {
    try {
      await collectionApis.reloadCollection(
        projectId,
        instanceId,
        selectedCollection
      );

      showToast(
        TOAST_TYPE.SUCCESS,
        t('reloadSuccess', { ns: 'notifications' })
      );
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('reloadError', { ns: 'notifications' }));
    }
  };
  const handleCollectionChange = (collectionName) => {
    setSelectedCollection(collectionName);
    setFolderChain(BASE_FOLDER_CHAIN);
  };
  const getCollections = async (instanceType) => {
    try {
      if (isSolrCloud(instanceType)) {
        const collectionRes = await collectionApis.getSolrCloudCollections(
          projectId,
          instanceId
        );
        setCollections(collectionRes.data.collections);
      } else {
        const { data: collectionRes } = await collectionApis.getCollections(
          projectId,
          instanceId
        );
        setCollections(Object.keys(collectionRes.status));
      }
    } catch (error) {
      setPageError(true);
    }
  };
  const handlePageLoad = async () => {
    setIsPageLoading(true);
    try {
      const [{ data: projectRes }, { data: instanceRes }] = await requestAll([
        projectApis.getProject(projectId),
        solrApis.getInstance(projectId, instanceId),
      ]);

      setProject(projectRes);
      setInstance(instanceRes);

      await getCollections(instanceRes?.type);
    } catch (error) {
      setPageError(true);
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    handlePageLoad();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!collections) return;
    setSelectedCollection(collections[0]);
    // eslint-disable-next-line
  }, [collections]);

  const getRootPath = async (collectionName, solrCloud) => {
    setIsFileLoading(true);
    try {
      const { data } = await collectionApis.getCollectionRootPath(
        projectId,
        instanceId,
        { name: collectionName, solrCloud }
      );
      setPath(data.rootPath);
    } catch (error) {
      setFileError(true);
      showToast(TOAST_TYPE.ERROR, t('filePath', { ns: 'errors' }));
    } finally {
      setIsFileLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedCollection) return;
    getRootPath(selectedCollection, isSolrCloud(instance.type));
    // eslint-disable-next-line
  }, [selectedCollection]);

  useEffect(() => {
    if (!path) return;
    updateFileList();
    // eslint-disable-next-line
  }, [path]);

  const getStructure = (file) => {
    const homePathLength = 5;
    const { newPath, newFolderChain } = actions.getStructure(
      file,
      path,
      folderChain,
      homePathLength
    );
    setPath(newPath);
    setFolderChain(newFolderChain);
  };
  const create = (folderName) => actions.create(folderName);
  const upload = (files, type) => actions.upload(files, type);
  const edit = (file) => actions.edit(file, history);
  const download = (files) => actions.download(files);
  const remove = (files) => actions.remove(files);
  const rename = (oldName, newName) => actions.rename(oldName, newName);

  const actionFunctions = {
    getStructure,
    create,
    upload,
    edit,
    download,
    remove,
    rename,
  };

  return (
    <Container>
      {isPageLoading && <Loading />}
      {pageError && (
        <Box mt={2}>
          <Alert severity="error">
            {sentenceCase(
              t('loading', {
                ns: 'errors',
                content: t('requestedFileList', { ns: 'instance' }),
              })
            )}
          </Alert>
        </Box>
      )}
      {!isPageLoading && project && instance && (
        <section>
          <Breadcrumbs
            projectName={project?.name}
            instanceName={instance?.name}
          />
          <ConfigHeader
            title={t('config', { ns: 'titles' })}
            instanceType={instance.type}
            collections={collections}
            onReload={handleReload}
            onUpconfig={handleUpconfig}
            onCollectionChange={handleCollectionChange}
          />
          {fileError && (
            <Box mt={2}>
              <Alert severity="error">
                {sentenceCase(
                  t('loading', {
                    ns: 'errors',
                    content: t('requestedFileList', { ns: 'instance' }),
                  })
                )}
              </Alert>
            </Box>
          )}
          {isFileLoading && <Loading />}
          {!isFileLoading && fileList && (
            <FileBrowser
              files={fileList || []}
              folderChain={folderChain}
              actionFunctions={actionFunctions}
            />
          )}
        </section>
      )}
    </Container>
  );
};

export default ConfigPage;
