export const PAYMENT_TYPES = {
  INVOICE: 'INVOICE',
  CARD: 'CARD',
};

export const PAYMENT_STATUS = {
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};
