import { instance, getToken } from './config';

const getRestores = (projectId) =>
  instance.get(`/projects/${projectId}/restores`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

const deleteRestore = (projectId, restoreId) =>
  instance.delete(`/projects/${projectId}/restores/${restoreId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

export const restoreApis = {
  getRestores,
  deleteRestore,
};
