import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Controller, useFormState, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { sentenceCase } from '../../../../utils/utils';
import TextInput from '../../../elements/text-input/TextInput';
import Text from '../../../elements/text/Text';

const CrawlStep4 = ({
  watch,
  setValue,
  control,
  trigger,
  instances,
  getCollections,
  getUniqueKey,
}) => {
  const { t } = useTranslation();
  const { errors } = useFormState({ control });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'crawlArgs',
  });

  const [collections, setCollections] = useState([]);

  const { instance, collection } = watch();

  const fetchCollections = async () => {
    const foundInstance = instances.find((inst) => inst.id === instance);
    const collectRes = await getCollections(foundInstance);
    setCollections(collectRes);
  };

  useEffect(() => {
    if (!instance) return;
    fetchCollections();
    // eslint-disable-next-line
  }, [instance]);

  const fetchUniqueKey = async () => {
    const uniqueKey = await getUniqueKey(instance, collection);
    setValue('uniqueKey', uniqueKey, { shouldValidate: true });
  };

  useEffect(() => {
    if (!instance || !collection) return;
    fetchUniqueKey();
    // eslint-disable-next-line
  }, [instance, collection]);

  const numOrBlank = (val) => /[0-9]/.test(val) || val === '';

  return (
    <Box>
      <section>
        <Box mb={2}>
          <Text caption style={{ color: 'gray' }}>
            {t('asteriskRequired', { ns: 'fields' })}
          </Text>
        </Box>
        <Grid container spacing={2} style={{ marginBottom: '1em' }}>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors?.instance}
            >
              <InputLabel>
                {`${t('instanceName', { ns: 'fields' })} *`}
              </InputLabel>
              <Controller
                name="instance"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    label={`${t('instanceName', { ns: 'fields' })} *`}
                    inputProps={{ 'aria-label': 'instance select' }}
                  >
                    {isEmpty(instances) ? (
                      <MenuItem value="">
                        {sentenceCase(
                          t('noFound', {
                            ns: 'descriptions',
                            text: t('instance', { ns: 'titles' }),
                          })
                        )}
                      </MenuItem>
                    ) : (
                      instances.map((inst) => (
                        <MenuItem key={inst.id} value={inst.id}>
                          {inst.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                )}
              />
              <FormHelperText>{errors?.instance?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors?.collection}
            >
              <InputLabel>
                {`${t('collectionName', { ns: 'fields' })} *`}
              </InputLabel>
              <Controller
                name="collection"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    label={`${t('collectionName', { ns: 'fields' })} *`}
                    inputProps={{ 'aria-label': 'collection select' }}
                  >
                    {isEmpty(collections) ? (
                      <MenuItem value="">
                        {sentenceCase(
                          t('noFound', {
                            ns: 'descriptions',
                            text: t('collections', { ns: 'titles' }),
                          })
                        )}
                      </MenuItem>
                    ) : (
                      collections.map((collect) => (
                        <MenuItem key={collect} value={collect}>
                          {collect}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                )}
              />
              <FormHelperText>{errors?.collection?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              name="uniqueKey"
              label={`${t('uniqueKey', { ns: 'fields' })} *`}
              control={control}
              errors={errors}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {['updateHandler', 'removeHandler', 'statusHandler'].map(
            (handler, index) => (
              <Grid item xs={12} sm={4} key={handler}>
                <TextInput
                  name={handler}
                  label={`${t(handler, { ns: 'fields' })} *`}
                  control={control}
                  errors={errors}
                />
              </Grid>
            )
          )}
        </Grid>
        <Box mt={1}>
          <Box mb={1}>
            <Text title>{t('commitSettings', { ns: 'sectionTitles' })}</Text>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controller
                name="commitTime"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('commitTime', { ns: 'fields' })}
                    variant="outlined"
                    inputProps={{ 'aria-label': 'commitTime' }}
                    fullWidth
                    multiline
                    size="small"
                    onChange={(e) => {
                      numOrBlank(e.target.value) &&
                        field.onChange(e.target.value);
                      trigger(['commitTime', 'commitAfterJob']);
                    }}
                    error={!!errors?.commitTime}
                    helperText={errors?.commitTime?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControl error={!!errors.commitAfterJob}>
                <FormControlLabel
                  label={t('commitAfterJob', { ns: 'fields' })}
                  control={
                    <Controller
                      name="commitAfterJob"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          inputProps={{
                            'aria-label': 'commitAfterJob',
                          }}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            trigger(['commitTime', 'commitAfterJob']);
                          }}
                        />
                      )}
                    />
                  }
                />
                <FormHelperText>
                  {errors.commitAfterJob?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box mb={1}>
            <Text title>{t('argumentSettings', { ns: 'sectionTitles' })}</Text>
          </Box>
          {fields.map((item, index) => (
            <Grid container spacing={2} key={item.id}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`crawlArgs[${index}].name`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={t('argName', { ns: 'fields' })}
                      size="small"
                      error={!!errors?.crawlArgs?.[index]?.name}
                      helperText={errors?.crawlArgs?.[index]?.name?.message}
                      inputProps={{
                        'aria-label': `crawlArgs[${index}].name`,
                      }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`crawlArgs[${index}].val`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={t('argValue', { ns: 'fields' })}
                      size="small"
                      error={!!errors?.crawlArgs?.[index]?.val}
                      helperText={errors?.crawlArgs?.[index]?.val?.message}
                      inputProps={{ 'aria-label': `crawlArgs[${index}].val` }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Box display="flex" alignItems="flex-start">
                {index > 0 && (
                  <IconButton
                    style={{ marginRight: '0.5em', marginTop: 14 }}
                    onClick={() => remove(index)}
                    size="small"
                    aria-label={`remove-${index}`}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
                <IconButton
                  style={{ marginTop: 14 }}
                  onClick={() => append({ name: '', val: '' })}
                  size="small"
                  aria-label={`add-${index}`}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Box>
      </section>
    </Box>
  );
};

CrawlStep4.propTypes = {
  instances: PropTypes.array.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  trigger: PropTypes.func.isRequired,
  getCollections: PropTypes.func.isRequired,
  getUniqueKey: PropTypes.func,
};

export default CrawlStep4;
