import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Modal from '../../../sections/modal/Modal';
import { saveQuerySchema } from './../formValidation';

const SaveQueryModal = ({ open, onSaveQuery, onToggle }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(saveQuerySchema()) });
  const handleSaveQuery = (name) => {
    onSaveQuery(name);
    onToggle();
  };

  return (
    <Modal
      openModal={open}
      title={t('saveQuery', { ns: 'titles' })}
      showActions
      handleClose={onToggle}
      handleConfirm={handleSubmit(handleSaveQuery)}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <Controller
          name="queryName"
          control={control}
          defaultValue=""
          shouldUnregister
          render={({ field }) => (
            <TextField
              {...field}
              label={t('search.queryName', { ns: 'fields' })}
              inputProps={{ 'aria-label': 'query name' }}
              fullWidth
              error={!!errors?.queryName}
              helperText={errors?.queryName?.message}
            />
          )}
        />
      </form>
    </Modal>
  );
};

SaveQueryModal.propTypes = {
  open: PropTypes.bool,
  onSaveQuery: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default SaveQueryModal;
