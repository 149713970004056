import { blue, green, orange, purple } from '@material-ui/core/colors';
import i18next from 'i18next';

export const graphColors = {
  green: green[300],
  blue: blue[300],
  orange: orange[300],
  purple: purple[300],
};

export const formattedChartData = (data) =>
  Object.entries(data.result).map((dataPoint) => ({
    timestamp: dataPoint[0],
    cpu: dataPoint[1].cpuUsage,
    ram: dataPoint[1].ramUsagePercent,
    diskUsage: dataPoint[1].dataDiskUsagePercent,
  }));

export const formattedIoData = (data, diskIoKeys) =>
  Object.entries(data.result).map((dataPoint) => {
    const ioData = { timestamp: dataPoint[0] };
    diskIoKeys.map(
      (ioKey) => (ioData[ioKey] = dataPoint[1].ioUtilization[ioKey] * 100)
    );
    return ioData;
  });

export const formatLabels = (label) => {
  switch (label) {
    case 'cpu':
    case 'ram':
      return label.toUpperCase();
    case 'diskUsage':
      return i18next.t('diskUsage', { ns: 'monitoring' });
    default:
      return label;
  }
};

export const formatDiskLabels = (label, solrDisk = null) => {
  if (label === solrDisk) {
    return 'table.solrDisk';
  } else {
    return 'table.rootDisk';
  }
};
