import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { userApis } from '../../../apis/userApis';
import Button from '../../../components/elements/button/Button';
import Loading from '../../../components/elements/loading/Loading';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import List from '../../../components/sections/list/List';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { useUser } from '../../../contexts/UserContext';
import { handleErrors } from '../../../helpers';
import useToast from '../../../hooks/useToast';
import { PRIVATE_ROUTE } from '../../../routes/routes';
import { sentenceCase } from '../../../utils/utils';

const ProjectsPage = () => {
  const { t } = useTranslation();

  const [projects, setProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const { user } = useUser();
  const { showToast } = useToast();

  useEffect(() => {
    if (!user) return;

    const fetchData = async () => {
      try {
        const { data } = await userApis.getUserProjects(user.id);
        const projects = data.content.map((item) => ({
          ...item,
          role: user.getRole(item.id),
        }));

        setProjects(projects);
        setIsLoading(false);
      } catch (error) {
        handleErrors(history, error, showToast);
        setIsLoading(false);
      }
    };

    if (user) fetchData();

    // eslint-disable-next-line
  }, [user]);

  const renderNewProject = () => history.push(PRIVATE_ROUTE.PROJECT_NEW);

  const projectListContent = projects ? (
    <>
      <Breadcrumbs />
      <TitlePanel visible title={t('projects', { ns: 'titles' })}>
        <Button onClick={renderNewProject}>
          {t('createProject', { ns: 'buttons' })}
        </Button>
      </TitlePanel>
      <List isProjectList data={projects} />
      {/* <List isProject dataList={projects} /> */}
    </>
  ) : (
    <Box mt={2}>
      <Alert severity="error">
        {sentenceCase(
          t('loading', {
            ns: 'errors',
            content: t('projects', { ns: 'titles' }),
          })
        )}
      </Alert>
    </Box>
  );

  return <Container>{isLoading ? <Loading /> : projectListContent}</Container>;
};

export default ProjectsPage;
