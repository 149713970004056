import Typography from '@material-ui/core/Typography';
import { intersection, keys, reduce } from 'lodash';
import PropTypes from 'prop-types';

const variantMap = {
  titleLarge: 'h5',
  title: 'h6',
  subtitleBold: 'subtitle2',
  subtitle: 'subtitle1',
  bodyBold: 'body2',
  body: 'body1',
  captionUpperCase: 'overline',
  caption: 'caption',
};

const formatProps = (props) => {
  const variantKey = intersection(keys(props), keys(variantMap))[0] || 'body';

  const otherProps = reduce(
    props,
    (result, _, key) => {
      if (!(key in variantMap)) result[key] = props[key];
      return result;
    },
    {}
  );

  return {
    variant: variantMap[variantKey],
    otherProps: otherProps,
  };
};

const Text = ({ text, children, ...restProps }) => {
  const fmtProps = formatProps(restProps);
  return (
    <Typography variant={fmtProps.variant} {...fmtProps.otherProps}>
      {text}
      {children}
    </Typography>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  titleLarge: PropTypes.bool,
  title: PropTypes.bool,
  subtitleBold: PropTypes.bool,
  subtitle: PropTypes.bool,
  bodyBold: PropTypes.bool,
  body: PropTypes.bool,
  captionUpperCase: PropTypes.bool,
  caption: PropTypes.bool,
};

export default Text;
