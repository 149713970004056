import { useEffect, useState } from 'react';

import { Box, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { antivirusApis } from '../../../apis/antivirusApis';
import { requestAll } from '../../../apis/config';
import { INSTANCE_STATE, isInstanceRunning } from '../../../apis/constants';
import { instanceApis } from '../../../apis/instanceApis';
import { projectApis } from '../../../apis/projectApis';
import { serverApis } from '../../../apis/serverApis';
import Loading from '../../../components/elements/loading/Loading';
import AntivirusSection from '../../../components/page-sections/antivirus/AntivirusSection';
import CidrAddressSection from '../../../components/page-sections/cidr-address/CidrAddressSection';
import InstanceForm from '../../../components/page-sections/instance-settings/InstanceForm';
import Breadcrumbs from '../../../components/sections/breadcrumbs/Breadcrumbs';
import DeleteSection from '../../../components/sections/delete/DeleteSection';
import TitlePanel from '../../../components/sections/title-panel/TitlePanel';
import { useServer } from '../../../contexts/ServerContext';
import useToast, { TOAST_TYPE } from '../../../hooks/useToast';
import { PRIVATE_ROUTE, buildPath } from '../../../routes/routes';
import {
  mapCidrs,
  sentenceCase,
  updateObjectValues,
} from '../../../utils/utils';

const serverSettingsChanged = (currentData, newData) =>
  currentData.name !== newData.name ||
  currentData.description !== newData.description ||
  currentData.subdmoain !== newData.subdomain;

const ServerSettingsPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { server, refreshServer } = useServer();
  const { showToast } = useToast();
  const { projectId, gpsId } = useParams();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [antivirus, setAntivirus] = useState(null);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [{ data: projectRes }, { data: antivirusRes }] = await requestAll([
        projectApis.getProject(projectId),
        antivirusApis.getAntivirusSettings(projectId, gpsId),
      ]);

      setProject(projectRes);
      setAntivirus(antivirusRes.content[0]);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchVirusScans = async (antivirusId) => {
    try {
      const { data: virusScanRes } = await antivirusApis.getVirusScanList(
        projectId,
        gpsId,
        antivirusId
      );

      return virusScanRes;
    } catch (error) {
      showToast(TOAST_TYPE.ERROR, t('virusScanError', { ns: 'notifications' }));
      return [];
    }
  };

  const onCheckDuplicateSubdomainInstance = async (subdomain) => {
    if (subdomain === server.subdomain) {
      return { isValid: true };
    }

    try {
      return { isValid: await instanceApis.checkSubdomain(subdomain) };
    } catch (e) {
      showToast(
        TOAST_TYPE.ERROR,
        t('unableToCheckSubdomain', { ns: 'notifications' })
      );

      return { isValid: false };
    }
  };

  const handleUpdateServerImage = async (imageFile) => {
    try {
      const formData = new FormData();
      formData.append('image', imageFile);

      await instanceApis.updateInstanceImage(projectId, gpsId, formData);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('instanceImage', { ns: 'instance' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('instanceImage', { ns: 'instance' }),
          })
        )
      );
    }
  };

  const handleCidrsUpdate = (cidrList) => {
    const formattedCidrList = { cidrs: cidrList };
    handleServerUpdate(formattedCidrList);
  };

  const formatInstanceData = (data) => {
    const { name, description, subdomain, clusterType } = server;

    const initialInstanceData = {
      name,
      description,
      subdomain,
      clusterType,
    };

    const instancePayload = updateObjectValues(initialInstanceData, data);
    return instancePayload;
  };

  const handleServerSettingsUpdate = async (settingsData) => {
    const serverPayload = formatInstanceData(settingsData);

    try {
      await serverApis.updateServer(projectId, gpsId, serverPayload);
      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('updateSuccess', {
            ns: 'notifications',
            text: t('instanceInfo', { ns: 'instance' }),
          })
        )
      );
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('updateError', {
            ns: 'notifications',
            text: t('instanceInfo', { ns: 'instance' }),
          })
        )
      );
    }
  };

  const handleServerUpdate = async (data) => {
    const { name, description, subdomain, cidrs, clusterType } = server;
    const { icon, ...restProps } = data;

    const initialInstanceData = {
      name,
      description,
      subdomain,
      clusterType,
      cidrs: mapCidrs(cidrs),
    };

    const settingsPayload = updateObjectValues(initialInstanceData, restProps);

    if (icon) {
      await handleUpdateServerImage(icon);
    }

    if (serverSettingsChanged(server, data)) {
      await handleServerSettingsUpdate(settingsPayload);
    }

    refreshServer();
  };

  const handleRemoveImageInstance = async () => {
    try {
      await instanceApis.deleteInstanceImage(projectId, gpsId);
      showToast(
        TOAST_TYPE.SUCCESS,
        t('deleteSuccess', {
          ns: 'notifications',
          text: t('avatar', { ns: 'fields' }),
        })
      );
      refreshServer();
    } catch (error) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('avatar', { ns: 'fields' }),
          })
        )
      );
    }
  };

  const onDeleteInstance = async () => {
    try {
      // delete instance
      await serverApis.deleteServer(projectId, gpsId);

      showToast(
        TOAST_TYPE.SUCCESS,
        sentenceCase(
          t('deleteSuccess', {
            ns: 'notifications',
            text: t('instance', { ns: 'instance' }),
          })
        )
      );
      const projectOverview = buildPath({
        path: PRIVATE_ROUTE.PROJECT_OVERVIEW,
        params: { projectId: projectId },
      });
      setTimeout(() => history.push(projectOverview), 800);
    } catch (errors) {
      showToast(
        TOAST_TYPE.ERROR,
        sentenceCase(
          t('deleteError', {
            ns: 'notifications',
            text: t('server', { ns: 'gps' }),
          })
        )
      );
    }
  };

  const handleAntivirusChange = async (setting) => {
    if (setting) {
      try {
        const updatedSettings = await antivirusApis.enableAntivirus(
          projectId,
          gpsId
        );
        setAntivirus(updatedSettings.data);
        showToast(
          TOAST_TYPE.SUCCESS,
          t('antivirusEnableSuccess', { ns: 'notifications' })
        );
      } catch (error) {
        showToast(
          TOAST_TYPE.ERROR,
          t('antivirusEnableError', { ns: 'notifications' })
        );
      }
    } else {
      try {
        const updatedSettings = await antivirusApis.disableAntivirus(
          projectId,
          gpsId,
          antivirus.id
        );
        setAntivirus(updatedSettings.data);
        showToast(
          TOAST_TYPE.SUCCESS,
          t('antivirusDisableSuccess', { ns: 'notifications' })
        );
      } catch (error) {
        showToast(
          TOAST_TYPE.ERROR,
          t('antivirusDisableError', { ns: 'notifications' })
        );
      }
    }
  };

  return (
    <Container>
      {loading && <Loading />}

      {!loading && error && (
        <Box mt={2}>
          <Alert severity="error">
            {sentenceCase(
              t('loading', {
                ns: 'errors',
                content: t('settingsPage', {
                  ns: 'project',
                  text: t('server', { ns: 'server' }),
                }),
              })
            )}
          </Alert>
        </Box>
      )}

      {!loading && project && server && (
        <>
          <Breadcrumbs projectName={project.name} serverName={server.name} />
          <TitlePanel title={t('settings', { ns: 'titles' })} />
          <InstanceForm
            data={server}
            checkDuplicate={onCheckDuplicateSubdomainInstance}
            onSubmit={handleServerUpdate}
            onRemoveImage={handleRemoveImageInstance}
            disableSubdomainMessage={
              server.status !== INSTANCE_STATE.RUNNING
                ? t('instanceIsBusy', { ns: 'descriptions' })
                : ''
            }
            server
          />
          <CidrAddressSection
            cidrs={server.cidrs}
            instanceState={server.status}
            onUpdateCidrRange={handleCidrsUpdate}
          />
          <AntivirusSection
            antivirus={antivirus}
            instanceSize={server?.instances[0]?.type}
            onAntivirusChange={handleAntivirusChange}
            fetchVirusScans={fetchVirusScans}
          />
          <DeleteSection
            title={t('gpsDeletion', { ns: 'sectionTitles' })}
            description={sentenceCase(
              t('deleteWarning', {
                ns: 'descriptions',
                text: t('server', { ns: 'gps' }),
              })
            )}
            validation={server.name}
            onDelete={onDeleteInstance}
            disableMessage={
              isInstanceRunning(server)
                ? ''
                : t('instanceIsBusy', { ns: 'descriptions' })
            }
          />
        </>
      )}
    </Container>
  );
};

export default ServerSettingsPage;
